
import { URLconst } from '../../const';

export function getBooksChartData(sessionStorage) {
  const BaseURL = URLconst('graph/get_book_chart_data.php');

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: 'POST',
      body: JSON.stringify(sessionStorage)
    })
      .then(response => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
