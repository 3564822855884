import { URLconst } from "./const";
import axios from "axios";

export function putActiveMinuteSettings(param) {
  let BaseURL = URLconst("hubsite/settings/active_minute_menu.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function putActiveMinuteSleepData(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putActiveMinuteDayNote(param) {
  let BaseURL = URLconst("hubsite/soul/day_note.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putActiveMinuteFilm(param) {
  let BaseURL = URLconst("hubsite/leisure/films.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
export function putActiveMinuteBook(param) {
  let BaseURL = URLconst("hubsite/leisure/books.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putCalendarSummary(param) {
  let BaseURL = URLconst("hubsite/soul/summaries.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putActivities(param) {
  let BaseURL = URLconst("hubsite/active_minute/activities.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putInjuriesAndIssues(param) {
  let BaseURL = URLconst("hubsite/active_minute/injuries_and_issues.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putSteps(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_list.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putNutrition(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putWeightOrFat(param) {
  let BaseURL = URLconst("hubsite/health/weight_and_fat.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putHappinessList(param) {
  let BaseURL = URLconst("hubsite/soul/happiness.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putBloodAndPressure(param) {
  let BaseURL = URLconst("hubsite/health/blood_pressure.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
