import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  getCalendarFilters,
  getNutritionList,
  getHappiness,
  getSleepGraph,
  getActiveDayNote,
  getBooks,
  getFilms
} from "../../services/GetHUBsiteData";
import {
  getSummary,
  getInjuriesAndIssues,
  getCalendarActivities
} from "../../services/GetActiveMinuteData";
import { postCalendarSummary } from "../../services/PostActiveMinuteData";
import { putCalendarSummary } from "../../services/PutActiveMinuteData";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  subDays,
  isSameMonth,
  parse,
  addMonths,
  subMonths,
  addWeeks,
  subWeeks
} from "date-fns";
import "./Calendar.css";
import { SwipeableDrawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";
import DayValue from "./DayValue/DayValue";

import moment from "moment";
import MomentUtils from "@date-io/moment";

const Calendar = ({ target_hub_id, requestorHubID }) => {
  const intl = useIntl();
  const locale = localStorage.getItem("personal_hub_language").slice(0, 2);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filtersDrawerState, setFiltersDrawerState] = useState(false);
  // const filtersTitles = ["calories", "sleep", "happiness", "day note"];
  const [selectedFilters, setSelectedFilters] = useState({});
  const [calories, setCalories] = useState([]);
  const [happiness, setHappiness] = useState([]);
  const [dayNotes, setDayNotes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [films, setFilms] = useState([]);
  const [books, setBooks] = useState([]);
  const [weekSummary, setWeekSummary] = useState([]);
  const [monthSummary, setMonthSummary] = useState([]);
  const [sleep, setSleep] = useState([]);
  const [view, setView] = useState("month");
  // const [datePickerState, setDatePickerState] = useState(false)
  const [filtersBtnBg, setFiltersBtnBg] = useState("#fff");
  const [colors, setColors] = useState([]);
  const [newSummary, setNewSummary] = useState({
    entry_id: null,
    date_on: null,
    personal_note: null,
    entry_type: null
  });
  let hubid =
    sessionStorage.getItem("hub_token") !== null
      ? JSON.parse(sessionStorage.getItem("hub_token"))
          .userData.my_hub_id.slice(-5)
          .replace("_", "")
      : "";
  var send_credential_to_registrar = {
    target_hub_id: target_hub_id,
    requestor_hub_id: requestorHubID
  };

  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  useEffect(() => {
    if (newSummary.personal_note !== null) {
      const timeout = setTimeout(() => {
        let payload = {
          target_hub_id: hubid
        };
        if (!sessionStorage.getItem("hub_token")) {
          payload.logged_in = null;
        } else {
          payload.logged_in = JSON.parse(
            sessionStorage.getItem("hub_token")
          ).userData;
        }
        payload.data = { ...newSummary, id: newSummary.entry_id };
        if (newSummary.entry_id !== null) {
          putCalendarSummary(payload).then(result => {
            if (result.data.success) {
              getSummaryData();
            } else {
              console.log(result.data.error);
            }
          });
        } else {
          postCalendarSummary(payload).then(result => {
            if (result.data.success) {
              getSummaryData();
              // setNewSummary()
            } else {
              console.log(result.data.error);
            }
          });
        }
      }, 2000);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [newSummary.personal_note]);

  useEffect(() => {
    getCalendarFiltersArray();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSummaryData();
    // eslint-disable-next-line
  }, [currentDate]);

  useEffect(() => {
    checkFilters();
    if (selectedFilters["calories"]) {
      getNutritionData("nutrition");
    }
    if (selectedFilters["sleep"]) {
      getSleepData("sleep");
    }
    if (selectedFilters["happiness"]) {
      getHappinessData("happiness");
    }
    if (selectedFilters["day note"]) {
      getActiveDayNoteData("Day note");
    }
    if (selectedFilters["activities"]) {
      getActivitiesData();
    }
    if (selectedFilters["injuries and issues"]) {
      getInjuriesAndIssuesData();
    }
    if (selectedFilters["films"]) {
      getFilmsData();
    }
    if (selectedFilters["books"]) {
      getBooksData();
    }
    // eslint-disable-next-line
  }, [selectedFilters, currentDate]);

  const getCalendarFiltersArray = () => {
    let categoryColors = [];
    getCalendarFilters().then(result => {
      if (result.data.data_array) {
        let temp_obj = {};
        result.data.data_array.forEach((item, id) => {
          categoryColors.push({
            category: item.category,
            color: item.category_color
          });
          temp_obj[item.category] = false;
        });
        setColors(categoryColors);
        setSelectedFilters(sortObjectProps(temp_obj));
      }
    });
  };

  const sortObjectProps = obj => {
    const keys = Object.keys(obj).sort();
    let result = {};
    keys.map(key => {
      return (result[key] = obj[key]);
    });
    return result;
  };

  const getProvidedDate = () => {
    return moment(currentDate)
      .date(1)
      .format("YYYY-MM-DD");
  };

  const getSummaryData = () => {
    let summary_var = {
      ...send_credential_to_registrar,
      summary_type: "week summary",
      provided_date: moment(currentDate)
        .set("date", 1)
        .format("YYYY-MM-DD")
    };
    getSummary(summary_var).then(result => {
      if (result.data.data_array) {
        setWeekSummary(result.data.data_array);
      }
    });
    summary_var = {
      ...send_credential_to_registrar,
      summary_type: "month summary",
      provided_date: moment(currentDate)
        .set("date", 28)
        .format("YYYY-MM-DD")
    };
    getSummary(summary_var).then(result => {
      if (result.data.data_array) {
        setMonthSummary(result.data.data_array);
      }
    });
  };

  const getNutritionData = param => {
    let nutrit_var = {
      ...send_credential_to_registrar,
      section: param,
      provided_date: getProvidedDate()
    };
    getNutritionList(nutrit_var).then(result => {
      if (result.data.data_array) {
        setCalories(result.data.data_array);
      }
    });
  };

  const getHappinessData = param => {
    let happy_var = {
      ...send_credential_to_registrar,
      section: param,
      provided_date: getProvidedDate()
    };
    getHappiness(happy_var).then(result => {
      if (result.data.data_array) {
        setHappiness(result.data.data_array);
      }
    });
  };

  const getSleepData = param => {
    let sleep_var = {
      ...send_credential_to_registrar,
      section: param,
      provided_date: getProvidedDate()
    };
    getSleepGraph(sleep_var).then(result => {
      if (result.data.data_array) {
        setSleep(result.data.data_array);
      }
    });
  };

  const getActiveDayNoteData = param => {
    let active_day_note = {
      ...send_credential_to_registrar,
      section: param,
      provided_date: getProvidedDate()
    };
    getActiveDayNote(active_day_note).then(result => {
      if (result.data.data_array) {
        setDayNotes(result.data.data_array);
      }
    });
  };

  const getInjuriesAndIssuesData = param => {
    getInjuriesAndIssues({
      ...send_credential_to_registrar,
      provided_date: getProvidedDate()
    }).then(result => {
      if (result.data.data_array) {
        console.log(result.data.data_array);
      }
    });
  };

  const getBooksData = () => {
    getBooks({
      ...send_credential_to_registrar,
      provided_date: getProvidedDate()
    }).then(result => {
      if (result.data.data_array) {
        setBooks(result.data.data_array);
      }
    });
  };

  const getFilmsData = () => {
    getFilms({
      ...send_credential_to_registrar,
      provided_date: getProvidedDate()
    }).then(result => {
      if (result.data.data_array) {
        setFilms(result.data.data_array);
      }
    });
  };

  const getActivitiesData = param => {
    getCalendarActivities({
      ...send_credential_to_registrar,
      provided_date: getProvidedDate()
    }).then(result => {
      if (result.data.error) {
        // console.log(result)
      } else {
        setActivities(result.data.data_array);
      }
    });
  };

  const getValue = (day, filter) => {
    let a = moment(day).format("YYYY-MM-DD");
    let result = "";
    switch (filter) {
      case "calories": // eslint-disable-next-line
        calories.map((i, id) => {
          let b = moment(i.date_on).format("YYYY-MM-DD");
          if (a === b) {
            result = i.value.replace(" ", "T");
          }
        });
        break;
      case "happiness": // eslint-disable-next-line
        happiness.map((i, id) => {
          let b = moment(i.date_on).format("YYYY-MM-DD");
          if (a === b) {
            result = i.value.replace(" ", "T");
          }
        });
        break;
      case "sleep": // eslint-disable-next-line
        sleep.map((i, id) => {
          // console.log(i)
          if (i.time_start !== null && i.time_end !== null) {
            let b = moment(i.date_on).format("YYYY-MM-DD");
            let bStart = moment(i.time_start).format("hh:mm");
            let bEnd = moment(i.time_end).format("hh:mm");
            if (a === b) {
              // eslint-disable-next-line
              result = `${bStart}-` + `${bEnd} ` + `${i.Sleep_in_hours}h`;
            }
          } else {
            let b = moment(i.date_on).format("YYYY-MM-DD");
            if (a === b) {
              // eslint-disable-next-line
              result = `${i.Sleep_in_hours}h`;
            }
          }
        });
        break;
      case "day note": // eslint-disable-next-line
        dayNotes.map((i, id) => {
          let b = moment(i.date_on).format("YYYY-MM-DD");
          if (a === b) {
            result = i.personal_note;
          }
        });
        break;
      case "activities": // eslint-disable-next-line
        activities.map((i, id) => {
          let b = moment(i.date_on).format("YYYY-MM-DD");
          if (a === b) {
            result = i.short_title;
          }
        });
        break;
      case "books": // eslint-disable-next-line
        books.map((i, id) => {
          let b = moment(i.end_date).format("YYYY-MM-DD");
          if (a === b) {
            result = i.title;
          }
        });
        break;
      case "films": // eslint-disable-next-line
        films.map((i, id) => {
          let b = moment(i.seen_on).format("YYYY-MM-DD");
          if (a === b) {
            result = i.title;
          }
        });
        break;
      default:
        return result;
    }
    return result;
  };

  const editMonthSummary = (value, day) => {
    let newArr = monthSummary;
    monthSummary.forEach((item, id) => {
      let flag = false;
      if (
        moment(item.date_on).isSame(moment(day).subtract(1, "days"), "month") &&
        item.entry_type === "month summary"
      ) {
        setNewSummary({
          ...newSummary,
          entry_type: "month summary",
          entry_id: item.entry_id,
          personal_note: value,
          date_on: moment(day)
            .set("date", 28)
            .format("YYYY-MM-DD")
        });
        newArr[id].personal_note = value;
      } else {
        newArr.forEach(i => {
          if (
            moment(i.date_on).isSame(moment(day).subtract(1, "days"), "month")
          ) {
            flag = true;
          }
        });
        if (!flag) {
          newArr.push({
            personal_note: value,
            date_on: day,
            entry_id: null,
            entry_type: "month summary"
          });
        }
      }
      flag = false;
    });
    setMonthSummary([...newArr]);
  };

  const editWeekSummary = (value, day) => {
    let newArr = weekSummary;
    weekSummary.forEach((item, id) => {
      let flag = false;
      if (moment(item.date_on).isSame(day, "week")) {
        setNewSummary({
          ...newSummary,
          entry_type: "week summary",
          entry_id: item.entry_id,
          date_on: moment(day)
            .add(4, "days")
            .format("YYYY-MM-DD"),
          personal_note: value
        });
        newArr[id].personal_note = value;
      } else {
        newArr.forEach(i => {
          if (moment(i.date_on).isSame(day, "week")) {
            flag = true;
          }
        });
        if (!flag) {
          newArr.push({
            personal_note: value,
            date_on: day,
            entry_id: null,
            entry_type: "week summary"
          });
        }
      }
      flag = false;
    });
    setWeekSummary([...newArr]);
  };

  const sendSummary = (value, type, day) => {
    switch (type) {
      case "month":
        editMonthSummary(value, day);
        break;
      case "week":
        editWeekSummary(value, day);
        break;
      default:
        break;
    }
  };

  const filters = () => {
    return (
      <div className="filters_container">
        <ul>
          {Object.getOwnPropertyNames(selectedFilters).map((item, id) => {
            return (
              // <div className="filters_item" key={id}>{filter.title}</div>
              <MenuItem key={id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFilters[item]}
                      onChange={event =>
                        setSelectedFilters(
                          sortObjectProps({
                            ...selectedFilters,
                            [item]: event.target.checked
                          })
                        )
                      }
                      value={selectedFilters[item]}
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({
                    id: item
                  })}
                />
              </MenuItem>
            );
          })}
        </ul>
      </div>
    );
  };

  const formatDateSelectLabel = date => {
    switch (view) {
      case "month":
        return moment(date).format("MMM `YY");
      case "week":
        return moment(date).format("Wo GGGG");
      case "day":
        return moment(date).format("D MMM `YY");
      default:
        return moment(date).format("YYYY MMM `Do");
    }
  };
  const checkFilters = () => {
    let flag = false;
    Object.keys(selectedFilters).forEach(key => {
      if (selectedFilters[key]) {
        flag = true;
      }
    });
    if (flag) {
      setFiltersBtnBg("#3fa5ff");
    } else {
      setFiltersBtnBg("#fff");
    }
  };
  const header = () => {
    // const dateFormat = "MMMM yyyy";
    return (
      <div className="header">
        <div className="header_date_picker">
          <div onClick={() => prevViewPage()}>
            <ChevronLeftIcon />
          </div>
          {/* <span onClick={() => setDatePickerState(true)}>{format(currentDate, dateFormat)}</span> */}
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={["year", "month", "date"]}
              inputVariant="outlined"
              value={currentDate}
              onChange={date => setCurrentDate(date)}
              labelFunc={formatDateSelectLabel}
            />
          </MuiPickersUtilsProvider> */}
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={locale}
          >
            <DatePicker
              views={["year", "month", "date"]}
              inputVariant="outlined"
              value={currentDate}
              onChange={date => setCurrentDate(date)}
              labelFunc={formatDateSelectLabel}
            />
          </MuiPickersUtilsProvider>
          <div onClick={() => nextViewPage()}>
            <ChevronRightIcon />
          </div>
        </div>
        {renderViewBtns()}
        <div className="filters_container_btn">
          <Button
            className="filters_btn"
            style={{ backgroundColor: filtersBtnBg }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => setFiltersDrawerState(true)}
          >
            <img
              src={`/assets/images/Filter${
                filtersBtnBg !== "#fff" ? "" : "_blue"
              }.svg`}
              alt=""
            />
          </Button>
        </div>
      </div>
    );
  };

  const renderMonthSummary = day => {
    if (view === "month") {
      return (
        <div className="month_summary">
          <div className="month_summary_title">
            {intl.formatMessage({
              id: "calendar_month_summay"
            })}
          </div>
          <textarea
            type="text"
            placeholder="+"
            rows={getMonthSummaryValue(day) !== "" ? 5 : 1}
            value={getMonthSummaryValue(day)}
            onChange={event => sendSummary(event.target.value, "month", day)}
            className="month_summary_content "
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getViewBtnsText = viewId => {
    const smallWidth = [
      intl.formatMessage({
        id: "calendar_view_btn_M"
      }),
      intl.formatMessage({
        id: "calendar_view_btn_W"
      }),
      intl.formatMessage({
        id: "calendar_view_btn_D"
      })
    ];
    const bigWidth = [
      intl.formatMessage({
        id: "calendar_view_btn_month"
      }),
      intl.formatMessage({
        id: "calendar_view_btn_week"
      }),
      intl.formatMessage({
        id: "calendar_view_btn_day"
      })
    ];

    if (window.innerWidth < 430) {
      return smallWidth[viewId];
    } else {
      return bigWidth[viewId];
    }
  };

  const renderViewBtns = () => {
    return (
      <div className="view_toggle_buttons">
        <ToggleButtonGroup
          value={getViewToggleAlignment()}
          exclusive
          onChange={changeView}
          aria-label="text alignment"
        >
          <ToggleButton value="left" aria-label="left aligned">
            {getViewBtnsText(0)}
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            {getViewBtnsText(1)}
          </ToggleButton>
          <ToggleButton value="right" aria-label="right aligned">
            {getViewBtnsText(2)}
          </ToggleButton>
        </ToggleButtonGroup>
        {/* <button className="change_view_btn" onClick={() => changeView()}>{getViewIcon()}</button> */}
      </div>
    );
  };

  const headerListItem = (day, id) => {
    return (
      <div key={id} className={"days_names_item " + view}>
        {day}
      </div>
    );
  };

  const headerList = () => {
    let monthList = [
      intl.formatMessage({
        id: "calendar_monday"
      }),
      intl.formatMessage({
        id: "calendar_tuesday"
      }),
      intl.formatMessage({
        id: "calendar_wednesday"
      }),
      intl.formatMessage({
        id: "calendar_thursday"
      }),
      intl.formatMessage({
        id: "calendar_friday"
      }),
      intl.formatMessage({
        id: "calendar_saturday"
      }),
      intl.formatMessage({
        id: "calendar_sunday"
      }),
      intl.formatMessage({
        id: "calendar_week_summay"
      })
    ];
    let weekList = [""];
    let resultList = [];
    if (view === "month") {
      resultList = monthList.map((day, id) => {
        return headerListItem(day, id);
      });
    } else if (view === "week") {
      resultList = weekList.map((day, id) => {
        return headerListItem(day, id);
      });
    }
    return <div className="days_names_container">{resultList}</div>;
  };

  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    if (view === "month") {
      showMonth(
        day,
        days,
        rows,
        monthStart,
        endDate,
        formattedDate,
        dateFormat
      );
    } else if (view === "week") {
      showWeek(currentDate, days, rows, formattedDate, dateFormat);
    } else if (view === "day") {
      showDay(currentDate, days, rows, formattedDate, dateFormat);
    }

    return <div className="body">{rows}</div>;
  };

  const getWeekSummaryValue = day => {
    if (weekSummary.length !== 0) {
      let result = "";
      if (
        moment(newSummary.date_on).isSame(
          moment(day).subtract(1, "days"),
          "week"
        ) &&
        newSummary.entry_type === "week summary"
      ) {
        result = newSummary.personal_note;
      } else {
        weekSummary.forEach((item, id) => {
          if (
            moment(item.date_on).isSame(day, "week") &&
            item.entry_type === "week summary"
          ) {
            result = item.personal_note;
          }
        });
      }
      return result;
    }
  };

  const getMonthSummaryValue = day => {
    if (monthSummary.length !== 0) {
      let result = "";
      monthSummary.forEach((item, id) => {
        if (
          moment(item.date_on).isSame(
            moment(day).subtract(1, "days"),
            "month"
          ) &&
          item.entry_type === "month summary"
        ) {
          result = item.personal_note;
        }
      });
      return result;
    }
  };

  const showMonth = (
    day,
    days,
    rows,
    monthStart,
    endDate,
    formattedDate,
    dateFormat
  ) => {
    while (day <= endDate) {
      let currDay = day;
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(renderDayCell(day, monthStart, cloneDay, formattedDate));
        day = addDays(day, 1);
      }
      rows.push(
        <div
          key={day}
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative"
          }}
        >
          <div className="row"> {days} </div>
          <textarea
            type="text"
            placeholder="+"
            rows={getWeekSummaryValue(currDay) !== "" ? 5 : 1}
            value={getWeekSummaryValue(currDay)}
            onChange={event => sendSummary(event.target.value, "week", currDay)}
            className={"calendar_week_summary " + view}
          />
        </div>
      );
      days = [];
    }
  };

  const showWeek = (day, days, rows, formattedDate, dateFormat) => {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      days.push(renderWeekCell(day, formattedDate));
      day = addDays(day, 1);
    }
    rows.push(
      <div
        key={day}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          height: "fit-content"
        }}
      >
        {days} {renderWeekSummary(day)}
      </div>
    );
  };

  const showDay = (day, days, rows, formattedDate, dateFormat) => {
    formattedDate = format(day, dateFormat);

    rows.push(
      <div key={day} style={{ display: "flex", flexDirection: "row" }}>
        {renderDayView(day, formattedDate)}
      </div>
    );
  };

  const renderWeekSummary = day => {
    return (
      <div className="week_view_summary">
        <div className="week_view_summary_title">
          {intl.formatMessage({
            id: "calendar_week_summay"
          })}
        </div>
        <div className="week_view_summary_content">
          {getWeekSummaryValue(day)}
        </div>
      </div>
    );
  };
  const renderDayCell = (day, monthStart, cloneDay, formattedDate) => {
    return (
      <div
        className={`column cell ${
          !isSameMonth(day, monthStart) ? "disabled" : ""
        }
        `}
        key={day}
        onClick={() => onDateClick(parse(cloneDay, "MM/DD/YYYY", new Date()))}
      >
        <span className="number">{formattedDate}</span>
        {/* <span className="bg">{formattedDate}</span> */}
        {/* eslint-disable-next-line */}
        {Object.getOwnPropertyNames(selectedFilters).map((item, id) => {
          const value = getValue(day, item);
          if (selectedFilters[item] === true && value !== "") {
            return renderDayValues(id, value, item);
          } else {
            return null;
          }
        })}
      </div>
    );
  };
  const renderWeekCell = day => {
    return (
      <div className={"day_line"} key={day}>
        <span className="number">{moment(day).format("ddd, D")}</span>
        {/* <span className="bg">{formattedDate}</span> */}
        {/* eslint-disable-next-line */}
        <div className="content">
          {Object.getOwnPropertyNames(selectedFilters).map((item, id) => {
            const value = getValue(day, item);
            if (selectedFilters[item] === true && value !== "") {
              return renderDayValues(id, value, item);
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  };
  const renderDayView = day => {
    return (
      <div className={"day_view"} key={day}>
        <span className="number">{moment(day).format("dddd")}</span>
        {/* <span className="bg">{formattedDate}</span> */}
        {/* eslint-disable-next-line */}
        <div className="content">
          {Object.getOwnPropertyNames(selectedFilters).map((item, id) => {
            const value = getValue(day, item);
            if (selectedFilters[item] === true && value !== "") {
              return renderDayValues(id, value, item);
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  };
  const renderDayValues = (id, value, item) => {
    return (
      <div key={id} className={"cell_item"}>
        <DayValue title={item} value={value} id={id} colors={colors} />
      </div>
    );
  };

  const filtersDrawer = () => {
    return (
      <SwipeableDrawer
        anchor="right"
        open={filtersDrawerState}
        onOpen={() => setFiltersDrawerState(true)}
        onClose={() => setFiltersDrawerState(false)}
      >
        <div className="calendar_drawer_title">
          {intl.formatMessage({
            id: "calendar_filters"
          })}
        </div>
        <div
          style={{
            position: "absolute",
            top: "2px",
            left: "2px"
          }}
          onClick={() => setFiltersDrawerState(false)}
        >
          <CloseIcon />
        </div>
        <div className="calendar_drawer_inputs">
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "calendar_filters_search_ph"
            })}
            className="filter_name"
          />
          <select className="filter_type">
            <option>
              {intl.formatMessage({
                id: "calendar_filters_option_all"
              })}
            </option>
          </select>
        </div>
        <div className="calendar_drawer_list">{filters()}</div>
      </SwipeableDrawer>
    );
  };

  const nextViewPage = () => {
    // console.log(currentDate);
    if (view === "month") {
      setCurrentDate(addMonths(currentDate, 1));
    } else if (view === "week") {
      setCurrentDate(addWeeks(currentDate, 1));
    } else if (view === "day") {
      setCurrentDate(addDays(currentDate, 1));
    }
  };

  const prevViewPage = () => {
    if (view === "month") {
      setCurrentDate(subMonths(currentDate, 1));
    } else if (view === "week") {
      setCurrentDate(subWeeks(currentDate, 1));
    } else if (view === "day") {
      setCurrentDate(subDays(currentDate, 1));
    }
  };

  const onDateClick = day => {
    setSelectedDate(day);
    console.log(selectedDate);
  };

  const changeView = (event, value) => {
    if (value === "left") {
      setView("month");
    } else if (value === "center") {
      setCurrentDate(new Date(moment(currentDate).startOf("isoWeek")));
      setView("week");
    } else if (value === "right") {
      setView("day");
    }
  };

  const getViewToggleAlignment = () => {
    if (view === "month") {
      return "left";
    } else if (view === "week") {
      return "center";
    } else if (view === "day") {
      return "right";
    }
  };

  return (
    <div className="calendar_container">
      {filtersDrawer()}
      <div className="calendar">
        <div>{header()}</div>
        <div>{renderMonthSummary(currentDate)}</div>
        <div>{headerList()}</div>
        <div>{cells()}</div>
      </div>
    </div>
  );
};
export default Calendar;
