import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Switch from "react-switch";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import {
  getTableColumns,
  postNewTableRow,
  putNewTableRow,
  deleteTableRow
} from "../../services/HubManager";

import update from "immutability-helper";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

import "./TableEdit.css";

class TableEdit extends Component {
  constructor() {
    super();
    this.state = {
      columns_names: [],
      columns_data: [],
      columns_comments: {},
      filtered_data: [],
      result_data: [],
      new_entry: {},
      loading: true,
      changeInInsert: false,
      show_columns_comments: false,
      table_name: null,
      redirectToHubsite: false,
      filter_column: null,
      table_filter: null,
      currentViewAll: null,
      biggerCell: null,
      sortArrow: { col: "", direction: "" },
      sortedProp: "",
      searchText: ""
    };
  }

  componentDidMount() {
    if (this.props.match.params.table_name) {
      if (this.props.location.state) {
        if (this.props.location.state.hasOwnProperty("table_filter")) {
          this.setState(
            {
              table_name: this.props.match.params.table_name,
              table_filter:
                this.props.location.state.table_filter !== "" &&
                typeof this.props.location.state.table_filter !== "number"
                  ? this.props.location.state.table_filter
                  : null
            },
            () => {
              this.get_hub_columns();
            }
          );
        }
      } else {
        this.setState(
          { table_name: this.props.match.params.table_name },
          () => {
            this.get_hub_columns();
          }
        );
      }
    } else {
      this.setState({ redirectToHubsite: true });
    }
  }

  get_hub_columns = () => {
    this.setState({ loading: true });
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar.table_name = this.state.table_name;
    if (this.state.table_filter !== null) {
      send_credential_to_registrar.section = this.state.table_filter;
    }

    getTableColumns(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        let clearObj = {};
        let keyArr = result.data.data_array[0].columns;
        for (var i = 0; i < keyArr.length; i++) {
          clearObj[keyArr[i]] = null;
        }
        this.setState(
          {
            columns_names: result.data.data_array[0].columns,
            columns_data: result.data.data_array[1].data,
            filtered_data: result.data.data_array[1].data,
            result_data: result.data.data_array[1].data,
            columns_comments: result.data.data_array[2].comments,
            new_entry: clearObj,
            currentViewAll: "all",
            filter_column: result.data.data_array[3]
              ? result.data.data_array[3].filter_column
              : null
          },
          () => {
            this.setState({ loading: false }, () => {
              if (this.state.filter_column !== null) {
                this.handle_filter();
              }
            });
          }
        );
      } else {
        console.log("something went wrong");
        this.setState({ redirectToHubsite: true });
      }
    });
  };

  searchData = () => {
    let searchedData = [];
    if (this.state.searchText === "") {
      this.setState({ result_data: this.state.filtered_data });
    }
    if (this.state.columns_data !== undefined) {
      // eslint-disable-next-line
      this.state.filtered_data.map(item => {
        this.state.columns_names.forEach(col => {
          if (item[col] !== null && item[col] !== undefined) {
            if (
              item[col]
                .toUpperCase()
                .includes(this.state.searchText.toUpperCase())
            ) {
              let flag = true;
              searchedData.forEach(element => {
                if (element.id === item.id) {
                  flag = false;
                }
              });
              if (flag) {
                searchedData.push(item);
              }
            }
          }
        });
      });
      this.setState({ result_data: [...searchedData] });
    }
  };

  insert_new_row = (keys, values) => {
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar.data = {
      table_name: this.state.table_name,
      row_keys: keys,
      row_values: values
    };
    postNewTableRow(send_credential_to_registrar).then(result => {
      if (result.data.success) {
        console.log("success");
        this.get_hub_columns();
      } else {
        this.get_hub_columns();
        console.log("something went wrong");
      }
    });
  };

  edit_hub_columns = (id, keys, values) => {
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar.data = {
      table_name: this.state.table_name,
      row_keys: keys,
      row_values: values,
      row_id: id
    };
    putNewTableRow(send_credential_to_registrar).then(result => {
      if (result.data.success) {
        console.log("success");
      } else {
        this.get_hub_columns();
        console.log("something went wrong");
      }
    });
  };

  remove_row = id => {
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar.table_name = this.state.table_name;
    send_credential_to_registrar.row_id = id;
    deleteTableRow(send_credential_to_registrar).then(result => {
      if (result.data.success) {
        console.log("success");
        this.get_hub_columns();
      } else {
        this.get_hub_columns();
        console.log("something went wrong");
      }
    });
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  inputHandleChange = event => {
    // because of react event reusing we need to call .persist to be able to use this specific event later in function
    event.persist();
    let row_id = Number(event.target.dataset.rowindex);
    let val = event.target.value === "" ? null : event.target.value;
    let indexInColumnData = this.state.columns_data.findIndex(
      element => Number(element.id) === Number(row_id)
    );
    let indexInResultData = this.state.result_data.findIndex(
      element => Number(element.id) === Number(row_id)
    );

    if (indexInColumnData === -1 || indexInResultData === -1) {
      return null;
    }
    this.setState(
      {
        columns_data: update(this.state.columns_data, {
          [indexInColumnData]: {
            [event.target.name]: { $set: val }
          }
        }),
        result_data: update(this.state.result_data, {
          [indexInResultData]: {
            [event.target.name]: { $set: val }
          }
        })
      },
      () => {
        // timeout disabled to test usage of onblur event
        // if (this.timeout) clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        let id = this.state.columns_data[indexInColumnData].id;
        let keys = Object.keys(this.state.columns_data[indexInColumnData]);
        let values = Object.values(this.state.columns_data[indexInColumnData]);
        //   this.edit_hub_columns(id, keys, values);
        // }, 1000);

        // on element blur save changes
        event.target.onblur = () => {
          this.edit_hub_columns(id, keys, values);
        };
      }
    );
  };

  inputHandleInsert = event => {
    this.setState({ ...this.state, insertMode: true });
    let val = event.target.value === "" ? null : event.target.value;
    this.setState({
      changeInInsert: true,
      new_entry: update(this.state.new_entry, {
        [event.target.name]: { $set: val }
      })
    });
  };

  handleInsertBtn = () => {
    if (this.state.changeInInsert) {
      let keys = Object.keys(this.state.new_entry);
      let values = Object.values(this.state.new_entry);
      this.insert_new_row(keys, values);
      this.setState({ changeInInsert: false });
    }
  };

  confirmDeleteRow = id => {
    // let rowId = this.state.columns_data[indexInColumnData].id;
    if (window.confirm("Delete the item?")) {
      this.remove_row(Number(id));
      console.log(id);
    }
  };

  handleShowComments = () => {
    this.setState({ show_columns_comments: !this.state.show_columns_comments });
  };

  handle_filter = () => {
    let searchedData = [];
    // eslint-disable-next-line
    this.state.columns_data.map(item => {
      if (
        item[this.state.filter_column] !== null &&
        item[this.state.filter_column] !== undefined
      ) {
        if (
          item[this.state.filter_column]
            .toUpperCase()
            .includes(this.state.table_filter.toUpperCase())
        ) {
          let flag = true;
          searchedData.forEach(element => {
            if (element.id === item.id) {
              flag = false;
            }
          });
          if (flag) {
            searchedData.push(item);
          }
        }
      }
    });
    this.setState({
      filtered_data: searchedData,
      result_data: searchedData,
      currentViewAll: false,
      sortArrow: { col: "", direction: "" }
    });
    // eslint-disable-next-line
  };

  checkNewEntryValues = () => {
    const fields = Object.getOwnPropertyNames(this.state.new_entry);
    let flag = false;
    fields.forEach(item => {
      if (this.state.new_entry[item] !== null) {
        flag = true;
      }
    });
    return flag;
  };

  handle_switch_view_all = () => {
    if (this.state.currentViewAll) {
      this.handle_filter();
    } else {
      this.setState({
        filtered_data: this.state.columns_data,
        result_data: this.state.columns_data,
        currentViewAll: true,
        sortArrow: { col: "", direction: "" }
      });
    }
  };
  setCellBigger = id => {
    this.setState({ ...this.state, biggerCell: id });
  };

  handleSortClick = prop => {
    let sortedData = this.state.result_data.sort((a, b) => {
      if (this.state.sortedProp === prop) {
        let c = a;
        a = b;
        b = c;
      }
      if (a[prop] < b[prop] && isNaN(Number(a[prop]))) {
        return -1;
      } else if (Number(a[prop]) < Number(b[prop])) {
        return -1;
      }
      if (a[prop] > b[prop] && isNaN(Number(a[prop]))) {
        return 1;
      } else if (Number(a[prop]) > Number(b[prop])) {
        return 1;
      }
      return 0;
    });
    if (this.state.sortedProp === prop) {
      this.setState({
        sortArrow: { col: prop.toLowerCase(), direction: "up" },
        sortedProp: null
      });
    } else {
      this.setState({
        sortArrow: { col: prop.toLowerCase(), direction: "down" },
        sortedProp: prop
      });
    }
    this.setState({ result_data: [...sortedData] });
  };

  render() {
    if (this.state.redirectToHubsite) {
      return <Redirect to={"/hubsite"} />;
    }
    const { columns_names } = this.state;
    const { result_data } = this.state;
    const { new_entry } = this.state;

    return (
      <div id="TableEdit">
        <div className="tableEdit_title_wrapper">
          <div
            style={{
              color: "#3FA5FF",
              fontWeight: "bold",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <ArrowBackIcon /> Back
          </div>
          <div className="raw_data_search">
            <input
              type="text"
              placeholder="Search"
              value={this.state.searchText}
              onChange={event =>
                this.setState(
                  { ...this.state, searchText: event.target.value },
                  () => {
                    this.searchData();
                  }
                )
              }
            />
            {this.state.searchText !== "" ? (
              <CloseIcon
                onClick={() =>
                  this.setState({ searchText: "" }, () => {
                    this.searchData();
                  })
                }
                className="raw_data_search_clean"
              />
            ) : (
              <SearchIcon className="raw_data_search_clean" />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "2.5vw"
          }}
        >
          <div className="tableEdit_title">
            {this.state.table_name !== null
              ? this.state.table_name.replace(/_/g, " ")
              : ""}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            {this.state.table_filter !== null ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "5px" }}>Filter:&nbsp;</div>
                <Switch
                  onChange={() => this.handle_switch_view_all()}
                  checked={!this.state.currentViewAll}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={18}
                  width={40}
                />
                {/* <button
                  onClick={() => {
                    this.handle_switch_view_all();
                  }}
                  className="raw_data_btn"
                >
                  {this.state.currentViewAll ? "View filtered" : "View all"}
                </button> */}
              </div>
            ) : null}
            <button className="raw_data_btn" onClick={this.handleShowComments}>
              Info
            </button>
          </div>
        </div>
        {this.state.loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              className="bot_top_loading"
              alt="loading"
              src="/assets/images/loading.gif"
              style={{ alignSelf: "center" }}
            />
          </div>
        ) : (
          <div className="tableEdit_tables_container">
            <table id="customers">
              <tbody
                style={{
                  display: "block",
                  maxHeight: "80vh",
                  border: "none"
                }}
              >
                <tr
                  style={{
                    display: "block",
                    position: "sticky",
                    top: "0",
                    zIndex: "100"
                  }}
                >
                  {columns_names.map((item, th_index) => {
                    if (!item) {
                      return null;
                    }
                    return (
                      <th
                        key={th_index}
                        onClick={() => {
                          this.setCellBigger(th_index);
                          this.handleSortClick(item);
                        }}
                        style={
                          th_index === this.state.biggerCell
                            ? {
                                textTransform: "capitalize",
                                minWidth: "200px",
                                borderBottom: "1px solid #ddd"
                              }
                            : {
                                textTransform: "capitalize",
                                maxWidth: "100px",
                                borderBottom: "1px solid #ddd"
                              }
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            // className="sort_arrow"
                            style={{
                              alignSelf: "center",
                              height: "14px",
                              marginRight: "5px"
                            }}
                            src={
                              this.state.sortArrow.col === item.toLowerCase()
                                ? this.state.sortArrow.direction === "down"
                                  ? "/assets/images/table/sortArrow/up.png"
                                  : "/assets/images/table/sortArrow/down.png"
                                : "/assets/images/table/sortArrow/none.png"
                            }
                            alt="Sort arrow"
                          />
                          {item === "id" ? (
                            <div>items</div>
                          ) : (
                            <div>{item.replace(/_/g, " ")}</div>
                          )}
                        </div>
                        {this.state.show_columns_comments ? (
                          <div
                            style={{
                              marginTop: "5px",
                              paddingTop: "5px",
                              borderTop: "1px solid black",
                              fontSize: "10px"
                            }}
                          >
                            {this.state.columns_comments[item] !== ""
                              ? this.state.columns_comments[item]
                              : "comment missing"}
                          </div>
                        ) : null}
                      </th>
                    );
                  })}
                </tr>
                <tr
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    borderTop: "1px solid #ddd",
                    display: "block"
                  }}
                >
                  {this.state.columns_names.map((i, object_index) => {
                    if (!i) {
                      return null;
                    }

                    let placeholder;
                    let disabled;
                    switch (i) {
                      case "id":
                        placeholder = "(automatic)";
                        disabled = "disabled";
                        break;
                      case "created_on":
                        placeholder = "(automatic)";
                        disabled = "disabled";
                        break;
                      case "updated_on":
                        placeholder = "(automatic)";
                        disabled = "disabled";
                        break;
                      default:
                        placeholder = "";
                        disabled = "";
                    }
                    if (i === "id") {
                      return (
                        <td
                          key={object_index}
                          onClick={this.handleInsertBtn}
                          style={
                            object_index === this.state.biggerCell
                              ? { minWidth: "200px", color: "#3FA4FF" }
                              : { maxWidth: "100px", color: "#3FA4FF" }
                          }
                        >
                          {this.checkNewEntryValues() ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center"
                              }}
                            >
                              <SaveAltIcon /> Save
                            </div>
                          ) : (
                            "+ Create"
                          )}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={object_index}
                          onClick={() => this.setCellBigger(object_index)}
                          style={
                            object_index === this.state.biggerCell
                              ? { minWidth: "200px" }
                              : { maxWidth: "100px" }
                          }
                        >
                          <input
                            disabled={disabled}
                            placeholder={placeholder}
                            type="text"
                            name={i}
                            style={{
                              border: "1px solid #E4E4E4",
                              height: "35px",
                              borderRadius: "7px"
                            }}
                            value={this.checkIfNull(new_entry[i])}
                            onChange={this.inputHandleInsert}
                          />
                        </td>
                      );
                    }
                  })}
                </tr>
                {result_data.map((item, row_index) => {
                  if (!item) {
                    return null;
                  }
                  return (
                    <tr key={item.id}>
                      {Object.keys(item).map((i, object_index) => {
                        if (!i) {
                          return null;
                        }
                        return (
                          <td
                            key={object_index}
                            style={
                              object_index === this.state.biggerCell
                                ? { minWidth: "200px" }
                                : { maxWidth: "100px" }
                            }
                            onClick={() => this.setCellBigger(object_index)}
                          >
                            <input
                              data-rowindex={item.id}
                              type="text"
                              name={i}
                              value={this.checkIfNull(item[i])}
                              onChange={e => {
                                this.inputHandleChange(e);
                              }}
                              style={i === "id" ? { width: "60px" } : null}
                            />
                            {i === "id" ? (
                              <span
                                data-rowindex={item.id}
                                onClick={() => this.confirmDeleteRow(item.id)}
                                // style={{ width: "50px" }}
                              >
                                <DeleteOutlineIcon style={{ color: "red" }} />
                              </span>
                            ) : null}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TableEdit);
