import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getActiveMinuteSettings,
  getSleepGraph
} from "../../../services/GetActiveMinuteData";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "../styles/sleep.css";
import { putActiveMinuteSettings } from "../../../services/PutActiveMinuteData";
import { injectIntl } from "react-intl";

import Advanced from "./Versions/Sleep/advanced";
import Basic from "./Versions/Sleep/basic";

const useStyles = makeStyles(theme => ({
  disabled: {
    color: "grey !important"
  }
}));

function ActiveSleep({ handleSubmitIconChange, item, intl }) {
  const [sleepData, setSleepData] = useState([]);
  const [version, setVersion] = useState("basic");
  const [settingsID, setSettingsID] = useState(0);
  const classes = useStyles();

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 61) {
            // if (item.value === "yesterday") {
            //   setAlignment("left");
            // }
            if (item.parameter2 !== null) {
              setVersion(item.parameter2);
            }
            setSettingsID(item.id);
          }
        });
      }
    });

    getSleepGraph(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        setSleepData(result.data.data_array);
      } else {
        console.log("get sleep graph data error");
      }
    });
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  const handleVersion = (event, newVersion) => {
    if (newVersion !== null) {
      setVersion(newVersion);
      updateSettingsInApi(newVersion);
    }
  };

  const updateSettingsInApi = param => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.parameter2 = param;

    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const renderList = () => {
    switch (version) {
      case "basic":
        return (
          <Basic
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            sleepData={sleepData}
            getApiData={getApiData}
          />
        );
      case "advanced":
        return (
          <Advanced
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            sleepData={sleepData}
            getApiData={getApiData}
          />
        );
      case "pro":
        return (
          <Advanced
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            sleepData={sleepData}
            getApiData={getApiData}
          />
        );
      default:
        return (
          <Advanced
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            sleepData={sleepData}
            getApiData={getApiData}
          />
        );
    }
  };
  const togleButtons = () => {
    return (
      <ToggleButtonGroup
        value={version}
        exclusive
        onChange={handleVersion}
        aria-label="text alignment"
      >
        <ToggleButton value={"basic"} aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_basic"
          })}
        </ToggleButton>
        <ToggleButton value={"advanced"} aria-label="center aligned">
          {intl.formatMessage({
            id: "active_minute_advanced"
          })}
        </ToggleButton>
        <ToggleButton
          className={classes.disabled}
          disabled
          value={"pro"}
          aria-label="right aligned"
        >
          {intl.formatMessage({
            id: "active_minute_pro"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  return (
    <div className="films_container">
      <div className="films_title_container">
        <div className="films_title_left">{togleButtons()}</div>
      </div>
      <div className="sleep_list_container">{renderList()}</div>
    </div>
  );
}

export default injectIntl(ActiveSleep)