import React, { useState, useEffect, useRef } from "react";

import Chart from "react-google-charts";

export default function Statistics({ data, column, format, procesType }) {
  const [questions, setQuestions] = useState(null);
  const [questionStatistic, setQuestionStatistic] = useState(null);
  const [chartData, setChartData] = useState(null);
  const data_ref = useRef();

  useEffect(() => {
    data_ref.current = data;
  });

  //avarage rating of each question
  useEffect(() => {
    let questions = [];
    //find available questions
    data_ref.current.forEach((element) => {
      element.data.forEach((item) => {
        // console.log(item.subject);
        if (questions.indexOf(item.subject) === -1) {
          questions.push(item.subject);
        }
      });
    });
    setQuestions(questions);
  }, [data]);

  useEffect(() => {
    if (questions !== null) {
      let statistic = {};
      let chart = {};
      questions.forEach((q) => {
        let qv = 0;
        let qc = 0;
        let init = true;
        chart = { ...chart, [q]: {} };
        data_ref.current.forEach((d) => {
          d.data.forEach((i) => {
            if (q === i.subject) {
              if (procesType === "count") {
                qc++;
                qv = qv + 1;
              } else if (procesType === "avg") {
                qc++;
                qv = qv + Number(i[column]);
              } else if (procesType === "sum") {
                qc++;
                qv = qv + Number(i[column]);
              } else if (procesType === "min") {
                if (init) {
                  qv = Number(i[column]);
                  init = false;
                }
                qc++;
                qv = qv > Number(i[column]) ? Number(i[column]) : qv;
              } else if (procesType === "max") {
                qc++;
                qv = qv < Number(i[column]) ? Number(i[column]) : qv;
              }

              let temp_option = Number(i[column]);
              if (
                format.label === "grouped" ||
                format.label === "user_grouped" ||
                format.label === "custom"
              ) {
                temp_option = null;
                Object.keys(format.obj).forEach((element) => {
                  if (format.obj[element].includes(String(i[column]))) {
                    temp_option = element;
                  }
                });
              }
              if (temp_option !== null) {
                chart = {
                  ...chart,
                  [q]: {
                    ...chart[q],
                    [temp_option]:
                      chart[q][temp_option] !== undefined
                        ? chart[q][temp_option] + 1
                        : 1,
                  },
                };
              }
            }
          });
        });

        let val;

        if (procesType === "count") {
          val = qv.toFixed(1);
        } else if (procesType === "avg") {
          val = (qv / qc).toFixed(1);
        } else if (procesType === "sum") {
          val = qv.toFixed(1);
        } else if (procesType === "min") {
          val = qv.toFixed(1);
        } else if (procesType === "max") {
          val = qv.toFixed(1);
        }

        statistic = { ...statistic, [q]: { value: val, count: qc } };
        // chart.push(q, val);
      });
      setChartData(chart);
      setQuestionStatistic(statistic);
    }
  }, [questions, column, format, procesType]);

  // const graphProcessing = (data, option, item) => {
  //   return {
  //     ...data,
  //     [option]: {
  //       ...data[option],
  //       [item[column]]:
  //         data[option][item[column]] !== undefined
  //           ? data[option][item[column]] + 1
  //           : 1
  //     }
  //   };
  // };

  //   useEffect(() => {
  //     if (questionStatistic !== null) {
  //       console.log(questionStatistic);
  //     }
  //   }, [questionStatistic]);

  const handleGraphRender = (item, title) => {
    let data = [["Answer", "Percentage"]];
    Object.keys(item).forEach((element) => {
      data.push([element, item[element]]);
    });

    const pieOptions = {
      title: "",
      pieHole: 0.3,

      pieSliceTextStyle: { color: "white", fontSize: 15 },
      slices: {
        0: { color: "#68DC75" },
        1: { color: "#1C93FF" },
        2: { color: "#EB6565" },
      },
      legend: {
        position: "bottom",
        alignment: "center",
        textStyle: {
          color: "233238",
          fontSize: 14,
          fontName: "Roboto",
        },
      },
      chartArea: {
        left: 0,
        top: 15,
        width: "100%",
        height: "80%",
      },
      tooltip: {
        showColorCode: true,
      },
      fontName: "Roboto",
    };

    return (
      <div className="hubprompter_statistics_chart_wrap">
        <div className="hubprompter_statistics_chart_title">{title}</div>
        <Chart
          chartType="PieChart"
          data={data}
          options={pieOptions}
          width={"250px"}
          height={"250px"}
        />
      </div>
    );
  };

  return (
    <>
      {questionStatistic !== null ? (
        <div className="hubprompter_statistics_stats_container">
          {Object.keys(questionStatistic).map((i, index) => {
            if (!i) {
              return null;
            } else {
              return (
                <div key={index}>
                  <h5>{i}</h5>
                  <h6>
                    Responses: {questionStatistic[i].count} <br></br>
                    {procesType}: {questionStatistic[i].value}
                  </h6>
                </div>
              );
            }
          })}
        </div>
      ) : null}
      {chartData !== null ? (
        <div className="hubprompter_statistics_charts_container">
          {Object.keys(chartData).map((e, i) => {
            if (!e) {
              return null;
            } else {
              return <div key={i}>{handleGraphRender(chartData[e], e)}</div>;
            }
          })}
        </div>
      ) : null}
    </>
  );
}
