import { URLconst } from "./const";
import axios from "axios";

// GET

export function getLanguage(param) {
  let BaseURL = URLconst("hubsite/settings/language.php");

  return axios.get(BaseURL, {
    params: param
  });
}

export function getCovid19Warning(param) {
  let BaseURL = URLconst("hubsite/settings/covid19warning.php");

  return axios.get(BaseURL, {
    params: param
  });
}

// POST

export function postLanguage(param) {
  let BaseURL = URLconst("hubsite/settings/language.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postCovid19Warning(param) {
  let BaseURL = URLconst("hubsite/settings/covid19warning.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

// PUT

export function putLanguage(param) {
  let BaseURL = URLconst("hubsite/settings/language.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putCovid19Warning(param) {
  let BaseURL = URLconst("hubsite/settings/covid19warning.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
