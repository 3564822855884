import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import SearchIcon from "@material-ui/icons/Search";
// import IconButton from "@material-ui/core/IconButton";
// import InputBase from "@material-ui/core/InputBase";

import { useIntl } from "react-intl";

import Details from "./details";
import AddUserModal from "../../../screens/HUBsite/Modals/AddUserModal/AddUserModal";
import EditUserModal from "../../../screens/HUBsite/Modals/EditUserModal/EditUserModal";

import moment from "moment";

import "./index.scss";
import {
  postPermissions,
  getTablePermissions,
  getUsers,
  deleteUserPermissions,
  patchUserPermissions
} from "../../../services/Permissions";

const useStyles = makeStyles({
  root: {
    width: "95%",
    margin: "0px auto",
    position: "relative",
    // boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)",
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
    overflow: "auto"
  },
  root_cont_left: {
    margin: "0 5px 5px 0",
    padding: "2px",
    position: "relative",
    // boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)",
    boxShadow: "none",
    overflow: "hidden",
    float: "right"
  },
  root_cont: {
    margin: "0 2.5% 5px 0",
    padding: "2px",
    position: "relative",
    // boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)",
    boxShadow: "none",
    overflow: "hidden",
    float: "right"
  },
  header: {
    width: "100%",
    margin: "10px auto 10px auto",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 2.5% 0 2.5%"
  },
  title: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px"
  },
  search: {
    position: "relative",
    border: "1px solid #E4E4E4",
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.14)",
    backgroundColor: "#FFF",
    display: "flex",
    paddingLeft: "10px",
    height: "34px",
    width: "176px"
  },
  searchIcon: {
    padding: "0px 5px"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(71, 119, 186, 0.07)"
    }
  },
  cell: { color: "black", fontSize: "14px", fontWeight: "400" },
  num_input: { width: "50px", maxWidth: "100px" }
});

const Permissions = ({
  table,
  target_hub_id,
  rowArrows,
  setRowArrows,
  columnArrows,
  setColumnArrows,
  setReqHubId,
  getApiData,
  setPermFor,
  currentSection
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const const_credentials = {
    target_hub_id: target_hub_id,
    logged_in: !sessionStorage.getItem("hub_token")
      ? null
      : JSON.parse(sessionStorage.getItem("hub_token")).userData
  };

  const [expand, setExpand] = useState(false);
  const [permissionsFor, setPermissionsFor] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [details, setDetails] = useState("");
  const [data, setData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [dataForMap, setDataForMap] = useState([]);

  const [sortedProp, setSortedProp] = useState();
  // const [searchText, setSearchText] = useState("");
  const [permissionForAll, setPermissionForAll] = useState([]);
  const [sortArrow, setSortArrow] = useState({ col: "", direction: "" });
  const columns = [
    { title: "Name", field: "name" },
    { title: "Hub ID", field: "requestor_hub_id" },
    { title: "Last change", field: "last_change" },
    { title: "User Permissions", field: "user_permissions" },
    { title: "Expiration", field: "expiration" }
  ];

  const color_codes = ["pink", "#d6ffd6", "#ffe7bc"];

  useEffect(() => {
    if (data.length >= 2 && details === "") {
      setDataForMap(data.slice(0, 2));
    } else {
      setDataForMap(data);
    }
  }, [data, details]);

  const handleSortClick = prop => {
    let sortedData = data.sort(function(a, b) {
      if (a[prop] < b[prop]) {
        return -1;
      }
      if (a[prop] > b[prop]) {
        return 1;
      }
      return 0;
    });
    setSortedProp(prop);
    setSortArrow({ col: prop, direction: "down" });
    if (sortedProp === prop) {
      sortedData.reverse();
      setSortArrow({ col: prop, direction: "up" });
      setSortedProp();
    }
    setData([...sortedData]);
  };

  const set_permissions = (param, granted, granted_start, granted_end, who) => {
    let send_credential_to_registrar = const_credentials;
    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.target = "table";
    send_credential_to_registrar.data.values = {
      table_name: param,
      section: currentSection,
      requestor_hub_id: who === undefined ? permissionsFor : who,
      granted: granted,
      granted_start: granted_start,
      granted_end: granted_end
    };

    postPermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        // console.log(result.data.success);
        get_table_permissions();
        get_users();
        getApiData();
      }
    });
  };

  const get_table_permissions = () => {
    let send_credential_to_registrar = const_credentials;
    send_credential_to_registrar.table_name = table;
    send_credential_to_registrar.section = currentSection;
    getTablePermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data);
      } else {
        // console.log(result.data);
        setPermissionForAll(result.data.data_array[0]);
      }
    });
  };

  const get_users = () => {
    let send_credential_to_registrar = const_credentials;
    send_credential_to_registrar.table_name = table;
    send_credential_to_registrar.section = currentSection;
    getUsers(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data);
      } else {
        // setData(result.data.data_array);

        if (sortArrow.col !== "") {
          let sortedData = result.data.data_array.sort(function(a, b) {
            if (a[sortArrow.col] < b[sortArrow.col]) {
              return -1;
            }
            if (a[sortArrow.col] > b[sortArrow.col]) {
              return 1;
            }
            return 0;
          });
          if (sortArrow.direction === "up") {
            sortedData.reverse();
          }
          setData([...sortedData]);
        } else {
          setData(result.data.data_array);
        }
      }
    });
  };

  const delete_user_from_permissions = param => {
    let send_credential_to_registrar = const_credentials;
    send_credential_to_registrar.table_name = table;
    send_credential_to_registrar.remove_hub_id = param;

    deleteUserPermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data);
        setPermissionsFor("");
        get_users();
      } else {
        // console.log(result.data);
        setPermissionsFor("");
        get_users();
      }
    });
  };

  const patch_user_from_permissions = param => {
    let send_credential_to_registrar = const_credentials;
    send_credential_to_registrar.table_name = table;
    send_credential_to_registrar.patch_hub_id = param;

    patchUserPermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data);
        get_users();
      } else {
        // console.log(result.data);
        get_users();
      }
    });
  };

  useEffect(() => {
    setReqHubId(permissionsFor);
    setPermFor(permissionsFor);
    if (table !== "") {
      get_table_permissions();
    } // eslint-disable-next-line
  }, [permissionsFor]);

  useEffect(() => {
    if (table !== "") {
      get_table_permissions();
      get_users();
    } // eslint-disable-next-line
  }, [table]);

  const parseDate = (exp_start, exp_end) => {
    let start = moment(exp_start).unix();
    let end = moment(exp_end).unix();
    let now = moment().unix();

    if (exp_start === null && exp_end === null) {
      return (
        <div
          style={{
            color: "black"
          }}
        >
          {intl.formatMessage({
            id: "hub_site_permissions_expir_not_specified"
          })}
        </div>
      );
    } else if (exp_start !== null && exp_end === null) {
      return (
        <div
          style={{
            backgroundColor: color_codes[1],
            color: "black"
          }}
        >
          {intl.formatMessage({
            id: "hub_site_permissions_expir_permanent"
          })}
        </div>
      );
    } else if (now < start) {
      return (
        <div
          style={{
            backgroundColor: color_codes[2],
            color: "black"
          }}
        >
          {intl.formatMessage({
            id: "hub_site_permissions_expir_starts"
          })}{" "}
          {moment(exp_start).format("YYYY-MM-DD")}
        </div>
      );
    } else if (now > start && now < end) {
      return (
        <div
          style={{
            backgroundColor: color_codes[1],
            color: "black"
          }}
        >
          {intl.formatMessage({
            id: "hub_site_permissions_expir_until"
          })}{" "}
          {moment(exp_end).format("YYYY-MM-DD")}
        </div>
      );
    } else if (now > end) {
      return (
        <div
          style={{
            backgroundColor: color_codes[0],
            color: "black"
          }}
        >
          {intl.formatMessage({
            id: "hub_site_permissions_expir_expired"
          })}
        </div>
      );
    }
  };
  const scrollToBottom = () => {
    window.scroll(0, 350);
  };

  return expand ? (
    <>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ color: "#fd8b00" }}>
              {intl.formatMessage({
                id: "hub_site_permissions_top_note"
              })}
            </div>
            <span
              style={{ position: "relative", zIndex: "20" }}
              className={classes.title}
            >
              {intl.formatMessage({
                id: "hub_site_permissions_permissions_for"
              })}{" "}
              {(table.charAt(0).toUpperCase() + table.slice(1))
                .replace(/_/g, " ")
                .replace(/id/gi, "ID")}
            </span>
          </div>
          {/* <img
            className="perm_toggle"
            style={expand ? null : { transform: "rotate(180deg)" }}
            alt="Expand"
            src="/assets/images/expand_icon.png"
            onClick={() => {
              setExpand(!expand);
              setPermissionsFor("");
            }}
          /> */}
          <button
            className="permissions_show_btn"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {intl.formatMessage({
              id: "hub_site_permissions_hide_permissions"
            })}
          </button>
        </div>
        {/* <div className="perm_individual_container"> */}
        {/* <Paper className={classes.search}>
            {searchText !== "" ? (
              <IconButton
                className="icon-button"
                aria-label="close"
                onClick={() => {
                  setSearchText("");
                }}
                style={{ marginRight: "5px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </IconButton>
            ) : (
                <></>
              )}
            <InputBase
              className={classes.searchField}
              value={searchText}
              placeholder="Search"
              // placeholder={intl.formatMessage({
              //   id: "virtual_assistant_search"
              // })}
              inputProps={{ "aria-label": "search" }}
              onChange={event => {
                setSearchText(event.target.value);
              }}
            />
            <IconButton
              className={classes.searchIcon}
              aria-label="search"
            // onClick={() => handleSearchClick(searchText)}
            >
              <SearchIcon />
            </IconButton>
          </Paper> */}
        {/* </div> */}
        <div
          style={{
            width: "100%",
            overflow: "auto",
            boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)"
          }}
        >
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((column, id) => {
                  return (
                    <TableCell
                      key={id}
                      onClick={() => handleSortClick(column.field)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {intl.formatMessage({
                          id: column.field
                        })}
                        <img
                          className="sort_arrow"
                          src={
                            sortArrow.col === column.field
                              ? sortArrow.direction === "down"
                                ? "/assets/images/table/sortArrow/up.png"
                                : "/assets/images/table/sortArrow/down.png"
                              : "/assets/images/table/sortArrow/none.png"
                          }
                          alt="Sort arrow"
                        />
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  <input
                    onChange={() => {
                      setPermissionsFor("all");
                      setRowArrows(false);
                      setColumnArrows(false);
                      if (details !== "all") {
                        setDetails("all");
                      }
                    }}
                    checked={permissionsFor === "all" ? true : false}
                    type="radio"
                    name="requestor_hub_id"
                  />
                </TableCell>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  all visitors
                </TableCell>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  (all)
                </TableCell>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  -
                </TableCell>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  <div className="perm_btn_wrapper">
                    <div
                      style={{
                        backgroundColor:
                          color_codes[Number(permissionForAll.color_code)],
                        color: "black"
                      }}
                    >
                      {permissionForAll.default_permission_text}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.cell + " perm_cell_blue_bg"}>
                  <div className="perm_btn_wrapper">
                    {parseDate(
                      permissionForAll.granted_start,
                      permissionForAll.granted_end
                    )}
                  </div>
                </TableCell>
              </TableRow>
              {details === "all" ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="cell_details_wrapper">
                      <div className="perm_det_btn_group">
                        <div className="perm_bottom_btns">
                          <button
                            onClick={() => {
                              set_permissions(
                                table,
                                permissionForAll.which_option === "A" ||
                                  permissionForAll.which_option === "D"
                                  ? 1
                                  : 0,
                                null,
                                null,
                                "all"
                              );
                            }}
                            className="btn-item"
                          >
                            {permissionForAll.which_option === "B" ||
                            permissionForAll.which_option === "C"
                              ? intl.formatMessage({
                                  id: "hub_site_permissions_show_whole_table"
                                })
                              : intl.formatMessage({
                                  id: "hub_site_permissions_hide_whole_table"
                                })}
                          </button>
                        </div>
                      </div>
                      <div className="perm_det_btn_group">
                        <div className="perm_bottom_btns">
                          <button
                            onClick={() => {
                              scrollToBottom();
                              setRowArrows(!rowArrows);
                              setColumnArrows(false);
                            }}
                            className="btn-item"
                          >
                            {intl.formatMessage({
                              id: "hub_site_permissions_hide_certain_rows"
                            })}
                          </button>
                        </div>
                      </div>
                      <div className="perm_det_btn_group">
                        <div className="perm_bottom_btns">
                          <button
                            onClick={() => {
                              scrollToBottom();
                              setColumnArrows(!columnArrows);
                              setRowArrows(false);
                            }}
                            className="btn-item"
                          >
                            {intl.formatMessage({
                              id: "hub_site_permissions_hide_certain_columns"
                            })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody className="permissions_table_body">
              {dataForMap.map((row, id) => {
                return (
                  <React.Fragment key={id}>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}>
                        <input
                          onChange={() => {
                            setPermissionsFor(row.requestor_hub_id);
                            setSelectedUserName(row.name);
                            setRowArrows(false);
                            setColumnArrows(false);
                            if (details !== id) {
                              setDetails(row.requestor_hub_id);
                            }
                          }}
                          checked={
                            permissionsFor === row.requestor_hub_id
                              ? true
                              : false
                          }
                          type="radio"
                          name="requestor_hub_id"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>{row.name}</TableCell>
                      <TableCell className={classes.cell}>
                        {row.requestor_hub_id}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {new Intl.DateTimeFormat(
                          localStorage.getItem("personal_hub_language")
                        ).format(moment(row.last_change).valueOf())}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div className="perm_btn_wrapper">
                          <div
                            style={{
                              backgroundColor:
                                color_codes[Number(row.color_code)],
                              color: "black"
                            }}
                          >
                            {row.user_permission_text}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div className="perm_btn_wrapper">
                          {parseDate(row.granted_start, row.granted_end)}
                        </div>
                      </TableCell>
                    </TableRow>
                    {details === row.requestor_hub_id ? (
                      <Details
                        set_permissions={set_permissions}
                        setRowArrows={setRowArrows}
                        setColumnArrows={setColumnArrows}
                        rowArrows={rowArrows}
                        columnArrows={columnArrows}
                        tablePermissions={row}
                        table={table}
                        rowId={id}
                      />
                    ) : (
                      <React.Fragment key={id + data.length}></React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {data.length > 2 ? (
          dataForMap.length < 3 ? (
            <div
              onClick={() => {
                setDataForMap(data);
              }}
              className="perm_expand_table"
            >
              <span>
                {intl.formatMessage({
                  id: "hub_site_permissions_see_all"
                })}
              </span>
            </div>
          ) : (
            <div
              onClick={() => {
                setDataForMap(data.slice(0, 2));
              }}
              className="perm_expand_table"
            >
              <span>
                {intl.formatMessage({
                  id: "hub_site_permissions_see_less"
                })}
              </span>
            </div>
          )
        ) : null}

        <div className="btn-group">
          <div className="perm_bottom_btns">
            <button
              onClick={() => {
                setOpenAddModal(true);
              }}
              className="btn-item"
            >
              {intl.formatMessage({
                id: "hub_site_permissions_add"
              })}
            </button>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
              className="btn-item"
              style={
                permissionsFor !== "" && permissionsFor !== "all"
                  ? null
                  : { color: "lightgrey", border: "1px solid lightgrey" }
              }
              disabled={
                permissionsFor !== "" && permissionsFor !== "all" ? false : true
              }
            >
              {intl.formatMessage({
                id: "hub_site_permissions_edit"
              })}
            </button>
            <button
              onClick={() => {
                patch_user_from_permissions(permissionsFor);
              }}
              className="btn-item"
              style={
                permissionsFor !== "" && permissionsFor !== "all"
                  ? null
                  : { color: "lightgrey", border: "1px solid lightgrey" }
              }
              disabled={
                permissionsFor !== "" && permissionsFor !== "all" ? false : true
              }
            >
              {intl.formatMessage({
                id: "hub_site_permissions_pause"
              })}
            </button>
            <button
              onClick={() => {
                delete_user_from_permissions(permissionsFor);
              }}
              className="btn-item"
              style={
                permissionsFor !== "" && permissionsFor !== "all"
                  ? null
                  : { color: "lightgrey", border: "1px solid lightgrey" }
              }
              disabled={
                permissionsFor !== "" && permissionsFor !== "all" ? false : true
              }
            >
              {intl.formatMessage({
                id: "hub_site_permissions_remove"
              })}
            </button>

            {/* Preview button hidden for now as we are not using it */}
            {/* <button
              onClick={() => {
                console.log("Preview");
              }}
              className="btn-item"
            >
              Preview
            </button> */}
          </div>
        </div>
      </Paper>
      <AddUserModal
        openAddModal={openAddModal}
        setOpenAddModal={setOpenAddModal}
        target_hub_id={target_hub_id}
        table_name={table}
        get_users={get_users}
        currentSection={currentSection}
      />
      <EditUserModal
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        target_hub_id={target_hub_id}
        permissionsFor={permissionsFor}
        selectedUserName={selectedUserName}
        get_users={get_users}
        currentSection={currentSection}
      />
    </>
  ) : (
    <>
      <Paper className={classes.root_cont}>
        <div className="perm_expand_container">
          {/* <div
              style={{
                backgroundColor: color_codes[Number(permissionForAll.color_code)],
                color: "black"
              }}
            >
              {permissionForAll.default_permission_text}
            </div> */}
          {/* <div className="exceptions">{data.length} exceptions</div> */}
          <button
            className="permissions_show_btn"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {intl.formatMessage({
              id: "hub_site_permissions_show_permissions"
            })}
          </button>
          {/* <img
              className="perm_toggle"
              style={expand ? null : { transform: "rotate(180deg)" }}
              onClick={() => {
                setExpand(!expand);
              }}
              alt="Expand"
              src="/assets/images/expand_icon.png"
            /> */}
        </div>
      </Paper>
      <Paper className={classes.root_cont_left}>
        <div className="perm_expand_container">
          <Link
            to={{
              pathname: "/hubmanager/" + table,
              state: { table_filter: currentSection }
            }}
          >
            <button className="permissions_show_btn">
              {intl.formatMessage({
                id: "hub_site_permissions_raw_data_edit"
              })}
            </button>
          </Link>
        </div>
      </Paper>
    </>
  );
};

export default Permissions;
