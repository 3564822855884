import React, { Component } from "react";
import NavMap from "../../components/Navigator/NavMap";
import Suggestions from "../../components/Navigator/Suggestions";
import Filter from "../../components/Navigator/Filter";
import {
  FindPosition,
  GetBookDataKNV,
  CheckIfInStock,
  SaveTextSearch
} from "../../services/FindPosition";
import { GetShopFloorPlan } from "../../services/GetShopFloorPlan";
import "./Navigator.css";
import BookDetails from "../../components/Navigator/BookDetails";
import Modal from "react-modal";

import { LoginCall } from "../../services/LoginCall";
// import { ClaimFreeHub } from "../../services/ClaimFreeHub";
import { CreateNewHub } from "../../services/CreateNewHub";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import "../Login/login.css";
import moment from "moment";

import CreateHubAlert from "../../components/CreateHubAlert";
import CreateHubModal from "../../components/CreateHubModal";

import { getProfileBasics } from "../../services/getProfileBasics";
import { Redirect } from "react-router-dom";

import AvatarInfo from "../../components/Avatar/AvatarInfo";

// validation of login input
const passwordValidator = require("password-validator");

const schema = new passwordValidator();
// has to be 4 digits and no spaces
schema
  .is()
  .min(4) // Minimum length 8
  .is()
  .max(4) // Maximum length 100
  .has()
  .not()
  .letters()
  .is()
  .digits() // Must have digits
  .has()
  .not()
  .spaces(); // Should not have spaces

class Navigator extends Component {
  constructor() {
    super();
    this.state = {
      search_result: null,
      searched_keyword: "",
      search_string: "",
      book_info: [],
      sessionID: null,
      top_pos: 0,
      left_pos: 0,
      shop_id: null,
      shop_basics: null,
      shop_category: null,
      show_book_details: false,
      selected_group: false,
      selected_book: false,
      showModal: false,
      username: "",
      password: "",
      pin_generated: false,
      login_error: false,
      login_validation_error: false,
      my_hub_id: null,
      in_stock: null,
      batch_start: 1,
      cat_filter: [],
      selected_filter: null,
      loadingDB: true,
      loadingKNV: true,
      carouselView: false,
      login_from_cart: false,
      confirm_order: false,
      new_register: false,
      user_data: {
        avatar: null,
        profile_picture: null
      },

      // for avatar
      avatar_to_show: null,
      displayAvatarInfo: false,

      // temp code for bottom navigation
      redirectToReferrer: false,

      userData: {},
      createHubError: false,
      openCreateModal: false,
      creatingDone: false,
      progressBarDone: false,
      timeLeft: 0,
      openAlertModal: false
    };
  }

  componentDidMount() {
    this.checkForShopId();
    this.checkForSession();
  }

  setProgressBarDone = () => {
    this.setState({
      progressBarDone: true
    });
    this.setState({
      showModal: false,
      new_register: true,
      my_hub_id: this.state.userData.userData.my_hub_id
    });
    this.avatar_info_btn_callback();
    if (this.state.login_from_cart) {
      this.setState({
        confirm_order: true
      });
    }
  };

  checkForSession = () => {
    if (JSON.parse(sessionStorage.getItem("hub_token")) !== null) {
      this.setState({
        my_hub_id: JSON.parse(sessionStorage.getItem("hub_token")).userData
          .my_hub_id
      });
    }
  };

  checkForShopId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("shop") !== null) {
      this.setState(
        {
          shop_id: urlParams.get("shop")
        },
        () => {
          this.get_shop_basics();
        }
      );
    } else {
      this.setState({ redirectToReferrer: true });
    }
  };

  get_shop_basics = () => {
    const send_credential_to_registrar = {
      shop_id: this.state.shop_id
    };
    GetShopFloorPlan(send_credential_to_registrar).then(result => {
      if (result.shop_basics) {
        this.setState({
          shop_basics: result.shop_basics,
          shop_category: result.shop_basics.shop_category
        });
      }
    });
  };

  find_position = () => {
    const send_credential_to_registrar = {
      searched_keyword: this.state.searched_keyword
    };
    send_credential_to_registrar.shop_id = this.state.shop_id;
    FindPosition(send_credential_to_registrar).then(result => {
      if (result.search_result) {
        this.setState({
          search_result: result.search_result,
          loadingDB: false
        });
      } else {
        this.setState({ loadingDB: false });
      }
    });
  };

  // clearData parameter if dont want to concat results send true
  get_book_data_knv_general = clearData => {
    const send_credential_to_registrar = {
      search_string: this.state.searched_keyword
    };
    send_credential_to_registrar.batch = {
      start: this.state.batch_start,
      end: this.state.batch_start + 20
    };
    if (this.state.sessionID !== null) {
      send_credential_to_registrar.sessionID = this.state.sessionID;
    }
    GetBookDataKNV(send_credential_to_registrar).then(result => {
      if (result.book_info && result.book_info !== "charTypeErr") {
        // set empty arr and fill it with product indexes
        const temp_arr = this.state.cat_filter;
        result.book_info.map(r => temp_arr.push(r.SortimentType));
        // filter duplicates and set state of cat_filter to possible filter options
        const res_arr = temp_arr.filter((v, i, a) => a.indexOf(v) === i);
        this.setState(prevState => ({
          book_info: clearData
            ? result.book_info
            : prevState.book_info.concat(result.book_info),
          cat_filter: res_arr,
          loadingKNV: false
        }));
      } else {
        this.setState({ loadingKNV: false });
      }
      if (result.sessionID) {
        this.setState({ sessionID: result.sessionID });
      }
    });
  };

  // timeout to wait for user to finish typing(break longer than 500ms), start searching only if min number of char is met (5)
  handleChange = () => {
    // let tgname = e.target.name;
    // let tgval = e.target.value;
    const tgname = this.searchInputField.name;
    const tgval = this.searchInputField.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          [tgname]: tgval,
          search_result: null,
          book_info: [],
          top_pos: 0,
          left_pos: 0,
          show_book_details: false,
          selected_group: false,
          selected_book: false,
          in_stock: null,
          cat_filter: [],
          selected_filter: null,
          batch_start: 1,
          loadingDB: true,
          loadingKNV: true
        },
        () => {
          if (this.state.searched_keyword.length > 1) {
            this.find_position();
            if (this.state.shop_category === "bookstore") {
              this.get_book_data_knv_general(true);
            }
          }
        }
      );
    }, 500);
  };

  handleClearInput = () => {
    this.searchInputField.value = "";
    this.searchInputField.focus();
    this.setState({
      searched_keyword: "",
      search_result: null,
      book_info: [],
      top_pos: 0,
      left_pos: 0,
      show_book_details: false,
      selected_group: false,
      selected_book: false,
      in_stock: null,
      cat_filter: [],
      selected_filter: null,
      batch_start: 1,
      loadingDB: true,
      loadingKNV: true
    });
  };

  suggestions_callback = (index, top_pos, left_pos, callback_from) => {
    if (callback_from === "search_result") {
      this.setState(
        {
          search_result_selected: [this.state.search_result[index]],
          top_pos: top_pos,
          left_pos: left_pos,
          selected_group: true
        },
        () => {
          this.create_session_storage(index, true, "group");
        }
      );
    } else if (callback_from === "book_info") {
      this.checkIfOnStorage(
        this.state.book_info[index].EAN,
        this.state.book_info[index].ProductGroup,
        index
      );
      this.setState({
        book_data: [this.state.book_info[index]],
        show_book_details: true,
        selected_book: true
      });
    }
  };

  closeBookDetails = () => {
    if (this.state.show_book_details) {
      this.setState({
        show_book_details: false,
        selected_book: false,
        top_pos: 0,
        left_pos: 0,
        in_stock: null
      });
    } else if (this.state.selected_group) {
      this.setState({
        selected_group: false,
        top_pos: 0,
        left_pos: 0,
        in_stock: null
      });
    }
  };

  bookdetails_callback = () => {
    this.setState({ showModal: true, login_from_cart: true });
  };

  bookdetailsresetcallback = () => {
    this.setState({ login_from_cart: false, confirm_order: false });
  };

  checkIfOnStorage = (ean, product_group, index) => {
    if (ean.constructor === Array) {
      ean = ean[0];
    }
    const send_credential_to_registrar = {
      ean
    };
    send_credential_to_registrar.shop_id = this.state.shop_id;
    send_credential_to_registrar.product_group = product_group;
    CheckIfInStock(send_credential_to_registrar).then(result => {
      if (result.search_result) {
        this.setState(
          {
            top_pos: result.search_result[0].position_top,
            left_pos: result.search_result[0].position_left,
            in_stock: result.search_result[0].in_stock
          },
          () => {
            this.create_session_storage(index, true, "item");
          }
        );
      } else {
        this.setState(
          {
            in_stock: false
          },
          () => {
            this.create_session_storage(index, false, "item");
          }
        );
      }
    });
  };

  create_session_storage = (index, in_stock, itemOrGroup) => {
    var array_assemble = [];
    var search_item_obj = {};
    if (sessionStorage.getItem("text_search") !== null) {
      let parse = JSON.parse(sessionStorage.getItem("text_search"));
      parse.text_search.forEach(element => {
        array_assemble.push(element);
      });
    }

    var searchPhrase = null;
    if (itemOrGroup === "item") {
      searchPhrase = this.state.book_info[index].ShortTitle;
    } else if ("group") {
      searchPhrase = this.state.search_result[index].prod_group;
    }

    if (in_stock) {
      search_item_obj = {
        search_phrase: searchPhrase,
        counterpart_hub_id: this.state.shop_id,
        search_found: 1,
        search_rating: null,
        position_top: this.state.top_pos,
        position_left: this.state.left_pos,
        search_time: Math.floor(Date.now() / 1000)
      };
      array_assemble.push(search_item_obj);
    } else {
      search_item_obj = {
        search_phrase: searchPhrase,
        counterpart_hub_id: this.state.shop_id,
        search_found: 0,
        search_rating: null,
        position_top: null,
        position_left: null,
        search_time: Math.floor(Date.now() / 1000)
      };
      array_assemble.push(search_item_obj);
    }

    var object_assemble = { text_search: array_assemble };
    sessionStorage.setItem("text_search", JSON.stringify(object_assemble));

    if (
      sessionStorage.getItem("hub_token") !== null &&
      array_assemble.length > 4
    ) {
      this.save_text_search(array_assemble);
    }
  };

  save_text_search = object => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.text_search = object;
    SaveTextSearch(send_credential_to_registrar).then(result => {
      const responseJson = result;
      if (responseJson.success) {
        console.log("save successfull");
      } else {
        console.log("save error");
      }
    });
  };

  login = () => {
    if (this.state.username && this.state.password) {
      if (
        schema.validate(this.state.username) &&
        schema.validate(this.state.password)
      ) {
        LoginCall(this.state).then(result => {
          const responseJson = result;
          if (responseJson.error) {
            this.setState({
              login_validation_error: false,
              login_error: true
            });
          }
          if (responseJson.userData) {
            //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
            sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
            this.setState({
              showModal: false,
              my_hub_id: responseJson.userData.my_hub_id
            });
            if (this.state.login_from_cart) {
              this.setState({
                confirm_order: true
              });
            }
          }
        });
      } else {
        this.setState({
          login_validation_error: true,
          login_error: true
        });
      }
    }
  };

  register = () => {
    let create_new_hub = true;
    if (localStorage.getItem("newHUB") !== null) {
      let difference =
        Number(moment().unix()) - Number(localStorage.getItem("newHUB"));
      if (difference < 600) {
        let num = Math.floor(difference / 60);
        create_new_hub = false;
        this.setState({ timeLeft: num, openAlertModal: true });
      }
    }
    if (create_new_hub) {
      if (sessionStorage.getItem("hub_token") === null) {
        const send_credential_to_registrar = {
          password: "This is CreHUBmultip@ssWord!"
        };
        this.setState({
          createHubError: false,
          openCreateModal: true
        });
        CreateNewHub(send_credential_to_registrar)
          .then(result => {
            const responseJson = result;
            if (responseJson.error) {
              this.setState({
                login_validation_error: false,
                login_error: false,
                createHubError: true
              });
            }
            if (responseJson.userData) {
              //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
              sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
              this.setState({ creatingDone: true, userData: responseJson });
            }
          })
          .catch(() => {
            this.setState({ createHubError: true });
          });
      }
    }
  };

  closeModal = () => {
    this.setState({ openCreateModal: false });
  };

  closeAlertModal = () => {
    this.setState({ openAlertModal: false });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      showRegisterBtn: false,
      login_from_cart: false
    });
  };

  handleArrowClick = param => {
    switch (param) {
      case "start":
        this.setState({ batch_start: 1 }, () => {
          this.get_book_data_knv_general();
        });
        break;
      case "back":
        this.setState({ batch_start: this.state.batch_start - 20 }, () => {
          this.get_book_data_knv_general();
        });
        break;
      case "next":
        this.setState({ batch_start: this.state.batch_start + 20 }, () => {
          this.get_book_data_knv_general();
        });
        break;
      default:
        return null;
    }
  };

  navigationFilterCallback = param => {
    this.setState({ selected_filter: param });
  };

  handleButtonClick = param => {
    if (param === "list") {
      this.setState({ carouselView: false });
    } else if (param === "carousel") {
      this.setState({ carouselView: true });
    }
  };

  //= ==================================================================
  // temporary before move to new app design call profile data and get picture for bottom navigation avatar preview
  get_profile_data = () => {
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }

    getProfileBasics(send_credential_to_registrar).then(result => {
      const receiptJson = result;
      if (receiptJson.data.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState({ user_data: receiptJson.data.userData }, function() {
          this.get_avatar();
        });
      } else {
        this.get_avatar();
      }
    });
  };

  get_avatar = () => {
    if (this.state.user_data.profile_picture === null) {
      if (this.state.user_data.avatar !== null) {
        this.setState({
          avatar_to_show: `/assets/images/${this.state.user_data.avatar}`
        });
      } else {
        this.setState({
          avatar_to_show: `/assets/images/person_icon.png`
        });
      }
    } else {
      this.setState({ avatar_to_show: this.state.user_data.profile_picture });
    }
  };

  redirectHandler = () => {
    this.setState({ redirectToReferrer: true });
  };

  avatar_info_btn_callback = () => {
    this.setState({
      displayAvatarInfo: !this.state.displayAvatarInfo
    });
  };
  //= ==================================================================

  render() {
    //= ======temporary======
    if (this.state.redirectToReferrer) {
      return (
        <Redirect
          to={{
            pathname: "/cockpit",
            state: { fromNavigation: true }
          }}
        />
      );
    }

    if (this.state.my_hub_id !== null && this.state.avatar_to_show === null) {
      this.get_profile_data();
    }
    //= =============
    const { intl } = this.props;
    let login_error_block;
    if (this.state.login_error) {
      login_error_block = (
        <label id="login_error">
          <FormattedMessage id="login_error" />
        </label>
      );
    }

    let login_login_validation_error_block;
    if (this.state.login_validation_error) {
      login_login_validation_error_block = (
        <label id="login_login_validation_error">
          <FormattedMessage id="login_validation_error" />
        </label>
      );
    }

    return (
      <div id="Navigation">
        <img
          src="/assets/images/library_fake.jpg"
          alt=""
          id="navigator_background_img"
        />
        <img
          src={
            this.state.shop_basics !== null
              ? this.state.shop_basics.shop_logo_link
              : null
          }
          alt=""
          id="navigator_shop_logo"
        />
        {this.state.my_hub_id === null ? (
          <div
            className="navigator_login_register"
            onClick={() => {
              this.handleOpenModal();
            }}
          >
            Login/Register
          </div>
        ) : null}
        <div className="navigator_round_corner_background">
          <div className="navigator_floorplan_title_class">
            <FormattedMessage id="navigator_nv_title" />
          </div>
          <input
            ref={ref => (this.searchInputField = ref)}
            className="navigator_searchfield_class"
            id="navigator_searchfield_id"
            name="searched_keyword"
            type="text"
            onChange={this.handleChange}
            autoComplete="off"
            placeholder={intl.formatMessage({
              id: "navigator_nv_input_placeholder"
            })}
          />
          {this.state.searched_keyword !== "" ? (
            <span
              className="navigator_clear_X_btn"
              onClick={this.handleClearInput}
            >
              X
            </span>
          ) : null}
          <div className="navigator_suggestions_container">
            {this.state.selected_group || this.state.selected_book ? (
              <img
                alt=""
                className="navigator_return_to_search_btn"
                onClick={this.closeBookDetails}
                src="/assets/images/navigator_return_icon.png"
              />
            ) : null}
            <div className="navigator_suggestions_wrapper">
              {this.state.cat_filter.length !== 0 &&
              !this.state.selected_book &&
              !this.state.selected_group ? (
                <Filter
                  cat_filter={this.state.cat_filter}
                  navigationFilterCallback={this.navigationFilterCallback}
                  selectedFilter={this.state.selected_filter}
                  state_data={this.state}
                  handleButtonClick={this.handleButtonClick}
                />
              ) : null}

              {!this.state.loadingDB && !this.state.loadingKNV ? (
                this.state.book_info.length !== 0 ? (
                  <Suggestions
                    search_result={
                      this.state.selected_group
                        ? this.state.search_result_selected
                        : this.state.search_result
                    }
                    selected_filter={
                      this.state.cat_filter.includes(
                        this.state.selected_filter
                      ) || this.state.selected_filter === null
                        ? this.state.selected_filter
                        : "filter_not_available"
                    }
                    navigationFilterCallback={this.navigationFilterCallback}
                    book_info={
                      this.state.show_book_details
                        ? this.state.book_data
                        : this.state.book_info
                    }
                    callbackFromParent={this.suggestions_callback}
                    selectedState={
                      !!(this.state.selected_book || this.state.selected_group)
                    }
                    selected_group={this.state.selected_group}
                    carouselView={
                      this.state.show_book_details || this.state.selected_group
                        ? false
                        : this.state.carouselView
                    }
                    handleArrowClick={this.handleArrowClick}
                    showBookDetails={this.state.show_book_details}
                  />
                ) : null
              ) : null}
            </div>
            {/* {(this.state.book_info || this.state.search_result) &&
            !this.state.show_book_details ? (
              <div
                style={{ height: "80px", position: "relative", gridArea: "d" }}
              />
            ) : null} */}
          </div>
          {this.state.in_stock === false ? null : (
            <NavMap
              top_offset={this.state.top_pos}
              left_offset={this.state.left_pos}
              floor_plan={
                this.state.shop_basics !== null
                  ? this.state.shop_basics.shop_floorplan_link
                  : null
              }
            />
          )}
          {this.state.show_book_details ? (
            <BookDetails
              book_info={this.state.book_data}
              shop_id={this.state.shop_id}
              my_hub_id={this.state.my_hub_id}
              bookdetails_callback={this.bookdetails_callback}
              in_stock={this.state.in_stock}
              login_from_cart={this.state.login_from_cart}
              confirm_order={this.state.confirm_order}
              bookdetailsresetcallback={this.bookdetailsresetcallback}
            />
          ) : null}
          {/* temporary code bellow, bumper for bottom navigation */}
          {this.state.my_hub_id !== null ? (
            <div style={{ height: "60px" }} />
          ) : null}
          {/* ========================== */}
        </div>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="Minimal Modal Example"
          onRequestClose={this.handleCloseModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.5)"
            },
            content: {
              background: "rgba(255,255,255,1)",
              top: "5%",
              right: "5%",
              left: "5%",
              bottom: "5%",
              padding: "0"
            }
          }}
        >
          {this.state.openCreateModal ? (
            <CreateHubModal
              open={this.state.openCreateModal}
              setOpen={this.closeModal}
              creatingDone={this.state.creatingDone}
              setProgressBarDone={this.setProgressBarDone}
              createHubError={this.state.createHubError}
              register={this.register}
            />
          ) : null}
          {this.state.openAlertModal ? (
            <CreateHubAlert
              open={this.state.openAlertModal}
              setOpen={this.closeAlertModal}
              timeLeft={this.state.timeLeft}
            />
          ) : null}
          <span
            className="navigation_login_close"
            onClick={this.handleCloseModal}
          >
            <span>&times;</span>
          </span>
          {this.state.login_from_cart ? (
            <div className="navigation_login_confirm_message">
              <FormattedMessage id="navigator_login_confirm_message" />
            </div>
          ) : null}
          <div
            className="navigation_login_container"
            style={this.state.login_from_cart ? { top: "0" } : null}
          >
            <div className="navigation_login_main_title">
              <FormattedMessage id="login_main_title" />
            </div>
            <label className="navig_login_label">
              <FormattedMessage id="login_HUB_ID" />:
            </label>
            <input
              className="navig_login_input"
              type="text"
              name="username"
              value={this.state.username}
              onChange={this.onChange}
            />
            <label className="navig_login_label">
              <FormattedMessage id="login_PIN" />:
            </label>
            <input
              className="navig_login_input"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.onChange}
            />
            <div className="navig_login_submit" onClick={this.login}>
              <FormattedMessage id="login_submit" />
            </div>
            <div className="navigation_login_new_here_text">
              <FormattedMessage id="navigator_login_new_here" />
            </div>
            <div
              className="navigation_login_register_btn"
              onClick={this.register}
            >
              <div>
                <FormattedMessage id="navigator_login_one_click" />
              </div>
              <div>&#8702;</div>
            </div>
            {login_error_block}
            {login_login_validation_error_block}
          </div>
        </Modal>
        {/* temporary code bellow */}
        {this.state.my_hub_id !== null ? (
          <div id="BottomNavigation">
            <div id="bn_rect_bg" style={{ justifyContent: "flex-start" }}>
              <span
                style={{ marginLeft: "25px" }}
                id="bn_home_icon"
                onClick={this.redirectHandler}
              >
                <img alt="" src="/assets/images/home_icon.png" />
              </span>
              <div id="bn_circ_bg">
                <span id="bn_profile_icon">
                  <img
                    alt=""
                    onClick={this.avatar_info_btn_callback}
                    src={this.state.avatar_to_show}
                  />
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.displayAvatarInfo ? (
          <AvatarInfo
            avatar_info_btn_callback={this.avatar_info_btn_callback}
            fromNavigation={this.state.new_register}
          />
        ) : null}
        {/* ============================= */}
      </div>
    );
  }
}

export default injectIntl(Navigator);
