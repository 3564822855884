import React, { Component } from "react";
import "./BookcolSingleBook.css";
import Rating from "react-rating";
import { GetBooksData, getSideBooks } from "../../services/GetBooksData";
import { FormattedMessage } from "react-intl";
import { UpdateEntertainment } from "../../services/UpdateEntertainment";
import { GetBookDataKNV } from "../../services/FindPosition";
import Modal from "react-modal";
import AvatarEditor from "react-avatar-editor";
import { injectIntl } from "react-intl";
import Carousel from "nuka-carousel";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import update from "immutability-helper";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// bsb_ prefix for BookcolSingleBook

class BookcolSingleBook extends Component {
  state = {
    currentBook: 0,
    bookCounter: 0,
    books: [],
    loading: true,
    update_enabled: false,
    insert_enabled: false,
    form_has_changed: false,

    curr_book_id: null,
    curr_book_index: null,
    curr_book_cat: null,
    curr_books_in_cat: null,
    leftEndReached: false,
    rightEndReached: false,

    // book states for database read/write
    id: "",
    kind: "",
    title: "",
    poster: "",
    series: "",
    link: "",
    rating: 0,
    start_date: "",
    end_date: "",
    language: "",
    status: "",
    progress: "",
    subtitles_language: "",
    possession: "",
    possession_related_to: "",
    format: "",
    external_id_1: null,
    external_id_2: "",
    note: "",
    added: "",
    books_count: null,

    //sidebooks
    prevBook: null,
    nextBook: null,

    carousel_style: "",

    bookAnimation: "",
    bookSize: {
      left: "",
      middle: "",
      right: ""
    },

    // book states from KNV read only
    knvData: [],
    knvdataloaded: false,

    //avatar modal states
    image: "",
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: 160,
    height: 250,

    //slider states in miliseconds step 3600000 = 1hour, min max handled by this.handleInitialSliderValues()
    min: 0,
    max: 0,
    value: [0, 0],
    step: 3600000
  };

  myRef = React.createRef();

  onBookChange = async (event, id, bookId) => {
    const { books } = this.state;
    event.preventDefault();
    if (id !== this.state.currentBook) {
      let send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.curr_book_index = id;
      send_credential_to_registrar.books_category = this.state.curr_book_cat;
      send_credential_to_registrar.section = "book";

      // if (this.state.search_string !== null) {
      //   send_credential_to_registrar.filter_type = "search_title";
      //   send_credential_to_registrar.filter_by = this.state.search_string;
      // }

      if (this.state.sort_by !== null && this.state.sort_dir !== null) {
        send_credential_to_registrar.order_by =
          this.state.sort_by + "_" + this.state.sort_dir;
      }

      if (id >= this.state.currentBook && id !== books.length - 1) {
        const newBooks = await getSideBooks(send_credential_to_registrar);
        this.setState({
          books: update(this.state.books, {
            [id + 1]: { $set: newBooks.side_books[2] }
          })
        });
      } else if (id <= this.state.currentBook && id !== 0) {
        const newBooks = await getSideBooks(send_credential_to_registrar);
        this.setState({
          books: update(this.state.books, {
            [id - 1]: { $set: newBooks.side_books[0] }
          })
        });
      }
      this.setState({ currentBook: id, loading: true });
      this.get_books_data(bookId);
    }
  };

  onSwipeHandler = async (oldIndex, newIndex) => {
    const { books } = this.state;
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.curr_book_index = newIndex;
    send_credential_to_registrar.books_category = this.state.curr_book_cat;
    send_credential_to_registrar.section = "book";

    // if (this.state.search_string !== null) {
    //   send_credential_to_registrar.filter_type = "search_title";
    //   send_credential_to_registrar.filter_by = this.state.search_string;
    // }

    if (this.state.sort_by !== null && this.state.sort_dir !== null) {
      send_credential_to_registrar.order_by =
        this.state.sort_by + "_" + this.state.sort_dir;
    }

    if (oldIndex > newIndex && newIndex !== 0) {
      const newBooks = await getSideBooks(send_credential_to_registrar);
      this.setState({
        books: update(this.state.books, {
          [newIndex - 1]: { $set: newBooks.side_books[0] }
        })
      });
    } else if (oldIndex < newIndex && newIndex !== books.length - 1) {
      const newBooks = await getSideBooks(send_credential_to_registrar);
      this.setState({
        books: update(this.state.books, {
          [newIndex + 1]: { $set: newBooks.side_books[2] }
        })
      });
    }
    this.setState({ currentBook: newIndex, loading: true });
    this.get_books_data(books[newIndex].id);
  };

  componentDidMount() {
    this.initialImage();
    this.setState(
      {
        curr_book_id: this.props.bookID,
        curr_book_index: this.props.book_index,
        curr_book_cat: this.props.books_cat,
        curr_books_in_cat: this.props.books_in_cat,
        search_string: this.props.search_string,
        sort_by: this.props.sort_by,
        sort_dir: this.props.sort_dir
      },
      () => {
        this.get_books_data(this.state.curr_book_id);
        this.get_side_books();
      }
    );
    window.scrollTo(0, 0);
  }

  get_books_data = bookID => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.bookID = bookID;
    send_credential_to_registrar.book_to_show = this.state.book_to_show;
    send_credential_to_registrar.filter = this.state.filter;
    send_credential_to_registrar.section = "book";
    GetBooksData(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.book) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState(
          {
            id: receiptJson.book.id,
            kind: receiptJson.book.kind,
            title: receiptJson.book.title,
            poster: receiptJson.book.poster,
            series: receiptJson.book.series,
            link: receiptJson.book.link,
            rating: receiptJson.book.rating,
            start_date: receiptJson.book.start_date,
            end_date: receiptJson.book.end_date,
            language: receiptJson.book.language,
            status: receiptJson.book.status,
            progress: receiptJson.book.progress,
            subtitles_language: receiptJson.book.subtitles_language,

            possession: receiptJson.book.possession,
            possession_related_to: receiptJson.book.possession_related_to,

            format: receiptJson.book.format,
            external_id_1: receiptJson.book.external_id_1,
            external_id_2: receiptJson.book.external_id_2,
            note: receiptJson.book.note,
            books_count: receiptJson.books_count,
            added: receiptJson.book.created_on
          },
          () => {
            this.get_book_data_knv_general(this.state.external_id_1);
            this.handleInitialSliderValues();
          }
        );
      }
    });
  };

  get_book_data_knv_general = param => {
    if (param === null) {
      this.setState({ loading: false, knvdataloaded: false });
      return;
    }
    let send_credential_to_registrar = {
      search_string: param
    };
    if (this.state.sessionID !== null) {
      send_credential_to_registrar.sessionID = this.state.sessionID;
    }
    GetBookDataKNV(send_credential_to_registrar).then(result => {
      if (result.book_info && result.book_info !== "charTypeErr") {
        this.setState(
          {
            knvData: result.book_info,
            knvPages: parseInt(result.book_info[0].Pages),
            sessionID: result.sessionID
          },
          () => {
            this.setState({ loading: false, knvdataloaded: true });
          }
        );
      } else {
        console.log("knv error");
        this.setState({ loading: false, knvdataloaded: false });
      }
    });
  };

  initialImage = () => {
    const image = [];
    for (let i = 0; i < this.props.books_in_cat; i++) {
      image.push({ poster: "/assets/images/loading.gif" });
    }
    this.setState({ books: image });
  };

  get_side_books = async () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.curr_book_index = this.state.curr_book_index;
    send_credential_to_registrar.books_category = this.state.curr_book_cat;
    send_credential_to_registrar.section = "book";

    // if (this.state.search_string !== null) {
    //   send_credential_to_registrar.filter_type = "search_title";
    //   send_credential_to_registrar.filter_by = this.state.search_string;
    // }

    if (this.state.sort_by !== null && this.state.sort_dir !== null) {
      send_credential_to_registrar.order_by =
        this.state.sort_by + "_" + this.state.sort_dir;
    }

    const books = await getSideBooks(send_credential_to_registrar);
    const cbook = this.state.curr_book_index;
    if (this.state.curr_book_index === 0) {
      this.setState({
        currentBook: this.state.curr_book_index,
        books: update(this.state.books, {
          [cbook]: { $set: books.side_books[0] },
          [cbook + 1]: { $set: books.side_books[1] }
        })
      });
    } else if (cbook === this.state.curr_books_in_cat - 1) {
      this.setState({
        currentBook: cbook,
        books: update(this.state.books, {
          [cbook]: { $set: books.side_books[1] },
          [cbook - 1]: { $set: books.side_books[0] }
        })
      });
    } else {
      this.setState({
        currentBook: cbook,
        books: update(this.state.books, {
          [cbook]: { $set: books.side_books[1] },
          [cbook - 1]: { $set: books.side_books[0] },
          [cbook + 1]: { $set: books.side_books[2] }
        })
      });
    }
  };

  set_rating = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.passed_id = this.state.id;
    send_credential_to_registrar.kind = this.state.kind;
    send_credential_to_registrar.title = this.state.title;
    send_credential_to_registrar.poster = this.state.poster;
    send_credential_to_registrar.series = this.state.series;
    send_credential_to_registrar.link = this.state.link;
    send_credential_to_registrar.rating = this.state.rating;
    send_credential_to_registrar.start_date = this.state.start_date;
    send_credential_to_registrar.end_date = this.state.end_date;
    send_credential_to_registrar.language = this.state.language;
    send_credential_to_registrar.status = this.state.status;
    send_credential_to_registrar.progress = this.state.progress;
    send_credential_to_registrar.subtitles_language = this.state.subtitles_language;
    send_credential_to_registrar.possession = this.state.possession;
    send_credential_to_registrar.possession_related_to = this.state.possession_related_to;
    send_credential_to_registrar.format = this.state.format;
    send_credential_to_registrar.external_id_1 = this.state.external_id_1;
    send_credential_to_registrar.external_id_2 = this.state.external_id_2;
    send_credential_to_registrar.note = this.state.note;

    UpdateEntertainment(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.success) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.get_books_data(this.state.curr_book_id);
        this.handleInitialSliderValues();
        this.setState({ form_has_changed: false });
        this.get_side_books();
      }
    });
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  enableUpdate = () => {
    if (this.state.update_enabled === true) {
      this.setState({ update_enabled: false });
    } else {
      this.setState({ update_enabled: true });
    }
    this.get_books_data(this.state.curr_book_id);
    this.setState({ insert_enabled: false });
  };

  cancelEdit = () => {
    this.setState({ update_enabled: false });
    this.setState({ insert_enabled: false });
    this.get_books_data(this.state.curr_book_id);
    this.get_side_books();
  };

  saveUpdatedValues = () => {
    if (this.state.form_has_changed === true) {
      this.set_rating();
    }
    this.setState({ update_enabled: false });
  };

  inputHandleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, function() {
      this.setState({ form_has_changed: true });
    });
  };

  progressinputHandleChange = event => {
    if (event.target.value > -1 && event.target.value <= this.state.knvPages) {
      this.setState({ [event.target.name]: event.target.value }, function() {
        this.setState({ form_has_changed: true });
      });
    }
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOpenPosterModal = () => {
    this.setState({ showPosterModal: true });
  };

  handleClosePosterModal = () => {
    this.setState({ showPosterModal: false });
  };

  scanner_callback = (ISBN, closeModal, formChange) => {
    this.setState({
      external_id_1: ISBN,
      showModal: closeModal,
      form_has_changed: formChange
    });
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] });
  };

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState(
      {
        preview: {
          img,
          rect,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          borderRadius: this.state.borderRadius
        }
      },
      function() {
        this.setState({
          poster: this.state.preview.img,
          showPosterModal: false,
          form_has_changed: true
        });
      }
    );
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  logCallback = e => {
    // eslint-disable-next-line
    console.log("callback", e);
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  // 2019-08-15
  handleStartEndReadingBtn = param => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    if (param === "start") {
      this.setState(
        { start_date: today, status: "reading", form_has_changed: true },
        () => {
          this.set_rating();
        }
      );
    } else if (param === "finish") {
      this.setState(
        { end_date: today, status: "finished", form_has_changed: true },
        () => {
          this.set_rating();
        }
      );
    }
  };

  handleInitialSliderValues = () => {
    //min max +/- offset is 604800000 miliseconds = 1 week

    let endDateMax = new Date(this.state.end_date).getTime() + 604800000;
    let endDate = new Date(this.state.end_date).getTime();
    if (this.state.end_date === null) {
      endDateMax =
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ).getTime() + 604800000;
      endDate = new Date(this.state.start_date).getTime();
    }
    this.setState(
      {
        min: new Date(this.state.start_date).getTime() - 604800000,
        max: endDateMax,
        value: [new Date(this.state.start_date).getTime(), endDate]
      }
      // () => {
      //   console.log(this.state.min);
      //   console.log(this.state.max);
      //   console.log(this.state.value);
      // }
    );
  };

  onSliderChange = value => {
    // console.log(value);
    this.setState(
      {
        value
      },
      () => {
        let startDate = new Date(this.state.value[0])
          .toISOString()
          .substring(0, 10);
        let endDate = new Date(this.state.value[1])
          .toISOString()
          .substring(0, 10);

        this.setState(
          {
            start_date: startDate,
            end_date: endDate,
            form_has_changed: true
          },
          () => {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.set_rating();
            }, 1000);
          }
        );
      }
    );
  };

  render() {
    const { intl } = this.props;
    let read_only_stars;

    let update_class;
    let button_save_invisible;
    let input_books_disabled;
    let button_cancel_invisible;
    // let button_scan_ISBN;
    let button_add_poster_invisible;
    // let no_books;
    // let ribbon_img;
    // let endDate;
    // let startDate;
    let numOfDays;
    // let input_date_invisible;
    // let timeline_date;

    let cover_image_link;
    if (this.state.external_id_1 === null && this.state.poster === null) {
      cover_image_link = "/assets/images/missing-image.png";
    } else {
      if (this.state.poster === null) {
        cover_image_link = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${this.state.external_id_1}&size=m`;
      } else {
        cover_image_link = this.state.poster;
      }
    }

    if (this.state.start_date !== null && this.state.end_date !== null) {
      let start = Date.parse(this.state.start_date);
      let end = Date.parse(this.state.end_date);
      if (end > start) {
        numOfDays = (end - start) / 1000 / 60 / 60 / 24;
      }
    }

    // let prev_image_link;
    // if (this.state.prevBook !== null) {
    //   if (
    //       this.state.prevBook.external_id_1 === null &&
    //       this.state.prevBook.poster === null
    //   ) {
    //     prev_image_link = "/assets/images/missing-image.png";
    //   } else {
    //     if (this.state.prevBook.poster === null) {
    //       prev_image_link = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${
    //           this.state.prevBook.external_id_1
    //           }&size=m`;
    //     } else {
    //       prev_image_link = this.state.prevBook.poster;
    //     }
    //   }
    // } else {
    //   prev_image_link = "/assets/images/missing-image.png";
    // }
    //
    // let next_image_link;
    // if (this.state.nextBook !== null) {
    //   if (
    //       this.state.nextBook.external_id_1 === null &&
    //       this.state.nextBook.poster === null
    //   ) {
    //     next_image_link = "/assets/images/missing-image.png";
    //   } else {
    //     if (this.state.nextBook.poster === null) {
    //       next_image_link = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${
    //           this.state.nextBook.external_id_1
    //           }&size=m`;
    //     } else {
    //       next_image_link = this.state.nextBook.poster;
    //     }
    //   }
    // } else {
    //   next_image_link = "/assets/images/missing-image.png";
    // }

    if (this.state.update_enabled === true) {
      update_class = "bsb_mark_inputs_as_editable";
      input_books_disabled = "";
    } else {
      button_save_invisible = "button_invisible_class";
      input_books_disabled = "disabled";
    }

    if (
      this.state.insert_enabled === true ||
      this.state.update_enabled === true
    ) {
      // timeline_date = "invisible_by_display_none";
      // input_date_invisible = "";
      // button_add_poster_invisible = "";
      read_only_stars = false;
      button_cancel_invisible = "";
      // button_scan_ISBN = "";
    } else {
      // timeline_date = "";
      // input_date_invisible = "invisible_by_display_none";
      // button_add_poster_invisible = "button_invisible_class";
      read_only_stars = true;
      // button_scan_ISBN = "button_invisible_class";
      button_cancel_invisible = "button_invisible_class";
    }

    let progress_calc;
    if (this.state.progress !== "" && this.state.knvdataloaded !== false) {
      let prog = Number(this.state.progress);
      if (this.state.progress === null) {
        prog = 0;
      }
      progress_calc = (100 / this.state.knvPages) * prog;
    } else {
      progress_calc = 0;
    }

    // let hidePrevBook;
    // if (this.state.leftEndReached) {
    //   hidePrevBook = "hidden";
    // }
    // let hideNextBook;
    // if (this.state.rightEndReached) {
    //   hideNextBook = "hidden";
    // }

    const { books } = this.state;

    return (
      <div id="BookcolSingleBook">
        <img
          id="bsb_top_bookshelf_bg"
          alt=""
          src="/assets/images/library_img_2.jpg"
        />
        <img
          className="bsb_top_back_arrow"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={this.props.closeSingleCallback}
        />
        <div className="bsb_round_corner_background">
          <div className="bsb_books_count">
            {this.state.currentBook + 1}/{this.state.curr_books_in_cat}
          </div>
          <div style={this.state.update_enabled ? { display: "none" } : null}>
            <Carousel
              slideIndex={this.state.currentBook}
              slidesToShow={3}
              beforeSlide={(oldIndex, newIndex) => {
                this.onSwipeHandler(oldIndex, newIndex);
              }}
              dragging={true}
              slidesToScroll={1}
              cellAlign="center"
              withoutControls={true}
              swiping={true}
            >
              {books.map((book, id) => (
                <div
                  key={id}
                  style={{
                    width: "inherit",
                    boxShadow: "none",
                    display: "flex"
                  }}
                  onClick={event => this.onBookChange(event, id, book.id)}
                >
                  <img
                    key={id}
                    className={
                      this.state.currentBook === id
                        ? "carousel-image carousel-style"
                        : this.state.currentBook < id
                        ? "carousel-image img-left"
                        : "carousel-image img-right"
                    }
                    src={
                      book.poster ||
                      `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${book.external_id_1}&size=s`
                    }
                    alt=""
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = "/assets/images/missing-image.png";
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <span
            style={this.state.update_enabled ? null : { display: "none" }}
            className="bsb_book_img_wrapper"
          >
            <div className="bsb_curr_book_img_wrapper">
              <img
                className="bsb_curr_book_img"
                alt=""
                src={cover_image_link}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "assets/images/missing-image.png";
                }}
              />
            </div>

            <span
              className={"button_add_poster " + button_add_poster_invisible}
              onClick={this.handleOpenPosterModal}
            >
              +
            </span>
          </span>
          {this.state.loading ? (
            <div className="bsb_loading_wrapper">
              <img
                className="bsb_top_loading fixPositionLoading"
                alt="loading"
                src="/assets/images/loading.gif"
              />
            </div>
          ) : (
            <>
              <div className="bsb_book_data_wrapper">
                <div className="bsb_book_data_content_container">
                  <div className="bsb_book_title_and_edit">
                    <textarea
                      disabled={input_books_disabled}
                      className={update_class + " bsb_book_title"}
                      type="text"
                      rows="2"
                      name="title"
                      placeholder={intl.formatMessage({
                        id: "bookcoll_plhold_title"
                      })}
                      maxLength="75"
                      value={this.checkIfNull(this.state.title)}
                      onChange={this.inputHandleChange}
                    />
                    <img
                      onClick={this.enableUpdate}
                      alt=""
                      id="bsb_edit_pencil"
                      src="/assets/images/edit_pencil_black.png"
                    />
                  </div>
                  <div className="bsb_book_author">
                    {this.state.knvdataloaded
                      ? this.state.knvData[0].Author
                      : null}
                  </div>
                  <div className={"Rating_stars " + update_class}>
                    <Rating
                      initialRating={this.state.rating}
                      stop={10}
                      onClick={rate =>
                        this.setState({ rating: rate }, function() {
                          this.setState({ form_has_changed: true });
                        })
                      }
                      readonly={read_only_stars}
                      emptySymbol={<span className="star">&#x2606;</span>}
                      fullSymbol={<span className="star">&#x2605;</span>}
                    />
                  </div>
                  <div className="bsb_border_box">
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_isbn" />:{" "}
                      </strong>{" "}
                      {this.state.external_id_1}
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_format" />:{" "}
                      </strong>{" "}
                      {this.state.format}
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_publisher" />:{" "}
                      </strong>{" "}
                      {this.state.knvdataloaded
                        ? this.state.knvData[0].Publisher
                        : null}
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_language" />:{" "}
                      </strong>{" "}
                      {this.state.language}
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_pages" />:{" "}
                      </strong>{" "}
                      {this.state.knvdataloaded ? this.state.knvPages : null}
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage id="bookcol_single_book_added" />:{" "}
                      </strong>{" "}
                      {this.state.added.slice(0, 10)}
                    </div>
                  </div>
                  <div className="bsb_box">
                    <div>
                      <div className="bsb_prog_bar_base">
                        <div
                          className="bsb_prog_bar_runner"
                          style={{
                            width: progress_calc + "%"
                          }}
                        >
                          <div className="bsb_prog_bar_text">
                            <FormattedMessage id="bookcol_single_book_read_progress" />
                            : {this.state.progress}/
                            {this.state.knvdataloaded
                              ? this.state.knvPages
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.update_enabled === true &&
                    this.state.knvdataloaded === true ? (
                      <div>
                        <strong>
                          <FormattedMessage id="bookcol_single_book_progress" />
                          :{" "}
                        </strong>
                        <input
                          onChange={this.progressinputHandleChange}
                          className={"bsb_bottom_box_input " + update_class}
                          type="number"
                          name="progress"
                          placeholder={intl.formatMessage({
                            id: "bookcoll_plhold_status"
                          })}
                          title="status"
                          value={this.checkIfNull(this.state.progress)}
                        />
                      </div>
                    ) : null}
                    {this.state.start_date !== null &&
                    this.state.end_date !== null ? null : (
                      <div className="bsb_box_start_end_btn_wrapper">
                        {this.state.start_date === null ? (
                          <span
                            className="bsb_box_start_btn"
                            onClick={() => {
                              this.handleStartEndReadingBtn("start");
                            }}
                          >
                            <FormattedMessage id="bookcol_single_book_start_reading" />
                          </span>
                        ) : null}

                        {this.state.end_date === null &&
                        this.state.start_date !== null ? (
                          <span
                            className="bsb_box_end_btn"
                            onClick={() => {
                              this.handleStartEndReadingBtn("finish");
                            }}
                          >
                            <FormattedMessage id="bookcol_single_book_finish_reading" />
                          </span>
                        ) : null}
                      </div>
                    )}
                    <div className="bsb_slider_container">
                      <div className="bsb_slider_daystotal">
                        {numOfDays}{" "}
                        <FormattedMessage id="bookcol_single_book_num_of_days" />
                      </div>
                      <Range
                        allowCross={false}
                        value={this.state.value}
                        onChange={this.onSliderChange}
                        min={this.state.min}
                        max={this.state.max}
                        step={this.state.step}
                        tipFormatter={value =>
                          `${new Date(value).toLocaleString()}`
                        }
                      />
                      <div className="bsb_slider_startToEnd">
                        <span>
                          <FormattedMessage id="bookcol_single_book_start" />:{" "}
                          {this.state.start_date}
                        </span>
                        <span>
                          <FormattedMessage id="bookcol_single_book_finish" />:{" "}
                          {this.state.end_date}
                        </span>
                      </div>
                    </div>
                    <div
                      className="bsb_box_inner_content_wrapper"
                      style={{ marginTop: "20px" }}
                    >
                      <strong>
                        <FormattedMessage id="bookcol_single_book_note" />:{" "}
                      </strong>
                      <div className="bsb_box_inner_content">
                        <textarea
                          disabled={input_books_disabled}
                          className={
                            "bsb_bottom_box_input bsb_bottom_box_textarea " +
                            update_class
                          }
                          type="text"
                          name="note"
                          placeholder={intl.formatMessage({
                            id: "bookcoll_plhold_note"
                          })}
                          maxLength="500"
                          value={this.checkIfNull(this.state.note)}
                          onChange={this.inputHandleChange}
                        />
                      </div>
                    </div>

                    <div className="bsb_box_inner_content_wrapper">
                      <strong>
                        <FormattedMessage id="bookcol_single_book_possession" />
                        :{" "}
                      </strong>
                      <div className="bsb_box_inner_content">
                        <select
                          disabled={input_books_disabled}
                          className={"bsb_bottom_box_input " + update_class}
                          value={this.checkIfNull(this.state.possession)}
                          onChange={this.inputHandleChange}
                          name="possession"
                        >
                          <option value="own">
                            {intl.formatMessage({ id: "bookcoll_option_own" })}
                          </option>
                          <option value="gift to">
                            {intl.formatMessage({
                              id: "bookcoll_option_gift_to"
                            })}
                          </option>
                          <option value="gift from">
                            {intl.formatMessage({
                              id: "bookcoll_plhold_gift_from"
                            })}
                          </option>
                          <option value="lent">
                            {intl.formatMessage({ id: "bookcoll_plhold_lent" })}
                          </option>
                          <option value="borrowed">
                            {intl.formatMessage({
                              id: "bookcoll_plhold_borrowed"
                            })}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="bsb_box_inner_content_wrapper">
                      <strong>
                        <FormattedMessage id="bookcol_single_book_possession_rel_to" />
                        :{" "}
                      </strong>
                      <div className="bsb_box_inner_content">
                        <input
                          disabled={input_books_disabled}
                          onChange={this.inputHandleChange}
                          className={"bsb_bottom_box_input " + update_class}
                          type="text"
                          name="possession_related_to"
                          placeholder={intl.formatMessage({
                            id: "bookcoll_plhold_possession_rel_to"
                          })}
                          title="possession related to"
                          value={this.checkIfNull(
                            this.state.possession_related_to
                          )}
                        />
                      </div>
                    </div>

                    {/* <div>
                      <strong>Status: </strong>
                      <input
                        disabled={input_books_disabled}
                        onChange={this.inputHandleChange}
                        className={"bsb_bottom_box_input " + update_class}
                        type="text"
                        name="status"
                        placeholder={intl.formatMessage({
                          id: "bookcoll_plhold_status"
                        })}
                        title="status"
                        value={this.checkIfNull(this.state.status)}
                      />
                    </div> */}

                    <div className="bookcollector_bottom_navigation">
                      <button
                        onClick={this.saveUpdatedValues}
                        className={button_save_invisible}
                      >
                        <FormattedMessage id="bookcoll_save_button" />
                      </button>
                      {/* <button
                        onClick={this.insertNewEntertainment}
                        className={button_add_invisible}
                      >
                        <FormattedMessage id="bookcoll_add_button" />
                      </button> */}
                      <button
                        onClick={this.cancelEdit}
                        className={
                          "button_cancel_class " + button_cancel_invisible
                        }
                      >
                        <FormattedMessage id="bookcoll_cancel_button" />
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "60px"
                    }}
                  />
                </div>
              </div>

              <Modal
                isOpen={this.state.showPosterModal}
                contentLabel="Minimal Modal Example"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0,0,0,0.5)"
                  },
                  content: {
                    background: "rgba(255,255,255,0.6)",
                    top: "3%",
                    right: "3%",
                    left: "3%",
                    bottom: "3%"
                  }
                }}
              >
                <span
                  className="modal_info close"
                  onClick={this.handleClosePosterModal}
                  id="info_close"
                >
                  <span>&times;</span>
                </span>
                <div id="avatar_grid_container">
                  <div id="avatar_editor_preview">
                    <AvatarEditor
                      ref={this.setEditorRef}
                      scale={parseFloat(this.state.scale)}
                      width={this.state.width}
                      height={this.state.height}
                      position={this.state.position}
                      onPositionChange={this.handlePositionChange}
                      rotate={parseFloat(this.state.rotate)}
                      borderRadius={
                        this.state.width / (100 / this.state.borderRadius)
                      }
                      onLoadFailure={this.logCallback.bind(
                        this,
                        "onLoadFailed"
                      )}
                      onLoadSuccess={this.logCallback.bind(
                        this,
                        "onLoadSuccess"
                      )}
                      onImageReady={this.logCallback.bind(this, "onImageReady")}
                      image={this.state.image}
                      style={{
                        width: "100%",
                        height: "auto",
                        gridArea: "left1",
                        margin: "auto 0"
                      }}
                      className="avatar_editor_canvas"
                    />
                    <div className="avatar_editor_rotate">
                      <button onClick={this.rotateRight}>&#8635;</button>
                    </div>

                    <div className="avatar_editor_zoom">
                      <input
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min={this.state.allowZoomOut ? "0.1" : "1"}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                      />
                    </div>
                  </div>

                  <div className="avatar_grid_right_column_grid_container">
                    <div className="avatar_editor_selectfile">
                      <input
                        name="newImage"
                        type="file"
                        onChange={this.handleNewImage}
                      />
                    </div>

                    <div className="avatar_editor_submit">
                      <input
                        type="button"
                        onClick={this.handleSave}
                        value="Upload"
                      />
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(BookcolSingleBook);
