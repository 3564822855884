import React, { Component } from "react";
import "../../screens/Navigator/Navigator.css";
import { ProdGroupImgAndName } from "./ProductGroupFunction";

class Suggestions extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let search_result;
    if (this.props.search_result && this.props.carouselView) {
      search_result = this.props.search_result.map((r, i) => (
        <div
          className="suggestions_group_carousel_item"
          key={i}
          onClick={() => {
            this.props.callbackFromParent(
              i,
              r.position_top,
              r.position_left,
              "search_result"
            );
          }}
        >
          <div className="suggestions_group_carousel_img_wrapper">
            {r.prod_group}
          </div>
        </div>
      ));
    } else if (this.props.search_result) {
      search_result = this.props.search_result.map((r, i) => (
        <div
          onClick={
            this.props.selected_group || this.props.showBookDetails
              ? null
              : () => {
                  this.props.callbackFromParent(
                    i,
                    r.position_top,
                    r.position_left,
                    "search_result"
                  );
                }
          }
          className="suggest_dropd_item"
          key={r.id}
        >
          <nobr>{r.prod_group}</nobr>
        </div>
      ));
    }

    let book_info;
    if (this.props.book_info && this.props.carouselView) {
      book_info = this.props.book_info.map((r, i) => {
        let ean = r.EAN;
        if (r.EAN.constructor === Array) {
          ean = r.EAN[0];
        }
        return (
          <div
            className="suggestions_carousel_item"
            key={i}
            style={
              this.props.selected_filter !== null &&
              this.props.selected_filter !== r.SortimentType
                ? { display: "none" }
                : null
            }
          >
            <div className="suggestions_carousel_img_wrapper">
              <img
                onClick={() => {
                  this.props.callbackFromParent(i, null, null, "book_info");
                }}
                className="suggestions_carousel_item_img"
                alt=""
                src={
                  "https://portal.dnb.de/opac/mvb/cover.htm?isbn=" +
                  ean +
                  "&size=m"
                }
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/missing-image.png";
                }}
              />
            </div>
            <div className="suggestions_carousel_item_price">
              <span>
                <img
                  className="suggest_icon_img"
                  alt=""
                  src={ProdGroupImgAndName("img", r.SortimentType)}
                  title={ProdGroupImgAndName("name", r.SortimentType)}
                />
              </span>
              <span>{r.PriceGermany} €</span>
            </div>
          </div>
        );
      });
    } else if (this.props.book_info) {
      book_info = this.props.book_info.map((r, i) => {
        var color;
        if (Math.ceil((i + 1) / 5) % 2 === 0) {
          color = "#f9f993";
        } else {
        }

        return (
          <div
            onClick={
              this.props.selected_group || this.props.showBookDetails
                ? null
                : () => {
                    this.props.callbackFromParent(i, null, null, "book_info");
                  }
            }
            className={"suggest_dropd_item " + color}
            key={i}
            style={
              this.props.selected_filter !== null &&
              this.props.selected_filter !== r.SortimentType
                ? { display: "none" }
                : { backgroundColor: color }
            }
          >
            <nobr>
              <span>
                <img
                  className="suggest_icon_img"
                  alt=""
                  src={ProdGroupImgAndName("img", r.SortimentType)}
                  title={ProdGroupImgAndName("name", r.SortimentType)}
                />
              </span>
              <span>
                {r.ShortTitle}, {r.Year}
              </span>
            </nobr>
          </div>
        );
      });
    }

    return (
      <div
        id={
          this.props.selectedState === true
            ? "Suggestions_selected"
            : "Suggestions"
        }
      >
        {this.props.selected_filter === "filter_not_available" ? (
          <span>Change filter please</span>
        ) : null}

        {this.props.book_info &&
        this.props.carouselView &&
        !this.props.showBookDetails ? (
          <div className="suggestions_carousel_container">
            <div className="suggestions_carousel_wrapper">
              {this.props.search_result ? search_result : null}
              {book_info}
              <div className="suggestions_load_carousel_item">
                <div
                  onClick={() => {
                    this.props.handleArrowClick("next");
                  }}
                  className="suggestions_load_carousel_img_wrapper"
                >
                  <img
                    className="suggest_load_more"
                    alt=""
                    src="/assets/images/left_arrow_icon.png"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {this.props.showBookDetails ? null : search_result}
            {this.props.selected_group ? null : book_info}
          </div>
        )}

        {this.props.book_info &&
        !this.props.carouselView &&
        !this.props.showBookDetails &&
        !this.props.selected_group ? (
          <div className="suggest_dropd_item_next_arrow">
            <img
              onClick={() => {
                this.props.handleArrowClick("next");
              }}
              className="suggest_load_more_v2"
              alt=""
              src="/assets/images/left_arrow_icon_v2.png"
            />
            {this.props.selected_filter !== null ? (
              <img
                className="suggest_icon_img_filter"
                alt=""
                src="/assets/images/filter_icon_close.png"
                onClick={() => {
                  this.props.navigationFilterCallback(null);
                }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Suggestions;
