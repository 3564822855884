import React from "react";
import "../../../screens/Receipt/ReceiptSingle.css";
// import "../receipt_with_imgs.css";
// import "../receipt_form_navigation.css";
// import "../receipt_blank.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const ReceiptForm = ({
  state,
  rows,
  handleHideReceipt,
  vat_rows,
  cash_to_return_class
}) => {
  return (
    <div id="receipt_form2">
      <div className={state.curentCss + "receipt_grid_container"}>
        <button
          className={state.curentCss + "spacer0"}
          onClick={handleHideReceipt}
        >
          {state.receiptHide ? (
            <ExpandMoreIcon fontSize="large" />
          ) : (
            <ExpandLessIcon fontSize="large" />
          )}
        </button>
        <hr className={state.curentCss + "spacer1"} />
        <hr className={state.curentCss + "spacer2"} />
        <hr className={state.curentCss + "spacer3"} />
        <hr className={state.curentCss + "spacer4"} />

        {state.receiptHide ? null : (
          <>
            <div id={state.curentCss + "HEADER_LINE_1"} />
            <div id={state.curentCss + "HEADER_LINE_2"} />
            <div id={state.curentCss + "HEADER_LINE_3"} />
            <div id={state.curentCss + "HEADER_LINE_4"} />
            <div id={state.curentCss + "HEADER_LINE_5"} />
            <div id={state.curentCss + "HEADER_LINE_6"} />
            <div id={state.curentCss + "HEADER_LINE_7"} />
            <div id={state.curentCss + "HEADER_LINE_8"} />
            <div id={state.curentCss + "HEADER_LINE_9"} />
            <div id={state.curentCss + "HEADER_LINE_10"} />
          </>
        )}
        <div id={state.curentCss + "HEADER_CASHIER_OPERATOR"}>
          {state.receipt_data.cashier}
        </div>
        <div id={state.curentCss + "HEADER_DAY_NAME"}>Pon</div>
        <div id={state.curentCss + "HEADER_DATE_DELIM_DOT"}>
          {state.receipt_data.issue_date_DMY_delimit_dot}
        </div>
        <div id={state.curentCss + "HEADER_DATE_DELIM_HYPHEN"}>
          {state.receipt_data.issue_date_DMY_delimit_hyphen}
        </div>
        <div id={state.curentCss + "HEADER_TIME_HMS_DELIM_COL"}>
          {state.receipt_data.issue_time_HMS_delimit_colon}
        </div>
        <div id={state.curentCss + "HEADER_TIME_HM_DELIM_COL"}>
          {state.receipt_data.issue_time_HM_delimit_colon}
        </div>
        <div id={state.curentCss + "HEADER_RECEIPT_ID"} />
        <div id={state.curentCss + "HEADER_RECEIPT_NUM_1"}>XXXX</div>
        <div id={state.curentCss + "HEADER_RECEIPT_NUM_2"}>XXXX</div>
        <div id={state.curentCss + "HEADER_LOCATION_1"}>11</div>
        <div id={state.curentCss + "HEADER_LOCATION_2"}>11</div>
        <div id={state.curentCss + "HEADER_CASHIER_NUM_1"}>1</div>
        <div id={state.curentCss + "HEADER_CASHIER_NUM_2"}>1</div>

        <main className={state.curentCss + "receipt_content"}>
          <div id={state.curentCss + "SHOP_GOODS_NAME"} />
          <div id={state.curentCss + "SHOP_GOODS_AMOUNT"} />
          <div id={state.curentCss + "SHOP_UNIT_PRICE"} />
          <div id={state.curentCss + "SHOP_TAX_DESC"} />
          <div id={state.curentCss + "SHOP_PRICE_AND_CURRENCY"} />
        </main>

        <main className={state.curentCss + "item_wrapper"}>{rows}</main>
        <div id={state.curentCss + "ITEMS_COUNT_DESC"} />
        <div id={state.curentCss + "ITEMS_COUNT_NO_DECIMALS"}>
          {Number(state.receipt_data.count_articles).toFixed(0)}
        </div>
        <div id={state.curentCss + "ITEMS_COUNT_ONE_DECIMAL"}>
          {Number(state.receipt_data.count_articles).toFixed(1)}
        </div>
        <div id={state.curentCss + "ITEMS_COUNT_TWO_DECIMAL"}>
          {Number(state.receipt_data.count_articles).toFixed(2)}
        </div>

        <div id={state.curentCss + "ROUNDING_DESC"} />
        <div id={state.curentCss + "ROUNDING"}>0.00</div>

        <div id={state.curentCss + "TAX_BASE_DESC"} />
        <div id={state.curentCss + "TAX_NAME_DESC"} />
        <div id={state.curentCss + "TAX_SUM_DESC"} />

        <div id={state.curentCss + "FOOTER_SUM_DESC"} />
        <div id={state.curentCss + "CART_TOTAL_PRICE"}>
          {state.receipt_data.big_total_price}
        </div>

        <main className={state.curentCss + "vat_grid_wrapper"}>{vat_rows}</main>

        <div id={state.curentCss + "BIG_TOTAL_PRICE_DESC"} />
        <div id={state.curentCss + "BIG_TOTAL_PRICE"}>
          {state.receipt_data.big_total_price}
        </div>

        <div id={state.curentCss + "SUM_WITHOUT_VAT_BIG_TOTAL_DESC"} />
        <div id={state.curentCss + "SUM_WITHOUT_VAT_BIG_TOTAL"}>
          {state.receipt_data.sum_without_vat_big_total}
        </div>
        <div id={state.curentCss + "SUM_VAT_BIG_TOTAL_DESC"} />
        <div id={state.curentCss + "SUM_VAT_BIG_TOTAL"}>
          {state.receipt_data.sum_vat_big_total}
        </div>

        <div id={state.curentCss + "CASH_SUM_TAKEN_DESC"} />
        <div id={state.curentCss + "CASH_SUM_TAKEN"}>
          {state.receipt_data.cash_received}
        </div>

        <div
          className={state.curentCss + cash_to_return_class}
          id={state.curentCss + "CASH_TO_RETURN_DESC"}
        />
        <div
          className={state.curentCss + cash_to_return_class}
          id={state.curentCss + "CASH_TO_RETURN"}
        >
          {state.receipt_data.cash_to_return}
        </div>

        <div id={state.curentCss + "FOOTER_RECEIPT_NUM_DESC"} />
        <div id={state.curentCss + "FOOTER_RECEIPT_NUM"}>0</div>

        <div id={state.curentCss + "CASHIER_IDENTIFIER_1"} />
        <div id={state.curentCss + "CASHIER_IDENTIFIER_2"}>1561626261</div>
        <div id={state.curentCss + "CASHIER_IDENTIFIER_2"}>515611621</div>
        <div id={state.curentCss + "PAYMENT_METHOD"}>
          {state.receipt_data.payment_method}
        </div>
        <div id={state.curentCss + "PAYMENT_REGISTRATION_TYPE"}>online</div>
        {state.receiptHide ? null : (
          <>
            <div id={state.curentCss + "FOOTER_LINE_1"} />
            <div id={state.curentCss + "FOOTER_LINE_2"} />
            <div id={state.curentCss + "FOOTER_LINE_3"} />
            <div id={state.curentCss + "FOOTER_LINE_4"} />
            <div id={state.curentCss + "FOOTER_LINE_5"} />
            <div id={state.curentCss + "FOOTER_LINE_6"} />
            <div id={state.curentCss + "FOOTER_LINE_7"} />
          </>
        )}
      </div>
    </div>
  );
};

export default ReceiptForm;
