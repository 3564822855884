import React from "react";
import Scanner from "./Scanner";

class ScanApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanning: true,
      results: []
    };
    this._onDetected = this._onDetected.bind(this);
  }
  render() {
    return (
      <div>
        {this.state.scanning ? <Scanner onDetected={this._onDetected} /> : null}
      </div>
    );
  }

  _onDetected(result) {
    if (this.state.results.length < 4) {
      this.setState({ results: this.state.results.concat([result]) });
    } else {
      if (
        this.state.results[0].codeResult.code ===
          this.state.results[1].codeResult.code &&
        this.state.results[1].codeResult.code ===
          this.state.results[2].codeResult.code
      ) {
        // console.log("all are same");
        this.props.callbackFromParent(
          this.state.results[0].codeResult.code,
          false,
          true
        );
        this.setState({ results: [] });
      } else {
        // console.log("dont match");
        this.setState({ results: [] });
      }
    }
  }
}

export default ScanApp;
