import React, { useState } from "react";
import axios from "axios";

function UploadTest() {
  const [file, setFile] = useState(null);

  const onChangeHandler = event => {
    setFile(event);
  };

  // const onClickHandler = () => {
  //   axios({
  //     url: "https://shop.personal-hub.com/PHP/proxy.php?ping",
  //     method: "get",
  //     headers: { "content-type": "application/x-www-form-urlencoded" }
  //   }).then(res => {
  //     // then print response status
  //     console.log(res);
  //   });
  // };
  // const onClickHandler = () => {
  //   //   const data = new FormData();
  //   //   data.append("file", file);
  //   const data = ["string test"];
  //   axios({
  //     url:
  //       // "https://shop.personal-hub.com/PHP/proxy.php?http://services.arcgisonline.com/ArcGIS/rest/services/?f=pjson",
  //       "https://shop.personal-hub.com/hubweb_proxy/hubproxy.php?www.google.com?maros3",
  //     method: "post",
  //     headers: { "content-type": "application/x-www-form-urlencoded" },
  //     // params:
  //     //   "http://10.169.59.108/index.php?hub_id=hub_000_000_001_173&hub_ip=10.169.59.108",
  //     data: data
  //   }).then(res => {
  //     // then print response status
  //     console.log(res.statusText);
  //   });
  // }; // proxy interprets this as GET, as there is no $_POST content

  //Try the get $_POST content for the proxy:
  // const onClickHandler = () => {
  //   const params = new URLSearchParams();
  //   params.append("param1", "value1");
  //   params.append("param2", "value2");
  //   axios.post(
  //     "https://shop.personal-hub.com/hubweb_proxy/hubproxy.php?www.google.com?maros3",
  //     params
  //   );
  // }; //worked: detected as POST by proxy

  const onClickHandler = () => {
    const data = new FormData();
    data.append("file", file);
    data.append("btn", null);
    axios.post(
      "https://stage.api.personal-hub.com/PHP/proxy.php?http://172.16.0.111/index.php?hub_id=hub_000_000_001_321&hub_ip=172.16.0.111&admin_pwd=acRsTwH_lP2%",
      data
    );
  };
  // https://shop.personal-hub.com/PHP/proxy.php?http://10.169.59.108/index.php?hub_id=hub_000_000_001_173&hub_ip=10.169.59.108
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            width: "500px",
            height: "200px",
            alignSelf: "center",
            backgroundColor: "lightblue"
          }}
        >
          <input
            type="file"
            name="file"
            onChange={event => {
              onChangeHandler(event.target.files[0]);
            }}
          />
          <button
            type="button"
            onClick={() => {
              onClickHandler();
            }}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
}

export default UploadTest;
