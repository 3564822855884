import React from "react";
import BottomNavigation from "../BottomNavigation/BottomNavigation";

const Content = props => (
  <div className="content">
    {props.children}
    {props.logged_in !== false ? <BottomNavigation {...props} /> : null}
  </div>
);

export default Content;
