import React from "react";
import "./AboutCovid19.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const Covid19FAQ = () => {
  const intl = useIntl();
  let history = useHistory();

  return (
    <div className="cov_faq_container">
      <img
        className="login_page_bg"
        src="/assets/images/background_img_hubsite.png"
        alt=""
      />
      <div className="cov_faq_header">
        <div className="cov_faq_back" onClick={() => history.goBack()}>
          <ArrowBackIcon />{" "}
          {intl.formatMessage({
            id: "active_minute_back"
          })}
        </div>
        <div className="cov_faq_title">
          {intl.formatMessage({
            id: "covid_19_infopage_title"
          })}
        </div>
      </div>

      <div className="cov_faq_text">
        <ol>
          <h4>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt1"
              })}
            </li>
          </h4>
          <ol type="a">
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt2"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt3"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt4"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt5"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt6"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt7"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt8"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt9"
              })}
            </li>
          </ol>
          <h4>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt10"
              })}
            </li>
          </h4>
          <h5>
            {intl.formatMessage({
              id: "covid_faq_pt11"
            })}
          </h5>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt12"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt13"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt14"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt15"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt16"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt17"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt18"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt19"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt20"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt21"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt22"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt23"
            })}
          </p>
          <h5>
            {intl.formatMessage({
              id: "covid_faq_pt24"
            })}
          </h5>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt25"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt26"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt27"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt28"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt29"
            })}
          </p>
          <h5>
            {intl.formatMessage({
              id: "covid_faq_pt30"
            })}
          </h5>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt31"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt32"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt33"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt34"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt35"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt36"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt37"
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt38"
            })}
          </p>
          <h4>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt39"
              })}
            </li>
          </h4>
          <p>
            {intl.formatMessage({
              id: "covid_faq_pt40"
            })}
          </p>{" "}
          <ul>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt41"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt42"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_faq_pt43"
              })}
            </li>
          </ul>
        </ol>
      </div>
    </div>
  );
};
export default Covid19FAQ;
