import React, { useState, useEffect } from "react";
import {
  getActiveMinuteSettings,
  getActiveMinuteStepsList
} from "../../../services/GetActiveMinuteData";
import { putActiveMinuteSettings } from "../../../services/PutActiveMinuteData";
import "../styles/steps.css";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { injectIntl } from "react-intl";
import Basic from "./Versions/Steps/basic";

const useStyles = makeStyles(theme => ({
  disabled: {
    color: "grey !important"
  },
  mainBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  togglesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  versionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));

function Steps({ handleSubmitIconChange, item, intl }) {
  const [providedData, setProvidedData] = useState([]);
  const [version, setVersion] = useState("basic");
  const [day, setDay] = useState("today");
  const [settingsID, setSettingsID] = useState(0);
  const classes = useStyles();

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 7) {
            if (item.value === "yesterday") {
              setDay(item.value);
            }
            if (item.parameter2 !== null) {
              setVersion(item.parameter2);
            }
            setSettingsID(item.id);
          }
        });
      }
    });
    send_credential_to_registrar.limit = 5;
    getActiveMinuteStepsList(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        setProvidedData(result.data.data_array.reverse());
      } else {
        console.log("get steps data error");
      }
    });
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  //   const handleVersion = (event, newVersion) => {
  //     if (newVersion !== null) {
  //       setVersion(newVersion);
  //       updateSettingsInApi(newVersion);
  //     }
  //   };

  const handleDay = (event, newDay) => {
    if (newDay !== null) {
      setDay(newDay);
      updateSettingsInApi(newDay);
    }
  };

  const updateSettingsInApi = param => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.value = param;

    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const renderList = () => {
    switch (version) {
      case "basic":
        return (
          <Basic
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            providedData={providedData}
            getApiData={getApiData}
            day={day}
          />
        );
      case "advanced":
        return (
          <Basic
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            providedData={providedData}
            getApiData={getApiData}
            day={day}
          />
        );
      case "pro":
        return (
          <Basic
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            providedData={providedData}
            getApiData={getApiData}
            day={day}
          />
        );
      default:
        return (
          <Basic
            handleSubmitIconChange={handleSubmitIconChange}
            item={item}
            providedData={providedData}
            getApiData={getApiData}
            day={day}
          />
        );
    }
  };

  const toggleButtonsVersion = () => {
    return (
      <ToggleButtonGroup
        value={version}
        exclusive
        // onChange={handleVersion}
        aria-label="text alignment"
      >
        <ToggleButton value="basic" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_basic"
          })}
        </ToggleButton>
        <ToggleButton
          value="advanced"
          className={classes.disabled}
          disabled
          aria-label="center aligned"
        >
          {intl.formatMessage({
            id: "active_minute_advanced"
          })}
        </ToggleButton>
        <ToggleButton
          className={classes.disabled}
          disabled
          value="pro"
          aria-label="right aligned"
        >
          {intl.formatMessage({
            id: "active_minute_pro"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const toggleButtonsDay = () => {
    return (
      <ToggleButtonGroup
        value={day}
        exclusive
        onChange={handleDay}
        aria-label="text alignment"
      >
        <ToggleButton value="yesterday" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_yesterday"
          })}
        </ToggleButton>
        <ToggleButton value="today" aria-label="center aligned">
          {intl.formatMessage({
            id: "active_minute_today"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  return (
    <div className={classes.mainBlock}>
      <div className={classes.togglesContainer}>
        <div>{toggleButtonsVersion()}</div>
        <div>{toggleButtonsDay()}</div>
      </div>
      <div className={classes.versionContainer}>{renderList()}</div>
    </div>
  );
}

export default injectIntl(Steps);
