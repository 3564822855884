import React from "react";
import "./WelcomeMessage.scss";

const WelcomeMessage = ({ shop_basics }) => (
  <div className="welcome-message">
    <img
      className="avatar"
      src={shop_basics.shop_assistant_link}
      onError={e => {
        e.target.onerror = null;
        e.target.src = "/assets/images/assistant_simmel.png";
      }}
      alt="Assistant's Avatar"
    />
    <div
      id="wm_idParent"
      className="messages"
      dangerouslySetInnerHTML={{ __html: shop_basics.shop_assistant_welcome }}
    >
      {/* <p className="message">{shop_basics.shop_assistant_welcome1}</p>
      <p className="message">{shop_basics.shop_assistant_welcome2}</p>
      <p className="message">{shop_basics.shop_assistant_welcome3}</p> */}
    </div>
  </div>
);
export default WelcomeMessage;
