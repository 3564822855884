import React, { useState, useEffect } from "react";
import "./HubPrompter.css";
import { useHistory } from "react-router-dom";
import {
  getHubPrompter,
  getHubPrompterSelf,
} from "../../services/GetHUBsiteData";
import Statistics from "./Statistics";
import FilteredSearch from "./FilteredSearch";
import CustomSearch from "./CustomSearch";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Link } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ClearIcon from "@material-ui/icons/Clear";

import { useIntl } from "react-intl";
import update from "immutability-helper";

export default function HubPrompter() {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [dataLimited, setDataLimited] = useState([]);
  const [hubsSearched, setHubsSearched] = useState({
    count: null,
    unreachable: null,
    no_data_match: null,
    returned: null,
    rows: null,
  });
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState("rating");
  const [selectedProcessing, setSelectedProcessing] = useState("count");
  const [selectedFormat, setSelectedFormat] = useState({
    label: "grouped",
    obj: {
      negative: ["1", "2", "3"],
      neutral: ["3", "4", "5", "6", "7"],
      positive: ["8", "9", "10"],
    },
  });

  const [definedGroups, setDefinedGroups] = useState([[]]);
  const [availableValues, setAvailableValues] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
  });

  const [activeGroup, setActiveGroup] = useState(0);

  const [expand, setExpand] = useState(null);

  const [loading, setLoading] = useState(false);
  const [blink, setBlink] = useState(false);

  const [showError, setShowError] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [fsInitialQuery, setFsInitialQuery] = useState("");
  const [limitedHub, setLimitedHub] = useState(0);

  const [currMode, setCurrentMode] = useState(1);
  const availableModes = [1, 2, 3];

  const predefinedQueries = [
    {
      label: intl.formatMessage({
        id: "hub_prompter_predef_q1",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject IN ('Q1','Q2','Q3')",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_predef_q2",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject IN ('Q4','Q5','Q6')",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_predef_q3",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject IN ('Q7','Q8','Q9')",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_predef_q4",
      }),
      query:
        "SELECT * from my_ratings cross join my_people_details WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q4' AND my_people_details.category='covid-19' AND my_people_details.hub_transaction_id='Q215' and my_people_details.value_text IN ('DE')",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G1_Q1",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q1'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G1_Q2",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q2'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G1_Q3",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q3'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G2_Q4",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q4'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G2_Q5",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q5'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G2_Q6",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q6'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G3_Q7",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q7'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G3_Q8",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q8'",
    },
    {
      label: intl.formatMessage({
        id: "covid_poll_group_question_db_G3_Q9",
      }),
      query:
        "select * from my_ratings WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q9'",
    },
  ];
  const filteredInitialQueries = [
    {
      qID: "Q1",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q1",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q1'",
    },
    {
      qID: "Q2",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q2",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q2'",
    },
    {
      qID: "Q3",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q3",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q3'",
    },
    {
      qID: "Q4",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q4",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q4'",
    },
    {
      qID: "Q5",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q5",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q5'",
    },
    {
      qID: "Q6",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q6",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q6'",
    },
    {
      qID: "Q7",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q7",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q7'",
    },
    {
      qID: "Q8",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q8",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q8'",
    },
    {
      qID: "Q9",
      label: intl.formatMessage({
        id: "hub_prompter_fs_q9",
      }),
      query:
        "SELECT id, entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, personal_note from my_ratings WHERE entry_type='covid-19' and subject_type='poll' and subject='Q9'",
    },
  ];

  const exampleQueries = [
    {
      label: intl.formatMessage({
        id: "hub_prompter_custom_ex1",
      }),
      query:
        "SELECT entry_type, subject_type, subject, rating, explanation, review, date_on, keywords, counterpart_hub_id, subject_brand, subject_identification, identified_by, my_ratings.personal_note, what, value_text	FROM my_ratings cross join my_people_details WHERE entry_type='covid-19' and subject_type='poll' AND subject='Q1' AND my_people_details.category='covid-19' AND my_people_details.hub_transaction_id='Q215' and my_people_details.value_text IN ('SK','CZ','DE','IT')",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_custom_ex2",
      }),
      query:
        "SELECT my_medical_values.id, my_medical_values.category, my_medical_values.date_on, my_medical_values.what, my_medical_values.value, my_medical_values.reference_value, my_medical_values.universal_index,my_people_details.what,my_people_details.category, my_people_details.value_text	FROM my_medical_values	cross join my_people_details 	WHERE my_medical_values.category='covid-19' AND universal_index='Q202' AND my_people_details.category='covid-19' AND my_people_details.hub_transaction_id='Q210' and my_people_details.value_text<='1980'",
    },
  ];

  let history = useHistory();

  useEffect(() => {
    //build columns array

    if (data.length !== 0) {
      let columns = Object.keys(data[0]["data"][0]);
      columns.unshift("HUB ID");
      columns.unshift("visit");
      setColumns(columns);

      if (limitedHub !== null) {
        setDataLimited([data[limitedHub]]);
      } else {
        setDataLimited(data);
      }
    } else {
      setDataLimited([]);
      setColumns([]);
    }
  }, [data, limitedHub]);

  // useEffect(() => {
  //   console.log(dataLimited);
  // }, [dataLimited]);

  const getPrompterData = (param) => {
    setLoading(true);
    let query_params = {};
    if (!sessionStorage.getItem("hub_token")) {
      query_params.logged_in = null;
    } else {
      query_params.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    query_params.section = "covid-19";
    query_params.target_hub_id = query_params.logged_in.my_hub_id;
    query_params.requestor_hub_id = query_params.target_hub_id;
    query_params.prompt = inputValue;
    // query_params.prompt =
    //   "SELECT id, date_on, headline, note, keywords from my_diary";
    if (param === "self") {
      getHubPrompterSelf(query_params)
        .then((result) => {
          setLoading(false);
          if (result.data.data_array) {
            setData(result.data.data_array);

            let temp_rows = 0;
            result.data.data_array.forEach((element) => {
              temp_rows = temp_rows + element.data.length;
            });
            setHubsSearched({
              count: result.data.all_hubs_count,
              unreachable:
                result.data.all_hubs_count - result.data.conn_to_count,
              no_data_match:
                result.data.all_hubs_count -
                (result.data.all_hubs_count -
                  result.data.conn_to_count +
                  result.data.data_array.length),
              returned: result.data.data_array.length,
              rows: temp_rows,
            });
          } else {
            setData([]);
            setHubsSearched({
              count: null,
              unreachable: null,
              no_data_match: null,
              returned: null,
              rows: null,
            });
            setShowError(true);
          }
        })
        .catch(() => {
          setLoading(false);
          setData([]);
          setHubsSearched({
            count: null,
            unreachable: null,
            no_data_match: null,
            returned: null,
            rows: null,
          });
          setShowError(true);
        });
    } else {
      getHubPrompter(query_params)
        .then((result) => {
          setLoading(false);
          if (result.data.data_array) {
            setData(result.data.data_array);

            let temp_rows = 0;
            result.data.data_array.forEach((element) => {
              temp_rows = temp_rows + element.data.length;
            });
            setHubsSearched({
              count: result.data.all_hubs_count,
              unreachable:
                result.data.all_hubs_count - result.data.conn_to_count,
              no_data_match:
                result.data.all_hubs_count -
                (result.data.all_hubs_count -
                  result.data.conn_to_count +
                  result.data.data_array.length),
              returned: result.data.data_array.length,
              rows: temp_rows,
            });
          } else {
            setData([]);
            setHubsSearched({
              count: null,
              unreachable: null,
              no_data_match: null,
              returned: null,
              rows: null,
            });
            setShowError(true);
          }
        })
        .catch(() => {
          setLoading(false);
          setData([]);
          setHubsSearched({
            count: null,
            unreachable: null,
            no_data_match: null,
            returned: null,
            rows: null,
          });
          setShowError(true);
        });
    }
  };

  const handleModeSwitch = (param) => {
    if (param === "previous") {
      if (currMode > 1) {
        setCurrentMode(currMode - 1);
      } else {
        setCurrentMode(availableModes.length);
      }
      setInputValue("");
      setFsInitialQuery("");
    } else {
      if (currMode < availableModes.length) {
        setCurrentMode(currMode + 1);
      } else {
        setCurrentMode(1);
      }
      setInputValue("");
      setFsInitialQuery("");
    }
  };

  const renderModeSwitch = (param) => {
    return (
      <div className="hubprompter_modes_container">
        <div
          onClick={() => {
            handleModeSwitch("previous");
          }}
          className="hubprompter_modes_arrow_box covid19_noshrink"
        >
          <ArrowBackIcon />
        </div>
        <div>
          <div className="hubprompter_modes_mode_title">{param}</div>
          <div className="hubprompter_small_dot_wrapper">
            {availableModes.map((item, i) => {
              if (item === currMode) {
                return (
                  <div key={i} className="hubprompter_small_dot_filled"></div>
                );
              } else {
                return <div key={i} className="hubprompter_small_dot"></div>;
              }
            })}
          </div>
        </div>
        <div
          onClick={() => {
            handleModeSwitch("next");
          }}
          className="hubprompter_modes_arrow_box covid19_noshrink"
        >
          <ArrowForwardIcon />
        </div>
      </div>
    );
  };

  const renderModesBlock = () => {
    switch (currMode) {
      case 1:
        return (
          <>
            {renderModeSwitch(
              intl.formatMessage({
                id: "hub_prompter_predef_searches",
              })
            )}
            <div className="hubprompt_predef_container">
              {predefinedQueries.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="hubprompter_predef_query_title"
                    onClick={() => {
                      setInputValue(item.query);
                      setBlink(true);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </>
        );
      case 2:
        return (
          <>
            {renderModeSwitch(
              intl.formatMessage({
                id: "hub_prompter_filtered_search",
              })
            )}
            <div className="hubprompt_filtered_search_container">
              <div className="hubprompt_filtered_search_predef">
                <div className="hubprompt_filtered_search_step_title">
                  {intl.formatMessage({
                    id: "hub_prompter_step1",
                  })}
                </div>
                <div className="hubprompt_filtered_search_predef_outer_wrap">
                  <div className="hubprompt_filtered_search_predef_inner_wrap">
                    {filteredInitialQueries.map((item, i) => {
                      let selected = "";
                      if (fsInitialQuery === item.query) {
                        selected = "hubprompt_filtered_search_predef_selected";
                      }

                      return (
                        <div
                          key={i}
                          className={
                            "hubprompt_filtered_search_predef_title " + selected
                          }
                          onClick={() => {
                            setFsInitialQuery(item.query);
                            setInputValue(item.query);
                          }}
                        >
                          <div className="hubprompt_filtered_search_predef_qID">
                            {item.qID}:
                          </div>
                          <div>{item.label}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <FilteredSearch
                initialQuery={fsInitialQuery}
                setInputValue={setInputValue}
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            {renderModeSwitch(
              intl.formatMessage({
                id: "hub_prompter_custom_search",
              })
            )}
            <div className="hubprompt_examp_container">
              <div className="hubprompt_covid_banner_text1">
                {intl.formatMessage({
                  id: "hub_prompter_custom_text1",
                })}
              </div>
              <div className="hubprompter_examp_query_btns_container">
                {exampleQueries.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="hubprompter_predef_query_btn"
                      onClick={() => {
                        setInputValue(item.query);
                      }}
                    >
                      <div className="hubprompter_predef_query_btn_text">
                        {item.label}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="hubprompt_covid_banner_text2">
                {intl.formatMessage({
                  id: "hub_prompter_custom_search"
                })}
              </div>
              <div className="hubprompt_covid_banner_links_wrap">
                <Link
                  to={{
                    pathname: "/hubmanager/people_details",
                    state: { table_filter: "covid-19" }
                  }}
                >
                  <span className="hubprompt_covid_banner_links">
                    people_details
                  </span>
                </Link>
                <Link
                  to={{
                    pathname: "/hubmanager/ratings",
                    state: { table_filter: "covid-19" }
                  }}
                >
                  <span className="hubprompt_covid_banner_links">ratings</span>
                </Link>
                <Link
                  to={{
                    pathname: "/hubmanager/medical_values",
                    state: { table_filter: "covid-19" }
                  }}
                >
                  <span className="hubprompt_covid_banner_links">
                    medical_values
                  </span>
                </Link>
              </div> */}
            </div>
            <CustomSearch
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleSelectFormat = (param) => {
    if (param === "grouped") {
      setSelectedFormat({
        label: param,
        obj: {
          negative: ["1", "2", "3"],
          neutral: ["3", "4", "5", "6", "7"],
          positive: ["8", "9", "10"],
        },
      });
    } else if (param === "by_value") {
      setSelectedFormat({
        label: param,
        obj: {},
      });
    } else if (param === "custom") {
      setSelectedFormat({
        label: param,
        obj: {},
      });
    }
  };

  const generateGrouping = () => {
    let temp_obj = {};
    definedGroups.forEach((element) => {
      let name = element.toString();
      temp_obj = { ...temp_obj, [name]: element };
    });

    setSelectedFormat({ ...selectedFormat, obj: temp_obj });
  };

  const handleCreateGrouping = () => {
    return (
      <>
        <div>
          <div className="hubprompter_grouping_values_container">
            <div className="hubprompter_grouping_values_title">
              {intl.formatMessage({
                id: "hub_prompter_possible",
              })}
              <br />
              {intl.formatMessage({
                id: "hub_prompter_values",
              })}
            </div>
            <div className="hubprompter_grouping_values_wrapper">
              {Object.keys(availableValues).map((item, i) => {
                if (!item) {
                  return null;
                } else {
                  let active = true;
                  if (availableValues[item] !== null) {
                    active = false;
                  }

                  return (
                    <div
                      className={
                        active
                          ? "hubprompter_grouping_value"
                          : "hubprompter_grouping_value selected"
                      }
                      key={i}
                      onClick={
                        active
                          ? () => {
                              setAvailableValues(
                                update(availableValues, {
                                  [item]: { $set: activeGroup },
                                })
                              );
                              setDefinedGroups(
                                update(definedGroups, {
                                  [activeGroup]: { $push: [item] },
                                })
                              );
                            }
                          : null
                      }
                    >
                      {item}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="hubprompter_grouping_groups_container">
            {definedGroups.map((item, i) => {
              return (
                <div className="hubprompter_grouping_values_container" key={i}>
                  <div
                    className={
                      activeGroup === i
                        ? "hubprompter_grouping_groups_title active"
                        : "hubprompter_grouping_groups_title"
                    }
                    onClick={() => {
                      setActiveGroup(i);
                    }}
                  >
                    {intl.formatMessage({
                      id: "hub_prompter_group",
                    })}
                  </div>
                  <div className="hubprompter_grouping_values_wrapper">
                    {item.map((element, index) => {
                      if (!element) {
                        return null;
                      } else {
                        return (
                          <div
                            className="hubprompter_grouping_value"
                            key={index}
                            onClick={() => {
                              setAvailableValues(
                                update(availableValues, {
                                  [element]: { $set: null },
                                })
                              );
                              setDefinedGroups(
                                update(definedGroups, {
                                  [i]: {
                                    $splice: [[index, 1]],
                                  },
                                })
                              );
                            }}
                          >
                            {element}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="hubprompter_grouping_bottom_btns_container">
            <div
              className="hubprompter_grouping_bottom_add_btn"
              onClick={() => {
                setDefinedGroups([...definedGroups, []]);
                setActiveGroup(definedGroups.length);
              }}
            >
              {intl.formatMessage({
                id: "hub_prompter_add_group",
              })}
            </div>
            <div
              className="hubprompter_grouping_bottom_apply_btn"
              onClick={() => {
                generateGrouping();
              }}
            >
              {intl.formatMessage({
                id: "hub_prompter_apply",
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const provideInputPlaceholder = () => {
    switch (currMode) {
      case 1:
        return intl.formatMessage({
          id: "hub_prompter_textarea_placeholder_1",
        });
      case 2:
        return intl.formatMessage({
          id: "hub_prompter_textarea_placeholder_2",
        });
      case 3:
        return intl.formatMessage({
          id: "hub_prompter_textarea_placeholder_3",
        });
      default:
        return null;
    }
  };

  const handleSingleHubNav = (param) => {
    switch (param) {
      case "prev":
        if (limitedHub > 0) {
          setLimitedHub(limitedHub - 1);
        }
        break;
      case "next":
        if (limitedHub + 1 < data.length) {
          setLimitedHub(limitedHub + 1);
        }
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={{ margin: "0px 50px", paddingTop: "30px" }}
        className="cov19_title"
      >
        {intl.formatMessage({
          id: "hub_prompter_title",
        })}
      </div>
      <img
        className="hubprompter_back_arrow"
        alt=""
        src="/assets/images/left_arrow_icon_v2.png"
        onClick={() => history.goBack()}
      />
      {/* <div className="hubprompter_title">HUB Prompter</div> */}

      <div className="cov19_orange_text_block">
        {intl.formatMessage({
          id: "hub_prompter_message_1",
        })}
      </div>
      <div className="hubprompter_container">
        <div className="hubprompter_top">
          <div
            onClick={
              inputValue !== ""
                ? () => {
                    setInputValue("");
                    setFsInitialQuery("");
                    setBlink(false);
                  }
                : null
            }
            className={
              inputValue !== ""
                ? "hubprompter_inputfield_clear_btn hbvisble"
                : "hubprompter_inputfield_clear_btn"
            }
          >
            <ClearIcon />
          </div>
          <textarea
            rows="4"
            className="hubprompter_input"
            style={
              currMode === 2 || currMode === 1
                ? { backgroundColor: "rgba(0, 0, 0, 0.25)" }
                : null
            }
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            placeholder={provideInputPlaceholder()}
          ></textarea>
          <div className="hubprompter_top_btn_wrapper">
            {loading ? (
              <img alt="loading" src="/assets/images/loadingv3.gif" />
            ) : (
              <>
                <button
                  className={blink ? "hubprompt_blink_me" : ""}
                  onClick={() => {
                    getPrompterData("global");
                    setBlink(false);
                    setShowError(false);
                  }}
                >
                  {intl.formatMessage({
                    id: "hub_prompter_start_search",
                  })}
                </button>
                <button
                  className={blink ? "hubprompt_blink_me" : ""}
                  onClick={() => {
                    getPrompterData("self");
                    setBlink(false);
                    setShowError(false);
                  }}
                >
                  {intl.formatMessage({
                    id: "hub_prompter_test_self",
                  })}
                </button>
              </>
            )}
          </div>
          {showError ? (
            <div className="hubprompter_top_error_msg">
              {intl.formatMessage({
                id: "hub_prompter_error_message",
              })}
            </div>
          ) : null}
        </div>
        <div className="hubprompter_dashed_divider"></div>

        {renderModesBlock()}
        {/* {true ? <div>{handleCreateGrouping()}</div> : null} */}

        {data.length !== 0 ? (
          <>
            <div className="hubprompter_return_header">
              <div className="hubprompter_return_header_title">
                {intl.formatMessage({
                  id: "hub_prompter_results",
                })}
              </div>
            </div>
            <div className="hubprompter_return_header_stats">
              {hubsSearched.count !== null ? (
                <div className="hubprompter_return_header_text">{`${
                  hubsSearched.count
                } ${intl.formatMessage({
                  id: "hub_prompter_hubs_searched",
                })}`}</div>
              ) : null}
              {hubsSearched.unreachable !== null ? (
                <div className="hubprompter_return_header_text">{`${
                  hubsSearched.unreachable
                } ${intl.formatMessage({
                  id: "hub_prompter_hubs_unreachable",
                })}`}</div>
              ) : null}
              {hubsSearched.no_data_match !== null ? (
                <div className="hubprompter_return_header_text">{`${
                  hubsSearched.no_data_match
                } ${intl.formatMessage({
                  id: "hub_prompter_hubs_no_data_match",
                })}`}</div>
              ) : null}
              {hubsSearched.returned !== null ? (
                <div className="hubprompter_return_header_text">{`${
                  hubsSearched.returned
                } ${intl.formatMessage({
                  id: "hub_prompter_returned_data",
                })}`}</div>
              ) : null}
              {hubsSearched.rows !== null ? (
                <div className="hubprompter_return_header_text">{`${
                  hubsSearched.rows
                } ${intl.formatMessage({
                  id: "hub_prompter_total_rows",
                })}`}</div>
              ) : null}
            </div>
            <div className="hubprompter_result_settings_container">
              <div className="hubprompter_result_settings_left">
                <div className="hubprompter_result_settings_title">
                  {intl.formatMessage({
                    id: "hub_prompter_grouping",
                  })}
                </div>
                <div className="hubprompter_result_settings_subtitle">
                  {intl.formatMessage({
                    id: "hub_prompter_grouping_on_field",
                  })}
                </div>
                <select
                  className="hubprompter_result_settings_select"
                  value={selectedColumns}
                  onChange={(event) => setSelectedColumns(event.target.value)}
                >
                  <option value="rating">
                    {intl.formatMessage({
                      id: "hub_prompter_option_rating",
                    })}
                  </option>
                  {/* {columns.map((item, index) => {
                  if (!item) {
                    return null;
                  }
                  return <option key={index}>{item}</option>;
                })} */}
                </select>
                <div className="hubprompter_result_settings_subtitle">
                  {intl.formatMessage({
                    id: "hub_prompter_group_by",
                  })}
                </div>
                <select
                  className="hubprompter_result_settings_select"
                  value={selectedFormat.label}
                  onChange={(event) => handleSelectFormat(event.target.value)}
                >
                  <option value="by_value">
                    {intl.formatMessage({
                      id: "hub_prompter_option_each_val_sep",
                    })}
                  </option>
                  <option value="grouped">
                    {intl.formatMessage({
                      id: "hub_prompter_option_grouped",
                    })}
                  </option>
                  <option value="custom">
                    {intl.formatMessage({
                      id: "hub_prompter_option_custom",
                    })}
                  </option>
                </select>
              </div>
              <div className="hubprompter_result_settings_right">
                <div className="hubprompter_result_settings_title">
                  {intl.formatMessage({
                    id: "hub_prompter_calc",
                  })}
                </div>
                <div className="hubprompter_result_settings_subtitle">
                  {intl.formatMessage({
                    id: "hub_prompter_use_field",
                  })}
                </div>
                <select
                  className="hubprompter_result_settings_select"
                  value={selectedColumns}
                  onChange={(event) => setSelectedColumns(event.target.value)}
                >
                  <option value="rating">
                    {intl.formatMessage({
                      id: "hub_prompter_option_rating",
                    })}
                  </option>
                </select>
                <div className="hubprompter_result_settings_subtitle">
                  {intl.formatMessage({
                    id: "hub_prompter_formula",
                  })}
                </div>
                <select
                  className="hubprompter_result_settings_select"
                  value={selectedProcessing}
                  onChange={(event) =>
                    setSelectedProcessing(event.target.value)
                  }
                >
                  <option value="count">
                    {intl.formatMessage({
                      id: "hub_prompter_option_count",
                    })}
                  </option>
                  <option value="avg">
                    {intl.formatMessage({
                      id: "hub_prompter_option_avg",
                    })}
                  </option>
                  <option value="sum">
                    {intl.formatMessage({
                      id: "hub_prompter_option_sum",
                    })}
                  </option>
                  <option value="min">
                    {intl.formatMessage({
                      id: "hub_prompter_option_min",
                    })}
                  </option>
                  <option value="max">
                    {intl.formatMessage({
                      id: "hub_prompter_option_max",
                    })}
                  </option>
                </select>
              </div>
            </div>
            {selectedFormat.label === "custom" ? (
              <div className="hubprompter_result_settings_grouping_container">
                {handleCreateGrouping()}
              </div>
            ) : null}

            <Statistics
              data={data}
              column={selectedColumns}
              format={selectedFormat}
              procesType={selectedProcessing}
            />
            <div className="hubprompter_return_header">
              <div className="hubprompter_return_header_title">
                {intl.formatMessage({
                  id: "hub_prompter_raw_results",
                })}
              </div>
              <div className="hubprompter_return_header_inner_block">
                {hubsSearched.count !== null ? (
                  <div className="hubprompter_return_header_text">{`${
                    hubsSearched.count
                  } ${intl.formatMessage({
                    id: "hub_prompter_hubs_searched",
                  })}`}</div>
                ) : null}
                {hubsSearched.returned !== null ? (
                  <div className="hubprompter_return_header_text">{`${
                    hubsSearched.returned
                  } ${intl.formatMessage({
                    id: "hub_prompter_returned_data",
                  })}`}</div>
                ) : null}
                {hubsSearched.rows !== null ? (
                  <div className="hubprompter_return_header_text">{`${
                    hubsSearched.rows
                  } ${intl.formatMessage({
                    id: "hub_prompter_total_rows",
                  })}`}</div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        <div className="hubprompter_bottom">
          {dataLimited.length !== 0 ? (
            <div className="hubprompter_table_top">
              <div className="hubprompter_table_top_left">
                <div className="hubprompter_table_top_text">
                  {intl.formatMessage({
                    id: "hub_prompter_show",
                  })}
                </div>
                <div
                  className={
                    limitedHub === null
                      ? "hubprompter_table_limit_btn selected"
                      : "hubprompter_table_limit_btn"
                  }
                  onClick={() => {
                    setLimitedHub(null);
                  }}
                >
                  <span>
                    {intl.formatMessage({
                      id: "hub_prompter_all_hubs",
                    })}
                  </span>
                </div>
                <div
                  className={
                    limitedHub !== null
                      ? "hubprompter_table_limit_btn selected"
                      : "hubprompter_table_limit_btn"
                  }
                  onClick={() => {
                    setLimitedHub(0);
                  }}
                >
                  <span>
                    {intl.formatMessage({
                      id: "hub_prompter_single_hub",
                    })}
                  </span>
                </div>
              </div>
              {limitedHub !== null ? (
                <div className="hubprompter_table_top_right">
                  <div
                    onClick={() => {
                      handleSingleHubNav("prev");
                    }}
                    className="hubprompter_table_top_arrow"
                  >
                    <ArrowLeftIcon />
                  </div>
                  <div className="hubprompter_table_top_text">
                    {limitedHub + 1}/{data.length}
                  </div>
                  <div className="hubprompter_table_top_text">
                    {`HUB: `}
                    {parseInt(
                      dataLimited[0].hub_id
                        .replace(/hub_/g, "")
                        .replace(/_/g, ""),
                      10
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleSingleHubNav("next");
                    }}
                    className="hubprompter_table_top_arrow"
                  >
                    <ArrowRightIcon />
                  </div>

                  <Link
                    to={
                      "/HUBsite/" +
                      parseInt(
                        dataLimited[0].hub_id
                          .replace(/hub_/g, "")
                          .replace(/_/g, ""),
                        10
                      )
                    }
                  >
                    <div className="hubprompter_table_top_btn">
                      {intl.formatMessage({
                        id: "hub_prompter_visit_this_hub",
                      })}
                    </div>
                  </Link>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="hubprompter_bottom">
          <table id="customers">
            <tbody
              style={{
                display: "block",
                maxHeight: "80vh",
                border: "none",
              }}
            >
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "100",
                }}
              >
                {columns.map((item, th_index) => {
                  if (!item) {
                    return null;
                  }
                  return (
                    <th
                      key={th_index}
                      style={
                        th_index === 0
                          ? {
                              textTransform: "capitalize",
                              minWidth: "0px",
                              borderBottom: "1px solid #ddd",
                            }
                          : {
                              textTransform: "capitalize",
                              maxWidth: "200px",
                              borderBottom: "1px solid #ddd",
                            }
                      }
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
              {dataLimited.map((item, index) => {
                if (!item) {
                  return null;
                }
                return item.data.map((i, tr_index) => {
                  if (!i) {
                    return null;
                  }
                  return (
                    <tr key={tr_index}>
                      {tr_index === 0 ? (
                        <td style={{ minWidth: "0px" }}>
                          <Link
                            to={
                              "/HUBsite/" +
                              parseInt(
                                item.hub_id
                                  .replace(/hub_/g, "")
                                  .replace(/_/g, ""),
                                10
                              )
                            }
                          >
                            <img
                              className="menu_icon"
                              src="/assets/images/Visit.svg"
                              alt="visit hub"
                            />
                          </Link>
                        </td>
                      ) : (
                        <td style={{ minWidth: "0px" }}>
                          <div className="hubprompter_td_div"></div>
                        </td>
                      )}
                      <td
                        style={
                          expand === "hub_id"
                            ? { minWidth: "400px" }
                            : { maxWidth: "200px" }
                        }
                        onClick={() =>
                          expand === "hub_id"
                            ? setExpand(null)
                            : setExpand("hub_id")
                        }
                      >
                        <div className="hubprompter_td_div">{item.hub_id}</div>
                      </td>
                      {Object.values(i).map((i, td_index) => {
                        return (
                          <td
                            style={
                              td_index === expand
                                ? { minWidth: "400px" }
                                : { maxWidth: "200px" }
                            }
                            key={td_index}
                            onClick={() =>
                              expand === td_index
                                ? setExpand(null)
                                : setExpand(td_index)
                            }
                          >
                            <div className="hubprompter_td_div">{i}</div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
        {dataLimited.length > 1 ? (
          <div className="hubprompter_scroll_top_wrap">
            <div
              className="hubprompter_scroll_top_btn"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <ExpandLessIcon />
            </div>
          </div>
        ) : null}

        <div style={{ height: "60px", width: "100%" }}></div>
      </div>
    </>
  );
}
