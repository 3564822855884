import React from "react";

import "./ReceiptDisplay.css";
import "./shop_hub_default.css";
// import { FormattedMessage } from "react-intl";

function ReceiptTemplate({ cart_data, vat_data, receipt_items, curentCss }) {
  let rows;
  if (receipt_items) {
    rows = receipt_items.map((item, i) => {
      //if item quantity is bigger then 1 display quantity and price, if no hide item quantity
      if (Number(item.quantity) > 1) {
        return (
          <div className={curentCss + "item_inner_wrapper_grid"} key={i}>
            <div className={curentCss + "item_description_class"}>
              {item["article_name"]}
            </div>
            <div className={curentCss + "item_id_class"}>{item["id"]}</div>
            <div className={curentCss + "item_ean_class"}>{item["ean"]}</div>
            <div className={curentCss + "item_free_parameter1_class"}>
              {item["free_parameter1"]}
            </div>
            <div className={curentCss + "item_measure_unit"}>
              {item["unit"]}
            </div>
            <div className={curentCss + "item_amount"}>{item.quantity}</div>
            <div className={curentCss + "unit_price_incl_tax"}>
              {item["sale_price"]}
            </div>
            <div className={curentCss + "total_item_price_incl_tax"}>
              {item["total_price"]}
            </div>
            <div className={curentCss + "tax_group_descr"}>
              {item["vat_category"]}
            </div>
            <div className={curentCss + "tax_group_value"}>
              {item["free_parameter2"]}
            </div>
          </div>
        );
      } else {
        return (
          <div className={curentCss + "item_inner_wrapper_grid"} key={i}>
            <div className={curentCss + "item_description_class"}>
              {item["article_name"]}
            </div>
            <div className={curentCss + "item_id_class"}>{item["id"]}</div>
            <div className={curentCss + "item_ean_class"}>{item["ean"]}</div>
            <div className={curentCss + "item_free_parameter1_class"}>
              {item["free_parameter1"]}
            </div>
            <div
              className={
                curentCss + "item_measure_unit " + curentCss + "single_item"
              }
            >
              {item["unit"]}
            </div>
            <div
              className={curentCss + "item_amount " + curentCss + "single_item"}
            >
              {item.quantity}
            </div>
            <div
              className={
                curentCss + "unit_price_incl_tax " + curentCss + "single_item"
              }
            >
              {item["sale_price"]}
            </div>
            <div className={curentCss + "total_item_price_incl_tax"}>
              {item["total_price"]}
            </div>
            <div className={curentCss + "tax_group_descr"}>
              {item["vat_category"]}
            </div>
            <div className={curentCss + "tax_group_value"}>
              {item["free_parameter2"]}
            </div>
          </div>
        );
      }
    });

    let vat_rows;
    if (vat_data) {
      vat_rows = vat_data.map((item, i) => {
        return (
          <div className={curentCss + "vat_grid_container"} key={i}>
            <div className={curentCss + "VAT_RATE"}>{item["vat_rate"]}%</div>
            <div className={curentCss + "VAT_CATEGORY_ROW1"}>
              {item["vat_category"]}
            </div>
            <div className={curentCss + "VAT_CATEGORY_ROW2"}>
              {item["vat_category"]}
            </div>
            <div className={curentCss + "VAT_CATEGORY_ROW3"}>
              {item["vat_category"]}
            </div>

            <div className={curentCss + "FOR_TAX_DESC"} />
            <div className={curentCss + "FOR_TAX_SUM"}>
              {item["total_price_per_cat"]}
            </div>
            <div className={curentCss + "TAX_BASE_DESC"} />
            <div className={curentCss + "TAX_BASE_SUM"}>
              {item["sum_without_vat_per_cat"]}
            </div>
            <div className={curentCss + "TAX_SUM_DESC"} />
            <div className={curentCss + "TAX_SUM"}>
              {item["sum_vat_per_cat"]}
            </div>
          </div>
        );
      });
    }

    // eslint-disable-next-line no-unused-vars
    let cash_to_return_class;
    if (Number(cart_data.cash_to_return) === 0) {
      cash_to_return_class = "CASH_TO_RETURN_ZERO";
    }

    return (
      <div>
        <div className={curentCss + "receipt_grid_container"}>
          <button className={curentCss + "spacer0"}></button>
          <hr className={curentCss + "spacer1"} />
          <hr className={curentCss + "spacer2"} />
          <hr className={curentCss + "spacer3"} />
          <hr className={curentCss + "spacer4"} />

          <>
            <div id={curentCss + "HEADER_LINE_1"} />
            <div id={curentCss + "HEADER_LINE_2"} />
            <div id={curentCss + "HEADER_LINE_3"} />
            <div id={curentCss + "HEADER_LINE_4"} />
            <div id={curentCss + "HEADER_LINE_5"} />
            <div id={curentCss + "HEADER_LINE_6"} />
            <div id={curentCss + "HEADER_LINE_7"} />
            <div id={curentCss + "HEADER_LINE_8"} />
            <div id={curentCss + "HEADER_LINE_9"} />
            <div id={curentCss + "HEADER_LINE_10"} />
          </>

          <div id={curentCss + "HEADER_CASHIER_OPERATOR"}>
            {cart_data.cashier}
          </div>
          <div id={curentCss + "HEADER_DAY_NAME"}>Pon</div>
          <div id={curentCss + "HEADER_DATE_DELIM_DOT"}>
            {cart_data.issue_date_DMY_delimit_dot}
          </div>
          <div id={curentCss + "HEADER_DATE_DELIM_HYPHEN"}>
            {cart_data.issue_date_DMY_delimit_hyphen}
          </div>
          <div id={curentCss + "HEADER_TIME_HMS_DELIM_COL"}>
            {cart_data.issue_time_HMS_delimit_colon}
          </div>
          <div id={curentCss + "HEADER_TIME_HM_DELIM_COL"}>
            {cart_data.issue_time_HM_delimit_colon}
          </div>
          <div id={curentCss + "HEADER_RECEIPT_ID"} />
          <div id={curentCss + "HEADER_RECEIPT_NUM_1"}>XXXX</div>
          <div id={curentCss + "HEADER_RECEIPT_NUM_2"}>XXXX</div>
          <div id={curentCss + "HEADER_LOCATION_1"}>11</div>
          <div id={curentCss + "HEADER_LOCATION_2"}>11</div>
          <div id={curentCss + "HEADER_CASHIER_NUM_1"}>1</div>
          <div id={curentCss + "HEADER_CASHIER_NUM_2"}>1</div>

          <main className={curentCss + "receipt_content"}>
            <div id={curentCss + "SHOP_GOODS_NAME"} />
            <div id={curentCss + "SHOP_GOODS_AMOUNT"} />
            <div id={curentCss + "SHOP_UNIT_PRICE"} />
            <div id={curentCss + "SHOP_TAX_DESC"} />
            <div id={curentCss + "SHOP_PRICE_AND_CURRENCY"} />
          </main>

          <main className={curentCss + "item_wrapper"}>{rows}</main>
          <div id={curentCss + "ITEMS_COUNT_DESC"} />
          <div id={curentCss + "ITEMS_COUNT_NO_DECIMALS"}>
            {Number(cart_data.count_articles).toFixed(0)}
          </div>
          <div id={curentCss + "ITEMS_COUNT_ONE_DECIMAL"}>
            {Number(cart_data.count_articles).toFixed(1)}
          </div>
          <div id={curentCss + "ITEMS_COUNT_TWO_DECIMAL"}>
            {Number(cart_data.count_articles).toFixed(2)}
          </div>

          <div id={curentCss + "ROUNDING_DESC"} />
          <div id={curentCss + "ROUNDING"}>0.00</div>

          <div id={curentCss + "TAX_BASE_DESC"} />
          <div id={curentCss + "TAX_NAME_DESC"} />
          <div id={curentCss + "TAX_SUM_DESC"} />

          <div id={curentCss + "FOOTER_SUM_DESC"} />
          <div id={curentCss + "CART_TOTAL_PRICE"}>{cart_data.ctrl_total}</div>

          <main className={curentCss + "vat_grid_wrapper"}>{vat_rows}</main>

          <div id={curentCss + "BIG_TOTAL_PRICE_DESC"} />
          <div id={curentCss + "BIG_TOTAL_PRICE"}>{cart_data.ctrl_total}</div>

          <div id={curentCss + "SUM_WITHOUT_VAT_BIG_TOTAL_DESC"} />
          <div id={curentCss + "SUM_WITHOUT_VAT_BIG_TOTAL"}>
            {cart_data.sum_without_vat_big_total}
          </div>
          <div id={curentCss + "SUM_VAT_BIG_TOTAL_DESC"} />
          <div id={curentCss + "SUM_VAT_BIG_TOTAL"}>
            {cart_data.sum_vat_big_total}
          </div>

          <div id={curentCss + "CASH_SUM_TAKEN_DESC"} />
          <div id={curentCss + "CASH_SUM_TAKEN"}>{cart_data.cash_received}</div>

          <div
            className={curentCss + cash_to_return_class}
            id={curentCss + "CASH_TO_RETURN_DESC"}
          />
          <div
            className={curentCss + cash_to_return_class}
            id={curentCss + "CASH_TO_RETURN"}
          >
            {cart_data.cash_to_return}
          </div>

          <div id={curentCss + "FOOTER_RECEIPT_NUM_DESC"} />
          <div id={curentCss + "FOOTER_RECEIPT_NUM"}>0</div>

          <div id={curentCss + "CASHIER_IDENTIFIER_1"} />
          <div id={curentCss + "CASHIER_IDENTIFIER_2"}>1561626261</div>
          <div id={curentCss + "CASHIER_IDENTIFIER_2"}>515611621</div>
          <div id={curentCss + "PAYMENT_METHOD"}>
            {cart_data.payment_method}
          </div>
          <div id={curentCss + "PAYMENT_REGISTRATION_TYPE"}>online</div>

          <>
            <div id={curentCss + "FOOTER_LINE_1"} />
            <div id={curentCss + "FOOTER_LINE_2"} />
            <div id={curentCss + "FOOTER_LINE_3"} />
            <div id={curentCss + "FOOTER_LINE_4"} />
            <div id={curentCss + "FOOTER_LINE_5"} />
            <div id={curentCss + "FOOTER_LINE_6"} />
            <div id={curentCss + "FOOTER_LINE_7"} />
          </>
        </div>
      </div>
    );
  }
}
export default ReceiptTemplate;
