import React, { useEffect } from "react";
import SpeechRecognition from "react-speech-recognition";
import MicIcon from '@material-ui/icons/Mic'
import IconButton from "@material-ui/core/IconButton";

const options = {
    autoStart: false,
    continuous: false,
}

const Microphone = ({transcript, startListening, setSearchText}) => {
  const start = () => {
    startListening();
  }
  useEffect(()=>{
    setSearchText(transcript)
    // eslint-disable-next-line
  }, [transcript])
  
  if (!window.navigator.userAgent.includes("Android") && !window.navigator.userAgent.includes("Chrome")) {
    return null;
  }else{
    return (
      <IconButton
          className="icon-button"
          aria-label="search"
          onClick={() => start()}
          >
          <MicIcon />
      </IconButton>
    );
  }
  
  
};
  
export default SpeechRecognition(options)(Microphone)