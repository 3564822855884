import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import "./VaccinationsHorisontalTimeLineChart.css";

const loader = () => {
  return <div className="loader">Loading Data</div>;
};

const TimeLineChart = ({ target_hub_id, requestorHubID, apiRequest }) => {
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("Loading");

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = requestorHubID;
    send_credential_to_registrar.section = "";
    //variables for dynamic created query for setting permissions

    apiRequest(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          setData([]);
          setErrorMsg(result.data.error);
        } else {
          setData(result.data.data_array);
          setErrorMsg(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  const formatData = dataToFormat => {
    let resultData = [
      [
        { type: "string", id: "Name" },
        { type: "string", id: "Name" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" }
      ]
    ];

    dataToFormat.map(item => {
      let start = new Date(item.protection_start);
      let end = new Date(item.protection_end);
      return resultData.push([item.desease, item.desease, start, end]);
    });
    return resultData;
  };
  if (data.length !== 0) {
    return (
      <div className="main_chart_block">
        <div className="chart_labels">
          {data.map((item, id) => {
            if (id < data.length - 1) {
              return (
                <div className="chart_labels_item" key={id}>
                  {item.desease}
                </div>
              );
            } else {
              return <React.Fragment key={id}></React.Fragment>;
            }
          })}
        </div>
        <div className="chart_block">
          <Chart
            width={"300%"}
            height={"500px"}
            chartType="Timeline"
            loader={loader()}
            data={formatData(data)}
            options={{
              timeline: {
                colorByRowLabel: true,
                showRowLabels: false
              }
            }}
          />
        </div>
        {errorMsg !== false ? (
          <h5 style={{ margin: "20px 20px 50px 20px" }}>{errorMsg}</h5>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
export default TimeLineChart;
