import React, { useState, useEffect } from "react";
import "./ReceiptDisplay.css";
import "./shop_hub_default.css";
import MenuSlider from "./MenuSlider/MenuSlider";
import ReceiptTemplate from "./ReceiptTemplate";
import ReceiptDisplayModal from "./ReceiptDisplayModal";
import { Redirect } from "react-router-dom";
import InfoModal from "./InfoModal/InfoModal";
import {
  postReceiptToHub,
  postReceiptToShop
} from "../../services/POST_requests";
import { putReceiptToShop } from "../../services/PUT_requests";
import { BrowserQRCodeReader } from "@zxing/library";
import HomeIcon from "@material-ui/icons/Home";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";

function ReceiptDisplay() {
  const intl = useIntl();

  const [cart_data, setCart_data] = useState({});
  const [vat_data] = useState([]);
  const [receipt_items, setReceipt_items] = useState([]);

  const [curentCss, setCurentCss] = useState("default");
  const [receipt_loaded, setReceipt_loaded] = useState(false);
  const [scanNextQRC, setScanNextQRC] = useState(false);
  const [shop_id, setShop_id] = useState(false);
  const [shop_entry_id, setShop_entry_id] = useState(null);
  // const [hub_id, setHub_id] = useState(null);

  const [showScanner, setShowScanner] = useState(false);
  const [scanNextCounter, setScanNextCounter] = useState([]);
  const [modal, setModal] = useState(false);

  const [redirHUBsite, setRedirHUBsite] = useState(false);
  const [redirImpressum, setRedirImpressum] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    // console.log(state);
    // get_receipt_data();
    // qr_code_data_parser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (receipt_loaded) {
      send_shop_initial();
    }
    // eslint-disable-next-line
  }, [receipt_loaded]);

  // const get_receipt_data = () => {
  //   if (sessionStorage.getItem("receipt_data") !== null) {
  //     //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
  //     let sess_stor_temp = JSON.parse(sessionStorage.getItem("receipt_data"));

  //     setCart_data(sess_stor_temp.cart);
  //     setReceipt_items(sess_stor_temp.items);
  //     // vat_data: receiptJson.receipt_vat
  //     setSessionStorageDataLoaded(true);
  //   } else {
  //     console.log("no sess data");
  //     // commented out for test if disable will break anything
  //     // get_shop_info();
  //   }
  // };

  const save_receipt_to_hub = (param, param2) => {
    setModal(false);
    let payload = {
      cart: cart_data,
      items: receipt_items,
      hub_id: param
    };
    console.log(payload);

    postReceiptToHub(payload).then(result => {
      if (result.data.success) {
        payload.shop_id = "shop_hub_" + create_shop_hub(shop_id);
        payload.cart_id = shop_entry_id;
        putReceiptToShop(payload).then(result => {
          if (!result.data.success) {
            console.log("put shop err");
          }
          if (param2 === "new") {
            setRedirHUBsite(true);
          }
        });
      }
    });
  };

  const send_shop_initial = () => {
    if (["2", "3", "4"].indexOf(shop_id) !== -1) {
      setShop_entry_id(cart_data.id);
      let payload = {
        cart: cart_data,
        shop_id: "shop_hub_" + create_shop_hub(shop_id)
      };
      // console.log(payload);
      postReceiptToShop(payload).then(result => {
        if (!result.data.success) {
          console.log("post shop err");
        }
      });
    }
  };

  const handle_new_acc = param => {
    if (param !== null) {
      save_receipt_to_hub(param.my_hub_id, "new");
    }
  };

  const discard = () => {
    setCart_data({});
    setReceipt_items([]);
    setScanNextQRC(false);
    setReceipt_loaded(false);
    setScanNextCounter([]);
  };

  const loadcss = (param, id) => {
    if (["2", "3", "4"].indexOf(id) !== -1) {
      //load SHOP CSS based on shop name
      require("./" + param + ".css");

      setCurentCss("css" + parseInt(param.slice(-7).replace("_", ""), 10));
    }
  };

  const null_parser = param => {
    if (param === "") {
      return null;
    } else {
      return param;
    }
  };

  const create_shop_hub = param => {
    let num_len = param.length;
    let temp = "000000";
    let created = temp.slice(-6 + num_len) + param;
    let under = created.slice(0, 3) + "_" + created.slice(3, 6);
    return under;
  };

  const qr_code_data_parser = param => {
    let input_date = param;
    // let input_date =
    //   "C^409^4^2020-01-21 11:26:50^45232^44^28.42^3^1^0.00^454^Bookstore^Müller Büro & Buch^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^^I^4^1515^409^Bastelbedarf dd^9783499272134^2^0.00^0.00^15.20^15.20^19.00^St.^v^5654^EOS";
    // let input_date =
    //   "C^412^3^2020-01-29 08:57:57^41234^1^27.94^2^1^0.00^454^bookstore^Müller Büro & Buch^I^3^1515^412^Lob:Das Eifeler Märchenbuch^9783937795416^1^0.00^0.00^14.95^14.95^7.00^St.^e^978-3-937-79541-6^I^3^1515^412^Hör mal rein, wer kann das sein? Vögel^9783845824307^1^0.00^0.00^12.99^12.99^7.00^St.^e^978-3-845-82430-7^EOS";
    let split = input_date.split("^");
    if (split[0] === "C") {
      let cart_part = split.slice(0, 13);
      let items_part = split.slice(13);
      let item_count = Math.floor(items_part.length / 15);
      let item_arr = [];
      let arr_strt = 0;
      let receipt_end_code = items_part.slice(item_count * 15)[0];
      for (var i = 0; i < item_count; i++) {
        item_arr.push(items_part.slice(arr_strt, arr_strt + 15));
        arr_strt = arr_strt + 15;
      }

      // console.log(split);
      // console.log(cart_part);
      // console.log(items_part);

      loadcss("shop_hub_" + create_shop_hub(cart_part[2]), cart_part[2]);
      setShop_id(cart_part[2]);
      let cart_parsed = {
        id: cart_part[1],
        hub_transaction_id:
          "shop_hub_" +
          create_shop_hub(cart_part[2]) +
          "-carts-" +
          cart_part[1],
        issue_date: null_parser(cart_part[3]),
        receipt_id: null_parser(cart_part[4]),
        cashier_id: null_parser(cart_part[5]),
        ctrl_total: null_parser(cart_part[6]),
        ctrl_count_articles: null_parser(cart_part[7]),
        payment_method: null_parser(cart_part[8]),
        cash_received: null_parser(cart_part[9]),
        receipt_num: null_parser(cart_part[10]),
        type: null_parser(cart_part[11]),
        shop_name: null_parser(cart_part[12]),
        counterpart_hub_id: "shop_hub_" + create_shop_hub(cart_part[2])
      };

      let item_arr_parser = receipt_items;
      item_arr.forEach(item => {
        let item_parsed = {
          related_transaction_id:
            "shop_hub_" + create_shop_hub(item[1]) + "-carts-" + item[3],
          id: null_parser(item[2]),
          cart_id: null_parser(item[3]),
          article_name: null_parser(item[4]),
          ean: null_parser(item[5]),
          quantity: null_parser(item[6]),
          discount_percent: null_parser(item[7]),
          discount_abs: null_parser(item[8]),
          sale_price: null_parser(item[9]),
          total_price: null_parser(item[10]),
          vat_rate: null_parser(item[11]),
          unit: null_parser(item[12]),
          vat_category: null_parser(item[13]),
          free_parameter1: null_parser(item[14])
        };
        item_arr_parser.push(item_parsed);
      });

      // let parsed_qr = JSON.stringify({
      //   items: item_arr_parser,
      //   cart: cart_parsed
      // });

      // sessionStorage.setItem("receipt_data", parsed_qr);
      setCart_data(cart_parsed);
      setReceipt_items(item_arr_parser);
      setScanNextQRC(receipt_end_code === "EOS" ? false : true);
      setShowScanner(receipt_end_code === "EOS" ? false : true);
      setReceipt_loaded(receipt_end_code === "EOS" ? true : false);
      setScanNextCounter(
        receipt_end_code === "NSF" ? [...scanNextCounter, "ok"] : []
      );
    } else if (scanNextQRC) {
      let items_part = split.slice(0);
      let item_count = Math.floor(items_part.length / 15);
      let item_arr = [];
      let arr_strt = 0;
      let receipt_end_code = items_part.slice(item_count * 15)[0];
      for (var x = 0; x < item_count; x++) {
        item_arr.push(items_part.slice(arr_strt, arr_strt + 15));
        arr_strt = arr_strt + 15;
      }

      let item_arr_parser = receipt_items;
      item_arr.forEach(item => {
        let item_parsed = {
          related_transaction_id:
            "shop_hub_" + create_shop_hub(item[1]) + "-carts-" + item[3],
          id: null_parser(item[2]),
          cart_id: null_parser(item[3]),
          article_name: null_parser(item[4]),
          ean: null_parser(item[5]),
          quantity: null_parser(item[6]),
          discount_percent: null_parser(item[7]),
          discount_abs: null_parser(item[8]),
          sale_price: null_parser(item[9]),
          total_price: null_parser(item[10]),
          vat_rate: null_parser(item[11]),
          unit: null_parser(item[12]),
          vat_category: null_parser(item[13]),
          free_parameter1: null_parser(item[14])
        };
        item_arr_parser.push(item_parsed);
      });

      // let parsed_qr = JSON.stringify({
      //   items: item_arr_parser,
      //   cart: state.receipt_data
      // });

      // sessionStorage.setItem("receipt_data", parsed_qr);

      setReceipt_items(item_arr_parser);
      setScanNextQRC(receipt_end_code === "EOS" ? false : true);
      setShowScanner(receipt_end_code === "EOS" ? false : true);
      setReceipt_loaded(receipt_end_code === "EOS" ? true : false);
      setScanNextCounter(
        receipt_end_code === "NSF" ? [...scanNextCounter, "ok"] : []
      );
    } else {
      console.log("qr code parser error");
    }
  };

  const handleOpenScanner = () => {
    setShowScanner(true);
    // setScanner_result(null);

    ScannZxing();
  };

  const ScannZxing = () => {
    // let selectedDeviceId;
    const codeReader = new BrowserQRCodeReader();
    // console.log("ZXing code reader initialized");

    codeReader
      .decodeFromInputVideoDevice(undefined, "rec_disp_video")
      .then(result => {
        // console.log(result);
        if (result.text) {
          qr_code_data_parser(result.text);
          // setScanner_result(null);
          // setShowScanner(false);
        }
      })
      .catch(err => console.error("some error" + err));
  };

  const renderResults = () => {
    return (
      <div
        style={{
          position: "absolute",
          // backgroundColor: "#fff",
          left: "0",
          top: "15vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "100",
          width: "fit-content"
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "row",
            paddingRight: "10px",
            alignItems: "center",
            justifyContent: "center",
            width: "fit-content"
          }}
        >
          {scanNextCounter.map((item, id) => {
            return (
              <div
                key={id}
                style={{
                  margin: "10px",
                  width: "30px",
                  height: "30px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                {/* <img style={{ height: "15px", position: "absolute", bottom: "0", right: "0" }} src="assets/images/qr-code.png" alt="" /> */}
                {item === "ok" ? (
                  <>
                    <img
                      style={{ height: "28px" }}
                      src="assets/images/qr-code.png"
                      alt=""
                    />
                    <img
                      style={{
                        height: "20px",
                        position: "absolute",
                        bottom: "-3px",
                        right: "-7px",
                        zIndex: "100",
                        color: "#00ff00"
                      }}
                      alt=""
                      src="/assets/images/Done.svg"
                    />
                  </>
                ) : null}
              </div>
            );
          })}
          {scanNextQRC ? (
            <div style={{ position: "relative" }}>
              <img
                style={{ height: "28px" }}
                src="assets/images/qr-code.png"
                alt=""
              />
              <img
                style={{
                  height: "20px",
                  position: "absolute",
                  bottom: "-3px",
                  right: "-7px",
                  zIndex: "100",
                  color: "#00ff00"
                }}
                alt=""
                src="/assets/images/In process.svg"
              />
            </div>
          ) : null}
        </div>
        <img
          style={{
            height: "50px",
            position: "relative",
            zIndex: "100",
            marginLeft: "-1px"
          }}
          src="/assets/images/corner.png"
          alt=""
        />
      </div>
    );
  };

  return (
    <>
      {redirHUBsite ? (
        <Redirect
          push
          to={{
            pathname: "/HUBsite",
            state: { login_redirect_prop: "new" }
          }}
        />
      ) : null}
      {redirImpressum ? (
        <Redirect
          push
          to={{
            pathname: "/impressum"
          }}
        />
      ) : null}
      <div id="ReceiptDisplay">
        {scanNextCounter.length !== 0 ? renderResults() : null}
        {showScanner ? (
          <>
            <div className="rec_disp_cam_overlay">
              <video className="receipt_video" id="rec_disp_video"></video>
              {scanNextQRC ? (
                <div className="scan_next_qr_big" onClick={() => ScannZxing()}>
                  <img alt="" src="assets/images/qr-code.svg" />
                  {intl.formatMessage({
                    id: "receipt_display_scan_next_QR"
                  })}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div onClick={handleOpenScanner} className="rec_disp_scan_btn">
            <div className="rec_disp_scan_btn_text">
              <div className="receipt_display_center_btn">
                <img alt="scan" src="assets/images/qr-code.svg" />
                {intl.formatMessage({
                  id: "receipt_display_scan_QR"
                })}
              </div>
            </div>
          </div>
        )}
        {window.innerWidth < window.innerHeight ? (
          <img
            alt="logo"
            className="james_bon_logo"
            src="assets/images/JamesBon_logo.svg"
          />
        ) : null}
        {!showScanner ? (
          <img
            alt="info"
            className="receipt_display_info_btn"
            src="assets/images/info.svg"
            onClick={() => setShowInfoModal(true)}
          />
        ) : (
          <CloseIcon
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              color: "#fff",
              zIndex: "100",
              height: "40px",
              width: "40px"
            }}
            onClick={() => setShowScanner(false)}
          />
        )}
        <div className="back_btn">
          <a href="/HUBsite">
            <HomeIcon />
          </a>
        </div>
        <InfoModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
        />
        {receipt_loaded ? (
          <>
            <div className="rec_disp_receipt">
              <ReceiptTemplate
                cart_data={cart_data}
                vat_data={vat_data}
                receipt_items={receipt_items}
                curentCss={curentCss}
              />
            </div>
            <MenuSlider
              discard={discard}
              setModal={setModal}
              receipt_loaded={receipt_loaded}
              showScanner={showScanner}
              setShowScanner={setShowScanner}
            />
          </>
        ) : (
          <button
            onClick={() => {
              setRedirImpressum(true);
            }}
            className="receipt_display_impressum"
          >
            {intl.formatMessage({
              id: "receipt_display_ipressum"
            })}
          </button>
        )}
      </div>
      <ReceiptDisplayModal
        modal={modal}
        setModal={setModal}
        save_receipt_to_hub={save_receipt_to_hub}
        handle_new_acc={handle_new_acc}
      />
    </>
  );
}

export default ReceiptDisplay;
