import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const FilmsBarGraph = ({ apiData }) => {
  const [data, setData] = useState([]);
  const [barWidth, setBarWidth] = useState(300);

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(a.seen_on) - new Date(b.seen_on);
    });
    let chart = [["Date", "Value"]];
    let newBarWidth = 0;

    let counter = apiDataToMap.reduce(function(o, i) {
      if (!o.hasOwnProperty(i.seen_on)) {
        o[i.seen_on] = 0;
      }
      o[i.seen_on]++;
      return o;
    }, {});

    let result = Object.keys(counter).map(function(id) {
      return { id: id, sum: counter[id] };
    });

    result.map(item => {
      newBarWidth += 20;
      return chart.push([item.id, Number(item.sum)]);
    });
    setBarWidth(barWidth + newBarWidth);
    setData(chart);
  };

  useEffect(() => {
    parseApiData(); // eslint-disable-next-line
  }, [apiData.length]);

  if (apiData.length !== 0) {
    return (
      <div
        style={{
          width: "fit-content",
          maxWidth: "100%",
          overflow: "scroll",
          margin: "0px auto 20px",
          scrollBehavior: "smooth"
        }}
        id="chart"
      >
        <Chart
          style={{ margin: "0 auto" }}
          width={barWidth}
          height={"200px"}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={data}
          options={{
            vAxis: {
              title: "Intensity"
            },
            series: {
              1: { curveType: "function" }
            }
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};
export default FilmsBarGraph;
