import React from "react";
import "./Settings.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";

import LanguageSwitch from "../../LanguageSwitch";

import Impressum from "../Impressum/impressum";

class Settings extends React.Component {
  constructor() {
    super();
    this.state = { showModalID: false };
  }

  componentDidMount() {}

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  logoutredirect = () => {
    this.setState({
      redirectToLogout: true
    });
  };

  render() {
    //detect user default language
    //default is en en
    let current_language = "en";
    //declare supported languages
    let supported_language = "en,sk";
    //check if user language match supported and if so assign it to current language
    if (supported_language.includes(navigator.language)) {
      current_language = navigator.language.split(/[-_]/)[0];
    }

    //check if device is iPhone and display correct a2hs img tutorial
    let a2hs_img_path =
      "./assets/images/add_home_howto_" + current_language + ".jpg";
    if (
      window.navigator.userAgent.includes("iPhone") ||
      window.navigator.userAgent.includes("iPad")
    ) {
      a2hs_img_path =
        "./assets/images/add_home_apple_howto_" + current_language + ".jpg";
    }

    if (this.state.redirectToLogout) {
      return <Redirect to={"/logout"} />;
    }

    return (
      <div id="Settings">
        <img
          className="set_close_settings"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={this.props.settings_btn_callback}
        />
        <div className="set_content_wrapper">
          <div style={{ backgroundColor: "lightgray" }}>
            <LanguageSwitch />
          </div>
          <div className="set_logoutButton" onClick={this.logoutredirect}>
            <FormattedHTMLMessage id="navbar_logout_button" />
          </div>

          <div id="set_info_button" onClick={this.handleOpenModal}>
            About
          </div>
          <div>
            <Impressum />
          </div>
        </div>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="Minimal Modal Example"
          onRequestClose={this.handleCloseModal}
        >
          <span
            className="modal_info close"
            onClick={this.handleCloseModal}
            id="info_close"
          >
            <span>&times;</span>
          </span>
          <p className="modal_info_p1">
            <FormattedMessage id="cockpit_info_top_paragraf" />
          </p>
          <img className="modal_info_img" alt="" src={a2hs_img_path} />
          <p className="modal_info_p2">
            <FormattedMessage id="cockpit_info_bottom_paragraf" />
          </p>
        </Modal>
      </div>
    );
  }
}

export default Settings;
