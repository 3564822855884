import React from "react";
import { Redirect, Route } from "react-router-dom";

var hub_id = null;
var pin = null;

function checkLogin() {
  let urlParams = new URLSearchParams(window.location.search);
  if (sessionStorage.getItem("hub_token")) {
    return true;
  } else if (urlParams.get("hub_id") !== null) {
    hub_id = urlParams.get("hub_id");
    pin = urlParams.get("pin");
    return false;
  } else {
    return false;
  }
}

// this.props.history.push(
//   window.location.pathname +
//     "?hub_id=" +
//     JSON.parse(sessionStorage.getItem("hub_token"))
//       .userData.my_hub_id.slice(-5)
//       .replace("_", "") +
//     "&pin=" +
//     JSON.parse(sessionStorage.getItem("hub_token")).userData.temp_pin
// );

export const PrivateRoute = ({
  component: Component,
  orientationSwitchBtn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      checkLogin() ? (
        <Component {...props} orientationSwitchBtn={orientationSwitchBtn} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location, hub_id: hub_id, pin: pin }
          }}
        />
      )
    }
  />
);
