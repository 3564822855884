import { URLconst } from "./const";
import axios from "axios";

export function postAdminPassword(param) {
  let BaseURL = URLconst("hub_locations/admin_password.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    data: param
  });
}

export function putAdminPassword(param) {
  let BaseURL = URLconst("hub_locations/admin_password.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    data: param
  });
}

export function postBasics(param) {
  let BaseURL = URLconst("hub_basics/basics.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    data: param
  });
}

export function putBasics(param) {
  let BaseURL = URLconst("hub_basics/basics.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    data: param
  });
}
