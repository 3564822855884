import React, { useState, useEffect } from "react";
import { getCovid19Poll } from "../../services/GetPersonalHub";
import { postCovid19Ratings } from "../../services/PostHUBsiteData";
import { putCovid19Ratings } from "../../services/PUT_requests";
import moment from "moment";
import { useIntl } from "react-intl";

import update from "immutability-helper";

export default function Covid19Poll({
  query_params,
  ratingsBlocks,
  getCovid19RatingsData
}) {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [pollBlocks, setPollBlocks] = useState({});

  const [editAnswer, setEditAnswer] = useState({
    index: null,
    group_title: null
  });

  // console.log(ratingsBlocks);
  //   let history = useHistory();

  useEffect(() => {
    getPrompterData();
  }, []);

  // useEffect(() => {
  //   console.log(pollBlocks);
  // }, [pollBlocks]);

  useEffect(() => {
    if (data.length > 0) {
      let poll = {};

      data.forEach(d => {
        let temp_d = {};
        if (ratingsBlocks !== undefined) {
          let indexInColumnData = ratingsBlocks.findIndex(
            element => element.identified_by === d.id
          );
          temp_d = {
            ...d,
            ratings_id:
              indexInColumnData === -1
                ? null
                : ratingsBlocks[indexInColumnData].id,
            ratings_value:
              indexInColumnData === -1
                ? null
                : ratingsBlocks[indexInColumnData].rating
          };
        } else {
          temp_d = {
            ...d,
            ratings_id: null,
            ratings_value: null
          };
        }
        if (poll[d.group_num] === undefined) {
          poll = { ...poll, [d.group_num]: [temp_d] };
        } else {
          let temp = poll[d.group_num];
          temp.push(temp_d);
          poll = { ...poll, [d.group_num]: temp };
        }
      });
      // console.log(sortObjectProps(poll));
      setPollBlocks(sortObjectProps(poll));
    }
  }, [data, ratingsBlocks]);

  const sortObjectProps = obj => {
    const keys = Object.keys(obj).sort();
    let result = {};
    keys.map(key => {
      return (result[key] = obj[key]);
    });
    return result;
  };

  const getPrompterData = () => {
    getCovid19Poll().then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        setData(result.data.data_array);
      }
    });
  };

  const pollBlock = (pollQ, group) => {
    // console.log(pollQ);
    return (
      <div className={"cov19_poll_question_block"}>
        <div className={"cov19_poll_question_block_title"}>
          {pollQ[0].group_num !== null &&
          pollQ[0].group_num !== "" &&
          pollQ[0].group_num !== undefined
            ? intl.formatMessage({
                id: "covid_poll_group_title_db_" + pollQ[0].group_num
              })
            : pollQ[0].group_num}
        </div>
        {pollQ.map((item, i) => {
          if (!item) {
            return null;
          } else {
            return (
              <div className={"cov19_poll_question_block_content"} key={i}>
                <div className={"cov19_poll_options_question"}>
                  <span className="covid_question_small_label">
                    {item.question_num}
                  </span>
                  {item.question_num !== null &&
                  item.question_num !== "" &&
                  item.question_num !== undefined
                    ? intl.formatMessage({
                        id:
                          "covid_poll_group_question_db_" +
                          item.group_num +
                          "_" +
                          item.question_num
                      })
                    : item.question_num}
                </div>
                <div className={"cov19_poll_options_container"}>
                  <div className={"cov19_poll_options_wrapper"}>
                    {responseOptions(item, i, group)}
                  </div>
                  <div className={"cov19_poll_options_label_wrapper"}>
                    <div>
                      {intl.formatMessage({
                        id: "covid_poll_strongly_disagree"
                      })}
                    </div>
                    <div>
                      {intl.formatMessage({
                        id: "covid_poll_strongly_agree"
                      })}
                    </div>
                  </div>
                  <div className={"cov19_poll_options_bottom_btn_wrapper"}>
                    <div>
                      {item.ratings_id === null ||
                      (editAnswer.index === i &&
                        editAnswer.group_title === item.group_title) ? (
                        <div
                          className={"cov19_poll_options_bottom_btn"}
                          onClick={() => {
                            handleOptionClick(item, 0);
                            setEditAnswer({ index: null, group_title: null });
                          }}
                        >
                          {intl.formatMessage({
                            id: "covid_poll_no_answer"
                          })}
                        </div>
                      ) : null}
                    </div>

                    {item.ratings_id !== null ? (
                      <div
                        className={"cov19_poll_options_bottom_btn"}
                        onClick={() => {
                          setEditAnswer({
                            group_title: pollQ[0].group_title,
                            index: i
                          });
                        }}
                      >
                        {intl.formatMessage({
                          id: "covid_poll_edit"
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleOptionClick = (item, val) => {
    let payload = query_params;
    payload.data = {};
    payload.data.entry_type = "covid-19";
    payload.data.subject_type = "poll";
    payload.data.subject = item.question_num;
    payload.data.rating = val;
    payload.data.explanation = item.question_text;
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.keywords = item.group_num;
    payload.data.identified_by = item.id;
    if (item.ratings_id !== null) {
      payload.data.id = item.ratings_id;
      putCovid19Ratings(payload).then(result => {
        // console.log(result);
        getCovid19RatingsData();
      });
    } else {
      postCovid19Ratings(payload).then(result => {
        // console.log(result);
        getCovid19RatingsData();
      });
    }
  };

  const responseOptions = (param, q_index, group) => {
    // console.log(param);
    let options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let answered = false;
    let edit = false;
    if (param.ratings_value !== null) {
      answered = true;
    }
    if (editAnswer !== null) {
      if (
        editAnswer.group_title === param.group_title &&
        editAnswer.index === q_index
      ) {
        edit = true;
      }
    }
    return options.map((item, i) => {
      let color = "covid19_red";
      if (item > 7) {
        color = "covid19_green";
      } else if (item > 3) {
        color = "covid19_blue";
      }
      let opaque = "";
      if (answered && item !== Number(param.ratings_value) && !edit) {
        opaque = "covid19_opaque";
      }
      if (answered && item === Number(param.ratings_value) && edit) {
        opaque = "";
        color = "covid19_white";
      }

      return (
        <div
          key={i}
          className={`cov19_poll_option ${color} ${opaque}`}
          onClick={
            answered && !edit
              ? null
              : () => {
                  setPollBlocks(
                    sortObjectProps({
                      ...pollBlocks,
                      [group]: update(pollBlocks[group], {
                        [q_index]: {
                          ratings_value: { $set: item }
                        }
                      })
                    })
                  );
                  handleOptionClick(param, item);
                  setEditAnswer({ index: null, group_title: null });
                }
          }
        >
          <span>{item}</span>
        </div>
      );
    });
  };

  return (
    <>
      <div className="cov19_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid_poll_title_pt1"
          })}
        </span>{" "}
        {intl.formatMessage({
          id: "covid_poll_title_pt2"
        })}
      </div>
      {Object.keys(pollBlocks).length > 0
        ? Object.keys(pollBlocks).map((item, i) => {
            if (!item) {
              return null;
            } else {
              return (
                <React.Fragment key={i}>
                  {pollBlock(pollBlocks[item], item)}
                </React.Fragment>
              );
            }
          })
        : null}
    </>
  );
}
