import React, { Component } from "react";
import "./BookcolInsertBook.css";
import Rating from "react-rating";
import { FormattedMessage } from "react-intl";
import { InsertEntertainment } from "../../services/InsertEntertainment";
import Modal from "react-modal";
import AvatarEditor from "react-avatar-editor";
import { injectIntl } from "react-intl";
import ScanApp from "../../components/Scanner/ScanApp";
import { Link, Redirect } from "react-router-dom";
import Content from "../../components/Content";

// bib_ prefix for BookcolSingleBook

class BookcolSingleBook extends Component {
  constructor() {
    super();
    this.state = {
      book: [],
      loading: true,
      update_enabled: false,
      insert_enabled: false,
      form_has_changed: false,

      // book states for database read/write
      id: null,
      kind: null,
      title: null,
      poster: null,
      series: null,
      link: null,
      rating: null,
      start_date: null,
      end_date: null,
      language: null,
      status: null,
      progress: null,
      subtitles_language: null,
      possession: null,
      possession_related_to: null,
      format: null,
      external_id_1: null,
      external_id_2: null,
      note: null,

      //avatar modal states
      image: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 160,
      height: 250,

      redirectToBookcollector: false
    };
  }

  componentDidMount() {}

  insertNewEntertainment = () => {
    if (this.state.title !== null) {
      let send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.passed_id = this.state.id;
      send_credential_to_registrar.kind = "book";
      send_credential_to_registrar.title = this.state.title;
      send_credential_to_registrar.poster = this.state.poster;
      send_credential_to_registrar.series = this.state.series;
      send_credential_to_registrar.link = this.state.link;
      send_credential_to_registrar.rating = this.state.rating;
      send_credential_to_registrar.start_date = this.state.start_date;
      send_credential_to_registrar.end_date = this.state.end_date;
      send_credential_to_registrar.language = this.state.language;
      send_credential_to_registrar.status = this.state.status;
      send_credential_to_registrar.progress = this.state.progress;
      send_credential_to_registrar.subtitles_language = this.state.subtitles_language;
      send_credential_to_registrar.possession = this.state.possession;
      send_credential_to_registrar.possession_related_to = this.state.possession_related_to;
      send_credential_to_registrar.format = this.state.format;
      send_credential_to_registrar.external_id_1 = this.state.external_id_1;
      send_credential_to_registrar.external_id_2 = this.state.external_id_2;
      send_credential_to_registrar.note = this.state.note;

      InsertEntertainment(send_credential_to_registrar).then(result => {
        let receiptJson = result;
        if (receiptJson.success) {
          //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
          this.setState({ redirectToBookcollector: true });
        }
      });
    }
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  scanner_callback = (ISBN, closeModal, formChange) => {
    this.setState({
      external_id_1: ISBN,
      showModal: closeModal
    });
  };

  saveUpdatedValues = () => {
    if (this.state.form_has_changed === true) {
      this.set_rating();
    }
    this.setState({ update_enabled: false });
  };

  inputHandleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, function() {
      this.setState({ form_has_changed: true });
    });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOpenPosterModal = () => {
    this.setState({ showPosterModal: true });
  };

  handleClosePosterModal = () => {
    this.setState({ showPosterModal: false });
  };

  scanner_callback = (ISBN, closeModal, formChange) => {
    this.setState({
      external_id_1: ISBN,
      showModal: closeModal,
      form_has_changed: formChange
    });
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] });
  };

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState(
      {
        preview: {
          img,
          rect,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          borderRadius: this.state.borderRadius
        }
      },
      function() {
        this.setState({
          poster: this.state.preview.img,
          showPosterModal: false,
          form_has_changed: true
        });
      }
    );
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  render() {
    if (this.state.redirectToBookcollector) {
      return <Redirect to={"/bookcollector"} />;
    }

    const { intl } = this.props;

    let cover_image_link;
    let read_only_stars;

    let update_class;
    let input_books_disabled;
    let button_scan_ISBN;

    // let button_save_invisible;
    // let button_add_poster_invisible;
    // let input_date_invisible;
    // let timeline_date;
    // let button_add_invisible;
    // let no_books;
    // let ribbon_img;
    // let endDate;
    // let startDate;
    // let numOfDays;

    if (this.state.poster === null) {
      cover_image_link = "/assets/images/missing-image.png";
    }

    return (
      <Content>
        <div id="BookcolSingleBook">
          <img
            id="bib_top_bookshelf_bg"
            alt=""
            src="/assets/images/library_img_2.jpg"
          />
          <div className="bib_round_corner_background">
            <div className="bib_book_data_wrapper">
              <span className="bib_book_img_wrapper">
                <img alt="" src={cover_image_link} />
                <span
                  className={"button_add_poster"}
                  onClick={this.handleOpenPosterModal}
                >
                  +
                </span>
              </span>
              <div className="bib_book_title_and_edit">
                <textarea
                  className={"bib_book_title"}
                  type="text"
                  rows="2"
                  name="title"
                  placeholder={intl.formatMessage({
                    id: "bookcoll_plhold_title"
                  })}
                  maxLength="75"
                  value={this.checkIfNull(this.state.title)}
                  onChange={this.inputHandleChange}
                />
              </div>
              <div className="bib_book_author">{this.state.Author}</div>
              <div className={"Rating_stars "}>
                <Rating
                  initialRating={this.state.rating}
                  stop={10}
                  onClick={rate =>
                    this.setState({ rating: rate }, function() {
                      this.setState({ form_has_changed: true });
                    })
                  }
                  readonly={read_only_stars}
                  emptySymbol={<span className="star">&#x2606;</span>}
                  fullSymbol={<span className="star">&#x2605;</span>}
                />
              </div>
              <div className="bib_border_box">
                <div>
                  <strong>ISBN: </strong>
                  <input
                    disabled={input_books_disabled}
                    className={"book_standard_input " + update_class}
                    type="text"
                    name="external_id_1"
                    placeholder={intl.formatMessage({
                      id: "bookcoll_plhold_isbn"
                    })}
                    maxLength="40"
                    value={this.checkIfNull(this.state.external_id_1)}
                    onChange={this.inputHandleChange}
                  />
                  <img
                    onClick={this.handleOpenModal}
                    className={"bookcol_scan_img " + button_scan_ISBN}
                    alt=""
                    id="scan_ISBN"
                    src="/assets/images/scan_btn_icon.png"
                  />
                </div>
                <div>
                  <strong>Format: </strong>{" "}
                  <input
                    disabled={input_books_disabled}
                    className={"book_standard_input " + update_class}
                    type="text"
                    name="format"
                    placeholder={intl.formatMessage({
                      id: "bookcoll_plhold_format"
                    })}
                    maxLength="50"
                    value={this.checkIfNull(this.state.format)}
                    onChange={this.inputHandleChange}
                  />
                </div>
                <div>
                  <strong>Language: </strong>{" "}
                  <input
                    className={"book_standard_input"}
                    type="text"
                    name="language"
                    placeholder={intl.formatMessage({
                      id: "bookcoll_plhold_language"
                    })}
                    maxLength="30"
                    value={this.checkIfNull(this.state.language)}
                    onChange={this.inputHandleChange}
                  />
                </div>
              </div>
              <div className="bib_box">
                <div>
                  <strong>Note: </strong>
                  <div>
                    <textarea
                      className={"bib_bottom_box_input "}
                      type="text"
                      name="note"
                      placeholder={intl.formatMessage({
                        id: "bookcoll_plhold_note"
                      })}
                      maxLength="500"
                      value={this.checkIfNull(this.state.note)}
                      onChange={this.inputHandleChange}
                    />
                  </div>
                </div>

                <div>
                  <strong>Possession: </strong>
                  <select
                    className={"bib_bottom_box_input "}
                    value={this.checkIfNull(this.state.possession)}
                    onChange={this.inputHandleChange}
                    name="possession"
                  >
                    <option value="own">
                      {intl.formatMessage({ id: "bookcoll_option_own" })}
                    </option>
                    <option value="gift to">
                      {intl.formatMessage({ id: "bookcoll_option_gift_to" })}
                    </option>
                    <option value="gift from">
                      {intl.formatMessage({ id: "bookcoll_plhold_gift_from" })}
                    </option>
                    <option value="lent">
                      {intl.formatMessage({ id: "bookcoll_plhold_lent" })}
                    </option>
                    <option value="borrowed">
                      {intl.formatMessage({ id: "bookcoll_plhold_borrowed" })}
                    </option>
                  </select>
                </div>

                <div>
                  <strong>Possession related to: </strong>
                  <input
                    onChange={this.inputHandleChange}
                    className={"bib_bottom_box_input "}
                    type="text"
                    name="possession_related_to"
                    placeholder={intl.formatMessage({
                      id: "bookcoll_plhold_possession_rel_to"
                    })}
                    title="possession related to"
                    value={this.checkIfNull(this.state.possession_related_to)}
                  />
                </div>

                <div className="bookcollector_bottom_navigation">
                  <button onClick={this.insertNewEntertainment}>
                    <FormattedMessage id="bookcoll_add_button" />
                  </button>
                  <Link to="/bookcollector">
                    <button className={"button_cancel_class"}>
                      <FormattedMessage id="bookcoll_cancel_button" />
                    </button>
                  </Link>
                </div>
              </div>
              <div
                style={{
                  height: "60px"
                }}
              />
            </div>
          </div>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.5)"
              },
              content: {
                background: "rgba(255,255,255,0.6)",
                top: "3%",
                right: "3%",
                left: "3%",
                bottom: "3%"
              }
            }}
          >
            <span
              className="modal_info close"
              onClick={this.handleCloseModal}
              id="info_close"
            >
              <span>&times;</span>
            </span>
            <ScanApp callbackFromParent={this.scanner_callback} />
          </Modal>
          <Modal
            isOpen={this.state.showPosterModal}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.5)"
              },
              content: {
                background: "rgba(255,255,255,0.6)",
                top: "3%",
                right: "3%",
                left: "3%",
                bottom: "3%"
              }
            }}
          >
            <span
              className="modal_info close"
              onClick={this.handleClosePosterModal}
              id="info_close"
            >
              <span>&times;</span>
            </span>
            <div id="avatar_grid_container">
              <div id="avatar_editor_preview">
                <AvatarEditor
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={this.handlePositionChange}
                  rotate={parseFloat(this.state.rotate)}
                  borderRadius={
                    this.state.width / (100 / this.state.borderRadius)
                  }
                  onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
                  onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
                  onImageReady={this.logCallback.bind(this, "onImageReady")}
                  image={this.state.image}
                  style={{
                    width: "100%",
                    height: "auto",
                    gridArea: "left1",
                    margin: "auto 0"
                  }}
                  className="avatar_editor_canvas"
                />
                <div className="avatar_editor_rotate">
                  <button onClick={this.rotateRight}>&#8635;</button>
                </div>

                <div className="avatar_editor_zoom">
                  <input
                    name="scale"
                    type="range"
                    onChange={this.handleScale}
                    min={this.state.allowZoomOut ? "0.1" : "1"}
                    max="2"
                    step="0.01"
                    defaultValue="1"
                  />
                </div>
              </div>

              <div className="avatar_grid_right_column_grid_container">
                <div className="avatar_editor_selectfile">
                  <input
                    name="newImage"
                    type="file"
                    onChange={this.handleNewImage}
                  />
                </div>

                <div className="avatar_editor_submit">
                  <input
                    type="button"
                    onClick={this.handleSave}
                    value="Upload"
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Content>
    );
  }
}

export default injectIntl(BookcolSingleBook);
