import React, { useState } from "react";
import "./loginCovid.css";
import { useIntl } from "react-intl";

export default function LoginCovidTop() {
  const [expand, setExpand] = useState(false);
  const intl = useIntl();

  return (
    <div className="loginCovid_container">
      <div className="loginCovid_wrapper">
        <img
          className="loginCovid_img"
          src="https://www.hub-web.com/assets/images/avtar/1.jpg"
          alt="Dalibor Sevcik"
        />

        <div className="loginCovid_text_block_top">
          {intl.formatMessage({
            id: "covid_login_welcome_part1"
          })}
          <span
            style={{ color: "#3FA4FF", fontWeight: "500" }}
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? (
              <>
                {" "}
                {intl.formatMessage({
                  id: "covid_login_welcome_read_less"
                })}
              </>
            ) : (
              <>
                {" "}
                {intl.formatMessage({
                  id: "covid_login_welcome_read_more"
                })}
              </>
            )}
          </span>
        </div>
      </div>

      <div
        className={
          "loginCovid_expand_wrapper " + (!expand ? "loginCovid_colapsed" : "")
        }
      >
        <div className="loginCovid_text_block">
          {intl.formatMessage({
            id: "covid_login_welcome_part2"
          })}{" "}
          <a href="/about/covid19"> -></a>.
        </div>
        <div className="loginCovid_text_block">
          {intl.formatMessage({
            id: "covid_login_welcome_part3"
          })}{" "}
          <span role="img" aria-label="smile">
            😊
          </span>
          .
        </div>
        <div className="loginCovid_text_block">
          {intl.formatMessage({
            id: "covid_login_welcome_part4"
          })}{" "}
          <a
            href="http://www.moveo-prevent.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dr. med. Alessandra Boscheri
          </a>
          {intl.formatMessage({
            id: "covid_login_welcome_part5"
          })}
        </div>
        <div className="loginCovid_text_block">
          <div style={{ marginBottom: "10px" }}>
            {intl.formatMessage({
              id: "covid_login_welcome_part6"
            })}
          </div>
          <ul>
            <li>
              {intl.formatMessage({
                id: "covid_login_welcome_part10"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_login_welcome_part11"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_login_welcome_part12"
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "covid_login_welcome_part13"
              })}
            </li>
          </ul>
        </div>
        <div className="loginCovid_text_block">
          {intl.formatMessage({
            id: "covid_login_welcome_part7"
          })}
        </div>
        <div className="loginCovid_text_block">
          <div>
            {" "}
            {intl.formatMessage({
              id: "covid_login_welcome_part8"
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: "covid_login_welcome_part9"
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
