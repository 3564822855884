import React, { useState, useEffect } from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import { get as _get } from "lodash";
import moment from "moment";

const HorizontalTimelineChart = ({ apiData }) => {
  const [state, setState] = useState({ value: 0, previous: 0 });
  const [dates, setDates] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    parseData();
    //eslint-disable-next-line
  }, [apiData.length]);

  const parseData = () => {
    let apiDates = [];
    let apiNotes = [];

    let sortedApiData = apiData.sort(function(a, b) {
      return new Date(a.date_on) - new Date(b.date_on);
    });

    [...sortedApiData].forEach(item => {
      if (moment(item.date_on, moment.ISO_8601, true).isValid()) {
        const date = new Date(item.date_on.replace(" ", "T"));
        apiDates = [...apiDates, date];
        apiNotes = [
          ...apiNotes,
          {
            note: item.personal_note
          }
        ];
      }
      // eslint-disable-next-line
    });

    setNotes(apiNotes);
    setDates(apiDates);
  };
  console.log(state);
  if (apiData.length === 0) {
    return <div>No data available</div>;
  } else {
    return (
      <div style={{ marginBottom: "100px" }}>
        {/* Bounding box for the Timeline */}
        <div style={{ width: "95%", height: "200px", margin: "0 auto" }}>
          <HorizontalTimeline
            index={state.value}
            indexClick={index => {
              setState({ value: index, previous: state.value });
            }}
            labelWidth={100}
            values={dates}
          />
        </div>
        <div className="text-center">
          <div>
            <b>Note:</b> {_get(notes, `[${state.value}].note`, "")}
          </div>
        </div>
      </div>
    );
  }
};
export default HorizontalTimelineChart;
