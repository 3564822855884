import React, { useState } from "react";
import "../VirtualAssistant";
import "./message.scss";
import Rating from "react-rating";
import NavMap from "../../../components/Navigator/NavMap";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const MessageItem = ({
  shop_basics,
  item,
  id,
  remove_search_item,
  repeat_search,
  set_rating_callback,
  user_avatar,
  user_info
}) => {
  const [ratingSize, setRatingSize] = useState(10);
  const [rating, setRating] = useState(item.rating !== null ? item.rating : 0);
  const [rateStartBg, setRateStarsBg] = useState("fff");
  const [rateStarsStyle, setRateStarsStyle] = useState();
  const [rateMsgDisplay, setRateMsgDisplay] = useState("");
  const [slideOutLeft, setSlideOutLeft] = useState("");
  const [slideOutTopLeft] = useState("");
  const [showTrash, setShowTrash] = useState("");
  const handleRatingClick = value => {
    setRatingSize(5);
    setRating(value / 2);
    setRateStarsBg("cce7f8");
    setRateStarsStyle({
      width: "100%",
      display: "flex",
      justifyContent: "flex-end"
    });
    setRateMsgDisplay("none");
    set_rating_callback(value, id);
  };

  if (item.type === "date") {
    return (
      <div className="chat-date-divider" id={`class_${id}`}>
        {item.value}
      </div>
    );
  }

  var time_extracted = moment(item.search_time).format("HH:mm");

  var nickname_to_use = <FormattedMessage id="virtual_assistant_user" />;

  if (user_info !== null) {
    if (user_info.nickname !== "") {
      nickname_to_use = user_info.nickname;
    }
  }
  if (item.type === "map") {
    return (
      <div id={`class_${id}`} className="message-item-left">
        <div className="message-info">
          <div className="message-info-text">
            <div className="message-info-text-title">
              {shop_basics.shop_assistant_name !== ""
                ? shop_basics.shop_assistant_name
                : "Peter"}
            </div>
            <div>{time_extracted}</div>
          </div>
          <div className="message-avatar">
            {item.role === "user" ? (
              <img
                src={
                  user_avatar !== null
                    ? user_avatar
                    : "/assets/images/user_icon.svg"
                }
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/user_icon.svg";
                }}
                alt=""
              />
            ) : (
              <img
                src={shop_basics.shop_assistant_link}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/assistant_simmel.png";
                }}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="chat-map_preview" id={`class_${id}`}>
          {/* <img src={item.value} alt="map" /> */}
          <NavMap
            top_offset={item.position_top}
            left_offset={item.position_left}
            position_width={item.position_width}
            position_height={item.position_height}
            floor_plan={item.value}
            kind={item.kind}
            id={id}
          />
        </div>
        {item.rating !== false ? (
          <div
            className="rate-block"
            style={
              item.rating !== null
                ? { width: "100%", display: "flex", justifyContent: "flex-end" }
                : rateStarsStyle
            }
          >
            <div
              className="rate-message"
              style={
                item.rating !== null
                  ? { display: "none" }
                  : { display: rateMsgDisplay }
              }
            >
              <FormattedMessage id="virtual_assistant_rate_experience" />
            </div>
            <div
              className="rate-stars"
              style={
                item.rating !== null
                  ? { backgroundColor: "#cce7f8" }
                  : { backgroundColor: `#${rateStartBg}` }
              }
            >
              {item.rating !== null ? (
                <Rating
                  initialRating={item.rating / 2}
                  stop={5}
                  readonly={true}
                  emptySymbol={<img src="/assets/images/Star.svg" alt="star" />}
                  fullSymbol={
                    <img src="/assets/images/Star filled.svg" alt="star" />
                  }
                />
              ) : (
                <Rating
                  initialRating={rating}
                  stop={ratingSize}
                  onClick={value => handleRatingClick(value)}
                  readonly={ratingSize === 10 ? false : true}
                  emptySymbol={<img src="/assets/images/Star.svg" alt="star" />}
                  fullSymbol={
                    <img src="/assets/images/Star filled.svg" alt="star" />
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  const playAnimation = () => {
    setShowTrash("slide-trash-in");
    var oldclass = document.getElementById(`class_${id + 1}`).className;
    setTimeout(() => {
      setSlideOutLeft("slide-out-left");
      const v = document.getElementById(`class_${id + 1}`);
      v.className += " slide-out-top-left";
    }, 250);
    setTimeout(() => setShowTrash("slide-trash-out"), 750);
    setTimeout(() => {
      remove_search_item(item.array_index);
      // after deleteing item class remain so we need to clean class from animation
      if (document.getElementById(`class_${id + 1}`)) {
        document.getElementById(`class_${id + 1}`).className = oldclass;
        setSlideOutLeft("");
      }
    }, 1500);
  };

  return (
    <>
      <div className={"trash_block " + showTrash}>
        <img src="/assets/images/trash_icon.png" alt="trash icon" />
      </div>
      <div
        id={`class_${id}`}
        className={
          item.role === "user"
            ? "message-item-right " + slideOutLeft
            : "message-item-left " + slideOutTopLeft
        }
      >
        <div className="message-info">
          <div className="message-info-text">
            <div className="message-info-text-title">
              {item.role === "user" ? nickname_to_use : item.role}
            </div>
            <div>{time_extracted}</div>
          </div>
          <div className="message-avatar">
            {item.role === "user" ? (
              <img
                src={
                  user_avatar !== null
                    ? user_avatar
                    : "/assets/images/user_icon.svg"
                }
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/user_icon.svg";
                }}
                alt=""
              />
            ) : (
              <img
                src={shop_basics.shop_assistant_link}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/assistant_simmel.png";
                }}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="message-block">
          <div className="message-settings">
            <div className="message-settings-icon">
              <img
                onClick={() => {
                  playAnimation();
                }}
                src="/assets/images/Delete.svg"
                alt=""
              />
            </div>
            <div className="message-settings-icon">
              <img
                onClick={() => {
                  repeat_search(item.value);
                }}
                src="/assets/images/Update.svg"
                alt=""
              />
            </div>
          </div>
          <div className="message-value">{item.value}</div>
        </div>
        {item.type === "error" ? (
          <>
            <button className="message-option"> Order with pickup here</button>
            <button className="message-option"> Order to send home</button>
            <button className="message-option">
              {" "}
              Add to next shopping list
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default MessageItem;
