import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import {
  getCovid19Ratings,
  getCovid19MedValData,
  getCovid19PeopDetails
} from "../../services/GetHUBsiteData";
import "./covid19.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import CovidImportantInfo from "./CovidImportantInfo";
import Covid19Poll from "./Covid19Poll";
import Covid19InfectionRisk from "./Covid19InfectionRisk";
import Covid19OverallIllness from "./Covid19OverallIllness";
import Covid19IllnessDiary from "./Covid19IllnessDiary";
import Covid19WelllDone from "./Covid19WelllDone";
import Covid19Welcome from "./Covid19Welcome";
import { useIntl } from "react-intl";

export default function Covid19(props) {
  const intl = useIntl();
  const [ratingsData, setRatingsData] = useState([]);
  const [medValData, setMedValData] = useState([]);
  const [peopDetData, setPeopDetData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [redirectHome, setRedirectHome] = useState(false);
  const [ratingsBlocks, setRatingsBlocks] = useState({
    interview: [],
    poll: []
  });

  const pages = [1, 2, 3, 4, 5, 6];

  var query_params = {};
  if (!sessionStorage.getItem("hub_token")) {
    query_params.logged_in = null;
  } else {
    query_params.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }
  query_params.section = "covid-19";
  query_params.target_hub_id = query_params.logged_in.my_hub_id;
  query_params.requestor_hub_id = query_params.target_hub_id;

  // let history = useHistory();
  useEffect(() => {
    if (props.match.params.page === undefined) {
      props.history.push("/covid19/1");
    } else {
      if (pages.indexOf(Number(props.match.params.page)) === -1) {
        props.history.push("/covid19/1");
      }
      setCurrentPage(Number(props.match.params.page));
    }

    if (redirectHome) {
      props.history.push("/HUBsite");
    }
  }, [props.match.params.page, props.history, pages, redirectHome]);

  useEffect(() => {
    getCovid19RatingsData();
    getCovid19MedVal();
    getCovid19PeopDet();
    // eslint-disable-next-line
  }, []);

  const getCovid19RatingsData = () => {
    getCovid19Ratings(query_params).then(result => {
      if (result.data.data_array) {
        setRatingsData(result.data.data_array);
      } else {
        console.log(result.data.error);
      }
    });
  };

  const getCovid19MedVal = () => {
    getCovid19MedValData(query_params).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        setMedValData(result.data.data_array);
      }
    });
  };

  const getCovid19PeopDet = () => {
    getCovid19PeopDetails(query_params).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        setPeopDetData(result.data.data_array);
      }
    });
  };

  useEffect(() => {
    if (ratingsData.length > 0) {
      let ratings = {};
      ratingsData.forEach(d => {
        if (ratings[d.subject_type] === undefined) {
          ratings = { ...ratings, [d.subject_type]: [d] };
        } else {
          let temp = ratings[d.subject_type];
          temp.push(d);
          ratings = { ...ratings, [d.subject_type]: temp };
        }
      });
      // console.log(ratings);
      setRatingsBlocks(ratings);
    }
  }, [ratingsData]);

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return <Covid19Welcome />;
      case 2:
        return (
          <Covid19InfectionRisk
            query_params={query_params}
            medValData={medValData}
            getCovid19MedVal={getCovid19MedVal}
            peopDetData={peopDetData}
            getCovid19PeopDet={getCovid19PeopDet}
          />
        );
      case 3:
        return (
          <Covid19Poll
            query_params={query_params}
            ratingsBlocks={ratingsBlocks.poll}
            getCovid19RatingsData={getCovid19RatingsData}
          />
        );
      case 4:
        return (
          <Covid19OverallIllness
            query_params={query_params}
            medValData={medValData}
            getCovid19MedVal={getCovid19MedVal}
          />
        );
      case 5:
        return (
          <Covid19IllnessDiary
            query_params={query_params}
            medValData={medValData}
            getCovid19MedVal={getCovid19MedVal}
          />
        );
      case 6:
        return (
          <Covid19WelllDone
            medValData={medValData}
            peopDetData={peopDetData}
            ratingsData={ratingsData}
          />
        );
      default:
        return null;
    }
  };

  const nextPage = () => {
    if (currentPage < pages.length) {
      props.history.push("/covid19/" + (currentPage + 1));
      window.scrollTo(0, 0);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      props.history.push("/covid19/" + (currentPage - 1));
      window.scrollTo(0, 0);
    }
  };
  const handleDotClick = param => {
    props.history.push("/covid19/" + param);
    window.scrollTo(0, 0);
  };

  const pageDots = () => {
    return (
      <div style={{ display: "flex" }}>
        {pages.map((item, i) => {
          return (
            <div
              key={i}
              style={{
                height: "12px",
                width: "12px",
                border: "1px solid #3FA4FF",
                borderRadius: "50%",
                backgroundColor: currentPage === item ? "#3FA4FF" : "white",
                margin: "4px",
                cursor: "pointer"
              }}
              onClick={() => {
                handleDotClick(item);
              }}
            ></div>
          );
        })}
      </div>
    );
  };

  const renderHeader = () => {
    switch (currentPage) {
      case 1:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div className="cov19_prev"></div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="cov19_nav_top">
              <div
                className="cov19_nav_top_home_btn"
                onClick={() => {
                  setRedirectHome(true);
                }}
              >
                <div className="cov19_nav_top_home_text">
                  {intl.formatMessage({
                    id: "covid_back_to_home"
                  })}
                </div>
              </div>
              <div className="cov19_nav_top_title">
                {intl.formatMessage({
                  id: "covid_19"
                })}
              </div>
            </div>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div className="cov19_next"></div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  const renderFooter = () => {
    switch (currentPage) {
      case 1:
        return (
          <>
            <div className="cov19_nav_bott">
              <div className="cov19_prev"></div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="cov19_nav_bott">
              <div
                className="cov19_prev"
                onClick={() => {
                  prevPage();
                }}
              >
                <ArrowBackIcon />
                <span>{intl.formatMessage({ id: "covid_previous" })}</span>
              </div>
              <div className="cov19_dots">{pageDots()}</div>
              <div
                className="cov19_next"
                onClick={() => {
                  nextPage();
                }}
              >
                <span>{intl.formatMessage({ id: "covid_next" })}</span>
                <ArrowForwardIcon />
              </div>
            </div>
          </>
        );
      case 6:
        return null;
      // return (
      //   <>
      //     <div className="cov19_nav_bott">
      //       <div
      //         className="cov19_prev"
      //         onClick={() => {
      //           prevPage();
      //         }}
      //       >
      //         <ArrowBackIcon />
      //         <span>{intl.formatMessage({ id: "covid_previous" })}</span>
      //       </div>
      //       <div className="cov19_dots">{pageDots()}</div>
      //       <div className="cov19_next"></div>
      //     </div>
      //   </>
      // );
      default:
        return null;
    }
  };

  return (
    <>
      {renderHeader()}
      <CovidImportantInfo query_params={query_params} />
      {renderPage()}
      {renderFooter()}
    </>
  );
}
