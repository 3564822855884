import { URLconst } from "./const";
import axios from "axios";

// app defaults/presets/etc...

export function getPersonalAppParameters() {
  let BaseURL = URLconst("personal_hub/parameters.php");

  return axios.get(BaseURL, {});
}

export function getColumnsNames(param) {
  let BaseURL = URLconst("personal_hub/columns.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovid19QuestionareStats(param) {
  let BaseURL = URLconst("hubsite/covid19/questionare_stats.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getColumnsAndRowsPermissionsAdmin(param) {
  let BaseURL = URLconst("hubsite/permissions/columns_and_rows.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCalendarFilters(param) {
  let BaseURL = URLconst("personal_hub/calendar_filters.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getVisitedHubIP(param) {
  let BaseURL = URLconst("hubsite/permissions/visiting_hub_ip.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getHubPrompter(param) {
  let BaseURL = URLconst("hubsite/hubprompter/prompt.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getHubPrompterSelf(param) {
  let BaseURL = URLconst("hubsite/hubprompter/prompt_self.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getBackupDataOnly(param) {
  let BaseURL = URLconst("hubsite/backup/data_only.php");

  return axios({
    url: BaseURL,
    method: "GET",
    // responseType: "blob",
    responseType: "arraybuffer",
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    params: param,
  });
}
export function getBackupWholeDB(param) {
  let BaseURL = URLconst("hubsite/backup/whole_db.php");

  return axios({
    url: BaseURL,
    method: "GET",
    responseType: "arraybuffer",
    params: param,
  });
}

// health segment

export function getVaccinationExpirations(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_expirations.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getVaccinationExpirationsAdmin(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_expirations_admin.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getVaccinationExpirationsDates(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_protection_periods.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getVaccinationDetailsAllForDesease(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_all_for_a_desease.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getDentistHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_history.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getDentistTeethHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_teeth_history.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovidDisease(param) {
  let BaseURL = URLconst("hubsite/health/covid_disease.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getInjuresList(param) {
  let BaseURL = URLconst("hubsite/health/injuries_and_chronic_issues_list.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getInjuresDetails(param) {
  let BaseURL = URLconst(
    "hubsite/health/injuries_and_chronic_issues_details.php"
  );

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getAllergiesList(param) {
  let BaseURL = URLconst("hubsite/health/allergies_list.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getAllergiesDetails(param) {
  let BaseURL = URLconst("hubsite/health/allergies_details.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getAllergiesChartData(param) {
  let BaseURL = URLconst("hubsite/health/allergy_calendar.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getAllergiesToData(param) {
  let BaseURL = URLconst("hubsite/health/allergic_to.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFitnessList(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_list.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFitnessGraph(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_graph.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getWeightList(param) {
  let BaseURL = URLconst("hubsite/health/weight_and_fat.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getPreventionList(param) {
  let BaseURL = URLconst("hubsite/health/prevention.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSmokingList(param) {
  let BaseURL = URLconst("hubsite/health/smoking.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getMedicationsList(param) {
  let BaseURL = URLconst("hubsite/health/medication_calendar.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getNutritionList(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getHeightList(param) {
  let BaseURL = URLconst("hubsite/health/height.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovid19MedValData(param) {
  let BaseURL = URLconst("hubsite/health/covid19_medval.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovid19MedValDataDaily(param) {
  let BaseURL = URLconst("hubsite/health/covid19_medval_daily.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of health segment
// work segment

export function getSalaryList(param) {
  let BaseURL = URLconst("hubsite/work/salary.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCareerList(param) {
  let BaseURL = URLconst("hubsite/work/career.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCareerDetails(param) {
  let BaseURL = URLconst("hubsite/work/career_details.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCertificates(param) {
  let BaseURL = URLconst("hubsite/work/certificates.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCVs(param) {
  let BaseURL = URLconst("hubsite/work/CVs.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getJobApplications(param) {
  let BaseURL = URLconst("hubsite/work/job_applications.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getJobApplicationsDetails(param) {
  let BaseURL = URLconst("hubsite/work/job_application_details.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of work segment
// education segment

export function getDegreesAndDiplomas(param) {
  let BaseURL = URLconst("hubsite/education/degrees.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSchools(param) {
  let BaseURL = URLconst("hubsite/education/schools.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSkills(param) {
  let BaseURL = URLconst("hubsite/education/skills.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getTalents(param) {
  let BaseURL = URLconst("hubsite/education/talents.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getExpertise(param) {
  let BaseURL = URLconst("hubsite/education/expertise.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getToLearn(param) {
  let BaseURL = URLconst("hubsite/education/to_learn.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getGrades(param) {
  let BaseURL = URLconst("hubsite/education/grades.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of education segment
// friends and family segment

export function getMyPhotoalbum(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/my_photoalbum.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getPhotosByMe(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/photos_by_me.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFamilyTree(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/family_tree.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFriends(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/friends.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getKids(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/kids.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getContacts(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/contacts.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovidContacts(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/covid19_peopdet.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getInteractions(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/interactions.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovid19PeopDetails(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/covid19_peopdet.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of friends and family segment
// various segment

export function getSettings(param) {
  let BaseURL = URLconst("hubsite/various/settings.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getRatings(param) {
  let BaseURL = URLconst("hubsite/various/ratings.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovidRatings(param) {
  let BaseURL = URLconst("hubsite/various/covidratings.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getComplaints(param) {
  let BaseURL = URLconst("hubsite/various/complaints.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getPolitical(param) {
  let BaseURL = URLconst("hubsite/various/political.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getPublicEngagements(param) {
  let BaseURL = URLconst("hubsite/various/public_engagements.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getShopNavigation(param) {
  let BaseURL = URLconst("hubsite/various/shop_navigation.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCovid19Ratings(param) {
  let BaseURL = URLconst("hubsite/various/covid19ratings.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of various segment
// soul segment

export function getHappiness(param) {
  let BaseURL = URLconst("hubsite/soul/happiness.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getDiary(param) {
  let BaseURL = URLconst("hubsite/soul/diary.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getReligion(param) {
  let BaseURL = URLconst("hubsite/soul/religion.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getReligionDetails(param) {
  let BaseURL = URLconst("hubsite/soul/religion_details.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCharity(param) {
  let BaseURL = URLconst("hubsite/soul/charity.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCharityDetails(param) {
  let BaseURL = URLconst("hubsite/soul/charity_details.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
export function getRomanceCalendar(param) {
  let BaseURL = URLconst("hubsite/soul/romance_calendar.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getRomanceHistory(param) {
  let BaseURL = URLconst("hubsite/soul/romance_history.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getGoals(param) {
  let BaseURL = URLconst("hubsite/soul/goals.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getBucketList(param) {
  let BaseURL = URLconst("hubsite/soul/bucket_list.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
export function getSexList(param) {
  let BaseURL = URLconst("hubsite/soul/sex.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
// end of soul segment
// leisure segment

export function getBooks(param) {
  let BaseURL = URLconst("hubsite/leisure/books.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCollections(param) {
  let BaseURL = URLconst("hubsite/leisure/collections.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFilms(param) {
  let BaseURL = URLconst("hubsite/leisure/films.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSleep(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSleepGraph(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep_graph.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getEvents(param) {
  let BaseURL = URLconst("hubsite/leisure/events.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSports(param) {
  let BaseURL = URLconst("hubsite/leisure/sports.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
export function getRelaxation(param) {
  let BaseURL = URLconst("hubsite/leisure/relaxation.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
export function getVacations(param) {
  let BaseURL = URLconst("hubsite/leisure/vacations.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getTravel(param) {
  let BaseURL = URLconst("hubsite/leisure/travel.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of leisure segment
// finances segment

export function getElectronics(param) {
  let BaseURL = URLconst("hubsite/finances/electronics.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCars(param) {
  let BaseURL = URLconst("hubsite/finances/cars.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getFurniture(param) {
  let BaseURL = URLconst("hubsite/finances/furniture.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getHouseOrAppartment(param) {
  let BaseURL = URLconst("hubsite/finances/house_or_appartment.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getLoans(param) {
  let BaseURL = URLconst("hubsite/finances/loans.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getBudget(param) {
  let BaseURL = URLconst("hubsite/finances/budget.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSavings(param) {
  let BaseURL = URLconst("hubsite/finances/savings.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getReceipts(param) {
  let BaseURL = URLconst("hubsite/finances/receipts.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getUnseenReceipts(param) {
  let BaseURL = URLconst("hubsite/finances/unseen_receipts.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

// end of finances segment
export function getActiveDayNote(param) {
  let BaseURL = URLconst("hubsite/soul/day_note.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
