import React, { useEffect, useState } from "react";
import { getCovidStatistics } from "../../services/GET_requests";
import { useIntl } from "react-intl";
import Chart from "react-google-charts";
import "./CovidStatistics.css";
import { useHistory } from "react-router-dom";

import {
  getCovid19Ratings,
  getCovid19MedValData
} from "../../services/GetHUBsiteData";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import moment from "moment";

export default function CovidStatistics() {
  const intl = useIntl();
  const [pollData, setPollData] = useState([]);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [questionnaireDataParsed, setQuestionnaireDataParsed] = useState({});
  const [status, setStatus] = useState([]);

  const [ratingsData, setRatingsData] = useState([]);
  const [medValData, setMedValData] = useState([]);
  const [currMode, setCurrentMode] = useState(1);
  const availableModes = [1, 2, 3, 4];
  const modes = {
    1: ["Q200", "Q201", "Q202", "Q203", "Q204", "Q205", "Q206", "Q207"],
    2: ["Q1", "Q2", "Q3"],
    3: ["Q4", "Q5", "Q6"],
    4: ["Q7", "Q8", "Q9"]
  };

  const [lastUpdate, setLastUpdate] = useState({ last: null, next: null });
  let history = useHistory();

  var query_params = {};
  if (!sessionStorage.getItem("hub_token")) {
    query_params.logged_in = null;
  } else {
    query_params.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }
  query_params.section = "covid-19";
  query_params.target_hub_id = query_params.logged_in.my_hub_id;
  query_params.requestor_hub_id = query_params.target_hub_id;

  useEffect(() => {
    getCovidStatistics().then(result => {
      console.log(result);
      if (result.data.poll_data) {
        setPollData(result.data.poll_data);
      }
      if (result.data.statistics_status) {
        setStatus(result.data.statistics_status);
      }
      if (result.data.questionnaire_data) {
        setQuestionnaireData(result.data.questionnaire_data);
      }
    });
    getCovid19RatingsData();
    getCovid19MedVal();
    // eslint-disable-next-line
  }, []);

  const getCovid19RatingsData = () => {
    getCovid19Ratings(query_params).then(result => {
      if (result.data.data_array) {
        setRatingsData(result.data.data_array);
      } else {
        console.log(result.data.error);
      }
    });
  };

  const getCovid19MedVal = () => {
    getCovid19MedValData(query_params).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        setMedValData(result.data.data_array);
      }
    });
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (status.length > 0) {
        let now = moment();
        let lastUpd = moment(status[0].search_start_on);
        let diff = moment.duration(now.diff(lastUpd));
        let minDur = Math.round(diff.asMinutes());
        let nextUpd = 60 - minDur;
        setLastUpdate({ last: minDur, next: nextUpd });

        if (nextUpd < 0) {
          getCovidStatistics().then(result => {
            if (result.data.poll_data) {
              setPollData(result.data.poll_data);
            }
            if (result.data.statistics_status) {
              setStatus(result.data.statistics_status);
            }
            if (result.data.questionnaire_data) {
              setQuestionnaireData(result.data.questionnaire_data);
            }
          });
        }
        // console.log({ last: minDur, next: nextUpd });
      }
    };
    handleTimeUpdate();
    let interval = setInterval(() => handleTimeUpdate(), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [status]);

  useEffect(() => {
    if (questionnaireData.length > 0) {
      let temp_obj = {};
      questionnaireData.forEach(e => {
        if (temp_obj[e.question_num] !== undefined) {
          temp_obj = {
            ...temp_obj,
            [e.question_num]: [...temp_obj[e.question_num], e]
          };
        } else {
          temp_obj = { ...temp_obj, [e.question_num]: [e] };
        }
      });
      // console.log(temp_obj);
      setQuestionnaireDataParsed(temp_obj);
    }
  }, [questionnaireData]);

  const handleGraphRender = (type, item) => {
    let data = [];
    let answ_count = 0;
    let dont_translate = ["Q203", "Q211", "Q214", "Q215"];
    let fem_male_case = [];
    let yes_no_case = ["Q201", "Q202", "Q204", "Q205", "Q206", "Q209", "Q218"];
    let my_answr = null;
    if (type === "poll") {
      data = [
        ["Answer", "Percentage"],
        [
          intl.formatMessage({
            id: "covid_statistics_positive"
          }),
          Number(item.count_positive)
        ],
        [
          intl.formatMessage({
            id: "covid_statistics_neutral"
          }),
          Number(item.count_neutral)
        ],
        [
          intl.formatMessage({
            id: "covid_statistics_negative"
          }),
          Number(item.count_negative)
        ]
      ];

      let question_id = item.question_num.split("_")[1];

      let indexInRatingAnswrs = ratingsData.findIndex(
        element => element.subject === question_id
      );

      if (indexInRatingAnswrs !== -1) {
        if (Number(ratingsData[indexInRatingAnswrs].rating) > 7) {
          my_answr = (
            <span className={`cov19_pool_option covid19_green`}>
              {ratingsData[indexInRatingAnswrs].rating}
            </span>
          );
        } else if (Number(ratingsData[indexInRatingAnswrs].rating) > 3) {
          my_answr = (
            <span className={`cov19_pool_option covid19_blue`}>
              {ratingsData[indexInRatingAnswrs].rating}
            </span>
          );
        } else if (Number(ratingsData[indexInRatingAnswrs].rating) > 0) {
          my_answr = (
            <span className={`cov19_pool_option covid19_red`}>
              {ratingsData[indexInRatingAnswrs].rating}
            </span>
          );
        }
        // else {
        //   my_answr = (
        //     <span className={`cov19_pool_option covid19_gray`}>
        //       {ratingsData[indexInRatingAnswrs].rating}
        //     </span>
        //   );
        // }
      }
    } else if (type === "questionnaire") {
      let temp_arr = questionnaireDataParsed[item];
      let indexInMedValAnswrs = medValData.findIndex(
        element => element.universal_index === item
      );
      data = [["Answer", "Percentage"]];

      temp_arr.forEach(e => {
        let qLabel = "";
        if (dont_translate.includes(item)) {
          qLabel = e.option_no;
        } else if (yes_no_case.includes(item)) {
          let temp_str = "";
          switch (Number(e.option_no)) {
            case 1:
              temp_str = "no";
              break;
            case 2:
              temp_str = "yes";
              break;
            default:
              temp_str = "-";
              break;
          }
          qLabel = intl.formatMessage({
            id: "covid_" + temp_str
          });
        } else if (fem_male_case.includes(item)) {
          qLabel = intl.formatMessage({
            id: "covid_" + e.option_text
          });
        } else {
          if (Number(e.option_no) === 0) {
            qLabel = intl.formatMessage({
              id: "covid_-"
            });
          } else if (Number(e.option_no) === 1) {
            qLabel = intl.formatMessage({
              id: "covid_no"
            });
          } else {
            qLabel = intl.formatMessage({
              id: "covid_" + item + "_o" + e.option_no
            });
          }
        }
        data = [...data, [qLabel, Number(e.count_this_option)]];
        answ_count = answ_count + Number(e.count_this_option);

        if (indexInMedValAnswrs !== -1) {
          if (dont_translate.includes(item)) {
            my_answr = medValData[indexInMedValAnswrs].value;
          } else if (yes_no_case.includes(item)) {
            let temp_str = "";
            switch (Number(medValData[indexInMedValAnswrs].value)) {
              case 1:
                temp_str = "no";
                break;
              case 2:
                temp_str = "yes";
                break;
              default:
                temp_str = "-";
                break;
            }
            my_answr = intl.formatMessage({
              id: "covid_" + temp_str
            });
          } else {
            if (Number(medValData[indexInMedValAnswrs].value) === 0) {
              my_answr = intl.formatMessage({
                id: "covid_-"
              });
            } else if (Number(medValData[indexInMedValAnswrs].value) === 1) {
              my_answr = intl.formatMessage({
                id: "covid_no"
              });
            } else {
              my_answr = intl.formatMessage({
                id:
                  "covid_" + item + "_o" + medValData[indexInMedValAnswrs].value
              });
            }
          }
        }
      });
    }

    const pieOptions = {
      title: "",
      pieHole: 0.3,

      pieSliceTextStyle: { color: "white", fontSize: 20 },
      slices: {
        0: { color: "#68DC75" },
        1: { color: "#1C93FF" },
        2: { color: "#EB6565" }
      },
      legend: {
        position: "bottom",
        alignment: "center",
        textStyle: {
          color: "233238",
          fontSize: 14,
          fontName: "Roboto"
        }
      },
      chartArea: {
        left: 0,
        top: 15,
        width: "100%",
        height: "80%"
      },
      tooltip: {
        showColorCode: true
      },
      fontName: "Roboto"
    };

    const renderChart = () => {
      return (
        <Chart
          chartType="PieChart"
          data={data}
          options={pieOptions}
          width={"100%"}
          height={"300px"}
        />
      );
    };

    if (type === "poll") {
      return (
        <>
          <div className="cov19_stat_grapth_top">
            <div className="cov19_stat_grapth_top_text">
              <span>{item.question_num}:</span>
              {` ${item.total_answers} ${intl.formatMessage({
                id: "covid_statistics_valid_answ"
              })}`}
            </div>
          </div>
          <div className="cov19_stat_grapth_middle">
            {intl.formatMessage({
              id: "covid_poll_group_question_db_" + item.question_num
            })}
          </div>
          <div className="cov19_stat_grapth_my_answr">
            {intl.formatMessage({
              id: "covid_wd_my_answer"
            })}
            :
            {my_answr !== null
              ? my_answr
              : ` ${intl.formatMessage({
                  id: "covid_poll_no_answer"
                })}`}
          </div>
          <div className="cov19_stat_grapth_bottom">{renderChart()}</div>
        </>
      );
    } else if (type === "questionnaire") {
      return (
        <>
          <div className="cov19_stat_grapth_top">
            <div className="cov19_stat_grapth_top_text">
              <span>{item}:</span>
              {` ${answ_count} ${intl.formatMessage({
                id: "covid_statistics_valid_answ"
              })}`}
            </div>
          </div>
          <div className="cov19_stat_grapth_middle">
            {intl.formatMessage({
              id: "covid_" + item
            })}
          </div>
          <div className="cov19_stat_grapth_my_answr">
            {intl.formatMessage({
              id: "covid_wd_my_answer"
            })}
            :
            {my_answr !== null
              ? ` ${my_answr}`
              : ` ${intl.formatMessage({
                  id: "covid_poll_no_answer"
                })}`}
          </div>
          <div className="cov19_stat_grapth_bottom">{renderChart()}</div>
        </>
      );
    }
  };

  const handleModeSwitch = param => {
    if (param === "previous") {
      if (currMode > 1) {
        setCurrentMode(currMode - 1);
      } else {
        setCurrentMode(availableModes.length);
      }
    } else {
      if (currMode < availableModes.length) {
        setCurrentMode(currMode + 1);
      } else {
        setCurrentMode(1);
      }
    }
  };

  const renderModeSwitch = param => {
    return (
      <div className="cov_stat_modes_container">
        <div
          onClick={() => {
            handleModeSwitch("previous");
          }}
          className="cov_stat_modes_arrow_box covid19_noshrink"
        >
          <ArrowBackIcon />
        </div>
        <div>
          <div className="cov_stat_modes_mode_title">{param}</div>
          <div className="cov_stat_small_dot_wrapper">
            {availableModes.map((item, i) => {
              if (item === currMode) {
                return (
                  <div key={i} className="cov_stat_small_dot_filled"></div>
                );
              } else {
                return <div key={i} className="cov_stat_small_dot"></div>;
              }
            })}
          </div>
        </div>
        <div
          onClick={() => {
            handleModeSwitch("next");
          }}
          className="cov_stat_modes_arrow_box covid19_noshrink"
        >
          <ArrowForwardIcon />
        </div>
      </div>
    );
  };

  const renderModesBlock = () => {
    switch (currMode) {
      case 1:
        return renderModeSwitch(
          intl.formatMessage({
            id: "covid_ir_sub_title_2"
          })
        );
      case 2:
        return renderModeSwitch(
          intl.formatMessage({
            id: "covid_poll_group_title_db_G1"
          })
        );
      case 3:
        return renderModeSwitch(
          intl.formatMessage({
            id: "covid_poll_group_title_db_G2"
          })
        );
      case 4:
        return renderModeSwitch(
          intl.formatMessage({
            id: "covid_poll_group_title_db_G3"
          })
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="cov19_stat_container">
        <div className="cov19_stat_top">
          <div
            className="cov19_stat_top_home_btn"
            onClick={() => history.goBack()}
          >
            <div className="cov19_stat_top_home_text">
              {intl.formatMessage({
                id: "covid_back_to_home"
              })}
            </div>
          </div>
        </div>
        <div className="cov19_stat_header_wrapper">
          <div className="cov19_stat_header_flex_box">
            <div className="cov19_stat_header_title">
              {intl.formatMessage({
                id: "covid_statistics_results"
              })}
            </div>
            <div className="cov19_stat_header_text">
              <span>
                {status.length > 0
                  ? `${status[0].hubs_count} ${intl.formatMessage({
                      id: "covid_statistics_hubs"
                    })}`
                  : null}
              </span>
              <span style={{ marginLeft: "10px" }}>
                {/* ADDED 6 TEMPORARY !!! */}
                {status.length > 0 ? (
                  <>
                    {Number(status[0].hubs_with_answer) +
                      6 +
                      " " +
                      intl.formatMessage({
                        id: "covid_statistics_responses"
                      })}
                  </>
                ) : null}
              </span>
            </div>
          </div>
          <div className="cov19_stat_header_flex_box">
            <div className="cov19_stat_header_update_time_wrapper">
              <div className="cov19_stat_header_update_time_label">
                {intl.formatMessage({
                  id: "covid_statistics_last"
                })}
              </div>
              <div className="cov19_stat_header_update_time">
                {lastUpdate.last !== null
                  ? `${lastUpdate.last} ${intl.formatMessage({
                      id: "covid_statistics_min_ago"
                    })}`
                  : null}
              </div>
            </div>
            <div className="cov19_stat_header_update_time_wrapper">
              <div className="cov19_stat_header_update_time_label">
                {intl.formatMessage({
                  id: "covid_statistics_next"
                })}
              </div>
              <div className="cov19_stat_header_update_time">
                {lastUpdate.next !== null
                  ? `${intl.formatMessage({
                      id: "covid_statistics_in"
                    })} ${lastUpdate.next} ${intl.formatMessage({
                      id: "covid_statistics_min"
                    })}`
                  : null}
              </div>
            </div>
          </div>
        </div>

        {renderModesBlock()}

        <div className="cov19_stat_graphs_wrapper">
          {pollData.map((item, i) => {
            if (!item) {
              return null;
            } else {
              if (modes[currMode].includes(item.question_num.split("_")[1])) {
                return (
                  <div key={i} className="cov19_stat_grapth_box">
                    {handleGraphRender("poll", item)}
                  </div>
                );
              } else {
                return null;
              }
            }
          })}

          {Object.keys(questionnaireDataParsed).map((item, i) => {
            if (!item) {
              return null;
            } else {
              if (modes[currMode].includes(item)) {
                return (
                  <div key={i} className="cov19_stat_grapth_box">
                    {handleGraphRender("questionnaire", item)}
                  </div>
                );
              } else {
                return null;
              }
            }
          })}
        </div>
      </div>
    </>
  );
}
