import React, { useState, useEffect, useRef } from "react";
import "./UpgradePage.css";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { getHubsiteSqlUpdate } from "../../services/GET_requests";
import { putUpgradeHubDB } from "../../services/PUT_requests";
import moment from "moment";
import SelectiveItem from "./SelectiveItem";
import ResultModal from "./ResultModal/ResultModal";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default function UpgradePage() {
  const intl = useIntl();
  const history = useHistory();
  const sql_all_ref = useRef(null);
  const sql_selective_ref = useRef(null);

  const [showSQL, setShowSQL] = useState(false);
  const [showSelectiveUpgrade, setShowSelectiveUpgrade] = useState(false);
  const [showSelectiveHeight, setShowSelectiveHeight] = useState(0);
  const [showSelectiveItemsHeight, setShowSelectiveItemsHeight] = useState(0);

  const [releaseInfo, setReleaseInfo] = useState({});
  const [releaseData, setReleaseData] = useState({});

  const [useSelectiveUpgrade, setUseSelectiveUpgrade] = useState({});

  const [open, setOpen] = useState(false);
  const [updResult, setUpdResult] = useState([]);

  useEffect(() => {
    getUpdateData();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log(releaseData, releaseInfo);
  // }, [releaseData, releaseInfo]);

  // useEffect(() => {
  //   console.log(useSelectiveUpgrade);
  // }, [useSelectiveUpgrade]);

  const getUpdateData = () => {
    getHubsiteSqlUpdate()
      .then((response) => {
        if (response.data.data_array) {
          setReleaseInfo(response.data.data_array.release_info);
          setReleaseData(response.data.data_array.release_sqls);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (type) => {
    var query_params = {};
    if (!sessionStorage.getItem("hub_token")) {
      query_params.logged_in = null;
    } else {
      query_params.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    if (type === "all") {
      let tempStr = "";
      Object.keys(releaseData).forEach((key) => {
        releaseData[key].forEach((item) => {
          tempStr = `${tempStr}${item.id},`;
        });
      });
      query_params.sql_ids = tempStr.substring(0, tempStr.length - 1);
      if (tempStr.length > 0) {
        putUpgradeHubDB(query_params)
          .then((response) => {
            setUpdResult(response.data.data_array);
            setOpen(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      let tempStr = "";
      Object.keys(useSelectiveUpgrade).forEach((key) => {
        if (useSelectiveUpgrade[key]) {
          tempStr = `${tempStr}${key},`;
        }
      });
      query_params.sql_ids = tempStr.substring(0, tempStr.length - 1);
      if (tempStr.length > 0) {
        putUpgradeHubDB(query_params)
          .then((response) => {
            setUpdResult(response.data.data_array);
            setOpen(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleSelectGroup = (item, bool) => {
    let tempobj = useSelectiveUpgrade;
    item.forEach((element) => {
      tempobj = { ...tempobj, [element.id]: bool };
    });
    setUseSelectiveUpgrade(tempobj);
  };

  return (
    <div className="upgrade_page_root">
      <img
        className="upgrade_page_bg"
        src="/assets/images/background_img_hubsite.png"
        alt=""
      />
      <div
        className="upgrade_page_back_button"
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />{" "}
        {intl.formatMessage({
          id: "active_minute_back",
        })}
      </div>
      <div className="upgrade_page_container">
        <div className="upgrade_page_title_text">
          {intl.formatMessage({
            id: "upgrade_page_title",
          })}
        </div>
        {Object.keys(releaseInfo).length !== 0 ? (
          <div className="upgrade_page_history_container">
            <div className="upgrade_page_history_text">
              Release Version <span> {releaseInfo.release_version} </span>|
              Available since :
              <span> {moment(releaseInfo.release_date).format("LL")} </span>
            </div>
            <div className="upgrade_page_history_text">
              Modified tables: <span> {releaseInfo.Modified_tables} </span>| New
              tables:<span> {releaseInfo.Modified_views} </span>| New tables:
              <span> {releaseInfo.New_tables} </span>| New views:
              <span> {releaseInfo.New_views} </span>
            </div>
            <div className="upgrade_page_history_text">
              Release note: {releaseInfo.public_note}
            </div>
          </div>
        ) : null}
        {Object.keys(releaseInfo).length !== 0 &&
        Object.keys(releaseData).length !== 0 ? (
          <div className="upgrade_page_block">
            <div className="upgrade_page_block_top">
              <div className="upgrade_page_block_top_left">
                <div
                  className="upgrade_page_download_btn_wrap"
                  style={{ backgroundColor: "#ff7d73" }}
                  onClick={() => {
                    handleUpdate("all");
                  }}
                >
                  <ArrowUpwardIcon fontSize="inherit" />
                </div>
              </div>
              <div className="upgrade_page_block_top_right">
                <div className="upgrade_page_subtitle">
                  Upgrade all (recommended)
                </div>
                <div className="upgrade_page_sql_hist_text">
                  {Object.keys(releaseData).map((item) => {
                    let count = 0;
                    count = count + releaseData[item].length;
                    return count;
                  })}{" "}
                  SQL commands | ~ {releaseInfo.size} | ~ {releaseInfo.duration}{" "}
                  <span
                    onClick={() => {
                      setShowSQL(!showSQL);
                    }}
                    style={showSQL ? { color: "#ff7d73" } : null}
                  >
                    {" "}
                    … view SQL code
                  </span>
                </div>
              </div>
            </div>
            <div
              className={"upgrade_page_block_bottom"}
              style={
                showSQL
                  ? { maxHeight: sql_all_ref.current.scrollHeight + 20 + "px" }
                  : null
              }
              ref={sql_all_ref}
            >
              <div className="upgrade_page_block_bottom_divider"></div>
              <div className={"upgrade_page_block_allsql_container"}>
                {Object.keys(releaseData).map((item) => {
                  if (!item) {
                    return null;
                  } else {
                    return releaseData[item].map((i) => {
                      if (!i) {
                        return null;
                      } else {
                        return (
                          <div
                            key={i.id}
                            className="upgrade_page_block_sql_all_piece"
                          >
                            <div>{`/*${i.object_type}: ${i.object_name}. ${i.public_reason}*/`}</div>
                            <div>{i.sql_command}</div>
                          </div>
                        );
                      }
                    });
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        {Object.keys(releaseData).length !== 0 ? (
          <>
            <div className="upgrade_page_mid_title_container">
              <div className="upgrade_page_subtitle">Selective upgrade</div>
              <div
                onClick={() => {
                  setShowSelectiveUpgrade(!showSelectiveUpgrade);
                  setShowSelectiveHeight(
                    sql_selective_ref.current.scrollHeight
                  );
                }}
              >
                <ArrowDropDownIcon
                  className={
                    "upgrade_page_show_selective_arrow " +
                    (showSelectiveUpgrade ? "upgprotate" : "")
                  }
                  fontSize="large"
                />
              </div>
            </div>

            <div
              className={"upgrade_page_show_selective_blocks_container"}
              style={
                showSelectiveUpgrade
                  ? {
                      maxHeight:
                        showSelectiveHeight + showSelectiveItemsHeight + "px",
                    }
                  : null
              }
              ref={sql_selective_ref}
            >
              {Object.keys(releaseData).map((item) => {
                if (!item) {
                  return null;
                } else {
                  return (
                    <div key={item} className="upgrade_page_block">
                      <div className="upgrade_page_seelctive_whole">
                        <input
                          className="upgrade_page_info_checkbox"
                          type="checkbox"
                          onChange={(e) => {
                            handleSelectGroup(
                              releaseData[item],
                              e.target.checked
                            );
                          }}
                        ></input>
                        <div className="upgrade_page_subtitle">{item}</div>
                      </div>
                      {releaseData[item].map((i) => {
                        if (!i) {
                          return null;
                        } else {
                          return (
                            <React.Fragment key={i.id}>
                              <SelectiveItem
                                item={i}
                                setUseSelectiveUpgrade={setUseSelectiveUpgrade}
                                useSelectiveUpgrade={useSelectiveUpgrade}
                                setShowSelectiveItemsHeight={
                                  setShowSelectiveItemsHeight
                                }
                                showSelectiveItemsHeight={
                                  showSelectiveItemsHeight
                                }
                              />
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}

              <div className="upgrade_page_selected_apply_container">
                <div
                  className="upgrade_page_selected_apply_btn_wrap"
                  onClick={() => {
                    handleUpdate("selective");
                  }}
                >
                  <div
                    className="upgrade_page_download_btn_wrap"
                    style={{ backgroundColor: "#3fa4ff" }}
                  >
                    <ArrowUpwardIcon fontSize="inherit" />
                  </div>
                  <div className="upgrade_page_selected_apply_btn">
                    <span>Apply only selected changes</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ResultModal open={open} setOpen={setOpen} updResult={updResult} />
    </div>
  );
}
