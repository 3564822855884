import React, { useEffect, useState, useRef, useCallback } from "react";
import "./VirtualAssistant.scss";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";
import MessageItem from "./message/MessageItem";
import HistoryItem from "./message/HistoryItem";
// import Logo from './logo.png';
import Suggestions from "../../components/VirtualAssistant/Suggestions";
import FinishChatButtons from "./FinishChatButtonsBlock/FinishChatButtons.js";
import FinishChatPoll from "./FinishChatPoll/FinishChatPoll.js";
import { GetShopFloorPlan } from "../../services/GetShopFloorPlan";
import debounce from "lodash/debounce";
import moment from "moment";

import {
  FindPositionNew,
  SetSearchInShop,
  SetSearchInHub,
  SetSearchRatingInShop,
  SetSearchRatingInHub,
  SetHubIdInShop,
  GetShopSearchHistory
} from "../../services/FindPosition";
import { getProfileBasics } from "../../services/getProfileBasics";
// import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import Content from "../../components/Content";
import { injectIntl } from "react-intl";
import Microphone from "./Microphone/index";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const VirtualAssistant = ({ intl }) => {
  const shop_id = useRef();
  const shop_session_id = useRef();
  const hub_session_id = useRef();
  const shop_basics_ref = useRef();
  // const target_el_ref = useRef();

  const [searchText, setSearchText] = useState("");
  const [shopID, setShopID] = useState(null);
  const [userLoggedIndirectly, setUserLoggedIndirectly] = useState(false);
  const [shop_basics, setShopBasics] = useState(null);
  const [historyExists, setHistoryExists] = useState(null);
  // const [shop_category, setShopCategory] = useState(null);
  const [shop_sessionID, setShopSessionId] = useState(null);
  const [hub_sessionID, setHubSessionId] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [search_result, setSearchResult] = useState([]);
  // const [loadingDB, setloadingDB] = useState(false);
  const [selected_obj, setSelectedObj] = useState(null);
  const [user_info, setUserInfo] = useState({
    avatar: null,
    profile_picture: null,
    nickname: "User"
  });
  const [user_avatar, setUserAvatar] = useState(null);
  const [search_history, setSearchHistory] = useState([]);
  const [batch_start, setBatchStart] = useState(0);
  const [history_end, setHistoryEnd] = useState(false);
  const [hist_last_date, setHistLastDate] = useState(
    new Date().getTime() - (new Date().getTime() % (24 * 60 * 60 * 1000))
  );
  const [suggestions_batch_start, setSuggestBatchStart] = useState(0);
  const [suggestions_batch_end, setSuggestBatchEnd] = useState(false);
  // const targetElement = document.getElementsByClassName("assistance");
  const [messageList, setMessageList] = useState([
    // {
    //   type: "message",
    //   role: "user",
    //   value: "Nutella"
    // },
    // {
    //   type: "map",
    //   rating: null,
    //   value: "/assets/images/map1.png"
    // },
    // {
    //   type: "date",
    //   value: "Today"
    // },
    // {
    //   type: "message",
    //   role: "user",
    //   value: "Kellogs fruit loops"
    // },
    // {
    //   type: "error",
    //   role: "Peter",
    //   value: "“Kellogs fruit loops” is currently not avaliable"
    // }
  ]);

  useEffect(() => {
    shop_id.current = shopID;
    shop_basics_ref.current = shop_basics;
    // target_el_ref.current = targetElement;
    if (sessionStorage.getItem("shop_session_id") !== null) {
      shop_session_id.current = sessionStorage.getItem("shop_session_id");
    } else {
      shop_session_id.current = shop_sessionID;
    }
    if (sessionStorage.getItem("hub_session_id") !== null) {
      hub_session_id.current = sessionStorage.getItem("hub_session_id");
    } else {
      hub_session_id.current = hub_sessionID;
    }
  });

  useEffect(() => {
    if (shop_basics !== null) {
      if (sessionStorage.getItem("text_search") !== null) {
        let parse = JSON.parse(sessionStorage.getItem("text_search"));
        let temp_arr = [];

        parse.text_search.forEach((obj, index) => {
          var element1 = {
            type: "search_string",
            role: "user",
            value: obj.search_phrase,
            search_time: obj.search_time * 1000,
            array_index: index
          };
          var element2 = {
            role: "Peter",
            type: "map",
            rating: obj.search_rating,
            value: shop_basics.shop_floorplan_link,
            position_left: obj.position_left,
            position_top: obj.position_top,
            position_width: obj.position_width,
            position_height: obj.position_height,
            kind: obj.kind,
            search_time: obj.search_time * 1000,
            array_index: index
          };

          temp_arr.push(element1);
          temp_arr.push(element2);
        });
        setMessageList(temp_arr);
      }
    }
  }, [shop_basics]);

  useEffect(() => {
    checkForShopId();
    get_profile_data();
  }, []);

  useEffect(() => {
    const create_session_storage = (
      current_obj,
      shop_search_item_id,
      hub_search_item_id
    ) => {
      const array_assemble = [];
      let search_item_obj = {};
      let hub_search_item_idcheck = null;
      if (hub_search_item_id !== null) {
        hub_search_item_idcheck = hub_search_item_id[0];
      }
      if (sessionStorage.getItem("text_search") !== null) {
        const parse = JSON.parse(sessionStorage.getItem("text_search"));
        parse.text_search.forEach(element => {
          array_assemble.push(element);
        });
      }

      search_item_obj = {
        search_phrase: current_obj.description,
        counterpart_hub_id: shop_id.current,
        search_found: 1,
        search_rating: null,
        position_top: current_obj.position_top,
        position_left: current_obj.position_left,
        position_width: current_obj.position_width,
        position_height: current_obj.position_height,
        search_time: Math.floor(Date.now() / 1000),
        shop_search_item_id: shop_search_item_id,
        hub_search_item_id: hub_search_item_idcheck,
        kind: current_obj.kind
      };
      array_assemble.push(search_item_obj);

      const object_assemble = { text_search: array_assemble };
      sessionStorage.setItem("text_search", JSON.stringify(object_assemble));

      let temp_arr = [];
      array_assemble.forEach((obj, index) => {
        var element1 = {
          type: "search_string",
          role: "user",
          value: obj.search_phrase,
          search_time: obj.search_time * 1000,
          array_index: index
        };
        var element2 = {
          role: "shop_assistant",
          type: "map",
          rating: obj.search_rating,
          value: shop_basics_ref.current.shop_floorplan_link,
          position_left: obj.position_left,
          position_top: obj.position_top,
          position_width: obj.position_width,
          position_height: obj.position_height,
          kind: obj.kind,
          search_time: obj.search_time * 1000,
          array_index: index
        };

        temp_arr.push(element1);
        temp_arr.push(element2);
      });
      setMessageList(temp_arr);
    };

    if (selected_obj !== null) {
      var send_credential_to_registrar = {};
      if (sessionStorage.getItem("hub_token") !== null) {
        send_credential_to_registrar = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
      } else {
        send_credential_to_registrar = { my_hub_id: "" };
      }
      send_credential_to_registrar.text_search = {
        search_phrase: selected_obj.description,
        counterpart_hub_id: shop_id.current,
        search_found: 1,
        search_rating: null,
        position_top: selected_obj.position_top,
        position_left: selected_obj.position_left,
        search_time: Math.floor(Date.now() / 1000)
      };
      if (shop_session_id.current !== null) {
        send_credential_to_registrar.search_session_id =
          shop_session_id.current;
      }

      var shop_search_item_id = null;
      var hub_search_item_id = null;
      SetSearchInShop(send_credential_to_registrar).then(result => {
        if (result.success) {
          shop_search_item_id = result.success.shop_search_item_id;
          setShopSessionId(result.success.search_session_id);
          sessionStorage.setItem(
            "shop_session_id",
            result.success.search_session_id
          );

          if (sessionStorage.getItem("hub_token") !== null) {
            var send_credential_to_registrar_second = (send_credential_to_registrar = JSON.parse(
              sessionStorage.getItem("hub_token")
            ).userData);
            send_credential_to_registrar_second.text_search = [
              {
                search_phrase: selected_obj.description,
                counterpart_hub_id: shop_id.current,
                search_found: 1,
                search_rating: null,
                position_top: selected_obj.position_top,
                position_left: selected_obj.position_left,
                search_time: Math.floor(Date.now() / 1000)
              }
            ];
            if (hub_session_id.current !== null) {
              send_credential_to_registrar_second.search_session_id =
                hub_session_id.current;
            }

            SetSearchInHub(send_credential_to_registrar_second).then(result => {
              if (result.success) {
                setHubSessionId(result.success.search_session_id);
                sessionStorage.setItem(
                  "hub_session_id",
                  result.success.search_session_id
                );
                hub_search_item_id = result.success.hub_search_item_ids;
                create_session_storage(
                  selected_obj,
                  shop_search_item_id,
                  hub_search_item_id
                );
              } else {
                create_session_storage(
                  selected_obj,
                  shop_search_item_id,
                  hub_search_item_id
                );
              }
            });
          } else {
            create_session_storage(
              selected_obj,
              shop_search_item_id,
              hub_search_item_id
            );
          }
        } else {
          create_session_storage(
            selected_obj,
            shop_search_item_id,
            hub_search_item_id
          );
        }
      });
    }
  }, [selected_obj]);

  useEffect(() => {
    if (shopID !== null) {
      const send_credential_to_registrar = {
        shop_id: shopID
      };
      GetShopFloorPlan(send_credential_to_registrar).then(result => {
        if (result.shop_basics) {
          setShopBasics(result.shop_basics);
          // setShopCategory(result.shop_basics.shop_category);
        }
      });
      if (
        historyExists === null &&
        sessionStorage.getItem("hub_token") !== null
      ) {
        get_search_history();
      }
    } // eslint-disable-next-line
  }, [shopID, historyExists]);

  useEffect(() => {
    if (messageList.length !== 0) {
      const list = document.getElementById(`class_${messageList.length - 1}`);
      // sometimes some colision witho other event or so.. delay 100ms seems to help
      setTimeout(() => {
        return list.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 100);
    }
  }, [messageList.length]);

  useEffect(() => {
    if (userLoggedIndirectly) {
      var send_credential_to_registrar = (send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData);
      send_credential_to_registrar.text_search = JSON.parse(
        sessionStorage.getItem("text_search")
      ).text_search;
      if (hub_session_id.current !== null) {
        send_credential_to_registrar.search_session_id = hub_session_id.current;
      }

      SetSearchInHub(send_credential_to_registrar).then(result => {
        if (result.success) {
          setHubSessionId(result.success.search_session_id);
          sessionStorage.setItem(
            "hub_session_id",
            result.success.search_session_id
          );
          assign_hub_search_item_id_in_session_storage(
            result.success.hub_search_item_ids
          );
        }
      });

      var send_credential_to_registrar2 = (send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData);
      send_credential_to_registrar.shop_session_id = shop_session_id.current;
      send_credential_to_registrar.counterpart_hub_id = shop_id.current;

      SetHubIdInShop(send_credential_to_registrar2).then(result => {
        if (result.success) {
          console.log(result.success);
        }
      });
    }
  }, [userLoggedIndirectly]);

  const find_pos_api = (string, batch_start) => {
    const send_credential_to_registrar = {
      searched_keyword: string,
      batch_start: batch_start,
      batch_size: 10
    };
    send_credential_to_registrar.shop_id = shop_id.current;
    FindPositionNew(send_credential_to_registrar).then(result => {
      if (result.search_result) {
        setSearchResult([...search_result, ...result.search_result]);
        if (result.search_result.length < 10) {
          setSuggestBatchEnd(true);
        } else {
          setSuggestBatchEnd(false);
        }

        setSuggestBatchStart(batch_start);
        // setloadingDB(false);
      } else {
        setSearchResult([...search_result, { description: "No results" }]);
        setSuggestBatchEnd(true);
        // setloadingDB(false);
      }
    });
  };

  const handleSearchClick = useCallback(debounce(find_pos_api, 1000), []);

  useEffect(() => {
    if (searchText !== "") {
      handleSearchClick(searchText, 0);
    }
    // if (searchText !== "") {
    //   handleSearchClick(searchText, 0);
    //   disableBodyScroll(target_el_ref.current, {
    //     allowTouchMove: el => el.className === "assistance"
    //   });
    // } else {
    //   enableBodyScroll(target_el_ref.current);
    // }
  }, [searchText, handleSearchClick]);

  const load_more_results = () => {
    if (!suggestions_batch_end) {
      let param = suggestions_batch_start + 10;
      find_pos_api(searchText, param);
    }
  };

  const checkForShopId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("shop") !== null) {
      setShopID(urlParams.get("shop"));
      if (sessionStorage.getItem("shop_id") !== null) {
        if (sessionStorage.getItem("shop_id") !== urlParams.get("shop")) {
          sessionStorage.setItem("shop_id", urlParams.get("shop"));
          sessionStorage.removeItem("shop_session_id");
          sessionStorage.removeItem("hub_session_id");
          sessionStorage.removeItem("text_search");
        }
      } else {
        sessionStorage.setItem("shop_id", urlParams.get("shop"));
      }
    } else {
      setRedirectToReferrer(true);
    }
  };

  const callbackFromParent = obj => {
    setSelectedObj(obj);
    setMessageList([
      ...messageList,
      {
        type: "search_string",
        role: "user",
        value: obj.description,
        search_time: Date.now()
      },
      {
        role: "shop_assistant",
        type: "map",
        rating: null,
        value: shop_basics.shop_floorplan_link,
        position_height: obj.position_height,
        position_left: obj.position_left,
        position_top: obj.position_top,
        position_width: obj.position_width,
        kind: obj.kind,
        search_time: Date.now()
      }
    ]);
    setSearchResult(null);
    setSearchText("");
  };

  const assign_hub_search_item_id_in_session_storage = hub_search_item_id => {
    const array_assemble = [];
    if (sessionStorage.getItem("text_search") !== null) {
      const parse = JSON.parse(sessionStorage.getItem("text_search"));
      parse.text_search.forEach(element => {
        array_assemble.push(element);
      });
      let ids_array = hub_search_item_id;
      for (let i = 0; i < ids_array.length; i++) {
        array_assemble[i].hub_search_item_id = ids_array[i];
      }
    }

    const object_assemble = { text_search: array_assemble };
    sessionStorage.setItem("text_search", JSON.stringify(object_assemble));
  };

  const login_callback = () => {
    setUserLoggedIndirectly(true);
  };

  const remove_search_item = param => {
    let initial_array = JSON.parse(sessionStorage.getItem("text_search"));
    // need to divide index by two because every entry is divided into
    // two messages and clicked entry is always even
    let parse = initial_array.text_search.filter(
      (item, index) => index !== param
    );
    const object_assemble = { text_search: parse };
    sessionStorage.setItem("text_search", JSON.stringify(object_assemble));

    let temp_arr = [];
    parse.forEach((obj, index) => {
      var element1 = {
        type: "search_string",
        role: "user",
        value: obj.search_phrase,
        search_time: obj.search_time * 1000,
        array_index: index
      };
      var element2 = {
        role: "Peter",
        type: "map",
        rating: obj.search_rating,
        value: shop_basics.shop_floorplan_link,
        position_left: obj.position_left,
        position_top: obj.position_top,
        position_width: obj.position_width,
        position_height: obj.position_height,
        kind: obj.kind,
        search_time: obj.search_time * 1000,
        array_index: index
      };
      temp_arr.push(element1);
      temp_arr.push(element2);
    });
    setMessageList(temp_arr);
  };

  const repeat_search = param => {
    setSearchText(param);
  };

  const set_rating_callback = (value, id) => {
    let initial_array = JSON.parse(sessionStorage.getItem("text_search"))
      .text_search;
    let curr_index = (id - 1) / 2;
    initial_array[curr_index].search_rating = value;
    let shop_search_item_id = initial_array[curr_index].shop_search_item_id;
    const object_assemble = { text_search: initial_array };
    sessionStorage.setItem("text_search", JSON.stringify(object_assemble));

    var send_credential_to_registrar = {
      set_rating: {
        counterpart_hub_id: shop_id.current,
        search_rating: value,
        shop_search_item_id: shop_search_item_id
      }
    };
    SetSearchRatingInShop(send_credential_to_registrar).then(result => {
      if (result.success) {
        // console.log("shop rating success");
      }
    });

    if (sessionStorage.getItem("hub_token") !== null) {
      var send_credential_to_registrar_second = (send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData);
      send_credential_to_registrar_second.set_rating = {
        search_rating: value,
        hub_search_item_id: initial_array[curr_index].hub_search_item_id
      };
      SetSearchRatingInHub(send_credential_to_registrar).then(result => {
        if (result.success) {
          // console.log("hub rating success");
        }
      });
    }
  };

  //= ==================================================================
  // temporary before move to new app design call profile data and get picture for bottom navigation avatar preview
  const get_profile_data = () => {
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }
    getProfileBasics(send_credential_to_registrar).then(result => {
      const receiptJson = result;
      if (receiptJson.data.userData) {
        setUserInfo(receiptJson.data.userData);
      }
    });
  };

  useEffect(() => {
    if (user_info !== null) {
      if (user_info.profile_picture === null) {
        if (user_info.avatar === null) {
          setUserAvatar(`/assets/images/person_icon.png`);
        } else {
          setUserAvatar(`/assets/images/${user_info.avatar}`);
        }
      } else {
        setUserAvatar(user_info.profile_picture);
      }
    } else {
      get_profile_data();
    }
  }, [user_info]);

  //= ==================================================================

  const get_search_history = () => {
    var send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.shop_id = shop_id.current;
    send_credential_to_registrar.batch_start = batch_start;
    send_credential_to_registrar.batch_size = 5;
    send_credential_to_registrar.section = "";
    send_credential_to_registrar.current_session_id =
      hub_session_id.current !== null ? hub_session_id.current : "";
    const oldOffset = window.pageYOffset;
    const oldHeight = document.body.clientHeight;
    GetShopSearchHistory(send_credential_to_registrar).then(result => {
      if (result.search_history) {
        if (historyExists === null) {
          return setHistoryExists(true);
        }
        let temp_arr = [];
        let previous_date = hist_last_date;
        result.search_history.text_searches_items_array.forEach(obj => {
          let curr_date_timeStamp = moment(obj.search_time).unix() * 1000;
          let curr_date_rounded =
            curr_date_timeStamp - (curr_date_timeStamp % (24 * 60 * 60 * 1000));
          if (previous_date - curr_date_rounded >= 86400000) {
            var date = {
              type: "date",
              value: obj.search_time,
              search_time: obj.search_time
            };
            temp_arr.unshift(date);
          }
          previous_date = curr_date_rounded;

          var element1 = {
            type: "search_string",
            role: "user",
            value: obj.search_phrase,
            search_time: obj.search_time
          };
          temp_arr.unshift(element1);
        });
        setHistLastDate(previous_date);
        setSearchHistory([...temp_arr, ...search_history]);
        setBatchStart(batch_start + 5);
        const newHeight = document.body.clientHeight;
        const scroll = newHeight - oldHeight + oldOffset;
        window.scrollTo(0, scroll);
      } else {
        if (historyExists === null) {
          return setHistoryExists(false);
        }
        setHistoryEnd(true);
      }
    });
  };

  return (
    <Content
      logged_in={
        sessionStorage.getItem("hub_token") !== null || userLoggedIndirectly
          ? true
          : false
      }
      currComp={"virtualAssistant"}
    >
      {redirectToReferrer ? (
        <Redirect
          to={{
            pathname: "/cockpit",
            state: { fromNavigation: true }
          }}
        />
      ) : null}
      <div className="assistance">
        <div className="header">
          <div className="logo_container">
            <img
              src={shop_basics !== null ? shop_basics.shop_logo_link : ""}
              className="logo"
              alt="logo"
            />
          </div>
          <div className="search">
            <Paper className="paper">
              <IconButton
                className="icon-button"
                aria-label="search"
                onClick={() => handleSearchClick(searchText)}
              >
                <SearchIcon />
              </IconButton>

              <InputBase
                className="input"
                value={searchText}
                onChange={event => {
                  setSearchResult(null);
                  setSearchText(event.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "virtual_assistant_search"
                })}
                inputProps={{ "aria-label": "search" }}
              />
              {searchText !== "" ? (
                <IconButton
                  className="icon-button"
                  aria-label="close"
                  onClick={() => {
                    setSearchText("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                  </svg>
                </IconButton>
              ) : (
                <></>
              )}
              <Microphone
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </Paper>
            {search_result !== null && searchText !== "" ? (
              <Suggestions
                searchText={searchText}
                search_result={search_result}
                callbackFromParent={callbackFromParent}
                load_more_callback={load_more_results}
                search_end_reached={suggestions_batch_end}
              />
            ) : null}
          </div>
        </div>
        <div className="chat">
          <div className="virtassist_default_welcome">
            Welcome to {shop_basics !== null ? shop_basics.shop_name : null}
          </div>
          <img
            className="virtassist_default_map_preview"
            alt=""
            src={shop_basics !== null ? shop_basics.shop_floorplan_link : ""}
          />
          {shop_basics !== null ? (
            <WelcomeMessage shop_basics={shop_basics} />
          ) : null}
          {search_history.length > 0 ? (
            history_end ? (
              <div className="chat-date-divider">end</div>
            ) : (
              <div
                onClick={() => get_search_history()}
                className="history-date-divider"
              >
                load more history
              </div>
            )
          ) : null}
          {search_history.length > 0
            ? search_history.map((item, id) => (
                <div key={id} className="message-item">
                  <HistoryItem
                    item={item}
                    repeat_search={repeat_search}
                    user_avatar={user_avatar}
                    user_info={user_info}
                  />
                </div>
              ))
            : null}
          {search_history.length > 0 ? (
            <div className="chat-date-divider">Today</div>
          ) : sessionStorage.getItem("hub_token") !== null &&
            historyExists === true ? (
            <div
              onClick={() => get_search_history()}
              className="history-date-divider"
            >
              Load history
            </div>
          ) : (
            <div className="chat-date-divider">Today</div>
          )}

          {messageList.length !== 0
            ? messageList.map((item, id) => (
                <div key={id} className="message-item">
                  <MessageItem
                    shop_basics={shop_basics}
                    item={item}
                    id={id}
                    remove_search_item={remove_search_item}
                    repeat_search={repeat_search}
                    set_rating_callback={set_rating_callback}
                    user_avatar={user_avatar}
                    user_info={user_info}
                  />
                </div>
              ))
            : null}
          {messageList.length !== 0 &&
          !userLoggedIndirectly &&
          sessionStorage.getItem("hub_token") === null ? (
            <FinishChatButtons
              myPropCallback={login_callback}
              setMessageList={setMessageList}
            />
          ) : null}
          {messageList.length < 4 ? null : <FinishChatPoll shopID={shopID} />}
          {/* revisit 60px bumper  */}
          <div style={{ height: "60px" }}></div>
        </div>
      </div>
    </Content>
  );
};

export default injectIntl(VirtualAssistant);
