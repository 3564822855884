import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import "./logout.css";
import { ChangePin } from "../../services/ChangePin";

import { Redirect } from "react-router-dom";

//validation of login input
var passwordValidator = require("password-validator");

var schema = new passwordValidator();
//has to be 4 digits and no spaces
schema
  .is()
  .min(4) // Minimum length 8
  .is()
  .max(4) // Maximum length 100
  .has()
  .not()
  .letters()
  .is()
  .digits() // Must have digits
  .has()
  .not()
  .spaces(); // Should not have spaces

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      redirectToCockpit: false,
      redirectToLogout: false,
      changePin: false,
      new_pin_1: "",
      new_pin_2: "",
      pin_login_validation_error: false,
      pin_not_match_error: false
    };
    this.onChange = this.onChange.bind(this);
  }

  logout = () => {
    sessionStorage.setItem("hub_token", "");
    sessionStorage.clear();
    this.setState({ redirectToReferrer: true });
  };

  backToCockpit = () => {
    this.setState({ redirectToCockpit: true });
  };

  changePin = () => {
    this.setState({ changePin: true });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitNewPin = () => {
    if (
      schema.validate(this.state.new_pin_1) &&
      schema.validate(this.state.new_pin_2)
    ) {
      if (this.state.new_pin_1 === this.state.new_pin_2) {
        let send_credential_to_registrar = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
        send_credential_to_registrar.new_pin = this.state.new_pin_1;
        ChangePin(send_credential_to_registrar).then(result => {
          let responseJson = result;
          sessionStorage.setItem("hub_token", "");
          sessionStorage.clear();
          if (responseJson.userData) {
            //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
            sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
            this.setState({ redirectToReferrer: true });
          }
        });
      } else {
        this.setState({
          pin_not_match_error: true,
          pin_login_validation_error: false
        });
      }
    } else {
      this.setState({
        pin_login_validation_error: true,
        pin_not_match_error: false
      });
    }
  };

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/login"} />;
    }

    if (this.state.redirectToLogout) {
      return <Redirect to={"/logout"} />;
    }

    if (this.state.redirectToCockpit) {
      return <Redirect to={"/HUBsite"} />;
    }

    let logout_change_pin_block;
    if (this.state.changePin) {
      logout_change_pin_block = (
        <div id="change_pin_input">
          <div className="change_pin_input">
            <div>
              <FormattedMessage id="logout_new_pin_1" />
            </div>
            <input
              type="password"
              name="new_pin_1"
              value={this.state.new_pin_1}
              onChange={this.onChange}
            />
          </div>
          <div className="change_pin_input">
            <div>
              <FormattedMessage id="logout_new_pin_2" />
            </div>
            <input
              type="password"
              name="new_pin_2"
              value={this.state.new_pin_2}
              onChange={this.onChange}
            />
          </div>
          <span
            onClick={this.submitNewPin}
            className="change_pin_input change_pin_submit button_lo"
          >
            <FormattedMessage id="logout_change_pin_submit" />
          </span>
        </div>
      );
    } else {
      logout_change_pin_block = (
        <div
          onClick={this.changePin}
          className="button_lo"
          id="logout_change_pin"
        >
          <span>
            <FormattedMessage id="logout_change_pin_btn" />
          </span>
        </div>
      );
    }
    let logout_error_block;
    if (this.state.pin_login_validation_error) {
      logout_error_block = (
        <div className="change_pin_error">
          <FormattedMessage id="logout_change_pin_validation_error" />
        </div>
      );
    }
    if (this.state.pin_not_match_error) {
      logout_error_block = (
        <div className="change_pin_error">
          <FormattedMessage id="logout_change_pin_doesnot_match" />
        </div>
      );
    }

    return (
      <div className="grid-container_logout">
        <span id="hub_lo">
          <p>
            <FormattedMessage id="logout_HUB_ID" />:
          </p>
          <p>
            {JSON.parse(sessionStorage.getItem("hub_token"))
              .userData.my_hub_id.slice(-5)
              .replace("_", "")}
          </p>
        </span>
        <span id="pin_lo">
          <p>
            <FormattedMessage id="logout_PIN" />:
          </p>
          <p>
            {JSON.parse(sessionStorage.getItem("hub_token")).userData.temp_pin}
          </p>
        </span>
        <p className="warning_text">
          <FormattedHTMLMessage id="logout_warning_message" />
        </p>
        <div
          onClick={this.backToCockpit}
          className="button_lo"
          id="return_to_hub_button"
        >
          <FormattedMessage id="logout_back_to_hub_btn" />
        </div>
        <h2 id="h2_lo">
          <FormattedMessage id="logout_success" />
        </h2>
        <div onClick={this.logout} className="button_lo" id="return_to_login">
          <FormattedMessage id="logout_back_to_login_btn" />
        </div>
        {logout_change_pin_block}
        {logout_error_block}
      </div>
    );
  }
}

export default Logout;
