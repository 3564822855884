import React, { useState, useEffect } from "react";
import "./FinishChatButtons.scss";
import Modal from "react-modal";
import passwordValidator from "password-validator";
import { FormattedMessage } from "react-intl";
import { LoginCall } from "../../../services/LoginCall";
// import { ClaimFreeHub } from "../../../services/ClaimFreeHub";
import { CreateNewHub } from "../../../services/CreateNewHub";
import "../../Login/login.css";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { useIntl } from "react-intl";

import CreateHubModal from "../../../components/CreateHubModal";
import CreateHubAlert from "../../../components/CreateHubAlert";

const schema = new passwordValidator();
// has to be 4 digits and no spaces
schema
  .is()
  .min(4) // Minimum length 8
  .is()
  .max(4) // Maximum length 100
  .has()
  .not()
  .letters()
  .is()
  .digits() // Must have digits
  .has()
  .not()
  .spaces(); // Should not have spaces

const FinishChatButtons = ({ myPropCallback, setMessageList }) => {
  const intl = useIntl();
  const [modal, setModal] = useState(false);
  // const [shoppingListModal, setShoppingListModal] = useState(false);
  // const [shopList, setShopList] = useState([]);
  // const [shopListChecked, setShopListChecked] = useState({});
  const [loginData, setLoginData] = useState({
    username: "",
    pin: "",
    password: "",
    redirectToReferrer: false,
    login_error: false,
    login_validation_error: false
  });

  const [userData, setUserData] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [creatingDone, setCreatingDone] = useState(false);
  const [progressBarDone, setProgressBarDone] = useState(false);
  const [createHubError, setCreateHubError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    console.log("progress bar done");
    if (progressBarDone) {
      setLoginData({
        ...loginData,
        modal: false,
        new_register: true,
        my_hub_id: userData.userData.my_hub_id
      });
      setOpenCreateModal(false);
      myPropCallback();
    }
    // eslint-disable-next-line
  }, [progressBarDone]);

  const handleInputChange = event => {
    const { value, name } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };
  // const handleShopListModalClick = () => {
  //   const shopListItems = JSON.parse(sessionStorage.getItem("text_search"));
  //   const shopListCheckedObj = {};
  //   // eslint-disable-next-line
  //   shopListItems.text_search.map((element, id) => {
  //     shopListCheckedObj[id] = false;
  //   });
  //   setShopList(shopListItems.text_search);
  //   setShopListChecked(shopListCheckedObj);
  //   setShoppingListModal(true);
  // };
  const isLoggedIn = () =>
    window.sessionStorage.getItem("hub_token") ? null : setModal(true);

  const register = () => {
    let create_new_hub = true;
    if (localStorage.getItem("newHUB") !== null) {
      let difference =
        Number(moment().unix()) - Number(localStorage.getItem("newHUB"));
      if (difference < 600) {
        let num = Math.floor(difference / 60);
        create_new_hub = false;
        setTimeLeft(num);
        setOpenAlertModal(true);
      }
    }
    if (create_new_hub) {
      if (sessionStorage.getItem("hub_token") === null) {
        const send_credential_to_registrar = {
          password: "This is CreHUBmultip@ssWord!"
        };
        setCreateHubError(false);
        setOpenCreateModal(true);
        CreateNewHub(send_credential_to_registrar)
          .then(result => {
            const responseJson = result;
            if (responseJson.error) {
              setLoginData({
                ...loginData,
                loginError: true,
                validationError: false
              });
              setCreateHubError(true);
            }
            if (responseJson.userData) {
              //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
              sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
              localStorage.setItem("newHUB", moment().unix());
              setUserData(responseJson);
              setCreatingDone(true);
            }
          })
          .catch(() => {
            setCreateHubError(true);
          });
      }
    }
  };

  let login_error_block;
  if (loginData.error) {
    login_error_block = (
      <label id="login_error">
        <FormattedMessage id="login_error" />
      </label>
    );
  }

  let login_login_validation_error_block;
  if (loginData.validationError) {
    login_login_validation_error_block = (
      <label id="login_login_validation_error">
        <FormattedMessage id="login_validation_error" />
      </label>
    );
  }

  const login = () => {
    let obj;

    if (index === 0) {
      obj = { username: loginData.username, pin: loginData.pin };
      if (
        !schema.validate(loginData.username) &&
        !schema.validate(loginData.pin)
      ) {
        setLoginData({
          ...loginData,
          login_validation_error: true,
          login_error: true
        });
        return;
      }
    } else {
      obj = {
        username: loginData.username,
        password: loginData.password
      };
      if (!schema.validate(loginData.username)) {
        setLoginData({
          ...loginData,
          login_validation_error: true,
          login_error: true
        });
        return;
      }
    }

    LoginCall(obj).then(result => {
      const responseJson = result;
      if (responseJson.error) {
        setLoginData({
          ...loginData,
          validationError: false,
          loginError: true
        });
      }
      if (responseJson.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
        setModal(false);
        myPropCallback();
      }
    });
  };

  const discard_session_storage = () => {
    sessionStorage.removeItem("hub_session_id");
    sessionStorage.removeItem("shop_session_id");
    sessionStorage.removeItem("text_search");
    setMessageList([]);
  };

  const handleChange = (event, value) => {
    setIndex(value);
  };

  const handleChangeIndex = index => {
    setIndex(index);
  };

  return (
    <div className="main-block">
      <button className="main-block-button blue" onClick={isLoggedIn}>
        <FormattedMessage id="virtual_assistant_save_search" />
      </button>
      {/* <button className="main-block-button blue" onClick={()=>{handleShopListModalClick()}}>
        <FormattedMessage id="virtual_assistant_save_as_shopping_list" />
      </button>
      <Modal
        isOpen={shoppingListModal}
        contentLabel="Minimal Modal Example"
        onRequestClose={() => setShoppingListModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.5)"
          },
          content: {
            background: "rgba(255,255,255,1)",
            padding: "0",
            height: 400,
            margin: "auto"
          }
        }}
      >
        <div className="save_shop_list_modal">
          <div
            className="close_btn"
            onClick={() => setShoppingListModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </div>
          <h4 className="title">
            <FormattedMessage id="virtual_assistant_save_as_shopping_list" />
          </h4>
          <div className="list">
            {shopList.map((item, id) => {
              return (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      checked={shopListChecked[id]}
                      onChange={event =>
                        setShopListChecked({
                          ...shopListChecked,
                          [id]: event.target.checked
                        })
                      }
                      value={shopListChecked[id]}
                      color="primary"
                      inputProps={{
                        "aria-label": "primary checkbox"
                      }}
                    />
                  }
                  label={item.search_phrase}
                />
              );
            })}
          </div>
          <button
            className="save_btn"
            onClick={() => setShoppingListModal(false)}
          >
            Save
          </button>
        </div>
      </Modal> */}
      <button
        onClick={() => {
          discard_session_storage();
        }}
        className="main-block-button white"
      >
        <FormattedMessage id="virtual_assistant_discard_search" />
      </button>
      <Modal
        ariaHideApp={false}
        isOpen={modal}
        contentLabel=""
        onRequestClose={() => setModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "99"
          },
          content: {
            background: "rgba(255,255,255,1)",
            top: "2%",
            right: "2%",
            left: "2%",
            bottom: "2%",
            padding: "0",
            zIndex: "100"
          }
        }}
      >
        {openCreateModal ? (
          <CreateHubModal
            open={openCreateModal}
            setOpen={setOpenCreateModal}
            creatingDone={creatingDone}
            setProgressBarDone={setProgressBarDone}
            createHubError={createHubError}
            register={register}
          />
        ) : null}
        {openAlertModal ? (
          <CreateHubAlert
            open={openAlertModal}
            setOpen={setOpenAlertModal}
            timeLeft={timeLeft}
          />
        ) : null}
        <span
          className="navigation_login_close"
          onClick={() => setModal(false)}
        >
          <span>&times;</span>
        </span>
        {/* {loginData.loginFromCart ? (
          <div className="navigation_login_confirm_message">
            <FormattedMessage id="navigator_login_confirm_message" />
          </div>
        ) : null} */}
        <div className="login_page_block" style={{ height: "100%" }}>
          <img
            className="login_page_bg"
            src="/assets/images/background_img_hubsite.png"
            alt=""
          />
          <div className="login_page_content">
            <div className="login_page_title">
              {intl.formatMessage({
                id: "login_to_hubsite_title"
              })}
            </div>
            <div className="login_form_block">
              <div className="login_form_title">
                {intl.formatMessage({
                  id: "login_form_title"
                })}
              </div>
              <div className="login_form">
                <Tabs
                  value={index}
                  onChange={handleChange}
                  style={{ width: "75%" }}
                >
                  <Tab
                    label={intl.formatMessage({
                      id: "login_to_hubsite_user"
                    })}
                    style={{ width: "50%", textTransform: "capitalize" }}
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: "login_to_hubsite_admin"
                    })}
                    style={{ width: "50%", textTransform: "capitalize" }}
                  />
                </Tabs>
                <SwipeableViews
                  index={index}
                  onChangeIndex={handleChangeIndex}
                  style={{ width: "100%" }}
                >
                  <div className="form">
                    {login_error_block}
                    {login_login_validation_error_block}
                    <div className="form_small_title">
                      {intl.formatMessage({
                        id: "login_form_hub_number"
                      })}
                    </div>
                    <form autoComplete="on" name="user">
                      <input
                        type="text"
                        name="username"
                        className="login_form_input"
                        placeholder={intl.formatMessage({
                          id: "login_to_hubsite_enter_hub_id"
                        })}
                        value={loginData.username}
                        onChange={handleInputChange}
                      />
                      <div className="form_small_title">Pin code</div>
                      <input
                        type="password"
                        name="pin"
                        className="login_form_input"
                        placeholder={intl.formatMessage({
                          id: "login_to_hubsite_enter_pin"
                        })}
                        value={loginData.pin}
                        onChange={handleInputChange}
                      />
                    </form>
                    <button
                      className="form_submit_btn"
                      type="submit"
                      onClick={login}
                    >
                      {" "}
                      {intl.formatMessage({
                        id: "login_form_title"
                      })}{" "}
                    </button>
                  </div>
                  <div className="form">
                    {login_error_block}
                    {login_login_validation_error_block}
                    <div className="form_small_title">
                      {intl.formatMessage({
                        id: "login_form_hub_number"
                      })}
                    </div>
                    <form autoComplete="on" name="admin">
                      <input
                        type="text"
                        name="username"
                        className="login_form_input"
                        placeholder={intl.formatMessage({
                          id: "login_to_hubsite_enter_hub_id"
                        })}
                        value={loginData.username}
                        onChange={handleInputChange}
                      />
                      <div className="form_small_title">Password</div>
                      <input
                        type="password"
                        name="password"
                        className="login_form_input"
                        placeholder={intl.formatMessage({
                          id: "login_to_hubsite_enter_password"
                        })}
                        value={loginData.password}
                        onChange={handleInputChange}
                      />
                    </form>
                    <button
                      className="form_submit_btn"
                      type="submit"
                      onClick={login}
                    >
                      {" "}
                      {intl.formatMessage({
                        id: "login_form_title"
                      })}{" "}
                    </button>
                  </div>
                </SwipeableViews>
              </div>
            </div>
            <div className="personal_hub_block">
              <div className="personal_hub_block_title">
                {intl.formatMessage({
                  id: "login_page_new_here"
                })}
              </div>
              <div className="personal_hub_form">
                <div className="personal_hub_form_text">
                  {intl.formatMessage({
                    id: "login_page_create_hub_p1"
                  })}{" "}
                  <b>
                    {intl.formatMessage({
                      id: "login_page_create_hub_p2"
                    })}
                  </b>
                </div>
                <div>
                  <button onClick={register} className="form_submit_btn">
                    {" "}
                    {intl.formatMessage({
                      id: "login_page_get_my_hub"
                    })}{" "}
                  </button>
                </div>
                <div className="personal_hub_form_text">
                  <a href="/about">
                    {intl.formatMessage({
                      id: "login_page_about_p1"
                    })}
                  </a>{" "}
                  {intl.formatMessage({
                    id: "login_page_about_p2"
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default FinishChatButtons;
