import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const AllergiesTimeLineGraph = ({
  target_hub_id,
  requestorHubID,
  apiRequest
}) => {
  const [apiData, setApiData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = requestorHubID;
    send_credential_to_registrar.section = "allergy-status";
    //variables for dynamic created query for setting permissions

    apiRequest(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        setApiData([]);
      } else {
        setApiData(result.data.data_array);
      }
    });
  };

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(b.date_on) - new Date(a.date_on);
    });
    let chart = [[{ type: "date", label: "Date" }, "Intensity"]];

    apiDataToMap.map(item => {
      return chart.push([
        new Date(item.date_on.replace(" ", "T")),
        Number(item.value)
      ]);
    });
    setChartData(chart);
  };
  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (apiData.length !== 0) {
      parseApiData();
    }
    // eslint-disable-next-line
  }, [apiData.length]);

  if (apiData.length !== 0) {
    return (
      <Chart
        width={"100%"}
        height={"200px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          vAxis: {
            title: "Intensity"
          },
          series: {
            1: { curveType: "function" }
          }
        }}
      />
    );
  } else {
    return null;
  }
};
export default AllergiesTimeLineGraph;
