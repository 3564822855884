import React from "react";
import { FormattedMessage } from "react-intl";

export default function OrderItem({
  item,
  index,
  detailsOf,
  statusHistory,
  handleClick,
  checkIfNull,
  handleClickstatusHistory,
  inputHandleChange
}) {
  if (!item) {
    return null;
  }

  let cover_image_link;
  if (item.external_id_1 === null && item.poster === null) {
    cover_image_link = "/assets/images/missing_image_portrait.png";
  } else {
    if (item.poster === null) {
      cover_image_link = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${item.external_id_1}&size=m`;
    } else {
      cover_image_link = item.poster;
    }
  }

  let plannedPUT;
  if (item.planned_pickup_time === null) {
    plannedPUT = "";
  } else {
    plannedPUT = item.planned_pickup_time;
  }

  let prog_bar_runner_col;
  switch (item.readiness) {
    case null:
      prog_bar_runner_col = "lightgrey";
      break;
    case "0":
      prog_bar_runner_col = "lightgrey";
      break;
    case "1":
      prog_bar_runner_col = "lightgrey";
      break;
    case "100":
      prog_bar_runner_col = "rgb(153, 255, 0)";
      break;
    default:
      prog_bar_runner_col = "orange";
  }
  return (
    <div className="bot_item_ordered" key={index}>
      <div className="bot_item_ordered_inner_wrapper">
        <div className="bot_img_wrapper">
          <img
            className="bot_item_image"
            alt=""
            src={cover_image_link}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/assets/images/missing_image_portrait.png";
            }}
          />
        </div>
        <div className="bot_item_content">
          <div className="bot_title">{item.title}</div>
          <div className="bot_line_light" />
          <div className="bot_publisher">{item.publisher}</div>
          <div className="bot_order_time">
            <FormattedMessage id="bookcol_orders_order_time" />:{" "}
            {item.created_on}
          </div>
          <div className="bot_prog_bar_base">
            <div
              className="bot_prog_bar_runner"
              style={{
                width: item.readiness + "%",
                backgroundColor: prog_bar_runner_col
              }}
            >
              Progress: {item.readiness}%
            </div>
          </div>
        </div>
      </div>
      <div
        className="bot_ordered_outer_wrapper"
        style={detailsOf === index ? { display: "block" } : { display: "none" }}
      >
        <div className="bot_ordered_inner_wrapper">
          <div>
            <strong>St: </strong>
            <i>{item.status_short_name}</i>
          </div>
          <div className="bot_order_message">
            <strong>Nachricht: </strong>
            <i>{item.message}</i>
          </div>
        </div>
        <div className="bot_ordered_inner_wrapper">
          <div>
            <strong>Geplante Abholzeit:</strong>
          </div>
          <input
            id={index}
            className={"bot_planned_pickup_time"}
            type="datetime-local"
            name="planned_pickup_time"
            value={checkIfNull(plannedPUT.replace(" ", "T"))}
            onChange={inputHandleChange}
          />
          <div>
            <strong>Nachricht an Shop:</strong>
          </div>
          <input
            id={index}
            className="bot_note_to_shop"
            type="text"
            name="note_to_shop"
            value={checkIfNull(item.note_to_shop)}
            onChange={inputHandleChange}
          />
        </div>
        <div className="bot_ordered_inner_wrapper">
          {item.status_history === undefined ? (
            <div className="bot_statusHistoryBtn_inactive">
              <span>
                <FormattedMessage id="bookcol_orders_all_statuses_btn" />
              </span>
            </div>
          ) : (
            <div
              className="bot_statusHistoryBtn"
              onClick={() => {
                handleClickstatusHistory(index);
              }}
            >
              <span>
                <FormattedMessage id="bookcol_orders_all_statuses_btn" />
              </span>
            </div>
          )}

          <div
            style={
              statusHistory === index
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <ol className="bot_ordered_list">
              {item.status_history === undefined
                ? null
                : item.status_history.map(i => {
                    if (!item) {
                      return null;
                    }
                    return (
                      <li key={i.entry_id}>
                        <div>
                          <mark>
                            <FormattedMessage id="bookcol_orders_as_progress" />
                            :
                          </mark>{" "}
                          {i.readiness}%
                        </div>
                        <div>
                          <mark>
                            <FormattedMessage id="bookcol_orders_as_message" />:
                          </mark>{" "}
                          {i.message}
                        </div>
                        <div>
                          <mark>
                            <FormattedMessage id="bookcol_orders_as_created" />:
                          </mark>{" "}
                          {i.issue_date}
                        </div>
                      </li>
                    );
                  })}
            </ol>
          </div>
        </div>
      </div>
      <div className="bot_line" />
    </div>
  );
}
