import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  postCovid19MedValData,
  postCovid19PeopDetData
} from "../../services/PostHUBsiteData";
import {
  putCovid19MedValData,
  putCovid19PeopDetData
} from "../../services/PutHUBsiteData";
import { useIntl } from "react-intl";
import { CovidQuestions } from "./CovidQuestions";

export default function Covid19InfectionRisk({
  query_params,
  medValData,
  getCovid19MedVal,
  peopDetData,
  getCovid19PeopDet
}) {
  const intl = useIntl();
  // console.log(countries_json_gen("code"));
  // const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "kg" });
  // const [heightUnit, setHeightUnit] = useState({ value: "cm", label: "cm" });
  const [answeredMap, setAnsweredMap] = useState({});
  const [peopDetAnsweredMap, setPeopDetAnsweredMap] = useState({});
  const [loading, setLoading] = useState(false);

  const [medValState, setMedValState] = useState([]);

  const questions = CovidQuestions();

  useEffect(() => {
    if (medValData.length > 0) {
      let temp_obj = {};

      medValData.forEach((item, index) => {
        temp_obj = { ...temp_obj, [item.universal_index]: index };
      });
      setAnsweredMap(temp_obj);
      setMedValState(medValData);
    }
  }, [medValData]);

  useEffect(() => {
    if (peopDetData.length > 0) {
      let temp_obj = {};

      peopDetData.forEach((item, index) => {
        temp_obj = { ...temp_obj, [item.hub_transaction_id]: index };
      });
      setPeopDetAnsweredMap(temp_obj);
      //   console.log(temp_obj);
    }
  }, [peopDetData]);

  const handleSelectMedVal = (qID, val, label, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};
    payload.data.category = "covid-19";
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.value = val;
    payload.data.reference_value = val !== null ? val + "-" + label : null;
    payload.data.universal_index = qID;

    if (id !== null) {
      payload.data.id = id;
      putCovid19MedValData(payload).then(result => {
        // console.log(result);
        setLoading(false);
        getCovid19MedVal();
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        // console.log(result);
        setLoading(false);
        getCovid19MedVal();
      });
    }
  };

  const renderSelectMedVal = (question, width) => {
    let width_val = 200;
    if (width !== undefined) {
      width_val = width;
    }
    let oldAnswer = null;
    let id = null;
    if (answeredMap[question] !== undefined) {
      let indexInOptions = questions[question].options.findIndex(
        element =>
          Number(element.value) ===
          Number(medValState[answeredMap[question]].value)
      );
      // console.log(question, indexInOptions);
      id = medValState[answeredMap[question]].id;
      oldAnswer = questions[question].options[indexInOptions];
    }

    let disabled = true;
    let temp_arr = Object.keys(answeredMap);
    if (questions[question].depend.length > 0) {
      let verif_arr = [];
      questions[question].depend.forEach(element => {
        if (temp_arr.includes(element)) {
          let temp_val = medValState[answeredMap[element]].value;
          if (Number(temp_val) === 2) {
            verif_arr.push(true);
          } else {
            verif_arr.push(false);
          }
        } else {
          verif_arr.push(false);
        }
      });
      if (!verif_arr.includes(false)) {
        disabled = false;
      }
    } else {
      disabled = false;
    }

    return (
      <div className="covid19_noshrink" style={{ width: width_val }}>
        <Select
          isDisabled={disabled}
          placeholder={""}
          value={oldAnswer}
          onChange={e => {
            e !== null
              ? handleSelectMedVal(question, e.value, e.label, id)
              : handleSelectMedVal(question, null, null, null);
          }}
          options={questions[question].options}
        />
      </div>
    );
  };

  const renderInputPeopDet = (question, val_type, unit) => {
    let oldAnswer = "";
    let id = null;
    if (peopDetAnsweredMap[question] !== undefined) {
      if (val_type === "text") {
        oldAnswer = peopDetData[peopDetAnsweredMap[question]].value_text;
      } else {
        oldAnswer = peopDetData[peopDetAnsweredMap[question]].value_number;
      }
      // console.log(oldAnswer, question);
      id = peopDetData[peopDetAnsweredMap[question]].id;
    }

    return (
      <input
        className="cov19_inf_risk_input"
        type={val_type}
        name={question}
        onBlur={e => {
          handleInputPeopDet(question, e.target.value, val_type, unit, id);
        }}
        defaultValue={oldAnswer}
      />
    );
  };

  const handleInputPeopDet = (qID, val, val_type, unit, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};
    payload.data.related_people_id = 0;
    payload.data.category = "covid-19";
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.value_date = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.hub_transaction_id = qID;
    if (val_type === "text") {
      payload.data.value_text = val;
    } else {
      payload.data.value_number = val !== "" ? val : null;
    }
    if (unit !== null) {
      payload.data.unit = unit;
    }
    if (id !== null) {
      payload.data.id = id;
    }

    if (payload.data.id !== undefined) {
      putCovid19PeopDetData(payload).then(result => {
        // console.log(result);
        setLoading(false);
        getCovid19PeopDet();
      });
    } else {
      if (val !== "") {
        postCovid19PeopDetData(payload).then(result => {
          // console.log(result);
          setLoading(false);
          getCovid19PeopDet();
        });
      }
    }
  };

  const findAgeGroup = param => {
    switch (true) {
      case param >= 0 && param < 10:
        return 1;
      case param >= 10 && param < 20:
        return 2;
      case param >= 20 && param < 30:
        return 3;
      case param >= 30 && param < 40:
        return 4;
      case param >= 40 && param < 50:
        return 5;
      case param >= 50 && param < 60:
        return 6;
      case param >= 60 && param < 70:
        return 7;
      case param >= 70 && param < 80:
        return 8;
      case param >= 80 && param < 90:
        return 9;
      case param >= 90 && param < 100:
        return 10;
      case param >= 100:
        return 11;
      default:
        return 0;
    }
  };

  const renderSelectPeopDet = (question, width) => {
    let width_val = 200;
    if (width !== undefined) {
      width_val = width;
    }
    let oldAnswer = null;
    let id = null;
    let disabled = false;
    if (peopDetAnsweredMap[question] !== undefined) {
      let indexInOptions = questions[question].options.findIndex(
        element =>
          element.value === peopDetData[peopDetAnsweredMap[question]].value_text
      );
      // console.log(question, indexInOptions);
      id = peopDetData[peopDetAnsweredMap[question]].id;
      oldAnswer = questions[question].options[indexInOptions];
    }

    if (question === "Q211") {
      if (peopDetAnsweredMap["Q210"] !== undefined) {
        if (peopDetData[peopDetAnsweredMap["Q210"]].value_number !== null) {
          let temp_val =
            moment().year() -
            Number(peopDetData[peopDetAnsweredMap["Q210"]].value_number);
          oldAnswer = questions["Q211"].options[findAgeGroup(temp_val)];
          disabled = true;
        }
      }
    }

    return (
      <div className="covid19_noshrink" style={{ width: width_val }}>
        <Select
          isDisabled={disabled}
          placeholder={""}
          value={oldAnswer}
          onChange={e => {
            e !== null
              ? handleSelectPeopDet(question, e.value, id)
              : handleSelectPeopDet(question, null, null);
          }}
          options={questions[question].options}
        />
      </div>
    );
  };

  const handleSelectPeopDet = (qID, val, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};
    payload.data.related_people_id = 0;
    payload.data.category = "covid-19";
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.value_text = val;
    payload.data.value_date = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.hub_transaction_id = qID;

    // console.log(payload);
    if (id !== null) {
      payload.data.id = id;
      putCovid19PeopDetData(payload).then(result => {
        // console.log(result);
        setLoading(false);
        getCovid19PeopDet();
      });
    } else {
      postCovid19PeopDetData(payload).then(result => {
        // console.log(result);
        setLoading(false);
        getCovid19PeopDet();
      });
    }
  };

  return (
    <>
      <div className="cov19_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid_ir_main_title"
          })}
        </span>
      </div>
      {/* <div
        className="cov19_poll_question_block_title"
        style={{ textTransform: "none" }}
      >
        {intl.formatMessage({
          id: "covid_ir_sub_title_1"
        })}
      </div> */}
      <div className="cov19_info_shade_block">
        <div className="cov19_inf_risk_question_text">
          <span className="covid_question_small_label">Q200</span>
          {questions.Q200.label}
        </div>
        {renderSelectMedVal("Q200")}
      </div>
      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_ir_sub_title_2"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q201</span>
            {questions.Q201.label}
          </div>
          {renderSelectMedVal("Q201")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q202</span>
            {questions.Q202.label}
          </div>
          {renderSelectMedVal("Q202")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q203</span>
            {questions.Q203.label}
          </div>
          {renderSelectMedVal("Q203")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q204</span>
            {questions.Q204.label}
          </div>
          {renderSelectMedVal("Q204")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q205</span>
            {questions.Q205.label}
          </div>
          {renderSelectMedVal("Q205")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q206</span>
            {questions.Q206.label}
          </div>
          {renderSelectMedVal("Q206")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q207</span>
            {questions.Q207.label}
          </div>
          {renderSelectMedVal("Q207")}
        </div>
        {/* <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
          <span className="covid_question_small_label">Q208</span>
            {questions.Q208.label}
          </div>
          {renderSelectMedVal("Q208")}
        </div> */}
      </div>
      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_ir_sub_title_3"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div
          className="cov19_inf_risk_about_cont"
          style={{ justifyContent: "space-between" }}
        >
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q209</span>
              {questions.Q209.label}:
            </div>
            {renderSelectPeopDet("Q209", 100)}
          </div>
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q210</span>
              {questions.Q210.label}:
            </div>
            {renderInputPeopDet("Q210", "number", "year")}
          </div>
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q211</span>
              {questions.Q211.label}:
            </div>
            {renderSelectPeopDet("Q211", 100)}
          </div>
        </div>
        {/* <div
          className="cov19_inf_risk_about_cont cov19_bottom_divider"
          style={{ justifyContent: "flex-start", marginTop: "20px" }}
        >
          <div className="cov19_inf_risk_about_cont1_block cov19_inf_risk_padd_right">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q212</span>
              {questions.Q212.label}:
            </div>
            <div className="cov19_inf_risk_inp_and_sel_wrap">
              {renderInputPeopDet("Q212", "number", heightUnit.value)}
              <div style={{ width: "80px" }}>
                <Select
                  value={heightUnit}
                  defaultValue={heightUnit}
                  onChange={e => {
                    setHeightUnit(e);
                  }}
                  options={[
                    { value: "cm", label: "cm" },
                    { value: "inch", label: "inch" }
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q213</span>
              {questions.Q213.label}:
            </div>
            <div className="cov19_inf_risk_inp_and_sel_wrap">
              {renderInputPeopDet("Q213", "number", weightUnit.value)}
              <div style={{ width: "80px" }}>
                <Select
                  value={weightUnit}
                  defaultValue={weightUnit}
                  onChange={e => {
                    setWeightUnit(e);
                  }}
                  options={[
                    { value: "kg", label: "kg" },
                    { value: "lb", label: "lb" }
                  ]}
                />
              </div>
            </div>
          </div>
        </div> */}

        <div
          className="cov19_inf_risk_about_cont"
          style={{ marginTop: "20px" }}
        >
          {/* <div className="cov19_inf_risk_about_cont2_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q214</span>
              {questions.Q214.label}:
            </div>
            {renderSelectPeopDet("Q214", 150)}
          </div> */}
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q215</span>
              {questions.Q215.label}:
            </div>
            {renderSelectPeopDet("Q215", 150)}
          </div>
        </div>
        <div className="cov19_inf_risk_about_cont">
          <div className="cov19_inf_risk_about_cont2_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q216</span>
              {questions.Q216.label}:
            </div>
            {renderInputPeopDet("Q216", "text", null)}
          </div>
          <div className="cov19_inf_risk_about_cont1_block">
            <div className="cov19_inf_risk_question_text">
              <span className="covid_question_small_label">Q217</span>
              {questions.Q217.label}:
            </div>
            {renderInputPeopDet("Q217", "text", null)}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loading_overlay">
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
}
