import React, { useState, useEffect } from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import { get as _get } from "lodash";

const HorizontalTimelineChart = ({ apiData }) => {
  const [state, setState] = useState({ value: 0, previous: 0 });
  const [dates, setDates] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    parseData();
    //eslint-disable-next-line
  }, [apiData.length]);

  const parseData = () => {
    let apiDates = [];
    let apiNotes = [];

    let sortedApiData = apiData.sort(function(a, b) {
      return new Date(a.issued_on) - new Date(b.issued_on);
    });

    [...sortedApiData].forEach(item => {
      const date = new Date(item.issued_on.replace(' ', 'T'));
      apiDates = [...apiDates, date];
      apiNotes = [
        ...apiNotes,
        {
          employer: item.document,
          where: item.issued_where
        }
      ]; // eslint-disable-next-line
    });

    setNotes(apiNotes);
    setDates(apiDates);
  };

  if (apiData.length === 0) {
    return <div>No data available</div>;
  } else {
    return (
      <div style={{ marginBottom: "100px" }}>
        {/* Bounding box for the Timeline */}
        <div style={{ width: "95%", height: "200px", margin: "0 auto" }}>
          <HorizontalTimeline
            index={state.value}
            indexClick={index => {
              setState({ value: index, previous: state.value });
            }}
            labelWidth={100}
            values={dates}
          />
        </div>
        <div className="text-center">
          <div>
            <b>Document:</b> {_get(notes, `[${state.value}].employer`, "")}
          </div>
          <div>
            <b>Where:</b> {_get(notes, `[${state.value}].where`, "")}
          </div>
        </div>
      </div>
    );
  }
};
export default HorizontalTimelineChart;
