import React, { Component } from "react";
import "./BookcolLibSortingComp.css";
import { FormattedMessage } from "react-intl";

class BookcolLibSortingComp extends Component {
  constructor() {
    super();
    this.state = {
      show_sort_opt: false,
      curr_sort: null,
      sort_name: "reset",
      sort_dir: null
    };
  }

  componentDidMount() {}

  showSortOpt = () => {
    this.setState({ show_sort_opt: !this.state.show_sort_opt });
  };

  handlePropsSending = (sort_by, direction, category) => {
    this.setState({
      show_sort_opt: false,
      // curr_sort: sort_by + "_" + direction,
      sort_name: sort_by,
      sort_dir: direction
    });
    this.props.bookColSortCompCallback(sort_by, direction, category);
  };

  render() {
    return (
      <div id="BookcolLibSortingComp">
        <div className="blsc_sort_icon" onClick={this.showSortOpt}>
          <FormattedMessage id="bookcol_sort_component_filter" />
        </div>
        {this.state.sort_name !== "reset" &&
        this.state.sort_dir !== null &&
        !this.state.show_sort_opt ? (
          <div className="blsc_curr_filter_and_reset">
            <div
              className="blsc_curr_filtandres_X_btn"
              onClick={() => {
                this.handlePropsSending(
                  "reset",
                  null,
                  this.props.sortBooksInCategory
                );
              }}
            >
              X
            </div>
            <FormattedMessage
              id={"bookcol_sort_component_" + this.state.sort_name}
            />
            {this.state.sort_dir === "asc" ? (
              <span className="blsc_curr_filtandres_dir">
                <nobr>A-Z</nobr>
              </span>
            ) : null}
            {this.state.sort_dir === "desc" ? (
              <span className="blsc_curr_filtandres_dir">
                <nobr>Z-A</nobr>
              </span>
            ) : null}
          </div>
        ) : null}
        {this.state.show_sort_opt ? (
          <div className="blsc_sort_options_container">
            {this.props.showTitleSort ? (
              <>
                <div className="blsc_grid_cell">
                  <FormattedMessage id="bookcol_sort_component_title" />
                </div>
                <div className="blsc_grid_cell">
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "title_asc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "title",
                        "asc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>A-Z</nobr>
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "title_desc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "title",
                        "desc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>Z-A</nobr>
                  </span>
                </div>
              </>
            ) : null}
            {this.props.showAddedSort ? (
              <>
                <div className="blsc_grid_cell">
                  <FormattedMessage id="bookcol_sort_component_added" />
                </div>
                <div className="blsc_grid_cell">
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "added_asc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "added",
                        "asc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>A-Z</nobr>
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "added_desc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "added",
                        "desc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>Z-A</nobr>
                  </span>
                </div>
              </>
            ) : null}
            {this.props.showGiftfromSort ? (
              <>
                <div className="blsc_grid_cell">
                  <FormattedMessage id="bookcol_sort_component_giftfrom" />
                </div>
                <div className="blsc_grid_cell">
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "giftfrom_asc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "giftfrom",
                        "asc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>A-Z</nobr>
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "giftfrom_desc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "giftfrom",
                        "desc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>Z-A</nobr>
                  </span>
                </div>
              </>
            ) : null}
            {this.props.showFavouriteSort ? (
              <>
                <div className="blsc_grid_cell">
                  <FormattedMessage id="bookcol_sort_component_rating" />
                </div>
                <div className="blsc_grid_cell">
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "rating_asc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "rating",
                        "asc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>A-Z</nobr>
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        this.state.sort_name + "_" + this.state.sort_dir ===
                        "rating_desc"
                          ? "#c0fd79"
                          : null
                    }}
                    className="blsc_asc_desc_arrow_btn"
                    onClick={() => {
                      this.handlePropsSending(
                        "rating",
                        "desc",
                        this.props.sortBooksInCategory
                      );
                    }}
                  >
                    <nobr>Z-A</nobr>
                  </span>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default BookcolLibSortingComp;
