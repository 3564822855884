import React from "react";
// import "./avatar.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Barcode from "react-hooks-barcode";

const barcode_config = {
  marginTop: "20px",
  marginBottom: "20px",
  width: 3,
  height: 50,
  isFlipped: false
};

class AvatarInfo extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div id="AvatarInfo">
        <img
          className="avat_close_info"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={this.props.avatar_info_btn_callback}
        />

        <div className="avat_content_wrapper">
          <div>
            {this.props.fromNavigation !== undefined ? (
              this.props.fromNavigation ? (
                <FormattedHTMLMessage id="avatar_info_register_message" />
              ) : null
            ) : null}
          </div>
          <div className="avat_hubid_pin">
            <div>
              <FormattedMessage id="logout_HUB_ID" />:{" "}
              {JSON.parse(sessionStorage.getItem("hub_token"))
                .userData.my_hub_id.slice(-5)
                .replace("_", "")}
            </div>

            <div>
              <FormattedMessage id="logout_PIN" />:{" "}
              {
                JSON.parse(sessionStorage.getItem("hub_token")).userData
                  .temp_pin
              }
            </div>
          </div>
          {this.props.fromNavigation !== undefined ? null : (
            <FormattedMessage id="avatar_info_message" />
          )}
          <div className="avat_barcode_img">
            <Barcode
              value={
                sessionStorage.getItem("hub_token")
                  ? JSON.parse(sessionStorage.getItem("hub_token"))
                      .userData.my_hub_id.slice(-5)
                      .replace("_", "")
                  : "LOG IN"
              }
              {...barcode_config}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AvatarInfo;
