import React, { Component } from "react";
import "./ReceiptsShopIcons.css";
import { GetShopsInfo } from "../../services/GetShopsInfo";
import Slider from "react-slick";
import { FormattedMessage } from "react-intl";
import Content from "../../components/Content";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

// PREFIX FOR ReceiptShopIcons = rsi_

class ReceiptsShopIcons extends Component {
  constructor() {
    super();
    this.state = {
      shops: null,
      shops_all_partners: null,
      recentShops: null,
      redirected: false
    };
  }

  componentDidMount() {
    this.get_shop_info();
  }

  get_shop_info = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "";
    GetShopsInfo(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.shops_info) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState({
          shops: receiptJson.shops_info,
          shops_all_partners: receiptJson.shop_partners,
          recentShops: receiptJson.shops_info.slice(0, 3)
        });
      } else {
        console.log("no shops");
      }
    });
  };
  redirect = () => {
    if (this.state.redirected === true) {
      console.log(this.state.redirected);
      return <Redirect to="/HUBsite" />;
    }
  };
  render() {
    const { shops } = this.state;
    const { recentShops } = this.state;
    const { shops_all_partners } = this.state;

    let disc_rows_num = 1;
    if (this.state.shops_all_partners) {
      if (this.state.shops_all_partners.length > 6) {
        disc_rows_num = 3;
      } else if (this.state.shops_all_partners.length > 3) {
        disc_rows_num = 2;
      }
    }

    var settings_discover = {
      arrows: false,
      autoplay: true,
      dots: true,
      infinite: false,
      speed: 500,
      rows: disc_rows_num,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    let all_rows_num = 1;
    if (this.state.shops) {
      if (this.state.shops.length > 3) {
        all_rows_num = 2;
      }
    }

    var settings_all = {
      // lazyLoad: true,
      infinite: false,
      arrows: false,
      dots: true,
      speed: 500,
      rows: all_rows_num,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    var settings_recent = {
      // lazyLoad: true,
      arrows: false,
      infinite: false,
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    return (
      <Content currComp={"receiptShopIcons"}>
        <img
          className="bsb_top_back_arrow"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={() => this.setState({ ...this.state, redirected: true })}
        />
        {this.redirect()}
        <div id="ReceiptsShopIcons">
          <div className="rsi_top_img_wrapper" />
          <div className="rsi_round_corner_background">
            <div className="rsi_big_title">
              <FormattedMessage id="receipt_shop_icons_receipts" />
            </div>
            <div>
              <div className="rsi_small_title">
                <FormattedMessage id="receipt_shop_icons_recent" />
              </div>
              <div className="rsi_recent_wrapper">
                {this.state.shops !== null && (
                  <Slider {...settings_recent}>
                    {recentShops.map((item, index) => {
                      if (!item) {
                        return null;
                      }
                      return (
                        <div key={index} className="rsi_shop_item_wrapper">
                          <Link to={"/receipts/" + item.shop_id}>
                            <div className="rsi_shop_logo_div">
                              <img
                                className="rsi_shop_logo"
                                alt=""
                                src={item.shop_logo_link}
                              />
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
            <div>
              <span className="rsi_hr_line" />
              <div className="rsi_small_title">
                <FormattedMessage id="receipt_shop_icons_all" />
              </div>
              <div className="rsi_all_wrapper">
                {this.state.shops !== null && (
                  <Slider {...settings_all}>
                    {shops.map((item, index) => {
                      if (!item) {
                        return null;
                      }
                      return (
                        <div key={index} className="rsi_shop_item_wrapper">
                          <Link to={"/receipts/" + item.shop_id}>
                            <div className="rsi_shop_logo_div">
                              <img
                                className="rsi_shop_logo"
                                alt=""
                                src={item.shop_logo_link}
                              />
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
            <div>
              <span className="rsi_hr_line" />
              <div className="rsi_small_title">
                <FormattedMessage id="receipt_shop_icons_discover_partners" />
              </div>

              <div>
                {this.state.shops_all_partners !== null && (
                  <Slider {...settings_discover}>
                    {shops_all_partners.map((item, index) => {
                      if (!item) {
                        return null;
                      }
                      return (
                        <div key={index} className="rsi_shop_item_wrapper">
                          <div className="rsi_shop_logo_div">
                            <img
                              className="rsi_shop_logo"
                              alt=""
                              src={item.shop_logo_link}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

export default ReceiptsShopIcons;
