import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useIntl } from "react-intl";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  progress_bar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    maxWidth: "500px",
    padding: "0 10px",
    marginBottom: "20px"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    width: "300px"
  },
  modalTitle: {
    alignSelf: "center",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
    backgroundColor: "#3FA5FF",
    color: "white",
    textAlign: "center",
    paddingTop: "18px",
    height: "60px",
    width: "100%"
  },
  modalTitleErr: {
    alignSelf: "center",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
    backgroundColor: "#ff5858",
    color: "white",
    textAlign: "center",
    paddingTop: "18px",
    height: "60px",
    width: "100%"
  },
  status: {
    alignSelf: "center",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#3FA5FF",
    padding: "20px 0"
  },
  error_text: {
    color: "#545669",
    fontFSize: "14px",
    fontWeight: "600",
    padding: "20px",
    textAlign: "center"
  },
  error_btn: {
    color: "#3FA4FF",
    fontWeight: "800",
    cursor: "pointer"
  }
}));

const CreateHubModal = ({
  open,
  setOpen,
  creatingDone,
  setProgressBarDone,
  createHubError,
  register
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    let i = 0;
    function increment1() {
      if (i < 80) {
        if (creatingDone) {
          clearInterval(interval1);
        } else {
          let random = Math.ceil(Math.random() * 10);
          i = i + random;
          setCompleted(i);
        }
      } else {
        clearInterval(interval1);
      }
    }
    let interval1 = setInterval(increment1, 500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (creatingDone) {
      let i = completed;
      function increment2() {
        if (i < 100) {
          i++;
          setCompleted(i);
        } else {
          clearInterval(interval2);
          setTimeout(() => {
            setProgressBarDone(true);
          }, 1000);
        }
      }
      let interval2 = setInterval(increment2, 50);
    }
    // eslint-disable-next-line
  }, [creatingDone]);

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <div className={classes.modal}>
        <span
          className={
            !createHubError ? classes.modalTitle : classes.modalTitleErr
          }
        >
          {intl.formatMessage({
            id: "create_hub_modal_prepare"
          })}
        </span>
        {!createHubError ? (
          <>
            <div className={classes.status}>{completed}%</div>
            <div className={classes.progress_bar}>
              <LinearProgress variant="determinate" value={completed} />
            </div>
          </>
        ) : (
          <>
            <div className={classes.error_text}>
              {intl.formatMessage({
                id: "create_hub_modal_not_quite_there_pt1"
              })}{" "}
              <br />
              <span
                className={classes.error_btn}
                onClick={() => {
                  setCompleted(0);
                  setOpen(false);
                  register();
                }}
              >
                {intl.formatMessage({
                  id: "create_hub_modal_not_quite_there_click"
                })}
              </span>{" "}
              <br />
              {intl.formatMessage({
                id: "create_hub_modal_not_quite_there_pt2"
              })}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default CreateHubModal;
