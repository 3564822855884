import React from "react";
import "./ReceiptSingle.css";
import "./receipt_with_imgs.css";
import "./receipt_form_navigation.css";
import "./receipt_blank.css";
import { GetShopSpecificReceiptData } from "../../services/GetReceiptData";
import Swipe from "react-easy-swipe";
import { GetShopsInfo } from "../../services/GetShopsInfo";
import { FormattedMessage } from "react-intl";
import ReceiptForm from "../../components/Receipt/ReceiptForm";
import { Link } from "react-router-dom";
import Content from "../../components/Content";
import { Redirect } from "react-router-dom";

class ReceiptSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: "",
      fadeOut: "",
      receipt_data_load: true,
      receipt_data: {},
      vat_data: [],
      receipt_items: [],
      picture_links: [],
      receipts_count: 0,
      receipt_to_show: 0,
      curentCss: "",
      selectedView: 0,
      display: true,
      showModalID: false,
      shop_id: this.props.match.params.shop_id,
      redirectToCockpit: false
    };
  }

  //function ONLY for SHOP MIRKA
  add_img_to_receipt_items = () => {
    if (this.state.receipt_data.counterpart_hub_id.slice(-1) === "2") {
      // if(true){
      // first, build an easier lookup of picture data:
      let picturemap = {};
      this.state.picture_links.forEach(function(picture) {
        picturemap[picture.article_id] = picture;
      });

      // now do the "join" and if img is null replace with missing image:
      this.state.receipt_items.forEach(function(item) {
        if (picturemap[item.item_id].img_src == null) {
          item.img_src = "./assets/images/missing-image.png";
        } else {
          item.img_src = picturemap[item.item_id].img_src;
        }
      });
    }
  };

  componentDidMount() {
    if (this.props.match.params.shop_id) {
      if (this.state.receipt_data_load) {
        this.get_receipt_data();
        this.setState({ receipt_data_load: false });
      }
    } else {
      this.get_shop_info();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.receipt_to_show !== this.state.receipt_to_show) {
      this.get_receipt_data();
    }
  }

  get_receipt_data = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.receipt_to_show = this.state.receipt_to_show;
    send_credential_to_registrar.shop_id = this.state.shop_id;
    send_credential_to_registrar.section = "";
    GetShopSpecificReceiptData(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.message) {
        this.setState({ receipts_count: 0 });
      }
      if (receiptJson.receipt_basics) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState(
          {
            receipt_data: receiptJson.receipt_basics,
            picture_links: receiptJson.picture_links,
            receipt_items: receiptJson.receipt_items,
            receipts_count: receiptJson.receipts_count,
            vat_data: receiptJson.receipt_vat
          },
          function() {
            // !!!!! ONLY AFTER counterpart_hub_id is WRITEN in STATE WE CAN iterate functions and conditions around it
            // Statement above should follow all functions working with state values
            this.add_img_to_receipt_items();

            //load SHOP CSS based on shop name
            require("./" + this.state.receipt_data.counterpart_hub_id + ".css");
            this.setState({
              curentCss:
                "css" +
                parseInt(
                  this.state.receipt_data.counterpart_hub_id
                    .slice(-7)
                    .replace("_", ""),
                  10
                )
            });

            // Callback from cockpit so we stay on correct receipt even if rerender on orientation change
            // this.props.callbackFromParent(
            //   this.state.receipts_count,
            //   this.state.receipt_to_show
            // );
          }
        );
      } else {
        console.log("callbac error");
        // commented out for test if disable will break anything
        // this.get_shop_info();
      }
    });
  };

  get_shop_info = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    GetShopsInfo(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.shops_info.length > 0) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState(
          {
            shops: receiptJson.shops_info,
            shop_id: receiptJson.shops_info[0].shop_id
          },
          () => {
            this.get_receipt_data();
          }
        );
      } else {
        console.log("no shops");
        this.setState({ redirectToCockpit: true });
      }
    });
  };

  onSwipeMove = position => {
    this.setState({ positionChange: Number(`${position.x}`) });
  };

  onSwipeEnd = () => {
    if (
      this.state.positionChange < -150 &&
      !(0 >= this.state.receipt_to_show)
    ) {
      this.setState({
        positionChange: 0,
        receipt_to_show: this.state.receipt_to_show - 1
      });
      this.fadeOut();
    } else if (
      this.state.positionChange > 150 &&
      !(this.state.receipt_to_show >= this.state.receipts_count - 1)
    ) {
      this.setState({
        positionChange: 0,
        receipt_to_show: this.state.receipt_to_show + 1
      });
      this.fadeIn();
    }
  };
  fadeIn = () => {
    this.setState({ fadeIn: "swing-in-right-fwd" });
    this.setState({ fadeOut: "swing-out-right-bck" });
    setTimeout(() => {
      this.setState({ fadeIn: "" });
      this.setState({ fadeOut: "" });
    }, 700);
  };
  fadeOut = () => {
    this.setState({ fadeIn: "swing-in-left-fwd " });
    this.setState({ fadeOut: "swing-out-left-bck" });
    setTimeout(() => {
      this.setState({ fadeIn: "" });
      this.setState({ fadeOut: "" });
    }, 700);
  };
  prevReceipt = () => {
    this.setState({ receipt_to_show: this.state.receipt_to_show - 1 });
    this.fadeOut();
  };

  nextReceipt = () => {
    this.setState({ receipt_to_show: this.state.receipt_to_show + 1 });
    this.fadeIn();
  };
  handleHideReceipt = () =>
    this.setState({ receiptHide: !this.state.receiptHide });

  render() {
    if (this.state.redirectToCockpit) {
      return <Redirect to={"/cockpit"} />;
    }

    let view = this.renderReceiptView();
    return (
      <Content currComp={"receiptSingle"}>
        <Swipe
          className="ReceiptSingle"
          onSwipeMove={this.onSwipeMove}
          onSwipeEnd={this.onSwipeEnd}
        >
          <div className="rs_top_img_wrapper" />
          <Link to="/receipts">
            <img
              className="rs_top_back_arrow"
              alt=""
              src="/assets/images/left_arrow_icon_v2.png"
              onClick={this.props.receipt_btn_callback}
            />
          </Link>
          <div className="rs_round_corner_background">
            <div id="receipt_index_navigation_container">
              <img
                alt=""
                className={
                  this.state.receipt_to_show >= this.state.receipts_count - 1
                    ? "rs_btn_disabled"
                    : null
                }
                id="rs_next_receipt_btn"
                src="/assets/images/left_arrow_icon.png"
                onClick={
                  this.state.receipt_to_show >= this.state.receipts_count - 1
                    ? null
                    : this.nextReceipt
                }
              />
              <img
                alt=""
                className={
                  0 >= this.state.receipt_to_show ? "rs_btn_disabled" : null
                }
                id="rs_prev_receipt_btn"
                src="/assets/images/right_arrow_icon.png"
                onClick={
                  0 >= this.state.receipt_to_show ? null : this.prevReceipt
                }
              />
              <div className="rs_receipt_count_wrapper">
                <span>
                  {this.state.receipts_count - this.state.receipt_to_show}
                </span>
                <span>/</span>
                <span>{this.state.receipts_count}</span>
              </div>
            </div>
            {view}
          </div>
        </Swipe>
      </Content>
    );
  }

  renderReceiptView = () => {
    let rows;
    if (this.state.receipt_items) {
      rows = this.state.receipt_items.map((item, i) => {
        //if item quantity is bigger then 1 display quantity and price, if no hide item quantity
        if (Number(item.quantity) > 1) {
          return (
            <div
              className={this.state.curentCss + "item_inner_wrapper_grid"}
              key={i}
            >
              <div className={this.state.curentCss + "item_description_class"}>
                {item["description"]}
              </div>
              <div className={this.state.curentCss + "item_id_class"}>
                {item["item_id"]}
              </div>
              <div className={this.state.curentCss + "item_ean_class"}>
                {item["ean"]}
              </div>
              <div
                className={this.state.curentCss + "item_free_parameter1_class"}
              >
                {item["free_parameter1"]}
              </div>
              <div className={this.state.curentCss + "item_measure_unit"}>
                {item["unit"]}
              </div>
              <div className={this.state.curentCss + "item_amount"}>
                {item.quantity}
              </div>
              <div className={this.state.curentCss + "unit_price_incl_tax"}>
                {item["unit_price"]}
              </div>
              <div
                className={this.state.curentCss + "total_item_price_incl_tax"}
              >
                {item.total}
              </div>
              <div className={this.state.curentCss + "tax_group_descr"}>
                {item["vat_category"]}
              </div>
              <div className={this.state.curentCss + "tax_group_value"}>
                {item["free_parameter2"]}
              </div>
            </div>
          );
        } else {
          return (
            <div
              className={this.state.curentCss + "item_inner_wrapper_grid"}
              key={i}
            >
              <div className={this.state.curentCss + "item_description_class"}>
                {item["description"]}
              </div>
              <div className={this.state.curentCss + "item_id_class"}>
                {item["item_id"]}
              </div>
              <div className={this.state.curentCss + "item_ean_class"}>
                {item["ean"]}
              </div>
              <div
                className={this.state.curentCss + "item_free_parameter1_class"}
              >
                {item["free_parameter1"]}
              </div>
              <div
                className={
                  this.state.curentCss +
                  "item_measure_unit " +
                  this.state.curentCss +
                  "single_item"
                }
              >
                {item["unit"]}
              </div>
              <div
                className={
                  this.state.curentCss +
                  "item_amount " +
                  this.state.curentCss +
                  "single_item"
                }
              >
                {item.quantity}
              </div>
              <div
                className={
                  this.state.curentCss +
                  "unit_price_incl_tax " +
                  this.state.curentCss +
                  "single_item"
                }
              >
                {item["unit_price"]}
              </div>
              <div
                className={this.state.curentCss + "total_item_price_incl_tax"}
              >
                {item.total}
              </div>
              <div className={this.state.curentCss + "tax_group_descr"}>
                {item["vat_category"]}
              </div>
              <div className={this.state.curentCss + "tax_group_value"}>
                {item["free_parameter2"]}
              </div>
            </div>
          );
        }
      });
    }

    let vat_rows;
    if (this.state.vat_data) {
      vat_rows = this.state.vat_data.map((item, i) => {
        return (
          <div className={this.state.curentCss + "vat_grid_container"} key={i}>
            <div className={this.state.curentCss + "VAT_RATE"}>
              {item["vat_rate"]}%
            </div>
            <div className={this.state.curentCss + "VAT_CATEGORY_ROW1"}>
              {item["vat_category"]}
            </div>
            <div className={this.state.curentCss + "VAT_CATEGORY_ROW2"}>
              {item["vat_category"]}
            </div>
            <div className={this.state.curentCss + "VAT_CATEGORY_ROW3"}>
              {item["vat_category"]}
            </div>

            <div className={this.state.curentCss + "FOR_TAX_DESC"} />
            <div className={this.state.curentCss + "FOR_TAX_SUM"}>
              {item["total_price_per_cat"]}
            </div>
            <div className={this.state.curentCss + "TAX_BASE_DESC"} />
            <div className={this.state.curentCss + "TAX_BASE_SUM"}>
              {item["sum_without_vat_per_cat"]}
            </div>
            <div className={this.state.curentCss + "TAX_SUM_DESC"} />
            <div className={this.state.curentCss + "TAX_SUM"}>
              {item["sum_vat_per_cat"]}
            </div>
          </div>
        );
      });
    }

    // eslint-disable-next-line no-unused-vars
    let cash_to_return_class;
    if (Number(this.state.receipt_data.cash_to_return) === 0) {
      cash_to_return_class = "CASH_TO_RETURN_ZERO";
    }

    if (this.state.receipt_items.length > 0) {
      return (
        <div className="form-container">
          <div className={this.state.fadeOut}>
            <ReceiptForm
              state={this.state}
              rows={rows}
              handleHideReceipt={this.handleHideReceipt}
              vat_rows={vat_rows}
              cash_to_return_class={this.cash_to_return_class}
            />
          </div>
          <div className={this.state.fadeIn}>
            <ReceiptForm
              state={this.state}
              rows={rows}
              handleHideReceipt={this.handleHideReceipt}
              vat_rows={vat_rows}
              cash_to_return_class={this.cash_to_return_class}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="rs_blank_receipt_container">
          <div className="blank_logo_container">
            <img
              className="blank_logo"
              alt=""
              src="/assets/images/personal_hub_icon.png"
            />
          </div>
          <div className="blank_grid_container">
            <div className="blank_text">
              <FormattedMessage id="no_receipts_yet_message" />
            </div>
            <div className="blank_text_0">Item 01524</div>
            <div className="blank_text_1">A 0.00 €</div>
            <div className="blank_text_2">SUM TOTAL</div>
            <div className="blank_text_3">0.00 €</div>
          </div>
        </div>
      );
    }
  };
}

export default ReceiptSingle;
