import { URLconst } from "./const";
import axios from "axios";

// export function GetProfileData(index) {
//   let BaseURL = URLconst("hub_basics/my_basics_profile_pic.php");

//   return new Promise((resolve, reject) => {
//     fetch(BaseURL, {
//       method: "POST",
//       body: JSON.stringify(index)
//     })
//       .then(response => response.json())
//       .then(res => {
//         resolve(res);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

export function getProfileBasics(param) {
  let BaseURL = URLconst("hub_basics/basics.php");

  return axios.get(BaseURL, {
    params: param
  });
}
