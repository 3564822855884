import React, { useState, useEffect } from "react";
import "./Categories.css";
import SubCategories from "../SubCategories/SubCategories";
import Badge from "@material-ui/core/Badge";
import { useIntl } from "react-intl";

const Categories = ({ items, state, setState, shift }) => {
  const intl = useIntl();
  const activeItem = state.activeCategory;
  const [activeSubCategory, setActiveSubCategory] = useState(
    state.activeSubCategory
  );

  useEffect(() => {
    setState({ ...state, activeSubCategory: activeSubCategory }); // eslint-disable-next-line
  }, [activeSubCategory]);

  const switchData = id => {
    setState({ ...state, activeCategory: id });
    if (id !== activeItem) {
      setActiveSubCategory(null);
    }
  };

  const disabledItemStyles = {
    filter: "grayscale(100%)"
  };
  if (items.length > 0) {
    return (
      <div className="container">
        {items.map((item, id) => {
          const itemStyles = {
            backgroundImage: `url("${item.src}")`,
            transform: "perspective(21em) rotateY(-25deg)",
            opacity: "0.80"
          };
          const itemStylesActive = {
            backgroundImage: `url("${item.src}")`,
            transform: `translateY(-10px)`,
            border: "1px solid #3FA4FF",
            boxShadow: "0 0 10px -2px #3FE0FF"
          };
          return (
            <div
              className={"item " + (id === activeItem ? "moveUp" : "")}
              key={id}
              onClick={() => switchData(id)}
              style={
                item.components.length === 0 && item.subCategories.length === 0
                  ? disabledItemStyles
                  : {}
              }
            >
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                badgeContent={
                  item.subCategories.length !== null
                    ? item.subCategories.length
                    : null
                }
                variant="dot"
                color="primary"
              >
                <div
                  className={
                    "image " + (id !== activeItem ? "reflection" : null)
                  }
                  style={id === activeItem ? itemStylesActive : itemStyles}
                ></div>
              </Badge>
              {activeItem === id || shift !== 0 ? (
                <div className="category_title">
                  {item.title !== "" &&
                  item.title !== null &&
                  item.title !== undefined
                    ? intl.formatMessage({
                        id: item.title
                      })
                    : item.title}
                </div>
              ) : null}
              {/* {activeItem !== id ? (
              // <div
              //   style={
              //     id !== activeItem
              //       ? {
              //         transform: "perspective(0em) rotateY(-45deg)"
              //       }
              //       : null
              //   }
              //   className="shadow"
              // ></div>
              
            ) : (
                <></>
              )} */}

              {activeItem === id && item.subCategories.length !== 0 ? (
                <SubCategories
                  items={item.subCategories}
                  length={items.length}
                  activeSubCategory={activeSubCategory}
                  setActiveSubCategory={setActiveSubCategory}
                  activeCategory={id}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default Categories;
