import { URLconst } from "./const";
import axios from "axios";

export function postReceiptToHub(param) {
  let BaseURL = URLconst("index.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: { endpoint: "receipts/hub_receipt" },
    data: param
  });
}

export function postReceiptToShop(param) {
  let BaseURL = URLconst("index.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: { endpoint: "receipts/shop_receipt" },
    data: param
  });
}

export function postHubBasics(param) {
  let BaseURL = URLconst("hub_basics/basics.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: null,
    data: param
  });
}
