import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const WeightTimeLineGraph = ({ apiData }) => {
  const [chartData, setChartData] = useState([]);

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(b.when) - new Date(a.when);
    });
    let chart = [
      [{ type: "date", label: "Date" }, "Weight", "Fat mass", "Non fat mass"]
    ];

    apiDataToMap.map(item => {
      return chart.push([
        new Date(item.when_on.replace(' ', 'T')),
        Number(item.weight),
        Number(item.fat_mass),
        Number(item.non_fat_mass)
      ]);
    });
    setChartData(chart);
  };

  useEffect(() => {
    if (apiData.length !== 0) {
      parseApiData();
    }
    // eslint-disable-next-line
  }, [apiData.length]);

  if (apiData.length !== 0) {
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        style={{ marginBottom: "100px" }}
        options={{
          vAxis: {
            title: "Value"
          },
          series: {
            1: { curveType: "function" }
          }
        }}
      />
    );
  } else {
    return null;
  }
};
export default WeightTimeLineGraph;
