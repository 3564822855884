import React from "react";
import { IntlProvider } from "react-intl";
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import messages_sk from "./translations/sk.json";
import messages_cs from "./translations/cs.json";
import messages_it from "./translations/it.json";

const deTranslation = messages_de;
const enTranslation = messages_en;
const skTranslation = messages_sk;
const csTranslation = messages_cs;
const itTranslation = messages_it;

const messages = {
  de: messages_de,
  en: messages_en,
  sk: messages_sk,
  cs: messages_cs,
  it: messages_it
};

var language = "en";
if (Object.keys(messages).indexOf(navigator.language.split(/[-_]/)[0]) > -1) {
  language = navigator.language.split(/[-_]/)[0];

  if (localStorage.getItem("personal_hub_language") === null) {
    localStorage.setItem("personal_hub_language", navigator.language);
  } else {
    if (
      Object.keys(messages).indexOf(
        localStorage.getItem("personal_hub_language").split(/[-_]/)[0]
      ) > -1
    ) {
      language = localStorage.getItem("personal_hub_language").split(/[-_]/)[0];
    } else {
      // console.log("unsupported language preset, falling to default EN");
    }
  }
}

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchToEnglish = () => {
      this.setState({ locale: "en", messages: enTranslation });
      localStorage.setItem("personal_hub_language", "en-GB");
    };

    this.switchToDeutsch = () => {
      this.setState({ locale: "de", messages: deTranslation });
      localStorage.setItem("personal_hub_language", "de-DE");
    };

    this.switchToSlovak = () => {
      this.setState({ locale: "sk", messages: skTranslation });
      localStorage.setItem("personal_hub_language", "sk-SK");
    };

    this.switchToCzech = () => {
      this.setState({ locale: "cs", messages: csTranslation });
      localStorage.setItem("personal_hub_language", "cs-CS");
    };

    this.switchToItalian = () => {
      this.setState({ locale: "it", messages: itTranslation });
      localStorage.setItem("personal_hub_language", "it-IT");
    };

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: language,
      messages: messages[language],
      switchToEnglish: this.switchToEnglish,
      switchToDeutsch: this.switchToDeutsch,
      switchToSlovak: this.switchToSlovak,
      switchToCzech: this.switchToCzech,
      switchToItalian: this.switchToItalian
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
          textComponent="span"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
