import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Item from "./TableItem";
import { getActivities } from "../../../../services/GetActiveMinuteData";
import { Divider } from "@material-ui/core";
import { getActiveMinuteSettings } from "../../../../services/GetActiveMinuteData";
import { putActiveMinuteSettings } from "../../../../services/PutActiveMinuteData";
import { injectIntl } from "react-intl";
import AddActivity from "./AddActivity";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  disabled: {
    color: "grey !important"
  },
  mainBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  togglesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  activitiessContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  info: {
    fontSize: "12px",
    fontFamily: "Roboto",
    width: "100%",
    padding: "3px"
  }
}));

const Activities = ({ handleSubmitIconChange, element, intl }) => {
  const classes = useStyles();
  const [version, setVersion] = useState("basic");
  const [day, setDay] = useState("today");
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(null);
  const [settingsID, setSettingsID] = useState(0);
  const [addActivityModal, setAddActivityModal] = useState(false);
  const [redirect, setRedirect] = useState("");
  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", "")
  };
  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  const getApiData = () => {
    getActivities(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result)
      } else {
        setData(result.data.data_array);
      }
    });
    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 63) {
            if (item.value === "yesterday") {
              setDay(item.value);
            }
            setSettingsID(item.id);
          }
        });
      }
    });
  };

  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  const updateSettingsInApi = newDay => {
    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.value = newDay;
    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const handleVersion = (event, newVersion) => {
    if (newVersion !== null) {
      setVersion(newVersion);
    }
  };

  const handleDay = (event, newDay) => {
    if (newDay !== null && edit === null) {
      setDay(newDay);
      updateSettingsInApi(newDay);
    }
  };

  const toggleButtonsVersion = () => {
    return (
      <ToggleButtonGroup
        value={version}
        exclusive
        onChange={handleVersion}
        aria-label="text alignment"
      >
        <ToggleButton value="basic" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_basic"
          })}
        </ToggleButton>
        <ToggleButton
          value="advanced"
          className={classes.disabled}
          disabled
          aria-label="center aligned"
        >
          {intl.formatMessage({
            id: "active_minute_advanced"
          })}
        </ToggleButton>
        <ToggleButton
          className={classes.disabled}
          disabled
          value="pro"
          aria-label="right aligned"
        >
          {intl.formatMessage({
            id: "active_minute_pro"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  const toggleButtonsDay = () => {
    return (
      <ToggleButtonGroup
        value={day}
        exclusive
        onChange={handleDay}
        aria-label="text alignment"
      >
        <ToggleButton value="yesterday" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_yesterday"
          })}
        </ToggleButton>
        <ToggleButton value="today" aria-label="center aligned">
          {intl.formatMessage({
            id: "active_minute_today"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  const renderActivities = () => {
    return data.map((item, id) => {
      return (
        <React.Fragment key={id}>
          <Item
            data={item}
            day={day}
            edit={edit}
            setEdit={setEdit}
            currentId={id}
            handleSubmitIconChange={handleSubmitIconChange}
            element={element}
          />
        </React.Fragment>
      );
    });
  };

  const renderRedirect = () => {
    if (redirect !== "") {
      return (
        <Redirect
          push
          to={{
            pathname: redirect
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.mainBlock}>
      <div className={classes.togglesContainer}>
        <div>{toggleButtonsVersion()}</div>
        <div>{toggleButtonsDay()}</div>
      </div>
      {renderRedirect()}
      <div className={classes.activitiessContainer}>{renderActivities()}</div>
      <Divider style={{ width: "100%", margin: "10px 0 5px 0" }} />
      <AddActivity
        openModal={addActivityModal}
        intl={intl}
        setAddActivityModal={setAddActivityModal}
        getApiData={getApiData}
      />
      <div className={classes.info}>
        <button
          className="acitivity_add_activity_btn"
          onClick={() => setAddActivityModal(true)}
        >
          {intl.formatMessage({
            id: "active_minute_activities_add_new"
          })}
        </button>
        <button
          className="acitivity_add_activity_btn"
          onClick={() => setRedirect("/hubmanager/activities")}
        >
          {intl.formatMessage({
            id: "active_minute_activities_edit_raw"
          })}
        </button>
      </div>
    </div>
  );
};

export default injectIntl(Activities);
