import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import "./ChartController.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ResponsiveContainer } from "recharts";
import { Typography } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import constants from "../../constants";
import helpers from "../../helpers";
import { FormattedMessage } from "react-intl";

const ChartController = props => {
  const [category, setCategory] = useState({});
  const [categoryList, setcategoryList] = useState([]);
  const [filter, setFilter] = useState({});
  const [currentDate, setCurrentDate] = useState(moment());
  const [data, setData] = useState([]);

  // destructuring
  const {
    chartsSelects,
    showCurrentDate,
    createCategorySelect
  } = helpers.Chart;
  const { filterDropDown } = constants.Charts;
  const { createChartsCollback, selectKeys, title } = props;

  useEffect(() => {
    const { getData = () => [], getDataPayload = {}, categoryProps } = props;
    const fetchData = async () => {
      const data = await getData(getDataPayload);
      if (!data.message) {
        setFilter(filterDropDown[0]);
        setData(data.graphdata);
        const selectData =
          categoryProps || createCategorySelect(data.graphdata, selectKeys);
        setcategoryList(selectData);
        setCategory(selectData[0]);
      }
    };
    fetchData();
  }, [createCategorySelect, filterDropDown, props, selectKeys]);

  // eventListener
  const popoverHandleChange = event => setCategory(event.target.value);
  const filterHandleChange = event => setFilter(event.target.value);
  const onCurrentDataChange = (handler = "increase") =>
    handler === "increase"
      ? setCurrentDate(moment(currentDate).add(1, filter.value))
      : setCurrentDate(moment(currentDate).subtract(1, filter.value));

  return (
    <>
      {data.length === 0 ? (
        <h5>
          <FormattedMessage id="charts_controller_no_items" />
        </h5>
      ) : null}
      <div className="chart-controller-header">
        <h3 className="charts-screen-type">{title}</h3>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="date-picker">
            <KeyboardDatePicker
              clearable
              format="dd-MM-yyyy"
              views={["year", "month", "date"]}
              value={currentDate}
              onChange={value => setCurrentDate(moment(value))}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
      <div className="controls">
        <div className="chart-controller-dropdown">
          {chartsSelects(
            categoryList,
            "category",
            category,
            popoverHandleChange
          )}
        </div>
        <div className="charts-screen-date-picker">
          {filter.value !== "all" ? (
            <button
              className="chart-controller-arrows"
              onClick={() => onCurrentDataChange("decrease")}
            >
              <ArrowBackIosIcon fontSize="small" />
            </button>
          ) : (
            <></>
          )}
          <Typography
            style={{ textAlign: "center", fontSize: "0,5em", width: "80px" }}
          >
            {showCurrentDate(filter.value, currentDate)}
          </Typography>
          {filter.value !== "all" ? (
            <button
              className="chart-controller-arrows"
              onClick={() => onCurrentDataChange("increase")}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="chart-controller-dropdown">
          {chartsSelects(filterDropDown, "filter", filter, filterHandleChange)}
        </div>
      </div>
      {category && (
        <div className="chart-controller">
          <ResponsiveContainer>
            {props.children({
              data: createChartsCollback(
                filter.value,
                data,
                currentDate,
                category
              )
            })}
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default ChartController;
