import { URLconst } from "./const";

export function GetShopsInfo(userData) {
  let BaseURL = URLconst("receipts/get_shop_info.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function GetShopsInfoForVirtualAssistant(userData) {
  let BaseURL = URLconst("navigation/get_available_shops.php");
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function GetBookShops(userData) {
  let BaseURL = URLconst("shop_exchange/get_bookshops.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}
