import React, { Component } from "react";
import "./SingleBookInfo.css";
import Content from "../../components/Content";

class SingleBookInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    if (this.props.location.state !== undefined) {
      const book = this.props.location.state.book_info[0];
      return (
        <Content currComp={"singleBookInfo"}>
          <div id="SingleBookInfo">
            <img
              id="sbi_top_bookshelf_bg"
              alt=""
              src="/assets/images/library_img.jpg"
            />
            <div className="sbi_round_corner_background">
              <div className="sbi_book_data_wrapper">
                <span className="sbi_book_img_wrapper">
                  <img alt="" src={book.url_normal} />
                </span>
                <div>
                  <strong>Title: </strong> {book.Title}
                </div>
                <div>
                  <strong>Subtitle: </strong> {book.Subtitle}
                </div>
                <div>
                  <strong>Author: </strong> {book.Author}
                </div>
                <div>
                  <strong>EAN: </strong> {book.EAN}
                </div>
                <div>
                  <strong>Series: </strong> {book.Series}
                </div>
                <div>
                  <strong>Year: </strong> {book.Year}
                </div>
                <div>
                  <strong>Pages: </strong> {book.Pages}
                </div>
                <div>
                  <strong>Publisher: </strong> {book.Publisher}
                </div>
              </div>
            </div>
          </div>
        </Content>
      );
    }
    return <div id="SingleBookInfo">NO PROPS RECEIVED</div>;
  }
}

export default SingleBookInfo;
