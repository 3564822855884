import React from "react";
import "./ErrorBoundary.css";
import { FormattedMessage } from "react-intl";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div id="ErrorBoundary">
          <div className="errBound_inner_container">
            <div className="errBound_inner_wrapper">
              <div className="errBound_text">
                <FormattedMessage id="errorBoundary_text" />
              </div>
              <div
                className="errBound_reload_btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <FormattedMessage id="errorBoundary_reload" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
