import { URLconst } from "./const";
import axios from "axios";

//POST export function data structure example

// export function postWeightOrFat(param) {
//   let BaseURL = URLconst("hubsite/health/weight_and_fat.php");

//   return axios({
//     url: BaseURL,
//     method: "post",
//     headers: { "content-type": "application/json" },
//     params: {
//       target_hub_id: param.target_hub_id,
//       logged_in: param.logged_in
//     },
//     data: {
//       data: {
//         related_issue_id: null,
//         related_visit_id: null,
//         category: "body shape",
//         when: "2018-01-02 16:31:00",
//         what: "fat",
//         value: "17.5",
//         unit: "%",
//         reference_value: null,
//         param: null,
//         note: "any note for fat"
//       }
//     }
//   });
// }

// Example of POST call from component

// postWeightOrFat(send_credential_to_registrar)
//       .then(result => {
//         if (result.data.error) {
//           console.log(result.data.error);
//         } else {
//           console.log(result.data.success);
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });

// Actual POST function

export function postWeightOrFat(param) {
  let BaseURL = URLconst("hubsite/health/weight_and_fat.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postVaccinationExpirations(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_expirations.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postDentistHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_history.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postDentistTeethHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_teeth_history.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCovidDisease(param) {
  let BaseURL = URLconst("hubsite/health/covid_disease.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postInjuresList(param) {
  let BaseURL = URLconst("hubsite/health/injuries_and_chronic_issues_list.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postInjuresDetails(param) {
  let BaseURL = URLconst(
    "hubsite/health/injuries_and_chronic_issues_details.php"
  );

  return axios.post(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      rel_issue_id: param.rel_issue_id
    }
  });
}

export function postAllergiesList(param) {
  let BaseURL = URLconst("hubsite/health/allergies_list.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postAllergiesDetails(param) {
  let BaseURL = URLconst("hubsite/health/allergies_details.php");

  return axios.post(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      rel_issue_id: param.rel_issue_id
    }
  });
}

export function postAllergiesChartData(param) {
  let BaseURL = URLconst("hubsite/health/allergy_calendar.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postAllergiesToData(param) {
  let BaseURL = URLconst("hubsite/health/allergic_to.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postFitnessList(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_list.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postPreventionList(param) {
  let BaseURL = URLconst("hubsite/health/prevention.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSmokingList(param) {
  let BaseURL = URLconst("hubsite/health/smoking.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postMedicationsList(param) {
  let BaseURL = URLconst("hubsite/health/medication_calendar.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postNutritionList(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postHeightList(param) {
  let BaseURL = URLconst("hubsite/health/height.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postVaccinationsList(param) {
  let BaseURL = URLconst("hubsite/health/vaccinations_expirations.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCovid19MedValData(param) {
  let BaseURL = URLconst("hubsite/health/covid19_medval.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postCovid19PeopDetData(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/covid19_peopdet.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postSleep(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCareerList(param) {
  let BaseURL = URLconst("hubsite/work/career.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSalaryList(param) {
  let BaseURL = URLconst("hubsite/work/salary.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCertificatesList(param) {
  let BaseURL = URLconst("hubsite/work/salary.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCVsList(param) {
  let BaseURL = URLconst("hubsite/work/CVs.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postJobApplicationsList(param) {
  let BaseURL = URLconst("hubsite/work/job_applications.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSchoolsList(param) {
  let BaseURL = URLconst("hubsite/education/schools.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postDegreesList(param) {
  let BaseURL = URLconst("hubsite/education/degrees.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postGradesList(param) {
  let BaseURL = URLconst("hubsite/education/grades.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postExpertiseList(param) {
  let BaseURL = URLconst("hubsite/education/expertise.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSkillsList(param) {
  let BaseURL = URLconst("hubsite/education/skills.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postTalentsList(param) {
  let BaseURL = URLconst("hubsite/education/talents.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postToLearnList(param) {
  let BaseURL = URLconst("hubsite/education/to_learn.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postMyPhotoalbumList(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/my_photoalbum.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postPhotosByMeList(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/photos_by_me.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postFriendsList(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/friends.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postKidsList(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/kids.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSettingsList(param) {
  let BaseURL = URLconst("hubsite/various/setings.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postRatingsList(param) {
  let BaseURL = URLconst("hubsite/various/ratings.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCovidRatingsList(param) {
  let BaseURL = URLconst("hubsite/various/covidratings.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postComplaintsList(param) {
  let BaseURL = URLconst("hubsite/various/complaints.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postPoliticalList(param) {
  let BaseURL = URLconst("hubsite/various/political.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postPublicEngagementList(param) {
  let BaseURL = URLconst("hubsite/various/publick_engagement.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCovid19Ratings(param) {
  let BaseURL = URLconst("hubsite/various/covid19ratings.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postHappinessList(param) {
  let BaseURL = URLconst("hubsite/soul/happiness.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postDiaryList(param) {
  let BaseURL = URLconst("hubsite/soul/diary.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postReligionList(param) {
  let BaseURL = URLconst("hubsite/soul/religion.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCharityList(param) {
  let BaseURL = URLconst("hubsite/soul/charity.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postRomanceCalendarList(param) {
  let BaseURL = URLconst("hubsite/soul/romance_calendar.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postDayNote(param) {
  let BaseURL = URLconst("hubsite/soul/day_note.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postGoalsList(param) {
  let BaseURL = URLconst("hubsite/soul/goals.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postBucketList(param) {
  let BaseURL = URLconst("hubsite/soul/bucket_list.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSexList(param) {
  let BaseURL = URLconst("hubsite/soul/sex.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postBooksList(param) {
  let BaseURL = URLconst("hubsite/leisure/books.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCollectionsList(param) {
  let BaseURL = URLconst("hubsite/leisure/collections.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}
export function postFilmsList(param) {
  let BaseURL = URLconst("hubsite/leisure/films.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postEventsList(param) {
  let BaseURL = URLconst("hubsite/leisure/events.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSportsList(param) {
  let BaseURL = URLconst("hubsite/leisure/sports.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postRelaxation(param) {
  let BaseURL = URLconst("hubsite/leisure/relaxation.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postVacationsList(param) {
  let BaseURL = URLconst("hubsite/leisure/vacations.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postElectronicsList(param) {
  let BaseURL = URLconst("hubsite/finances/electronics.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postCarsList(param) {
  let BaseURL = URLconst("hubsite/finances/cars.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postFurnitureList(param) {
  let BaseURL = URLconst("hubsite/finances/furniture.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postHouseList(param) {
  let BaseURL = URLconst("hubsite/finances/house_or_appartment.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postLoansList(param) {
  let BaseURL = URLconst("hubsite/finances/loans.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postBudgetList(param) {
  let BaseURL = URLconst("hubsite/finances/budget.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSavingsList(param) {
  let BaseURL = URLconst("hubsite/finances/savings.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}
