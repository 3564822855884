import React, { Component } from "react";
import "../../screens/Navigator/Navigator.css";
import Divider from "@material-ui/core/Divider";

// import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class Suggestions extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      active_filter_kind: "",
      active_view_kind: "list"
    };
  }

  render() {
    const filter_btn_filter = [
      {
        className: "suggestions_filters_btn_product ",
        kind: "",
        value: "All"
      },
      {
        className: "suggestions_filters_btn_product ",
        kind: "group",
        value: <img src="/assets/images/filter_icon_2.png" alt="filter icon" />
      },
      {
        className: "suggestions_filters_btn_product ",
        kind: "dept",
        value: <img src="/assets/images/filter_icon_1.png" alt="filter icon" />
      }
    ];
    const filter_btn_view = [
      {
        className: "suggestions_filters_btn_list ",
        kind: "list",
        value: (
          <img
            src={
              this.state.active_view_kind === "list"
                ? "/assets/images/filter_icon_list.svg"
                : "/assets/images/filter_icon_list_black.svg"
            }
            alt=""
          />
        )
      },
      {
        className: "suggestions_filters_btn_list ",
        kind: "imgs",
        value: (
          <img
            src={
              this.state.active_view_kind === "list"
                ? "/assets/images/filter_icon_imgs.svg"
                : "/assets/images/filter_icon_imgs_white.svg"
            }
            alt=""
          />
        )
      }
    ];

    let search_result;
    if (this.props.search_result && this.props.carouselView) {
      search_result = this.props.search_result.map((r, i) => (
        <div
          className="suggestions_group_carousel_item"
          key={i}
          onClick={() => {
            this.props.callbackFromParent(r);
          }}
        >
          <div className="suggestions_group_carousel_img_wrapper">
            {r.prod_group}
          </div>
        </div>
      ));
    } else if (this.props.search_result) {
      if (this.state.active_view_kind === "list") {
        search_result = this.props.search_result.map((r, i) => {
          const description = r.description;
          const searchText = this.props.searchText;
          const str = description.replace(searchText, `<b>${searchText}</b>`);
          const title = str.replace(
            searchText.toUpperCase(),
            `<b>${searchText.toUpperCase()}</b>`
          );

          if (
            r.kind === this.state.active_filter_kind ||
            this.state.active_filter_kind === ""
          ) {
            return (
              <React.Fragment key={i}>
                <div
                  onClick={() => {
                    this.props.callbackFromParent(r);
                  }}
                  className="suggest_dropd_item"
                >
                  {r.kind !== "group" ? (
                    <img
                      className="list_item_img"
                      src="/assets/images/filter_icon_1.png"
                      alt=""
                    />
                  ) : (
                    <img
                      className="list_item_img"
                      src="/assets/images/filter_icon_2.png"
                      alt=""
                    />
                  )}
                  <nobr
                    style={{
                      textTransform: r.kind !== "group" ? "uppercase" : ""
                    }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></nobr>
                </div>
                <Divider className="suggestions_divider" />
              </React.Fragment>
            );
          } else {
            return null;
          }
        });
      } else if (this.state.active_view_kind === "imgs") {
        // eslint-disable-next-line
        search_result = this.props.search_result.map((r, i) => {
          const description = r.description;
          const searchText = this.props.searchText;
          const str = description.replace(searchText, `<b>${searchText}</b>`);
          const title = str.replace(
            searchText.toUpperCase(),
            `<b>${searchText.toUpperCase()}</b>`
          );

          if (
            r.kind === this.state.active_filter_kind ||
            this.state.active_filter_kind === ""
          ) {
            if (r.kind === "group") {
              return (
                <div
                  className="suggest_group_item_block"
                  key={i}
                  onClick={() => {
                    this.props.callbackFromParent(r);
                  }}
                >
                  <img
                    className="suggest_group_item_img"
                    src={r.img_src || "/assets/images/missing-image.png"}
                    alt=""
                  />
                  <span dangerouslySetInnerHTML={{ __html: title }}></span>
                </div>
              );
            } else if (r.kind === "dept") {
              return (
                <div
                  key={i}
                  onClick={() => {
                    this.props.callbackFromParent(r);
                  }}
                  className="suggest_dept_item_block"
                  style={{
                    backgroundImage: `linear-gradient(to left, transparent, #fff 70%, #fff), 
                url("${r.img_src}")`,
                    backgroundSize: "cover"
                  }}
                >
                  <img
                    className="list_item_img"
                    src="/assets/images/filter_icon_1.png"
                    alt=""
                  />
                  {r.description}
                </div>
              );
            }
          }
        });
      }
    }

    return (
      <div className="suggestions_block">
        {this.props.selected_filter === "filter_not_available" ? (
          <span>Change filter please</span>
        ) : null}
        <div className="suggestions_filters">
          <div className="suggestion_filters_type">
            {filter_btn_filter.map((item, id) => {
              return (
                <button
                  className={
                    item.className +
                    (this.state.active_filter_kind === item.kind
                      ? " btn_active"
                      : " btn_passive")
                  }
                  key={id}
                  onClick={() =>
                    this.setState({ active_filter_kind: item.kind })
                  }
                >
                  {item.value}
                </button>
              );
            })}
          </div>
          <div className="suggestion_filters_view">
            {filter_btn_view.map((item, id) => {
              return (
                <button
                  className={
                    item.className +
                    (this.state.active_view_kind === item.kind
                      ? " btn_active"
                      : " btn_passive")
                  }
                  key={id}
                  onClick={() => this.setState({ active_view_kind: item.kind })}
                >
                  {item.value}
                </button>
              );
            })}
          </div>
        </div>
        <Divider className="suggestions_divider" />
        {this.props.book_info &&
        this.props.carouselView &&
        !this.props.showBookDetails ? (
          <div className="suggestions_carousel_container">
            <div className="suggestions_carousel_wrapper">
              {this.props.search_result ? search_result : null}
            </div>
          </div>
        ) : (
          <>
            <div ref={this.myRef} className="suggestions_item_list">
              {this.props.showBookDetails ? null : search_result}
              {this.props.search_end_reached ? null : (
                <div
                  className="suggestion_pagination"
                  onClick={() => {
                    this.props.load_more_callback();
                    setTimeout(() => {
                      this.myRef.current.scrollTo({
                        top: this.myRef.current.scrollTop + 200,
                        behavior: "smooth"
                      });
                    }, 500);
                  }}
                >
                  <img
                    style={{ transform: "rotate(90deg)" }}
                    src="/assets/images/right_arrow_icon.png"
                    alt="Load more"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Suggestions;
