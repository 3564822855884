import React, { useState, useEffect } from "react";
import {
  getCovid19Warning,
  postCovid19Warning,
  putCovid19Warning
} from "../../services/SettingsData";
import { useIntl } from "react-intl";

export default function CovidImportantInfo({ query_params }) {
  const intl = useIntl();
  const [showDetails, setShowDetails] = useState(false);
  const [close, setClose] = useState(true);
  const [settingsID, setSettingsID] = useState(null);

  useEffect(() => {
    let payload = { ...query_params, section: "setting" };
    getCovid19Warning(payload).then(result => {
      if (result.data.data_array) {
        setSettingsID(result.data.data_array[0].id);
        if (Number(result.data.data_array[0].value) !== 0) {
          setClose(false);
        }
      } else {
        setClose(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  const postDontShow = param => {
    let payload = query_params;
    payload.data = {};
    payload.data.value = param ? 0 : 1;
    payload.data.entry_type = "setting";
    payload.data.product = "personal-hub";
    payload.data.product_area = "covid-19_data_warning";
    payload.data.description = intl.formatMessage({
      id: "covid_info_pt8"
    });
    payload.data.personal_note = intl.formatMessage({
      id: "covid_info_pt9"
    });

    if (settingsID !== null) {
      payload.data.id = settingsID;
      putCovid19Warning(payload).then(result => {
        if (result.data.error) {
          console.log("put error");
        }
      });
    } else {
      postCovid19Warning(payload).then(result => {
        if (result.data.error) {
          console.log("post error");
        }
      });
    }
  };

  if (close) {
    return null;
  } else {
    return (
      <div className="cov19_info_container">
        <img
          className="cov19_info_close"
          onClick={() => {
            setClose(true);
          }}
          src="/assets/images/icon_close.png"
          alt=""
        />
        <div className="cov19_info_text_block">
          <span style={{ fontWeight: "600" }}>
            {intl.formatMessage({
              id: "covid_info_pt1"
            })}
            :
          </span>{" "}
          {intl.formatMessage({
            id: "covid_info_pt2"
          })}{" "}
          <span
            onClick={() => {
              setShowDetails(!showDetails);
            }}
            style={{ color: "#3FA4FF", fontWeight: "600", cursor: "pointer" }}
          >
            {intl.formatMessage({
              id: "covid_info_pt3"
            })}
          </span>
          .
        </div>
        {showDetails ? (
          <div>
            <div className="cov19_info_text_block">
              {intl.formatMessage({
                id: "covid_info_pt4"
              })}
            </div>
            <div className="cov19_info_text_block">
              {intl.formatMessage({
                id: "covid_info_pt5"
              })}
            </div>
            <div className="cov19_info_text_block">
              {intl.formatMessage({
                id: "covid_info_pt6"
              })}
            </div>
          </div>
        ) : null}
        <div className="cov19_info_bottom">
          <div className="cov19_info_input">
            <span>
              <input
                type="checkbox"
                onChange={e => {
                  postDontShow(e.target.checked);
                }}
              />
            </span>
            <span className="cov19_info_input_label">
              {intl.formatMessage({
                id: "covid_info_pt7"
              })}
            </span>
          </div>
          <div
            className="cov19_info_bottom_btn"
            onClick={() => {
              setClose(true);
            }}
          >
            <div>
              {intl.formatMessage({
                id: "covid_hide"
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
