import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Redirect } from "react-router-dom";
import { Divider } from "@material-ui/core";
import "./BurgerMenu.css";
import MenuIcon from "@material-ui/icons/MenuRounded";
// import InfoIcon from "@material-ui/icons/Info";
import { getProfileBasics } from "../../../services/getProfileBasics";
import Checkbox from "@material-ui/core/Checkbox";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles({
  drawer: {
    overflowY: "scroll",
    maxHeight: "100vh",
  },
  list: {
    width: 310,
  },
});

export default function BurgerMenu({
  loggedInAs,
  showCalendar,
  setShowCalendar,
  setOpenAvatarModal,
  setLogoutModal,
  compactMode,
  setCompactMode,
  updateProfileData,
  setUpdateProfileData,
  state,
  setState,
  dbUpdateNeeded,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState(
    localStorage.getItem("phsli") !== null ? true : false
  );
  const [redirectLink, setRedirectLink] = useState("");
  const [userData, setUserData] = useState({
    first_name: null,
    last_name: null,
    profile_picture: null,
    nickname: null,
    avatar: null,
  });
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    if (updateProfileData) {
      get_profile_data();
    }
    // eslint-disable-next-line
  }, [updateProfileData]);

  useEffect(() => {
    get_profile_data();
    // eslint-disable-next-line
  }, []);

  const toggleDrawer = (open) => (event) => {
    setDrawer(open);
  };

  const get_profile_data = () => {
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token") !== null) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }

    getProfileBasics(send_credential_to_registrar).then((result) => {
      if (result.data.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        setUserData(result.data.userData);
      }
    });
    setUpdateProfileData(false);
  };

  useEffect(() => {
    if (userData !== null) {
      get_avatar();
    } // eslint-disable-next-line
  }, [userData]);

  const get_avatar = () => {
    if (userData.profile_picture !== null) {
      setAvatar(userData.profile_picture);
    } else {
      if (userData.avatar !== null) {
        setAvatar(`/assets/images/${userData.avatar}`);
      } else {
        setAvatar(`/assets/images/person_icon.png`);
      }
    }
  };

  const handleStayLoggedInCheckbox = (name) => (event) => {
    setStayLoggedIn(event.target.checked);
    if (event.target.checked === true) {
      if (sessionStorage.getItem("hub_token") !== null) {
        localStorage.setItem("phsli", sessionStorage.getItem("hub_token"));
      }
    } else {
      if (localStorage.getItem("phsli") !== null) {
        localStorage.setItem("phsli", "");
        localStorage.removeItem("phsli");
      }
    }
  };

  const handleStayLoggedIn = (param) => {
    setStayLoggedIn(param);
    if (param === true) {
      if (sessionStorage.getItem("hub_token") !== null) {
        localStorage.setItem("phsli", sessionStorage.getItem("hub_token"));
      }
    } else {
      if (localStorage.getItem("phsli") !== null) {
        localStorage.setItem("phsli", "");
        localStorage.removeItem("phsli");
      }
    }
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className="avatar_container">
        <img
          src={avatar.length === 0 ? "/assets/images/person_icon.png" : avatar}
          alt=""
          onClick={() => setOpenAvatarModal(true)}
          className="avatar_img"
        />
        <div className="menu_title">
          <div style={{ fontWeight: "bold" }}>
            {userData.first_name !== null || userData.last_name !== null
              ? (userData.first_name !== null
                  ? userData.first_name + " "
                  : "") +
                (userData.last_name !== null ? userData.last_name : "")
              : userData.nickname !== null
              ? userData.nickname
              : sessionStorage.getItem("hub_token") !== null
              ? JSON.parse(sessionStorage.getItem("hub_token")).userData
                  .my_hub_id
              : null}
          </div>
          <div
            style={{
              fontSize: "14px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              HUB #{" "}
              {sessionStorage.getItem("hub_token") !== null
                ? JSON.parse(sessionStorage.getItem("hub_token"))
                    .userData.my_hub_id.slice(-5)
                    .replace("_", "")
                : null}
            </div>
            <div onClick={() => setOpenAvatarModal(true)}>
              <img alt="settings_icon" src="/assets/images/Settings.svg" />
            </div>
          </div>
        </div>
      </div>
      {dbUpdateNeeded ? (
        <>
          <Divider />
          <ListItem
            className="menu_list_item"
            button
            onClick={() => setRedirectLink("/upgrade")}
          >
            <ListItemIcon>
              <div
                style={{
                  borderRadius: "50%",
                  boxShadow: "inset 0px 0px 0px 2px #868686",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5px 0",
                }}
              >
                <ArrowUpwardIcon />
              </div>
            </ListItemIcon>
            <span>
              <span>
                {intl.formatMessage({
                  id: "burger_menu_upgrade",
                })}
              </span>
              <span
                style={{
                  color: "red",
                  paddingLeft: "10px",
                  fontSize: "14px",
                }}
              >
                {intl.formatMessage({
                  id: "burger_menu_upgrade_new_version",
                })}
              </span>
            </span>
          </ListItem>
        </>
      ) : null}
      <Divider />
      <div
        style={{
          paddingLeft: "15px",
          paddingTop: "5px",
          display: "flex",
          fontSize: "1rem",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
        }}
      >
        <Checkbox
          checked={stayLoggedIn}
          onChange={handleStayLoggedInCheckbox("checkbox")}
          value="checkbox"
          color="primary"
        />
        <div
          onClick={() => {
            handleStayLoggedIn(!stayLoggedIn);
          }}
          style={{ paddingLeft: "33px", paddingTop: "1px" }}
        >
          <FormattedMessage id="burger_menu_stay_logged" />
        </div>
      </div>

      <List>
        <Divider />
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setOpenAvatarModal(true)}
        >
          <ListItemIcon>
            <img
              style={{ height: "15px", width: "20px" }}
              className="hubsite_icon"
              src="/assets/images/quad_flag.jpg"
              alt="logout_icon"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              intl.formatMessage({
                id: "edit_profile_title",
              }) +
              " [" +
              (localStorage.getItem("personal_hub_language") !== null
                ? localStorage
                    .getItem("personal_hub_language")
                    .split(/[-_]/)[0]
                    .toUpperCase() + "]"
                : null)
            }
          />
        </ListItem>
        <Divider />
      </List>
      <div className="big_row_menu">
        <div>
          <img
            className="row_menu_icon"
            src="/assets/images/bars-code.svg"
            alt="barcode_icon"
            onClick={() => setRedirectLink("/scanandbarcode")}
          />
        </div>
        <div style={{ backgroundColor: "black", borderRadius: "4px" }}>
          <img
            className="row_menu_icon"
            src="/assets/images/james_bon_scan_icon.png"
            alt="barcode_icon"
            onClick={() => setRedirectLink("/receiptDisplay")}
          />
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px 0",
        }}
      >
        <div
          className="row_menu_item home"
          onClick={() => setRedirectLink("/")}
        >
          <img src="/assets/images/home_page.svg" alt="home page icon" />
        </div>
        <div
          className="row_menu_item"
          onClick={() => setRedirectLink("/about")}
        >
          <img src="/assets/images/About.svg" alt="About icon" />
          <FormattedMessage id="burger_menu_info" />
        </div>
        <div
          className="row_menu_item"
          onClick={() => setRedirectLink("/impressum")}
        >
          <img src="/assets/images/Imprint.svg" alt="imprint_icon" />
          <FormattedMessage id="burger_menu_imprint" />
        </div>
      </div>
      <Divider />
      <ListItem
        className="menu_list_item"
        button
        onClick={() => setRedirectLink("/covid19/1")}
      >
        <ListItemIcon>
          <img
            className="menu_icon"
            style={{ transform: "rotate(180deg)" }}
            src="/assets/images/virus.svg"
            alt="Covid 19"
          />
        </ListItemIcon>
        <FormattedMessage id="burger_menu_covid_19_module" />
      </ListItem>
      <ListItem
        className="menu_list_item"
        button
        onClick={() => setRedirectLink("/hubsearchengine")}
      >
        <ListItemIcon>
          <img
            className="menu_icon"
            src="/assets/images/hub_search_engine.svg"
            alt="Hub search engine"
          />
        </ListItemIcon>
        <FormattedMessage id="burger_menu_HUBsite_search_engine" />
      </ListItem>
      <Divider />
      <List>
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/visit")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Visit.svg"
              alt="Visit icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_visit" />
        </ListItem>
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/activeMinute")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Active min.svg"
              alt="Active minute"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_active_minute" />
        </ListItem>
        <ListItem
          className="menu_list_item"
          selected={showCalendar}
          button
          onClick={() => {
            setCompactMode(!compactMode);
            setState({ ...state, activeSubCategory: null });
            setShowCalendar(!showCalendar);
          }}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Calendar.svg"
              alt="Calendar icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_calendar" />
        </ListItem>
      </List>
      {/* links to old apps */}
      <Divider />
      {loggedInAs !== "admin" ? (
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/createAdmin")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              style={{ transform: "rotate(180deg)" }}
              src="/assets/images/Logout.svg"
              alt="login icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_login_admin" />
        </ListItem>
      ) : (
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/changeAdminPassword")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              style={{ transform: "rotate(180deg)" }}
              src="/assets/images/Logout.svg"
              alt="logout_icon"
            />
          </ListItemIcon>
          <ListItemText primary={"Change admin password"} />
        </ListItem>
      )}
      <Divider />
      <List>
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/receipts")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Receipt.svg"
              alt="receipts_icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_receipts" />
        </ListItem>
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/bookcollector")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Book app.svg"
              alt="bookcollector_icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_book_app" />
        </ListItem>
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setRedirectLink("/virtualassistantmaps")}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/map.svg"
              alt="virtual_assistant_icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_interactive_maps" />
        </ListItem>
        <Divider />
        <ListItem
          className="menu_list_item"
          button
          onClick={() => setLogoutModal(true)}
        >
          <ListItemIcon>
            <img
              className="menu_icon"
              src="/assets/images/Logout.svg"
              alt="logout_icon"
            />
          </ListItemIcon>
          <FormattedMessage id="burger_menu_log_out" />
        </ListItem>
      </List>
    </div>
  );

  const redirect = () => {
    if (redirectLink !== "") {
      return <Redirect push to={`${redirectLink}`} />;
    }
  };

  return loggedInAs !== "visitor" ? (
    <div>
      {redirect()}
      <button onClick={toggleDrawer(true)} className="burger_menu_btn">
        <MenuIcon fontSize="large" />
      </button>
      <SwipeableDrawer
        open={drawer}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        {sideList()}
      </SwipeableDrawer>
    </div>
  ) : null;
}
