import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chart from "react-google-charts";

import { postCovid19MedValData } from "../../services/PostHUBsiteData";
import { putCovid19MedValData } from "../../services/PutHUBsiteData";
import { getCovid19MedValDataDaily } from "../../services/GetHUBsiteData";
import { useIntl } from "react-intl";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

export default function Covid19IllnessDiary({
  query_params,
  medValData,
  getCovid19MedVal
}) {
  const intl = useIntl();
  const [answeredMap, setAnsweredMap] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modaleExchangeData, setModaleExchangeData] = useState(null);
  const [wellBeingData, setWellBeingData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableDays, setAvailableDays] = useState({
    disease: [],
    reconvalescence: []
  });

  const [selectedDay, setSelectedDay] = useState({
    day: null,
    type: null,
    index: null
  });

  const wellBeingOptions = [
    {
      value: 1,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_1"
      }),
      color: "#d20000"
    },
    {
      value: 2,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_2"
      }),
      color: "#d50000"
    },
    {
      value: 3,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_3"
      }),
      color: "#e23100"
    },
    {
      value: 4,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_4"
      }),
      color: "#ea5200"
    },
    {
      value: 5,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_5"
      }),
      color: "#f3b10f"
    },
    {
      value: 6,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_6"
      }),
      color: "#f2db00"
    },
    {
      value: 7,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_7"
      }),
      color: "#cbda26"
    },
    {
      value: 8,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_8"
      }),
      color: "#8ebc2b"
    },
    {
      value: 9,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_9"
      }),
      color: "#489951"
    },
    {
      value: 10,
      label: intl.formatMessage({
        id: "covid_ild_wb_state_10"
      }),
      color: "#258439"
    }
  ];

  const temperatureOptions = [
    { value: 42.0 },
    { value: 41.9 },
    { value: 41.8 },
    { value: 41.7 },
    { value: 41.6 },
    { value: 41.5 },
    { value: 41.4 },
    { value: 41.3 },
    { value: 41.2 },
    { value: 41.1 },
    { value: 41.0 },
    { value: 40.9 },
    { value: 40.8 },
    { value: 40.7 },
    { value: 40.6 },
    { value: 40.5 },
    { value: 40.4 },
    { value: 40.3 },
    { value: 40.2 },
    { value: 40.1 },
    { value: 40.0 },
    { value: 39.9 },
    { value: 39.8 },
    { value: 39.7 },
    { value: 39.6 },
    { value: 39.5 },
    { value: 39.4 },
    { value: 39.3 },
    { value: 39.2 },
    { value: 39.1 },
    { value: 39.0 },
    { value: 38.9 },
    { value: 38.8 },
    { value: 38.7 },
    { value: 38.6 },
    { value: 38.5 },
    { value: 38.4 },
    { value: 38.3 },
    { value: 38.2 },
    { value: 38.1 },
    { value: 38.0 },
    { value: 37.9 },
    { value: 37.8 },
    { value: 37.7 },
    { value: 37.6 },
    { value: 37.5 },
    { value: 37.4 },
    { value: 37.3 },
    { value: 37.2 },
    { value: 37.1 },
    { value: 37.0 },
    { value: 36.9 },
    { value: 36.8 },
    { value: 36.7 },
    { value: 36.6 },
    { value: 36.5 }
  ];

  const yesNo = [
    { value: 0, label: "-" },
    {
      value: 1,
      label: intl.formatMessage({
        id: "covid_no"
      })
    },
    {
      value: 2,
      label: intl.formatMessage({
        id: "covid_yes"
      })
    }
  ];

  const questions = {
    Q218: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q218"
      }),
      options: yesNo
    },
    Q220: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q220"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q221: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q221"
      }),
      options: [{ value: "input-field", label: "" }]
    }
  };

  const getCovid19MedValDailyData = () => {
    let params = { ...query_params, daily_what: "well-being" };
    getCovid19MedValDataDaily(params).then(result => {
      if (result.data.error) {
        console.log(result.data.error);
      } else {
        setWellBeingData(result.data.data_array);
      }
    });

    params = { ...query_params, daily_what: "temperature" };
    getCovid19MedValDataDaily(params).then(result => {
      if (result.data.error) {
        console.log(result.data.error);
      } else {
        setTemperatureData(result.data.data_array);
      }
    });
  };

  useEffect(() => {
    getCovid19MedValDailyData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (medValData.length > 0) {
      let temp_obj = {};

      medValData.forEach((item, index) => {
        temp_obj = { ...temp_obj, [item.universal_index]: index };
      });
      setAnsweredMap(temp_obj);
      //   console.log(temp_obj);
    }
  }, [medValData]);

  useEffect(() => {
    handleAvailableDayBlocks();
    // eslint-disable-next-line
  }, [answeredMap]);

  const handleSelectMedVal = (qID, val, label, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};
    payload.data.category = "covid-19";
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.value = val;
    payload.data.reference_value = val !== null ? val + "-" + label : null;
    payload.data.universal_index = qID;

    if (id !== null) {
      payload.data.id = id;
      putCovid19MedValData(payload).then(result => {
        // console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        // console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    }
  };

  const renderSelectMedVal = (question, width) => {
    let width_val = 200;
    if (width !== undefined) {
      width_val = width;
    }
    let oldAnswer = null;
    let id = null;
    if (answeredMap[question] !== undefined) {
      let indexInOptions = questions[question].options.findIndex(
        element =>
          Number(element.value) ===
          Number(medValData[answeredMap[question]].value)
      );
      // console.log(question, indexInOptions);
      id = medValData[answeredMap[question]].id;
      oldAnswer = questions[question].options[indexInOptions];
    }

    let disabled = true;
    let temp_arr = Object.keys(answeredMap);
    if (questions[question].depend.length > 0) {
      let verif_arr = [];
      questions[question].depend.forEach(element => {
        if (temp_arr.includes(element)) {
          let temp_val = medValData[answeredMap[element]].value;
          if (Number(temp_val) === 2) {
            verif_arr.push(true);
          } else {
            verif_arr.push(false);
          }
        } else {
          verif_arr.push(false);
        }
      });
      if (!verif_arr.includes(false)) {
        disabled = false;
      }
    } else {
      disabled = false;
    }

    return (
      <div className="covid19_noshrink" style={{ width: width_val }}>
        <Select
          isDisabled={disabled}
          placeholder={""}
          value={oldAnswer}
          onChange={e => {
            e !== null
              ? handleSelectMedVal(question, e.value, e.label, id)
              : handleSelectMedVal(question, null, null, null);
          }}
          options={questions[question].options}
        />
      </div>
    );
  };

  const renderInputMedVal = (question, val_type, unit) => {
    let oldAnswer = "";
    let id = null;
    let date_width = val_type === "date" ? "covid19_input_date_width" : "";
    let disabled = true;
    let disabledOveride = false;
    if (answeredMap[question] !== undefined) {
      if (val_type === "date") {
        if (unit === "from") {
          if (
            moment(
              medValData[answeredMap[question]].time_start,
              moment.ISO_8601,
              true
            ).isValid()
          ) {
            oldAnswer = moment(
              medValData[answeredMap[question]].time_start
            ).format("YYYY-MM-DD");
          }
        } else {
          if (
            moment(
              medValData[answeredMap[question]].time_end,
              moment.ISO_8601,
              true
            ).isValid()
          ) {
            oldAnswer = moment(
              medValData[answeredMap[question]].time_end
            ).format("YYYY-MM-DD");
          } else {
            if (
              Number(medValData[answeredMap[question]].reference_value) === 1
            ) {
              disabledOveride = true;
            }
          }
        }
      } else if (val_type === "checkbox") {
        let temp_val = medValData[answeredMap[question]].reference_value;
        oldAnswer = Number(temp_val) === 1 ? true : false;
        if (
          moment(
            medValData[answeredMap[question]].time_end,
            moment.ISO_8601,
            true
          ).isValid()
        ) {
          disabledOveride = true;
        }
      } else {
        oldAnswer = medValData[answeredMap[question]].value;
      }

      // console.log(oldAnswer, question);
      id = medValData[answeredMap[question]].id;
    }
    let temp_arr = Object.keys(answeredMap);
    if (questions[question].depend.length > 0) {
      let verif_arr = [];
      questions[question].depend.forEach(element => {
        if (temp_arr.includes(element)) {
          let temp_val = medValData[answeredMap[element]].value;
          if (Number(temp_val) === 2) {
            verif_arr.push(true);
          } else {
            verif_arr.push(false);
          }
        } else {
          verif_arr.push(false);
        }
      });
      if (!verif_arr.includes(false)) {
        disabled = false;
      }
    } else {
      disabled = false;
    }

    // if (question === "Q221" && unit === "from") {
    //   disabledOveride = true;
    // }

    if (disabledOveride) {
      disabled = true;
    }

    if (val_type === "checkbox") {
      return (
        <input
          disabled={disabled}
          className={""}
          type={val_type}
          name={question}
          onChange={e => {
            handleInputMedVal(question, e.target.checked, val_type, unit, id);
          }}
          checked={oldAnswer}
        />
      );
    } else {
      return (
        <input
          disabled={disabled}
          className={"cov19_inf_risk_input " + date_width}
          type={val_type}
          name={question}
          onBlur={e => {
            handleInputMedVal(question, e.target.value, val_type, unit, id);
          }}
          defaultValue={oldAnswer}
        />
      );
    }
  };

  const handleInputMedVal = (qID, val, val_type, unit, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};

    payload.data.category = "covid-19";
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.universal_index = qID;
    if (val_type === "number") {
      payload.data.value = val;
    } else if (val_type === "date") {
      if (unit !== null) {
        if (unit === "from") {
          payload.data.time_start = moment(val).format("YYYY-MM-DDTHH:mm:ss");
        } else {
          payload.data.time_end = moment(val).format("YYYY-MM-DDTHH:mm:ss");
        }
      } else {
        console.log("input error unit not provided");
        return null;
      }
    } else if (val_type === "checkbox") {
      payload.data.reference_value = val;
    }

    if (unit !== null) {
      payload.data.unit = unit;
    }
    if (id !== null) {
      payload.data.id = id;
    }
    // console.log(payload);
    if (payload.data.id !== undefined) {
      putCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    }
  };

  const handleAvailableDayBlocks = () => {
    let temp_arr = ["Q220", "Q221"];
    let temp_obj = { disease: [], reconvalescence: [] };
    temp_arr.forEach(question => {
      let from = null;
      let to = null;
      let renderDays = false;
      if (answeredMap[question] !== undefined) {
        if (
          moment(
            medValData[answeredMap[question]].time_start,
            moment.ISO_8601,
            true
          ).isValid()
        ) {
          from = moment(medValData[answeredMap[question]].time_start).format(
            "YYYY-MM-DD"
          );
        }
        if (
          moment(
            medValData[answeredMap[question]].time_end,
            moment.ISO_8601,
            true
          ).isValid()
        ) {
          to = moment(medValData[answeredMap[question]].time_end).format(
            "YYYY-MM-DD"
          );
        } else {
          if (medValData[answeredMap[question]].reference_value !== null) {
            if (
              Number(medValData[answeredMap[question]].reference_value) === 1
            ) {
              renderDays = true;
            }
          }
        }
      }

      if (from !== null && to !== null) {
        let check = moment(from).isBefore(to);
        let temp_arr = [];
        if (check) {
          while (!moment(from).isAfter(to)) {
            temp_arr.push(from);
            from = moment(from)
              .add(1, "d")
              .format("YYYY-MM-DD");
          }
        } else {
          console.log("start date is not before end date");
          return null;
        }

        if (question === "Q220") {
          temp_obj.disease = temp_arr;
        } else if (question === "Q221") {
          temp_obj.reconvalescence = temp_arr;
        }
      } else if (from !== null && to === null && renderDays) {
        to = moment().format("YYYY-MM-DD");
        let check = moment(from).isBefore(to);
        let temp_arr = [];
        if (check) {
          while (!moment(from).isAfter(to)) {
            temp_arr.push(from);
            from = moment(from)
              .add(1, "d")
              .format("YYYY-MM-DD");
          }
        } else {
          console.log("start date is not before end date");
          return null;
        }

        if (question === "Q220") {
          temp_obj.disease = temp_arr;
        } else if (question === "Q221") {
          temp_obj.reconvalescence = temp_arr;
        }
      } else {
        return null;
      }
    });
    setAvailableDays(temp_obj);
    if (temp_obj.reconvalescence.length > 0) {
      let lastIndex = temp_obj.reconvalescence.length - 1;
      let temp_item = temp_obj.reconvalescence[lastIndex];
      setSelectedDay({
        day: temp_item,
        type: "reconvalescence",
        index: lastIndex
      });
    } else if (temp_obj.disease.length > 0) {
      let lastIndex = temp_obj.disease.length - 1;
      let temp_item = temp_obj.disease[lastIndex];
      setSelectedDay({
        day: temp_item,
        type: "disease",
        index: lastIndex
      });
    }
  };

  const renderDayBlocks = param => {
    return availableDays[param].map((item, i) => {
      let num = i + 1;
      if (!item) {
        return null;
      } else {
        let temp_val = "covid_ild_day_block";
        if (selectedDay.type === param && selectedDay.index === i) {
          temp_val = "covid_ild_day_block cov_day_selected";
        }
        return (
          <div
            key={i}
            className={temp_val}
            onClick={() => {
              setSelectedDay({ day: item, type: param, index: i });
              // console.log({ day: item, type: question });
            }}
          >
            <div
              className={param === "reconvalescence" ? "covid_green_text" : ""}
            >
              Day {num}
            </div>
          </div>
        );
      }
    });
  };

  const handleSendDailyValue = (type, value, date, id) => {
    let payload = query_params;
    payload.data = {};
    payload.data.category = "covid-19";
    payload.data.date_on = moment(date).format("YYYY-MM-DD");
    payload.data.what = type.substring(0, 49);
    payload.data.value = value;
    if (id !== null) {
      payload.data.id = id;
    }
    // console.log(payload);
    if (payload.data.id !== undefined) {
      putCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedValDailyData();
        setOpenModal(false);
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedValDailyData();
        setOpenModal(false);
      });
    }
  };

  const handleSelectedDayOldValue = (type, day) => {
    if (type === "well-being") {
      let foundIndex = wellBeingData.findIndex(
        element =>
          moment(element.date_on).format("YYYY-MM-DD") ===
          moment(day).format("YYYY-MM-DD")
      );
      if (foundIndex !== -1) {
        return wellBeingData[foundIndex];
      } else {
        return null;
      }
    } else if (type === "temperature") {
      let foundIndex = temperatureData.findIndex(
        element =>
          moment(element.date_on).format("YYYY-MM-DD") ===
          moment(day).format("YYYY-MM-DD")
      );
      if (foundIndex !== -1) {
        return temperatureData[foundIndex];
      } else {
        return null;
      }
    }
  };

  const renderSelectedDay = () => {
    let well_being_var = handleSelectedDayOldValue(
      "well-being",
      selectedDay.day
    );
    let well_being_id = well_being_var !== null ? well_being_var.id : null;
    let div_color = "silver";
    if (well_being_var !== null) {
      let foundIndex = wellBeingOptions.findIndex(
        element => Number(element.value) === Number(well_being_var.value)
      );
      if (foundIndex !== -1) {
        div_color = wellBeingOptions[foundIndex].color;
      }
    }

    let temperature_var = handleSelectedDayOldValue(
      "temperature",
      selectedDay.day
    );
    let temperature_id = temperature_var !== null ? temperature_var.id : null;

    if (selectedDay.day !== null) {
      return (
        <div className="covid_ild_sel_day_text_big">
          <div>{moment(selectedDay.day).format("ddd, L")}</div>
          <div className="covid_ild_sel_day_wrap">
            <div
              style={{ backgroundColor: div_color }}
              onClick={() => {
                setModaleExchangeData({
                  type: "well-being",
                  id: well_being_id
                });
                setOpenModal(true);
              }}
              className="covid_ild_sel_day_item"
            >
              <div className="covid_ild_sel_day_item_title">
                {intl.formatMessage({
                  id: "covid_ild_well_being"
                })}
              </div>
              <div className="covid_idl_selected_date_value_wrapper">
                <div style={{ marginRight: "5px" }}>
                  {well_being_var !== null ? Number(well_being_var.value) : ""}
                </div>
              </div>
              <div className="covid19_daily_edit_icon">
                <EditIcon />
              </div>
            </div>
            <div
              onClick={() => {
                setModaleExchangeData({
                  type: "temperature",
                  id: temperature_id
                });
                setOpenModal(true);
              }}
              className="covid_ild_sel_day_item covid_idl_temp_daily_box"
            >
              <div className="covid_ild_sel_day_item_title">
                {intl.formatMessage({
                  id: "covid_ild_temperature"
                })}
              </div>

              <div className="covid_idl_selected_date_value_wrapper">
                <div style={{ marginRight: "5px" }}>
                  {temperature_var !== null
                    ? Number(temperature_var.value)
                    : ""}
                  {"°C"}
                </div>
              </div>
              <div className="covid19_daily_edit_icon">
                <EditIcon />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleGraphRender = type => {
    let hTicks = [...availableDays.disease, ...availableDays.reconvalescence];
    let temp_arr = [];
    hTicks.forEach((item, i) => {
      temp_arr.push({ v: moment(item).toDate(), f: "Day " + (i + 1) });
    });

    let temp_arr2 = [["Day", "Value"]];
    let scale = {};
    if (type === "well-being") {
      wellBeingData.forEach((item, i) => {
        temp_arr2.push([moment(item.date_on).toDate(), Number(item.value)]);
        scale = {
          minValue: 0,
          maxValue: 10,
          viewWindow: { max: 10 }
        };
      });
    } else {
      temperatureData.forEach((item, i) => {
        temp_arr2.push([moment(item.date_on).toDate(), Number(item.value)]);
        scale = {
          minValue: 36,
          maxValue: 42
        };
      });
    }

    let data = temp_arr2;

    let options = {
      title: intl.formatMessage({
        id: "covid_ild_graph_" + type
      }),
      legend: {
        position: "none"
      },
      vAxis: scale,
      hAxis: {
        ticks: temp_arr,
        slantedText: true
      }
    };

    return (
      <Chart
        chartType="LineChart"
        width="100%"
        height="300px"
        data={data}
        options={options}
      />
    );
  };

  const renderModal = () => {
    let temp_arr = [];
    if (modaleExchangeData.type === "well-being") {
      temp_arr = wellBeingOptions.reverse();
    } else {
      temp_arr = temperatureOptions.reverse();
    }
    return (
      <Dialog aria-labelledby="moda" aria-describedby="modal" open={openModal}>
        <DialogContent>
          <div>
            <div className="covid_modal_header_wrapper">
              <div>
                {intl.formatMessage({
                  id: "covid_ild_wb_state_select"
                })}
                :
              </div>
              <div onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </div>
            </div>
            {temp_arr.map((item, i) => {
              if (!item) {
                return null;
              } else {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      handleSendDailyValue(
                        modaleExchangeData.type,
                        item.value,
                        selectedDay.day,
                        modaleExchangeData.id
                      );
                    }}
                    className="cov19_wb_option"
                    style={
                      modaleExchangeData.type === "well-being"
                        ? { backgroundColor: item.color }
                        : Number.isInteger(item.value)
                        ? {
                            border: "1px solid #676767",
                            borderRadius: "7px",
                            backgroundColor: "#dedede"
                          }
                        : { border: "1px solid #676767", borderRadius: "7px" }
                    }
                  >
                    {modaleExchangeData.type === "well-being" ? (
                      <div>{`${item.value} - ${item.label}`}</div>
                    ) : (
                      <div>{`${item.value.toFixed(1)}°C`}</div>
                    )}
                  </div>
                );
              }
            })}
          </div>

          {/* <div>
            <button onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </button>
          </div> */}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <div className="cov19_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid_ild_main_title"
          })}
        </span>
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q218</span>
            {questions.Q218.label}
          </div>
          {renderSelectMedVal("Q218")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q220</span>
            {questions.Q220.label}
          </div>
          <div className="cov19_illnes_dates_cont">
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_from"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "date", "from")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_to"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "date", "to")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_ongoing"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "checkbox", null)}
            </div>
          </div>
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text covid_green_text">
            <span className="covid_question_small_label">Q221</span>
            {questions.Q221.label}
          </div>
          <div className="cov19_illnes_dates_cont">
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_from"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "date", "from")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_to"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "date", "to")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_ongoing"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "checkbox", null)}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", margin: "0 16px", flexWrap: "wrap" }}>
        {renderDayBlocks("disease")}
        {renderDayBlocks("reconvalescence")}
      </div>
      {renderSelectedDay()}
      {wellBeingData.length > 1 ? (
        <div>{handleGraphRender("well-being")}</div>
      ) : null}
      {temperatureData.length > 1 ? (
        <div>{handleGraphRender("temperature")}</div>
      ) : null}

      {modaleExchangeData !== null ? renderModal() : null}

      {loading ? (
        <div className="loading_overlay">
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
}
