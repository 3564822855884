import React from "react";
import "./AboutPage.css";
import { FormattedMessage } from "react-intl";
// import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const AboutPage = () => {
  const intl = useIntl();
  let history = useHistory();
  // const [redirected, setRedirected] = useState(false);
  //detect user default language
  //default is en en
  let current_language = "en";
  //declare supported languages
  let supported_language = "en,sk";
  //check if user language match supported and if so assign it to current language
  if (supported_language.includes(navigator.language)) {
    current_language = navigator.language.split(/[-_]/)[0];
  }
  //check if device is iPhone and display correct a2hs img tutorial
  let a2hs_img_path =
    "./assets/images/add_home_howto_" + current_language + ".jpg";
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("iPad")
  ) {
    a2hs_img_path =
      "./assets/images/add_home_apple_howto_" + current_language + ".jpg";
  }

  // const redirect = () => {
  //     if (redirected === true) {
  //         return <Redirect to='/HUBsite' />
  //     }
  // }
  return (
    <div className="aboutpage_container">
      <div className="about_back_btn" onClick={() => history.goBack()}>
        <ArrowBackIcon />{" "}
        {intl.formatMessage({
          id: "active_minute_back"
        })}
      </div>
      <img
        className="login_page_bg"
        src="/assets/images/background_img_hubsite.png"
        alt=""
      />
      <div className="aboutpage_title">
        {intl.formatMessage({
          id: "about_title"
        })}
      </div>
      <div className="aboutpage_text">
        <p>
          {intl.formatMessage({
            id: "about_pt1"
          })}
        </p>
        <h4>
          {intl.formatMessage({
            id: "about_h4_title"
          })}
        </h4>
        <h5>
          {intl.formatMessage({
            id: "about_h5_title"
          })}
        </h5>
        <p>
          {intl.formatMessage({
            id: "about_pt2"
          })}
        </p>
        <h5>
          {intl.formatMessage({
            id: "about_h5_title2"
          })}
        </h5>
        <p>
          {intl.formatMessage({
            id: "about_pt3"
          })}
        </p>
        <h5>
          {intl.formatMessage({
            id: "about_h5_title3"
          })}
        </h5>
        <p>
          {intl.formatMessage({
            id: "about_pt4"
          })}
        </p>
        <h5>
          {intl.formatMessage({
            id: "about_h5_title4"
          })}
        </h5>
        <p>
          {intl.formatMessage({
            id: "about_pt5_1"
          })}{" "}
          <a href="https://www.hub-web.com/">
            {intl.formatMessage({
              id: "about_pt5_2"
            })}
          </a>
        </p>
      </div>
      <p className="about_info_p1">
        <FormattedMessage id="cockpit_info_top_paragraf" />
      </p>
      <img className="about_info_img" alt="" src={a2hs_img_path} />
      <p className="about_info_p2">
        <FormattedMessage id="cockpit_info_bottom_paragraf" />
      </p>
    </div>
  );
};
export default AboutPage;
