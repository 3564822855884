import { URLconst } from "./const";
import axios from "axios";

// GET

export function getTablePermissions(param) {
  let BaseURL = URLconst("hubsite/permissions/table.php");

  return axios.get(BaseURL, {
    params: param
  });
}

export function getUsers(param) {
  let BaseURL = URLconst("hubsite/permissions/users.php");

  return axios.get(BaseURL, {
    params: param
  });
}

export function getPeoples(param) {
  let BaseURL = URLconst("hubsite/permissions/peoples.php");

  return axios.get(BaseURL, {
    params: param
  });
}

// POST

export function postPermissions(param) {
  let BaseURL = URLconst("hubsite/set_permissions.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

// REMOVE

export function deleteUserPermissions(param) {
  let BaseURL = URLconst("hubsite/set_permissions.php");

  return axios.delete(BaseURL, {
    params: param
  });
}

// PATCH

export function patchUserPermissions(param) {
  let BaseURL = URLconst("hubsite/set_permissions.php");

  return axios({
    url: BaseURL,
    method: "patch",
    headers: { "content-type": "application/json" },
    params: param,
    data: {}
  });
}

// PUT

export function putUserPermissions(param) {
  let BaseURL = URLconst("hubsite/set_permissions.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}
