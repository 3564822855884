import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { LoginCall } from "../../services/LoginCall";
import { FormattedMessage } from "react-intl";
import "./login.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

// import { ClaimFreeHub } from "../../services/ClaimFreeHub";
import { CreateNewHub } from "../../services/CreateNewHub";
import { getLanguage } from "../../services/SettingsData";
import { injectIntl } from "react-intl";
import moment from "moment";

import CreateHubModal from "../../components/CreateHubModal";
import CreateHubAlert from "../../components/CreateHubAlert";
import LoginCovidTop from "./loginCovid";

//validation of login input
var passwordValidator = require("password-validator");

var schema = new passwordValidator();
//has to be 4 digits and no spaces
schema
  .is()
  .min(4) // Minimum length 8
  .is()
  .max(4) // Maximum length 100
  .has()
  .not()
  .letters()
  .is()
  .digits() // Must have digits
  .has()
  .not()
  .spaces(); // Should not have spaces

class Login extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      pin: "",
      password: "",
      redirectToReferrer: false,
      login_error: false,
      pin_generated: false,
      login_validation_error: false,
      index: 0,
      createHubError: false,
      // newRedirectHome: false,

      openCreateModal: false,
      creatingDone: false,
      progressBarDone: false,
      timeLeft: 0,
      openAlertModal: false
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({
      index: value
    });
  };

  handleChangeIndex = index => {
    this.setState({
      index
    });
  };

  setProgressBarDone = () => {
    this.setState({
      progressBarDone: true
    });
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.hasOwnProperty("hub_id")) {
        if (this.props.location.state.hub_id !== null) {
          this.props.history.push(
            "/login?hub_id=" +
              this.props.location.state.hub_id +
              "&pin=" +
              this.props.location.state.pin
          );
        }
      }
    }

    this.checkForLoginInURL();
  }

  // first condition accept format like: (localhost:3000 or personal-hub.com)
  // http://localhost:3000?hub_id=1026&pin=1111
  // or
  // http://localhost:3000?hub_id=hub_000_000_001_026&pin=1111

  register = () => {
    let create_new_hub = true;
    if (localStorage.getItem("newHUB") !== null) {
      let difference =
        Number(moment().unix()) - Number(localStorage.getItem("newHUB"));
      if (difference < 300) {
        let num = Math.floor(difference / 60);
        create_new_hub = false;
        this.setState({ timeLeft: num, openAlertModal: true });
      }
    }
    if (create_new_hub) {
      if (sessionStorage.getItem("hub_token") === null) {
        const send_credential_to_registrar = {
          password: "This is CreHUBmultip@ssWord!"
        };
        this.setState({
          createHubError: false,
          openCreateModal: true
        });
        CreateNewHub(send_credential_to_registrar)
          .then(result => {
            const responseJson = result;
            if (responseJson.error) {
              console.log(responseJson.error);
              this.setState({
                createHubError: true
              });
            }
            if (responseJson.userData) {
              //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
              sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
              localStorage.setItem("newHUB", moment().unix());
              this.setState({ creatingDone: true });
              // this.setState({ newRedirectHome: true });
            }
          })
          .catch(() => {
            this.setState({
              createHubError: true
            });
          });
      }
    }
  };

  checkForLoginInURL() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("hub_id") !== null && urlParams.get("pin") !== null) {
      this.setState(
        {
          username: urlParams
            .get("hub_id")
            .slice(-5)
            .replace("_", ""),
          pin: urlParams.get("pin")
        },
        function() {
          if (this.state.username !== null && this.state.pin !== null) {
            this.login();
          }
        }
      );
    } else if (
      urlParams.get("hub_id") !== null &&
      urlParams.get("pin") === null
    ) {
      let val = Math.floor(1000 + Math.random() * 9000);
      this.setState(
        {
          username: urlParams
            .get("hub_id")
            .slice(-5)
            .replace("_", ""),
          pin: val.toString(),
          pin_generated: true
        },
        function() {
          if (this.state.username !== null && this.state.pin !== null) {
            this.login();
          }
        }
      );
    } else if (urlParams.get("register") === "new") {
      this.register();
    }
  }

  login() {
    let obj;

    if (this.state.index === 0) {
      obj = { username: this.state.username, pin: this.state.pin };
      if (
        !schema.validate(this.state.username) &&
        !schema.validate(this.state.pin)
      ) {
        this.setState({
          login_validation_error: true,
          login_error: true
        });
        return;
      }
    } else {
      obj = {
        username: this.state.username,
        password: this.state.password
      };
      if (!schema.validate(this.state.username)) {
        this.setState({
          login_validation_error: true,
          login_error: true
        });
        return;
      }
    }

    LoginCall(obj).then(result => {
      let responseJson = result;
      if (responseJson.error) {
        this.setState({
          login_validation_error: false,
          login_error: true
        });
      }
      if (responseJson.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        sessionStorage.setItem("hub_token", JSON.stringify(responseJson));

        if (localStorage.getItem("phsli") !== null) {
          localStorage.setItem("phsli", JSON.stringify(responseJson));
        }

        let send_credential_to_registrar = {
          target_hub_id: this.state.username
        };

        send_credential_to_registrar.requestor_hub_id = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData.my_hub_id;

        send_credential_to_registrar.section = "";

        send_credential_to_registrar.logged_in = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
        getLanguage(send_credential_to_registrar).then(result => {
          if (result.data.data_array) {
            if (result.data.data_array[0].value !== null) {
              if (
                localStorage.getItem("personal_hub_language") !==
                result.data.data_array[0].value
              ) {
                localStorage.setItem(
                  "personal_hub_language",
                  result.data.data_array[0].value
                );
                window.location.reload();
              }
            }
            this.setState({ redirectToReferrer: true });
          } else {
            console.log("error");
            this.setState({ redirectToReferrer: true });
          }
        });
      }
    });
  }

  closeModal = () => {
    this.setState({ openCreateModal: false });
  };

  closeAlertModal = () => {
    this.setState({ openAlertModal: false });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  submitBtn() {
    return (
      <button className="form_submit_btn" type="submit" onClick={this.login}>
        {" "}
        Login{" "}
      </button>
    );
  }

  render() {
    const { index } = this.state;
    const { intl } = this.props;

    if (this.state.progressBarDone) {
      return (
        <Redirect
          to={{
            pathname: "/HUBsite",
            state: { login_redirect_prop: "new" }
          }}
        />
      );
    }
    if (
      (this.state.redirectToReferrer || sessionStorage.getItem("hub_token")) &&
      this.state.openCreateModal === false
    ) {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.fromHubsite !== undefined) {
          return (
            <Redirect
              to={"/HUBsite/" + this.props.location.state.fromHubsite}
            />
          );
        } else {
          return <Redirect to={"/HUBsite"} />;
        }
      } else {
        return <Redirect to={"/HUBsite"} />;
      }
    }

    let login_error_block;
    if (this.state.login_error && this.state.index === 0) {
      login_error_block = (
        <label id="login_error">
          <FormattedMessage id="login_error_pin" />
        </label>
      );
    } else if (this.state.login_error && this.state.index === 1) {
      login_error_block = (
        <label id="login_error">
          <FormattedMessage id="login_error_password" />
        </label>
      );
    }
    let login_login_validation_error_block;
    if (this.state.login_validation_error) {
      login_login_validation_error_block = (
        <label id="login_login_validation_error">
          <FormattedMessage id="login_validation_error" />
        </label>
      );
    }
    return (
      <div className="login_page_block">
        {this.state.openCreateModal ? (
          <CreateHubModal
            open={this.state.openCreateModal}
            setOpen={this.closeModal}
            creatingDone={this.state.creatingDone}
            setProgressBarDone={this.setProgressBarDone}
            createHubError={this.state.createHubError}
            register={this.register}
          />
        ) : null}
        {this.state.openAlertModal ? (
          <CreateHubAlert
            open={this.state.openAlertModal}
            setOpen={this.closeAlertModal}
            timeLeft={this.state.timeLeft}
          />
        ) : null}
        <img
          className="login_page_bg"
          src="/assets/images/background_img_hubsite.png"
          alt=""
        />
        <div className="login_page_content">
          <div className="login_page_title">
            {/* <FormattedMessage id="login_to_hubsite_title" /> */}
            <span>
              {intl.formatMessage({
                id: "login_to_hubsite_cov_exch"
              })}
            </span>
          </div>
          <LoginCovidTop />
          <div className="personal_hub_block">
            <div className="personal_hub_block_title">
              <FormattedMessage id="login_page_new_here" />
            </div>
            <div className="personal_hub_form">
              <div className="personal_hub_form_text">
                <FormattedMessage id="login_page_create_hub_p1" />{" "}
                <b>
                  <FormattedMessage id="login_page_create_hub_p2" />
                </b>
              </div>
              <div>
                <button onClick={this.register} className="form_submit_btn">
                  {" "}
                  <FormattedMessage id="login_page_get_my_hub" />{" "}
                </button>
              </div>
              <div className="personal_hub_form_text">
                <a href="/about">
                  <FormattedMessage id="login_page_about_p1" />
                  &nbsp;
                </a>
                <FormattedMessage id="login_page_about_p2" />
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="personal_hub_form_text"
              >
                <a href="/about/covid19">
                  <FormattedMessage id="login_page_about_p1" />
                  &nbsp;
                </a>
                {intl.formatMessage({
                  id: "login_to_hubsite_about_covid"
                })}
              </div>
            </div>
          </div>
          <div className="login_form_block">
            <div className="login_form_title">
              <FormattedMessage id="login_form_title" />
            </div>
            <div className="login_form">
              <Tabs
                value={index}
                onChange={this.handleChange}
                style={{ width: "75%" }}
              >
                <Tab
                  label={intl.formatMessage({
                    id: "login_to_hubsite_user"
                  })}
                  style={{ width: "50%", textTransform: "capitalize" }}
                />
                <Tab
                  label={intl.formatMessage({
                    id: "login_to_hubsite_admin"
                  })}
                  style={{ width: "50%", textTransform: "capitalize" }}
                />
              </Tabs>
              <SwipeableViews
                index={index}
                onChangeIndex={this.handleChangeIndex}
                style={{ width: "100%" }}
              >
                <div className="form">
                  {login_error_block}
                  {login_login_validation_error_block}
                  <div className="form_small_title">
                    <FormattedMessage id="login_form_hub_number" />
                  </div>
                  <form autoComplete="on" name="user">
                    <input
                      type="text"
                      name="username"
                      className="login_form_input"
                      placeholder={intl.formatMessage({
                        id: "login_to_hubsite_enter_hub_id"
                      })}
                      value={this.state.username}
                      onChange={this.onChange}
                    />
                    <div className="form_small_title">
                      <FormattedMessage id="login_form_hub_pin" />
                    </div>
                    <input
                      type="password"
                      name="pin"
                      className="login_form_input"
                      placeholder={intl.formatMessage({
                        id: "login_to_hubsite_enter_pin"
                      })}
                      value={this.state.pin}
                      onChange={this.onChange}
                    />
                  </form>
                  {this.submitBtn()}
                </div>
                <div className="form">
                  {login_error_block}
                  {login_login_validation_error_block}
                  <div className="form_small_title">
                    <FormattedMessage id="login_form_hub_number" />
                  </div>
                  <form autoComplete="on" name="admin">
                    <input
                      type="text"
                      name="username"
                      className="login_form_input"
                      placeholder={intl.formatMessage({
                        id: "login_to_hubsite_enter_hub_id"
                      })}
                      value={this.state.username}
                      onChange={this.onChange}
                    />
                    <div className="form_small_title">
                      <FormattedMessage id="login_form_hub_password" />
                    </div>
                    <input
                      type="password"
                      name="password"
                      className="login_form_input"
                      placeholder={intl.formatMessage({
                        id: "login_to_hubsite_enter_password"
                      })}
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </form>
                  {this.submitBtn()}
                </div>
              </SwipeableViews>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Login);
