import React from "react";
import { Redirect } from "react-router-dom";

const BookApp = ({ loggedInAs }) => {
  if (loggedInAs !== "visitor") {
    return <Redirect to="/bookcollector" />;
  }
  return null;
};
export default BookApp;
