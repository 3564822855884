import React, { useEffect, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import "./Details.css";

const Details = ({
  item,
  target_hub_id,
  apiRequest,
  apiParam,
  itemParam,
  detailsFields,
  colSpan
}) => {
  const [details, setDetails] = useState([]);
  const [error, setError] = useState();
  const getApiDetails = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar[apiParam] = item[itemParam];
    // console.log(send_credential_to_registrar);
    apiRequest(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        setError(result.data.error);
      } else {
        setDetails(result.data.data_array);
      }
    });
  };
  useEffect(() => {
    getApiDetails();
    // eslint-disable-next-line
  }, [item]);

  if (!error) {
    return details.map((item, key) => {
      return (
        <TableRow className="main_details_block" key={key}>
          <TableCell colSpan={detailsFields.length <= 4 ? 3 : colSpan}>
            {detailsFields.map((field, id) => {
              if (id < 4) {
                return (
                  <div key={id} className="details_table_item">
                    {field.title}:{" "}
                    <b>
                      {field.value.map((value, i) => {
                        return (
                          <React.Fragment key={i}>
                            {item[value]},{" "}
                          </React.Fragment>
                        );
                      })}
                    </b>
                  </div>
                );
              } else {
                return null; //Default return
              }
            })}
          </TableCell>
          <TableCell colSpan={colSpan}>
            {detailsFields.map((field, id) => {
              if (id >= 4 && id < 8) {
                return (
                  <div key={id} className="details_table_item">
                    {field.title}:{" "}
                    <b>
                      {field.value.map((value, i) => {
                        return (
                          <React.Fragment key={i}>
                            {item[value]},{" "}
                          </React.Fragment>
                        );
                      })}
                    </b>
                  </div>
                );
              } else {
                return null; //Default return
              }
            })}
          </TableCell>
          <TableCell colSpan={colSpan}>
            {detailsFields.map((field, id) => {
              if (id >= 8) {
                return (
                  <div key={id} className="details_table_item">
                    {field.title}:{" "}
                    <b>
                      {field.value.map((i, id) => {
                        return (
                          <React.Fragment key={id}>{item[i]}, </React.Fragment>
                        );
                      })}
                    </b>
                  </div>
                );
              } else {
                return null; //Default return
              }
            })}
          </TableCell>
        </TableRow>
      );
    });
  } else {
    return (
      <TableRow className="main_details_block">
        <TableCell colSpan={detailsFields.length <= 4 ? 3 : colSpan}>
          {error}
        </TableCell>
      </TableRow>
    );
  }
};
export default Details;
