import React, { Component } from "react";
import "../../screens/Navigator/Navigator.css";
import { OrderBook } from "../../services/OrderBook";
import { FormattedMessage } from "react-intl";
import { ProdGroupImgAndName } from "./ProductGroupFunction";
import { injectIntl } from "react-intl";

class BookDetails extends Component {
  constructor() {
    super();
    this.state = {
      show_order_box: false,
      orderError: false
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.confirm_order !== prevProps.confirm_order) {
      if (this.props.login_from_cart && this.props.confirm_order) {
        let r = this.props.book_info["0"];
        this.send_order_to_shop(
          r.EAN,
          r.ShortTitle,
          r.Publisher,
          r.PriceGermany
        );
      }
    }
  }

  send_order_to_shop = (ean, article_name, publisher, price) => {
    const { intl } = this.props;
    if (this.props.my_hub_id !== null) {
      let send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.external_id_1 = ean;
      send_credential_to_registrar.shop_id = this.props.shop_id;
      send_credential_to_registrar.title = article_name;
      send_credential_to_registrar.publisher = publisher;
      send_credential_to_registrar.price = price;
      send_credential_to_registrar.possession = "ordered";
      send_credential_to_registrar.kind = "book";
      send_credential_to_registrar.possession_related_to =
        send_credential_to_registrar.shop_id;
      send_credential_to_registrar.referrer = "shop_navigation";
      if (this.props.login_from_cart && this.props.confirm_order) {
        OrderBook(send_credential_to_registrar).then(result => {
          if (result.success) {
            this.props.bookdetailsresetcallback();
            this.setState({
              inserted: true
            });
          } else {
            this.props.bookdetailsresetcallback();
            this.setState({
              orderError: true
            });
          }
        });
      } else {
        if (
          window.confirm(
            intl.formatMessage({
              id: "navigator_bookdetails_confirm_order"
            })
          )
        ) {
          OrderBook(send_credential_to_registrar).then(result => {
            if (result.success) {
              this.props.bookdetailsresetcallback();
              this.setState({
                inserted: true
              });
            } else {
              this.props.bookdetailsresetcallback();
              this.setState({
                orderError: true
              });
            }
          });
        }
      }
    } else {
      this.props.bookdetails_callback();
    }
  };

  render() {
    return (
      <div id="BookDetails">
        {this.props.book_info.map(r => (
          <div className="book_details" key={r.EntryID}>
            <div className="book_details_stock_container">
              {this.props.in_stock === false ? (
                <div className="book_details_stock_status">
                  <FormattedMessage id="navigator_bd_not_in_stock" />
                </div>
              ) : (
                <div>
                  <FormattedMessage id="navigator_bd_book_available_pt1" />{" "}
                  {`(${this.props.in_stock}x)`}.{" "}
                  <FormattedMessage id="navigator_bd_book_available_pt2" />
                </div>
              )}
            </div>
            <div className="book_details_data_content_container">
              <img
                className="book_details-item-image"
                alt=""
                src={
                  "https://portal.dnb.de/opac/mvb/cover.htm?isbn=" +
                  r.EAN +
                  "&size=m"
                }
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/missing-image.png";
                }}
              />
              <div className="book_details_book_title">{r.ShortTitle}</div>
              <div className="book_details_book_author">{r.Author}</div>
              <div className="book_details_price_container">
                <div className="book_details_invisible"></div>
                {this.state.inserted !== true ? (
                  <div className="book_details_price">€ {r.PriceGermany}</div>
                ) : null}
                {this.props.in_stock === false ? (
                  this.state.inserted !== true ? (
                    <img
                      className="book_details_cart_image"
                      alt=""
                      src="/assets/images/shoping_cart_icon.png"
                      onClick={() =>
                        this.send_order_to_shop(
                          r.EAN,
                          r.ShortTitle,
                          r.Publisher,
                          r.PriceGermany
                        )
                      }
                    />
                  ) : (
                    <div>
                      <FormattedMessage id="navigator_bd_order_success" />
                    </div>
                  )
                ) : (
                  <div className="book_details_invisible"></div>
                )}
                {this.state.orderError ? (
                  <FormattedMessage id="navigator_bd_order_error" />
                ) : null}
              </div>
              <div className="book_details_border_box">
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_ean" />:{" "}
                  </strong>{" "}
                  {r.EAN}
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_product_group" />:{" "}
                  </strong>{" "}
                  {ProdGroupImgAndName("name", r.SortimentType)}
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_publisher" />:{" "}
                  </strong>{" "}
                  {r.Publisher}
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_year" />:{" "}
                  </strong>{" "}
                  {r.Year}
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_weight" />:{" "}
                  </strong>{" "}
                  {r.Weight}
                </div>
                <div>
                  <strong>
                    <FormattedMessage id="navigator_bd_pages" />:{" "}
                  </strong>{" "}
                  {r.FigureNote}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default injectIntl(BookDetails);

// Author: "Rowling, J. K."
// BookHeight: null
// BookWidth: null
// EAN: "9783551557407"
// EntryID: "2019043001795"
// Pages: "4192 "
// PriceGermany: "129"
// ProductGroup: "25"
// ProductIndex: "1"
// Publisher: "CARLSEN"
// Series: "Harry Potter"
// ShortTitle: "Rowling:Harry Potter.1-7 im Schuber"
// Subtitle: "Harry Potter und der Stein der Weisen; Harry Potter und die Kammer des Schreckens; Harry Potter und der Gefangene von Askaban; Harry Potter und der Feuerkelch; Harry Potter und der Orden des Phönix; Harr"
// Title: "Harry Potter, 7 Vols."
// Weight: null
// Year: "2019"
// url_mini: "https://multimedia.knv.de/cgi-bin/knvmmdb.cgi?herkunft=knv&MM_IDENTNR=999wsmm&typ=cover&vknr=39996&id=77722395&kennwort=UNREHNKGSCHLQF&variante=mini&rang=01"
// url_normal: "https://multimedia.knv.de/cgi-bin/knvmmdb.cgi?herkunft=knv&MM_IDENTNR=999wsmm&typ=cover&vknr=39996&id=77722395&kennwort=UNREHNKGSCHLQF&variante=normal&rang=01"
// url_zoom: "https://multimedia.knv.de/cgi-bin/knvmmdb.cgi?herkunft=knv&MM_IDENTNR=999wsmm&typ=cover&vknr=39996&id=77722395&kennwort=UNREHNKGSCHLQF&variante=zoom&rang=01"
