import React, { useState, useRef } from "react";

export default function SelectiveItem({
  item,
  setUseSelectiveUpgrade,
  useSelectiveUpgrade,
  setShowSelectiveItemsHeight,
  showSelectiveItemsHeight,
}) {
  const sql_selective_ref = useRef(null);
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div className="upgrade_page_seelctive_part">
        <input
          checked={
            useSelectiveUpgrade[item.id] !== undefined
              ? useSelectiveUpgrade[item.id]
              : false
          }
          className="upgrade_page_info_checkbox"
          type="checkbox"
          onChange={(e) => {
            setUseSelectiveUpgrade({
              ...useSelectiveUpgrade,
              [item.id]: e.target.checked,
            });
          }}
        ></input>
        <div className="upgrade_page_selective_text">
          {item.public_reason}{" "}
          <span
            onClick={() => {
              setExpand(!expand);
              return !expand
                ? setShowSelectiveItemsHeight(
                    showSelectiveItemsHeight +
                      sql_selective_ref.current.scrollHeight +
                      20
                  )
                : setShowSelectiveItemsHeight(
                    showSelectiveItemsHeight -
                      (sql_selective_ref.current.scrollHeight + 20)
                  );
            }}
            style={expand ? { color: "#ff7d73" } : null}
          >
            ... SQL
          </span>
        </div>
      </div>
      <div
        className="upg_selective_item_expand_container"
        style={
          expand
            ? { maxHeight: sql_selective_ref.current.scrollHeight + 20 + "px" }
            : null
        }
        ref={sql_selective_ref}
      >
        {item.sql_command}
      </div>
    </div>
  );
}
