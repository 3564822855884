import React from "react";
import "./Charts.css";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import constants from "../../constants";
import Chart from "../../components/Charts";
import { getSpendingChartData } from "../../services/Charts/spendings";
import { getBooksChartData } from "../../services/Charts/books";
import helpers from "../../helpers/Charts";

const Charts = () => {
  // destructuring
  const { ChartController } = Chart;
  const userData = JSON.parse(window.sessionStorage.getItem("hub_token"))
    .userData;
  const { filterControllerBook, filterController } = helpers;
  const { ChartsCategories } = constants.Charts;
  const selectKeys = {
    spendings: ["StoreID", "StoreType"]
  };

  return (
    <>
      <img alt="bg" src="/assets/images/library_img_2.jpg" className="bg-img" />
      <div className="charts-screen">
        <h1 className="charts-screen-tittle">Statistics</h1>
        <div className="charts-screen-chart-block">
          <ChartController
            title="Books"
            createChartsCollback={filterControllerBook}
            categoryProps={ChartsCategories}
            getDataPayload={userData}
            getData={getBooksChartData}
          >
            {({ data }) => (
              <BarChart
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
                data={data}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval="preserveStartEnd" />
                <YAxis interval="preserveStartEnd" />
                <Tooltip />
                <Bar
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </BarChart>
            )}
          </ChartController>
          <div>
            <ChartController
              title="Spending"
              createChartsCollback={filterController}
              selectKeys={selectKeys.spendings}
              getDataPayload={userData}
              getData={getSpendingChartData}
              // ToDo pass the props for  select, dynamic select
            >
              {({ data }) => (
                <AreaChart
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                  data={data}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval="preserveStartEnd" />
                  <YAxis interval="preserveStartEnd" />
                  <Tooltip />
                  <Area type="monotone" dataKey="value" fill="#8884d8" />
                </AreaChart>
              )}
            </ChartController>
          </div>
        </div>
      </div>
    </>
  );
};

export default Charts;
