import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import Categories from "./Categories/Categories";
import Wheel from "./Wheel/Wheel";
import "./index.css";
import BookApp from "./LeisureStatement/BookApp/BookApp";
import ReceiptsApp from "./FinancesStatement/ReceiptsApp/ReceiptsApp";
import ShopNavigation from "./VariousStatement/ShopNavigation/ShopNavigation";
import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

import VaccinationsTable from "./HealthStatement/VaccinationsTable/Table";
import DentistTable from "./HealthStatement/DentistTable/Table";
import TeethHistoryTable from "./HealthStatement/TeethHistoryTable/Table";
import SicknessHistoryTable from "./HealthStatement/SicknessHistoryTable/Table";
import InjuriesHistoryTable from "./HealthStatement/InjuriesHistoryTable/Table";
import AllergiesTable from "./HealthStatement/AllergiesTable/Table";
import AllergiesToTable from "./HealthStatement/AllergiesToTable/Table";
import FitnessTable from "./HealthStatement/FitnessTable/Table";
import WeightTable from "./HealthStatement/WeightTable/Table";
import SleepTable from "./HealthStatement/SleepTable/Table";
import CovidDiseaseTable from "./HealthStatement/CovidDiseaseTable/Table";
import PreventionTable from "./HealthStatement/PreventionTable/Table";
import BloodPressureTable from "./HealthStatement/BloodPressureTable/Table";
import SmokingTable from "./HealthStatement/SmokingTable/Table";
import MedicationsTable from "./HealthStatement/MedicationsTable/Table";
import NutritionTable from "./HealthStatement/NutritionTable/Table";
import HeightTable from "./HealthStatement/HeightTable/Table";

import ElectronicsTable from "./FinancesStatement/ElectronicsTable/Table";
// import Permissions from './Permissions/Permissions'
import VaccinationsHorisontalTimeLineChart from "./VaccinationsHorisontalTimeLineChart/VaccinationsHorisontalTimeLineChart";
// import HorizontalTimelineChart from "./HorizontalTimelineChart/index";
// import { Redirect } from "react-router-dom";
import AllergiesTimeLineGraph from "./HealthStatement/AllergiesTimeLineGraph/AllergiesTimeLineGraph";
import FitnessTimeLineGraph from "./HealthStatement/FitnessTimeLineGraph/FitnessTimeLineGraph";
import BudgetTable from "./FinancesStatement/BudgetTable/Table";
import CarsTable from "./FinancesStatement/CarsTable/Table";
import LoansDebtsTable from "./FinancesStatement/LoansDebtsTable/Table";
import FurnitureTable from "./FinancesStatement/FurnitureTable/Table";
import HouseTable from "./FinancesStatement/HouseTable/Table";
import SavingsTable from "./FinancesStatement/SavingsTable/Table";
import ReceiptsTable from "./FinancesStatement/ReceiptsTable/Table";

import SalaryTable from "./WorkStatement/SalaryTable/Table";
import CareerTable from "./WorkStatement/CareerTable/Table";
import CertificatesTable from "./WorkStatement/CertificatesTable/Table";
import CurriculumVitaeTable from "./WorkStatement/CurriculumVitaeTable/Table";
import JobApplicationsTable from "./WorkStatement/JobApplicationsTable/Table";

import BooksTable from "./LeisureStatement/BooksTable/Table";
import CollectionsTable from "./LeisureStatement/CollectionsTable/Table";
import FilmsTable from "./LeisureStatement/FilmsTable/Table";
import SportsTable from "./LeisureStatement/SportsTable/Table";
import RelaxationTable from "./LeisureStatement/RelaxationTable/Table";
import TravelTable from "./LeisureStatement/TravelTable/Table";
import VacationsTable from "./LeisureStatement/VacationsTable/Table";

import ReligionTable from "./SoulStatement/ReligionTable/Table";
import HappinessTable from "./SoulStatement/HappinessTable/Table";
import DiaryTable from "./SoulStatement/DiaryTable/Table";
import CharityTable from "./SoulStatement/CharityTable/Table";
import SexTable from "./SoulStatement/SexTable/Table";
import GoalsTable from "./SoulStatement/GoalsTable/Table";
import BucketListTable from "./SoulStatement/BucketTable/Table";
import RomanceTable from "./SoulStatement/RomanceTable/Table";
import DayNoteTable from "./SoulStatement/DayNoteTable/Table";

import DegreesDiplomasTable from "./EducationStatement/DegreesDiplomasTable/Table";
import ExpertiseTable from "./EducationStatement/ExpertiseTable/Table";
import GradesTable from "./EducationStatement/GradesTable/Table";
import SchoolsTable from "./EducationStatement/SchoolsTable/Table";
import SkillsTable from "./EducationStatement/SkillsTable/Table";
import TalentsTable from "./EducationStatement/TalentsTable/Table";
import ToLearnTable from "./EducationStatement/ToLearnTable/Table";

import ContactsTable from "./FriendsFamilyStatement/ContactsTable/Table";
import CovidContactsTable from "./FriendsFamilyStatement/CovidContactsTable/Table";
import FamilyTreeTable from "./FriendsFamilyStatement/FamilyTreeTable/Table";
import FriendsTable from "./FriendsFamilyStatement/FriendsTable/Table";
import InteractionsTable from "./FriendsFamilyStatement/InteractionsTable/Table";
import KidsTable from "./FriendsFamilyStatement/KidsTable/Table";
import MyPhotoalbumTable from "./FriendsFamilyStatement/MyPhotoalbumTable/Table";
import PhotosByMeTable from "./FriendsFamilyStatement/PhotosByMeTable/Table";

import Complaints from "./VariousStatement/Complaints/Table";
import Political from "./VariousStatement/Political/Table";
import PublicEngagements from "./VariousStatement/PublicEngagements/Table";
import Ratings from "./VariousStatement/Ratings/Table";
import CovidRatings from "./VariousStatement/CovidRatings/Table";
import Settings from "./VariousStatement/Settings/Table";
import ShopNavigationTable from "./VariousStatement/ShopNavigationTable/Table";

import {
  getVaccinationExpirationsDates,
  getAllergiesChartData,
  getPersonalAppParameters,
  getFitnessGraph,
  getNutritionList,
  getUnseenReceipts,
  getVisitedHubIP,
} from "../../services/GetHUBsiteData";

import { getActiveMinuteSettings } from "../../services/GetActiveMinuteData";

import { putUnseenToSeen } from "../../services/PutHUBsiteData";

import { postLogData } from "../../services/Logging";
import NutritionBarGraph from "./HealthStatement/NutritionBarGraph/NutritionBarGraph";

import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowBackwardIcon from "@material-ui/icons/ArrowBack";
// import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Calendar from "../../screens/Calendar/Calendar";
import { SwipeableDrawer } from "@material-ui/core";
// import { Button, Divider } from "@material-ui/core";
// import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
// import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";

import EditProfileModal from "./Modals/EditProfileModal/EditProfileModal";
import { useIntl } from "react-intl";
import Covid19 from "./Covid19/Covid19";

import { getUpdateCheck } from "../../services/GET_requests";

const useStyles = makeStyles({
  search: {
    position: "relative",
    // border: "1px solid #E4E4E4",
    border: "none",
    // boxShadow: "0 2px 6px 0 rgba(0,0,0,0.14)",
    boxShadow: "none",
    backgroundColor: "transparent",
    display: "flex",
  },
  globalSearchTitle: {
    width: "310px",
    height: "80px",
    textAlign: "center",
    paddingTop: "40px",
    color: " #232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
  },
  searchIcon: {
    padding: "0",
    paddingTop: "3px",
    marginRight: "20px",
    color: "black",
  },
  searchField: {
    margin: "0 10px 0 10px",
    paddingLeft: "10px",
    width: "93%",
    border: "1px solid #E4E4E4",
    borderRadius: "7px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.14)",
  },
  searchType: {
    paddingLeft: "5px",
    margin: "0 10px 0 5px",
    width: "80px",
    border: "1px solid #E4E4E4",
    borderRadius: "7px",
    backgroundColor: "#FFFFFF",
    height: "35px",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(71, 119, 186, 0.07)",
    },
  },
  cell: {
    borderBottom: "none !important",
    position: "relative",
  },
  table: {
    backgroundColor: "white",
    marginTop: "10px",
  },
  compactModeBlock: {
    height: "100px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "100px",
  },
  compactSegment: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
});

const HUBsite = (props) => {
  const intl = useIntl();
  const [state, setState] = useState({
    activeWheelItem: 0,
    activeCategory: null,
    activeSubCategory: null,
  });

  const [shift, setShift] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [target_hub_id, setProvidedHubID] = useState("");
  const [targetHubIpAddress, setTargetHubIpAddress] = useState(null);
  const [requestorHubID, setRequestorHubID] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [unseenReceipts, setUnseenReceipts] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(false);
  const [loggedInAs, setLoggedInAs] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [compactMode, setCompactMode] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [logoutState, setLogoutState] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [updateProfileData, setUpdateProfileData] = useState(false);

  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [dbUpdateNeeded, setDbUpdateNeeded] = useState(false);

  // const [mimicHUB, setMimicHUB] = useState(null);
  const [dataArray, setDataArray] = useState([
    {
      title: "",
      src: "",
      color: "",
      categories: [
        {
          title: "",
          components: [],
          subCategories: [{ title: "" }],
        },
      ],
    },
  ]);
  const classes = useStyles();
  const data_array = useRef();
  const target_hub_id_ref = useRef();
  const requestor_hub_id_ref = useRef();
  const login_prop = useRef();
  const textInput = useRef(null);
  useEffect(() => {
    data_array.current = dataArray;
    target_hub_id_ref.current = target_hub_id;
    requestor_hub_id_ref.current = requestorHubID;
    login_prop.current = props.location.state;
  });

  useEffect(() => {
    if (login_prop.current !== undefined && newUser !== false) {
      if (login_prop.current.login_redirect_prop === "new") {
        setNewUser(true);
      }
    }
  }, [newUser]);

  useEffect(() => {
    let result = [];
    if (searchText !== "") {
      // loop through each segment
      data_array.current.forEach((seg_item, seg_i) => {
        let seg = seg_item.title + " " + seg_item.keywords;
        if (seg.toUpperCase().includes(searchText.toUpperCase())) {
          let temp_obj = {
            title: seg_item.title,
            state: {
              activeWheelItem: seg_i,
              activeCategory: null,
              activeSubCategory: null,
            },
          };
          result.push(temp_obj);
        }
        // loop through each category
        seg_item.categories.forEach((cat_item, cat_i) => {
          let cat = cat_item.title + " " + cat_item.keywords;
          if (cat.toUpperCase().includes(searchText.toUpperCase())) {
            let temp_obj = {
              title: cat_item.title,
              state: {
                activeWheelItem: seg_i,
                activeCategory: cat_i,
                activeSubCategory: null,
              },
            };
            result.push(temp_obj);
          }
          // loop through each sub category
          cat_item.subCategories.forEach((subcat_item, subcat_i) => {
            let sub_cat = subcat_item.title + " " + subcat_item.keywords;
            if (sub_cat.toUpperCase().includes(searchText.toUpperCase())) {
              let temp_obj = {
                title: subcat_item.title,
                state: {
                  activeWheelItem: seg_i,
                  activeCategory: cat_i,
                  activeSubCategory: subcat_i,
                },
              };
              result.push(temp_obj);
            }
          });
        });
      });
      setResultData(result);
    } else {
      // loop through each segment
      data_array.current.forEach((seg_item, seg_i) => {
        let temp_obj = {
          title: seg_item.title,
          state: {
            activeWheelItem: seg_i,
            activeCategory: null,
            activeSubCategory: null,
          },
        };
        result.push(temp_obj);
      });
      setResultData(result);
    }
  }, [searchText, dataArray]);

  // logic to handle who is logged in and what he want ot look at
  useEffect(() => {
    getPersonalAppParameters().then((result) => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        setDataArray(result.data.data_array);
      }
    });

    if (
      props.match.params.hub_id === undefined &&
      sessionStorage.getItem("hub_token")
    ) {
      props.history.push(
        "/HUBsite/" +
          parseInt(
            JSON.parse(sessionStorage.getItem("hub_token"))
              .userData.my_hub_id.replace(/hub_/g, "")
              .replace(/_/g, ""),
            10
          )
      );
      setProvidedHubID(
        JSON.parse(sessionStorage.getItem("hub_token")).userData.my_hub_id
      );
      setRequestorHubID(
        JSON.parse(sessionStorage.getItem("hub_token")).userData.my_hub_id
      );
      setLoggedInAs(
        JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData.db_username.slice(20)
      );
    } else if (
      props.match.params.hub_id !== undefined &&
      sessionStorage.getItem("hub_token") !== null
    ) {
      setProvidedHubID(props.match.params.hub_id);
      setRequestorHubID(
        JSON.parse(sessionStorage.getItem("hub_token")).userData.my_hub_id
      );
      setLoggedInAs(
        JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData.db_username.slice(20)
      );
      if (
        parseInt(
          JSON.parse(sessionStorage.getItem("hub_token"))
            .userData.my_hub_id.replace(/hub_/g, "")
            .replace(/_/g, ""),
          10
        ) !==
        parseInt(
          props.match.params.hub_id.replace(/hub_/g, "").replace(/_/g, ""),
          10
        )
      ) {
        setLoggedInAs("visitor");
        let credentials = {
          target_hub_id: props.match.params.hub_id,
          logged_in: !sessionStorage.getItem("hub_token")
            ? null
            : JSON.parse(sessionStorage.getItem("hub_token")).userData,
        };
        postLogData(credentials).then((result) => {
          if (result.data.error) {
            // console.log(result.data);
          } else {
            // console.log(result.data);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [props.match.params.hub_id]);

  useEffect(() => {
    let curr_usr_id = parseInt(
      JSON.parse(sessionStorage.getItem("hub_token"))
        .userData.my_hub_id.replace(/hub_/g, "")
        .replace(/_/g, ""),
      10
    );

    if (Number(target_hub_id) !== Number(curr_usr_id) && target_hub_id !== "") {
      let send_credential_to_registrar = {
        target_hub_id: target_hub_id_ref.current,
        requestor_hub_id: requestor_hub_id_ref.current,
        section: "",
      };
      if (!sessionStorage.getItem("hub_token")) {
        send_credential_to_registrar.logged_in = null;
      } else {
        send_credential_to_registrar.logged_in = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
      }
      getVisitedHubIP(send_credential_to_registrar).then((result) => {
        if (result.data.error) {
          console.log("get ip error");
        } else {
          // console.log(result.data.data_array);
          setTargetHubIpAddress(result.data.data_array);
        }
      });
    }
  }, [target_hub_id]);

  useEffect(() => {
    if (
      loggedInAs !== false &&
      target_hub_id_ref.current !== "" &&
      dataArray.length > 1
    ) {
      let send_credential_to_registrar = {
        target_hub_id: target_hub_id_ref.current,
        requestor_hub_id: requestor_hub_id_ref.current,
        section: "",
      };
      if (!sessionStorage.getItem("hub_token")) {
        send_credential_to_registrar.logged_in = null;
      } else {
        send_credential_to_registrar.logged_in = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
      }
      getUnseenReceipts(send_credential_to_registrar).then((result) => {
        if (result.data.error) {
          // console.log("no unseen");
        } else {
          // console.log(result.data.data_array[0].counterpart_hub_id);
          setUnseenReceipts(result.data.data_array[0].counterpart_hub_id);
          putUnseenToSeen(send_credential_to_registrar).then((result) => {
            // console.log(result);
          });
        }
      });
      send_credential_to_registrar.reminder = true;
      getActiveMinuteSettings(send_credential_to_registrar).then((result) => {
        if (result.data.data_array === "reset") {
          setShowReminder(true);
        }
      });
      getUpdateCheck(send_credential_to_registrar).then((result) => {
        if (result.data.update_needed) {
          setDbUpdateNeeded(true);
        }
      });
    }
  }, [loggedInAs, dataArray]);

  const unseen_Receipts = () => {
    if (unseenReceipts !== false && !newUser) {
      return <Redirect to={"/receipts/" + unseenReceipts} />;
    }
  };

  useEffect(() => {
    if (showSearchBar) {
      textInput.current.focus();
    }
  }, [showSearchBar]);

  useEffect(() => {
    setShowCalendar(false);
  }, [state.activeWheelItem, state.activeCategory]);

  const logout = () => {
    if (logoutState === true) {
      sessionStorage.setItem("hub_token", "");
      sessionStorage.clear();
      // console.log("redirect below");
      return <Redirect to={"/login"} />;
    }
  };

  const menuAndSearch = () => {
    return (
      <div className="menu_search_container">
        {/* Burger menu */}
        <BurgerMenu
          loggedInAs={loggedInAs}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          setOpenAvatarModal={setOpenAvatarModal}
          setLogoutModal={setLogoutModal}
          compactMode={compactMode}
          setCompactMode={setCompactMode}
          updateProfileData={updateProfileData}
          setUpdateProfileData={setUpdateProfileData}
          state={state}
          setState={setState}
          dbUpdateNeeded={dbUpdateNeeded}
        />
        {openAvatarModal ? (
          <EditProfileModal
            openAvatarModal={openAvatarModal}
            setOpenAvatarModal={setOpenAvatarModal}
            setUpdateProfileData={setUpdateProfileData}
          />
        ) : null}
        {showReminder ? (
          <Link to={"/activeMinute"}>
            <div
              style={{
                position: "relative",
                display: "flex",
                paddingTop: "20px",
                zIndex: "10",
              }}
            >
              <img
                style={{ width: "15px", height: "15px" }}
                className="hubsite_icon"
                src="/assets/images/active_minute_icon.png"
                alt="logout_icon"
              />
              <div style={{ fontSize: "10px", marginLeft: "5px" }}>
                Active Minute alert
              </div>
            </div>
          </Link>
        ) : null}

        <IconButton
          className={classes.searchIcon + " searchIcon"}
          aria-label="search"
          // fontSize="large"
          onClick={() => {
            setGlobalSearch(true);
          }}
        >
          <SearchIcon fontSize="large" />
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={globalSearch}
          onOpen={() => setGlobalSearch(true)}
          onClose={() => setGlobalSearch(false)}
        >
          <div className={classes.globalSearchTitle}>Global search</div>
          <div
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
            }}
            onClick={() => setGlobalSearch(false)}
          >
            <CloseIcon />
          </div>
          <div>
            <InputBase
              className={classes.searchField}
              value={searchText}
              placeholder="Search"
              inputRef={textInput}
              // placeholder={intl.formatMessage({
              //   id: "virtual_assistant_search"
              // })}
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </div>

          {resultData !== null ? (
            <Table className={classes.table} size="small">
              <TableBody>
                {resultData.map((item, id) => {
                  return (
                    <TableRow
                      onClick={() => {
                        setState(item.state);
                        setGlobalSearch(false);
                        setShowCalendar(false);
                        setCompactMode(false);
                        setShowSearchBar(false);
                      }}
                      key={id}
                      className={classes.row}
                    >
                      <TableCell className={classes.cell} key={id}>
                        {item.state.activeCategory === null &&
                        item.state.activeSubCategory === null ? (
                          <b>{item.title}</b>
                        ) : (
                          item.title
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : null}
        </SwipeableDrawer>
      </div>
    );
  };

  return (
    <>
      {newUser === true ? (
        // {true ? (
        <div
          style={{
            padding: "10px",
            backgroundColor: "white",
            zIndex: "1000",
            position: "sticky",
            top: "0",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Roboto",
            justifyContent: "space-between",
            boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#3FA4FF",
              alignSelf: "center",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setNewUser(false);
            }}
          >
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              style={{
                color: "rgb(12, 138, 212)",
                alignSelf: "center",
                transform: "rotate(180deg)",
              }}
            >
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
            <span style={{ whiteSpace: "nowrap" }}>
              {intl.formatMessage({
                id: "hubsite_new_go_to",
              })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                // alignSelf: "center"
              }}
            >
              {intl.formatMessage({
                id: "hubsite_new_welcome",
              })}
            </div>
            <div
              style={{
                // alignSelf: "center"
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {intl.formatMessage({
                    id: "hubsite_new_your_hub_id",
                  })}
                </span>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    lineHeight: "25px",
                  }}
                >
                  {JSON.parse(sessionStorage.getItem("hub_token"))
                    .userData.my_hub_id.slice(-5)
                    .replace("_", "")}
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  {intl.formatMessage({
                    id: "hubsite_new_your_pin",
                  })}
                </span>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    lineHeight: "25px",
                  }}
                >
                  {
                    JSON.parse(sessionStorage.getItem("hub_token")).userData
                      .temp_pin
                  }
                </span>
              </div>
            </div>
            {unseenReceipts ? (
              // {true ? (
              <div
                onClick={() => {
                  setNewUser(false);
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  {intl.formatMessage({
                    id: "hubsite_unseen_rec_receipt_saved",
                  })}
                </div>

                <div>
                  {intl.formatMessage({
                    id: "hubsite_unseen_rec_click",
                  })}{" "}
                  <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                    style={{
                      color: "rgb(12, 138, 212)",
                      alignSelf: "center",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                  </svg>{" "}
                  {intl.formatMessage({
                    id: "hubsite_unseen_rec_to_view_it",
                  })}
                </div>
              </div>
            ) : null}
          </div>
          <div className="right_block"></div>
        </div>
      ) : null}
      {loggedInAs === "visitor" && props.match.params.hub_id !== undefined ? (
        <div
          style={{
            backgroundColor: "#fff",
            zIndex: "1000",
            position: "sticky",
            top: "0",
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "0 2px 4px -2px gray",
          }}
        >
          <Link title="return to your hub" to={"/hubsite"}>
            <div className="back_to_hub_text">
              {" "}
              <ArrowBackwardIcon />{" "}
              {intl.formatMessage({
                id: "hubsite_visit_back_to",
              })}
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#000",
              paddingTop: "10px",
              width: "30%",
            }}
          >
            <div style={{ fontSize: "10px", textAlign: "center" }}>
              {intl.formatMessage({
                id: "hubsite_visit_visiting_hub",
              })}
            </div>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {parseInt(
                props.match.params.hub_id
                  .replace(/hub_/g, "")
                  .replace(/_/g, ""),
                10
              )}
            </div>
          </div>
          <div className="visit_panel_right_block"></div>
        </div>
      ) : null}
      <div
        className="new_design_page_block"
        style={{ paddingTop: `${shift}px` }}
      >
        {/* <div 
          style={{ 
            position: "absolute", 
            zIndex: "20", 
            top: "130px", 
            left: "calc(50% + 255px)", 
            transform: "translateX(-100%)" 
          }} 
        > 
          <input 
            onChange={event => { 
              set_mimic_hub(event.target.value); 
            }} 
            placeholder={ 
              sessionStorage.getItem("mimic_hub") !== null 
                ? sessionStorage.getItem("mimic_hub") 
                : "" 
            } 
            name="mimic_hub" 
          /> 
          <button 
            onClick={() => { 
              start_mimic_hub(); 
            }} 
          > 
            start 
          </button> 
          <button 
            onClick={() => { 
              stop_mimic_hub(); 
            }} 
          > 
            stop 
          </button> 
        </div> */}
        <img
          style={{
            filter: `hue-rotate(${dataArray[state.activeWheelItem].color}deg)`,
            width: "100%",
            position: "absolute",
            opacity: 0.15,
            zIndex: "0",
            maxHeight: "100vh",
          }}
          src="/assets/images/background_img_hubsite.png"
          alt=""
        />

        {compactMode ? (
          <div className={classes.compactModeBlock}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setCompactMode(false);
                setShowCalendar(false);
              }}
            >
              <div className={classes.compactSegment}>
                <img
                  alt=""
                  src={dataArray[state.activeWheelItem].src}
                  style={{
                    border: "2px solid #3FE0FF",
                    opacity: 1,
                    width: "50px",
                    height: "50px",
                    borderRadius: "15px",
                    boxShadow:
                      "-6px 6px 31px 0 rgba(22,25,31,0.13), 0 2px 24px -11px rgba(22,232,255,0.76)",
                    marginRight: "5px",
                  }}
                />
                <div>
                  {dataArray[state.activeWheelItem].title !== ""
                    ? intl.formatMessage({
                        id: dataArray[state.activeWheelItem].title,
                      })
                    : dataArray[state.activeWheelItem].title}
                </div>
              </div>
              {state.activeCategory !== null ? (
                <div className={classes.compactSegment}>
                  <div
                    style={{
                      margin: "0 10px 0 10px",
                      height: "40px",
                      width: "1px",
                      backgroundColor: "black",
                    }}
                    orientation="vertical"
                  ></div>
                  {/* {console.log(dataArray[state.activeWheelItem].categories[state.activeCategory].src)} */}
                  {/* categories */}
                  <img
                    alt=""
                    src={
                      dataArray[state.activeWheelItem].categories[
                        state.activeCategory
                      ].src
                    }
                    style={{
                      border: "2px solid #3FE0FF",
                      opacity: 1,
                      width: "50px",
                      height: "50px",
                      borderRadius: "15px",
                      boxShadow:
                        "-6px 6px 31px 0 rgba(22,25,31,0.13), 0 2px 24px -11px rgba(22,232,255,0.76)",
                      marginRight: "5px",
                    }}
                  />
                  <div>
                    {dataArray[state.activeWheelItem].categories[
                      state.activeCategory
                    ].title !== ""
                      ? intl.formatMessage({
                          id:
                            dataArray[state.activeWheelItem].categories[
                              state.activeCategory
                            ].title,
                        })
                      : dataArray[state.activeWheelItem].categories[
                          state.activeCategory
                        ].title}
                  </div>
                </div>
              ) : null}
            </div>
            <div style={{ width: "100%" }}>{menuAndSearch()}</div>
          </div>
        ) : (
          <React.Fragment>
            <Wheel
              items={dataArray}
              state={state}
              setState={setState}
              shift={shift}
              setShift={setShift}
            />
            {menuAndSearch()}

            {/* Focus categories */}
            <Categories
              items={dataArray[state.activeWheelItem].categories}
              state={state}
              setState={setState}
              shift={shift}
            />
          </React.Fragment>
        )}
        {/* 
        <button 
          className="modeBtn" 
          onClick={() => setCompactMode(!compactMode)} 
          style={ 
            compactMode && window.screen.width < 420 
              ? { marginTop: "-70px" } 
              : {} 
          } 
        > 
          {!compactMode ? <FlipToFrontIcon /> : <FlipToBackIcon />} 
        </button> */}
        {/* Active category components */}
        {/* eslint-disable-next-line */}

        {state.activeCategory !== null &&
        state.activeSubCategory === null &&
        !showCalendar ? (
          dataArray[state.activeWheelItem].categories[
            state.activeCategory
          ].components.map((item, id) => {
            switch (item) {
              //Health
              case "VaccinationsTable":
                return (
                  <React.Fragment key={id}>
                    <VaccinationsTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                    />
                    <VaccinationsHorisontalTimeLineChart
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      apiRequest={getVaccinationExpirationsDates}
                    />
                  </React.Fragment>
                );
              case "DentistTable":
                return (
                  <DentistTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CovidDiseaseTable":
                return (
                  <CovidDiseaseTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "TeethHistoryTable":
                return (
                  <TeethHistoryTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "TeethHistoryChart":
                // return <TeethHistoryChart target_hub_id={target_hub_id} key={id} />;
                break;
              case "SicknessHistoryTable":
                return (
                  <SicknessHistoryTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SleepTable":
                return (
                  <SleepTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "InjuriesHistoryTable":
                return (
                  <InjuriesHistoryTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "AllergiesTable":
                return (
                  <React.Fragment key={id}>
                    <AllergiesTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                    />
                    <AllergiesToTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                    />
                    <AllergiesTimeLineGraph
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      apiRequest={getAllergiesChartData}
                    />
                  </React.Fragment>
                );
              case "FitnessTable":
                return (
                  <React.Fragment key={id}>
                    <FitnessTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                    />
                    <FitnessTimeLineGraph
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      apiRequest={getFitnessGraph}
                    />
                  </React.Fragment>
                );
              case "PreventionTable":
                return (
                  <PreventionTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SmokingTable":
                return (
                  <SmokingTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "MedicationsTable":
                return (
                  <MedicationsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );

              //Finances
              case "ElectronicsTable":
                return (
                  <ElectronicsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "BudgetTable":
                return (
                  <BudgetTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CarsTable":
                return (
                  <CarsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "LoansDebtsTable":
                return (
                  <LoansDebtsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "FurnitureTable":
                return (
                  <FurnitureTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "HouseTable":
                return (
                  <HouseTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SavingsTable":
                return (
                  <SavingsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "ReceiptsTable":
                return (
                  <ReceiptsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              //Work
              case "SalaryTable":
                return (
                  <SalaryTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CareerTable":
                return (
                  <CareerTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CertificatesTable":
                return (
                  <CertificatesTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CVsTable":
                return (
                  <CurriculumVitaeTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "JobApplicationsTable":
                return (
                  <JobApplicationsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );

              //Leisure
              case "BooksTable":
                return (
                  <BooksTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CollectionsTable":
                return (
                  <CollectionsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "FilmsTable":
                return (
                  <FilmsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "TravelTable":
                return (
                  <TravelTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              //Soul
              case "ReligionTable":
                return (
                  <ReligionTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "HappinessTable":
                return (
                  <HappinessTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "DiaryTable":
                return (
                  <DiaryTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CharityTable":
                return (
                  <CharityTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SexTable":
                return (
                  <SexTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "GoalsTable":
                return (
                  <GoalsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "BucketListTable":
                return (
                  <BucketListTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "RomanceTable":
                return (
                  <RomanceTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              //Education
              case "DegreesDiplomasTable":
                return (
                  <DegreesDiplomasTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "ExpertiseTable":
                return (
                  <ExpertiseTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "GradesTable":
                return (
                  <GradesTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SchoolsTable":
                return (
                  <SchoolsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "SkillsTable":
                return (
                  <SkillsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "TalentsTable":
                return (
                  <TalentsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "ToLearnTable":
                return (
                  <ToLearnTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );

              //FriendsFamily
              case "ContactsTable":
                return (
                  <ContactsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CovidContactsTable":
                return (
                  <CovidContactsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "FamilyTreeTable":
                return (
                  <FamilyTreeTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "FriendsTable":
                return (
                  <FriendsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "InteractionsTable":
                return (
                  <InteractionsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "KidsTable":
                return (
                  <KidsTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "MyPhotoalbumTable":
                return (
                  <MyPhotoalbumTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    targetHubIpAddress={targetHubIpAddress}
                    key={id}
                  />
                );
              case "PhotosByMeTable":
                return (
                  <PhotosByMeTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    targetHubIpAddress={targetHubIpAddress}
                    key={id}
                  />
                );

              //Various
              case "Complaints":
                return (
                  <Complaints
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "Political":
                return (
                  <Political
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "PublicEngagements":
                return (
                  <PublicEngagements
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "Ratings":
                return (
                  <Ratings
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "CovidRatings":
                return (
                  <CovidRatings
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "Settings":
                return (
                  <Settings
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );
              case "ShopNavigationTable":
                return (
                  <ShopNavigationTable
                    target_hub_id={target_hub_id}
                    requestorHubID={requestorHubID}
                    loggedInAs={loggedInAs}
                    key={id}
                  />
                );

              default:
                return null;
            }
            return null;
          })
        ) : state.activeSubCategory !== null &&
          dataArray[state.activeWheelItem].categories[state.activeCategory]
            .subCategories.length !== 0 ? (
          dataArray[state.activeWheelItem].categories[
            state.activeCategory
          ].subCategories[state.activeSubCategory].components.map(
            (item, id) => {
              switch (item) {
                case "WeightTable":
                  return (
                    <WeightTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "HeightTable":
                  return (
                    <HeightTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "DayNoteTable":
                  return (
                    <DayNoteTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "NutritionTable":
                  return (
                    <React.Fragment key={id}>
                      <NutritionTable
                        target_hub_id={target_hub_id}
                        requestorHubID={requestorHubID}
                        loggedInAs={loggedInAs}
                      />
                      <NutritionBarGraph
                        target_hub_id={target_hub_id}
                        requestorHubID={requestorHubID}
                        apiRequest={getNutritionList}
                      />
                    </React.Fragment>
                  );
                case "BookApp":
                  return <BookApp key={id} loggedInAs={loggedInAs} />;
                case "ShopNavigation":
                  return <ShopNavigation key={id} loggedInAs={loggedInAs} />;
                case "ReceiptsApp":
                  return <ReceiptsApp key={id} loggedInAs={loggedInAs} />;
                case "SportsTable":
                  return (
                    <SportsTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "VacationsTable":
                  return (
                    <VacationsTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "RelaxationTable":
                  return (
                    <RelaxationTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                case "BloodPressureTable":
                  return (
                    <BloodPressureTable
                      target_hub_id={target_hub_id}
                      requestorHubID={requestorHubID}
                      loggedInAs={loggedInAs}
                      key={id}
                    />
                  );
                default:
                  return null;
              }
            }
          )
        ) : showCalendar ? (
          <Calendar
            target_hub_id={target_hub_id}
            requestorHubID={requestorHubID}
          />
        ) : null}
        {state.activeWheelItem === 0 &&
        state.activeCategory === null &&
        state.activeSubCategory === null &&
        !showCalendar ? (
          <Covid19
            target_hub_id={target_hub_id}
            requestorHubID={requestorHubID}
            loggedInAs={loggedInAs}
            setState={setState}
          />
        ) : null}
        <Modal
          isOpen={logoutModal}
          contentLabel="Logout"
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: "10",
            },
            content: {
              background: "rgba(255,255,255,1)",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
              maxWidth: "380px",
              minWidth: "300px",
              height: "fit-content",
              maxHeight: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              fontFamily: "Roboto",
            },
          }}
        >
          <div className="logout_modal_title">
            {intl.formatMessage({
              id: "logout_back_to_login_btn",
            })}
          </div>
          <div className="logout_modal_content">
            <div className="logout_modal_content_title">
              {intl.formatMessage({
                id: "logout_warning_message_pt1",
              })}
            </div>
            <div className="logout_modal_content_text">
              <p>
                {intl.formatMessage({
                  id: "logout_warning_message_pt2",
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: "logout_warning_message_pt3",
                })}
              </p>
            </div>
            <div className="logout_modal_content_bottom_container">
              <div className="logout_modal_content_bottom_item">
                {intl.formatMessage({
                  id: "logout_HUB_ID",
                })}{" "}
                {JSON.parse(sessionStorage.getItem("hub_token"))
                  .userData.my_hub_id.slice(-5)
                  .replace("_", "")}
              </div>
              <div className="logout_modal_content_bottom_item">
                {intl.formatMessage({
                  id: "logout_PIN",
                })}{" "}
                {
                  JSON.parse(sessionStorage.getItem("hub_token")).userData
                    .temp_pin
                }
              </div>
              <div className="logout_modal_content_bottom_item">
                <button
                  className="btn_white"
                  onClick={() => setLogoutState(true)}
                >
                  {intl.formatMessage({
                    id: "logout_back_to_login_btn",
                  })}
                </button>
                {logout()}
              </div>
              <div className="logout_modal_content_bottom_item">
                <button
                  className="btn_blue"
                  onClick={() => setLogoutModal(false)}
                >
                  {intl.formatMessage({
                    id: "logout_back_to_hub_btn",
                  })}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {!loggedInAs ? (
          <div style={{ margin: "10%", position: "relative", zIndex: "20" }}>
            <h3>
              {intl.formatMessage({
                id: "hubsite_index_you_arent_logged_in",
              })}
            </h3>
            <h5>
              {intl.formatMessage({
                id: "hubsite_index_log_in",
              })}{" "}
              <Link
                to={{
                  pathname: "/login",
                  state: {
                    fromHubsite: props.match.params.hub_id,
                  },
                }}
              >
                {intl.formatMessage({
                  id: "hubsite_index_here",
                })}
              </Link>
              ,
            </h5>
            <h5>
              {intl.formatMessage({
                id: "hubsite_index_or_create_new_hub",
              })}{" "}
              <Link to="/login?register=new">
                {intl.formatMessage({
                  id: "hubsite_index_here",
                })}
              </Link>
              .
            </h5>
          </div>
        ) : null}
      </div>
      {unseen_Receipts()}
    </>
  );
};

export default HUBsite;
