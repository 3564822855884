import React, { useState, useEffect } from "react";
import { getActiveDayNote } from "../../../services/GetHUBsiteData";
import { getActiveMinuteSettings } from "../../../services/GetActiveMinuteData";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "../styles/notes.css";
// import Rating from "react-rating";
import { postActiveMinuteDayNote } from "../../../services/PostActiveMinuteData";
import {
  putActiveMinuteDayNote,
  putActiveMinuteSettings,
} from "../../../services/PutActiveMinuteData";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// import EventIcon from "@material-ui/icons/Event";
// import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { injectIntl } from "react-intl";

function ActiveDayNoteList({ handleSubmitIconChange, item, intl }) {
  const prepareDate = (param) => {
    return {
      entry_id: null,
      entry_type: "day_note",
      personal_note: null,
      date_on:
        param === "left"
          ? moment().subtract(1, "days").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
    };
  };

  const [notesData, setNotesData] = useState([]);
  // const [resultData, setResultData] = useState([]);
  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");
  const [settingsID, setSettingsID] = useState(0);
  const [clickedNote, setClickedNote] = useState(null);
  const [alignment, setAlignment] = useState("right");
  const [version, setVersion] = useState("basic");
  const [searchValue, setSearchValue] = useState("");
  // const [custom, setCustom] = useState(false);
  const [customDate, setCustomDate] = useState(moment().format("YYYY-MM-DD"));
  const [modalState, setModalState] = useState(false);
  const [activeNote, setActiveNote] = useState(prepareDate(alignment));
  const useStyles = makeStyles((theme) => ({
    disabled: {
      color: "grey !important",
    },
  }));
  const classes = useStyles();
  // const [success, setSuccess] = useState(false);
  // const [prevNote, setPrevNote] = useState(null);
  // const [prevDate, setPrevDate] = useState(null);
  // const [showSaveBtn, setShowSaveBtn] = useState(false);
  // const [btnRotate, setBtnRotate] = useState(false);

  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", ""),
  };
  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  // useEffect(() => {
  //   if (
  //     (activeNote.personal_note !== prevNote &&
  //       activeNote.personal_note !== "" &&
  //       activeNote.personal_note !== null) ||
  //     (prevDate !== activeNote.date_on && prevDate !== null) ||
  //     success
  //   ) {
  //     setShowSaveBtn(true);
  //   } else {
  //     setShowSaveBtn(false);
  //   }
  //   // eslint-disable-next-line
  // }, [activeNote]);

  const getApiData = () => {
    // setPrevDate(null);
    setActiveNote(prepareDate(alignment));
    getActiveMinuteSettings(send_credential_to_registrar).then((result) => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach((item) => {
          if (Number(item.value2) === 1006) {
            if (item.value === "yesterday") {
              setAlignment("left");
              setActiveNote(prepareDate("left"));
            }
            setSettingsID(item.id);
          }
        });
        get_active_day_note();
      }
    });
  };

  const updateSettingsInApi = (newAlignment) => {
    let send_credential_to_registrar = {
      target_hub_id: hubid,
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    let value = "today";
    if (newAlignment === "left") {
      value = "yesterday";
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.value = value;
    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const get_active_day_note = (param) => {
    if (param !== null) {
      send_credential_to_registrar.search_string = param;
      send_credential_to_registrar.limit_var = "LIMIT 4";
    }
    getActiveDayNote(send_credential_to_registrar).then((result) => {
      if (result.data.data_array) {
        setNotesData(result.data.data_array);
      } else {
        console.log(result.data.error);
      }
    });
  };

  useEffect(() => {
    get_active_day_note(searchValue); // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    getApiData();
    setVersion("basic");
    // eslint-disable-next-line
  }, []);

  const sendValueToApi = () => {
    send_credential_to_registrar.data = {
      personal_note: activeNote.personal_note,
      id: activeNote.entry_id,
      date_on: activeNote.date_on,
      entry_type: activeNote.entry_type,
    };

    // setPrevNote(activeNote.personal_note);

    if (
      activeNote.entry_id === null &&
      activeNote.personal_note !== "" &&
      activeNote.personal_note !== null
    ) {
      // if (alignment === "right") {
      //   send_credential_to_registrar.data.date_on = moment().format(
      //     "YYYY-MM-DDTHH:mm:ss"
      //   );
      // } else if (alignment === "left") {
      //   send_credential_to_registrar.data.date_on = moment()
      //     .subtract(1, "days")
      //     .format("YYYY-MM-DDTHH:mm:ss");
      // } else {
      //   send_credential_to_registrar.data.date_on = moment(customDate).format(
      //     "YYYY-MM-DDTHH:mm:ss"
      //   );
      // }
      postActiveMinuteDayNote(send_credential_to_registrar).then((result) => {
        if (result.data.success) {
          // setNewNote({ ...newNote, entry_id: result.data.insert_id });
          console.log(result.data.success);
          handleSubmitIconChange(item);
          getApiData();
        } else {
          console.log(result.data.error);
        }
      });
    } else {
      putActiveMinuteDayNote(send_credential_to_registrar).then((result) => {
        if (result.data.success) {
          console.log(result.data.success);
          handleSubmitIconChange(item);
          getApiData();
        } else {
          // console.log(result.data.error);
        }
      });
    }
    setModalState(false);
    setActiveNote(prepareDate(alignment));
    // setBtnRotate(true);
    // setSuccess(true);
  };

  const handleVersion = (event, newVersion) => {
    // setVersion(newVersion);
  };

  const getDate = (value) => {
    switch (value) {
      case "left":
        return moment().subtract(1, "days").format("YYYY-MM-DD");
      case "right":
        return moment().format("YYYY-MM-DD");
      case "custom":
        return moment(customDate).format("YYYY-MM-DD");
      default:
        return null;
    }
  };

  const handleAlignment = (event, newAlignment) => {
    if (alignment === "custom" && newAlignment === null) {
      setAlignment(newAlignment);
      // setCustom(true);
    } else {
      if (newAlignment !== null) {
        // setCustom(false);
        setActiveNote({ ...activeNote, date_on: getDate(newAlignment) });
        updateSettingsInApi(newAlignment);
        setAlignment(newAlignment);
      }
    }
  };

  const renderList = () => {
    switch (version) {
      case "basic":
        return renderBasicList();
      default:
        return renderBasicList();
    }
  };

  const handleNoteClick = (id, note) => {
    setModalState(true);
    setActiveNote({ ...activeNote, ...note });
    if (clickedNote === id) {
      setClickedNote(null);
    } else {
      setClickedNote(id);
    }
  };

  const renderDateInput = () => {
    // console.log(customDate);
    return (
      <input
        type="date"
        value={customDate}
        onChange={(event) => setCustomDate(event.target.value)}
        className="custom_date_input"
      />
    );
  };

  const handleSave = () => {
    sendValueToApi();
  };

  const handleModalClose = () => {
    setModalState(false);
    setActiveNote(prepareDate(alignment));
  };

  const renderModal = () => {
    return (
      <Modal open={modalState} onClose={() => handleModalClose()}>
        <div className="notes_modal_containter">
          <CloseIcon
            style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={() => handleModalClose()}
          />
          <div className="notes_modal_title">Day Note</div>
          <div className="notes_modal_item">
            <div>Date</div>
            <input
              type="date"
              value={moment(activeNote.date_on).format("YYYY-MM-DD")}
              onChange={(event) =>
                setActiveNote({
                  ...activeNote,
                  date_on: event.target.value,
                })
              }
            />
          </div>
          <div className="notes_modal_item">
            <div>Note</div>
            <textarea
              type="text"
              rows={6}
              value={
                activeNote.personal_note !== null
                  ? activeNote.personal_note
                  : ""
              }
              onChange={(event) =>
                setActiveNote({
                  ...activeNote,
                  personal_note: event.target.value,
                })
              }
            />
          </div>
          <button onClick={() => handleSave()}>Save</button>
        </div>
      </Modal>
    );
  };

  const renderBasicList = () => {
    return (
      <div className="notes_advanced_container">
        <div className="notes_advanced_left">
          <div className="notes_advanced_left_header">
            <input
              className={
                "day_note_search_field " + (searchValue !== "" ? "blue" : "")
              }
              type="text"
              value={searchValue}
              placeholder="Search"
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
            />
            {searchValue !== "" ? (
              <CloseIcon onClick={() => setSearchValue("")} />
            ) : (
              <SearchIcon
                style={{ top: "2px", right: "2px" }}
                fontSize="small"
              />
            )}
          </div>
          <div className="notes_advanced_right">
            <div className="notes_advanced_new_note_input">
              {moment(activeNote.date_on).format("dd, DD MMM YYYY")}
            </div>
            <div
              className="notes_advanced_new_note_textArea"
              onClick={() => setModalState(true)}
            ></div>
          </div>
          <div className="notes_advanced_left_list">
            {notesData.map((note, i) => {
              if (i < 3) {
                return (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                      // alignItems: "center",
                    }}
                  >
                    <span>
                      {moment(note.date_on).format("dd, DD MMM YYYY")}
                    </span>
                    <div
                      style={{
                        border: "1px solid #CFD7DE",
                        borderRadius: "7px",
                        padding: "0 0 0 5px",
                        height: "50px",
                        overflow: "auto",
                      }}
                      onClick={() => handleNoteClick(i, note)}
                    >
                      {note.personal_note}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="notes_container">
      <div className="notes_title_container">
        <div className="notes_title_left">
          <ToggleButtonGroup
            value={version}
            exclusive
            onChange={handleVersion}
            aria-label="text alignment"
          >
            <ToggleButton value="basic" aria-label="left aligned">
              {intl.formatMessage({
                id: "active_minute_basic",
              })}
            </ToggleButton>
            <ToggleButton
              className={classes.disabled}
              disabled
              value="advanced"
              aria-label="center aligned"
            >
              {intl.formatMessage({
                id: "active_minute_advanced",
              })}
            </ToggleButton>
            <ToggleButton
              className={classes.disabled}
              disabled
              value="pro"
              aria-label="right aligned"
            >
              {intl.formatMessage({
                id: "active_minute_pro",
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="notes_title_right">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="left" aria-label="left aligned">
              {intl.formatMessage({
                id: "active_minute_yesterday",
              })}
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              {intl.formatMessage({
                id: "active_minute_today",
              })}
            </ToggleButton>
            {/* <ToggleButton value="custom">
              <EventIcon style={custom ? { color: "#3FA5FF" } : {}} />
            </ToggleButton> */}
          </ToggleButtonGroup>
          {alignment === "custom" ? renderDateInput() : null}
        </div>
      </div>
      <div className="notes_list_container">{renderList()}</div>
      {renderModal()}
      {/* <div className="notes_submit_container">
        {showSaveBtn === true && !modalState ? (
          <button
            className={btnRotate ? "rotate" : ""}
            onClick={success ? null : () => sendValueToApi()}
          >
            {success ? <DoneOutlineIcon /> : "Save"}
          </button>
        ) : null}
      </div> */}
    </div>
  );
}
export default injectIntl(ActiveDayNoteList);
