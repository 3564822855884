import { URLconst } from "./const";
import axios from "axios";

// app defaults/presets/etc...

export function getCovidStatistics() {
  let BaseURL = URLconst("personal_hub/covid_statistics.php");

  return axios.get(BaseURL, {});
}

export function getHubsiteSqlUpdate() {
  let BaseURL = URLconst("personal_hub/upgrade_db.php");

  return axios.get(BaseURL, {});
}

export function getUpdateCheck(param) {
  let BaseURL = URLconst("personal_hub/upgrade_db_check.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
