import { URLconst } from "./const";
import axios from "axios";

export function getActiveMinuteSettings(param) {
  let BaseURL = URLconst("hubsite/settings/active_minute_menu.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getActiveMinuteNutritionList(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getActiveMinuteWeightList(param) {
  let BaseURL = URLconst("hubsite/health/weight.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getActiveMinuteHappinessList(param) {
  let BaseURL = URLconst("hubsite/active_minute/happiness.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSleepGraph(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep_graph_active_minute.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getSummary(param) {
  let BaseURL = URLconst("hubsite/soul/summaries.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getActivities(param) {
  let BaseURL = URLconst("hubsite/active_minute/activities.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getCalendarActivities(param) {
  let BaseURL = URLconst("hubsite/calendar/activities.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getInjuriesAndIssues(param) {
  let BaseURL = URLconst("hubsite/active_minute/injuries_and_issues.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getActiveMinuteStepsList(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_graph.php");

  return axios.get(BaseURL, {
    params: param,
  });
}

export function getBloodPressureList(param) {
  let BaseURL = URLconst("hubsite/health/blood_pressure.php");

  return axios.get(BaseURL, {
    params: param,
  });
}
