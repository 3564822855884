import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const loader = () => {
    return <div className="loader">Loading Data</div>;
};

const TimeLineChart = ({ apiData }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(apiData); // eslint-disable-next-line
    }, [apiData.length]);

    const formatData = dataToFormat => {
        let resultData = [
            [
                { type: "string", id: "Name" },
                { type: "string", id: "Name" },
                { type: "date", id: "Start" },
                { type: "date", id: "End" }
            ]
        ];

        dataToFormat.map(item => {
            let start = new Date(item.start_date.replace(' ', 'T'));
            let end = null;
            if (item.end_date !== null) {
                end = new Date(item.end_date.replace(' ', 'T'));
            } else {
                end = new Date();
            }
            return resultData.push([item.category, item.title, start, end]);
        });
        
        return resultData;
    };
    if (apiData.length !== 0) {
        return (
            <div className="main_chart_block">
                <Chart
                    width={"300%"}
                    height={"1000px"}
                    chartType="Timeline"
                    loader={loader()}
                    data={formatData(data)}
                    options={{
                        timeline: {
                            colorByRowLabel: true
                        },
                        
                    }}
                />
            </div>
        );
    } else {
        return null
    }
};
export default TimeLineChart;
