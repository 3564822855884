import React, { useState, useRef, useEffect } from "react";
import "./AdminPage.css";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { postMail } from "../../services/SendMail";
import {
  postAdminPassword,
  postBasics,
  putBasics
} from "../../services/setAdminPassword";
import { getProfileBasics } from "../../services/getProfileBasics";
import { Redirect } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

var passwordValidator = require("password-validator");

var schema_length = new passwordValidator();
var schema_upper = new passwordValidator();
var schema_lower = new passwordValidator();
var schema_digit = new passwordValidator();
var schema_symbol = new passwordValidator();
var schema_spaces = new passwordValidator();
//has to be 4 digits and no spaces
schema_length
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100); // Maximum length 100
schema_upper.has().uppercase(); // Must have Uppercase
schema_lower.has().lowercase(); // Must have Uppercase
schema_digit.has().digits(); // Must have digits
schema_symbol.has().symbols(); // Must have symbol
schema_spaces
  .has()
  .not()
  .spaces(); // Should not have spaces

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function AdminPage() {
  const intl = useIntl();
  const classes = useStyles();
  const validation_ref = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [saveEmail, setSaveEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [receivedPin, setReceivedPin] = useState("");
  const [generatedPin] = useState(
    Math.floor(Math.random() * (999 - 100 + 1) + 100)
  );
  const [redirected, setRedirected] = useState(false);
  const [redirLogin, setRedirLogin] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationResult, setValidationResult] = useState({
    length: false,
    upper: false,
    lower: false,
    digit: false,
    symbol: false,
    spaces: true
  });
  const [userBasics, setUserBasics] = useState({ id: null, email: null });

  const redirect = () => {
    if (redirected === true) {
      return <Redirect to="/HUBsite" />;
    }
    if (redirLogin === true) {
      sessionStorage.removeItem("hub_token");
      return <Redirect to="/login" />;
    }
  };

  useEffect(() => {
    if (
      JSON.parse(sessionStorage.getItem("hub_token")).userData
        .AdminPwdChangedByUser === "1"
    ) {
      setRedirLogin(true);
    }
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token") !== null) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }
    getProfileBasics(send_credential_to_registrar).then(result => {
      if (result.data.userData) {
        setEmail(result.data.userData.email);
        setUserBasics(result.data.userData);
      }
    });
  }, []);

  const getSteps = () => {
    return [
      intl.formatMessage({
        id: "admin_page_insert_email"
      }),
      intl.formatMessage({
        id: "admin_page_confirm_code"
      }),
      intl.formatMessage({
        id: "admin_page_set_pass"
      }),
      intl.formatMessage({
        id: "admin_page_done"
      })
    ];
  };
  const steps = getSteps();
  useEffect(() => {
    validation_ref.current = validationResult;
  });

  useEffect(() => {
    var keys = Object.keys(validation_ref.current);
    var obj = {};
    keys.forEach(e => {
      switch (e) {
        case "length":
          if (schema_length.validate(password)) {
            obj.length = true;
          } else {
            obj.length = false;
          }
          break;
        case "upper":
          if (schema_upper.validate(password)) {
            obj.upper = true;
          } else {
            obj.upper = false;
          }
          break;
        case "lower":
          if (schema_lower.validate(password)) {
            obj.lower = true;
          } else {
            obj.lower = false;
          }
          break;
        case "digit":
          if (schema_digit.validate(password)) {
            obj.digit = true;
          } else {
            obj.digit = false;
          }
          break;
        case "symbol":
          if (schema_symbol.validate(password)) {
            obj.symbol = true;
          } else {
            obj.symbol = false;
          }
          break;
        case "spaces":
          if (schema_spaces.validate(password)) {
            obj.spaces = true;
          } else {
            obj.spaces = false;
          }
          break;
        default:
      }
    });
    setValidationResult(obj);
  }, [password]);

  useEffect(() => {
    if (Object.values(validationResult).includes(false)) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  }, [validationResult]);

  const send_mail = () => {
    if (email !== "") {
      let body;
      if (activeStep === 2) {
        body = {
          email_address: email,
          subject: intl.formatMessage({
            id: "admin_page_email_pass_subject"
          }),
          mail_body:
            intl.formatMessage({
              id: "admin_page_email_pass_body_pt1"
            }) +
            password +
            intl.formatMessage({
              id: "admin_page_email_pass_body_pt2"
            })
        };
      } else {
        body = {
          email_address: email,
          subject: intl.formatMessage({
            id: "admin_page_email_otc_subject"
          }),
          mail_body:
            intl.formatMessage({
              id: "admin_page_email_otc_body_pt1"
            }) +
            generatedPin +
            intl.formatMessage({
              id: "admin_page_email_otc_body_pt2"
            })
        };
      }

      postMail(body).then(result => {
        if (result.data.error) {
          console.log(result.data.error);
        } else {
          if (
            email !== "" &&
            saveEmail &&
            email !== userBasics.email &&
            activeStep === 0
          ) {
            let body = JSON.parse(sessionStorage.getItem("hub_token")).userData;
            body.data = {
              id: userBasics.id
            };
            body.data.email = email;
            body.data.nickname = email.split("@")[0];

            if (userBasics.id === null) {
              postBasics(body).then(result => {
                if (result.data.error) {
                  console.log(result.data.error);
                } else {
                  // console.log(result.data.success);
                }
              });
            } else {
              putBasics(body).then(result => {
                if (result.data.error) {
                  console.log(result.data.error);
                } else {
                  // console.log(result.data.success);
                }
              });
            }
          }
          if (activeStep === 0 && email !== "") {
            handleNext();
          }
        }
      });
    }
  };

  const send_admin_password = () => {
    if (email !== "") {
      let body = JSON.parse(sessionStorage.getItem("hub_token")).userData;
      body.admin_password = password;

      postAdminPassword(body).then(result => {
        if (result.data.error) {
          console.log(result.data.error);
        } else {
          // console.log(result.data.success);
          send_mail();
          handleNext();
        }
      });
    }
  };

  const verify_pin = () => {
    if (Number(receivedPin) === Number(generatedPin)) {
      setActiveStep(2);
    } else {
      console.log("wrong pin");
    }
  };

  const login_as_admin = () => {
    let hub_token = JSON.parse(sessionStorage.getItem("hub_token"));
    hub_token.userData.db_username = hub_token.userData.my_hub_id + "_admin";
    hub_token.userData.db_pwd = password;
    hub_token.userData.AdminPwdChangedByUser = "1";
    sessionStorage.setItem("hub_token", JSON.stringify(hub_token));
    setRedirected(true);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="email_input_wrapper">
              <div className="input_title">
                {intl.formatMessage({
                  id: "admin_page_email"
                })}
              </div>
              <input
                type="text"
                onChange={event => {
                  setEmail(event.target.value);
                }}
                value={email}
                placeholder={intl.formatMessage({
                  id: "admin_page_email"
                })}
                className="admin_input"
              />
            </div>
            <div style={{ padding: "20px 20px 0 20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveEmail}
                    onChange={event => {
                      setSaveEmail(event.target.checked);
                    }}
                    value="save_email"
                    color="primary"
                  />
                }
                label="Save mail in my HUB"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="admin_stepper_text">
              <FormattedMessage id="admin_stepper_send_code" />
            </div>
            <div className="input_title">
              <FormattedMessage id="admin_stepper_confirm_code" />
            </div>
            <input
              type="text"
              onChange={event => {
                setReceivedPin(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: "admin_page_confirm_code"
              })}
              className="admin_input"
            />
          </div>
        );
      case 2:
        return (
          <div>
            <div className="admin_stepper_text">
              <span style={validationResult.length ? { color: "green" } : null}>
                <FormattedMessage id="admin_stepper_validation_8ch" />
              </span>
              ,{" "}
              <span style={validationResult.upper ? { color: "green" } : null}>
                <FormattedMessage id="admin_stepper_validation_1cap" />
              </span>
              ,{" "}
              <span style={validationResult.lower ? { color: "green" } : null}>
                <FormattedMessage id="admin_stepper_validation_1sm" />
              </span>
              ,{" "}
              <span style={validationResult.digit ? { color: "green" } : null}>
                <FormattedMessage id="admin_stepper_validation_1num" />
              </span>
              ,{" "}
              <span style={validationResult.symbol ? { color: "green" } : null}>
                <FormattedMessage id="admin_stepper_validation_1sp" />
              </span>
              {validationResult.spaces ? null : (
                <span style={{ color: "red" }}>
                  <FormattedMessage id="admin_stepper_validation_no_spaces" />
                </span>
              )}
              .
            </div>
            <div className="password_input_container">
              <div className="password_input_wrapper">
                <div className="input_title">
                  <FormattedMessage id="admin_stepper_set_pass" />
                </div>
                <div className="password_field_block">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    onChange={event => {
                      setPassword(event.target.value);
                    }}
                    placeholder={intl.formatMessage({
                      id: "admin_page_admin_pass"
                    })}
                    className="admin_input"
                  />
                  {passwordVisible ? (
                    <VisibilityIcon onClick={() => setPasswordVisible(false)} />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setPasswordVisible(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="admin_stepper_finish_text">
              <FormattedMessage id="admin_stepper_done" />
            </div>
            <div className="admin_stepper_text">
              <FormattedMessage id="admin_stepper_final_text" />
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const checkStep = () => {
    switch (activeStep) {
      case 0:
        send_mail();
        break;
      case 1:
        verify_pin();
        break;
      case 2:
        if (passwordValid) {
          send_admin_password();
        }
        break;
      case 3:
        login_as_admin();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="admin_container">
        <img
          className="login_page_bg"
          src="/assets/images/background_img_hubsite.png"
          alt=""
        />
        {redirect()}
        <div className="admin_block">
          <div className="close_icon" onClick={() => setRedirected(true)}>
            <CloseIcon />
          </div>
          {activeStep === 0 ? (
            <>
              <div className="admin_block_title">
                <b>
                  {intl.formatMessage({
                    id: "admin_page_already_have"
                  })}{" "}
                  <span
                    className="admin_block_link"
                    onClick={() => setRedirLogin(true)}
                  >
                    {intl.formatMessage({
                      id: "admin_page_here"
                    })}
                  </span>
                </b>
              </div>
              <div className="admin_block_subtitle">
                {intl.formatMessage({
                  id: "admin_page_or"
                })}
              </div>
              <h3>
                {intl.formatMessage({
                  id: "admin_page_create_admin_acc"
                })}
              </h3>
            </>
          ) : null}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="stepper_content">
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <Button
              variant="contained"
              onClick={checkStep}
              className="next_btn"
              disabled={!passwordValid && activeStep === 2}
            >
              {activeStep === steps.length - 1
                ? intl.formatMessage({
                    id: "admin_page_continue_as_admin"
                  })
                : intl.formatMessage({
                    id: "admin_page_next"
                  })}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
