import React from "react";
import "./Wheel.scss";
import Swipe from "react-easy-swipe";
import { useIntl } from "react-intl";

const Wheel = ({ items, state, setState, shift, setShift }) => {
  const intl = useIntl();
  const activeImg = state.activeWheelItem;
  const opacity = 0.3;
  const moveArr = [
    {
      x: 0,
      y: 0
    },
    {
      x: 110,
      y: -15
    },
    {
      x: 180,
      y: -50
    },
    {
      x: 110,
      y: -110
    },
    {
      x: 40,
      y: -140
    },
    {
      x: -20,
      y: -140
    },
    {
      x: -90,
      y: -110
    },
    {
      x: -160,
      y: -50
    },
    {
      x: -90,
      y: -15
    }
  ];

  const checkPos = id => {
    let x = 0;
    let y = 0;
    if (id - activeImg >= 0) {
      x = moveArr[id - activeImg].x;
      y = moveArr[id - activeImg].y;
    } else {
      x = moveArr[items.length + id - activeImg].x;
      y = moveArr[items.length + id - activeImg].y;
    }
    return { x: x, y: y };
  };

  const checkScale = id => {
    let scale = 1;
    if (Math.abs(id - activeImg) >= 4 && Math.abs(id - activeImg) <= 5) {
      scale = 0.6;
    } else if (Math.abs(id - activeImg) >= 2 && Math.abs(id - activeImg) <= 7) {
      scale = 0.6;
    } else if (
      Math.abs(id - activeImg) === 1 ||
      Math.abs(id - activeImg) === 8
    ) {
      scale = 0.8;
    }
    return scale;
  };
  const handleCenterClick = () => {
    if (shift === 0) {
      setShift(100);
    } else {
      setShift(0);
    }
  };

  const leftSwipe = () => {
    if (state.activeWheelItem < 7) {
      setState({
        ...state,
        activeWheelItem: state.activeWheelItem + 1,
        activeCategory: null
      });
    } else {
      setState({ ...state, activeWheelItem: 0, activeCategory: null });
    }
  };

  const rightSwipe = () => {
    if (state.activeWheelItem > 0) {
      setState({
        ...state,
        activeWheelItem: state.activeWheelItem - 1,
        activeCategory: null
      });
    } else {
      setState({ ...state, activeWheelItem: 7, activeCategory: null });
    }
  };

  return (
    <div className="wheel_block">
      <div className="wheel_center" onClick={() => handleCenterClick()}>
        <img
          alt="arrow"
          className="img"
          style={{ transform: `rotate(${shift !== 0 ? "0.5turn" : "0"})` }}
          src="/assets/images/simple_arrow_black.png"
        />
      </div>
      {items.map((item, id) => {
        return (
          <div
            key={id}
            className="item"
            style={{
              transform: `translate(${checkPos(id).x}px, ${
                checkPos(id).y
              }px) scale(${checkScale(id)})`
            }}
          >
            <Swipe
              // onSwipeDown={() => setShift(100)}
              // onSwipeUp={() => setShift(0)}
              onSwipeLeft={() => leftSwipe()}
              onSwipeRight={() => rightSwipe()}
            >
              <img
                onClick={() =>
                  setState({
                    ...state,
                    activeWheelItem: id,
                    activeCategory: null,
                    activeSubCategory: null
                  })
                }
                src={item.src}
                alt=""
                style={
                  id === activeImg
                    ? {
                        border: "2px solid #3FE0FF",
                        opacity: 1,
                        width: "100px",
                        height: "100px"
                      }
                    : shift === 0
                    ? id < activeImg
                      ? {
                          opacity: `${opacity}`
                        }
                      : {
                          opacity: `${opacity}`
                        }
                    : null
                }
              />
            </Swipe>
            {activeImg === id || shift !== 0 ? (
              <div
                className="title"
                style={activeImg !== id ? { margin: "0 auto" } : null}
              >
                {item.title !== "" &&
                item.title !== null &&
                item.title !== undefined
                  ? intl.formatMessage({
                      id: item.title
                    })
                  : item.title}
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default Wheel;
