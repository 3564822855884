import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
// // import ShareDataModal from "../../Modals/ShareDataModal/ShareDataDodal";
import AddItemModal from "../../Modals/AddItemModal/AddItemModal";
import "./Table.css";
// import Details from "../Details/Details";
import { getBucketList } from "../../../../services/GetHUBsiteData";
import { postBucketList } from "../../../../services/PostHUBsiteData";

import {
  getColumnsAndRowsPermissionsAdmin,
  getColumnsNames
} from "../../../../services/GetHUBsiteData";
import { postPermissions } from "../../../../services/Permissions";
import Permissions from "../../../../components/HUBsite/Permissions";

const useStyles = makeStyles({
  root: {
    width: "95%",
    margin: "0px auto",
    position: "relative",
    boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)",
    overflowX: "scroll"
  },
  header: {
    width: "95%",
    margin: "20px auto 20px auto",
    display: "flex",
    justifyContent: "space-between"
  },

  title: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px"
  },
  filter: {
    minWidth: "80px"
  },
  filter_btn: {
    border: "1px solid #FFFFFF",
    borderRadius: "4px",
    backgroundColor: "#3FA5FF",
    boxShadow: "0 2px 2px 0 rgba(25,50,89,0.2)"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(71, 119, 186, 0.07)"
    }
  },
  cell: {
    borderBottom: "none !important",
    position: "relative"
  },
  search: {
    position: "relative",
    border: "1px solid #E4E4E4",
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.14)",
    backgroundColor: "#FFF",
    display: "flex",
    paddingLeft: "10px",
    height: "34px",
    width: "176px"
  },
  searchIcon: {
    padding: "0px 5px"
  },
  titleCellContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const BucketTable = ({ target_hub_id, requestorHubID, loggedInAs }) => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [sortedProp, setSortedProp] = useState();
  const [searchText, setSearchText] = useState("");
  const [sortArrow, setSortArrow] = useState({ col: "", direction: "" });
  const [clickedCell, setClickedCell] = useState({ row: null, item: null });
  // const [details, setDetails] = useState();

  const [columns, setColumns] = useState([{ title: "", field: "" }]);
  const [rowArrows, setRowArrows] = useState(false);
  const [columnArrows, setColumnArrows] = useState(false);
  const [reqHubId, setReqHubId] = useState(requestorHubID);
  const [permFor, setPermFor] = useState("all");
  const [tableName, setTableName] = useState("");
  const [apiParam, setApiParam] = useState("");
  const [apiParamValue, setApiParamValue] = useState("");

  const [errorMsg, setErrorMsg] = useState("Loading");

  const currentSection = "bucket list";

  const get_columns_names = () => {
    let param = { component_name: "BucketTable" };
    param.admin = loggedInAs === "admin" ? true : false;
    getColumnsNames(param).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        let new_arr = [];
        result.data.data_array.forEach(function(item) {
          let temp_obj = {
            title: (
              item.column_name.charAt(0).toUpperCase() +
              item.column_name.slice(1)
            )
              .replace(/_/g, " ")
              .replace(/id/gi, "ID"),
            field: item.column_name,
            type: item.datatype,
            showInModal: item.show_in_modal
          };
          if (item.hardcoded_parameter === "1") {
            setApiParam(item.column_name);
            setApiParamValue(item.parameter_value);
          }
          new_arr.push(temp_obj);
        });
        setColumns(new_arr);
        setTableName(result.data.data_array[0].table_name);
      }
    });
  };

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = reqHubId;
    send_credential_to_registrar.section = currentSection;
    //variables for dynamic created query for setting permissions
    send_credential_to_registrar.component_name = "BucketTable";
    send_credential_to_registrar.where_var = "WHERE item = 'bucket list'";
    send_credential_to_registrar.order_by_var = "ORDER BY name ASC, id DESC";

    loggedInAs === "admin"
      ? getColumnsAndRowsPermissionsAdmin(send_credential_to_registrar).then(
        result => {
          if (result.data.error) {
            setData([]);
            setErrorMsg(false);
            } else {
              setData(result.data.data_array);
              setErrorMsg(false);
            }
          }
        )
      : getBucketList(send_credential_to_registrar).then(result => {
          if (result.data.error) {
            setData([]);
            setErrorMsg(result.data.error);
          } else {
            setData(result.data.data_array);
            setErrorMsg(false);
          }
        });
  };

  const set_permissions = (target, granted, table_name, id_or_name, who) => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    let prem_for_id = who === undefined ? permFor : who;
    send_credential_to_registrar.data = {};
    switch (target) {
      case "table":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          granted: granted
        };
        break;
      case "row":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          row_id: id_or_name,
          granted: granted
        };
        break;
      case "column":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          column_name: id_or_name,
          granted: granted
        };
        break;
      default:
    }

    postPermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        getApiData();
      }
    });
  };

  useEffect(() => {
    get_columns_names();
    getApiData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setResultData(data);
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSortClick = (prop, title) => {
    let sortedData = resultData.sort(function(a, b) {
      if (sortedProp === prop) {
        let c = a;
        a = b;
        b = c;
      }
      if (a[prop] < b[prop] && isNaN(Number(a[prop]))) {
        return -1;
      } else if (Number(a[prop]) < Number(b[prop])) {
        return -1;
      }
      if (a[prop] > b[prop] && isNaN(Number(a[prop]))) {
        return 1;
      } else if (Number(a[prop]) > Number(b[prop])) {
        return 1;
      }
      return 0;
    });
    if (sortedProp === prop) {
      setSortArrow({ col: title.toLowerCase(), direction: "up" });
      setSortedProp();
    } else {
      setSortArrow({ col: title.toLowerCase(), direction: "down" });
      setSortedProp(prop);
    }
    setResultData([...sortedData]);
  };
  
  useEffect(() => {
    let searchedData = [];
    // eslint-disable-next-line
    data.map(item => {
      columns.forEach(col => {
        if (item[col.field] !== null && item[col.field] !== undefined) {
          if (
            item[col.field].toUpperCase().includes(searchText.toUpperCase())
          ) {
            let flag = true;
            searchedData.forEach(element => {
              if (element.id === item.id) {
                flag = false;
              }
            });
            if (flag) {
              searchedData.push(item);
            }
          }
        }
      });
    });
    setResultData(searchedData);
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <>
      {loggedInAs === "admin" ? (
        <Permissions
          table={tableName}
          target_hub_id={target_hub_id}
          rowArrows={rowArrows}
          setRowArrows={setRowArrows}
          columnArrows={columnArrows}
          setColumnArrows={setColumnArrows}
          setReqHubId={setReqHubId}
          getApiData={getApiData}
          setPermFor={setPermFor}
          currentSection={currentSection}
        />
      ) : null}
      <div className={classes.header}>
        <span className={classes.title}>Bucket</span>
        {errorMsg !== false ? null : (
          <Paper className={classes.search}>
            {searchText !== "" ? (
              <IconButton
                className="icon-button"
                aria-label="close"
                onClick={() => {
                  setSearchText("");
                }}
                style={{ marginRight: "5px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </IconButton>
            ) : (
              <></>
            )}
            <InputBase
              className={classes.searchField}
              value={searchText}
              placeholder="Search"
              // placeholder={intl.formatMessage({
              //   id: "virtual_assistant_search"
              // })}
              inputProps={{ "aria-label": "search" }}
              onChange={event => {
                setSearchText(event.target.value);
              }}
            />
            <IconButton
              className={classes.searchIcon}
              aria-label="search"
              // onClick={() => handleSearchClick(searchText)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
      </div>
      {errorMsg !== false ? null : (
        <>
          <Paper className={classes.root}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column, id) => {
                    return (
                      <TableCell
                        key={id}
                        onClick={() => {
                          handleSortClick(column.field, column.title);
                        }}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "160px"
                        }}
                      >
                        <div className={classes.titleCellContent}>
                          <div>{column.title}</div>
                          <div>
                            <img
                              className="sort_arrow"
                              src={
                                sortArrow.col === column.title.toLowerCase()
                                  ? sortArrow.direction === "down"
                                    ? "/assets/images/table/sortArrow/up.png"
                                    : "/assets/images/table/sortArrow/down.png"
                                  : "/assets/images/table/sortArrow/none.png"
                              }
                              alt="Sort arrow"
                            />
                          </div>
                        </div>
                        {columnArrows ? (
                          <img
                            className="permissions_arrow_top"
                            src={
                              resultData.length !== 0
                                ? resultData[0][column.field + "_preview"] !==
                                  "***"
                                  ? "/assets/images/arrow_icon_green.png"
                                  : "/assets/images/arrow_icon_red.png"
                                : null
                            }
                            onClick={event => {
                              set_permissions(
                                "column",
                                resultData[0][column.field + "_preview"] !==
                                  "***"
                                  ? "0"
                                  : "1",
                                tableName,
                                column.field
                              );
                              event.stopPropagation();
                            }}
                            alt="Sort arrow"
                          />
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((row, id) => {
                  function check_if_visible(param) {
                    if (reqHubId === "") {
                      return null;
                    }
                    return row[param] === "***" ||
                      row.Big_overall_granted === "0"
                      ? " hubsite_permissions_cell_invisible"
                      : " hubsite_permissions_cell_visible";
                  }

                  return (
                    <React.Fragment key={id}>
                      <TableRow className={classes.row}>
                        {columns.map((item, i) => {
                          return (
                            <TableCell
                              className={
                                classes.cell +
                                check_if_visible(item.field + "_preview")
                              }
                              key={i}
                              onClick={() =>
                                setClickedCell({ row: id, item: i })
                              }
                              style={
                                i === clickedCell.item && id === clickedCell.row
                                  ? {
                                      minWidth: "300px",
                                      maxHeight: "100px",
                                      textOverflow: "auto"
                                    }
                                  : {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "150px"
                                    }
                              }
                            >
                              {i === 0 ? (
                                rowArrows ? (
                                  <img
                                    className="permissions_arrow"
                                    src={
                                      row.Big_overall_granted === "1"
                                        ? "/assets/images/arrow_icon_green.png"
                                        : "/assets/images/arrow_icon_red.png"
                                    }
                                    onClick={() => {
                                      set_permissions(
                                        "row",
                                        row.Big_overall_granted === "1"
                                          ? "0"
                                          : "1",
                                        tableName,
                                        row.id
                                      );
                                    }}
                                    alt="Sort arrow"
                                  />
                                ) : null
                              ) : null}
                              {row[item.field]}
                            </TableCell>
                          );
                        })}
                        {/* <TableCell className={classes.cell} align="right">
                      {details !== id ? (
                        <ArrowDropDownIcon
                          onClick={() => {
                            if (details !== id) {
                              setDetails(id);
                            }
                          }}
                        />
                      ) : (
                        <ArrowDropUpIcon onClick={() => setDetails(999)} />
                      )}
                    </TableCell> */}
                      </TableRow>
                      {/* {details === id ? (
                    <Details key={id} desease={row.desease} target_hub_id={target_hub_id}/>
                  ) : (
                    <React.Fragment
                      key={id + resultData.length}
                    ></React.Fragment>
                  )} */}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>

          <div className="btn-group">
            {loggedInAs !== "visitor" ? (
              <button className="btn-item" onClick={handleOpen}>
                + Add
            </button>
            ) : null}
            {/* <button className="btn-item">Download</button> */} <div></div>
          </div>
          <AddItemModal
            open={open}
            setOpen={setOpen}
            fields={columns}
            apiRequest={postBucketList}
            target_hub_id={target_hub_id}
            getApiData={getApiData}
            apiParam={apiParam}
            apiParamValue={apiParamValue}
          />
        </>
      )}
      {errorMsg !== false ? (
        <h5 style={{ margin: "20px 20px 50px 20px" }}>{errorMsg}</h5>
      ) : null}
      {/* <ShareDataModal
        open={open}
        handleOpen={handleOpen}
       handleClose={handleClose}
      /> */}
    </>
  );
};

export default BucketTable;
