import React, { useEffect, useState } from 'react'
import './ContactsCards.css'

const ContactCards = ({ apiData }) => {
    const [cards, setCards] = useState([])

    useEffect(() => {
        parseData();
        //eslint-disable-next-line
    }, [apiData.length])

    const parseData = () => {
        let result = [];
        apiData.sort(function (a, b) {
            return new Date(a.value_date) - new Date(b.value_date);
        }).map(item => {
            let flag = true;
            result.map((i, id) => {
                if (i.Person_id === item.Person_id) {
                    flag = false;
                    result[id][item.what] = item.value_text;
                }
                return null;
            })
            if (flag) {
                result.push({ Person_id: item.Person_id, [item.what]: item.value_text, name: item.name })
            }
            return null;
        })
        setCards(result);
    }

    return (
        <div className="contacts_container">
            {cards.map((card, id) => {
                return (
                    <div className="contact_card" key={id}>
                        <div className="contact_title">{card.name}</div>
                        {Object.getOwnPropertyNames(card).map((value, id)=>{
                            if(value !== 'Person_id'){
                                return (
                                    <div key={id} className="contact_info"><b style={{textTransform:"capitalize"}}>{value}:</b> {card[value]}</div>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </div>
                )
            })}
        </div>
    )
}
export default ContactCards;