import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (localStorage.getItem("personal_hub_language") === null) {
  localStorage.setItem("personal_hub_language", navigator.language);
}

if (
  sessionStorage.getItem("hub_token") === null &&
  localStorage.getItem("phsli") !== null
) {
  sessionStorage.setItem("hub_token", localStorage.getItem("phsli"));
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
