export function ProdGroupImgAndName(type, pg) {
  const product_groups = {
    AB: { img_name: "navigator_undefined", name: "Nonbook" },
    AC: { img_name: "navigator_audio", name: "Hörbuch" },
    AD: { img_name: "navigator_video", name: "Film" },
    AE: { img_name: "navigator_undefined", name: "Software" },
    AG: { img_name: "navigator_ebook", name: "ePublikation" },
    AI: { img_name: "navigator_calendar", name: "Kalender" },
    AJ: { img_name: "navigator_undefined", name: "Landkarte/Globus" },
    AM: { img_name: "navigator_undefined", name: "Papeterie/PBS" },
    AF: { img_name: "navigator_undefined", name: "Tonträger" },
    AK: { img_name: "navigator_audio", name: "Musik" },
    AL: { img_name: "navigator_undefined", name: "Noten" },
    AH: { img_name: "navigator_undefined", name: "Games" },
    AN: { img_name: "navigator_undefined", name: "Spiel" },
    AO: { img_name: "navigator_undefined", name: "Spielzeug" },
    HC: { img_name: "navigator_hardcover", name: "Hardcover" },
    TB: { img_name: "navigator_softcover", name: "Taschenbuch" },
    SB: { img_name: "navigator_undefined", name: "Schulbuch" },
    XX: { img_name: "navigator_undefined", name: "Undefined" }
  };

  if (type === "img") {
    if (pg in product_groups) {
      return "/assets/images/" + product_groups[pg]["img_name"] + ".png";
    } else {
      return "/assets/images/" + product_groups["XX"]["img_name"] + ".png";
    }
  } else if (type === "name") {
    if (pg in product_groups) {
      return product_groups[pg]["name"];
    } else {
      return product_groups["XX"]["name"];
    }
  } else {
    console.log("error from get img function");
  }
}
