import { URLconst } from "./const";

export function GetBooksData(index) {
  let BaseURL = URLconst("entertainment/read_one_book.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(index)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const getBooksList = data => {
  let BaseURL = URLconst("entertainment/read_book_batch.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getOrderStatus = data => {
  let BaseURL = URLconst("entertainment/get_order_status.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getBooksDataDynamic = data => {
  let BaseURL = URLconst("entertainment/read_books_dynamic.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getSideBooks = data => {
  let BaseURL = URLconst("entertainment/read_side_books.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};
