import React, { Component } from "react";
import "./BookcolOrderTab.css";
import "./BookcolNavbarCSS.css";
import { getOrderStatus } from "../../services/GetBooksData";
import { FormattedMessage } from "react-intl";
import SwipeableListItem from "../../components/SwipeableList/SwipeableListItem";
import SwipeableList from "../../components/SwipeableList/SwipeableList";
import OrderItem from "../../components/SwipeableList/OrderItem";
import { UpdateBookOrder } from "../../services/OrderBook";

import update from "immutability-helper";

// bot_ => prefix for BookcolOrderTab

class BookcolOrderTab extends Component {
  constructor() {
    super();
    this.state = { book_order_status: null, no_orders: false, detailsOf: null };
  }

  componentDidMount() {
    this.get_order_status();
  }

  get_order_status = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "book";
    getOrderStatus(send_credential_to_registrar).then(result => {
      if (result.order_progress) {
        this.setState({
          book_order_status: result.order_progress,
          detailsOf: null
        });
      } else {
        this.setState({ no_orders: true });
      }
    });
  };

  update_book_order = index => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.shop_id = this.state.book_order_status[
      index
    ].possession_related_to;
    send_credential_to_registrar.id_from_entertainment = this.state.book_order_status[
      index
    ].id;
    send_credential_to_registrar.planned_pickup_time = this.state.book_order_status[
      index
    ].planned_pickup_time.replace("T", " ");
    send_credential_to_registrar.note = this.state.book_order_status[
      index
    ].note_to_shop;
    UpdateBookOrder(send_credential_to_registrar).then(result => {
      if (result.success) {
        console.log("success");
      } else {
        console.log("error");
      }
    });
  };

  cancel_book_order = (event, id, possession_related_to) => {
    event.preventDefault();
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.shop_id = possession_related_to;
    send_credential_to_registrar.id_from_entertainment = id;
    send_credential_to_registrar.status = "cancelled by hub";
    if (window.confirm("Bestellung wirklich stornieren?")) {
      UpdateBookOrder(send_credential_to_registrar).then(result => {
        if (result.success) {
          this.get_order_status();
        } else {
          console.log("error");
        }
      });
    }
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  inputHandleChange = event => {
    let num = Number(event.target.id);
    let val = event.target.value === "" ? null : event.target.value;
    this.setState(
      {
        book_order_status: update(this.state.book_order_status, {
          [num]: {
            [event.target.name]: { $set: val }
          }
        })
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.update_book_order(num);
        }, 1000);
      }
    );
  };

  handleClick = index => {
    if (index === this.state.detailsOf) {
      this.setState({ detailsOf: null });
    } else {
      this.setState({ detailsOf: index });
    }
  };

  handleClickstatusHistory = index => {
    if (index === this.state.statusHistory) {
      this.setState({ statusHistory: null });
    } else {
      this.setState({ statusHistory: index });
    }
  };

  render() {
    if (this.state.book_order_status === null) {
      return (
        <div id="BookcolOrderTab">
          <img
            id="bot_top_bookshelf_bg"
            alt=""
            src="/assets/images/library_order.jpg"
          />
          <div className="white_bgr">
            <div id="orders_white" className="orders_white_ord">
              <FormattedMessage id="bookcol_library_tab_orders" />
            </div>
            <div
              onClick={() => {
                this.props.switchTabCallback("store");
              }}
              id="store_grey"
              className="store_grey"
            >
              <FormattedMessage id="bookcol_library_tab_store" />
            </div>
            <div
              onClick={() => {
                this.props.switchTabCallback("library");
              }}
              id="library_grey"
              className="library_grey"
            >
              <FormattedMessage id="bookcol_library_tab_library" />
            </div>
          </div>
          <div className="bot_round_corner_background">
            <div className="bot_book_data_wrapper">
              <div className="bot_top_title">
                <FormattedMessage id="bookcol_orders_ordered_books" />
              </div>
              {this.state.no_orders ? (
                <div>NO ORDERS</div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="bot_top_loading"
                    alt="loading"
                    src="/assets/images/loading.gif"
                    style={{ alignSelf: "center" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="BookcolOrderTab">
          <img
            id="bot_top_bookshelf_bg"
            alt=""
            src="/assets/images/library_order.jpg"
          />
          <div className="white_bgr">
            <div id="orders_white" className="orders_white_ord">
              <FormattedMessage id="bookcol_library_tab_orders" />
            </div>
            <div
              onClick={() => {
                this.props.switchTabCallback("store");
              }}
              id="store_grey"
              className="store_grey"
            >
              <FormattedMessage id="bookcol_library_tab_store" />
            </div>
            <div
              onClick={() => {
                this.props.switchTabCallback("library");
              }}
              id="library_grey"
              className="library_grey"
            >
              <FormattedMessage id="bookcol_library_tab_library" />
            </div>
          </div>
          {/* <div className="white_bgr1">
            <div id="library_grey1" className="library_grey1">
              <span className="bot_top_menu_span">Library</span>
            </div>
            <div id="store_grey1" className="store_grey1">
              <span className="bot_top_menu_span">Store</span>
            </div>
            <div id="orders_grey1" className="orders_grey1">
              <span className="bot_top_menu_span">Orders</span>
            </div>
          </div> */}
          <div className="bot_round_corner_background">
            <div className="bot_book_data_wrapper">
              <div className="bot_top_title">
                <FormattedMessage id="bookcol_orders_ordered_books" />
              </div>
              <SwipeableList>
                {this.state.book_order_status.map((item, index) => {
                  return (
                    <SwipeableListItem key={index}>
                      <OrderItem
                        key={index}
                        item={item}
                        index={index}
                        detailsOf={this.state.detailsOf}
                        statusHistory={this.state.statusHistory}
                        handleClick={this.handleClick}
                        checkIfNull={this.checkIfNull}
                        handleClickstatusHistory={this.handleClickstatusHistory}
                        inputHandleChange={this.inputHandleChange}
                        cancel_book_order={this.cancel_book_order}
                      />
                    </SwipeableListItem>
                  );
                })}
              </SwipeableList>
            </div>
            <div
              style={{
                height: "60px"
              }}
            />
          </div>
        </div>
      );
    }
  }
}

export default BookcolOrderTab;
