import React from "react";
import Routes from "./services/routes";
import { IntlProviderWrapper } from "./IntlContext";
import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

import moment from "moment";
// import locales for moment
import "moment/min/locales.min";
// set moment locale to user language
if (localStorage.getItem("personal_hub_language") !== null) {
  moment.locale(localStorage.getItem("personal_hub_language"));
}

const isIE = /* @cc_on!@ */ false || !!document.documentMode;

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      orientation: window.matchMedia("(orientation: portrait)").matches
        ? "portrait"
        : "landscape"
    };
  }

  componentDidMount() {
    this.onOrientationChange();
    window.addEventListener("resize", this.onOrientationChange);
    sessionStorage.setItem("prevent_orientation_change", false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onOrientationChange);
    sessionStorage.removeItem("prevent_orientation_change");
  }

  onOrientationChange = () => {
    let prevent_change = false;
    if (sessionStorage.getItem("prevent_orientation_change")) {
      prevent_change = JSON.parse(
        sessionStorage.getItem("prevent_orientation_change")
      );
    }

    if (
      window.matchMedia("(orientation: portrait)").matches &&
      !prevent_change &&
      this.state.orientation !== "portrait"
    ) {
      this.setState({ orientation: "portrait" });
    } else if (
      window.matchMedia("(orientation: landscape)").matches &&
      !prevent_change &&
      this.state.orientation !== "landscape"
    ) {
      this.setState({
        orientation: "landscape"
      });
    }
  };

  landscapeToPortraitBtnSwitch = () => {
    this.setState({ orientation: "portrait" });
  };

  render() {
    if (isIE) {
      return (
        <h1>
          SORRY WE DO NOT SUPPORT IE 6-11 BROWSER, PLEASE USE CHROME, MOZILLA,
          SAFARI, OPERA or EDGE INSTEAD
        </h1>
      );
    }
    return (
      <IntlProviderWrapper>
        <ErrorBoundary>
          <div className="App">
            <Routes
              orientation={this.state.orientation}
              orientationSwitchBtn={this.landscapeToPortraitBtnSwitch}
            />
          </div>
        </ErrorBoundary>
      </IntlProviderWrapper>
    );
  }
}

export default App;
