import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  modal: {
    position: "relative",
    maxWidth: "80%",
    maxHeight: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "column"
  },
  bntBlock: {
    display: "flex",
    width: "100%",
    margin: "10px"
  },
  button: {
    margin: "0 auto",
    width: "150px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px"
  },
  row: {
    borderBottom: "1px dashed #cacaca"
  },
  message_body: {
    fontSize: "20px"
  },
  cell: {
    border: "none"
  }
});

const AddUnavailableModal = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <div className={classes.root}>
        <div className={classes.modal}>
          <div className={classes.message_body}>
            <p>Adding data manually is disabled.</p>
            <p>
              This table is populated automatically by external systems, when
              you shop or navigate in HUB-enabled shops – see the complete list
              in the app (icon above). These partners insert the data for you
              <span aria-label="emoji" role="img">
                😊
              </span>
              …
            </p>
            <p>
              We are always expanding the network of participating shops, so
              talk to your favorite shop owner and let them contact us.
            </p>
          </div>
          <div className={classes.bntBlock}>
            <button className={classes.button} onClick={() => setOpen(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUnavailableModal;
