import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { getSicknessHistory } from "../../../services/GetActiveMinuteData";
import { postSicknessHistory } from "../../../services/PostActiveMinuteData";
import { putSicknessHistory } from "../../../services/PutActiveMinuteData";

import { getActiveMinuteSettings } from "../../../services/GetActiveMinuteData";
import { putActiveMinuteSettings } from "../../../services/PutActiveMinuteData";
import { injectIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
// import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  disabled: {
    color: "grey !important"
  },
  mainBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  togglesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  activitiessContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  info: {
    fontSize: "12px",
    fontFamily: "Roboto",
    width: "100%",
    padding: "3px"
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    backgroundColor: "#f5f7fa",
    borderRadius: "4px",
    padding: "5px",
    margin: "5px 0"
  },
  inputBlock: {
    width: "49%",
    display: "flex",
    flexDirection: "column"
  },
  inputNumBlock: {
    width: "25%",
    display: "flex",
    flexDirection: "column"
  },
  inputBigBlock: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative"
  },
  input: {
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%",
    paddingLeft: "5px",
    height: "30px"
  },
  inputArea: {
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%",
    paddingLeft: "5px"
  },
  inputTitle: {
    color: "#414248",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px"
  },
  listContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  listItem: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  listItemText: {
    color: "#414248",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "1.3"
  },
  successBtn: {
    border: "1px solid #AAC8FF",
    width: "110px",
    borderRadius: "4px",
    backgroundColor: "#3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25, 50, 89, 0.2)",
    color: "#fff",
    transition: "0.5s all"
  },
  editBtn: {
    backgroundColor: "#fff",
    border: "1px solid #3FA5FF",
    borderRadius: "4px",
    color: "#3FA5FF"
  },
  listItemNumberInput: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#fff",
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    height: "30px"
  },
  listItemTextInput: {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    paddingLeft: "5px",
    height: "30px"
  },
  footer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "5px"
  },
  cancelBtn: {
    padding: "0 5px 0 5px",
    backgroundColor: "#fff",
    border: "1px solid #3FA5FF",
    borderRadius: "4px",
    color: "#3FA5FF",
    marginRight: "5px"
  },
  title: {
    fontSize: "12px"
  }
}));

const defaultLayout = {
  title: "",
  category: "",
  personal_note: "",
  start_date: null,
  end_date: null
};

const Sickness = ({ handleSubmitIconChange, element, intl }) => {
  const classes = useStyles();
  const [version, setVersion] = useState("basic");
  const [day, setDay] = useState("today");
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState(defaultLayout);
  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editItem, setEditItem] = useState({
    title: "",
    category: "",
    personal_note: "",
    start_date: null,
    end_date: null
  });
  const [settingsID, setSettingsID] = useState(0);
  const [editItemId, setEditItemId] = useState(null);
  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", "")
  };
  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  const getApiData = () => {
    send_credential_to_registrar.limit = 3;
    getSicknessHistory(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result)
      } else {
        setData(result.data.data_array);
      }
      getCategories();
    });

    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 2) {
            if (item.value === "yesterday") {
              setDay(item.value);
            }
            setSettingsID(item.id);
          }
        });
      }
    });
  };

  const getCategories = () => {
    let newCredentials = send_credential_to_registrar;
    newCredentials.limit = null;
    newCredentials.categories = "";

    getSicknessHistory(newCredentials).then(result => {
      if (result.data.error) {
        // console.log(result)
      } else {
        setCategories(result.data.data_array);
      }
    });
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  const updateSettingsInApi = newDay => {
    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.value = newDay;
    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const handleVersion = (event, newVersion) => {
    if (newVersion !== null) {
      setVersion(newVersion);
    }
  };

  const getDate = () => {
    if (day === "today") {
      return moment().format("YYYY-MM-DDTHH:mm:ss");
    } else {
      return moment()
        .subtract(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss");
    }
  };

  const handleDay = (event, newDay) => {
    if (newDay !== null) {
      setDay(newDay);
      updateSettingsInApi(newDay);
    }
  };

  useEffect(() => {
    setInputs({ ...inputs, end_date: getDate() });
    // eslint-disable-next-line
  }, [day]);

  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };

    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.entry_type = "sickness";

    if (editItemId !== null) {
      send_credential_to_registrar.data.start_date = editItem.start_date;
      send_credential_to_registrar.data.end_date = editItem.end_date;
      send_credential_to_registrar.data.category = editItem.category;
      send_credential_to_registrar.data.title = editItem.title;
      send_credential_to_registrar.data.personal_note = editItem.personal_note;
      send_credential_to_registrar.data.id = editItem.id;
      // console.log(send_credential_to_registrar);
      putSicknessHistory(send_credential_to_registrar).then(() => {
        handleSubmitIconChange(element);
        setInputs(defaultLayout);
        getApiData();
        setEditItemId(null);
      });
    } else {
      send_credential_to_registrar.data.start_date = inputs.start_date;
      send_credential_to_registrar.data.end_date = inputs.end_date;
      send_credential_to_registrar.data.category = inputs.category;
      send_credential_to_registrar.data.title = inputs.title;
      send_credential_to_registrar.data.personal_note = inputs.personal_note;
      // console.log(send_credential_to_registrar);
      postSicknessHistory(send_credential_to_registrar).then(() => {
        getApiData();
        setInputs(defaultLayout);
        handleSubmitIconChange(element);
      });
    }
  };

  const getDuration = (start, end) => {
    const getForm = num => {
      if (Number(num) === 1) {
        return "day";
      } else {
        return "days";
      }
    };
    if (start !== null && end !== null) {
      const a = moment(start);
      const b = moment(end);
      const days = b.diff(a, "days");
      return `${days} ${getForm(days)}`;
    } else {
      return `0 days`;
    }
  };

  const renderCategories = () => {
    if (showCategories) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "42px",
            left: "1px",
            backgroundColor: "#fff",
            zIndex: "100",
            width: "140px",
            boxShadow: "0px 6px 10px 0px rgba(199,199,199,1)",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px"
          }}
        >
          {categories.map((item, id) => {
            if (item.toLowerCase().includes(inputs.category.toLowerCase())) {
              return (
                <div
                  key={id}
                  style={{
                    padding: "2px 5px 2px 5px",
                    borderBottom: "1px solid rgba(199,199,199,0.5)"
                  }}
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      category: item
                    });
                    setShowCategories(false);
                  }}
                >
                  {item}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const toggleButtonsVersion = () => {
    return (
      <ToggleButtonGroup
        value={version}
        exclusive
        onChange={handleVersion}
        aria-label="text alignment"
      >
        <ToggleButton value="basic" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_basic"
          })}
        </ToggleButton>
        <ToggleButton
          value="advanced"
          className={classes.disabled}
          disabled
          aria-label="center aligned"
        >
          {intl.formatMessage({
            id: "active_minute_advanced"
          })}
        </ToggleButton>
        <ToggleButton
          className={classes.disabled}
          disabled
          value="pro"
          aria-label="right aligned"
        >
          {intl.formatMessage({
            id: "active_minute_pro"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  const toggleButtonsDay = () => {
    return (
      <ToggleButtonGroup
        value={day}
        exclusive
        onChange={handleDay}
        aria-label="text alignment"
      >
        <ToggleButton value="yesterday" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_yesterday"
          })}
        </ToggleButton>
        <ToggleButton value="today" aria-label="center aligned">
          {intl.formatMessage({
            id: "active_minute_today"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const handleEdit = (item, id) => {
    setEditItem({
      id: item.id,
      title: item.title,
      category: item.category,
      start_date: item.start_date,
      end_date: item.end_date,
      personal_note: item.personal_note
    });
    setEditItemId(id);
  };

  const renderList = () => {
    return data.map((item, id) => {
      if (id === editItemId) {
        return (
          <React.Fragment key={id}>
            <Divider style={{ width: "100%", margin: "5px 0 5px 0" }} />
            <div className={classes.inputsContainer}>
              <div className={classes.inputBigBlock}>
                <span className={classes.inputTitle}>
                  {intl.formatMessage({
                    id: "active_minute_title_issue"
                  })}
                </span>
                <input
                  type="text"
                  className={classes.input}
                  value={editItem.title}
                  onChange={event =>
                    setEditItem({
                      ...editItem,
                      title: event.target.value
                    })
                  }
                />
              </div>
              <div className={classes.inputBigBlock}>
                <span className={classes.inputTitle}>
                  {intl.formatMessage({
                    id: "active_minute_category"
                  })}
                </span>
                <input
                  type="text"
                  className={classes.input}
                  value={editItem.category}
                  onChange={event =>
                    setEditItem({
                      ...editItem,
                      category: event.target.value
                    })
                  }
                />
              </div>
              <div className={classes.inputBlock}>
                <span className={classes.inputTitle}>
                  {intl.formatMessage({
                    id: "active_minute_add_activity_start_date"
                  })}
                </span>
                <input
                  type="date"
                  className={classes.input}
                  value={
                    editItem.start_date !== null && inputs.start_date !== ""
                      ? moment(editItem.start_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={event =>
                    setEditItem({
                      ...editItem,
                      start_date: event.target.value
                    })
                  }
                />
              </div>
              <div className={classes.inputBlock}>
                <span className={classes.inputTitle}>
                  {intl.formatMessage({
                    id: "active_minute_end_date"
                  })}
                </span>
                <input
                  type="date"
                  className={classes.input}
                  value={
                    editItem.end_date !== null && inputs.end_date !== ""
                      ? moment(editItem.end_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={event =>
                    setEditItem({
                      ...editItem,
                      end_date: event.target.value
                    })
                  }
                />
              </div>
              <div className={classes.inputBigBlock}>
                <span className={classes.inputTitle}>
                  {intl.formatMessage({
                    id: "active_minute_note"
                  })}
                </span>
                <textarea
                  type="text"
                  className={classes.inputArea}
                  rows={3}
                  value={
                    editItem.personal_note !== null
                      ? editItem.personal_note
                      : ""
                  }
                  onChange={event =>
                    setEditItem({
                      ...editItem,
                      personal_note: event.target.value
                    })
                  }
                />
              </div>
              {renderFooter()}
            </div>
            <Divider style={{ width: "100%", margin: "5px 0 5px 0" }} />
          </React.Fragment>
        );
      } else {
        return (
          <div
            style={{
              margin: "5px"
            }}
            key={id}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div className={classes.listItem}>
                <div style={{ width: "25%" }}>
                  <div
                    style={{ fontWeight: "500" }}
                    className={classes.listItemText}
                  >
                    {item.category}
                  </div>
                  <div className={classes.listItemText}>
                    {getDuration(item.start_date, item.end_date)}
                  </div>
                </div>

                <div style={{ width: "55%" }}>
                  <div
                    style={{ fontWeight: "500" }}
                    className={classes.listItemText}
                  >
                    {item.title}
                  </div>
                  <div className={classes.listItemText}>
                    {(item.start_date !== null
                      ? moment(item.start_date).format("dd, DD MMM - ")
                      : null) +
                      (item.end_date !== null
                        ? moment(item.end_date).format("dd, DD MMM")
                        : " (-) ") +
                      (item.start_date !== null
                        ? moment(item.start_date).format(" YYYY")
                        : item.end_date !== null
                        ? moment(item.end_date).format(" YYYY")
                        : null)}
                  </div>
                </div>
                <button
                  className={classes.editBtn}
                  onClick={() => handleEdit(item, id)}
                >
                  <EditIcon />
                </button>
              </div>
            </div>
            <div
              className={classes.listItemText}
              style={{ maxHeight: "35px", width: "100%", overflow: "auto" }}
            >
              {item.personal_note}
            </div>
          </div>
        );
      }
    });
  };

  const getInputs = () => {
    return (
      <div className={classes.inputsContainer}>
        <div className={classes.inputBigBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_title_issue"
            })}
          </span>
          <input
            type="text"
            className={classes.input}
            value={inputs.title}
            onChange={event =>
              setInputs({
                ...inputs,
                title: event.target.value
              })
            }
          />
        </div>
        <div className={classes.inputBigBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_category"
            })}
          </span>
          <input
            type="text"
            className={classes.input}
            value={inputs.category}
            onFocus={() => setShowCategories(true)}
            onBlur={() =>
              setTimeout(() => {
                setShowCategories(false);
              }, 100)
            }
            onChange={event =>
              setInputs({
                ...inputs,
                category: event.target.value
              })
            }
          />
          {renderCategories()}
        </div>
        <div className={classes.inputBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_add_activity_start_date"
            })}
          </span>
          <input
            type="date"
            className={classes.input}
            value={
              inputs.start_date !== null && inputs.start_date !== ""
                ? moment(inputs.start_date).format("YYYY-MM-DD")
                : ""
            }
            onChange={event =>
              setInputs({
                ...inputs,
                start_date: event.target.value
              })
            }
          />
        </div>
        <div className={classes.inputBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_end_date"
            })}
          </span>
          <input
            type="date"
            className={classes.input}
            value={
              inputs.end_date !== null && inputs.end_date !== ""
                ? moment(inputs.end_date).format("YYYY-MM-DD")
                : ""
            }
            onChange={event =>
              setInputs({
                ...inputs,
                end_date: event.target.value
              })
            }
          />
        </div>
        <div className={classes.inputBigBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_note"
            })}
          </span>
          <textarea
            type="text"
            className={classes.inputArea}
            rows={2}
            value={inputs.personal_note !== null ? inputs.personal_note : ""}
            onChange={event =>
              setInputs({
                ...inputs,
                personal_note: event.target.value
              })
            }
          />
        </div>
        {renderFooter()}
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        {editItemId !== null ? (
          <button
            className={classes.cancelBtn}
            onClick={() => setEditItemId(null)}
          >
            {intl.formatMessage({
              id: "active_minute_cancel"
            })}
          </button>
        ) : null}
        <button className={classes.successBtn} onClick={() => sendValueToApi()}>
          {intl.formatMessage({
            id: "active_minute_save"
          })}
        </button>
      </div>
    );
  };
  return (
    <div className={classes.mainBlock}>
      <div className={classes.togglesContainer}>
        <div>{toggleButtonsVersion()}</div>
        <div>{toggleButtonsDay()}</div>
      </div>
      <div className={classes.activitiessContainer}>
        {editItemId === null ? getInputs() : null}
        {editItemId === null ? (
          <Divider style={{ width: "100%", margin: "5px 0 5px 0" }} />
        ) : null}
        <div className={classes.listContainer}>{renderList()}</div>
      </div>
    </div>
  );
};

export default injectIntl(Sickness);
