import React, { useEffect, useState } from "react";
import { getCovidStatistics } from "../../../services/GET_requests";
import { getCovid19QuestionareStats } from "../../../services/GetHUBsiteData";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Covid19.css";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";

export default function Covid19({
  target_hub_id,
  requestorHubID,
  loggedInAs,
  setState
}) {
  const intl = useIntl();
  const [status, setStatus] = useState([]);
  const [statistic, setStatistic] = useState({ answers: 0, update: "" });

  const [lastUpdate, setLastUpdate] = useState({ last: null, next: null });
  const [credentials, setCredential] = useState({ ID: null, PIN: null });

  const [sectionState, setSectionState] = useState({ 1: false, 2: false });

  const [stayLoggedIn, setStayLoggedIn] = useState(
    localStorage.getItem("phsli") !== null ? true : false
  );

  const handleStayLoggedInCheckbox = name => event => {
    setStayLoggedIn(event.target.checked);
    if (event.target.checked === true) {
      if (sessionStorage.getItem("hub_token") !== null) {
        localStorage.setItem("phsli", sessionStorage.getItem("hub_token"));
      }
    } else {
      if (localStorage.getItem("phsli") !== null) {
        localStorage.setItem("phsli", "");
        localStorage.removeItem("phsli");
      }
    }
  };

  // const handleStayLoggedIn = param => {
  //   setStayLoggedIn(param);
  //   if (param === true) {
  //     if (sessionStorage.getItem("hub_token") !== null) {
  //       localStorage.setItem("phsli", sessionStorage.getItem("hub_token"));
  //     }
  //   } else {
  //     if (localStorage.getItem("phsli") !== null) {
  //       localStorage.setItem("phsli", "");
  //       localStorage.removeItem("phsli");
  //     }
  //   }
  // };

  const getStatisticsData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id,
      requestor_hub_id: requestorHubID,
      section: "covid-19"
    };
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    getCovid19QuestionareStats(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        let now = moment();
        let lastUpd = moment(result.data.data_array[0].last_update);
        let diff = moment.duration(now.diff(lastUpd));
        let dayDiff = Math.round(diff.asDays());
        setStatistic({
          answers: result.data.data_array[1].questions_answered,
          update: dayDiff
        });
      }
    });
  };

  useEffect(() => {
    getCovidStatistics().then(result => {
      if (result.data.statistics_status) {
        setStatus(result.data.statistics_status);
      }
    });

    if (sessionStorage.getItem("hub_token")) {
      let data = JSON.parse(sessionStorage.getItem("hub_token")).userData;
      setCredential({
        ID: parseInt(data.my_hub_id.replace(/hub_/g, "").replace(/_/g, ""), 10),
        PIN: data.temp_pin
      });
    }
  }, []);

  useEffect(() => {
    if (target_hub_id !== "") {
      getStatisticsData();
    }
    // eslint-disable-next-line
  }, [target_hub_id]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (status.length > 0) {
        let now = moment();
        let lastUpd = moment(status[0].search_start_on);
        let diff = moment.duration(now.diff(lastUpd));
        let minDur = Math.round(diff.asMinutes());
        let nextUpd = 60 - minDur;
        setLastUpdate({ last: minDur, next: nextUpd });

        if (nextUpd < 0) {
          getCovidStatistics().then(result => {
            if (result.data.statistics_status) {
              setStatus(result.data.statistics_status);
            }
          });
        }
        // console.log({ last: minDur, next: nextUpd });
      }
    };
    handleTimeUpdate();
    let interval = setInterval(() => handleTimeUpdate(), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [status]);

  return (
    <>
      <div className="cov19_hs_body">
        <div className="cov19_hs_announcment_pt1">
          {intl.formatMessage({
            id: "covid_hubsite_helping"
          })}
        </div>
        <div className="cov19_hs_announcment_pt2">
          <span>
            {intl.formatMessage({
              id: "covid_hubsite_created_acc_for_you"
            })}{" "}
          </span>
          {`HUB ID: ${credentials["ID"]}, PIN: ${credentials["PIN"]}`}
          <span style={{ marginLeft: "20px" }}>
            <Checkbox
              checked={stayLoggedIn}
              onChange={handleStayLoggedInCheckbox("checkbox")}
              value="checkbox"
              color="primary"
            />
          </span>
          <span>
            {intl.formatMessage({
              id: "burger_menu_stay_logged"
            })}
          </span>
        </div>
        {/* <div
          style={{
            paddingLeft: "15px",
            paddingTop: "5px",
            display: "flex",
            fontSize: "1rem",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            lineHeight: "1.5",
            letterSpacing: "0.00938em"
          }}
        >
          <Checkbox
            checked={stayLoggedIn}
            onChange={handleStayLoggedInCheckbox("checkbox")}
            value="checkbox"
            color="primary"
          />
          <div
            onClick={() => {
              handleStayLoggedIn(!stayLoggedIn);
            }}
            style={{ paddingLeft: "33px", paddingTop: "1px" }}
          >
            {intl.formatMessage({
              id: "burger_menu_stay_logged"
            })}
          </div>
        </div> */}
        <div className="cov19_hs_seg_title">
          {intl.formatMessage({
            id: "covid_hubsite_basic_use"
          })}
        </div>

        <div className="cov19_hs_seg_container">
          <div className="cov19_hs_seg_container_top">
            <div className="cov19_hs_seg_container_top_start">
              <div className="cov19_hs_seg_num_block">
                <div>1</div>
              </div>
              <div className="cov19_hs_seg_titleblock">
                <div className="cov19_hs_title_text">
                  {intl.formatMessage({
                    id: "covid_hubsite_share_situation"
                  })}
                </div>
                <Link to="/covid19/1">
                  <div className="cov19_hs_link_btn_wrap">
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "burger_menu_covid_19_module"
                      })}
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="cov19_hs_seg_data_block_horiz">
              <div className="cov19_hs_horiz_wrap">
                <img
                  style={
                    Number(statistic.answers) < 17
                      ? { filter: "saturate(0)" }
                      : null
                  }
                  className="cov19_hs_dsb_img"
                  alt="Data sharer badge"
                  src="/assets/images/data_sharer_badge.png"
                />
              </div>

              <div className="cov19_hs_horiz_wrap">
                <div className="cov19_hs_upd_wrap">
                  <div className="cov19_hs_upd_grey">
                    {intl.formatMessage({
                      id: "covid_hubsite_answers"
                    })}
                  </div>
                  <div
                    style={
                      Number(statistic.answers) >= 17
                        ? { color: "green" }
                        : null
                    }
                    className="cov19_hs_upd_black"
                  >
                    {" "}
                    {statistic.answers}
                    {" / 17"}
                  </div>
                </div>
                <div className="cov19_hs_upd_wrap">
                  <div className="cov19_hs_upd_grey">
                    {intl.formatMessage({
                      id: "covid_hubsite_update"
                    })}
                  </div>
                  <div className="cov19_hs_upd_black">
                    {" "}
                    {statistic.update}{" "}
                    {intl.formatMessage({
                      id: "covid_hubsite_days_ago"
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "cov19_hs_seg_container_bottom " +
              (sectionState[1] ? "" : "expanded")
            }
          >
            <div className="cov19_hs_seg_exp_inner_container">
              <div className="cov19_hs_seg_1_pt1">
                <div className="cov19_hs_seg_1_pt1_text">
                  {intl.formatMessage({
                    id: "covid_hubsite_find_answ"
                  })}
                </div>
                <Link to="/covid19/6">
                  <div className="cov19_hs_link_btn_wrap">
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "covid_hubsite_summary_of_answ"
                      })}
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <div className="cov19_hs_links_title">
                  {intl.formatMessage({
                    id: "covid_hubsite_tables"
                  })}
                </div>
                <div className="cov19_hs_links_cont">
                  <div
                    className="cov19_hs_link_btn_wrap"
                    onClick={() => {
                      setState({
                        activeWheelItem: 4,
                        activeCategory: 0,
                        activeSubCategory: null
                      });
                    }}
                  >
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "people_details"
                      })}
                    </div>
                  </div>
                  <div
                    className="cov19_hs_link_btn_wrap"
                    onClick={() => {
                      setState({
                        activeWheelItem: 5,
                        activeCategory: 0,
                        activeSubCategory: null
                      });
                    }}
                  >
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "ratings"
                      })}
                    </div>
                  </div>
                  <div
                    className="cov19_hs_link_btn_wrap"
                    onClick={() => {
                      setState({
                        activeWheelItem: 1,
                        activeCategory: 0,
                        activeSubCategory: null
                      });
                    }}
                  >
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "medical_values"
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {Number(statistic.answers) >= 17 ? (
                <div className="cov19_hs_sharer_block">
                  <div className="cov19_hs_sharer_badge_wrap">
                    <img
                      className="cov19_hs_dsb_img_bigger"
                      alt="Data sharer badge"
                      src="/assets/images/data_sharer_badge.png"
                    />
                  </div>
                  <div className="cov19_hs_sharer_wrap">
                    <div className="cov19_hs_sharer_text">
                      {intl.formatMessage({
                        id: "covid_hubsite_unlocked_badge"
                      })}
                    </div>
                    <a href="/assets/images/data_sharer_badge.png" download>
                      <div className="cov19_hs_link_btn_wrap">
                        <div className="cov19_hs_link_btn_text">
                          {intl.formatMessage({
                            id: "download_badge"
                          })}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div
            onClick={() => {
              setSectionState({ ...sectionState, 1: !sectionState[1] });
            }}
            className={"cov19_hs_expand " + (sectionState[1] ? "rotate" : "")}
          >
            <ExpandMoreIcon fontSize="inherit" />
          </div>
        </div>

        <div className="cov19_hs_seg_container">
          <div className="cov19_hs_seg_container_top">
            <div className="cov19_hs_seg_container_top_start">
              <div className="cov19_hs_seg_num_block">
                <div>2</div>
              </div>
              <div className="cov19_hs_seg_titleblock">
                <div className="cov19_hs_title_text">
                  {intl.formatMessage({
                    id: "covid_hubsite_how_others"
                  })}
                </div>
                <Link to="/covid19/statistics">
                  <div className="cov19_hs_link_btn_wrap">
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "covid_latest_statistics"
                      })}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="cov19_hs_seg_data_block_horiz">
              <div className="cov19_hs_horiz_wrap">
                <img
                  className="cov19_hs_dsb_img"
                  alt="Graph example"
                  src="/assets/images/graph_example.png"
                />
              </div>
              <div className="cov19_hs_horiz_wrap">
                <div className="cov19_hs_upd_wrap">
                  <div className="cov19_hs_upd_grey">
                    {intl.formatMessage({
                      id: "covid_statistics_last"
                    })}
                  </div>
                  <div className="cov19_hs_upd_black">
                    {lastUpdate.last !== null
                      ? `${lastUpdate.last} ${intl.formatMessage({
                          id: "covid_statistics_min_ago"
                        })}`
                      : null}
                  </div>
                </div>
                <div className="cov19_hs_upd_wrap">
                  <div className="cov19_hs_upd_grey">
                    {intl.formatMessage({
                      id: "covid_statistics_next"
                    })}
                  </div>
                  <div className="cov19_hs_upd_black">
                    {lastUpdate.next !== null
                      ? `${intl.formatMessage({
                          id: "covid_statistics_in"
                        })} ${lastUpdate.next} ${intl.formatMessage({
                          id: "covid_statistics_min"
                        })}`
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "cov19_hs_seg_container_bottom " +
              (sectionState[2] ? "" : "expanded")
            }
          >
            <div className="cov19_hs_seg_exp_inner_container">
              <div className="cov19_hs_graph_text">
                {intl.formatMessage({
                  id: "covid_browse_graphs"
                })}
              </div>
              <div className="cov19_hs_seg_data_text">
                <span>
                  {status.length > 0
                    ? `${status[0].hubs_count} ${intl.formatMessage({
                        id: "covid_statistics_hubs"
                      })}`
                    : null}
                </span>
                {" - "}
                <span style={{ marginLeft: "10px" }}>
                  {status.length > 0
                    ? `${status[0].hubs_with_answer} ${intl.formatMessage({
                        id: "covid_statistics_responses"
                      })}`
                    : null}
                </span>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setSectionState({ ...sectionState, 2: !sectionState[2] });
            }}
            className={"cov19_hs_expand " + (sectionState[2] ? "rotate" : "")}
          >
            <ExpandMoreIcon fontSize="inherit" />
          </div>
        </div>

        <div className="cov19_hs_seg_title">
          {intl.formatMessage({
            id: "covid_hubsite_advanced_use"
          })}
        </div>

        <div className="cov19_hs_seg_container">
          <div className="cov19_hs_seg_container_top">
            <div className="cov19_hs_seg_container_top_start">
              <div className="cov19_hs_seg_num_block">
                <div>3</div>
              </div>
              <div className="cov19_hs_seg_titleblock">
                <div className="cov19_hs_title_text">
                  {intl.formatMessage({
                    id: "covid_hubsite_create_stats"
                  })}
                </div>
                <Link to="/hubsearchengine">
                  <div className="cov19_hs_link_btn_wrap">
                    <div className="cov19_hs_link_btn_text">
                      {intl.formatMessage({
                        id: "burger_menu_HUBsite_search_engine"
                      })}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="cov19_hs_seg_data_block"></div>
            </div>
          </div>

          <div
            className={
              "cov19_hs_seg_container_bottom " +
              (sectionState[3] ? "" : "expanded")
            }
          >
            <div className="cov19_hs_seg_exp_inner_container">
              <div className="cov19_hs_graph_text">
                {intl.formatMessage({
                  id: "covid_explanation_engine"
                })}
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setSectionState({ ...sectionState, 3: !sectionState[3] });
            }}
            className={"cov19_hs_expand " + (sectionState[3] ? "rotate" : "")}
          >
            <ExpandMoreIcon fontSize="inherit" />
          </div>
        </div>

        {loggedInAs !== "admin" ? (
          <div className="cov19_hs_seg_container">
            <div className="cov19_hs_seg_container_top">
              <div className="cov19_hs_seg_container_top_start">
                <div className="cov19_hs_seg_num_block">
                  <div>4</div>
                </div>
                <div className="cov19_hs_seg_titleblock">
                  <div className="cov19_hs_title_text">
                    {intl.formatMessage({
                      id: "covid_hubsite_become_admin"
                    })}
                  </div>
                  <Link to="/createAdmin">
                    <div className="cov19_hs_link_btn_wrap">
                      <div className="cov19_hs_link_btn_text">
                        {intl.formatMessage({
                          id: "admin_page_create_admin_acc"
                        })}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="cov19_hs_seg_data_block"></div>
              </div>
            </div>

            <div
              className={
                "cov19_hs_seg_container_bottom " +
                (sectionState[4] ? "" : "expanded")
              }
            >
              <div className="cov19_hs_seg_exp_inner_container">
                <div className="cov19_hs_graph_text">
                  {intl.formatMessage({
                    id: "covid_explanation_admin"
                  })}
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                setSectionState({ ...sectionState, 4: !sectionState[4] });
              }}
              className={"cov19_hs_expand " + (sectionState[4] ? "rotate" : "")}
            >
              <ExpandMoreIcon fontSize="inherit" />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
