import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { CovidQuestions } from "./CovidQuestions";

export default function Covid19WelllDone({
  medValData,
  peopDetData,
  ratingsData
}) {
  const intl = useIntl();

  const questions = CovidQuestions();

  return (
    <>
      <div className="covid19_welldone_container">
        <div className="covid19_welldone_title">
          {intl.formatMessage({
            id: "covid19_welldone_title"
          })}
        </div>
        <img
          className="covid19_welldone_img"
          src="/assets/images/well_done.svg"
          alt="Well done"
        />
      </div>
      <div className="cov19_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid_wd_summary"
          })}
        </span>
      </div>
      <div className="covid19_welldone_answers_container">
        <div className="covid19_welldone_title_block">
          <div className="covid19_welldone_title_questions">Questions</div>
          <div className="covid19_welldone_title_answers">Answers</div>
        </div>
        {ratingsData.map((rating, i) => {
          let color = "covid19_gray";
          if (rating.rating > 7) {
            color = "covid19_green";
          } else if (rating.rating > 3) {
            color = "covid19_blue";
          } else if (rating.rating > 0) {
            color = "covid19_red";
          }
          if (!rating) {
            return null;
          } else {
            return (
              <div
                key={rating.subject + i}
                className="covid19_welldone_answer_block"
              >
                <div className="covid19_welldone_answer_label">
                  {rating.subject !== null &&
                  rating.subject !== "" &&
                  rating.subject !== undefined
                    ? intl.formatMessage({
                        id:
                          "covid_poll_group_question_db_" +
                          rating.keywords +
                          "_" +
                          rating.subject
                      })
                    : rating.subject}
                </div>
                <div className="covid19_welldone_answer_rating">
                  <div className={`cov19_poll_option ${color}`}>
                    {rating.rating}
                  </div>
                </div>
              </div>
            );
          }
        })}

        {medValData.map((medval, i) => {
          let answer = medval.value;
          let durationCase = ["Q220", "Q221"];
          if (durationCase.includes(medval.universal_index)) {
            answer = `${intl.formatMessage({
              id: "covid_ild_from"
            })}: ${
              moment(medval.time_start, moment.ISO_8601, true).isValid()
                ? moment(medval.time_start).format("L")
                : "-"
            } - ${intl.formatMessage({
              id: "covid_ild_to"
            })}: ${
              moment(medval.time_end, moment.ISO_8601, true).isValid()
                ? moment(medval.time_end).format("L")
                : "-"
            }`;
          }
          // console.log(questions[medval.universal_index].options);
          if (
            questions[medval.universal_index].options[0].value !== "input-field"
          ) {
            answer =
              questions[medval.universal_index].options[medval.value].label;
          }
          if (!medval) {
            return null;
          } else {
            return (
              <div
                key={medval.universal_index + i}
                className="covid19_welldone_answer_block"
              >
                <div className="covid19_welldone_answer_label">
                  {questions[medval.universal_index].label}
                </div>
                <div className="covid19_welldone_answer_value">{answer}</div>
              </div>
            );
          }
        })}

        {peopDetData.map((peopDet, i) => {
          let answer = peopDet.value_text;
          let selectCases = ["Q209", "Q211", "Q215"];
          let numCases = ["Q210", "Q212", "Q213"];
          if (selectCases.includes(peopDet.hub_transaction_id)) {
            let indexInOptions = questions[
              peopDet.hub_transaction_id
            ].options.findIndex(
              element => element.value === peopDet.value_text
            );
            answer =
              questions[peopDet.hub_transaction_id].options[indexInOptions]
                .label;
          } else if (numCases.includes(peopDet.hub_transaction_id)) {
            answer = `${peopDet.value_number} ${
              peopDet.unit !== "year" ? peopDet.unit : ""
            }`;
          }
          if (!peopDet) {
            return null;
          } else {
            return (
              <div
                key={peopDet.hub_transaction_id + i}
                className="covid19_welldone_answer_block"
              >
                <div className="covid19_welldone_answer_label">
                  {questions[peopDet.hub_transaction_id].label}
                </div>
                <div className="covid19_welldone_answer_value">{answer}</div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
