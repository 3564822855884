import React, { useState } from "react";
import { IntlContext } from "./IntlContext";
import Flag from "react-world-flags";
// import Dropdown from "react-bootstrap/Dropdown";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useIntl } from "react-intl";
import {
  getLanguage,
  putLanguage,
  postLanguage
} from "./services/SettingsData";

import moment from "moment";
// import locales for moment
import "moment/min/locales.min";

const LanguageSwitch = () => {
  const intl = useIntl();
  const {
    switchToEnglish,
    switchToDeutsch,
    switchToSlovak,
    switchToCzech,
    switchToItalian
  } = React.useContext(IntlContext);
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("personal_hub_language") !== null
      ? localStorage.getItem("personal_hub_language").split(/[-_]/)[0]
      : null
  );

  const saveLangToSetting = param => {
    let send_credential_to_registrar = {
      target_hub_id: JSON.parse(sessionStorage.getItem("hub_token")).userData
        .my_hub_id,
      requestor_hub_id: JSON.parse(sessionStorage.getItem("hub_token")).userData
        .my_hub_id,
      section: ""
    };

    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;

    getLanguage(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        send_credential_to_registrar.data = {};

        send_credential_to_registrar.data.id = result.data.data_array[0].id;
        send_credential_to_registrar.data.value = param;
        putLanguage(send_credential_to_registrar).then(result => {
          if (result.data.success) {
            // console.log("put success");
          } else {
            // console.log("put error");
          }
        });
      } else {
        send_credential_to_registrar.data = {};
        send_credential_to_registrar.data.value = param;
        send_credential_to_registrar.data.entry_type = "setting";
        send_credential_to_registrar.data.product = "personal-hub";
        send_credential_to_registrar.data.product_area = "language_preference";
        send_credential_to_registrar.data.description =
          "Last used language for the whole app";
        postLanguage(send_credential_to_registrar).then(result => {
          if (result.data.success) {
            // console.log("put success");
          } else {
            // console.log("put error");
          }
        });
      }
    });
  };

  const switchLang = value => {
    // set moment locale to user language
    moment.locale(value);
    switch (value) {
      case "en":
        saveLangToSetting("en");
        switchToEnglish();
        break;
      case "de":
        saveLangToSetting("de");
        switchToDeutsch();
        break;
      case "cs":
        saveLangToSetting("cs");
        switchToCzech();
        break;
      case "sk":
        saveLangToSetting("sk");
        switchToSlovak();
        break;
      case "it":
        saveLangToSetting("it");
        switchToItalian();
        break;
      default:
        saveLangToSetting("en");
        switchToEnglish();
    }
    setCurrentLang(value);
  };
  const getFlag = code => {
    return <Flag code={code} height="16" width="22" />;
  };

  return (
    <>
      {/* <Dropdown style={{ width: "fit-content" }}>
        <Dropdown.Toggle id="dropdown-basic">
          <img src="/assets/images/quad_flag.jpg" alt="Quad Flag" height="20px" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              saveLangToSetting("en");
              switchToEnglish();
            }}
          >
            <Flag code="gb" height="16" width="22" /> English
        </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              saveLangToSetting("de");
              switchToDeutsch();
            }}
          >
            <Flag code="de" height="16" width="22" /> Deutsch
        </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              saveLangToSetting("cs");
              switchToCzech();
            }}
          >
            <Flag code="cz" height="16" width="22" /> Česky
        </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              saveLangToSetting("sk");
              switchToSlovak();
            }}
          >
            <Flag code="sk" height="16" width="22" /> Slovensky
        </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      {/* ---------------------- */}
      <FormControl variant="outlined" style={{ width: "140px" }}>
        <InputLabel id="demo-simple-select-outlined-label">
          {intl.formatMessage({
            id: "language_switch_language"
          })}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={currentLang}
          onChange={event => switchLang(event.target.value)}
        >
          <MenuItem value="en">{getFlag("gb")} English</MenuItem>
          <MenuItem value="de">{getFlag("de")} Deutsch</MenuItem>
          <MenuItem value="cs">{getFlag("cz")} Česky</MenuItem>
          <MenuItem value="sk">{getFlag("sk")} Slovensky</MenuItem>
          <MenuItem value="it">{getFlag("it")} Italian</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
export default LanguageSwitch;
