import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const RomanceTimeLineGraph = ({ apiData }) => {
  const [chartData, setChartData] = useState([]);

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(b.when) - new Date(a.when);
    });
    let chart = [[{ type: "date", label: "Date" }, "Romance"]];

    apiDataToMap.map(item => {
      return chart.push([new Date(item.date_on.replace(' ', 'T')), Number(item.value)]);
    });
    setChartData(chart);
  };

  useEffect(() => {
    parseApiData(); // eslint-disable-next-line
  }, [apiData.length]);

  if (apiData.length !== 0) {
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        style={{ marginBottom: "100px" }}
        options={{
          vAxis: {
            title: "Value"
          },
          series: {
            1: { curveType: "function" }
          }
        }}
      />
    );
  } else {
    return null;
  }
};
export default RomanceTimeLineGraph;
