import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import { getSleepGraph } from "../../../../services/GetHUBsiteData";

const SleepBarGraph = ({ target_hub_id, requestorHubID }) => {
  const [chartData, setChartData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = requestorHubID;
    send_credential_to_registrar.section = "sleep";
    send_credential_to_registrar.component_name = "SleepBarGraph";
    //variables for dynamic created query for setting permissions
    // send_credential_to_registrar.where_var = "WHERE category='sleep'";
    // send_credential_to_registrar.order_by_var = "ORDER BY date_on DESC, id DESC";

    getSleepGraph(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        setApiData([]);
      } else {
        setApiData(result.data.data_array);
      }
    });
  };

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(b.when) - new Date(a.when);
    });
    let chart = [[{ type: "date", label: "Day" }, "Hours"]];
    apiDataToMap.map(item => {
      if (item.Sleep_in_hours !== null && item.date_on !== null) {
        return chart.push([
          new Date(item.date_on.replace(" ", "T")),
          Number(item.Sleep_in_hours.replace(":", "."))
        ]);
      }else{
        return null
      }
    });
    setChartData(chart);
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (apiData.length !== 0) {
      parseApiData();
    }
    // eslint-disable-next-line
  }, [apiData.length]);

  if (apiData.length !== 0) {
    return (
      <Chart
        width={"98%"}
        height={"300px"}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={chartData}
        style={{ marginBottom: "100px" }}
        options={{
          vAxis: {
            title: "Value"
          },
          series: {
            1: { curveType: "function" }
          }
        }}
      />
    );
  } else {
    return null;
  }
};
export default SleepBarGraph;
