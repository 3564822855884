import React, { Component } from "react";
import "./BookcolSearchBook.css";

class BookcolSuggestions extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let book_info;
    if (this.props.book_info) {
      book_info = this.props.book_info.map((r, i) => (
        <div
          onClick={() => {
            this.props.callbackFromParent(
              i,
              r.EAN,
              r.ShortTitle,
              r.Publisher,
              r.PriceGermany
            );
          }}
          className="bsug_suggest_dropd_item"
          key={r.EntryID}
        >
          <div>{r.ShortTitle}</div>
          <div>{r.Add_desc}</div>
        </div>
      ));
    }

    let book_details;
    if (this.props.selectedState) {
      book_details = this.props.book_info.map((r, i) => (
        <div className="bsug_suggestion_item" key={i}>
          <img
            className="book-item-image"
            alt=""
            src={r.url_normal}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/assets/images/missing-image.png";
            }}
          />
          <div className="bsug_suggestion_price_and_stock">
            <p className="bsug_suggestion_item_title">{r.ShortTitle}</p>
            <p className="bsug_suggestion_item_price">{r.PriceGermany} €</p>
            <p className="bsug_suggestion_item_instock">
              Vorrat:{" "}
              {this.props.product_not_in_stock ? 0 : this.props.in_stock}
            </p>
            <p>{r.Add_desc}</p>
          </div>
        </div>
      ));
    }

    return (
      <div
        id={
          this.props.selectedState === true
            ? "bsug_search_Bookcolsuggestions_selected"
            : "bsug_search_Bookcolsuggestions"
        }
      >
        {this.props.selectedState ? book_details : book_info}
      </div>
    );
  }
}

export default BookcolSuggestions;
