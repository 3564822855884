import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { putUserPermissions } from "../../../../services/Permissions";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  modal: {
    position: "relative",
    maxWidth: "80%",
    maxHeight: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "column"
  },
  modalTitle: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px"
  },
  inputBlock: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "5px auto"
  },
  inputTitle: {
    minWidth: "130px"
  },
  inputField: {
    borderRadius: "5px",
    border: "1px solid #E4E4E4",
    width: "100%",
    paddingLeft: "5px"
  },
  bntBlock: {
    display: "flex",
    width: "100%",
    margin: "10px"
  },
  button: {
    margin: "0 auto",
    width: "150px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px"
  },
  row: {
    borderBottom: "1px dashed #cacaca"
  },
  cell: {
    border: "none"
  }
});

const EditUserModal = ({
  openEditModal,
  setOpenEditModal,
  target_hub_id,
  permissionsFor,
  selectedUserName,
  get_users
}) => {
  const classes = useStyles();
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  const [oldHubID, setOldHubID] = useState("");
  const [newHubID, setNewHubID] = useState("");

  useEffect(() => {
    setOldName(selectedUserName);
    setNewName(selectedUserName !== null ? selectedUserName : "");
    setOldHubID(permissionsFor);
    setNewHubID(permissionsFor !== null ? permissionsFor : "");
  }, [permissionsFor, selectedUserName]);

  const put_user = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };

    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;

    send_credential_to_registrar.data = {
      hub_id_old: oldHubID,
      hub_id_new: newHubID,
      name_old: oldName,
      name_new: newName
    };

    putUserPermissions(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          setOpenEditModal(false);
          get_users();
        } else {
          setOpenEditModal(false);
          get_users();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openEditModal}
    >
      <div className={classes.root}>
        <div className={classes.modal}>
          <span className={classes.modalTitle}>Edit data</span>
          <div className={classes.inputBlock}>
            <div className={classes.inputTitle}>HUB ID: </div>
            <input
              type="text"
              className={classes.inputField}
              value={newHubID}
              onChange={event => setNewHubID(event.target.value)}
            />
          </div>
          <div className={classes.inputBlock}>
            <div className={classes.inputTitle}>Name: </div>
            <input
              type="text"
              className={classes.inputField}
              value={newName}
              onChange={event => setNewName(event.target.value)}
            />
          </div>
          <div className={classes.bntBlock}>
            <button
              className={classes.button}
              onClick={() => {
                put_user();
              }}
            >
              Save
            </button>
            <button
              className={classes.button}
              onClick={() => setOpenEditModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserModal;
