import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './Maps.css';

const Maps = (props) => {
  const [position, setPosition] = useState();
  const [destination, setDestination] = useState({
    lat: 0,
    lng: 0
  });

  const getLocation = () => navigator.geolocation.getCurrentPosition(
    item => setPosition({ ...position, lat: item.coords.latitude, lng: item.coords.longitude })
  );

  const createRoute = (lat, lng) => {
    console.log(lat, lng);
  };


  getLocation();

  return (
    <>
      {position
        && (
          <div className="maps-map-container">
            <Map
              styles={{ height: '50%' }}
              google={props.google}
              zoom={8}
              initialCenter={{ lat: position.lat, lng: position.lng }}
            >
              <Marker position={{ lat: position.lat, lng: position.lng }} />
            </Map>
          </div>
        )
      }
      <div className="maps-controls">
        <input type="text" value={destination.lat} onChange={event => setDestination({ ...destination, lat: event.target.value })} className="maps-controls-item" />
        <input type="text" value={destination.lng} onChange={event => setDestination({ ...destination, lng: event.target.value })} className="maps-controls-item" />
        <button onClick={() => createRoute(destination.lat, destination.lng)} className="maps-controls-item">Send</button>
      </div>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDmg_87kr6PtjhdBgSWtBpQu7K3Ra-qSf0'
})(Maps);
