import React, { Component } from "react";
import "./BookcolLibraryTab.css";
import "./BookcolNavbarCSS.css";
import { getBooksDataDynamic } from "../../services/GetBooksData";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormattedMessage } from "react-intl";
import Rating from "react-rating";
import BookcolLibSortingComp from "../../components/Bookcollector/BookcolLibSortingComp";

import "react-perfect-scrollbar/dist/css/styles.css";
import Content from "../../components/Content";

// blt_ => prefix for BookcolLibraryTab

class BookcolLibraryTab extends Component {
  constructor() {
    super();
    this.state = {
      book_order_status: null,
      recent_books: [],
      recent_books_count: 0,
      currently_reading_books: [],
      favourite_books: [],
      gifted_books: [],
      currently_reading_books_count: 0,
      favourite_books_count: 0,
      gifted_books_count: 0,
      loadingCurrent: false,
      loadMoreCurrent: true,
      loadingGifted: false,
      loadMoreGifted: true,
      loadingFavourite: false,
      loadMoreFavourite: true,
      no_orders: false,

      search_string: null,

      sort_by: null,
      sort_direction: null,
      sort_category: null
    };
  }

  bookStartRecent = 0;
  bookStartCurrent = 0;
  bookStartFavourite = 0;
  bookStartGifted = 0;

  componentDidMount() {
    this.get_category_data_dynamic(0, "recent");
    this.get_category_data_dynamic(0, "currently_reading");
    this.get_category_data_dynamic(0, "favourite");
    this.get_category_data_dynamic(0, "gifted");
  }
  get_category_data_dynamic = (show_from, param) => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.book_to_show = show_from;
    send_credential_to_registrar.batch_size = 10;
    send_credential_to_registrar.books_category = param;
    send_credential_to_registrar.section = "book";

    if (this.state.search_string !== null) {
      send_credential_to_registrar.filter_type = "search_title";
      send_credential_to_registrar.filter_by = this.state.search_string;
    }

    if (this.state.sort_by !== null && this.state.sort_direction !== null) {
      send_credential_to_registrar.order_by =
        this.state.sort_by + "_" + this.state.sort_direction;
    }

    switch (param) {
      case "recent":
        break;
      case "gifted":
        this.setState({
          loadingGifted: true
        });
        break;
      case "currently_reading":
        this.setState({
          loadingCurrent: true
        });
        break;
      case "favourite":
        this.setState({
          loadingFavourite: true
        });
        break;
      default:
        console.log("get_category_data_dynamic no param met error");
    }

    getBooksDataDynamic(send_credential_to_registrar).then(result => {
      if (result.message === "No products found.") {
        this.setState({ loadMore: false });
      } else {
        switch (param) {
          case "recent":
            this.setState(prevState => ({
              recent_books: prevState.recent_books.concat(result.recent_books),
              recent_books_count: result.recent_books_count
            }));
            break;
          case "gifted":
            this.setState(prevState => ({
              gifted_books: prevState.gifted_books.concat(result.gifted_books),
              gifted_books_count: result.gifted_books_count,
              loadingGifted: false,
              loadMoreGifted: !Boolean(result.book_not_found)
            }));
            break;
          case "currently_reading":
            this.setState(prevState => ({
              currently_reading_books: prevState.currently_reading_books.concat(
                result.currently_reading_books
              ),
              currently_reading_books_count:
                result.currently_reading_books_count,
              loadingCurrent: false,
              loadMoreCurrent: !Boolean(result.book_not_found)
            }));
            break;
          case "favourite":
            this.setState(prevState => ({
              favourite_books: prevState.favourite_books.concat(
                result.favourite_books
              ),
              favourite_books_count: result.favourite_books_count,
              loadingFavourite: false,
              loadMoreFavourite: !Boolean(result.book_not_found)
            }));
            break;
          default:
            console.log("get_category_data_dynamic no param met error");
        }
      }
    });
  };

  searchInCategories = e => {
    let tgname = e.target.name;
    let tgval = e.target.value;
    this.bookStartRecent = 0;
    this.bookStartCurrent = 0;
    this.bookStartFavourite = 0;
    this.bookStartGifted = 0;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          [tgname]: tgval,
          recent_books: [],
          currently_reading_books: [],
          favourite_books: [],
          gifted_books: [],
          loadingCurrent: false,
          loadMoreCurrent: true,
          loadingGifted: false,
          loadMoreGifted: true,
          loadingFavourite: false,
          loadMoreFavourite: true
        },
        () => {
          if (this.state.search_string.length > 0) {
            this.get_category_data_dynamic(0, "recent");
            this.get_category_data_dynamic(0, "currently_reading");
            this.get_category_data_dynamic(0, "favourite");
            this.get_category_data_dynamic(0, "gifted");
          } else if (this.state.search_string.length === 0) {
            this.get_category_data_dynamic(0, "recent");
            this.get_category_data_dynamic(0, "currently_reading");
            this.get_category_data_dynamic(0, "favourite");
            this.get_category_data_dynamic(0, "gifted");
          }
        }
      );
    }, 1000);
  };

  clearSearchBar = () => {
    this.searchInputField.value = "";
    this.bookStartRecent = 0;
    this.bookStartCurrent = 0;
    this.bookStartFavourite = 0;
    this.bookStartGifted = 0;
    this.setState(
      {
        search_string: null,
        recent_books: [],
        currently_reading_books: [],
        favourite_books: [],
        gifted_books: [],
        loadingCurrent: false,
        loadMoreCurrent: true,
        loadingGifted: false,
        loadMoreGifted: true,
        loadingFavourite: false,
        loadMoreFavourite: true
      },
      () => {
        this.get_category_data_dynamic(0, "recent");
        this.get_category_data_dynamic(0, "currently_reading");
        this.get_category_data_dynamic(0, "favourite");
        this.get_category_data_dynamic(0, "gifted");
      }
    );
  };

  onXReachEnd = param => {
    switch (param) {
      case "gifted":
        if (
          this.state.gifted_books.length !== 0 &&
          !this.state.loadingGifted &&
          this.state.loadMoreGifted
        ) {
          this.bookStartGifted += 10;
          this.get_category_data_dynamic(
            this.bookStartGifted,
            "gifted",
            this.state.search_string
          );
        }
        break;
      case "favourite":
        if (
          this.state.favourite_books.length !== 0 &&
          !this.state.loadingFavourite &&
          this.state.loadMoreFavourite
        ) {
          this.bookStartFavourite += 10;
          this.get_category_data_dynamic(
            this.bookStartFavourite,
            "favourite",
            this.state.search_string
          );
        }
        break;
      case "currently_reading":
        if (
          this.state.currently_reading_books.length !== 0 &&
          !this.state.loadingCurrent &&
          this.state.loadMoreCurrent
        ) {
          this.bookStartCurrent += 10;
          this.get_category_data_dynamic(
            this.bookStartCurrent,
            "currently_reading",
            this.state.search_string
          );
        }
        break;
      default:
        console.log("X end reached but none condition met");
    }
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  bookColSortCompCallback = (sort_by, direction, category) => {
    let cat = category + "_books";
    if (sort_by === "reset") {
      this.setState(
        {
          sort_by: null,
          sort_direction: null,
          sort_category: null,
          [cat]: []
        },
        () => {
          this.get_category_data_dynamic(0, category);
        }
      );
    } else {
      switch (category) {
        case "favourite":
          this.bookStartFavourite = 0;
          break;
        case "gifted":
          this.bookStartGifted = 0;
          break;
        case "currently_reading":
          this.bookStartCurrent = 0;
          break;
        default:
      }
      this.setState(
        {
          sort_by: sort_by,
          sort_direction: direction,
          sort_category: category,
          [cat]: []
        },
        () => {
          this.get_category_data_dynamic(0, category);
        }
      );
    }
  };

  render() {
    const { recent_books } = this.state;
    const { currently_reading_books } = this.state;
    const { favourite_books } = this.state;
    const { gifted_books } = this.state;

    return (
      <Content>
        <div id="BookcolLibraryTab">
          <img
            id="blt_top_bookshelf_bg"
            alt=""
            src="/assets/images/library_img_2.jpg"
          />
          <div className="white_bgr">
            <div
              onClick={() => {
                this.props.switchTabCallback("orders");
              }}
              id="orders_grey"
              className="orders_grey"
            >
              <FormattedMessage id="bookcol_library_tab_orders" />
            </div>
            <div
              onClick={() => {
                this.props.switchTabCallback("store");
              }}
              id="store_grey"
              className="store_grey"
            >
              <FormattedMessage id="bookcol_library_tab_store" />
            </div>

            <div id="library_white" className="library_white_lib">
              <FormattedMessage id="bookcol_library_tab_library" />
            </div>
          </div>

          <div className="blt_round_corner_background">
            <div className="blt_book_data_wrapper">
              <div className="blt_search_bar_container">
                <div className="blt_search_bar_wrapper">
                  <img
                    className="blt_search_zoom_icon"
                    alt=""
                    src="/assets/images/zoom_icon.png"
                    // onClick={this.handleSearchClick}
                  />

                  <input
                    ref={ref => (this.searchInputField = ref)}
                    className="blt_searchbook_input"
                    name="search_string"
                    type="text"
                    onFocus={() => {
                      sessionStorage.setItem(
                        "prevent_orientation_change",
                        true
                      );
                    }}
                    onBlur={() => {
                      sessionStorage.setItem(
                        "prevent_orientation_change",
                        false
                      );
                    }}
                    onChange={this.searchInCategories}
                    autoComplete="off"
                    placeholder="Suche"
                  />
                </div>

                {this.state.search_string !== null &&
                this.state.search_string !== "" ? (
                  <span
                    onClick={this.clearSearchBar}
                    className="blt_searchbook_input_del_icon"
                  >
                    X
                  </span>
                ) : null}
              </div>
              <div className="blt_line" />
              <div className="blt_top_title">
                <FormattedMessage id="bookcol_library_tab_recently_added" />
                <BookcolLibSortingComp
                  bookColSortCompCallback={this.bookColSortCompCallback}
                  sortBooksInCategory="recent"
                  showTitleSort={true}
                  showAddedSort={true}
                />
              </div>
              <div className="blt_book_list">
                {recent_books.map((item, index) => {
                  if (!item) {
                    return null;
                  }

                  let cover_image_link_1;
                  if (item.external_id_1 === null && item.poster === null) {
                    cover_image_link_1 =
                      "/assets/images/missing_image_portrait.png";
                  } else {
                    if (item.poster === null) {
                      cover_image_link_1 = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${item.external_id_1}&size=m`;
                    } else {
                      cover_image_link_1 = item.poster;
                    }
                  }
                  return (
                    <div
                      className={"blt_item_container"}
                      onClick={() =>
                        this.props.showBook(
                          item.id,
                          index,
                          this.state.recent_books_count,
                          "recent",
                          this.state.sort_by,
                          this.state.sort_direction,
                          this.state.search_string
                        )
                      }
                      key={index}
                    >
                      <div>
                        <img
                          className="blt_item_image"
                          alt=""
                          src={cover_image_link_1}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src =
                              "/assets/images/missing_image_portrait.png";
                          }}
                        />
                      </div>
                      <Rating
                        className="blt_rating_stars"
                        initialRating={item.rating / 2}
                        stop={5}
                        readonly={true}
                        emptySymbol={<span className="star">&#x2606;</span>}
                        fullSymbol={<span className="star">&#x2605;</span>}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="blt_line" />
              <div className="blt_top_title">
                <FormattedMessage id="bookcol_library_tab_currently_reading" />
                <BookcolLibSortingComp
                  bookColSortCompCallback={this.bookColSortCompCallback}
                  sortBooksInCategory="currently_reading"
                  showTitleSort={true}
                  showAddedSort={true}
                />
              </div>
              <div className="blt_book_wrapper">
                <PerfectScrollbar
                  className={"blt_book_list"}
                  onXReachEnd={() => {
                    this.onXReachEnd("currently_reading");
                  }}
                >
                  {currently_reading_books.map((item, index) => {
                    if (!item) {
                      return null;
                    }

                    let cover_image_link_1;
                    if (item.external_id_1 === null && item.poster === null) {
                      cover_image_link_1 =
                        "/assets/images/missing_image_portrait.png";
                    } else {
                      if (item.poster === null) {
                        cover_image_link_1 = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${item.external_id_1}&size=m`;
                      } else {
                        cover_image_link_1 = item.poster;
                      }
                    }

                    if (item.possession === "ordered") {
                      return null;
                    } else {
                      return (
                        <div
                          className={"blt_item_container"}
                          onClick={() =>
                            this.props.showBook(
                              item.id,
                              index,
                              this.state.currently_reading_books_count,
                              "currently_reading",
                              this.state.sort_by,
                              this.state.sort_direction,
                              this.state.search_string
                            )
                          }
                          key={index}
                        >
                          <div>
                            <img
                              className="blt_item_image"
                              alt=""
                              src={cover_image_link_1}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  "/assets/images/missing_image_portrait.png";
                              }}
                            />
                          </div>
                          <Rating
                            className="blt_rating_stars"
                            initialRating={item.rating / 2}
                            stop={5}
                            readonly={true}
                            emptySymbol={<span className="star">&#x2606;</span>}
                            fullSymbol={<span className="star">&#x2605;</span>}
                          />
                        </div>
                      );
                    }
                  })}
                </PerfectScrollbar>
              </div>

              <div className="blt_line" />
              <div className="blt_top_title">
                <FormattedMessage id="bookcol_library_tab_favourites" />
                <BookcolLibSortingComp
                  bookColSortCompCallback={this.bookColSortCompCallback}
                  sortBooksInCategory="favourite"
                  showTitleSort={true}
                  showAddedSort={true}
                  showFavouriteSort={true}
                />
              </div>
              <div className="blt_book_wrapper">
                <PerfectScrollbar
                  className={"blt_book_list"}
                  onXReachEnd={() => {
                    this.onXReachEnd("favourite");
                  }}
                >
                  {favourite_books.map((item, index) => {
                    if (!item) {
                      return null;
                    }

                    let cover_image_link_1;
                    if (item.external_id_1 === null && item.poster === null) {
                      cover_image_link_1 =
                        "/assets/images/missing_image_portrait.png";
                    } else {
                      if (item.poster === null) {
                        cover_image_link_1 = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${item.external_id_1}&size=m`;
                      } else {
                        cover_image_link_1 = item.poster;
                      }
                    }

                    if (item.possession === "ordered") {
                      return null;
                    } else {
                      return (
                        <div
                          className={"blt_item_container"}
                          onClick={() =>
                            this.props.showBook(
                              item.id,
                              index,
                              this.state.favourite_books_count,
                              "favourite",
                              this.state.sort_by,
                              this.state.sort_direction,
                              this.state.search_string
                            )
                          }
                          key={index}
                        >
                          <div>
                            <img
                              className="blt_item_image"
                              alt=""
                              src={cover_image_link_1}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  "/assets/images/missing_image_portrait.png";
                              }}
                            />
                          </div>
                          <Rating
                            className="blt_rating_stars"
                            initialRating={item.rating / 2}
                            stop={5}
                            readonly={true}
                            emptySymbol={<span className="star">&#x2606;</span>}
                            fullSymbol={<span className="star">&#x2605;</span>}
                          />
                        </div>
                      );
                    }
                  })}
                </PerfectScrollbar>
              </div>
              <div className="blt_line" />

              <div className="blt_top_title">
                <FormattedMessage id="bookcol_library_tab_gifted" />
                <BookcolLibSortingComp
                  bookColSortCompCallback={this.bookColSortCompCallback}
                  sortBooksInCategory="gifted"
                  showTitleSort={true}
                  showAddedSort={true}
                  showGiftfromSort={true}
                />
              </div>
              <div className="blt_book_wrapper">
                <PerfectScrollbar
                  className={"blt_book_list"}
                  onXReachEnd={() => {
                    this.onXReachEnd("gifted");
                  }}
                >
                  {gifted_books.map((item, index) => {
                    if (!item) {
                      return null;
                    }

                    let cover_image_link_1;
                    if (item.external_id_1 === null && item.poster === null) {
                      cover_image_link_1 =
                        "/assets/images/missing_image_portrait.png";
                    } else {
                      if (item.poster === null) {
                        cover_image_link_1 = `https://portal.dnb.de/opac/mvb/cover.htm?isbn=${item.external_id_1}&size=m`;
                      } else {
                        cover_image_link_1 = item.poster;
                      }
                    }

                    if (item.possession === "ordered") {
                      return null;
                    } else {
                      return (
                        <div
                          className={"blt_item_container"}
                          onClick={() =>
                            this.props.showBook(
                              item.id,
                              index,
                              this.state.gifted_books_count,
                              "gifted",
                              this.state.sort_by,
                              this.state.sort_direction,
                              this.state.search_string
                            )
                          }
                          key={index}
                        >
                          <div>
                            <img
                              className="blt_item_image"
                              alt=""
                              src={cover_image_link_1}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  "/assets/images/missing_image_portrait.png";
                              }}
                            />
                          </div>
                          <div className="blt_poss_rel_to">
                            {item.possession_related_to}
                          </div>
                        </div>
                      );
                    }
                  })}
                </PerfectScrollbar>
              </div>
              <div className="blt_line" />
              <div
                style={{
                  height: "60px"
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

export default BookcolLibraryTab;
