import React, { useState, useEffect } from "react";
import moment from "moment";
import { postNutrition } from "../../../../../services/PostActiveMinuteData";
import { putNutrition } from "../../../../../services/PutActiveMinuteData";
import Slider from "rc-slider";
import { injectIntl } from "react-intl";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

function Basic({
  handleSubmitIconChange,
  item,
  providedData,
  getApiData,
  day,
  intl,
}) {
  const [success, setSuccess] = useState(false);
  const [maxVal, setMaxVal] = useState(1);
  const [inputValue, setInputValue] = useState({
    id: null,
    value: null,
    personal_note: null,
    date_on:
      day === "today"
        ? moment().format("YYYY-MM-DDTHH:mm:ss")
        : moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [prevValue, setPrevValue] = useState(null);

  const [clickedItem, setClickedItem] = useState(null);

  const slider_styles = {
    trackStyle: {
      background: "linear-gradient(0deg, #626DA7 0%, #3B4680 100%)",
      width: 12,
      left: "1px",
    },
    trackWide: {
      width: 40,
      left: "-13px",
    },
    railStyle: { backgroundColor: "#ffffff00", width: 12 },
    railWide: { width: 40 },
    handleStyle: [
      {
        border: "transparent",
        borderRadius: "3px",
        height: "16px",
        width: "12px",
        backgroundColor: "transparent",
        bottom: "15%",
        marginLeft: "0px",
        marginBottom: "-7px",
      },
    ],
  };

  useEffect(() => {
    if (JSON.stringify(inputValue) === JSON.stringify(prevValue)) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [inputValue, prevValue]);

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid,
    };

    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.category = "nutrition";
    send_credential_to_registrar.data.value = inputValue.value;
    send_credential_to_registrar.data.personal_note = inputValue.personal_note;
    send_credential_to_registrar.data.unite = "Ccal";

    send_credential_to_registrar.data.date_on = moment(
      inputValue.date_on
    ).format("YYYY-MM-DDTHH:mm:ss");

    if (clickedItem !== null) {
      send_credential_to_registrar.data.id = providedData[clickedItem].id;
      // console.log(send_credential_to_registrar);
      putNutrition(send_credential_to_registrar).then(() => {
        getApiData();
        setSuccess(true);
        setPrevValue(inputValue);
        handleSubmitIconChange(item);
      });
    } else {
      // console.log(send_credential_to_registrar);
      postNutrition(send_credential_to_registrar).then(() => {
        getApiData();
        setSuccess(true);
        setPrevValue(inputValue);
        handleSubmitIconChange(item);
      });
    }
  };

  let grid_style = {
    width: "100%",
    height: "1px",
    backgroundColor: "#CFD7DE",
  };

  return (
    <>
      <div style={{ display: "flex", marginTop: "20px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "180px",
            flexDirection: "column",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "italic",
            marginTop: "5px",
            marginRight: "5px",
            color: "#545669",
          }}
        >
          <div>{(maxVal / 4) * 4}</div>
          <div>{(maxVal / 4) * 3}</div>
          <div>{(maxVal / 4) * 2}</div>
          <div>{maxVal / 4}</div>
          <div>0</div>
        </div>
        <div
          style={{
            width: "100%",
            height: "200px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              height: "165px",
              marginTop: "13px",
            }}
          >
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              height: "185px",
              marginTop: "13px",
            }}
          >
            {providedData.map((data, i) => {
              if (!data) {
                return null;
              } else {
                if (Number(data.value) > maxVal) {
                  setMaxVal(Math.ceil(Number(data.value) / 1000) * 1000);
                }

                let track_style = [slider_styles.trackStyle];
                let rail_style = slider_styles.railStyle;
                if (clickedItem === i) {
                  track_style = [
                    { ...slider_styles.trackStyle, ...slider_styles.trackWide },
                  ];
                  rail_style = {
                    ...slider_styles.railStyle,
                    ...slider_styles.railWide,
                  };
                }

                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      opacity: "1",
                      width:
                        clickedItem !== i
                          ? "calc(100% / " + (providedData.length + 1) + ")"
                          : "calc(2*(100% / " + (providedData.length + 2) + ")",
                    }}
                    onClick={() => {
                      setClickedItem(i);
                      setInputValue({
                        id: providedData[i].id,
                        value: providedData[i].value,
                        personal_note: providedData[i].personal_note,
                        date_on: providedData[i].date_on,
                      });
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-25px",
                        fontSize: "12px",
                        transform: "rotate(-45deg)",
                      }}
                    >
                      {data.value !== null ? data.value : null}
                    </div>
                    <Slider
                      vertical={true}
                      disabled={true}
                      trackStyle={track_style}
                      handleStyle={slider_styles.handleStyle}
                      railStyle={rail_style}
                      min={0}
                      max={maxVal}
                      value={Number(data.value)}
                      step={1}
                    />
                    <div
                      style={{
                        height: "20px",
                        color: "#000000",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontStyle: "italic",
                        letterSpacing: "0.43px",
                        lineHeight: "14px",
                        textAlign: "center",
                      }}
                    >
                      {moment(data.date_on).format("DD.MM")}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div className="steps_basic_input_container">
        <div className="steps_basic_input_block">
          <div className="steps_basic_input_note_container">
            <div style={{ marginBottom: "5px" }}>
              {intl.formatMessage({
                id: "active_minute_note",
              })}
            </div>
            <input
              style={success ? { backgroundColor: "transparent" } : null}
              disabled={success}
              type="text"
              value={
                inputValue.personal_note !== null
                  ? inputValue.personal_note
                  : ""
              }
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  personal_note: event.target.value,
                })
              }
            />
          </div>

          <div className="steps_basic_input_steps_block">
            <div style={{ marginBottom: "5px" }}>
              {intl.formatMessage({
                id: "active_minute_calories",
              })}
            </div>
            <input
              style={success ? { backgroundColor: "transparent" } : null}
              disabled={success}
              type="number"
              value={inputValue.value !== null ? inputValue.value : ""}
              max="500000"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  value:
                    event.target.value !== ""
                      ? Math.round(Number(event.target.value))
                      : "",
                })
              }
            />
          </div>
        </div>
        <div className="steps_basic_save_cancel_block">
          {clickedItem !== null && !success ? (
            <button
              onClick={() => {
                setClickedItem(null);
                setInputValue({ id: null, value: null, personal_note: null });
              }}
            >
              {intl.formatMessage({
                id: "active_minute_cancel",
              })}
            </button>
          ) : null}

          <button
            className={success ? "rotate" : ""}
            onClick={!success ? () => sendValueToApi() : null}
          >
            {success ? (
              <DoneOutlineIcon
                onClick={() => {
                  setClickedItem(null);
                  setInputValue({ id: null, value: null, personal_note: null });
                }}
              />
            ) : (
              intl.formatMessage({
                id: "active_minute_submit",
              })
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default injectIntl(Basic);
