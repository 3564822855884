import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import "./ResultModal.css";

const ResultModal = ({ open, setOpen, updResult }) => {
  const [resultSuccess, setResultSuccess] = useState(true);

  useEffect(() => {
    if (updResult.length > 0) {
      updResult.forEach((element) => {
        if (!element.result) {
          setResultSuccess(false);
        }
      });
    }
  }, [updResult]);

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <DialogContent>
        <div className="upg_modal_container">
          <div className="upg_modal_close_btn">
            <div onClick={() => setOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="upg_modal_title_wrap">Update Result</div>
          <div className="upg_modal_result_container">
            {updResult.map((item) => {
              if (!item) {
                return null;
              } else {
                return (
                  <div key={item.id} className="upg_modal_result_item">
                    <div className="upg_modal_result_item_title">{`${item.rel_to}, #${item.id}`}</div>
                    {item.result ? (
                      <div className="upg_modal_result_icon_success">
                        <DoneIcon fontSize="large" />
                      </div>
                    ) : (
                      <div className="upg_modal_result_icon_error">
                        <ClearIcon fontSize="large" />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>

          <div
            className="upg_modal_result_subtitle"
            style={resultSuccess ? { color: "#7cdb80" } : { color: "#e6817a" }}
          >
            {resultSuccess ? "UPGRADE SUCCESSFULL" : "UPGRADE INCOMPLETE"}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ResultModal;
