import React, { useState, useEffect } from "react";
import { getFilms } from "../../../services/GetHUBsiteData";
import { getActiveMinuteSettings } from "../../../services/GetActiveMinuteData";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "../styles/films.css";
import Rating from "react-rating";
import { postFilmsList } from "../../../services/PostHUBsiteData";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  putActiveMinuteSettings,
  putActiveMinuteFilm
} from "../../../services/PutActiveMinuteData";
import { Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

export default function FilmsList({ handleSubmitIconChange, item }) {
  const [filmsData, setFilmsData] = useState([]);
  const [alignment, setAlignment] = useState("today");
  const [version, setVersion] = useState("basic");
  const [clickedItem, setClickedItem] = useState(null);
  const [settingsID, setSettingsID] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [success, setSuccess] = useState(false);
  const [newItemInfo, setNewItemInfo] = useState({
    title: "",
    seen_on: moment().format("YYYY-MM-DD"),
    rating: 0,
    note: ""
  });
  const useStyles = makeStyles(theme => ({
    disabled: {
      color: "grey !important"
    }
  }));
  const classes = useStyles();

  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", "")
  };
  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 26) {
            if (item.value === "yesterday") {
              setAlignment(item.value);
              setNewItemInfo({
                ...newItemInfo,
                seen_on: moment()
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")
              });
            }
            if (item.parameter2 !== null) {
              setVersion(item.parameter2);
            }
            setSettingsID(item.id);
          }
        });
      }
    });
    send_credential_to_registrar.limit_var = "LIMIT 3";
    getFilms(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        setFilmsData(result.data.data_array);
      }
    });
  };

  const get_active_film = param => {
    if (param !== null) {
      send_credential_to_registrar.search_string = param;
      send_credential_to_registrar.limit_var = "LIMIT 3";
    }
    getFilms(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        setFilmsData(result.data.data_array);
      } else {
        console.log(result.data.error);
      }
    });
  };

  useEffect(() => {
    get_active_film(searchText); // eslint-disable-next-line
  }, [searchText]);

  const updateSettingsInApi = (type, param) => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    if (type === "version") {
      send_credential_to_registrar.data.parameter2 = param;
    } else if (type === "alignment") {
      send_credential_to_registrar.data.value = param;
    } else {
      console.log("wrong type");
      return;
    }

    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  const cleanFields = () => {
    setNewItemInfo({
      title: "",
      seen_on: moment().format("YYYY-MM-DD"),
      rating: 0,
      note: ""
    });
  };

  const handleVersion = (event, newVersion) => {
    if (newVersion !== null) {
      setVersion(newVersion);
      updateSettingsInApi("version", newVersion);
    }
  };
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      updateSettingsInApi("alignment", newAlignment);
    }
  };

  const editItem = (item, id) => {
    setEditMode(true);
    setNewItemInfo(item);
    setEditItemId(id);
  };

  const renderBasicList = () => {
    return (
      <div className="films_list">
        {filmsData.map((item, id) => {
          if (id < 3) {
            return (
              <React.Fragment key={id}>
                <div key={id} className="films_list_item">
                  <div className="films_list_item_title">{item.title}</div>
                  <div className="films_list_item_details">
                    <div className="films_list_item_details_item">
                      {" "}
                      {item.seen_on !== null
                        ? item.seen_on
                        : "- - - - . - - . - -"}
                    </div>
                    <div className="films_list_item_details_item">
                      <Rating
                        initialRating={item.rating / 2}
                        stop={5}
                        readonly={true}
                        emptySymbol={
                          <img src="/assets/images/Star.svg" alt="star" />
                        }
                        fullSymbol={
                          <img
                            src="/assets/images/Star filled.svg"
                            alt="star"
                          />
                        }
                      />
                    </div>
                    <div
                      onClick={() => setClickedItem(id)}
                      style={
                        id !== clickedItem
                          ? {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap"
                            }
                          : {}
                      }
                    >
                      {item.note}{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      {id !== editItemId && editMode === false ? (
                        <button onClick={() => editItem(item, id)}>
                          <EditIcon />
                        </button>
                      ) : id === editItemId && editMode === true ? (
                        <button
                          style={{
                            border: "none",
                            boxShadow: "none"
                          }}
                          onClick={() => {
                            cleanFields();
                            setEditMode(false);
                            setEditItemId(null);
                          }}
                        >
                          Cancel
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  const renderList = () => {
    switch (version) {
      case "basic":
        return renderBasicList();
      default:
        return renderBasicList();
    }
  };
  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.kind = "film";
    send_credential_to_registrar.data.title = newItemInfo.title;
    send_credential_to_registrar.data.rating = newItemInfo.rating;
    send_credential_to_registrar.data.note = newItemInfo.note;
    send_credential_to_registrar.data.start_date = newItemInfo.seen_on;

    if (editMode === false) {
      postFilmsList(send_credential_to_registrar).then(() => {
        cleanFields();
        setSuccess(true);
        handleSubmitIconChange(item);
        getApiData();
      });
    } else {
      send_credential_to_registrar.data.id = newItemInfo.id;
      // console.log(send_credential_to_registrar);
      putActiveMinuteFilm(send_credential_to_registrar).then(() => {
        cleanFields();
        setSuccess(true);
        handleSubmitIconChange(item);
        getApiData();
      });
    }
    setEditMode(false);
    setEditItemId(null);
  };

  const clearSuccess = () => {
    if (success) {
      setSuccess(false);
    }
  };

  return (
    <div className="films_container">
      <div className="films_title_container">
        <div className="films_title_left">
          <ToggleButtonGroup
            value={version}
            exclusive
            onChange={handleVersion}
            aria-label="text alignment"
          >
            <ToggleButton value={"basic"} aria-label="left aligned">
              Basic
            </ToggleButton>
            <ToggleButton
              className={classes.disabled}
              disabled
              value={"advanced"}
              aria-label="center aligned"
            >
              Advanced
            </ToggleButton>
            <ToggleButton
              className={classes.disabled}
              disabled
              value={"pro"}
              aria-label="right aligned"
            >
              Pro
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="films_title_right">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="yesterday" aria-label="yesterday aligned">
              Yesterday
            </ToggleButton>
            <ToggleButton value="today" aria-label="today aligned">
              Today
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="films_search_container">
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
        />
      </div>
      <div className="films_list_container">{renderList()}</div>
      <Divider style={{ margin: "15px 0 15px 0" }} />
      <div className="films_inputs_container">
        <input
          type="text"
          className="films_input_item title"
          placeholder="Title"
          value={newItemInfo.title}
          onChange={event => {
            setNewItemInfo({ ...newItemInfo, title: event.target.value });
            clearSuccess();
          }}
        />
        <input
          type="date"
          className="films_input_item date"
          placeholder="Seen on"
          value={newItemInfo.seen_on}
          onChange={event => {
            setNewItemInfo({ ...newItemInfo, seen_on: event.target.value });
            clearSuccess();
          }}
        />
        <Rating
          className="films_input_item rating"
          initialRating={newItemInfo.rating}
          stop={10}
          onClick={value => {
            setNewItemInfo({ ...newItemInfo, rating: value });
            clearSuccess();
          }}
          emptySymbol={<img src="/assets/images/Star.svg" alt="star" />}
          fullSymbol={<img src="/assets/images/Star filled.svg" alt="star" />}
        />
        <textarea
          type="text"
          rows={2}
          className="films_input_item note"
          placeholder="Note"
          value={newItemInfo.note}
          onChange={event => {
            setNewItemInfo({ ...newItemInfo, note: event.target.value });
            clearSuccess();
          }}
        />
      </div>
      <div className="films_submit_container">
        <button
          className={success ? "rotate" : ""}
          onClick={
            success
              ? null
              : () => {
                  sendValueToApi();
                }
          }
        >
          {success ? <DoneOutlineIcon /> : "Save"}
        </button>
      </div>
    </div>
  );
}
