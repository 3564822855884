import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./ActiveMinute.css";
import "./styles/materialStyles.css";
// import Paper from "@material-ui/core/Paper";
// import InputBase from "@material-ui/core/InputBase";
// import IconButton from "@material-ui/core/IconButton";
// import SearchIcon from "@material-ui/icons/Search";
import { injectIntl } from "react-intl";
import { getActiveMinute } from "../../services/GetPersonalHub";
import { getActiveMinuteSettings } from "../../services/GetActiveMinuteData";
import WeightTimeLineGraph from "./Graphs/Weight";
import HappinessTimeLineGraph from "./Graphs/Happiness";
import NutritionTimeLineGraph from "./Graphs/Nutrition";
import FilmsList from "./Graphs/Films";
import BooksList from "./Graphs/Books";
import ActiveSleep from "./Graphs/ActiveSleep";
import ActiveDayNote from "./Graphs/ActiveDayNote";
import { putActiveMinuteSettings } from "../../services/PutActiveMinuteData";
import { postActiveMinuteSettings } from "../../services/PostActiveMinuteData";
import { deleteSettings } from "../../services/DeleteHUBsiteData";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Activities from "./Graphs/Activities/Activities";
import InjuriesAndIssues from "./Graphs/InjuriesAndIssues/InjuriesAndIssues";
import Steps from "./Graphs/Steps.js";
import Sickness from "./Graphs/Sickness.js";
import BloodPressure from "./Graphs/BloodAndPressure.js";
import SearchIcon from "@material-ui/icons/Search";
import { SwipeableDrawer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SettingsIcon from "@material-ui/icons/Settings";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    position: "relative",
  },
  table: {
    backgroundColor: "white",
    marginTop: "10px",
  },
  heading: {
    fontSize: "14px",
    textTransform: "capitalize",
    color: "#545669",
    fontWeight: "500",
  },
  activeMinuteSettingsTitle: {
    width: "320px",
    height: "80px",
    textAlign: "center",
    paddingTop: "40px",
    color: " #232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(71, 119, 186, 0.07)",
    },
  },
  cell: {
    borderBottom: "none !important",
    position: "relative",
    width: "100%",
    paddingLeft: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkBox: {
    marginRight: "5px",
  },
  add_circle_icon: {
    color: "green",
    marginRight: "10px",
  },
}));

const YellowCheckbox = withStyles({
  root: {
    color: "#b7b7b7",
    "&$checked": {
      color: "#fdd835",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const OrangeCheckbox = withStyles({
  root: {
    color: "#b7b7b7",
    "&$checked": {
      color: "#fb8c00",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ActiveMinute({ intl }) {
  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [oneTimeOpenId, setOneTimeOpenId] = useState(null);
  const [finishedDaily, setFinishedDaily] = useState({
    finished: 0,
    all: 0,
  });
  const [redirected, setRedirected] = useState(false);
  const [redirectedToAdmin, setRedirectedToAdmin] = useState(false);
  const [activeOccasional, setActiveOccasional] = useState(null);
  const [select, setSelect] = useState("all");
  const [occasionalPanel, setOccasionalPanel] = useState({
    open: false,
    title: "",
    content: "",
  });
  const [daily, setDaily] = useState([]);
  const [oneTime, setOneTime] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [occasional, setOccasional] = useState([]);
  const [modal, setModal] = useState(false);
  const [activeMinuteSettings, setActiveMinuteSettings] = useState(false);
  const [typeModal, setTypeModal] = useState({ open: false, type: "" });
  const [activeItem, setActiveItem] = useState({});
  const [searchText, setSearchText] = useState("");
  const [oneTimeShowAvailable, setOneTimeShowAvailable] = useState(false);
  const [searchCategoriesText, setSearchCategoriesText] = useState("");
  const [foundCategories, setFoundCategories] = useState([]);

  const logged_in_as = JSON.parse(
    sessionStorage.getItem("hub_token")
  ).userData.db_username.slice(20);

  let send_credential_to_registrar = {
    target_hub_id: hubid,
  };
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }
  useEffect(() => {
    if (logged_in_as !== "Admin") {
      setModal(true);
    }
  }, [logged_in_as]);

  const getApiData = () => {
    getActiveMinute(send_credential_to_registrar).then((result) => {
      if (result.data.error) {
      } else {
        // console.log(result.data.data_array);
        // setData(result.data.data_array);
        let temp_array = result.data.data_array;
        getActiveMinuteSettings(send_credential_to_registrar).then((result) => {
          if (result.data.error) {
            parseData(temp_array);
            setDefaultApiData(temp_array, false);
          } else {
            parseAvailableCategories(temp_array, result.data.data_array);
            // console.log(result.data.data_array);
            // setData(result.data.data_array);
            parseUserData(result.data.data_array, temp_array);
          }
        });
      }
    });
  };

  var itemsProcessed = 0;

  const setDefaultApiData = (data, setting_default) => {
    const defaultValues = [
      {
        path: "value2",
        value: "rel_category_id",
        hard: "",
      },
      {
        path: "entry_type",
        hard: "setting",
      },
      {
        path: "display_order",
        value: "display_order",
        hard: "",
      },
      {
        path: "parameter",
        value: "frequency",
        hard: "",
      },
      {
        path: "parameter2",
        value: "detail_level",
        hard: "",
      },
      {
        path: "current_status",
        hard: "0",
      },
      {
        path: "product",
        hard: "personal-hub",
      },
      {
        path: "description",
        value: "category_title",
        hard: "",
      },
      {
        path: "value",
        hard: "today",
      },
      {
        path: "personal_note",
        value: "category_title",
        hard: " - Currently active category for your attention",
      },
      {
        path: "product_area",
        hard: "active_minute",
      },
    ];

    data.forEach((dataItem, id) => {
      send_credential_to_registrar.data = {};
      defaultValues.forEach((defaultValue, i) => {
        if (defaultValue.value) {
          send_credential_to_registrar.data[defaultValue.path] =
            dataItem[defaultValue.value] + defaultValue.hard;
        } else {
          send_credential_to_registrar.data[defaultValue.path] =
            defaultValue.hard;
        }
      });

      if (Number(dataItem.is_default) === 1 || setting_default) {
        postActiveMinuteSettings(send_credential_to_registrar).then(
          (result) => {
            itemsProcessed++;
            if (itemsProcessed === data.length) {
              getApiData();
            }
          }
        );
      } else {
        itemsProcessed++;
        if (itemsProcessed === data.length) {
          getApiData();
        }
      }

      send_credential_to_registrar.data = {};
    });
  };

  const parseAvailableCategories = (allItems, userItems) => {
    let result = [];
    let settings_id = 0;
    let frequency = null;
    allItems.forEach((item) => {
      let flag = false;
      userItems.forEach((i) => {
        if (i.description === item.category_title) {
          settings_id = i.id;
          frequency = i.parameter;
          flag = true;
        }
      });
      if (flag) {
        result.push({
          data: item,
          checked: true,
          settings_id: settings_id,
          frequency: frequency,
        });
      } else {
        result.push({
          data: item,
          checked: false,
          settings_id: null,
          frequency: item.frequency,
        });
      }
    });
    // console.log(result);
    setAvailableCategories(result);
  };

  const parseData = (data) => {
    let dailyResult = [];
    let occasionalResult = [];

    data.forEach((item, id) => {
      if (item.parameter === "daily" && Number(item.is_default) === 1) {
        dailyResult.push({
          title: item.category_title,
          component_name: item.active_minute_component,
        });
      } else if (
        item.parameter === "occasional" &&
        Number(item.is_default) === 1
      ) {
        occasionalResult.push({
          title: item.category_title,
          component_name: item.active_minute_component,
          src: item.img_src,
        });
      }
    });

    setDaily(dailyResult);
    setOccasional(occasionalResult);
  };

  const parseUserData = (arr1, arr2) => {
    let dailyResult = [];
    let occasionalResult = [];
    let all = 0;
    let finished = 0;
    arr1.forEach((e, id) => {
      if (e.parameter === "daily") {
        arr2.forEach((i) => {
          if (Number(e.value2) === Number(i.rel_category_id)) {
            all++;
            if (Number(e.current_status) === 1) {
              finished++;
            }
            dailyResult.push({
              id: e.id,
              title: i.category_title,
              component_name: i.active_minute_component,
              status: e.current_status,
            });
          }
        });
      } else if (e.parameter === "occasional") {
        arr2.forEach((i) => {
          if (Number(e.value2) === Number(i.rel_category_id)) {
            occasionalResult.push({
              id: e.id,
              title: i.category_title,
              component_name: i.active_minute_component,
              status: e.current_status,
              src: i.img_src,
            });
          }
        });
      }
    });
    setFinishedDaily({ ...finishedDaily, all: all, finished: finished });
    setDaily(dailyResult);
    setOccasional(occasionalResult);
  };
  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeOccasional !== null) {
      setExpanded(null);
    }
    setOccasionalPanel({ ...occasionalPanel, open: true }); // eslint-disable-next-line
  }, [activeOccasional]);

  useEffect(() => {
    if (expanded !== null) {
      setActiveOccasional(null);
      setOccasionalPanel({ ...occasionalPanel, open: false });
    }
    // eslint-disable-next-line
  }, [expanded]);

  useEffect(() => {
    let temp_arr = [];
    // console.log(availableCategories);
    availableCategories.forEach((i, id) => {
      if (
        i.data.category_title
          .toUpperCase()
          .includes(searchCategoriesText.toUpperCase())
      ) {
        let obj = {
          data: i.data,
          id: i.data.id,
          settings_id: i.settings_id,
          title: i.data.category_title,
          component_name: i.data.active_minute_component,
          checked: i.checked,
          frequency: i.frequency,
        };
        temp_arr.push(obj);
      }
    });
    setFoundCategories(temp_arr);
    // console.log(temp_arr);
    // eslint-disable-next-line
  }, [searchCategoriesText]);

  // const shoultCategoryShow = item => {
  //   let flag = false;
  //   availableCategories.forEach((i, id) => {
  //     if (i.data.category_title === item.title &&
  //       i.checked &&
  //       i.data.category_title.includes(searchCategoriesText)
  //     ) {
  //       flag = true;
  //     }
  //   });
  //   return flag;
  // };

  const renderComponent = (x) => {
    switch (x.component_name) {
      case "WeightTimeLineGraph":
        return (
          <WeightTimeLineGraph
            handleSubmitIconChange={handleSubmitIconChange}
            item={x}
          />
        );
      // case "SicknessTimeLineGraph":
      //   return (
      //     <SicknessTimeLineGraph
      //       handleSubmitIconChange={handleSubmitIconChange}
      //       item={x}
      //     />
      //   );
      case "HappinessTimeLineGraph":
        return (
          <HappinessTimeLineGraph
            handleSubmitIconChange={handleSubmitIconChange}
            item={x}
          />
        );
      case "NutritionTimeLineGraph":
        return (
          <NutritionTimeLineGraph
            handleSubmitIconChange={handleSubmitIconChange}
            item={x}
          />
        );
      case "FilmsList":
        return (
          <FilmsList handleSubmitIconChange={handleSubmitIconChange} item={x} />
        );
      case "BookList":
        return (
          <BooksList handleSubmitIconChange={handleSubmitIconChange} item={x} />
        );
      case "ActiveSleep":
        return (
          <ActiveSleep
            handleSubmitIconChange={handleSubmitIconChange}
            item={x}
          />
        );
      case "ActiveDayNote":
        return (
          <ActiveDayNote
            handleSubmitIconChange={handleSubmitIconChange}
            item={x}
          />
        );
      case "Activities":
        return (
          <Activities
            handleSubmitIconChange={handleSubmitIconChange}
            element={x}
          />
        );
      case "InjuriesAndIssues":
        return (
          <InjuriesAndIssues
            handleSubmitIconChange={handleSubmitIconChange}
            element={x}
          />
        );
      case "Steps":
        return (
          <Steps handleSubmitIconChange={handleSubmitIconChange} item={x} />
        );
      case "BloodPressure":
        return (
          <BloodPressure
            handleSubmitIconChange={handleSubmitIconChange}
            element={x}
          />
        );
      case "Sickness":
        return (
          <Sickness
            handleSubmitIconChange={handleSubmitIconChange}
            element={x}
          />
        );
      default:
        break;
    }
  };

  const redirect = () => {
    if (redirected === true) {
      return <Redirect to="/HUBsite" />;
    }
  };
  const redirectToCreateAdmin = () => {
    if (redirectedToAdmin === true) {
      return <Redirect to="/createAdmin" />;
    }
  };
  const handleChangeDaily = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeOneTime = (panel) => (event, isExpanded) => {
    setOneTimeOpenId(isExpanded ? panel : false);
  };

  const handleIconClick = (item) => {
    send_credential_to_registrar.data = {};

    if (Number(item.status) === 1) {
      send_credential_to_registrar.data.current_status = "0";
    } else {
      send_credential_to_registrar.data.current_status = "1";
    }

    send_credential_to_registrar.data.id = item.id;
    putActiveMinuteSettings(send_credential_to_registrar).then(() => {
      getApiData();
      setOccasionalPanel({
        ...occasionalPanel,
        status: send_credential_to_registrar.data.current_status,
      });
    });
  };

  const handleSubmitIconChange = (item) => {
    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.current_status = "1";

    send_credential_to_registrar.data.id = item.id;
    putActiveMinuteSettings(send_credential_to_registrar).then(() => {
      getApiData();
      setOccasionalPanel({
        ...occasionalPanel,
        status: send_credential_to_registrar.data.current_status,
      });
    });
  };

  const deleteFromApi = (settings_id) => {
    send_credential_to_registrar.remove_id = settings_id;
    deleteSettings(send_credential_to_registrar).then(() => {
      setTypeModal({ ...typeModal, open: false });
      getApiData();
    });
  };

  const handleCheckBox = (entity, id) => {
    let temp = availableCategories;
    temp.forEach((item, i) => {
      if (i === id) {
        if (item.checked === false) {
          setDefaultApiData([{ ...entity.data }], true);
        } else {
          deleteFromApi(entity.settings_id);
        }
        item.checked = !item.checked;
      }
    });
    setAvailableCategories([...temp]);
  };

  const renderTypeModal = () => {
    const getTitle = () => {
      return activeItem.title;
    };
    const getType = () => {
      return typeModal.type === "daily" ? "Occasional" : "Daily";
    };

    const moveItem = () => {
      if (typeModal.type === "daily") {
        changeEntryType(activeItem, "daily");
      } else {
        changeEntryType(occasionalPanel, "occasional");
        setActiveOccasional(null);
      }
      setTypeModal({ open: false, type: "" });
    };

    const addToSettings = (freq) => {
      let temp_item = { ...activeItem.data, frequency: freq };
      setDefaultApiData([{ ...temp_item }], true);
      let arr = oneTime;
      arr.splice(typeModal.oneTimeID, 1);
      setOneTime(arr);
      setOneTimeOpenId(null);
    };

    return (
      <Modal
        open={typeModal.open}
        onClose={() => setTypeModal({ open: false, type: "" })}
      >
        <div className="active_minute_modal_block">
          <div
            className="active_minute_modal_close"
            onClick={() => setTypeModal({ open: false, type: "" })}
          >
            <CloseIcon />
          </div>

          <div className="type_modal_title">{getTitle()}</div>
          {typeModal.type === "one_time" ? (
            <>
              <div className="type_modal_text">save to</div>
              <button
                onClick={() => {
                  addToSettings("daily");
                  setTypeModal({ open: false, type: "" });
                }}
                style={{ textTransform: "capitalize" }}
              >
                {intl.formatMessage({
                  id: "active_minute_daily",
                })}
              </button>
              <div className="type_modal_text">or</div>
              <button
                onClick={() => {
                  addToSettings("occasional");
                  setTypeModal({ open: false, type: "" });
                }}
              >
                {intl.formatMessage({
                  id: "active_minute_occasional",
                })}
              </button>
            </>
          ) : (
            <>
              <div className="type_modal_text">move to</div>
              <button
                onClick={() => moveItem()}
                style={{ textTransform: "capitalize" }}
              >
                {getType()}
              </button>
              <div className="type_modal_text">or</div>
              <button
                onClick={() => {
                  getType() === "Daily"
                    ? deleteFromApi(occasionalPanel.id)
                    : deleteFromApi(activeItem.id);
                }}
              >
                {intl.formatMessage({
                  id: "active_minute_hide",
                })}
              </button>
            </>
          )}
        </div>
      </Modal>
    );
  };

  const handleFoundCategoryClick = (item) => {
    if (!item.checked) {
      //if clicked item isn't assigned yet add it to one time, if it is in one time, open it as One time

      let one_time_index = oneTime.findIndex(
        (element) => Number(element.data.id) === Number(item.data.id)
      );
      if (one_time_index === -1) {
        setOneTimeOpenId(oneTime.length);
        setOneTime([...oneTime, item]);
      } else {
        setOneTimeOpenId(one_time_index);
      }
      setSearchCategoriesText("");
    } else {
      if (item.frequency === "daily") {
        //if clicked item is from daily open it

        let daily_index = daily.findIndex(
          (element) => Number(element.id) === Number(item.settings_id)
        );
        if (daily_index !== -1) {
          setExpanded(daily_index);
        }
        setSearchCategoriesText("");
      } else if (item.frequency === "occasional") {
        //if clicked item is from occasional open it

        let occasional_index = occasional.findIndex(
          (element) => Number(element.id) === Number(item.settings_id)
        );
        setActiveOccasional(occasional_index);
        setOccasionalPanel({
          ...occasionalPanel,
          id: occasional[occasional_index].id,
          title: occasional[occasional_index].title,
          component_name: occasional[occasional_index].component_name,
          status: occasional[occasional_index].status,
        });
        setSearchCategoriesText("");
      }
    }
  };

  const renderSearchResult = () => {
    if (foundCategories.length > 0 && searchCategoriesText !== "") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50px",
            left: "0px",
            backgroundColor: "#fff",
            zIndex: "100",
            width: "100%",
            boxShadow: "0px 6px 10px 0px rgba(199,199,199,1)",
            borderRadius: "4px",
          }}
        >
          {foundCategories.map((item, id) => {
            let check_box = (
              <Checkbox
                className={classes.checkBox}
                checked={item.checked}
                disabled
                color="primary"
              />
            );
            if (item.frequency === "daily") {
              check_box = (
                <YellowCheckbox
                  className={classes.checkBox}
                  checked={item.checked}
                  disabled
                />
              );
            } else if (item.frequency === "occasional") {
              check_box = (
                <OrangeCheckbox
                  className={classes.checkBox}
                  checked={item.checked}
                  disabled
                />
              );
            }
            return (
              <div
                key={id}
                style={{
                  margin: "0px 10px",
                  padding: "7px",
                  borderBottom: "1px solid rgba(199,199,199,0.5)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  handleFoundCategoryClick(item);
                }}
              >
                <div>
                  {check_box}
                  {item.data.category_title}
                </div>
                {item.checked ? (
                  <div style={{ fontSize: "10px" }}>{item.frequency}</div>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const getCategoryItem = (item, id) => {
    let flag = false;
    if (select === "all") {
      flag = true;
    } else if (select === "checked" && item.checked) {
      flag = true;
    } else if (select === "unchecked" && !item.checked) {
      flag = true;
    } else if (
      select === "daily" &&
      item.frequency === "daily" &&
      item.checked
    ) {
      flag = true;
    } else if (
      select === "occasional" &&
      item.frequency === "occasional" &&
      item.checked
    ) {
      flag = true;
    } else {
      flag = false;
    }
    if (flag) {
      let check_box = (
        <Checkbox
          className={classes.checkBox}
          checked={item.checked}
          onChange={() => handleCheckBox(item, id)}
          color="primary"
        />
      );
      if (item.frequency === "daily") {
        check_box = (
          <YellowCheckbox
            className={classes.checkBox}
            checked={item.checked}
            onChange={() => handleCheckBox(item, id)}
          />
        );
      } else if (item.frequency === "occasional") {
        check_box = (
          <OrangeCheckbox
            className={classes.checkBox}
            checked={item.checked}
            onChange={() => handleCheckBox(item, id)}
          />
        );
      }
      return (
        <TableRow onClick={() => {}} key={id} className={classes.row}>
          <TableCell className={classes.cell} key={id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {check_box}
              {item.data.category_title}
            </div>
            {item.checked ? (
              <div style={{ fontSize: "10px" }}>{item.frequency}</div>
            ) : null}
          </TableCell>
        </TableRow>
      );
    }
  };

  const changeEntryType = (item, parameter) => {
    send_credential_to_registrar.data = {};

    if (parameter === "daily") {
      send_credential_to_registrar.data.parameter = "occasional";
    } else {
      send_credential_to_registrar.data.parameter = "daily";
      setActiveOccasional(null);
    }

    send_credential_to_registrar.data.id = item.id;
    putActiveMinuteSettings(send_credential_to_registrar).then(() => {
      getApiData();
    });
  };

  if (logged_in_as === "admin") {
    return (
      <div className={classes.root}>
        {redirect()}
        {renderTypeModal()}
        <img
          className="active_minute_bg"
          src="/assets/images/background_img_hubsite.png"
          alt=""
        />
        <div
          className="active_minute_back_button"
          onClick={() => setRedirected(true)}
        >
          <ArrowBackIcon />{" "}
          {intl.formatMessage({
            id: "active_minute_back",
          })}
        </div>
        <div
          className="active_minute_settings_button"
          onClick={() => setActiveMinuteSettings(true)}
        >
          <SettingsIcon />
        </div>
        <div className="active_minute_title_text">
          {intl.formatMessage({
            id: "active_minute_title",
          })}
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: "0 auto",
          }}
        >
          <input
            className="search_categories_input"
            value={searchCategoriesText}
            placeholder="Search"
            onChange={(event) => setSearchCategoriesText(event.target.value)}
          />
          {searchCategoriesText !== "" ? (
            <CloseIcon
              style={{ position: "absolute", top: "15px", right: "2px" }}
              onClick={() => setSearchCategoriesText("")}
            />
          ) : (
            <SearchIcon
              style={{ position: "absolute", top: "15px", right: "2px" }}
            />
          )}
          {renderSearchResult()}
        </div>
        {/* <div className="active_minute_search_container">
        <Paper className="active_minute_search">
          <InputBase
            className="active_minute_input"
            placeholder={intl.formatMessage({
              id: "virtual_assistant_search"
            })}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton className="icon-button" aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div> */}
        <div className="active_minute_block_title_container">
          <div className="active_minute_block_title_text">
            {intl.formatMessage({
              id: "active_minute_daily",
            })}
          </div>
          <div
            style={
              finishedDaily.finished === finishedDaily.all
                ? { color: "green" }
                : null
            }
            className="active_minute_block_title_counter"
          >
            {finishedDaily.finished}/{finishedDaily.all}
          </div>
        </div>
        {daily.map((item, id) => {
          return (
            <div key={id} style={{ position: "relative" }}>
              <button
                className="panel_btn"
                onClick={() => {
                  setActiveItem(item);
                  setTypeModal({ open: true, type: "daily" });
                }}
              >
                <BookmarkIcon
                  style={
                    expanded === id ? { color: "#fff" } : { color: "#3FA5FF" }
                  }
                />
              </button>
              {item.status !== undefined ? (
                Number(item.status) === 0 ? (
                  <img
                    alt=""
                    className="panel_icon"
                    src="/assets/images/table/orange.svg"
                    onClick={() => handleIconClick(item)}
                  />
                ) : (
                  <img
                    alt=""
                    className="panel_icon"
                    src="/assets/images/table/green.svg"
                    onClick={() => handleIconClick(item)}
                  />
                )
              ) : null}
              <ExpansionPanel
                expanded={expanded === id}
                onChange={handleChangeDaily(id)}
                className="active_minute_expansion_panel"
              >
                <ExpansionPanelSummary
                  aria-controls="1a-content"
                  id="1a-header"
                  className={expanded === id ? "bgColor" : ""}
                >
                  <Typography className={classes.heading}>
                    {intl.formatMessage({
                      id: item.title,
                    })}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {renderComponent(item)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          );
        })}
        <div className="active_minute_block_title_text">
          {intl.formatMessage({
            id: "active_minute_occasional",
          })}
        </div>
        {activeOccasional !== null && occasionalPanel.open ? (
          <div style={{ position: "relative" }}>
            <button
              className="panel_btn occasional"
              onClick={() => {
                setActiveItem(occasionalPanel);
                setTypeModal({ open: true, type: "occasional" });
              }}
            >
              <BookmarkBorderIcon />
            </button>
            {occasionalPanel.status !== undefined ? (
              Number(occasionalPanel.status) === 0 ? (
                <img
                  alt=""
                  className="panel_icon"
                  src="/assets/images/table/orange.svg"
                  onClick={() => handleIconClick(occasionalPanel)}
                />
              ) : (
                <img
                  alt=""
                  className="panel_icon"
                  src="/assets/images/table/green.svg"
                  onClick={() => handleIconClick(occasionalPanel)}
                />
              )
            ) : null}
            <ExpansionPanel
              expanded={occasionalPanel.open}
              onChange={() => {
                setOccasionalPanel({
                  ...occasionalPanel,
                  open: !occasionalPanel.open,
                });
              }}
              className="active_minute_occasional_panel"
            >
              <ExpansionPanelSummary
                aria-controls="1a-content"
                id="1a-header"
                className={occasionalPanel.open ? "bgColor" : ""}
              >
                <Typography className={classes.heading}>
                  {intl.formatMessage({
                    id: occasionalPanel.title,
                  })}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {renderComponent(occasionalPanel)}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        ) : null}
        <div className="occasional_container">
          {occasional.map((item, id) => {
            return (
              <div
                key={id}
                className="occasional_item"
                onClick={() => {
                  if (activeOccasional !== id) {
                    setActiveOccasional(id);
                    setActiveItem({});
                    setOccasionalPanel({
                      ...occasionalPanel,
                      id: item.id,
                      title: item.title,
                      component_name: item.component_name,
                      status: item.status,
                    });
                  } else {
                    setActiveOccasional(null);
                  }
                }}
              >
                <img
                  alt=""
                  src={item.src}
                  className={
                    "occasional_item_img " +
                    (activeOccasional === id ? " active" : "")
                  }
                />
                <div className="occasional_item_title">
                  {intl.formatMessage({
                    id: item.title,
                  })}
                </div>
                {item.status === "1" ? (
                  <img
                    alt=""
                    src="/assets/images/table/green.svg"
                    className="occasional_image_icon"
                  />
                ) : null}
              </div>
            );
          })}
        </div>

        {oneTime.length > 0 || oneTimeShowAvailable ? (
          <div className="active_minute_block_title_container">
            <div className="active_minute_block_title_text">
              {intl.formatMessage({
                id: "active_minute_one_time",
              })}
            </div>
          </div>
        ) : null}

        {oneTime.length > 0 ? (
          <>
            {oneTime.map((item, id) => {
              return (
                <div key={id} style={{ position: "relative" }}>
                  <button
                    className="panel_btn"
                    onClick={() => {
                      setActiveItem(item);
                      setTypeModal({
                        open: true,
                        type: "one_time",
                        oneTimeID: id,
                      });
                    }}
                  >
                    <BookmarkIcon
                      style={
                        oneTimeOpenId === id
                          ? { color: "#fff" }
                          : { color: "#3FA5FF" }
                      }
                    />
                  </button>
                  <ExpansionPanel
                    expanded={oneTimeOpenId === id}
                    onChange={handleChangeOneTime(id)}
                    className="active_minute_expansion_panel"
                  >
                    <ExpansionPanelSummary
                      aria-controls="1a-content"
                      id="1a-header"
                      className={oneTimeOpenId === id ? "bgColor" : ""}
                    >
                      <Typography className={classes.heading}>
                        {intl.formatMessage({
                          id: item.title,
                        })}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {renderComponent(item)}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              );
            })}{" "}
          </>
        ) : null}

        <div className="one_time_container">
          <Button
            onClick={() => {
              setOneTimeShowAvailable(!oneTimeShowAvailable);
            }}
            variant="contained"
          >
            <AddCircleIcon className={classes.add_circle_icon} />
            {intl.formatMessage({
              id: "active_minute_show_more",
            })}
          </Button>
        </div>

        {availableCategories.length > 0 && oneTimeShowAvailable ? (
          <div className="one_time_container">
            {availableCategories.map((item, id) => {
              // console.log(oneTime);
              let already_displayed = oneTime.findIndex(
                (element) => Number(element.id) === Number(item.data.id)
              );

              if (!item.checked && already_displayed === -1) {
                return (
                  <div
                    key={id}
                    className="one_time_item"
                    onClick={() => {
                      handleFoundCategoryClick({
                        data: item.data,
                        id: item.data.id,
                        settings_id: item.settings_id,
                        title: item.data.category_title,
                        component_name: item.data.active_minute_component,
                        checked: item.checked,
                        frequency: item.frequency,
                      });
                      setOneTimeShowAvailable(false);
                    }}
                  >
                    <img
                      alt=""
                      src={item.data.img_src}
                      className="one_time_item_img"
                    />
                    <div className="one_time_item_title">
                      {intl.formatMessage({
                        id: item.data.category_title,
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        ) : null}

        <SwipeableDrawer
          anchor="right"
          open={activeMinuteSettings}
          onOpen={() => setActiveMinuteSettings(true)}
          onClose={() => setActiveMinuteSettings(false)}
        >
          <div>
            <div className={classes.activeMinuteSettingsTitle}>
              Active minute settings
            </div>
            <div
              style={{
                position: "absolute",
                top: "2px",
                right: "2px",
              }}
              onClick={() => setActiveMinuteSettings(false)}
            >
              <CloseIcon />
            </div>
            <div className="filters_container">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "virtual_assistant_search",
                  })}
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                />
                {searchText !== "" ? (
                  <CloseIcon
                    style={{ position: "absolute", top: "5px", right: "2px" }}
                    onClick={() => setSearchText("")}
                  />
                ) : (
                  <SearchIcon
                    style={{ position: "absolute", top: "5px", right: "2px" }}
                  />
                )}
              </div>
              <select
                value={select}
                onChange={(event) => setSelect(event.target.value)}
              >
                <option value="all">
                  {intl.formatMessage({
                    id: "receipt_shop_icons_all",
                  })}
                </option>
                <option value="checked">
                  {intl.formatMessage({
                    id: "active_minute_checked",
                  })}
                </option>
                <option value="unchecked">
                  {intl.formatMessage({
                    id: "active_minute_unchecked",
                  })}
                </option>
                <option value="occasional">
                  {intl.formatMessage({
                    id: "active_minute_occasional",
                  })}
                </option>
                <option value="daily">
                  {intl.formatMessage({
                    id: "active_minute_daily",
                  })}
                </option>
              </select>
            </div>
            {availableCategories !== null ? (
              <Table className={classes.table} size="small">
                <TableBody>
                  {availableCategories.map((item, id) => {
                    if (
                      searchText !== "" &&
                      item.data.category_title
                        .toUpperCase()
                        .includes(searchText.toUpperCase())
                    ) {
                      return getCategoryItem(item, id);
                    } else if (searchText === "") {
                      return getCategoryItem(item, id);
                    } else {
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            ) : null}
          </div>
        </SwipeableDrawer>
      </div>
    );
  } else {
    return (
      <Modal open={modal} onClose={() => setRedirected(true)}>
        <div className="active_minute_modal_block">
          {redirect()}
          {redirectToCreateAdmin()}
          <div
            className="active_minute_modal_close"
            onClick={() => setRedirected(true)}
          >
            <CloseIcon />
          </div>
          <div>
            {intl.formatMessage({
              id: "active_minute_public_user_pt1",
            })}
          </div>
          <img
            style={{ margin: "10px" }}
            src="/assets/images/active_minute_screenshot.jpg"
            alt="Active minute screenshot"
          />
          <div>
            (
            {intl.formatMessage({
              id: "active_minute_public_user_pt2",
            })}{" "}
            <span
              onClick={() => setRedirectedToAdmin(true)}
              style={{ color: "#3FA4FF" }}
            >
              {intl.formatMessage({
                id: "hubsite_index_here",
              })}
            </span>
            )
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(ActiveMinute);
