import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const FitnessTimeLineGraph = ({
  target_hub_id,
  requestorHubID,
  apiRequest
}) => {
  const [apiData, setApiData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [barWidth, setBarWidth] = useState(300);

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = requestorHubID;
    send_credential_to_registrar.section = "steps";
    //variables for dynamic created query for setting permissions

    apiRequest(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        setApiData([]);
      } else {
        setApiData(result.data.data_array);
      }
    });
  };

  const parseApiData = () => {
    let apiDataToMap = apiData.sort(function(a, b) {
      return new Date(a.date_on) - new Date(b.date_on);
    });

    let chart = [["Date", "Steps"]];
    let newBarWidth = 0;
    apiDataToMap.map(item => {
      newBarWidth += 20;
      return chart.push([item.date_on, Number(item.value)]);
    });

    setBarWidth(barWidth + newBarWidth);
    setChartData(chart);
    setTimeout(() => {
      let chart = document.getElementById("chart");
      if (chart && chart.offsetWidth === document.documentElement.clientWidth) {
        chart.scrollLeft += 1000;
      }
    }, 2000);
  };
  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    parseApiData();
    // eslint-disable-next-line
  }, [apiData.length]);
  if (apiData.length !== 0) {
    return (
      <div
        style={{
          width: "fit-content",
          maxWidth: "100%",
          overflow: "scroll",
          margin: "0px auto 100px",
          scrollBehavior: "smooth"
        }}
        id="chart"
      >
        <Chart
          style={{ margin: "0 auto" }}
          width={barWidth}
          height={"200px"}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            vAxis: {
              title: "Intensity"
            },
            series: {
              1: { curveType: "function" }
            }
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};
export default FitnessTimeLineGraph;
