import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { postFriendsList } from "../../../../services/PostHUBsiteData";
import { getPeoples, postPermissions } from "../../../../services/Permissions";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  modal: {
    position: "relative",
    maxWidth: "80%",
    maxHeight: "80%",
    top:"50%",
    left:"50%",
    transform:"translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "column"
  },
  modalTitle: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px"
  },
  inputBlock: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "5px auto"
  },
  inputTitle: {
    minWidth: "130px"
  },
  inputField: {
    borderRadius: "5px",
    border: "1px solid #E4E4E4",
    width: "100%",
    paddingLeft: "5px"
  },
  bntBlock: {
    display: "flex",
    width: "100%",
    margin: "10px"
  },
  button: {
    margin: "0 auto",
    width: "150px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px"
  },
  row: {
    borderBottom: "1px dashed #cacaca"
  },
  cell: {
    border: "none"
  }
});

const AddUserModal = ({
  openAddModal,
  setOpenAddModal,
  target_hub_id,
  table_name,
  get_users,
  currentSection
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [hubID, setHubID] = useState("");
  const [peoples, setPeoples] = useState([]);

  const get_peoples = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };

    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    getPeoples(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          // console.log(result.data.error);
          alert(result.data.error);
        } else {
          // console.log(result.data.data_array);
          setPeoples(result.data.data_array);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (openAddModal) {
      get_peoples();
    }
    // eslint-disable-next-line
  }, [openAddModal]);

  const postFriendsData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };

    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.name = name !== "" ? name : null;
    send_credential_to_registrar.data.counterpart_hub_id = hubID;

    postFriendsList(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          // console.log(result.data.error);
          alert(result.data.error);
        } else {
          // setOpenAddModal(false);
          // console.log(result.data.success);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const postPermissionsData = param => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };

    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.target = "table";
    send_credential_to_registrar.data.values = {
      table_name: table_name,
      section: currentSection,
      requestor_hub_id: param === null ? hubID : param,
      granted: null,
      granted_start: null,
      granted_end: null
    };

    postPermissions(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          // console.log(result.data.error);
          setOpenAddModal(false);
        } else {
          // console.log(result.data.success);
          setName("");
          setHubID("");
          get_users();
          setOpenAddModal(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openAddModal}
    >
      <div className={classes.root}>
        <div className={classes.modal}>
          <span className={classes.modalTitle}>Add data</span>
          <div className={classes.inputBlock}>
            <div className={classes.inputTitle}>HUB ID: </div>
            <input
              type="number"
              placeholder="1234"
              className={classes.inputField}
              value={hubID}
              onChange={event => setHubID(event.target.value)}
            />
          </div>
          <div className={classes.inputBlock}>
            <div className={classes.inputTitle}>Name (optional): </div>
            <input
              type="text"
              placeholder="Name"
              className={classes.inputField}
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </div>
          <div className={classes.bntBlock}>
            <button
              className={classes.button}
              onClick={() => {
                postFriendsData();
                postPermissionsData(null);
              }}
            >
              + Add
            </button>
            <button
              className={classes.button}
              onClick={() => setOpenAddModal(false)}
            >
              Cancel
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div style={{ alignSelf: "center" }}>or</div>
            <div style={{ alignSelf: "center" }}>
              add a previously used HUB:
            </div>
            <div
              style={{
                height: "215px",
                overflow: "auto",
                margin: "20px"
              }}
            >
              <Table className={classes.table} size="small">
                <TableBody>
                  {peoples.map((item, id) => {
                    return (
                      <TableRow className={classes.row} key={id}>
                        <TableCell className={classes.cell}>
                          {parseInt(
                            item.requestor_hub_id
                              .replace(/hub_/g, "")
                              .replace(/_/g, ""),
                            10
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              postPermissionsData(item.requestor_hub_id);
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
