import React, { Component } from "react";
import "../../screens/Navigator/Navigator.css";
import { ProdGroupImgAndName } from "./ProductGroupFunction";

class Filter extends Component {
  constructor() {
    super();
    this.state = {};
  }

  showFilterHandler = () => {
    this.setState({ display_filters: !this.state.display_filters });
  };

  render() {
    const props_data = this.props.state_data;
    let cat_filter;
    if (this.props.cat_filter) {
      cat_filter = this.props.cat_filter.map(r => (
        <img
          key={r}
          onClick={() => {
            this.props.selectedFilter === r
              ? this.props.navigationFilterCallback(null)
              : this.props.navigationFilterCallback(r);
          }}
          className={
            this.props.selectedFilter === r
              ? "filter_icon_img filter_selected"
              : "filter_icon_img"
          }
          alt=""
          src={ProdGroupImgAndName("img", r)}
          title={ProdGroupImgAndName("name", r)}
        />
      ));
    }

    return (
      <div id="Filter">
        {props_data.book_info.length !== 0 &&
        (!props_data.selected_book && !props_data.selected_group) ? (
          <div className="navigation_view_switch">
            <div
              className="navigation_view_switch_list"
              onClick={() => {
                this.props.handleButtonClick("list");
              }}
              style={
                props_data.carouselView ? null : { backgroundColor: "grey" }
              }
            >
              <img
                alt=""
                src="/assets/images/list_icon.png"
                style={
                  props_data.carouselView ? null : { filter: "invert(100%)" }
                }
              />
            </div>
            <div
              className="navigation_view_switch_carousel"
              onClick={() => {
                this.props.handleButtonClick("carousel");
              }}
              style={
                props_data.carouselView ? { backgroundColor: "grey" } : null
              }
            >
              <img
                alt=""
                src="/assets/images/carousel_icon.png"
                style={
                  props_data.carouselView ? { filter: "invert(100%)" } : null
                }
              />
            </div>
          </div>
        ) : null}

        {this.props.selectedFilter === null ? (
          <img
            alt=""
            className="filter_icon_img_filter"
            src={"/assets/images/filter_icon_grey.png"}
          />
        ) : (
          <img
            alt=""
            className="filter_icon_img_filter"
            onClick={() => {
              this.props.navigationFilterCallback(null);
            }}
            src={"/assets/images/filter_icon_close_grey.png"}
          />
        )}
        <div className="filter_icons_container">{cat_filter}</div>
      </div>
    );
  }
}

export default Filter;
