import { URLconst } from "./const";
import axios from "axios";

export function putUnseenToSeen(param) {
  let BaseURL = URLconst("hubsite/finances/unseen_receipts.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: null
  });
}

export function putCovid19MedValData(param) {
  let BaseURL = URLconst("hubsite/health/covid19_medval.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function putCovid19PeopDetData(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/covid19_peopdet.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
