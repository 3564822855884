import React from "react";
import "../VirtualAssistant";
import "./message.scss";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const HistoryItem = ({ item, repeat_search, user_avatar, user_info }) => {
  //if hour or minute is less than 10 add 0 at front
  var time_extracted = moment(item.search_time).format("HH:mm");

  var date_extracted = moment(item.search_time).format("DD/MM/YYYY");

  var nickname_to_use = <FormattedMessage id="virtual_assistant_user" />;

  if (user_info !== null) {
    if (user_info.nickname !== "") {
      nickname_to_use = user_info.nickname;
    }
  }

  if (item.type === "date") {
    return <div className="chat-date-divider">{date_extracted}</div>;
  }

  return (
    <div className="message-item-right">
      <div className="message-info">
        <div className="message-info-text">
          <div className="message-info-text-title">{nickname_to_use}</div>
          <div>{time_extracted}</div>
        </div>
        <div className="message-avatar">
          <img
            className="message_avatar_user"
            src={
              user_avatar !== null
                ? user_avatar
                : "/assets/images/user_icon.svg"
            }
            alt=""
          />
        </div>
      </div>
      <div className="history-block">
        <div className="history-settings">
          <div className="history-settings-icon">
            <img
              onClick={() => {
                repeat_search(item.value);
              }}
              src="/assets/images/Update.svg"
              alt=""
            />
          </div>
        </div>
        <div className="history-value">{item.value}</div>
      </div>
    </div>
  );
};
export default HistoryItem;
