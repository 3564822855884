import React from "react";
import "./avatar.css";
import { getProfileBasics } from "../../services/getProfileBasics";
import { SetAvatar } from "../../services/SetAvatar";
import { SetProfilePicture } from "../../services/SetProfilePicture";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";

import AvatarEditor from "react-avatar-editor";

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: {},
      avatar_load: true,
      avatar_to_show: "",

      image: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 50,
      preview: null,
      width: 250,
      height: 250,

      redirected: false
    };
  }

  componentDidMount() {
    if (this.state.avatar_load) {
      this.get_profile_data();
      this.setState({ avatar_load: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.avatar_to_show !== this.state.avatar_to_show) {
      this.get_profile_data();
    }
  }

  get_profile_data = () => {
    let send_credential_to_registrar = {};
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "";
    getProfileBasics(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.data.userData) {
        // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState({ user_data: receiptJson.data.userData }, function() {
          this.get_avatar();
        });
      } else {
        this.get_avatar();
      }
    });
  };

  get_avatar = () => {
    if (this.state.user_data.profile_picture === null) {
      if (this.state.user_data.avatar !== null) {
        this.setState({
          avatar_to_show: `/assets/images/${this.state.user_data.avatar}`
        });
      } else {
        this.setState({
          avatar_to_show: `/assets/images/person_icon.png`
        });
      }
    } else {
      this.setState({ avatar_to_show: this.state.user_data.profile_picture });
    }
  };

  set_avatar = val => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.new_avatar = val;

    SetAvatar(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.success) {
        // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.get_profile_data();
      }
    });
  };

  set_profilepicture = val => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.new_profilepicture = val;

    SetProfilePicture(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.success) {
        // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.get_profile_data();
        this.setState({ ...this.state, redirected: true });
      }
    });
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] });
  };

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState(
      {
        preview: {
          img,
          rect,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          borderRadius: this.state.borderRadius
        }
      },
      function() {
        this.set_profilepicture(this.state.preview.img);
      }
    );
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };
  redirect = () => {
    if (this.state.redirected === true) {
      return <Redirect to="/HUBsite" />;
    }
  };
  render() {
    return (
      <div className="Avatar">
        <img
          className="bsb_top_back_arrow"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={() => this.setState({ ...this.state, redirected: true })}
        />
        {this.redirect()}
        <div id="avatar_grid_container">
          <div id="avatar_editor_preview">
            <AvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(this.state.scale)}
              width={this.state.width}
              height={this.state.height}
              position={this.state.position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(this.state.rotate)}
              borderRadius={this.state.width / (100 / this.state.borderRadius)}
              onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
              onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
              onImageReady={this.logCallback.bind(this, "onImageReady")}
              image={this.state.image}
              style={{
                width: "100%",
                height: "auto",
                gridArea: "left1",
                margin: "auto 0"
              }}
              className="avatar_editor_canvas"
            />
            <div className="avatar_editor_rotate">
              <button onClick={this.rotateRight}>&#8635;</button>
            </div>

            <div className="avatar_editor_zoom">
              <input
                name="scale"
                type="range"
                onChange={this.handleScale}
                min={this.state.allowZoomOut ? "0.1" : "1"}
                max="2"
                step="0.01"
                defaultValue="1"
              />
            </div>
          </div>

          <div className="avatar_grid_right_column_grid_container">
            <div className="set_avatar_text">
              <FormattedMessage id="choose_avatar_instructions" />
            </div>
            <div id="avatar_carousel">
              <span onClick={this.set_avatar.bind(this, "avatar1.png")}>
                <img alt="" src="/assets/images/avatar1.png" />
              </span>
              <span onClick={this.set_avatar.bind(this, "avatar2.png")}>
                <img alt="" src="/assets/images/avatar2.png" />
              </span>
              <span onClick={this.set_avatar.bind(this, "avatar3.png")}>
                <img alt="" src="/assets/images/avatar3.png" />
              </span>
            </div>

            <div className="avatar_editor_selectfile">
              <input
                name="newImage"
                type="file"
                onChange={this.handleNewImage}
              />
            </div>

            <div className="avatar_upload_text">
              <FormattedMessage id="choose_own_profile_pic_instructions" />
            </div>

            <div className="avatar_editor_submit">
              {this.state.image !== "" ? (
                <button onClick={this.handleSave}>
                  <FormattedMessage id="avatar_upload_button" />
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Avatar;
