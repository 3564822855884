import React, { useState, useEffect } from "react";
import Rating from "react-rating";
import "./styles.css";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { getActivities } from "../../../../services/GetActiveMinuteData";
import { putActivities } from "../../../../services/PutActiveMinuteData";
import { postActivities } from "../../../../services/PostActiveMinuteData";
import { injectIntl } from "react-intl";

const TableItem = ({
  data,
  day,
  edit,
  setEdit,
  currentId,
  handleSubmitIconChange,
  element,
  intl
}) => {
  const [title, setTitle] = useState("");
  const [activityData, setActivityData] = useState({});
  const [editActivity, setEditActivity] = useState({});
  const [open, setOpen] = useState(false);
  const [footerData, setFooterData] = useState({});
  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", "")
  };
  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  useEffect(() => {
    cleanAll();
    getActivityData(); // eslint-disable-next-line
  }, [data, day]);

  const getKeys = () => {
    return Object.keys(activityData);
  };

  const cleanAll = () => {
    setActivityData({});
    setEditActivity({});
  };

  const getActivityData = () => {
    if (day === "yesterday") {
      send_credential_to_registrar.provided_date = moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    } else {
      send_credential_to_registrar.provided_date = moment().format(
        "YYYY-MM-DD"
      );
    }
    getActivities(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result)
        parseActivityData([]);
      } else {
        parseActivityData(result.data.data_array);
      }
    });
  };

  const setFooterItemData = element => {
    setFooterData({
      Last_date: element.Last_date,
      Year_count: element.Year_count,
      Week_avg_year: element.Week_avg_year,
      Total_count: element.Total_count
    });
  };

  const parseActivityData = arr => {
    cleanAll();
    let flag = true;
    if (arr.length !== 0) {
      arr.forEach(element => {
        if (element.id !== null && element.related_activity_id === data.id) {
          setTitle(element.short_title);
          setActivityData(element);
          flag = false;
        } else {
          if (flag) {
            setTitle(data.title);
            setActivityData({});
            flag = false;
          }
        }
        if (element.related_activity_id === data.id) {
          setFooterItemData(element);
        }
      });
    } else {
      setTitle(data.title);
    }
  };

  const shortLayout = () => {
    return (
      <div className="short_view_container">
        {/* <div className="short_date">
          {moment(activityData.date_on).format("MMM Do")}
        </div> */}
        <div className="short_location">{activityData.location}</div>
        <div className="short_note">{activityData.personal_note}</div>
        <div className="short_rating">
          <img src="/assets/images/Star filled.svg" alt="star" />
          <span>{activityData.rating !== null ? activityData.rating : 0}</span>
        </div>
        <div className="short_icon" onClick={() => setEdit(currentId)}>
          <EditIcon />
        </div>
      </div>
    );
  };

  const editLayout = () => {
    return (
      <div
        className="edit_view_container"
        style={
          open
            ? { padding: "5px", maxHeight: "300px" }
            : { maxHeight: "0px", overflow: "hidden" }
        }
      >
        <div className="edit_date">
          <div>
            {intl.formatMessage({
              id: "active_minute_date"
            })}
          </div>
          <input
            value={
              editActivity.date_on !== undefined
                ? moment(editActivity.date_on).format("YYYY-MM-DD")
                : day === "yesterday"
                ? moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")
            }
            type="date"
            onChange={event =>
              setEditActivity({ ...editActivity, date_on: event.target.value })
            }
          />
        </div>
        <div className="edit_location">
          <div>
            {intl.formatMessage({
              id: "active_minute_location"
            })}
          </div>
          <input
            value={
              editActivity.location !== undefined
                ? editActivity.location
                : data.location
            }
            type="text"
            onChange={event =>
              setEditActivity({ ...editActivity, location: event.target.value })
            }
          />
        </div>
        <div className="edit_note">
          <div>
            {intl.formatMessage({
              id: "active_minute_note"
            })}
          </div>
          <input
            value={
              editActivity.personal_note !== undefined
                ? editActivity.personal_note
                : ""
            }
            type="text"
            onChange={event =>
              setEditActivity({
                ...editActivity,
                personal_note: event.target.value
              })
            }
          />
        </div>
      </div>
    );
  };

  const getEdit = () => {
    if (currentId === edit) {
      return true;
    } else {
      return false;
    }
  };

  const getLayout = () => {
    if (getKeys().length !== 0 && getEdit() === false) {
      return shortLayout();
    } else if (getKeys().length !== 0 && getEdit() === true) {
      if (Object.keys(editActivity).length === 0) {
        setEditActivity(activityData);
        handleOpen();
      }
      return editLayout();
    } else {
      return editLayout();
    }
  };

  const getFinished = () => {
    if (getKeys().length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpen = () => {
    if (getKeys().length === 0) {
      setOpen(!open);
    } else if (getKeys().length !== 0 && getEdit()) {
      setOpen(true);
    }
  };

  const sendValueToApi = () => {
    if (editActivity.related_activity_id) {
      send_credential_to_registrar.data = {
        id: editActivity.id,
        date_on: moment(editActivity.date_on).format("YYYY-MM-DDTHH:mm:ss"),
        location:
          editActivity.location !== undefined
            ? editActivity.location
            : data.location,
        personal_note: editActivity.personal_note,
        rating: editActivity.rating
      };
      putActivities(send_credential_to_registrar).then(result => {
        getActivityData();
        if (result.data.success) {
          handleSubmitIconChange(element);
        }
      });
    } else {
      let date_var = moment(editActivity.date_on).format("YYYY-MM-DDTHH:mm:ss");
      if (editActivity.date_on === undefined) {
        if (day === "yesterday") {
          date_var = moment()
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        } else {
          date_var = moment().format("YYYY-MM-DD");
        }
      }

      send_credential_to_registrar.data = {
        related_activity_id: data.id,
        short_title: data.title,
        location:
          editActivity.location !== undefined
            ? editActivity.location
            : data.location,
        rating: editActivity.rating,
        date_on: date_var,
        personal_note: editActivity.personal_note
      };

      postActivities(send_credential_to_registrar).then(result => {
        getActivityData();
        if (result.data.success) {
          handleSubmitIconChange(element);
        }
      });
    }
  };

  const handleSave = () => {
    setEdit(null);
    setOpen(false);
    sendValueToApi();
    setEditActivity({});
  };
  const handleCancel = () => {
    setEdit(null);
    setOpen(false);
    setEditActivity({});
    getActivityData();
  };

  const renderFooterInfo = () => {
    if (Object.keys(footerData).length !== 0) {
      return (
        <div className="footer_info">
          <div>
            <img alt="Total count" src="/assets/images/footer_path.svg" />
            {footerData.Total_count}
          </div>
          <div>
            <img alt="Year count" src="/assets/images/footer_calendar.svg" />
            {footerData.Year_count}
          </div>
          <div>
            <img alt="Week cal" src="/assets/images/week_calendar.png" />
            <img alt="Week avg" src="/assets/images/footer_group.svg" />
            {footerData.Week_avg_year}
          </div>
          <div>
            <img alt="Last entry" src="/assets/images/footer_past.svg" />
            {moment(footerData.Last_date).format("DD.MM.YYYY")}
          </div>
        </div>
      );
    }
  };

  const renderFooter = () => {
    if (
      (getKeys().length === 0 && open) ||
      (getKeys().length !== 0 && getEdit())
    ) {
      return (
        <>
          <div className="activity_footer">
            <Rating
              initialRating={editActivity.rating ? editActivity.rating : 0}
              stop={10}
              readonly={false}
              onClick={value =>
                setEditActivity({ ...editActivity, rating: value })
              }
              emptySymbol={<img src="/assets/images/Star.svg" alt="star" />}
              fullSymbol={
                <img src="/assets/images/Star filled.svg" alt="star" />
              }
            />
            <div style={{ display: "flex" }}>
              <button onClick={() => handleCancel()}>
                {intl.formatMessage({
                  id: "receipt_display_modal_cancel"
                })}
              </button>
              <button onClick={() => handleSave()}>
                {intl.formatMessage({
                  id: "active_minute_save"
                })}
              </button>
            </div>
          </div>
          {renderFooterInfo()}
        </>
      );
    }
  };
  return (
    <div className="activity_block">
      <div
        className={"activity_header " + (getFinished() ? "green" : "grey")}
        onClick={() => handleOpen()}
      >
        {title}
      </div>
      {getLayout()}
      {renderFooter()}
    </div>
  );
};

export default injectIntl(TableItem);
