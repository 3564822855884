import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { URLconst } from "../../../services/const";

function UploadBox({
  target_hub_id,
  loggedInAs,
  targetHubIpAddress,
  getApiData,
  currentSection
}) {
  const [file, setFile] = useState(null);
  const [sizeError, setSizeError] = useState(null);
  const url_const = URLconst("");
  const fileInputElement = useRef(null);

  // let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
  //   .userData.my_hub_id.slice(-5)
  //   .replace("_", "");

  // let send_credential_to_registrar = {
  //   target_hub_id: hubid
  // };

  // if (!sessionStorage.getItem("hub_token")) {
  //   send_credential_to_registrar.logged_in = null;
  // } else {
  //   send_credential_to_registrar.logged_in = JSON.parse(
  //     sessionStorage.getItem("hub_token")
  //   ).userData;
  // }

  useEffect(() => {
    if (file !== null) {
      let file_size = file.size;
      if (file.size > 1048576) {
        setSizeError(
          Math.round((file_size / 1024 / 1024 + Number.EPSILON) * 100) / 100
        );
        setFile(null);
        fileInputElement.current.value = "";
      } else {
        setSizeError(null);
      }
    }
    // eslint-disable-next-line
  }, [file]);

  const onChangeHandler = event => {
    setFile(event);
  };

  const onClickHandler = () => {
    if (sessionStorage.getItem("hub_token") !== null && file !== null) {
      let user_data_parsed = JSON.parse(sessionStorage.getItem("hub_token"))
        .userData;
      let extr_ip = user_data_parsed.ip_address;
      if (loggedInAs === "visitor" && targetHubIpAddress !== null) {
        extr_ip = targetHubIpAddress;
      }

      let post_address = `${url_const}PHP/proxy_testing.php?http://${extr_ip}/index.php?hub_id=${
        user_data_parsed.db_name
      }&hub_ip=${user_data_parsed.ip_address}&admin_pwd=${encodeURIComponent(
        user_data_parsed.db_pwd
      )}&username=${
        user_data_parsed.db_username
      }&target_hub=${target_hub_id}&entry_type=${currentSection}`;
      // let post_address = `${url_const}PHP/proxy_testing.php?http://${
      //   send_credential_to_registrar.logged_in.ip_address
      // }/index.php?logged_in=${encodeURIComponent(
      //   JSON.stringify(send_credential_to_registrar.logged_in)
      // )}&target_hub_id=${send_credential_to_registrar.target_hub_id}`;
      // console.log(post_address);
      const data = new FormData();
      data.append("file", file);
      data.append("btn", null);
      axios.post(post_address, data).then(() => {
        getApiData();
      });
      setFile(null);
      fileInputElement.current.value = "";
    }
  };

  return (
    <>
      <div
        style={{
          // width: "100vw",
          // height: "100vh",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            // width: "500px",
            // height: "200px",
            alignSelf: "center"

            // backgroundColor: "lightblue"
          }}
        >
          <input
            ref={fileInputElement}
            type="file"
            name="file"
            onChange={event => {
              onChangeHandler(event.target.files[0]);
            }}
          />
          <button
            type="button"
            onClick={() => {
              onClickHandler();
            }}
          >
            Upload
          </button>
        </div>
        {sizeError !== null ? (
          <div
            style={{
              alignSelf: "center",
              backgroundColor: "red",
              color: "white",
              fontWeight: "600",
              padding: "5px"
            }}
          >{`You are not allowed upload files above 1MB, your file size is ${sizeError}MB`}</div>
        ) : null}
      </div>
    </>
  );
}

export default UploadBox;
