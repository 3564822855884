import React, { useState } from "react";
import "./styles.css";
import moment from "moment";
import { injectIntl } from "react-intl";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { postAllergiesList } from "../../../../services/PostHUBsiteData";

const AddInjuriesAndIssues = ({
  openModal,
  intl,
  setAddItemModal,
  getApiData
}) => {
  const [activity, setActivity] = useState({
    entry_type: "",
    issue: "",
    personal_note: "",
    date_started: moment().format("YYYY-MM-DD")
  });

  const post_new_activity = () => {
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.target_hub_id = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData.my_hub_id;
    }
    if (activity.entry_type !== "") {
      send_credential_to_registrar.data = activity;
    } else {
      return console.log("entry type not selected");
    }

    // if (new Date(send_credential_to_registrar.data.start_date).getDate()) {
    //   console.log("true");
    // } else {
    //   console.log("false");
    // }
    // console.log(send_credential_to_registrar);

    postAllergiesList(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          // console.log(result.data.error);
          alert(result.data.error);
        } else {
          getApiData();
          setAddItemModal(false);
          // console.log(result.data.success);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Modal open={openModal} onClose={() => setAddItemModal(false)}>
      <div className="notes_modal_containter">
        <CloseIcon
          style={{ position: "absolute", right: "5px", top: "5px" }}
          onClick={() => {
            setAddItemModal(false);
          }}
        />
        <div className="notes_modal_title">
          {intl.formatMessage({
            id: "active_minute_add_inj_add_inj_or_issue"
          })}
        </div>
        <div className="acitvity_add_activity_item">
          <div>
            {intl.formatMessage({
              id: "active_minute_add_inj_type"
            })}
          </div>
          <select
            value={activity.entry_type}
            onChange={event =>
              setActivity({
                ...activity,
                entry_type: event.target.value
              })
            }
          >
            <option value="" />
            <option value={"Chronic"}>
              {intl.formatMessage({
                id: "active_minute_add_inj_chronic"
              })}
            </option>
            <option value={"Accute"}>
              {intl.formatMessage({
                id: "active_minute_add_inj_accute"
              })}
            </option>
            <option value={"Injury"}>
              {intl.formatMessage({
                id: "active_minute_add_inj_injury"
              })}
            </option>
          </select>
        </div>
        <div className="acitvity_add_activity_item">
          <div>
            {intl.formatMessage({
              id: "active_minute_add_activity_start_date"
            })}
          </div>
          <input
            type="date"
            value={activity.date_started}
            onChange={event =>
              setActivity({
                ...activity,
                date_started: event.target.value
              })
            }
          />
        </div>
        <div className="acitvity_add_activity_item">
          <div>
            {intl.formatMessage({
              id: "active_minute_inj_issue"
            })}
          </div>
          <input
            type="text"
            value={activity.issue}
            onChange={event =>
              setActivity({
                ...activity,
                issue: event.target.value
              })
            }
          />
        </div>
        <div className="acitvity_add_activity_item">
          <div>
            {intl.formatMessage({
              id: "active_minute_note"
            })}
          </div>
          <textarea
            type="text"
            placeholder=""
            rows={2}
            value={activity.personal_note}
            onChange={event =>
              setActivity({
                ...activity,
                personal_note: event.target.value
              })
            }
          />
        </div>
        <div style={{ display: "flex" }}>
          <button onClick={() => post_new_activity()}>
            {intl.formatMessage({
              id: "active_minute_save"
            })}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(AddInjuriesAndIssues);
