import React from 'react';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';
import * as _ from 'lodash';

// const getDay = (date = '0') => Number(moment(date).format('D'));
//
// const getDayOfWeek = (date = '0') => moment(date).format('dddd');

const getWeek = (date = '0') => moment(date).format('W');

const getMonth = (date = '0', type = 'M') => moment(date).format(type);

const getYear = (date = '0') => moment(date).format('YYYY');

const getDateType = (date = moment(), type = 'YYYY') => moment(date).format(type);


const showCurrentDate = (filter = 'month', date = moment()) => {
  switch (filter) {
  case 'month':
    return getDateType(date, 'MMMM');
  case 'week':
    return getDateType(date, 'W');
  case 'year':
    return getDateType(date, 'YYYY');
  default:
    return 'All';
  }
};

const sortedYearList = () => {
  let yearList = [];
  for (let i = 1; i <= 12; i++) {
    yearList = [...yearList, { month: i, name: moment().month(`${i - 1}`).format('MMMM') }];
  }
  return yearList;
};
const chartsYearDataBook = (data = [], currentDate = 2018) => sortedYearList().map((year) => {
  let modify = 0;
  data.map(item => (item.Year === getYear(currentDate)
    && Number(item.Month) === year.month)
    && (modify += Number(item.Sum)));
  return { ...year, value: Math.round(modify, 1) };
});

const chartsYearData = (data = [], currentDate = 2018, category = '') => sortedYearList().map((year) => {
  let modify = 0;
  data.map(item => ((category.value === 'all' ? true : category.value === item[category.key]) && (item.Year) === getYear(currentDate)
    && Number(item.Month) === year.month)
            && (modify += Number(item.Sum)));
  return { ...year, value: Math.round(modify, 1) };
});

const sortedMonthList = (year = '2019', month = 1, type = 'YYYY-MM') => {
  let monthList = [];
  for (let i = 1; i <= moment(`${year}-${month}`, type).daysInMonth(); i++) {
    monthList = [...monthList, { day: i, name: i, value: 0 }];
  }
  return monthList;
};

const chartsMonthDataBook = (data = [], currentDate = moment(), category = '', type = 'YYYY-MM') => {
  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);
  return sortedMonthList(currentYear, currentMonth, type).map((month) => {
    let modify = 0;
    data.map(item => ((item.Year === currentYear) && (item.Month === currentMonth) && (Number(item.DayOfMonth) === month.name))
      && (modify += Number(item.Sum)));
    return { ...month, value: Math.round(modify, 1) };
  });
};

const chartsMonthData = (data = [], currentDate = moment(), category = '', type = 'YYYY-MM') => {
  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);
  return sortedMonthList(currentYear, currentMonth, type).map((month) => {
    let modify = 0;
    data.map(item => ((category.value === 'all' ? true : category.value === item[category.key]) && (item.Year === currentYear) && (item.Month === currentMonth) && (Number(item.DayOfMonth) === month.name))
        && (modify += Number(item.Sum)));
    return { ...month, value: Math.round(modify, 1) };
  });
};

const sortedWeekList = () => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return days.map((item, id) => ({ value: 0, name: item, daysOfWeek: ++id }));
};

const chartsWeekDataBook = (data = [], currentDate = moment(), category) => {
  const currentYear = getYear(currentDate);
  const currentWeek = getWeek(currentDate);

  return sortedWeekList().map((week) => {
    let modify = 0;
    data.map(item => ((item.Year === currentYear) && (item.WeekOfYear === currentWeek) && (Number(item.WeekDay) === week.daysOfWeek))
      && (modify += item.Sum));
    return { ...week, value: Number(modify) };
  });
};

const chartsWeekData = (data = [], currentDate = moment(), category) => {
  const currentYear = getYear(currentDate);
  const currentWeek = getWeek(currentDate);

  return sortedWeekList().map((week) => {
    let modify = 0;
    data.map(item => ((category.value === 'all' ? true : category.value === item[category.key]) && (item.Year === currentYear) && (item.WeekOfYear === currentWeek) && (Number(item.WeekDay) === week.daysOfWeek))
      && (modify += item.Sum));
    return { ...week, value: Number(modify) };
  });
};

const chartsAll = (data = [], currentDate = moment(), category) => (category.value === 'all'
  ? [...data, { Date: moment().format('YYYY-MM-DD'), Sum: 0 }]
  : data.filter(item => (Number(item.Sum) !== 0 && category.value === item[category.key])))
  .map((item, id) => ({ ...item, value: Number(item.Sum), name: item.Date }));

const chartsAllBook = (data = [], currentDate = moment(), category) => ([..._.uniqBy(data, 'Date'), { Sum: 0, Date: moment().format('YYYY-MM-DD') }].map(
  (date, id) => {
    let value = 0;
    data.map(
      item => (item.Date === date.Date ? value += item.Sum : null)
    );
    return ({ ...date, value: Math.round(value, 1), name: date.Date });
  }
));

const filterController = (filter = '', ...args) => {
  switch (filter) {
  case 'year':
    return chartsYearData(...args);
  case 'month':
    return chartsMonthData(...args);
  case 'week':
    return chartsWeekData(...args);
  default:
    return chartsAll(...args);
  }
};

const filterDataByCategory = (data, category) => {
  switch (category.value) {
  case 'buy':
    return data;
  case 'read':
    return data.filter(item => item.EndDate);
  case 'gift':
    return data.filter(item => item.Possession === 'gift from');
  default:
    return data;
  }
};

const filterControllerBook = (filter = '', data, currentDate, category) => {
  const filterData = filterDataByCategory(data, category).map(item => ({ ...item, Sum: 1 }));
  switch (filter) {
  case 'year':
    return chartsYearDataBook(filterData, currentDate, category);
  case 'month':
    return chartsMonthDataBook(filterData, currentDate, category);
  case 'week':
    return chartsWeekDataBook(filterData, currentDate, category);
  default:
    return chartsAllBook(filterData, currentDate, category);
  }
};

const createCategorySelect = (data, keys = []) => {
  const categoryList = [];
  keys.map(
    key => _.uniqBy(data, key).map((item, id) => (categoryList.push({ value: item[key], key, name: item[key] })))
  );
  return [{ value: 'all', name: 'All', key: 'all' }, ...categoryList];
};


const chartsSelects = (array = [], categoryName = '', category = {}, onChange) => (
  <>
    <Select
      className="select"
      value={category}
      variant="outlined"
      onChange={onChange}
      style={{ fontSize: '1em', width: 'fit-content' }}
      inputProps={{
        // name: {(categoryName ? categoryName : '')},
        id: `${categoryName}-id`
      }}
    >
      {
        array && array.map((category, id) => category.value
            && (
              <MenuItem
                key={id}
                alignItems="center"
                value={category}
              >
                {category.name}
              </MenuItem>
            ))
      }
    </Select>
  </>
);

export default {
  filterControllerBook,
  createCategorySelect,
  sortedMonthList,
  chartsSelects,
  chartsMonthData,
  chartsWeekData,
  filterController,
  showCurrentDate
};
