import React from "react";
import "./BottomNavigation.css";
import { Link } from "react-router-dom";
import { getProfileBasics } from "../../services/getProfileBasics";
import Settings from "../Settings/Settings";
import AvatarInfo from "../Avatar/AvatarInfo";

import CreateAdminAccount from "../CreateAdminAccount/CreateAdminAccount";

class BottomNavigation extends React.Component {
  constructor() {
    super();
    this.state = {
      avatar_to_show: "",
      receipt_icon: true,
      bookcol_icon: true,
      settings_icon: false,
      scanner_icon: false,
      plus_icon: false,
      bookColCallback: false,
      assistant_maps: false,
      admin_login: false,
      public_login: false,

      displaySettings: false,
      displayAvatarInfo: false,
      displayCreateAdmin: false,
      user_data: {
        avatar: null,
        profile_picture: null
      }
    };
  }

  componentDidMount() {
    this.get_profile_data();
    this.parse_icons_to_display(this.props);
  }

  // component will receive props will be deprecated in react 17, will need change for another lifecycle component
  componentWillReceiveProps(nextProps) {
    this.parse_icons_to_display(nextProps);
  }

  avatar_info_btn_callback = () => {
    this.setState({
      displayAvatarInfo: !this.state.displayAvatarInfo,
      displaySettings: false
    });
  };

  displaySettings = () => {
    this.setState({
      displaySettings: !this.state.displaySettings,
      displayAvatarInfo: false
    });
  };

  display_create_admin = param => {
    this.setState({
      displayCreateAdmin: param
    });
  };

  parse_icons_to_display = nextProps => {
    switch (nextProps.currComp) {
      case "bookColTab":
        if (nextProps.currTab === "library") {
          this.setState({
            receipt_icon: true,
            bookcol_icon: false,
            settings_icon: false,
            scanner_icon: false,
            plus_icon: true,
            bookColCallback: true,
            assistant_maps: false,
            admin_login: false,
            public_login: false
          });
        } else if (nextProps.currTab === "store") {
          this.setState({
            receipt_icon: true,
            bookcol_icon: false,
            settings_icon: false,
            scanner_icon: false,
            plus_icon: true,
            bookColCallback: true,
            assistant_maps: false,
            admin_login: false,
            public_login: false
          });
        } else if (nextProps.currTab === "orders") {
          this.setState({
            receipt_icon: true,
            bookcol_icon: false,
            settings_icon: false,
            scanner_icon: false,
            plus_icon: true,
            bookColCallback: true,
            assistant_maps: false,
            admin_login: false,
            public_login: false
          });
        }
        break;
      case "bookColSingle":
        this.setState({
          bookcol_icon: true,
          receipt_icon: true,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "receiptSingle":
        this.setState({
          bookcol_icon: true,
          receipt_icon: true,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "singleBookInfo":
        this.setState({
          bookcol_icon: false,
          receipt_icon: false,
          settings_icon: true,
          scanner_icon: true,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "receiptShopIcons":
        this.setState({
          receipt_icon: true,
          bookcol_icon: true,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "homePage":
        this.setState({
          scanner_icon: true,
          settings_icon: true,
          receipt_icon: false,
          bookcol_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "scanAndBarcode":
        this.setState({
          receipt_icon: true,
          bookcol_icon: true,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "virtualAssistantMaps":
        this.setState({
          receipt_icon: false,
          bookcol_icon: false,
          settings_icon: true,
          scanner_icon: true,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
        break;
      case "virtualAssistant":
        this.setState({
          receipt_icon: false,
          bookcol_icon: false,
          settings_icon: false,
          scanner_icon: true,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: true,
          admin_login: false,
          public_login: false
        });
        break;
      case "hubSite_public":
        this.setState({
          receipt_icon: false,
          bookcol_icon: false,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: true
        });
        break;
      case "hubSite_admin":
        this.setState({
          receipt_icon: false,
          bookcol_icon: false,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: true,
          public_login: false
        });
        break;
      default:
        this.setState({
          receipt_icon: true,
          bookcol_icon: true,
          settings_icon: false,
          scanner_icon: false,
          plus_icon: false,
          bookColCallback: false,
          assistant_maps: false,
          admin_login: false,
          public_login: false
        });
    }
  };

  //= ==================================================================
  // temporary before move to new app design call profile data and get picture for bottom navigation avatar preview
  get_profile_data = () => {
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }

    getProfileBasics(send_credential_to_registrar).then(result => {
      const receiptJson = result;
      if (receiptJson.data.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState({ user_data: receiptJson.data.userData }, () =>
          this.get_avatar()
        );
      } else {
        this.get_avatar();
      }
    });
  };

  get_avatar = () => {
    if (this.state.user_data.profile_picture === null) {
      if (this.state.user_data.avatar !== null) {
        this.setState({
          avatar_to_show: `/assets/images/${this.state.user_data.avatar}`
        });
      } else {
        this.setState({
          avatar_to_show: `/assets/images/person_icon.png`
        });
      }
    } else {
      this.setState({ avatar_to_show: this.state.user_data.profile_picture });
    }
  };

  //= ==================================================================

  render() {
    return (
      <div id="BottomNavigation">
        <div id="bn_rect_bg">
          <Link to="/HUBsite">
            <span
              id="bn_home_icon"
              className="bn_standard_icon"
              // onClick={this.props.home_btn_callback}
            >
              <img alt="" src="/assets/images/home_icon.png" />
            </span>
          </Link>
          {this.state.plus_icon && this.state.bookColCallback ? (
            this.props.currTab === "orders" ? (
              <span
                id="bn_plus_icon"
                className="bn_standard_icon"
                onClick={() => {
                  this.props.bookColCallback("store");
                }}
              >
                <img alt="" src="/assets/images/plus_icon.png" />
              </span>
            ) : (
              <Link to="/bookcollector/insertbook">
                <span id="bn_plus_icon" className="bn_standard_icon">
                  <img alt="" src="/assets/images/plus_icon.png" />
                </span>
              </Link>
            )
          ) : null}
          {this.state.receipt_icon ? (
            <Link to="/receipts">
              <span id="bn_receipt_icon" className="bn_standard_icon">
                <img alt="" src="/assets/images/receipt_icon.png" />
              </span>
            </Link>
          ) : null}
          {this.state.assistant_maps ? (
            <Link to="/virtualassistantmaps">
              <span id="bn_virt_assist_maps_icon" className="bn_standard_icon">
                <img alt="" src="/assets/images/home_map_icon.svg" />
              </span>
            </Link>
          ) : null}
          {this.state.bookcol_icon ? (
            <Link to="/bookcollector">
              <span id="bn_bookcol_icon" className="bn_standard_icon">
                <img alt="" src="/assets/images/books_icon.png" />
              </span>
            </Link>
          ) : null}

          {this.state.scanner_icon ? (
            <Link to="/scanandbarcode">
              <span id="bn_scanner_icon" className="bn_standard_icon">
                <img alt="" src="/assets/images/barcode_icon.png" />
              </span>
            </Link>
          ) : null}

          {this.state.displayCreateAdmin ? (
            <CreateAdminAccount
              display_create_admin={this.display_create_admin}
            />
          ) : null}
          {this.state.admin_login ? (
            <Link to="/logout">
              <span id="bn_admin_logout_icon" className="bn_standard_icon">
                <img alt="" src="/assets/images/logout_icon.png" />
              </span>
            </Link>
          ) : null}

          {this.state.public_login ? (
            <span
              id="bn_public_login_icon"
              className="bn_standard_icon"
              onClick={() => this.display_create_admin(true)}
            >
              <img alt="" src="/assets/images/login_icon.png" />
            </span>
          ) : null}

          {this.state.displaySettings ? <Settings /> : null}
          {this.state.settings_icon ? (
            <span
              id="bn_settings_icon"
              className="bn_standard_icon"
              onClick={() => this.displaySettings()}
            >
              <img alt="" src="/assets/images/gear_icon.png" />
            </span>
          ) : null}
          {this.state.displayAvatarInfo ? (
            <AvatarInfo
              avatar_info_btn_callback={this.avatar_info_btn_callback}
            />
          ) : null}
          <div id="bn_circ_bg">
            <span id="bn_profile_icon">
              <img
                alt=""
                onClick={this.avatar_info_btn_callback}
                src={this.state.avatar_to_show}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default BottomNavigation;
