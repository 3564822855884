import React, { Component } from "react";
import "../../screens/Navigator/Navigator.css";

class NavMap extends Component {
  constructor() {
    super();
    this.state = {
      container_scale: null,
      mapWidth: 0,
      mapHeight: 0
    };
  }

  // set scroll of view portal so marker is in view
  scroll_element = (param, top, left) => {
    document.getElementById("NavMap_" + param).scrollTop = top;
    document.getElementById("NavMap_" + param).scrollLeft = left;
  };

  // on img load get img natural dimensions and set in state
  getdimensions = e => {
    this.setState({
      mapHeight: e.target.naturalHeight,
      mapWidth: e.target.naturalWidth
    });
    // console.log(e.target.naturalHeight);
    // console.log(e.target.naturalWidth);
  };

  render() {
    // if the map native dimensions are too big, we need to scale
    // everythig down so map thumbnail in messageitem is not confusing
    // for user ( too small portion of map or too small map in view portal)

    // scale multiplayer to zoom out (<1) or zoom in(>1)
    let scale = 1;

    //define default dimensions for view portal
    let default_width = window.innerWidth * 0.8;
    let default_height = default_width * 0.6;

    // scale conditions
    if (this.state.mapWidth !== 0) {
      if (default_width / this.state.mapWidth > scale) {
        scale = default_width / this.state.mapWidth;
      } else {
        scale = 0.7;
      }
    }

    // define dimmensions for marker, marker_offset and map
    let marker_dimensions_width = 30 * scale;
    let marker_dimensions_height = 30 * scale;

    let marker_top_offset = Number(this.props.top_offset) * scale;
    let marker_left_offset = Number(this.props.left_offset) * scale;

    let map_width = this.state.mapWidth * scale;
    let map_height = this.state.mapHeight * scale;

    //define position_width and height
    let position_width = this.props.position_width * scale;
    let position_height = this.props.position_height * scale;

    // define scroll position of view portal
    let offtop =
      (Number(this.props.top_offset) -
        default_height / 2 +
        Number(this.props.position_height) / 2) *
      scale;
    let offleft =
      (Number(this.props.left_offset) -
        default_width / 2 +
        Number(this.props.position_width) / 2) *
      scale;

    // styles for top layer container (view portal)
    let container = {
      width: default_width,
      height: default_height,
      overflow: "auto",
      border: "3px solid #A7D3FF",
      borderRadius: "0 20px 20px 20px",
      position: "relative"
    };

    return (
      <div style={container} id={"NavMap_" + this.props.id}>
        <div>
          <img
            onLoad={this.getdimensions}
            width={map_width + "px"}
            height={map_height + "px"}
            alt=""
            src={this.props.floor_plan}
          />
          {this.props.kind === "group" ? (
            <div
              className="selector_div_class"
              // id="selector_div_id"
              style={{
                display: "block",
                top: marker_top_offset + "px",
                left: marker_left_offset + "px",
                width: marker_dimensions_width + "px",
                height: marker_dimensions_height + "px"
              }}
            >
              <img
                style={{
                  width: marker_dimensions_width + "px",
                  height: marker_dimensions_height + "px"
                }}
                id={"green_marker_" + this.props.id}
                alt=""
                className="green_selector_img_class blink_animation_class"
                src="/assets/images/green_selector.png"
              />
            </div>
          ) : null}
          {this.props.kind === "dept" ? (
            <div
              className="selector_div_class blink_animation_class"
              // id="selector_div_id"
              style={{
                display: "block",
                top: marker_top_offset + "px",
                left: marker_left_offset + "px",
                width: position_width + "px",
                height: position_height + "px",
                backgroundColor: "rgba(0, 255, 0, 0.219)",
                border: "1px solid green"
              }}
            ></div>
          ) : null}
          <div
            style={
              marker_left_offset > 350
                ? {
                    display: "block"
                  }
                : { display: "none" }
            }
            className="arrow_div_class"
            id="arrow_div_class"
          >
            <img
              onLoad={() => this.scroll_element(this.props.id, offtop, offleft)}
              alt=""
              className="green_arrow_img_class"
              src="/assets/images/green_arrow.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NavMap;
