import React from "react";
import { Redirect } from "react-router-dom";

const ShopNavigation = ({ loggedInAs }) => {
  if (loggedInAs !== "visitor") {
    return <Redirect to="/virtualassistantmaps" />;
  }
  return null;
};
export default ShopNavigation;
