import React, { Component } from "react";
import "./BookcolStoreTab.css";
import "./BookcolNavbarCSS.css";
import { GetBestSellers } from "../../services/GetBestSellers";
import { OrderBook } from "../../services/OrderBook";
import BookcolSearchBook from "../../components/Bookcollector/BookcolSearchBook";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// bst_ => prefix for BookcolStoreTab

class BookcolStoreTab extends Component {
  constructor() {
    super();
    this.state = {
      bestsellers: [],
      order_ean: undefined,
      order_article: undefined,
      show_order_result_box: false,
      order_result: null,
      shop_to_use: null
    };
  }

  componentDidMount() {
    this.get_bestsellers();
  }

  open_order_box = (ean, article_name, publisher, price) => {
    this.setState({
      show_order_box: true,
      order_ean: ean,
      order_publisher: publisher,
      order_price: price,
      order_article: article_name
    });
  };

  send_order_to_shop = () => {
    let ean;
    if (this.state.order_ean.constructor === Array) {
      ean = this.state.order_ean[0];
    } else {
      ean = this.state.order_ean;
    }
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.external_id_1 = ean;
    send_credential_to_registrar.shop_id = this.state.shop_to_use;
    send_credential_to_registrar.title = this.state.order_article;
    send_credential_to_registrar.publisher = this.state.order_publisher;
    send_credential_to_registrar.price = this.state.order_price;
    send_credential_to_registrar.possession = "ordered";
    send_credential_to_registrar.kind = "book";
    send_credential_to_registrar.possession_related_to =
      send_credential_to_registrar.shop_id;
    OrderBook(send_credential_to_registrar).then(result => {
      let order_result = result;
      if (order_result.success) {
        this.setState({
          show_order_box: false,
          order_ean: undefined,
          order_article: undefined,
          book_info: null,
          order_result: "sucess",
          show_order_result_box: true
        });
      } else {
        this.setState({
          show_order_box: false,
          order_ean: undefined,
          order_article: undefined,
          book_info: null,
          order_result: "error",
          show_order_result_box: true
        });
      }
    });
  };

  close_order_box = () => {
    this.setState({
      show_order_box: false,
      order_ean: undefined,
      order_article: undefined
    });
  };

  get_bestsellers = () => {
    if (this.state.shop_to_use !== null) {
      let shop_id = JSON.parse("{}");
      shop_id.shop_id = this.state.shop_to_use;
      GetBestSellers(shop_id).then(result => {
        let receiptJson = result;
        if (receiptJson.bestsellers) {
          //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
          this.setState({ bestsellers: receiptJson.bestsellers });
        }
      });
    }
  };

  checkIfNull = val => {
    if (val === null) {
      return "";
    } else {
      return val;
    }
  };

  shop_to_search = shop_id => {
    // console.log(shop_id);
    this.setState({ shop_to_use: shop_id }, () => {
      this.get_bestsellers();
    });
  };

  render() {
    const { bestsellers } = this.state;

    let order_box;
    if (this.state.show_order_box === true) {
      order_box = (
        <div className="bestseller_order_box">
          <span
            className="reserve_book_close_btn"
            onClick={this.close_order_box}
          >
            X
          </span>
          <div
            className="send_reservation_btn"
            onClick={() => this.send_order_to_shop()}
          >
            <FormattedMessage id="bookcol_store_tab_order_book" />
          </div>
          <div className="send_reservation_article_name">
            {this.state.order_article}
          </div>
        </div>
      );
    }

    let order_result;
    if (this.state.show_order_result_box === true) {
      this.state.order_result === "sucess"
        ? (order_result = (
            <div>
              <FormattedMessage id="bookcol_store_tab_order_success" />
            </div>
          ))
        : (order_result = (
            <div>
              <FormattedMessage id="bookcol_store_tab_order_error" />
            </div>
          ));
    }

    return (
      <div id="BookcolStoreTab">
        <img
          id="bst_top_bookshelf_bg"
          alt=""
          src="/assets/images/bookpile_img.jpg"
        />
        <div className="white_bgr">
          <div
            onClick={() => {
              this.props.switchTabCallback("orders");
            }}
            id="orders_grey"
            className="orders_grey"
          >
            <FormattedMessage id="bookcol_library_tab_orders" />
          </div>
          <div id="store_white" className="store_white_sto">
            <FormattedMessage id="bookcol_library_tab_store" />
          </div>
          <div
            onClick={() => {
              this.props.switchTabCallback("library");
            }}
            id="library_grey"
            className="library_grey"
          >
            <FormattedMessage id="bookcol_library_tab_library" />
          </div>
        </div>
        <div className="bst_round_corner_background">
          <div className="bst_book_data_wrapper">
            <BookcolSearchBook shop_to_search={this.shop_to_search} />
            <div className="bst_bestsellers_title">
              <FormattedMessage id="bookcol_store_tab_bestsellers" />
            </div>
            <div className="bst_bestsellers_container">
              <div className="bst_bestsellers_flex_wrapper">
                {bestsellers.map((item, index) => {
                  if (!item) {
                    return null;
                  }
                  return (
                    <div className="bst_bestseller_item" key={index}>
                      <div className="bst_bestseller_img_wrapper">
                        <img
                          onClick={() =>
                            this.open_order_box(
                              item.ean,
                              item.article_name,
                              item.publisher,
                              item.price
                            )
                          }
                          className="sbt_book-item-image"
                          alt=""
                          src={
                            "https://portal.dnb.de/opac/mvb/cover.htm?isbn=" +
                            item.ean +
                            "&size=m"
                          }
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = "/assets/images/missing-image.png";
                          }}
                        />
                      </div>
                      <div className="bst_bestseller_item_price">
                        {item.price} €
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="blt_line" />
            {order_box}
            {order_result}

            {this.state.shop_to_use === null ? null : (
              <Link to={"/virtualassistant?shop=" + this.state.shop_to_use}>
                <div className="bst_navigation_redirect_btn">
                  &#8594;{" "}
                  <FormattedMessage id="bookcol_store_tab_redirect_btn" />
                </div>
              </Link>
            )}

            <div
              style={{
                height: "60px"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BookcolStoreTab;
