import { URLconst } from "./const";
import axios from "axios";

// Example of DELETE call from component

// deleteDentistHistory(send_credential_to_registrar)
//       .then(result => {
//         if (result.data.error) {
//           console.log(result.data.error);
//         } else {
//           console.log(result.data.success);
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });

export function deleteDentistHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_history.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteDentistTeethHistory(param) {
  let BaseURL = URLconst("hubsite/health/dentist_teeth_history.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteInjuresList(param) {
  let BaseURL = URLconst("hubsite/health/injuries_and_chronic_issues_list.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteInjuresDetails(param) {
  let BaseURL = URLconst(
    "hubsite/health/injuries_and_chronic_issues_details.php"
  );

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id,
      rel_issue_id: param.rel_issue_id
    }
  });
}

export function deleteAllergiesList(param) {
  let BaseURL = URLconst("hubsite/health/allergies_list.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteAllergiesDetails(param) {
  let BaseURL = URLconst("hubsite/health/allergies_details.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id,
      rel_issue_id: param.rel_issue_id
    }
  });
}

export function deleteAllergiesChartData(param) {
  let BaseURL = URLconst("hubsite/health/allergy_calendar.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteAllergiesToData(param) {
  let BaseURL = URLconst("hubsite/health/allergic_to.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteFitnessList(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_list.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deletePreventionList(param) {
  let BaseURL = URLconst("hubsite/health/prevention.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteSmokingList(param) {
  let BaseURL = URLconst("hubsite/health/smoking.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteMedicationsList(param) {
  let BaseURL = URLconst("hubsite/health/medication_calendar.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteNutritionList(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteHeightList(param) {
  let BaseURL = URLconst("hubsite/health/height.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteSettings(param) {
  let BaseURL = URLconst("hubsite/settings/active_minute_menu.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}

export function deleteCov19PeopDet(param) {
  let BaseURL = URLconst("hubsite/friends_and_family/covid19_peopdet.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}
export function deleteCov19MedVal(param) {
  let BaseURL = URLconst("hubsite/health/covid19_medval.php");

  return axios.delete(BaseURL, {
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in,
      remove_id: param.remove_id
    }
  });
}
