import React from "react";
import "./SubCategories.css";
import { useIntl } from "react-intl";

const SubCategories = ({
  items,
  length,
  activeSubCategory,
  setActiveSubCategory,
  activeCategory
}) => {
  const intl = useIntl();
  const changeData = id => {
    setActiveSubCategory(id);
  };

  return (
    <div
      className="subcategory_container"
      style={
        items.length !== 1
          ? activeCategory !== 0 && activeCategory !== length - 1
            ? { marginLeft: "-80%" }
            : activeCategory === length - 1
            ? { marginLeft: "-160%" }
            : null
          : null
      }
    >
      {items.map((item, id) => {
        const myStyles = {
          transform: `rotate3d(0, 1, 0, -20deg)`
        };
        const myStylesActive = {
          transform: `translateY(-10px)`,
          border: "1px solid #3FA4FF",
          boxShadow: "0 0 10px -2px #3FE0FF"
        };
        return (
          <div key={id}>
            <img
              className="subcategory_item"
              src={item.src}
              style={id === activeSubCategory ? myStylesActive : myStyles}
              onClick={() => changeData(id)}
              alt=""
            ></img>
            {id === activeSubCategory ? (
              <div className="subcategory_title">
                {item.title !== "" &&
                item.title !== null &&
                item.title !== undefined
                  ? intl.formatMessage({
                      id: item.title
                    })
                  : item.title}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SubCategories;
