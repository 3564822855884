import React from "react";
import { Modal } from "@material-ui/core";
import "./InfoModal.css";
import CancelIcon from "@material-ui/icons/Cancel";
import { useIntl } from "react-intl";

const InfoModal = ({ showInfoModal, setShowInfoModal }) => {
  const intl = useIntl();

  return (
    <Modal open={showInfoModal} onClose={() => setShowInfoModal(false)}>
      <div className="info_modal_block">
        <div
          className="info_modal_close_icon"
          onClick={() => setShowInfoModal(false)}
        >
          <CancelIcon />
        </div>
        <img alt="James Bon" src="/assets/images/bond_silhouette.svg" />
        <div className="info_modal_content">
          <div className="info_modal_content_title">
            {intl.formatMessage({
              id: "receipt_display_info_pt1"
            })}
          </div>
          <div className="info_modal_content_subtitle">
            {intl.formatMessage({
              id: "receipt_display_info_pt2"
            })}
          </div>
          <div className="info_modal_content_p">
            {intl.formatMessage({
              id: "receipt_display_info_pt3"
            })}
          </div>
          <div className="info_modal_content_p">
            {intl.formatMessage({
              id: "receipt_display_info_pt4"
            })}{" "}
          </div>
          <div className="info_modal_content_p">
            {intl.formatMessage({
              id: "receipt_display_info_pt5"
            })}
          </div>
          <div className="info_modal_content_p">
            {intl.formatMessage({
              id: "receipt_display_info_pt6"
            })}{" "}
            <a href="/about">
              {" "}
              {intl.formatMessage({
                id: "receipt_display_info_pt7"
              })}
            </a>
            .
          </div>
          <div className="info_modal_content_footnote">
            {intl.formatMessage({
              id: "receipt_display_info_pt8"
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
