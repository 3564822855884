import React from "react";
import { FormattedMessage } from "react-intl";
import "./impressum.css";
import Modal from "react-modal";

class Impressum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className="imprint_wrapper">
        <div className="imprint_button" onClick={this.handleOpenModal}>
          <i className="fa fa-arrow-down" aria-hidden="true" />{" "}
          <FormattedMessage id="footer_imprint_title" />{" "}
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </div>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="Minimal Modal Example"
          onRequestClose={this.handleCloseModal}
        >
          <span
            className="modal_info close"
            onClick={this.handleCloseModal}
            id="info_close"
          >
            <span>&times;</span>
          </span>

          <p>HUBweb GmbH</p>
          <p>Pater-Rupert-Mayer-Str. 1</p>
          <p>82008 Unterhaching</p>
          <p>Telefon: +49 89 32 495 491</p>
          <p>Telefax: +49 89 32 495 490</p>

          <p>E-Mail: info@hub-web.com</p>
          <p>Internet: www.hub-web.com</p>
          <p>Vertretungsberechtigter Geschäftsführer: Dalibor Sevcik</p>
          <p>Verantwortlich für die redaktionellen Inhalte: Dalibor Sevcik</p>

          <p>Registergericht: AG München</p>
          <p>Registernummer: HRB 248352</p>
          <p>
            Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz:
            DE323996886
          </p>
        </Modal>
      </div>
    );
  }
}

export default Impressum;
