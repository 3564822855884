import { URLconst } from "./const";
import axios from "axios";

export function postActiveMinuteSettings(param) {
  let BaseURL = URLconst("hubsite/settings/active_minute_menu.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postWeightOrFat(param) {
  let BaseURL = URLconst("hubsite/health/weight_and_fat.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postNutrition(param) {
  let BaseURL = URLconst("hubsite/health/nutrition.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postHappinessList(param) {
  let BaseURL = URLconst("hubsite/soul/happiness.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSicknessHistory(param) {
  let BaseURL = URLconst("hubsite/health/sickness_history.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: { data: param.data }
  });
}

export function postSleepData(param) {
  let BaseURL = URLconst("hubsite/active_minute/sleep.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postActiveMinuteDayNote(param) {
  let BaseURL = URLconst("hubsite/soul/day_note.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
export function postCalendarSummary(param) {
  let BaseURL = URLconst("hubsite/soul/summaries.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}
export function postActivities(param) {
  let BaseURL = URLconst("hubsite/active_minute/activities.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postInjuriesAndIssues(param) {
  let BaseURL = URLconst("hubsite/active_minute/injuries_and_issues.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postSteps(param) {
  let BaseURL = URLconst("hubsite/health/fitness_and_steps_list.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

export function postBloodAndPressure(param) {
  let BaseURL = URLconst("hubsite/health/blood_pressure.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}