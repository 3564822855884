import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
// import ShareDataModal from "../../Modals/ShareDataModal/ShareDataDodal";
import "./Table.css";
import Details from "../../Details/Details";
import {
  getVaccinationExpirations,
  getColumnsAndRowsPermissionsAdmin,
  getVaccinationDetailsAllForDesease,
  getColumnsNames
} from "../../../../services/GetHUBsiteData";
import AddItemModal from "../../Modals/AddItemModal/AddItemModal";
import { postVaccinationsList } from "../../../../services/PostHUBsiteData";

import { postPermissions } from "../../../../services/Permissions";

import Permissions from "../../../../components/HUBsite/Permissions";

const useStyles = makeStyles({
  root: {
    width: "95%",
    margin: "0px auto",
    position: "relative",
    boxShadow: "0 2px 18px -4px rgba(0,0,0,0.14)"
  },
  header: {
    width: "100%",
    margin: "10px auto 10px auto",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 2.5% 0 2.5%"
  },
  title: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px"
  },
  filter: {
    minWidth: "80px"
  },
  filter_btn: {
    border: "1px solid #FFFFFF",
    borderRadius: "4px",
    backgroundColor: "#3FA5FF",
    boxShadow: "0 2px 2px 0 rgba(25,50,89,0.2)"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(71, 119, 186, 0.07)"
    }
  },
  cell: {
    borderBottom: "none !important",
    position: "relative",
    color: "black"
  },
  search: {
    position: "relative",
    border: "1px solid #E4E4E4",
    borderRadius: "7px",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.14)",
    backgroundColor: "#FFF",
    display: "flex",
    paddingLeft: "10px",
    height: "34px",
    width: "176px"
  },
  searchIcon: {
    padding: "0px 5px"
  },
  titleCellContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
});

const VaccinationsTable = ({ target_hub_id, requestorHubID, loggedInAs }) => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [sortedProp, setSortedProp] = useState();
  const [searchText, setSearchText] = useState("");
  const [details, setDetails] = useState(null);
  const [sortArrow, setSortArrow] = useState({ col: "", direction: "" });
  const [apiParam, setApiParam] = useState("");
  const [apiParamValue, setApiParamValue] = useState("");
  const [columns, setColumns] = useState([{ title: "", field: "" }]);
  const [rowArrows, setRowArrows] = useState(false);
  const [columnArrows, setColumnArrows] = useState(false);
  const [reqHubId, setReqHubId] = useState(requestorHubID);
  const [permFor, setPermFor] = useState("all");
  const [tableName, setTableName] = useState("");

  const detailsFields = [
    {
      title: "Vaccination on:",
      value: ["date_start"]
    },
    {
      title: "Note",
      value: ["note"]
    },
    {
      title: "Method",
      value: ["method"]
    },
    {
      title: "Vaccine",
      value: ["manufacturer", "product_code", "batch_number"]
    },
    {
      title: "Title",
      value: ["title"]
    },
    {
      title: "Rating",
      value: ["rating"]
    },
    {
      title: "Vaccination by:",
      value: ["doctor", "where"]
    }
  ];

  const [errorMsg, setErrorMsg] = useState("Loading");

  const currentSection = "";

  const get_columns_names = () => {
    let param = { component_name: "VaccinationsTable" };
    param.admin = loggedInAs === "admin" ? true : false;
    getColumnsNames(param).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        let new_arr = [];
        result.data.data_array.forEach(function (item) {
          let temp_obj = {
            title: (
              item.column_name.charAt(0).toUpperCase() +
              item.column_name.slice(1)
            )
              .replace(/_/g, " ")
              .replace(/id/gi, "ID"),
            field: item.column_name,
            type: item.datatype,
            showInModal: item.show_in_modal
          };
          if (item.hardcoded_parameter === "1") {
            setApiParam(item.column_name);
            setApiParamValue(item.parameter_value);
          }
          new_arr.push(temp_obj);
        });
        setColumns(new_arr);
        setTableName(result.data.data_array[0].table_name);
      }
    });
  };

  const getApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.requestor_hub_id = reqHubId;
    send_credential_to_registrar.section = currentSection;
    //variables for dynamic created query for setting permissions
    send_credential_to_registrar.component_name = "VaccinationsTable";

    loggedInAs === "admin"
      ? getColumnsAndRowsPermissionsAdmin(send_credential_to_registrar).then(
        result => {
          if (result.data.error) {
            setData([]);
            setErrorMsg(false);
            } else {
              setData(result.data.data_array);
              setErrorMsg(false);
            }
          }
        )
      : getVaccinationExpirations(send_credential_to_registrar).then(result => {
          if (result.data.error) {
            setData([]);
            setErrorMsg(result.data.error);
          } else {
            setData(result.data.data_array);
            setErrorMsg(false);
          }
        });
  };

  const set_permissions = (target, granted, table_name, id_or_name, who) => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    let prem_for_id = who === undefined ? permFor : who;
    send_credential_to_registrar.data = {};
    switch (target) {
      case "table":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          granted: granted
        };
        break;
      case "row":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          row_id: id_or_name,
          granted: granted
        };
        break;
      case "column":
        send_credential_to_registrar.data.target = target;
        send_credential_to_registrar.data.values = {
          table_name: table_name,
          section: currentSection,
          requestor_hub_id: prem_for_id,
          column_name: id_or_name,
          granted: granted
        };
        break;
      default:
    }

    postPermissions(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result.data.error);
      } else {
        getApiData();
      }
    });
  };

  useEffect(() => {
    get_columns_names();
    getApiData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setResultData(data);
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
  };

  const filterData = filter => {
    let filteredData = data.filter(item => item.flag === filter);
    setResultData(filteredData);
  };

  const handleSortClick = (prop, title) => {
    let sortedData = resultData.sort(function (a, b) {
      if (sortedProp === prop) {
        let c = a;
        a = b;
        b = c;
      }
      if (a[prop] < b[prop] && isNaN(Number(a[prop]))) {
        return -1;
      } else if (Number(a[prop]) < Number(b[prop])) {
        return -1;
      }
      if (a[prop] > b[prop] && isNaN(Number(a[prop]))) {
        return 1;
      } else if (Number(a[prop]) > Number(b[prop])) {
        return 1;
      }
      return 0;
    });
    if (sortedProp === prop) {
      setSortArrow({ col: title.toLowerCase(), direction: "up" });
      setSortedProp();
    } else {
      setSortArrow({ col: title.toLowerCase(), direction: "down" });
      setSortedProp(prop);
    }
    setResultData([...sortedData]);
  };

  useEffect(() => {
    let searchedData = [];
    // eslint-disable-next-line
    data.map(item => {
      columns.forEach(col => {
        if (
          item[col.field] !== null &&
          item[col.field].toLowerCase().includes(searchText.toLowerCase())
        ) {
          let flag = true;
          searchedData.forEach(element => {
            if (element.desease === item.desease) {
              flag = false;
            }
          });
          if (flag) {
            searchedData.push(item);
          }
        }
      });
    });
    setResultData(searchedData);
    // eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, [reqHubId]);

  return (
    <>
      {loggedInAs === "admin" ? (
        <Permissions
          table={tableName}
          target_hub_id={target_hub_id}
          rowArrows={rowArrows}
          setRowArrows={setRowArrows}
          columnArrows={columnArrows}
          setColumnArrows={setColumnArrows}
          setReqHubId={setReqHubId}
          getApiData={getApiData}
          setPermFor={setPermFor}
          currentSection={currentSection}
        />
      ) : null}
      <div className={classes.header}>
        <span
          style={{ position: "relative", zIndex: "20" }}
          className={classes.title}
        >
          Vaccinations
        </span>
        {errorMsg !== false ? null : (
          <Paper className={classes.search}>
            {searchText !== "" ? (
              <IconButton
                className="icon-button"
                aria-label="close"
                onClick={() => {
                  setSearchText("");
                }}
                style={{marginRight:"5px"}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </IconButton>
            ) : (
                <></>
              )}
            <InputBase
              className={classes.searchField}
              value={searchText}
              placeholder="Search"
              // placeholder={intl.formatMessage({
              //   id: "virtual_assistant_search"
              // })}
              inputProps={{ "aria-label": "search" }}
              onChange={event => {
                setSearchText(event.target.value);
              }}
            />
            <IconButton
              className={classes.searchIcon}
              aria-label="search"
              // onClick={() => handleSearchClick(searchText)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
      </div>
      {errorMsg !== false ? null : (
        <>
          <Paper className={classes.root}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column, id) => {
                    if (column.title !== "ID") {
                      return (
                        <TableCell
                          key={id}
                          onClick={() =>
                            handleSortClick(column.field, column.title)
                          }
                        >
                          {column.title}
                          <img
                            className="sort_arrow"
                            src={
                              sortArrow.col === column.title.toLowerCase()
                                ? sortArrow.direction === "down"
                                  ? "/assets/images/table/sortArrow/up.png"
                                  : "/assets/images/table/sortArrow/down.png"
                                : "/assets/images/table/sortArrow/none.png"
                            }
                            alt="Sort arrow"
                          />
                          {columnArrows ? (
                            <img
                              className="permissions_arrow_top"
                              src={
                                resultData.length !== 0
                                  ? resultData[0][column.field + "_preview"] !==
                                    "***"
                                    ? "/assets/images/arrow_icon_green.png"
                                    : "/assets/images/arrow_icon_red.png"
                                  : null
                              }
                              onClick={event => {
                                set_permissions(
                                  "column",
                                  resultData[0][column.field + "_preview"] !==
                                    "***"
                                    ? "0"
                                    : "1",
                                  tableName,
                                  column.field
                                );
                                event.stopPropagation();
                              }}
                              alt="Sort arrow"
                            />
                          ) : null}
                        </TableCell>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <TableCell className={classes.filter} align="right">
                    <button
                      className={classes.filter_btn}
                      onClick={() => filterData("green")}
                    >
                      <img src="/assets/images/table/green.svg" alt="" />
                    </button>
                    <button
                      className={classes.filter_btn}
                      onClick={() => filterData("orange")}
                    >
                      <img src="/assets/images/table/orange.svg" alt="" />
                    </button>
                    <button
                      className={classes.filter_btn}
                      onClick={() => filterData("red")}
                    >
                      <img src="/assets/images/table/error.svg" alt="" />
                    </button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((row, id) => {
                  let color_vis = { color: "black" };
                  let color_inv = { color: "#B2B2B2" };

                  function check_if_visible(param) {
                    if (reqHubId === "") {
                      return null;
                    }
                    return resultData[0][param] === "***" ||
                      row.Big_overall_granted === "0"
                      ? color_inv
                      : color_vis;
                  }

                  return loggedInAs === "admin" ? (
                    <React.Fragment key={id}>
                      <TableRow className={classes.row}>
                        <TableCell
                          style={check_if_visible("desease_preview")}
                          className={classes.cell}
                        >
                          {rowArrows ? (
                            <img
                              className="permissions_arrow"
                              src={
                                row.Big_overall_granted === "1"
                                  ? "/assets/images/arrow_icon_green.png"
                                  : "/assets/images/arrow_icon_red.png"
                              }
                              onClick={() => {
                                set_permissions(
                                  "row",
                                  row.Big_overall_granted === "1" ? "0" : "1",
                                  tableName,
                                  row.id
                                );
                              }}
                              alt="Sort arrow"
                            />
                          ) : null}
                          {row.desease}
                        </TableCell>

                        <TableCell
                          style={check_if_visible("protection_end_preview")}
                          className={classes.cell}
                        >
                          {new Intl.DateTimeFormat(
                            localStorage.getItem("personal_hub_language")
                          ).format(moment(row.protection_end).valueOf())}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={id}>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {row.desease}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {moment(
                            row.protection_end,
                            moment.ISO_8601,
                            true
                          ).isValid()
                            ? new Intl.DateTimeFormat(
                                localStorage.getItem("personal_hub_language")
                              ).format(moment(row.protection_end).valueOf())
                            : row.protection_end}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.flag === "green" ? (
                            <img src="/assets/images/table/green.svg" alt="" />
                          ) : row.flag === "orange" ? (
                            <img src="/assets/images/table/orange.svg" alt="" />
                          ) : (
                            <img src="/assets/images/table/error.svg" alt="" />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell} align="right">
                          {details !== id ? (
                            <ArrowDropDownIcon
                              onClick={() => {
                                if (details !== id) {
                                  setDetails(id);
                                }
                              }}
                            />
                          ) : (
                            <ArrowDropUpIcon onClick={() => setDetails(null)} />
                          )}
                        </TableCell>
                      </TableRow>
                      {details === id ? (
                        <Details
                          colSpan={Math.abs(columns.length / 3)}
                          key={id}
                          item={row}
                          target_hub_id={target_hub_id}
                          apiRequest={getVaccinationDetailsAllForDesease}
                          apiParam={"desease"}
                          itemParam={"desease"}
                          detailsFields={detailsFields}
                        />
                      ) : (
                        <React.Fragment
                          key={id + resultData.length}
                        ></React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          <div className="btn-group">
            {loggedInAs !== "visitor" ? (
              <button className="btn-item" onClick={handleOpen}>
                + Add
            </button>
            ) : null}
            {/* <button className="btn-item">Download</button> */} <div></div>
          </div>
          <AddItemModal
            open={open}
            setOpen={setOpen}
            fields={columns}
            apiRequest={postVaccinationsList}
            target_hub_id={target_hub_id}
            getApiData={getApiData}
            apiParam={apiParam}
            apiParamValue={apiParamValue}
          />
        </>
      )}
      {errorMsg !== false ? (
        <h5 style={{ margin: "20px 20px 50px 20px" }}>{errorMsg}</h5>
      ) : null}
    </>
  );
};

export default VaccinationsTable;
