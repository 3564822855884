import React, { useState, useEffect } from "react";

// import { ClaimFreeHub } from "../../services/ClaimFreeHub";
import { CreateNewHub } from "../../services/CreateNewHub";
import moment from "moment";
import { useIntl } from "react-intl";
import Modal from "react-modal";
import Tab from "@material-ui/core/Tab";

import CreateHubModal from "../../components/CreateHubModal";
import CreateHubAlert from "../../components/CreateHubAlert";

function ReceiptDisplayModal({
  modal,
  setModal,
  save_receipt_to_hub,
  handle_new_acc
}) {
  const intl = useIntl();
  const [loginData, setLoginData] = useState({
    username: "",
    pin: "",
    password: "",
    redirectToReferrer: false,
    login_error: false,
    login_validation_error: false
  });
  const [userData, setUserData] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [creatingDone, setCreatingDone] = useState(false);
  const [progressBarDone, setProgressBarDone] = useState(false);
  const [createHubError, setCreateHubError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  useEffect(() => {
    if (progressBarDone) {
      setLoginData({
        ...loginData,
        modal: false,
        new_register: true,
        my_hub_id: userData.userData.my_hub_id
      });
      setOpenCreateModal(false);
      handle_new_acc(userData.userData);
    }
    // eslint-disable-next-line
  }, [progressBarDone]);

  const handleInputChange = event => {
    const { value, name } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const register = () => {
    let create_new_hub = true;
    if (localStorage.getItem("newHUB") !== null) {
      let difference =
        Number(moment().unix()) - Number(localStorage.getItem("newHUB"));
      if (difference < 600) {
        let num = Math.floor(difference / 60);
        create_new_hub = false;
        setTimeLeft(num);
        setOpenAlertModal(true);
      }
    }
    if (create_new_hub) {
      if (sessionStorage.getItem("hub_token") === null) {
        const send_credential_to_registrar = {
          password: "This is CreHUBmultip@ssWord!"
        };
        setCreateHubError(false);
        setOpenCreateModal(true);
        CreateNewHub(send_credential_to_registrar)
          .then(result => {
            const responseJson = result;
            if (responseJson.error) {
              setLoginData({
                ...loginData,
                login_error: true,
                login_validation_error: false
              });
              handle_new_acc(null);
              setCreateHubError(true);
            }
            if (responseJson.userData) {
              //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
              sessionStorage.setItem("hub_token", JSON.stringify(responseJson));
              localStorage.setItem("newHUB", moment().unix());
              setUserData(responseJson);
              setCreatingDone(true);
            }
          })
          .catch(() => {
            setCreateHubError(true);
          });
      }
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal}
      contentLabel="Minimal Modal Example"
      onRequestClose={() => setModal(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "99"
        },
        content: {
          background: "rgba(255,255,255,1)",
          top: "2%",
          right: "2%",
          left: "2%",
          bottom: "2%",
          padding: "0",
          zIndex: "100"
        }
      }}
    >
      {openCreateModal ? (
        <CreateHubModal
          open={openCreateModal}
          setOpen={setOpenCreateModal}
          creatingDone={creatingDone}
          setProgressBarDone={setProgressBarDone}
          createHubError={createHubError}
          register={register}
        />
      ) : null}
      {openAlertModal ? (
        <CreateHubAlert
          open={openAlertModal}
          setOpen={setOpenAlertModal}
          timeLeft={timeLeft}
        />
      ) : null}
      <span className="navigation_login_close" onClick={() => setModal(false)}>
        <span>&times;</span>
      </span>
      {/* {loginData.loginFromCart ? (
        <div className="navigation_login_confirm_message">
          <FormattedMessage id="navigator_login_confirm_message" />
        </div>
      ) : null} */}
      <div className="login_page_block" style={{ height: "100%" }}>
        <img
          className="login_page_bg"
          src="/assets/images/background_img_hubsite.png"
          alt=""
        />
        <div className="login_page_content">
          <div className="login_page_title" style={{ maxWidth: "90%" }}>
            {intl.formatMessage({
              id: "login_to_hubsite_title"
            })}
          </div>
          <div className="login_form_block">
            <div className="login_form_title" style={{ maxWidth: "90%" }}>
              {intl.formatMessage({
                id: "receipt_display_already_have_hub_?"
              })}
            </div>
            <div className="login_form">
              <Tab
                label={intl.formatMessage({
                  id: "receipt_display_send_to_hub"
                })}
                style={{
                  width: "75%",
                  marginBottom: "10px",
                  textTransform: "unset",
                  color: "#3fa5ff",
                  borderBottom: "2px solid #3fa5ff"
                }}
              />

              <div className="form">
                <form autoComplete="on" name="user">
                  <input
                    type="number"
                    name="username"
                    className="login_form_input"
                    placeholder={intl.formatMessage({
                      id: "receipt_display_placeholder_enter_hub_id"
                    })}
                    value={loginData.username}
                    onChange={handleInputChange}
                  />
                </form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-10px"
                  }}
                >
                  <button
                    className="form_cancel_btn"
                    type="submit"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "receipt_display_modal_cancel"
                    })}
                  </button>
                  <button
                    className="form_submit_btn"
                    type="submit"
                    onClick={() => {
                      save_receipt_to_hub(loginData.username);
                    }}
                  >
                    {intl.formatMessage({
                      id: "receipt_display_modal_go"
                    })}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="personal_hub_block">
            <div className="personal_hub_block_title">
              {intl.formatMessage({
                id: "login_page_new_here"
              })}
            </div>
            <div className="personal_hub_form">
              <div className="personal_hub_form_text">
                {intl.formatMessage({
                  id: "login_page_create_hub_p1"
                })}{" "}
                <b>
                  {intl.formatMessage({
                    id: "login_page_create_hub_p2"
                  })}
                </b>
              </div>
              <div>
                <button onClick={register} className="form_submit_btn">
                  {intl.formatMessage({
                    id: "login_page_get_my_hub"
                  })}
                </button>
              </div>
              <div className="personal_hub_form_text">
                <a href="/about">
                  {intl.formatMessage({
                    id: "login_page_about_p1"
                  })}
                </a>{" "}
                {intl.formatMessage({
                  id: "login_page_about_p2"
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ReceiptDisplayModal;
