import { URLconst } from "./const";
import axios from "axios";

export function postMail(param) {
  let BaseURL = URLconst("send_mail.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    data: { data: param }
  });
}
