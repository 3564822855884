import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import Avatar from "../../../../components/AvatarEditor/avatar";
import Slider from "react-slick";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// import { SetAvatar } from "../../../../services/SetAvatar";
// import { SetProfilePicture } from "../../../../services/SetProfilePicture";
import { getProfileBasics } from "../../../../services/getProfileBasics";
import { ChangePin } from "../../../../services/ChangePin";
import { putHubBasics } from "../../../../services/PUT_requests";
import { postHubBasics } from "../../../../services/POST_requests";

import LanguageSwitch from "../../../../LanguageSwitch";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useIntl } from "react-intl";

import InfoIcon from "@material-ui/icons/Info";
import CancelIcon from "@material-ui/icons/Cancel";

import "./EditProfileModal.css";

//validation of login input
const passwordValidator = require("password-validator");

const schema = new passwordValidator();
//has to be 4 digits and no spaces
schema
  .is()
  .min(4) // Minimum length 8
  .is()
  .max(4) // Maximum length 100
  .has()
  .not()
  .letters()
  .is()
  .digits() // Must have digits
  .has()
  .not()
  .spaces(); // Should not have spaces

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  modal: {
    position: "relative",
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
  },
  modalTitle: {
    color: "#232326",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
    textAlign: "center",
    marginBottom: "20px",
  },
  smallTitle: {
    color: "#414248",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.44px",
    lineHeight: "16px",
    marginBottom: "20px",
  },
  avatarBlockContainer: { display: "flex", width: "100%" },
  avatarEditor: { width: "40%", paddingRight: "20px" },
  avatarCarousel: {
    position: "relative",
    width: "60%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  avatarCarousel_wrapper: { width: "80%", alignSelf: "center" },
  // carousel_img_wrapper: { width: "80px", height: "80px", padding: "10px" }
  bntBlock: {
    display: "flex",
    width: "100%",
  },
  button: {
    margin: "0 auto",
    width: "150px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
  },
  buttonWhite: {
    margin: "0 auto",
    width: "150px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #fff",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#3FA5FF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
  },
  greyscale: {
    filter: "grayscale(100%)",
  },
  selected: {
    borderRadius: "50%",
    boxShadow: "inset 0px 0px 0px 5px green",
    boxSizing: "border-box",
  },
});

const EditProfileModal = ({
  openAvatarModal,
  setOpenAvatarModal,
  setUpdateProfileData,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [avatarindex, setAvatarIndex] = useState(null);
  const [showCurrentPin, setShowCurrentPin] = useState(false);
  const [showNewPin, setShowNewPin] = useState(false);
  const [currentPin, setCurrentPin] = useState(null);
  const [newPin, setNewPin] = useState(null);
  const [updateResult, setUpdateResult] = useState(null);
  const [showBackupPageInfo, setShowBackupPageInfo] = useState(false);

  const [pin_not_match_error, setPin_not_match_error] = useState(false);
  const [pin_login_validation_error, setPin_login_validation_error] = useState(
    false
  );

  const [userBasicsNew, setUserBasicsNew] = useState({
    first_name: null,
    last_name: null,
    birthdate: null,
    profile_picture: null,
    nickname: null,
    avatar: null,
    email: null,
    country: null,
  });
  const [userBasics, setUserBasics] = useState({
    id: null,
    first_name: null,
    last_name: null,
    birthdate: null,
    profile_picture: null,
    nickname: null,
    avatar: null,
    email: null,
    country: null,
  });

  const profilePictures = [
    { name: "avatar_female_1.png" },
    { name: "avatar_male_1.png" },
    { name: "avatar_female_2.png" },
    { name: "avatar_male_2.png" },
    { name: "avatar_female_3.png" },
    { name: "avatar_male_3.png" },
    { name: "avatar_female_4.png" },
    { name: "avatar_male_4.png" },
    { name: "avatar_female_5.png" },
    { name: "avatar_male_5.png" },
    { name: "avatar_female_6.png" },
    { name: "avatar_male_6.png" },
    { name: "avatar_female_7.png" },
    { name: "avatar_male_7.png" },
    { name: "avatar_female_8.png" },
    { name: "avatar_male_8.png" },
  ];

  const carouselSettings = {
    // lazyLoad: true,
    infinite: false,
    dots: true,
    speed: 500,
    rows: 2,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
  };

  useEffect(() => {
    get_profile_data();
    if (sessionStorage.getItem("hub_token") !== null) {
      setCurrentPin(
        JSON.parse(sessionStorage.getItem("hub_token")).userData.temp_pin
      );
      // console.log(
      //   JSON.parse(sessionStorage.getItem("hub_token")).userData.temp_pin
      // );
    }
    // eslint-disable-next-line
  }, []);

  const get_profile_data = () => {
    let send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
      send_credential_to_registrar.section = "";
    } else {
      return;
    }

    getProfileBasics(send_credential_to_registrar).then((result) => {
      if (result.data.userData) {
        //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
        setUserBasicsNew(result.data.userData);
        setUserBasics(result.data.userData);
      }
    });
  };

  const handleProfileData = () => {
    let send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "";

    send_credential_to_registrar.data = {
      id: userBasics.id,
    };
    let update = false;
    if (
      userBasicsNew.profile_picture !== null &&
      userBasicsNew.profile_picture !== userBasics.profile_picture
    ) {
      send_credential_to_registrar.data.profile_picture =
        userBasicsNew.profile_picture;
      update = true;
    } else if (
      userBasicsNew.avatar !== null &&
      userBasicsNew.avatar !== userBasics.avatar
    ) {
      send_credential_to_registrar.data.avatar = userBasicsNew.avatar;
      send_credential_to_registrar.data.profile_picture = null;
      update = true;
    }

    if (
      userBasicsNew.nickname !== null &&
      userBasicsNew.nickname !== userBasics.nickname
    ) {
      send_credential_to_registrar.data.nickname =
        userBasicsNew.nickname === "" ? null : userBasicsNew.nickname;
      update = true;
    }

    if (
      userBasicsNew.email !== null &&
      userBasicsNew.email !== userBasics.email
    ) {
      send_credential_to_registrar.data.email =
        userBasicsNew.email === "" ? null : userBasicsNew.email;
      update = true;
    }

    if (
      userBasicsNew.first_name !== null &&
      userBasicsNew.first_name !== userBasics.first_name
    ) {
      send_credential_to_registrar.data.first_name =
        userBasicsNew.first_name === "" ? null : userBasicsNew.first_name;
      update = true;
    }

    if (
      userBasicsNew.last_name !== null &&
      userBasicsNew.last_name !== userBasics.last_name
    ) {
      send_credential_to_registrar.data.last_name =
        userBasicsNew.last_name === "" ? null : userBasicsNew.last_name;
      update = true;
    }

    if (update) {
      if (userBasics.id !== null) {
        putHubBasics(send_credential_to_registrar).then((result) => {
          if (result.data.error) {
            console.log(result.data.error);
          } else {
            if (newPin === null) {
              setUpdateProfileData(true);
              setUpdateResult(true);
              setTimeout(() => setOpenAvatarModal(false), 2000);
            } else {
              get_profile_data();
              setUpdateProfileData(true);
            }
          }
        });
      } else {
        postHubBasics(send_credential_to_registrar).then((result) => {
          if (result.data.error) {
            console.log(result.data.error);
          } else {
            if (newPin === null) {
              setUpdateProfileData(true);
              setUpdateResult(true);
              setTimeout(() => setOpenAvatarModal(false), 2000);
            } else {
              get_profile_data();
              setUpdateProfileData(true);
            }
          }
        });
      }
    }

    if (newPin !== null) {
      if (schema.validate(newPin)) {
        let send_credential_to_registrar = JSON.parse(
          sessionStorage.getItem("hub_token")
        ).userData;
        send_credential_to_registrar.new_pin = newPin;
        ChangePin(send_credential_to_registrar).then((result) => {
          let responseJson = result;
          let temp_UD = JSON.parse(sessionStorage.getItem("hub_token"))
            .userData;
          sessionStorage.setItem("hub_token", "");
          sessionStorage.clear();
          if (responseJson.userData) {
            let tempOBJ = {
              userData: {
                ...temp_UD,
                temp_pin: responseJson.userData.temp_pin,
              },
            };
            //  sessionStorage.setItem('userData',JSON.stringify(responseJson));
            sessionStorage.setItem("hub_token", JSON.stringify(tempOBJ));
            setUpdateProfileData(true);
            setUpdateResult(true);
            setPin_not_match_error(false);
            setPin_login_validation_error(false);
            setTimeout(() => setOpenAvatarModal(false), 2000);
          }
        });
      } else {
        setPin_not_match_error(false);
        setPin_login_validation_error(true);
      }
    }
  };

  const setPicture = (param) => {
    setUserBasicsNew({ ...userBasicsNew, profile_picture: param });
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openAvatarModal}
    >
      <div className={classes.root}>
        <div className={classes.modal}>
          <div>
            <div className="edit_profile_avatar_container">
              <img
                src={
                  userBasics.profile_picture !== null
                    ? userBasics.profile_picture
                    : userBasics.avatar !== null
                    ? `/assets/images/${userBasics.avatar}`
                    : "/assets/images/person_icon.png"
                }
                alt=""
                className="avatar_img"
              />
              <div className="menu_title">
                <div style={{ fontWeight: "bold" }}>
                  {userBasics.first_name !== null ||
                  userBasics.last_name !== null
                    ? (userBasics.first_name !== null
                        ? userBasics.first_name + " "
                        : "") +
                      (userBasics.last_name !== null
                        ? userBasics.last_name
                        : "")
                    : userBasics.nickname !== null
                    ? userBasics.nickname
                    : sessionStorage.getItem("hub_token") !== null
                    ? JSON.parse(sessionStorage.getItem("hub_token")).userData
                        .my_hub_id
                    : null}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    HUB #{" "}
                    {sessionStorage.getItem("hub_token") !== null
                      ? parseInt(
                          JSON.parse(sessionStorage.getItem("hub_token"))
                            .userData.my_hub_id.replace(/hub_/g, "")
                            .replace(/_/g, ""),
                          10
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#232326",
              fontFamily: "Roboto",
              fontSize: "22px",
              fontWeight: "bold",
              letterSpacing: "0.69px",

              textAlign: "center",
            }}
          >
            {intl.formatMessage({
              id: "edit_profile_title",
            })}
          </div>
          <div>
            <div className={classes.avatarBlockContainer}>
              <div className={classes.avatarEditor}>
                <div className={classes.smallTitle}>
                  {intl.formatMessage({
                    id: "edit_profile_upload_img",
                  })}
                </div>
                <Avatar setPicture={setPicture} />
              </div>
              <div className={classes.avatarCarousel}>
                <div className={classes.avatarCarousel_wrapper}>
                  <div className={classes.smallTitle}>
                    {intl.formatMessage({
                      id: "edit_profile_select_avatar",
                    })}
                  </div>
                  <Slider {...carouselSettings}>
                    {profilePictures.map((item, index) => {
                      if (!item) {
                        return null;
                      }
                      return (
                        <img
                          key={index}
                          onClick={() => {
                            setUserBasicsNew({
                              ...userBasicsNew,
                              avatar: item.name,
                              profile_picture: null,
                            });
                            setAvatarIndex(index);
                          }}
                          className={
                            userBasicsNew.profile_picture !== null
                              ? classes.greyscale
                              : avatarindex === index
                              ? classes.selected
                              : null
                          }
                          alt=""
                          src={"/assets/images/" + item.name}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="user_info_block">
              <div className="edit_profile_user_info_item">
                <TextField
                  fullWidth
                  size="small"
                  label={intl.formatMessage({
                    id: "edit_profile_nickname",
                  })}
                  variant="outlined"
                  onChange={(e) =>
                    setUserBasicsNew({
                      ...userBasicsNew,
                      nickname: e.target.value,
                    })
                  }
                  value={
                    userBasicsNew.nickname !== null
                      ? userBasicsNew.nickname
                      : ""
                  }
                />
              </div>
              <div className="edit_profile_user_info_item">
                <TextField
                  fullWidth
                  size="small"
                  label={intl.formatMessage({
                    id: "edit_profile_email",
                  })}
                  variant="outlined"
                  onChange={(e) =>
                    setUserBasicsNew({
                      ...userBasicsNew,
                      email: e.target.value,
                    })
                  }
                  value={
                    userBasicsNew.email !== null ? userBasicsNew.email : ""
                  }
                />
              </div>
              <div className="edit_profile_user_info_item">
                <TextField
                  fullWidth
                  size="small"
                  label={intl.formatMessage({
                    id: "edit_profile_first_name",
                  })}
                  variant="outlined"
                  onChange={(e) =>
                    setUserBasicsNew({
                      ...userBasicsNew,
                      first_name: e.target.value,
                    })
                  }
                  value={
                    userBasicsNew.first_name !== null
                      ? userBasicsNew.first_name
                      : ""
                  }
                />
              </div>
              <div className="edit_profile_user_info_item">
                <TextField
                  fullWidth
                  size="small"
                  label={intl.formatMessage({
                    id: "edit_profile_last_name",
                  })}
                  variant="outlined"
                  onChange={(e) =>
                    setUserBasicsNew({
                      ...userBasicsNew,
                      last_name: e.target.value,
                    })
                  }
                  value={
                    userBasicsNew.last_name !== null
                      ? userBasicsNew.last_name
                      : ""
                  }
                />
              </div>
              <div className="edit_profile_user_info_item">
                {/* <TextField
                  fullWidth
                  size="small"
                  label="Current pin"
                  variant="outlined"
                  onChange={e => setOldPin(e.target.value)}
                  value={oldPin !== null ? oldPin : ""}
                /> */}
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {intl.formatMessage({
                      id: "edit_profile_current_pin",
                    })}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showCurrentPin ? "text" : "password"}
                    value={currentPin !== null ? currentPin : ""}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowCurrentPin(!showCurrentPin);
                          }}
                          edge="end"
                        >
                          {showCurrentPin ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={80}
                  />
                </FormControl>
              </div>
              <div className="edit_profile_user_info_item">
                {/* <TextField
                  fullWidth
                  size="small"
                  label="New Pin (optional)"
                  variant="outlined"
                  onChange={e => setNewPin(e.target.value)}
                  value={newPin !== null ? newPin : ""}
                /> */}
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password1">
                    {intl.formatMessage({
                      id: "edit_profile_new_pin",
                    })}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password1"
                    type={showNewPin ? "text" : "password"}
                    value={newPin !== null ? newPin : ""}
                    onChange={(e) => setNewPin(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowNewPin(!showNewPin);
                          }}
                          edge="end"
                        >
                          {showNewPin ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={80}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          {pin_login_validation_error ? (
            <div className="update_error">
              <FormattedMessage id="logout_change_pin_validation_error" />
            </div>
          ) : null}
          {pin_not_match_error ? (
            <div className="update_error">
              {/* <FormattedMessage id="logout_change_pin_doesnot_match" />*/}
              {intl.formatMessage({
                id: "edit_profile_wrong_old_pin",
              })}
            </div>
          ) : null}
          {updateResult ? (
            <div className="update_successfull">
              {intl.formatMessage({
                id: "edit_profile_update_successfull",
              })}
            </div>
          ) : null}
          <div>
            <div className={classes.bntBlock}>
              <button
                className={classes.buttonWhite}
                onClick={() => setOpenAvatarModal(false)}
              >
                {intl.formatMessage({
                  id: "receipt_display_modal_cancel",
                })}
              </button>
              <button
                className={classes.button}
                onClick={() => {
                  handleProfileData();
                }}
              >
                {intl.formatMessage({
                  id: "bookcoll_save_button",
                })}
              </button>
            </div>
          </div>
          <div
            style={{
              color: "#232326",
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Roboto",
              margin: "25px auto 25px",
              letterSpacing: "0.69px",
              lineHeight: "25px",
              paddingRight: "5px",
            }}
          >
            {intl.formatMessage({
              id: "edit_profile_set_language",
            })}
          </div>
          <div>
            <div
              className="user_info_language"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LanguageSwitch />
            </div>
          </div>

          <div>
            <div className="ep_backup_cont">
              <div className="ep_backup_title_wrap">
                <div
                  style={{
                    color: "#232326",
                    fontSize: "22px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    margin: "20px 0",
                    letterSpacing: "0.69px",
                    lineHeight: "25px",
                  }}
                >
                  {intl.formatMessage({
                    id: "edit_profile_backup_title",
                  })}
                </div>
                <div
                  onClick={() => {
                    setShowBackupPageInfo(!showBackupPageInfo);
                  }}
                  className="ep_backup_info_btn"
                >
                  {showBackupPageInfo ? (
                    <CancelIcon fontSize="inherit" />
                  ) : (
                    <InfoIcon fontSize="inherit" />
                  )}
                </div>
              </div>
              <div
                className={
                  showBackupPageInfo
                    ? "ep_backup_info_block"
                    : "ep_backup_info_block hidden"
                }
              >
                {intl.formatMessage({
                  id: "edit_profile_backup_info",
                })}
              </div>
              <div className="ep_backup_nav_wrap">
                <Link to="/backup">
                  <div className="ep_backup_nav_btn">
                    {intl.formatMessage({
                      id: "edit_profile_go_to_backup",
                    })}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
