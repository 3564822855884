import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./VisitHUBsite.css";
import { Redirect } from "react-router-dom";
import { getVisitedHUBs } from "../../services/getVisitedHubs";
import { useIntl } from "react-intl";

const VisitAnotherHUBsite = () => {
  const intl = useIntl();
  const [hubID, setHubID] = useState("");
  const [redirected, setRedirected] = useState(false);

  const [data, setData] = useState([]);

  const getApiData = () => {
    let hubid =
      sessionStorage.getItem("hub_token") !== null
        ? JSON.parse(sessionStorage.getItem("hub_token"))
            .userData.my_hub_id.slice(-5)
            .replace("_", "")
        : null;

    let send_credential_to_registrar = {
      target_hub_id: hubid,
      requestor_hub_id: hubid
    };

    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    send_credential_to_registrar.section = "";
    getVisitedHUBs(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        setData(result.data.data_array);
      }
    });
  };

  useEffect(() => {
    getApiData();
  }, []);

  const redirect = () => {
    if (redirected === true) {
      return <Redirect to="/HUBsite" />;
    }
  };
  return (
    <div className="visit_container">
      <img
        className="login_page_bg"
        src="/assets/images/background_img_hubsite.png"
        alt=""
      />
      {redirect()}
      <div className="visit_container_title">
        {intl.formatMessage({
          id: "visit_title"
        })}
      </div>
      <div className="visit_block">
        <div className="visit_block_title">
          {intl.formatMessage({
            id: "visit_hub_num"
          })}
        </div>
        <input
          onChange={event => setHubID(event.target.value)}
          type="number"
          value={hubID}
          className="visit_input"
          placeholder={intl.formatMessage({
            id: "change_admin_pass_enter_your_hub_id"
          })}
        />
        <div className="visit_block_main_controls">
          <button className="cancel" onClick={() => setRedirected(true)}>
            {intl.formatMessage({
              id: "receipt_display_modal_cancel"
            })}
          </button>
          <Link to={"/HUBsite/" + hubID}>
            <button className="go">
              {intl.formatMessage({
                id: "receipt_display_modal_go"
              })}
            </button>
          </Link>
        </div>
        <div className="visited_hubs_list_title">
          {intl.formatMessage({
            id: "visit_last_hubs"
          })}
        </div>
        <div className="visited_hubs_list_container">
          {data.map((item, id) => {
            return (
              <div className="visited_hubs_list_item" key={id}>
                <div className="visited_hubs_list_item_info">
                  <div className="number">
                    {item.value.slice(-5).replace("_", "")}
                  </div>
                  <div className="name">{item.name}</div>
                </div>
                <div className="visited_hubs_list_item_controls">
                  {/* <button className="white">Contact info</button> */}
                  <Link
                    to={"/HUBsite/" + item.value.slice(-5).replace("_", "")}
                  >
                    <button className="blue">
                      {intl.formatMessage({
                        id: "receipt_display_modal_go"
                      })}
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default VisitAnotherHUBsite;
