import { URLconst } from "./const";

export function FindPosition(userData) {
  let BaseURL = URLconst("navigation/find_position.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function GetBookDataKNV(userData) {
  let BaseURL = URLconst("soap_req/get_book_data_general.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function CheckIfInStock(userData) {
  let BaseURL = URLconst("navigation/check_if_in_stock.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SaveTextSearch(userData) {
  let BaseURL = URLconst("navigation/set_search_history.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function FindPositionNew(userData) {
  let BaseURL = URLconst("navigation/find_position_new.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetSearchInShop(userData) {
  let BaseURL = URLconst("navigation/set_search_history_shop.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetSearchInHub(userData) {
  let BaseURL = URLconst("navigation/set_search_history_hub.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetSurveyInShop(userData) {
  let BaseURL = URLconst("navigation/set_survey_shop.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetSearchRatingInShop(userData) {
  let BaseURL = URLconst("navigation/set_search_rating_shop.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetSearchRatingInHub(userData) {
  let BaseURL = URLconst("navigation/set_search_rating_hub.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function SetHubIdInShop(userData) {
  let BaseURL = URLconst("navigation/set_customer_hub_id.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function GetShopSearchHistory(userData) {
  let BaseURL = URLconst("navigation/get_search_history.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}
