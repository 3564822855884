import React, { Component } from "react";
import "./BookcolSearchBook.css";
import { FormattedMessage } from "react-intl";
import BookcolSuggestions from "./BookcolSuggestions";
import { GetBookDataKNV, CheckIfInStock } from "../../services/FindPosition";
import { GetBookShops } from "../../services/GetShopsInfo";
import { OrderBook } from "../../services/OrderBook";

class BookcolSearchBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shop_id: null,
      shop_name: null,
      search_result: null,
      searched_keyword: "",
      search_string: "",
      book_info: null,
      show_vendor_choice: false,

      selectedSuggestion: null,

      show_book_details: false,
      product_not_in_stock: false,

      in_stock: null,
      show_order_result_box: false,
      order_result: null,

      book_shops: []
    };
  }

  componentDidMount() {
    this.get_shops_info();
  }

  get_book_data_knv_general = () => {
    const send_credential_to_registrar = {
      search_string: this.state.search_string
    };
    if (this.state.sessionID !== null) {
      send_credential_to_registrar.sessionID = this.state.sessionID;
    }
    GetBookDataKNV(send_credential_to_registrar).then(result => {
      if (result.book_info) {
        this.setState({ book_info: result.book_info });
      }
      if (result.sessionID) {
        this.setState({ sessionID: result.sessionID });
      }
    });
  };

  send_order_to_shop = () => {
    const send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.external_id_1 = this.state.order_ean;
    send_credential_to_registrar.shop_id = this.state.shop_id;
    send_credential_to_registrar.title = this.state.order_article;
    send_credential_to_registrar.publisher = this.state.order_publisher;
    send_credential_to_registrar.price = this.state.order_price;
    send_credential_to_registrar.possession = "ordered";
    send_credential_to_registrar.kind = "book";
    send_credential_to_registrar.possession_related_to =
      send_credential_to_registrar.shop_id;
    OrderBook(send_credential_to_registrar).then(result => {
      const order_result = result;
      if (order_result.success) {
        this.setState({
          show_order_result_box: true,
          show_order_box: false,
          order_ean: undefined,
          order_article: undefined,
          book_info: null,
          order_result: "sucess"
        });
      } else {
        this.setState({
          show_order_result_box: true,
          show_order_box: false,
          order_ean: undefined,
          order_article: undefined,
          book_info: null,
          order_result: "error"
        });
      }
    });
  };

  get_shops_info = () => {
    const send_credential_to_registrar = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "book";
    GetBookShops(send_credential_to_registrar).then(result => {
      if (result.book_shops) {
        if (result.book_shops.length > 0) {
          this.setState({
            book_shops: result.book_shops,
            shop_id: result.book_shops[0].shop_hub_id,
            shop_name: result.book_shops[0].shop_name
          });
          this.props.shop_to_search(result.book_shops[0].shop_hub_id);
        }
      }
    });
  };

  // timeout to wait for user to finish typing(break longer than 500ms), start searching only if min number of char is met (5)
  handleChange = e => {
    const tgname = e.target.name;
    const tgval = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          [tgname]: tgval,
          search_result: null,
          top_pos: 0,
          left_pos: 0,
          show_book_details: false,
          product_not_in_stock: false,
          selected: false
        },
        () => {
          if (this.state.search_string.length > 2) {
            this.get_book_data_knv_general();
          }
        }
      );
    }, 500);
  };

  checkIfOnStorage = e => {
    const send_credential_to_registrar = {
      ean: e
    };
    send_credential_to_registrar.shop_id = this.state.shop_id;
    CheckIfInStock(send_credential_to_registrar).then(result => {
      if (result.search_result) {
        this.setState({
          search_result: result.search_result,
          in_stock: result.search_result[0].in_stock
        });
      } else {
        this.setState({
          product_not_in_stock: true
        });
      }
    });
  };

  suggestions_callback = (index, ean, article_name, publisher, price) => {
    if (ean.constructor === Array) {
      ean = ean[0];
    }
    // this.checkIfOnStorage(this.state.book_info[index]["EAN"]);
    this.checkIfOnStorage(ean);
    document.getElementById("bsch_searchbook_input").value = "";
    this.setState({
      book_info: [this.state.book_info[index]],
      show_book_details: true,
      selected: true,
      show_order_box: true,
      order_article: article_name,
      order_ean: ean,
      order_publisher: publisher,
      order_price: price
    });
  };

  handleSearchClick = () => {
    if (this.state.search_string.length !== 0) {
      this.get_book_data_knv_general();
    }
  };

  switchVendor = (shop_hub_id, shop_name) => {
    this.setState(
      {
        shop_id: shop_hub_id,
        shop_name,
        show_vendor_choice: !this.state.show_vendor_choice
      },
      () => {
        this.props.shop_to_search(shop_hub_id);
      }
    );
  };

  showVendorChoices = () => {
    this.setState({ show_vendor_choice: !this.state.show_vendor_choice });
  };

  render() {
    // const { SearchBook } = this.state;

    let order_box;
    if (this.state.show_order_box === true) {
      order_box = (
        <div
          className="bsch_search_order_btn"
          onClick={() => this.send_order_to_shop()}
        >
          <FormattedMessage id="bookcol_store_tab_order_book" />
        </div>
      );
    }

    let order_result;
    if (this.state.show_order_result_box === true) {
      this.state.order_result === "sucess"
        ? (order_result = <div>Bestellung erfolgreich</div>)
        : (order_result = (
            <div>
              In Ihrem Personal-HUB ist noch kein Einkauf bei einer Buchhandlung
              registriert; Bestellung kann deswegen nicht aufgegeben werden.
              Sammeln Sie Ihre Kassenbons digital um solche Dienste zu
              aktivieren.
            </div>
          ));
    }
    let showVendors;
    if (this.state.show_vendor_choice) {
      showVendors = "flex";
    } else {
      showVendors = "none";
    }

    return (
      <div id="BookcolSearchBook">
        <div className="bsch_searchbook_input_container">
          <div className="bsch_sbic_shop_name">{this.state.shop_name}</div>
          {this.state.shop_id !== null ? (
            <div className="bsch_searchbook_input_and_switch_container">
              <div className="bsch_searchbook_input_wrapper">
                <img
                  className="bsch_search_zoom_icon"
                  alt=""
                  src="/assets/images/zoom_icon.png"
                  onClick={this.handleSearchClick}
                />
                <input
                  className="bsch_searchbook_input"
                  id="bsch_searchbook_input"
                  name="search_string"
                  type="text"
                  onFocus={() => {
                    sessionStorage.setItem("prevent_orientation_change", true);
                  }}
                  onBlur={() => {
                    sessionStorage.setItem("prevent_orientation_change", false);
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                  placeholder="Suchwort oder ISBN"
                />
              </div>
              <div className="bsch_searchbook_switch_wrapper">
                <div
                  className="bsch_switch_vendor_btn"
                  onClick={this.showVendorChoices}
                >
                  &#8596;{" "}
                  <FormattedMessage id="bookcol_search_book_switch_vendor" />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <FormattedMessage id="bookcol_search_book_no_shops_message" />
            </div>
          )}
        </div>

        <div
          className="bsch_switch_vendor_wrapper"
          style={{ display: showVendors }}
        >
          {this.state.book_shops.map((item, i) => {
            if (!item) {
              return null;
            }
            return (
              <div
                className="bsch_switch_vendor_item"
                key={i}
                onClick={() => {
                  this.switchVendor(item.shop_hub_id, item.shop_name);
                }}
              >
                {item.shop_name}
              </div>
            );
          })}
        </div>

        {this.state.book_info !== null ? (
          <div className="bsch_search_container">
            <BookcolSuggestions
              book_info={this.state.book_info}
              callbackFromParent={this.suggestions_callback}
              selectedState={this.state.selected}
              in_stock={this.state.in_stock}
              product_not_in_stock={this.state.product_not_in_stock}
            />

            {order_box}
          </div>
        ) : null}
        {order_result}
      </div>
    );
  }
}

export default BookcolSearchBook;
