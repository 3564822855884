import React from "react";
import "./Impressum.css";
// import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Impressum = () => {
  //   const [redirected, setRedirected] = useState(false);
  let history = useHistory();
  //   console.log(props.history);

  //   const redirect = () => {
  //     if (redirected === true) {
  //       return <Redirect to="/HUBsite" />;
  //     }
  //   };

  return (
    <div className="impressum_container">
      <img
        className="bsb_top_back_arrow"
        alt=""
        src="/assets/images/left_arrow_icon_v2.png"
        onClick={() => history.goBack()}
      />
      {/* {redirect()} */}
      <h2>Impressum</h2>
      <p>HUBweb GmbH</p>
      <p>Pater-Rupert-Mayer-Str. 1</p>
      <p>82008 Unterhaching</p>
      <p>Telefon: +49 89 32 495 491</p>
      <p>Telefax: +49 89 32 495 490</p>

      <p>E-Mail: info@hub-web.com</p>
      <p>Internet: www.hub-web.com</p>
      <p>Vertretungsberechtigter Geschäftsführer: Dalibor Sevcik</p>
      <p>Verantwortlich für die redaktionellen Inhalte: Dalibor Sevcik</p>

      <p>Registergericht: AG München</p>
      <p>Registernummer: HRB 248352</p>
      <p>
        Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz:
        DE323996886
      </p>
    </div>
  );
};
export default Impressum;
