import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { getBloodPressureList } from "../../../services/GetActiveMinuteData";
import { postBloodAndPressure } from "../../../services/PostActiveMinuteData";
import { putBloodAndPressure } from "../../../services/PutActiveMinuteData";
import { getActiveMinuteSettings } from "../../../services/GetActiveMinuteData";
import { putActiveMinuteSettings } from "../../../services/PutActiveMinuteData";
import { injectIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
// import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  disabled: {
    color: "grey !important"
  },
  mainBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  togglesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  activitiessContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  info: {
    fontSize: "12px",
    fontFamily: "Roboto",
    width: "100%",
    padding: "3px"
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    backgroundColor: "#f5f7fa",
    borderRadius: "4px",
    padding: "5px",
    marginTop: "5px"
  },
  inputBlock: {
    width: "49%",
    display: "flex",
    flexDirection: "column"
  },
  inputNumBlock: {
    width: "25%",
    display: "flex",
    flexDirection: "column"
  },
  inputBigBlock: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  inputArea: {
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%",
    paddingLeft: "5px"
  },
  input: {
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%",
    paddingLeft: "5px",
    height: "30px"
  },
  inputTitle: {
    color: "#414248",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px"
  },
  listContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  listItem: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    margin: "5px 0 5px 0",
    flexWrap: "wrap"
  },
  listItemText: {
    margin: "5px",
    color: "#414248",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px"
  },
  successBtn: {
    border: "1px solid #AAC8FF",
    width: "110px",
    borderRadius: "4px",
    backgroundColor: "#3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25, 50, 89, 0.2)",
    color: "#fff",
    transition: "0.5s all"
  },
  editBtn: {
    backgroundColor: "#fff",
    border: "1px solid #3FA5FF",
    borderRadius: "4px",
    color: "#3FA5FF"
  },
  listItemNumberInput: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#fff",
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    height: "30px"
  },
  listItemTextInput: {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #f1f1f1",
    borderRadius: "4px",
    paddingLeft: "5px",
    height: "30px"
  },
  footer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "5px"
  },
  cancelBtn: {
    padding: "0 5px 0 5px",
    backgroundColor: "#fff",
    border: "1px solid #3FA5FF",
    borderRadius: "4px",
    color: "#3FA5FF",
    marginRight: "5px"
  },
  title: {
    fontSize: "12px"
  }
}));

const defaultLayout = { sys: "", dis: "", personal_note: "" };

const BloodPressure = ({ handleSubmitIconChange, element, intl }) => {
  const classes = useStyles();
  const [version, setVersion] = useState("basic");
  const [day, setDay] = useState("today");
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState(defaultLayout);
  const [editItem, setEditItem] = useState({
    value: "",
    reference_value: "",
    personal_note: ""
  });
  const [settingsID, setSettingsID] = useState(0);
  const [editItemId, setEditItemId] = useState(null);
  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", "")
  };
  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  const getApiData = () => {
    send_credential_to_registrar.limit = 5;
    getBloodPressureList(send_credential_to_registrar).then(result => {
      if (result.data.error) {
        // console.log(result)
      } else {
        setData(result.data.data_array);
      }
    });
    getActiveMinuteSettings(send_credential_to_registrar).then(result => {
      if (result.data.error) {
      } else {
        result.data.data_array.forEach(item => {
          if (Number(item.value2) === 1010) {
            if (item.value === "yesterday") {
              setDay(item.value);
            }
            setSettingsID(item.id);
          }
        });
      }
    });
  };

  useEffect(() => {
    getApiData(); // eslint-disable-next-line
  }, []);

  const updateSettingsInApi = newDay => {
    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.value = newDay;
    send_credential_to_registrar.data.id = settingsID;
    // console.log(send_credential_to_registrar)
    putActiveMinuteSettings(send_credential_to_registrar);
  };

  const handleVersion = (event, newVersion) => {
    if (newVersion !== null) {
      setVersion(newVersion);
    }
  };

  const getDate = () => {
    if (day === "today") {
      return moment().format("YYYY-MM-DDTHH:mm:ss");
    } else {
      return moment()
        .subtract(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss");
    }
  };

  const handleDay = (event, newDay) => {
    if (newDay !== null) {
      setDay(newDay);
      updateSettingsInApi(newDay);
    }
  };

  useEffect(() => {
    setInputs({ ...inputs, date_on: getDate() });
    // eslint-disable-next-line
  }, [day]);

  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.category = "blood-pressure";
    send_credential_to_registrar.data.unit = "mmHg";

    if (editItemId !== null) {
      send_credential_to_registrar.data.value = editItem.value;
      send_credential_to_registrar.data.date_on = editItem.date_on;
      send_credential_to_registrar.data.reference_value =
        editItem.reference_value;
      send_credential_to_registrar.data.personal_note = editItem.personal_note;
      send_credential_to_registrar.data.id = editItem.id;
      putBloodAndPressure(send_credential_to_registrar).then(() => {
        handleSubmitIconChange(element);
        setInputs(defaultLayout);
        getApiData();
        setEditItemId(null);
      });
    } else {
      const editItemDate = moment(editItem.date_on).format("YYYY-MM-DD");
      send_credential_to_registrar.data.date_on = editItemDate;
      send_credential_to_registrar.data.value = inputs.sys;
      send_credential_to_registrar.data.reference_value = inputs.dis;
      send_credential_to_registrar.data.personal_note = inputs.personal_note;
      postBloodAndPressure(send_credential_to_registrar).then(() => {
        getApiData();
        setInputs(defaultLayout);
        handleSubmitIconChange(element);
      });
    }
  };

  const toggleButtonsVersion = () => {
    return (
      <ToggleButtonGroup
        value={version}
        exclusive
        onChange={handleVersion}
        aria-label="text alignment"
      >
        <ToggleButton value="basic" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_basic"
          })}
        </ToggleButton>
        <ToggleButton
          value="advanced"
          className={classes.disabled}
          disabled
          aria-label="center aligned"
        >
          {intl.formatMessage({
            id: "active_minute_advanced"
          })}
        </ToggleButton>
        <ToggleButton
          className={classes.disabled}
          disabled
          value="pro"
          aria-label="right aligned"
        >
          {intl.formatMessage({
            id: "active_minute_pro"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  const toggleButtonsDay = () => {
    return (
      <ToggleButtonGroup
        value={day}
        exclusive
        onChange={handleDay}
        aria-label="text alignment"
      >
        <ToggleButton value="yesterday" aria-label="left aligned">
          {intl.formatMessage({
            id: "active_minute_yesterday"
          })}
        </ToggleButton>
        <ToggleButton value="today" aria-label="center aligned">
          {intl.formatMessage({
            id: "active_minute_today"
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const handleEdit = (item, id) => {
    setEditItem({
      id: item.id,
      value: item.value,
      reference_value: item.reference_value,
      date_on: item.date_on,
      personal_note: item.personal_note
    });
    setEditItemId(id);
  };

  const renderList = () => {
    return data.map((item, id) => {
      if (id === editItemId) {
        return (
          <div key={id} className={classes.inputsContainer}>
            <div className={classes.inputBlock}>
              <span className={classes.inputTitle}>
                {intl.formatMessage({
                  id: "active_minute_date_on"
                })}
              </span>
              <input
                type="date"
                className={classes.input}
                value={moment(editItem.date_on).format("YYYY-MM-DD")}
                onChange={event =>
                  setEditItem({ ...editItem, date_on: event.target.value })
                }
              />
            </div>
            <div className={classes.inputNumBlock}>
              <span className={classes.inputTitle}>
                {intl.formatMessage({
                  id: "active_minute_bloodpressure_systolic"
                })}
              </span>
              <input
                type="number"
                className={classes.input}
                value={editItem.value}
                onChange={event =>
                  setEditItem({ ...editItem, value: event.target.value })
                }
              />
            </div>
            <div className={classes.inputNumBlock}>
              <span className={classes.inputTitle}>
                {intl.formatMessage({
                  id: "active_minute_bloodpressure_diastolic"
                })}
              </span>
              <input
                type="number"
                className={classes.input}
                value={editItem.reference_value}
                onChange={event =>
                  setEditItem({
                    ...editItem,
                    reference_value: event.target.value
                  })
                }
              />
            </div>
            <div className={classes.inputBigBlock}>
              <span className={classes.inputTitle}>
                {intl.formatMessage({
                  id: "active_minute_note"
                })}
              </span>
              <textarea
                type="text"
                className={classes.inputArea}
                rows={3}
                value={
                  editItem.personal_note !== null ? editItem.personal_note : ""
                }
                onChange={event =>
                  setEditItem({
                    ...editItem,
                    personal_note: event.target.value
                  })
                }
              />
            </div>
            {renderFooter()}
          </div>
        );
      } else {
        return (
          <div key={id} className={classes.listItem}>
            <div
              style={{ width: "100px", flex: "0 0 100px" }}
              className={classes.listItemText}
            >
              {moment(item.date_on).format("ddd, DD MMM YYYY")}
            </div>
            <div
              style={{ width: "50px", flex: "0 0 50px" }}
              className={classes.listItemText}
            >
              {item.value}/{item.reference_value}
            </div>
            <div
              style={{
                width: "calc(100% - 206px)",
                height: "18px",
                overflow: "auto"
              }}
              className={classes.listItemText}
            >
              {item.personal_note}
            </div>
            <button
              style={{ width: "26px", flex: "0 0 26px" }}
              className={classes.editBtn}
              onClick={() => handleEdit(item, id)}
            >
              <EditIcon />
            </button>
          </div>
        );
      }
    });
  };

  const getInputs = () => {
    return (
      <div className={classes.inputsContainer}>
        <div className={classes.inputBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_date_on"
            })}
          </span>
          <input
            type="date"
            className={classes.input}
            value={moment(inputs.date_on).format("YYYY-MM-DD")}
            onChange={event =>
              setInputs({ ...inputs, date_on: event.target.value })
            }
          />
        </div>
        <div className={classes.inputNumBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_bloodpressure_systolic"
            })}
          </span>
          <input
            type="number"
            className={classes.input}
            value={inputs.sys}
            onChange={event =>
              setInputs({ ...inputs, sys: event.target.value })
            }
          />
        </div>
        <div className={classes.inputNumBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_bloodpressure_diastolic"
            })}
          </span>
          <input
            type="number"
            className={classes.input}
            value={inputs.dis}
            onChange={event =>
              setInputs({ ...inputs, dis: event.target.value })
            }
          />
        </div>
        <div className={classes.inputBigBlock}>
          <span className={classes.inputTitle}>
            {intl.formatMessage({
              id: "active_minute_note"
            })}
          </span>
          <textarea
            type="text"
            className={classes.inputArea}
            rows={2}
            value={inputs.personal_note !== null ? inputs.personal_note : ""}
            onChange={event =>
              setInputs({
                ...inputs,
                personal_note: event.target.value
              })
            }
          />
        </div>
        {renderFooter()}
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        {editItemId !== null ? (
          <button
            className={classes.cancelBtn}
            onClick={() => setEditItemId(null)}
          >
            {intl.formatMessage({
              id: "active_minute_cancel"
            })}
          </button>
        ) : null}
        <button className={classes.successBtn} onClick={() => sendValueToApi()}>
          {intl.formatMessage({
            id: "active_minute_save"
          })}
        </button>
      </div>
    );
  };
  return (
    <div className={classes.mainBlock}>
      <div className={classes.togglesContainer}>
        <div>{toggleButtonsVersion()}</div>
        <div>{toggleButtonsDay()}</div>
      </div>
      <div className={classes.activitiessContainer}>
        {editItemId === null ? getInputs() : null}
        {editItemId === null ? (
          <Divider style={{ width: "100%", margin: "5px 0 5px 0" }} />
        ) : null}
        <div className={classes.listContainer}>{renderList()}</div>
      </div>
    </div>
  );
};

export default injectIntl(BloodPressure);
