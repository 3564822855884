import React, { Component } from "react";
import BookcolLibraryTab from "../../screens/Bookcollector/BookcolLibraryTab";
import BookcolStoreTab from "../../screens/Bookcollector/BookcolStoreTab";
import BookcolOrderTab from "../../screens/Bookcollector/BookcolOrderTab";
import BookcolSingleBook from "../../screens/Bookcollector/BookcolSingleBook";
// import BookcolInsertBook from "../../screens/Bookcollector/BookcolInsertBook";
import Content from "../../components/Content";
import { Redirect } from 'react-router-dom'

class BookcolTabsControl extends Component {
  constructor() {
    super();
    this.state = {
      tab_to_show: "library",
      display_single: false,
      bookID: null,
      book_index: null,
      books_in_cat: null,
      sort_by: null,
      sort_dir: null,
      search_string: null,

      prevBook: null,
      currBook: null,
      nextBook: null,
      arrLength: null,
      redirected:false
    };
  }

  switchTabCallback = e => {
    this.setState({
      tab_to_show: e,
      display_single: false
    });
  };

  closeSingleCallback = () => {
    this.setState({
      display_single: false
    });
  };

  showBook = (id, index, count, category, sortby, direction, search_string) => {
    this.setState({
      bookID: id,
      book_index: index,
      books_in_cat: count,
      books_cat: category,
      display_single: true,
      sort_by: sortby,
      sort_dir: direction,
      search_string: search_string
    });
  };
  redirect = () => {
    if(this.state.redirected === true){
      console.log(this.state.redirected)
      return <Redirect to='/HUBsite' />
    }
  }
  render() {
    return (
      <Content
        currComp={this.state.display_single ? "bookColSingle" : "bookColTab"}
        currTab={this.state.tab_to_show}
        bookColCallback={this.switchTabCallback}
      >
        <img
          className="bsb_top_back_arrow"
          alt=""
          src="/assets/images/left_arrow_icon_v2.png"
          onClick={()=>this.setState({...this.state, redirected: true})}
        />
        {this.redirect()}
        <div id="BookcolTabsControl">
          {this.state.tab_to_show === "library" &&
          this.state.display_single === false ? (
            <BookcolLibraryTab
              switchTabCallback={this.switchTabCallback}
              showBook={this.showBook}
            />
          ) : null}
          {this.state.tab_to_show === "store" &&
          this.state.display_single === false ? (
            <BookcolStoreTab switchTabCallback={this.switchTabCallback} />
          ) : null}
          {this.state.tab_to_show === "orders" &&
          this.state.display_single === false ? (
            <BookcolOrderTab switchTabCallback={this.switchTabCallback} />
          ) : null}
          {this.state.bookID !== null && this.state.display_single === true ? (
            <BookcolSingleBook
              bookID={this.state.bookID}
              book_index={this.state.book_index}
              books_in_cat={this.state.books_in_cat}
              books_cat={this.state.books_cat}
              closeSingleCallback={this.closeSingleCallback}
              sort_by={this.state.sort_by}
              sort_dir={this.state.sort_dir}
              search_string={this.state.search_string}
            />
          ) : null}
          {/* {this.state.tab_to_show === "insert" &&
          this.state.display_single === false ? (
            <BookcolInsertBook
              closeSingleCallback={this.closeSingleCallback}
              switchTabCallback={this.switchTabCallback}
            />
          ) : null} */}
        </div>
      </Content>
    );
  }
}

export default BookcolTabsControl;
