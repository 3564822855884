import "./SwipeableListItem.css";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class SwipeableListItem extends React.Component {
  // DOM Refs
  listElement;
  wrapper;
  background;

  // Drag & Drop
  dragStartX = 0;
  left = 0;
  dragged = false;

  // FPS Limit
  startTime;
  fpsInterval = 1000 / 60;

  constructor(props) {
    super(props);

    this.listElement = null;
    this.wrapper = null;
    this.background = null;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onDragStartMouse = this.onDragStartMouse.bind(this);
    this.onDragStartTouch = this.onDragStartTouch.bind(this);
    this.onDragEndMouse = this.onDragEndMouse.bind(this);
    this.onDragEndTouch = this.onDragEndTouch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.onClicked = this.onClicked.bind(this);

    this.onSwiped = this.onSwiped.bind(this);
    this.state = { left: 0 };
  }

  componentDidMount() {
    window.addEventListener("mouseup", this.onDragEndMouse);
    window.addEventListener("touchend", this.onDragEndTouch);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onDragEndMouse);
    window.removeEventListener("touchend", this.onDragEndTouch);
  }

  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener("mousemove", this.onMouseMove);
  }

  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener("touchmove", this.onTouchMove);
  }

  onDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.className = "ListItem";
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  onDragEndMouse(evt) {
    window.removeEventListener("mousemove", this.onMouseMove);
    this.onDragEnd();
  }

  onDragEndTouch(evt) {
    window.removeEventListener("touchmove", this.onTouchMove);
    this.onDragEnd();
  }

  onDragEnd() {
    if (this.dragged) {
      this.dragged = false;
      const threshold = this.props.threshold || 0.25;
      if (this.left < this.listElement.offsetWidth * threshold * -1) {
        this.left = -this.listElement.offsetWidth / 4;
        this.onSwiped();
      } else if (this.left > this.listElement.offsetWidth * threshold) {
        this.left = this.listElement.offsetWidth / 4;
        this.onSwiped();
      } else {
        this.left = 0;
      }

      this.listElement.className = "BouncingListItem";
      this.listElement.style.transform = `translateX(${this.left}px)`;
    }
  }
  checkDirection(left) {
    if (left < 0) {
      this.setState({ left: 1 });
    } else {
      this.setState({ left: 2 });
    }
  }
  onMouseMove(evt) {
    const left = evt.clientX - this.dragStartX;
    this.left = left;
    this.checkDirection(left);
  }

  onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    this.left = left;
    this.checkDirection(left);
  }

  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);
    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      this.listElement.style.transform = `translateX(${this.left}px)`;

      const opacity = (Math.abs(this.left) / 100).toFixed(2);
      if (opacity < 1 && opacity.toString() !== this.background.style.opacity) {
        this.background.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        this.background.style.opacity = "1";
      }
      this.startTime = Date.now();
    }
  }

  onClicked() {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }

  onSwiped() {
    this.layer = 0;
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }
  showHistory = () => {
    this.showDetails();
    this.props.children.props.handleClickstatusHistory(
      this.props.children.props.index
    );
  };

  showDetails = () => {
    this.left = 0;
    this.listElement.className = "BouncingListItem";
    this.listElement.style.transform = `translateX( ${this.left}px )`;
    this.props.children.props.handleClick(this.props.children.props.index);
  };

  render() {
    const { item, cancel_book_order } = this.props.children.props;
    return (
      <div className="Wrapper" ref={div => (this.wrapper = div)}>
        <div ref={div => (this.background = div)} className="Background">
          {this.state.left === 1 ? (
            <div className="right-side">
              <button className={"btn details"} onClick={this.showDetails}>
                Details
              </button>
                <button
                  disabled={!this.props.children.props.item.status_history}
                  className={this.props.children.props.item.status_history ? "btn history" : "btn history grey-out"} onClick={this.showHistory}>
                  History
                </button>
            </div>
          ) : (
            <div ref={div => (this.background = div)} className="left-side">
              <button
                className={"btn delete"}
                onClick={event =>
                  cancel_book_order(event, item.id, item.possession_related_to)
                }
              >
                <DeleteIcon fontSize="large" />
              </button>
            </div>
          )}
        </div>
        <div
          onClick={this.onClicked}
          ref={div => (this.listElement = div)}
          onMouseDown={this.onDragStartMouse}
          onTouchStart={this.onDragStartTouch}
          className="ListItem"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SwipeableListItem;
