import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
// import { TextField } from "@material-ui/core";
import Bubble, { BoxSizing, Position } from "react-nice-bubble";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";

import moment from "moment";
import { useIntl } from "react-intl";

const useStyles = makeStyles({
  root: {
    fontSize: "14px"
  },
  num_input: {
    width: "50px",
    maxWidth: "100px"
  },
  switch: {
    // fontSize: "14px"
  },
  button: {
    boxSizing: "border-box",
    height: "33px",
    width: "60px",
    border: "1px solid #AAC8FF",
    color: "white",
    borderRadius: "4px",
    backgroundColor: "#3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)"
  }
});

const Details = ({
  set_permissions,
  setRowArrows,
  setColumnArrows,
  rowArrows,
  columnArrows,
  tablePermissions,
  table,
  rowId
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [permissions, setPermissions] = useState(tablePermissions);
  const [permissionsNew, setPermissionsNew] = useState({
    granted_start: null,
    granted_end: null
  });

  const [period, setPeriod] = useState(86400);
  // const [periodTimes, setPeriodTimes] = useState(1);
  const [periodTimes] = useState(1);
  const [periodContainer, setPeriodContainer] = useState(false);
  const [bubleWidth, setBubleWidth] = useState(null);
  const permissions_ref = useRef();
  useEffect(() => {
    permissions_ref.current = permissions;
  });

  useEffect(() => {
    setPermissions(tablePermissions);
  }, [tablePermissions]);

  useEffect(() => {
    if (periodContainer) {
      if (period !== "permanent") {
        if (
          permissions_ref.current.granted_start !== null &&
          permissions_ref.current.granted_end !== null
        ) {
          setPermissionsNew({
            granted_start: moment(permissions_ref.current.granted_start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            granted_end: moment(permissions_ref.current.granted_end).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          });
        } else if (permissions_ref.current.granted_start !== null) {
          setPermissionsNew({
            ...permissions_ref.current,
            granted_end: moment
              .unix(
                moment(permissions_ref.current.granted_start).unix() +
                  period * periodTimes
              )
              .format("YYYY-MM-DD HH:mm:ss")
          });
        } else {
          setPermissionsNew({
            ...permissions_ref.current,
            granted_end: moment
              .unix(moment().unix() + period * periodTimes)
              .format("YYYY-MM-DD HH:mm:ss"),
            granted_start: moment().format("YYYY-MM-DD HH:mm:ss")
          });
        }
      } else {
        setPermissionsNew({
          ...permissions_ref.current,
          granted_end: null
        });
      }
    } else {
      setPermissionsNew({
        granted_start: null,
        granted_end: null
      });
    }
  }, [period, periodTimes, periodContainer]);

  const updateDate = (date, which) => {
    setPermissionsNew({
      ...permissionsNew,
      ["granted_" + which]: date.replace("T", " ")
    });
  };

  useEffect(() => {
    if (document.getElementById("permTable").offsetWidth !== null) {
      const width = document.getElementById("permTable").offsetWidth;
      // console.log(width)
      setBubleWidth(width);
    } // eslint-disable-next-line
  }, [document.getElementById("permTable")]);

  return (
    <>
      <TableRow
        className="permissions_row"
        style={
          rowId % 2 !== 0
            ? { backgroundColor: "white" }
            : { backgroundColor: "rgba(71, 119, 186, 0.07)" }
        }
      >
        <TableCell colSpan={6} id="permTable">
          <Bubble
            height={40}
            width={bubleWidth}
            arrowSize={16}
            arrowDegree0={45}
            arrowDegree1={45}
            arrowOffset={60}
            position={Position.TOP_LEFT}
            borderWidth={1}
            borderColor="grey"
            borderRadius={10}
            boxSizing={BoxSizing.BORDER_BOX}
          >
            <div className="cell_details_wrapper">
              <button
                onClick={() => {
                  set_permissions(
                    table,
                    Number(permissions.granted) === 1 ? 0 : 1,
                    permissionsNew.granted_start,
                    permissionsNew.granted_end
                  );
                }}
                className="permissions_btn"
              >
                {Number(permissions.granted) === 1 ? (
                  <img src="/assets/images/table on.svg" alt="" />
                ) : (
                  <img src="/assets/images/table off.svg" alt="" />
                )}
              </button>
              <button
                onClick={() => {
                  setRowArrows(!rowArrows);
                  setColumnArrows(false);
                }}
                className="permissions_btn"
              >
                {rowArrows ? (
                  <img src="/assets/images/line on.svg" alt="" />
                ) : (
                  <img src="/assets/images/line off.svg" alt="" />
                )}
              </button>
              <button
                onClick={() => {
                  setColumnArrows(!columnArrows);
                  setRowArrows(false);
                }}
                className="permissions_btn"
              >
                {columnArrows ? (
                  <img src="/assets/images/row on.svg" alt="" />
                ) : (
                  <img src="/assets/images/row off.svg" alt="" />
                )}
              </button>
              {/* <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  checked={periodContainer}
                  onChange={() => {
                    setPeriodContainer(!periodContainer);
                  }}
                color="primary"
                />
              }
              label="set a period"
            /> */}
              <div
                style={{
                  margin: " 0 10px 0 10px",
                  width: "2px",
                  height: "30px",
                  backgroundColor: "lightgrey"
                }}
              ></div>
              <FormControlLabel
                className="permissions_period_checkbox"
                control={
                  <Checkbox
                    checked={periodContainer}
                    onChange={() => {
                      setPeriodContainer(!periodContainer);
                    }}
                    color="primary"
                  />
                }
                label={intl.formatMessage({
                  id: "hub_site_permissions_limited_period"
                })}
              />

              {periodContainer ? (
                <>
                  {/* <input
                    value={periodTimes}
                    type="number"
                    placeholder="1"
                    className="permissions_input period"
                    onChange={event => {
                      setPeriodTimes(event.target.value);
                    }}
                  />
                  <select
                    value={period}
                    className="permissions_input type"
                    onChange={event => {
                      setPeriod(event.target.value);
                    }}
                  >
                    <option value={3600}>Hour(s)</option>
                    <option value={86400}>Day(s)</option>
                    <option value={604800}>Week(s)</option>
                    <option value={2629745}>Month(s)</option>
                    <option value={31556926}>Year(s)</option>
                    <option value={"permanent"}>Permanent</option>
                  </select> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {/* <div className={classes.num_input}>
                  <TextField
                    type="number"
                    size="small"
                    defaultValue={periodTimes}
                    variant="outlined"
                    onChange={event => {
                      setPeriodTimes(event.target.value);
                    }}
                  />
                </div>
                <FormControl size="small" variant="outlined">
                  <Select
                    value={period}
                    onChange={event => {
                      setPeriod(event.target.value);
                    }}
                  >
                    <MenuItem value={3600}>Hour(s)</MenuItem>
                    <MenuItem value={86400}>Day(s)</MenuItem>
                    <MenuItem value={604800}>Week(s)</MenuItem>
                    <MenuItem value={2629745}>Month(s)</MenuItem>
                    <MenuItem value={31556926}>Year(s)</MenuItem>
                    <MenuItem value={"permanent"}>Permanent</MenuItem>
                  </Select>
                </FormControl> */}
                    <div
                      style={{
                        margin: "0 5px 0 10px",
                        padding: "0",
                        height: "fit-content"
                      }}
                    >
                      {intl.formatMessage({
                        id: "hub_site_permissions_from"
                      })}
                      From
                    </div>
                    <input
                      id="datetime-local"
                      type="date"
                      className="permissions_input_date"
                      onChange={event => {
                        updateDate(event.target.value, "start");
                      }}
                      value={moment(permissionsNew.granted_start).format(
                        "YYYY-MM-DD"
                      )}
                    />
                    <span style={{ margin: "0 5px 0 0" }}>
                      {intl.formatMessage({
                        id: "hub_site_permissions_to"
                      })}
                    </span>
                    {period === "permanent" ? (
                      <div
                        onClick={() => {
                          setPeriod(86400);
                        }}
                      >
                        {intl.formatMessage({
                          id: "hub_site_permissions_permanent"
                        })}
                      </div>
                    ) : (
                      <input
                        id="datetime-local"
                        type="date"
                        className="permissions_input_date"
                        onChange={event => {
                          updateDate(event.target.value, "end");
                        }}
                        value={moment(permissionsNew.granted_end).format(
                          "YYYY-MM-DD"
                        )}
                      />
                    )}
                    <button
                      className={classes.button}
                      onClick={() => {
                        set_permissions(
                          table,
                          Number(permissions.granted),
                          permissionsNew.granted_start,
                          permissionsNew.granted_end
                          //   moment(permissions.granted_start).unix(),
                          //   moment(permissions.granted_end).unix()
                        );
                      }}
                    >
                      Use
                    </button>
                  </div>
                </>
              ) : null}

              {/* <div className="perm_det_btn_group">
              <div className="perm_bottom_btns">

              </div>
            </div>
            <div className="perm_det_btn_group">
              <div className="perm_bottom_btns">
                
              </div>
            </div> */}
            </div>
          </Bubble>
          {/* <div className="permissions_row_triangle"></div> */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default Details;
