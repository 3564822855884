import React, { useEffect, useState } from "react";
import "./FinishChatPoll.scss";
import { SetSurveyInShop } from "../../../services/FindPosition";
import { FormattedMessage } from "react-intl";
import Modal from "@material-ui/core/Modal";
import { useIntl } from "react-intl";

const FinishChatPoll = ({ shopID }) => {
  const intl = useIntl();
  const [surveyAnswered, setSurveyAnswered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAnimation, setModalAnimation] = useState();
  useEffect(() => {
    if (sessionStorage.getItem("survey_answered") !== null) {
      setSurveyAnswered(true);
    }
  }, []);

  const submit_answer = param => {
    var answer;
    if (param) {
      answer = 1;
    } else {
      answer = 0;
    }
    var send_credential_to_registrar = {};
    if (sessionStorage.getItem("hub_token") !== null) {
      send_credential_to_registrar = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    } else {
      send_credential_to_registrar = { my_hub_id: "" };
    }
    send_credential_to_registrar.answer_time = Math.floor(Date.now() / 1000);
    send_credential_to_registrar.answer = answer;
    send_credential_to_registrar.counterpart_hub_id = shopID;

    SetSurveyInShop(send_credential_to_registrar).then(result => {
      if (result.success) {
        setSurveyAnswered(true);
        sessionStorage.setItem("survey_answered", true);
      } else {
        console.log("set survey error");
      }
    });

    setModalOpen(true);
    setModalAnimation("modal_animation_moveIn");
    setTimeout(() => {
      setModalAnimation("modal_animation_moveOut");
    }, 3000);
    setTimeout(() => {
      setModalOpen(false);
    }, 3500);
  };

  return (
    <>
      {!surveyAnswered ? (
        <div className="finish-chat-poll-block">
          <div className="title">
            <FormattedMessage id="virtual_assistant_survey_pt1" />
          </div>
          <div className="list-item">
            - <FormattedMessage id="virtual_assistant_survey_pt2" />
          </div>
          <div className="list-item">
            - <FormattedMessage id="virtual_assistant_survey_pt3" />
          </div>
          <div className="list-item">
            - <FormattedMessage id="virtual_assistant_survey_pt4" />
          </div>
          <div className="btn-block">
            <button
              onClick={() => {
                submit_answer(true);
              }}
              className="btn blue"
            >
              <FormattedMessage id="virtual_assistant_survey_btn1" />
            </button>
            <button
              onClick={() => {
                submit_answer(false);
              }}
              className="btn white"
            >
              <FormattedMessage id="virtual_assistant_survey_btn2" />
            </button>
          </div>
          <div className="phone-img">
            <img src="/assets/images/phone-img.png" alt="" />
          </div>
        </div>
      ) : null}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={modalOpen}
        // onClose={()=>setModalOpen(false)}
      >
        <div className={"modal-block " + modalAnimation}>
          <img
            src="/assets/images/answer_icon.svg"
            alt="Answer icon"
            className="answer_icon"
          />
          <p className="main-text">
            <FormattedMessage id="virtual_assistant_survey_btn3" />
          </p>
          <button
            className="modal-button"
            onClick={() => {
              setModalAnimation("modal_animation_moveOut");
              setTimeout(() => {
                setModalOpen(false);
              }, 500);
            }}
          >
            {intl.formatMessage({
              id: "virtual_assistant_survey_ok"
            })}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default FinishChatPoll;
