import React, { useState, useEffect } from "react";
import moment from "moment";
import TodayIcon from "@material-ui/icons/Today";
import { postSleepData } from "../../../../../services/PostActiveMinuteData";
import { putActiveMinuteSleepData } from "../../../../../services/PutActiveMinuteData";
import TextField from "@material-ui/core/TextField";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { injectIntl } from "react-intl";
const Handle = Slider.Handle;

const getDayValue = (value) => {
  let result = 0;
  result = Math.trunc(value) + (value - Math.trunc(value)) * 0.6;
  result = Number(result).toFixed(2);
  return `${result}h`;
};

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={getDayValue(value) + "h"}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function Basic({ handleSubmitIconChange, item, sleepData, getApiData, intl }) {
  const [clickedItem, setClickedItem] = useState(6);
  const [sleepTimeVal, setSleepTimeVal] = useState(8);
  const [openSlider, setOpenSlider] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState({
    index: 6,
    date_on: moment().format("YYYY-MM-DD"),
    real_id: null,
    personal_note: null,
  });
  const slider_styles = {
    trackStyle: {
      background: "linear-gradient(0deg, #626DA7 0%, #3B4680 100%)",
      width: 12,
      left: "1px",
    },
    trackWide: {
      width: 40,
      left: "-13px",
    },
    trackGray: {
      background: "#CFD7DE",
    },
    railStyle: { backgroundColor: "#ffffff00", width: 12 },
    railWide: { width: 40 },
    handleStyle: [
      {
        border: "transparent",
        borderRadius: "3px",
        height: "16px",
        width: "12px",
        backgroundColor: "transparent",
        bottom: "15%",
        marginLeft: "0px",
        marginBottom: "-7px",
      },
    ],
  };

  const marks = {
    0: {
      style: {
        color: "#626DA7",
      },
      label: <strong>0h</strong>,
    },
    7: {
      style: {
        color: "#626DA7",
      },
      label: <strong>7h</strong>,
    },
    14: {
      style: {
        color: "#626DA7",
      },
      label: <strong>14h</strong>,
    },
  };

  useEffect(() => {
    setSelected({
      index: selected.index,
      date_on: selected.date_on,
      real_id:
        sleepData[selected.index] !== undefined
          ? sleepData[selected.index].real_id
          : null,
      personal_note:
        sleepData[selected.index] !== undefined
          ? sleepData[selected.index].personal_note
          : null,
    });
    // eslint-disable-next-line
  }, [sleepData]);

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid,
    };
    if (!sessionStorage.getItem("hub_token")) {
      return;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.category = "sleep";
    send_credential_to_registrar.data.unit = "time";
    send_credential_to_registrar.data.value = Number(sleepTimeVal);
    send_credential_to_registrar.data.personal_note = selected.personal_note;

    send_credential_to_registrar.data.date_on = selected.date_on;
    if (selected.real_id !== null) {
      send_credential_to_registrar.data.id = selected.real_id;
      putActiveMinuteSleepData(send_credential_to_registrar).then(() =>
        getApiData()
      );
    } else {
      postSleepData(send_credential_to_registrar).then((result) => {
        setSelected({ ...selected, real_id: Number(result.data.insert_id) });
        getApiData();
      });
      if (selected.date_on === moment().format("YYYY-MM-DD")) {
        handleSubmitIconChange(item);
      }
    }
    setOpenSlider(false);
  };

  const handleSlider = (param) => {
    setSuccess(false);
    setSleepTimeVal(param);
  };

  let grid_style = {
    width: "100%",
    height: "1px",
    backgroundColor: "#CFD7DE",
  };

  return (
    <>
      <div
        style={{ display: "flex", marginTop: "10px" }}
        className="sleep_list"
      >
        <img
          style={{
            height: "165px",
            margin: "13px 5px 0 -5px",
          }}
          alt=""
          src="/assets/images/Night bg.png"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "180px",
            flexDirection: "column",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "italic",
            marginTop: "5px",
            marginRight: "5px",
            color: "#545669",
          }}
        >
          <div>14h</div>
          <div>12h</div>
          <div>10h</div>
          <div>8h</div>
          <div>6h</div>
          <div>4h</div>
          <div>2h</div>
          <div>0h</div>
        </div>
        <div
          style={{
            width: "80%",
            height: "200px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              height: "165px",
              marginTop: "13px",
            }}
          >
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              height: "185px",
              marginTop: "13px",
            }}
          >
            {sleepData.map((item, i) => {
              if (!item) {
                return null;
              } else {
                let val = 8;
                let track_style = [
                  { ...slider_styles.trackStyle, ...slider_styles.trackGray },
                ];
                let rail_style = slider_styles.railStyle;
                if (item.value !== null) {
                  val = Number(item.value);
                  track_style = slider_styles.trackStyle;
                }
                if (clickedItem === i) {
                  track_style = [
                    { ...slider_styles.trackStyle, ...slider_styles.trackWide },
                  ];
                  rail_style = {
                    ...slider_styles.railStyle,
                    ...slider_styles.railWide,
                  };
                  if (item.value === null) {
                    track_style = [
                      { ...track_style[0], ...slider_styles.trackGray },
                    ];
                  }
                }

                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      opacity:
                        clickedItem !== null && clickedItem !== i ? "0.7" : "1",
                      width:
                        clickedItem !== i
                          ? "calc(100% / 8)"
                          : "calc(2*(100% / 8))",
                    }}
                    onClick={() => {
                      setClickedItem(i);
                      setSelected({
                        index: i,
                        date_on: item.curr_date,
                        real_id: item.real_id,
                        personal_note: item.personal_note,
                      });
                      setOpenSlider(false);
                      setSuccess(false);
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-25px",
                        fontSize: "12px",
                        transform: "rotate(-45deg)",
                      }}
                    >
                      {item.value !== null ? getDayValue(item.value) : null}
                    </div>
                    <Slider
                      handle={handle}
                      vertical={true}
                      disabled={selected.index === i ? false : true}
                      onChange={handleSlider}
                      trackStyle={track_style}
                      handleStyle={slider_styles.handleStyle}
                      railStyle={rail_style}
                      min={0}
                      max={14}
                      defaultValue={val}
                      step={0.25}
                    />
                    <div
                      style={{
                        height: "20px",
                        color: "#000000",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontStyle: "italic",
                        letterSpacing: "0.43px",
                        lineHeight: "14px",
                        textAlign: "center",
                      }}
                    >
                      {item.display_day}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div className="advanced_input_fields_container">
        <div className="date_icon_wrapper_basic">
          <TodayIcon className="date_icon" fontSize="large" />
        </div>
        <TextField
          id="date"
          // label="Day"
          type="date"
          value={selected.date_on}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            console.log("change");
            setSelected({
              real_id: null,
              date_on: e.target.value,
              personal_note: null,
            });
            setOpenSlider(true);
            setClickedItem(null);
          }}
        />
      </div>
      {openSlider ? (
        <div className="advanced_input_fields_container">
          <img className="night_icon" src="/assets/images/night.svg" alt="" />
          <div style={{ paddingLeft: "10px", width: "60%" }}>
            <Slider
              marks={marks}
              handle={handle}
              onChange={handleSlider}
              trackStyle={slider_styles.trackStyle}
              // handleStyle={slider_styles.handleStyle}
              railStyle={slider_styles.railStyle}
              min={0}
              max={14}
              defaultValue={7}
              step={0.25}
            />
          </div>
        </div>
      ) : null}

      <div className="sleep_submit_container">
        <div className="steps_basic_input_note_container">
          <div style={{ marginBottom: "5px" }}>
            {intl.formatMessage({
              id: "active_minute_note",
            })}
          </div>
          <input
            style={success ? { backgroundColor: "transparent" } : null}
            disabled={success}
            type="text"
            value={
              selected.personal_note !== null ? selected.personal_note : ""
            }
            onChange={(event) =>
              setSelected({
                ...selected,
                personal_note: event.target.value,
              })
            }
          />
        </div>
        <button
          className={success ? "rotate" : ""}
          onClick={() => {
            sendValueToApi();
            setSuccess(true);
          }}
        >
          {success ? (
            <DoneOutlineIcon />
          ) : (
            intl.formatMessage({
              id: "active_minute_save",
            })
          )}
        </button>
      </div>
    </>
  );
}
export default injectIntl(Basic);
