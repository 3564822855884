import React, { useState, useEffect } from "react";
import "./styles.css";
import { getInjuriesAndIssues } from "../../../../services/GetActiveMinuteData";
import { postInjuriesAndIssues } from "../../../../services/PostActiveMinuteData";
import { putInjuriesAndIssues } from "../../../../services/PutActiveMinuteData";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { injectIntl } from "react-intl";

const GraphItem = ({
  data,
  day,
  handleSubmitIconChange,
  element,
  getApiData,
  intl,
}) => {
  const [value, setValue] = useState(0);
  const [note, setNote] = useState("");
  const [edit, setEdit] = useState(false);
  const [itemData, setItemData] = useState({});
  const [open, setOpen] = useState(false);
  const [prevData, setPrevData] = useState({
    date_on: "",
    value: "",
    personal_note: "",
  });
  const send_credential_to_registrar = {
    target_hub_id: JSON.parse(sessionStorage.getItem("hub_token"))
      .userData.my_hub_id.slice(-5)
      .replace("_", ""),
  };
  if (sessionStorage.getItem("mimic_hub")) {
    send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
      "mimic_hub"
    );
  }
  if (!sessionStorage.getItem("hub_token")) {
    send_credential_to_registrar.logged_in = null;
  } else {
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
  }

  useEffect(() => {
    getItemData();
    // eslint-disable-next-line
  }, [data, day]);

  const cleanAll = () => {
    setItemData({});
    setNote("");
    setValue(data.Extracted_value);
    setOpen(false);
    setEdit(false);
  };

  const getDate = () => {
    if (day === "yesterday") {
      return moment().subtract(1, "days");
    } else {
      return moment();
    }
  };

  const getItemData = () => {
    send_credential_to_registrar.provided_date = getDate().format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    getInjuriesAndIssues(send_credential_to_registrar).then((result) => {
      if (result.data.error) {
        // console.log(result)
        cleanAll();
      } else {
        parseData(result.data.data_array);
      }
      getPreviousData();
    });
  };

  const sendValueToApi = () => {
    if (getKeys(itemData).length !== 0) {
      send_credential_to_registrar.data = {
        id: itemData.id,
        date_on: getDate().format("YYYY-MM-DDTHH:mm:ss"),
        personal_note: note,
        value: value,
      };
      putInjuriesAndIssues(send_credential_to_registrar).then((result) => {
        getApiData();
        if (result.data.success) {
          handleSubmitIconChange(element);
        }
      });
    } else {
      send_credential_to_registrar.data = {
        related_issue_id: data.id,
        category: "medical-issue",
        date_on: getDate().format("YYYY-MM-DDTHH:mm:ss"),
        personal_note: note,
        value: value,
      };
      postInjuriesAndIssues(send_credential_to_registrar).then((result) => {
        getApiData();
        if (result.data.success) {
          handleSubmitIconChange(element);
        }
      });
    }
    setOpen(false);
    setEdit(false);
  };

  const parseData = (arr) => {
    cleanAll();
    arr.forEach((element) => {
      if (element.related_issue_id === data.id) {
        setItemData(element);
      }
    });
  };

  const getPreviousData = () => {
    send_credential_to_registrar.provided_date = null;
    send_credential_to_registrar.rel_issue_id = data.id;
    getInjuriesAndIssues(send_credential_to_registrar).then((result) => {
      if (result.data.error) {
      } else {
        setPrevData(result.data.data_array[0]);
      }
    });
  };

  const handleEdit = () => {
    setOpen(true);
    setEdit(true);
    setNote(itemData.personal_note);
    if (itemData.value !== null) {
      setValue(itemData.value);
    } else {
      setValue(0);
    }
  };

  const shortLayout = () => {
    return (
      <div className="short_layout_container">
        <div style={{ fontWeight: "400", paddingLeft: "5px" }}>
          {getDate().format("MMM Do")}
        </div>
        <div style={{ width: "50%" }}>{itemData.personal_note}</div>
        <button
          onClick={() => {
            handleEdit();
          }}
        >
          <EditIcon />
        </button>
      </div>
    );
  };

  const handleValue = (newValue) => {
    if (newValue <= 10 && newValue >= 0) {
      setValue(newValue);
    }
  };

  const editLayout = () => {
    return (
      <div
        className="edit_layout_container"
        style={
          open
            ? { maxHeight: "500px", paddingTop: "5px" }
            : { maxHeight: "0px" }
        }
      >
        <div className="edit_layout_note_container">
          <div style={{ marginBottom: "5px" }}>
            {intl.formatMessage({
              id: "active_minute_note",
            })}
          </div>
          <input
            value={note}
            onChange={(event) => setNote(event.target.value)}
          />
        </div>
        <div className="edit_layout_input_container">
          <div
            style={{
              color: "#414248",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "14px",
              marginBottom: "5px",
            }}
          >
            {intl.formatMessage({
              id: "active_minute_current",
            })}
          </div>
          <div className="edit_layout_input_block">
            <input value={value !== null ? value : 10} readOnly />
            <div className="edit_layout_input_controls">
              <button onClick={() => handleValue(Number(value) + 1)}>
                <ArrowDropUpIcon />
              </button>
              <button onClick={() => handleValue(Number(value) - 1)}>
                <ArrowDropDownIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getKeys = (object) => {
    return Object.keys(object);
  };

  const getLayout = () => {
    if (edit || getKeys(itemData).length === 0) {
      return editLayout();
    } else if (getKeys(itemData).length !== 0) {
      return shortLayout();
    }
  };

  const getFooter = () => {
    if (edit || open) {
      return (
        <div className="injuries_footer">
          <button
            onClick={() => {
              setOpen(false);
              setEdit(false);
              getItemData();
            }}
          >
            {intl.formatMessage({
              id: "receipt_display_modal_cancel",
            })}
          </button>
          <button onClick={() => sendValueToApi()}>
            {intl.formatMessage({
              id: "active_minute_save",
            })}
          </button>
        </div>
      );
    }
  };

  const handleOpen = () => {
    if (getKeys(itemData).length === 0) {
      setOpen(!open);
    }
  };

  const renderPrevious = () => {
    return (
      <div
        className="injuries_prev_container"
        style={
          open
            ? { maxHeight: "500px", paddingTop: "5px" }
            : { maxHeight: "0px" }
        }
      >
        <div className="injuries_prev_note">
          <span>
            {intl.formatMessage({
              id: "active_minute_note",
            })}
          </span>
          <div>{prevData.personal_note}</div>
        </div>
        <div className="injuries_prev_value">
          <span>
            {intl.formatMessage({
              id: "active_minute_inj_prev",
            })}{" "}
            (
            {moment(prevData.date_on, moment.ISO_8601, true).isValid()
              ? moment(prevData.date_on).format("DD.MM.YYYY")
              : "-"}
            )
          </span>
          <div>{prevData.value}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="injuries_block">
      <div
        onClick={() => handleOpen()}
        className={
          "injuries_header " +
          (getKeys(itemData).length !== 0 ? "green" : "grey")
        }
      >
        <div>{data.issue}</div>
        <div>{data.Extracted_value}</div>
      </div>
      {edit || getKeys(itemData).length === 0 ? renderPrevious() : null}
      {getLayout()}
      {getFooter()}
    </div>
  );
};

export default injectIntl(GraphItem);
