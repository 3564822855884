import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CovidQuestions } from "../Covid19/CovidQuestions";
import { useIntl } from "react-intl";
import ClearIcon from "@material-ui/icons/Clear";

export default function FilteredSearch({ initialQuery, setInputValue }) {
  const initialQ = initialQuery;
  const selectDisabled = initialQuery !== "" ? false : true;
  const [limitSelection, setLimitSelection] = useState({ 0: {} });
  const [excludeSelection, setExcludeSelection] = useState({ 0: {} });
  const [assignedIndex, setAssignedIndex] = useState(1);
  const intl = useIntl();
  const [chosenType, setChosenType] = useState("include");

  const questions = CovidQuestions();

  const limitOpt = [
    {
      label: intl.formatMessage({
        id: "hub_prompter_fs_limit_opt_1",
      }),
      value: "include",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_fs_limit_opt_2",
      }),
      value: "exclude",
    },
  ];

  const typeOpt = [
    {
      label: intl.formatMessage({
        id: "hub_prompter_fs_type_opt_1",
      }),
      value: "country",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_fs_type_opt_2",
      }),
      value: "ageGroup",
    },
    {
      label: intl.formatMessage({
        id: "hub_prompter_fs_type_opt_3",
      }),
      value: "gender",
    },
  ];

  // const [comparisonSelected, setComparisonSelected] = useState("AND");

  useEffect(() => {
    let limitCountries = "";
    let limitAge = "";
    let limitGender = "";
    let excludeCountries = "";
    let excludeAge = "";
    let excludeGender = "";

    let temp_q = "";
    switch (chosenType) {
      case "include":
        Object.keys(limitSelection).forEach((item) => {
          switch (Object.keys(limitSelection[item])[0]) {
            case "country":
              limitCountries = `${limitCountries}'${limitSelection[item].country}',`;
              break;
            case "ageGroup":
              limitAge = `${limitAge}'${limitSelection[item].ageGroup}',`;
              break;
            case "gender":
              limitGender = `${limitGender}'${limitSelection[item].gender}',`;
              break;
            default:
              break;
          }
        });

        if (limitCountries !== "") {
          temp_q =
            temp_q + handleQueryBuild("Q215", limitCountries.slice(0, -1));
        }
        if (limitAge !== "") {
          temp_q = temp_q + handleAgeQueryBuild("Q211", limitAge.slice(0, -1));
        }
        if (limitGender !== "") {
          temp_q = temp_q + handleQueryBuild("Q209", limitGender.slice(0, -1));
        }

        break;
      case "exclude":
        Object.keys(excludeSelection).forEach((item) => {
          switch (Object.keys(excludeSelection[item])[0]) {
            case "country":
              excludeCountries = `${excludeCountries}'${excludeSelection[item].country}',`;
              break;
            case "ageGroup":
              excludeAge = `${excludeAge}'${excludeSelection[item].ageGroup}',`;
              break;
            case "gender":
              excludeGender = `${excludeGender}'${excludeSelection[item].gender}',`;
              break;
            default:
              break;
          }
        });

        if (excludeCountries !== "") {
          temp_q =
            temp_q + handleQueryBuild("Q215", excludeCountries.slice(0, -1));
        }
        if (excludeAge !== "") {
          temp_q =
            temp_q + handleAgeQueryBuild("Q211", excludeAge.slice(0, -1));
        }
        if (excludeGender !== "") {
          temp_q =
            temp_q + handleQueryBuild("Q209", excludeGender.slice(0, -1));
        }

        break;
      default:
        return null;
    }

    let finalQuery = temp_q !== "" ? `${initialQ} ${temp_q}` : `${initialQ}`;

    setInputValue(finalQuery);
    // console.log(limitSelection, excludeSelection);
    // eslint-disable-next-line
  }, [limitSelection, excludeSelection]);

  const handleQueryBuild = (question, params) => {
    return `and exists ( SELECT id from my_people_details WHERE category='covid-19' AND hub_transaction_id='${question}' and value_text IN (${params})) `;
  };

  const handleAgeQueryBuild = (question, params) => {
    let temp_query = `and exists 
    (
    SELECT 
      CASE WHEN not exists 
      (
      SELECT id
      from my_people_details
      WHERE hub_transaction_id='Q210' and value_text BETWEEN 1900 and 2020
      ) 
      THEN 
      (
        select value_text
        from my_people_details
        WHERE hub_transaction_id='${question}' and value_text IN (${params})
      )  
      ELSE 
      (
        SELECT 
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 40 and 49 THEN '40-49' ELSE 
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 30 and 39 THEN '30-39' ELSE 
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 20 and 29 THEN '30-39' ELSE
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 10 and 19 THEN '10-19' ELSE
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 0 and 9 THEN '0-9' ELSE
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 50 and 59 THEN '50-59' ELSE
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 60 and 69 THEN '60-69' ELSE
        CASE WHEN year(curdate()) - CAST(value_text as INT) between 70 and 79 THEN '70-79' ELSE '80+'
        END 		
        END 		
        END 	
        END 
        END 
        END 
        END
        END as Calc_AgeGroup
        from my_people_details
        WHERE hub_transaction_id='Q210' 
        HAVING Calc_AgeGroup IN (${params})
      ) END as Calc_AgeGroup 
    HAVING Calc_AgeGroup IS NOT NULL
    )`;

    return temp_query;
    // return `and exists ( SELECT  CASE WHEN not exists  ( SELECT id from my_people_details WHERE hub_transaction_id='Q210' and value_text BETWEEN 1900 and 2020 )  THEN  ( select value_text from my_people_details WHERE hub_transaction_id='Q211' and value_text IN ('30-39','40-49') )   ELSE  ( SELECT  CASE WHEN year(curdate()) - CAST(value_text as INT) between 40 and 49 THEN '40-49' ELSE  CASE WHEN year(curdate()) - CAST(value_text as INT) between 30 and 39 THEN '30-39' ELSE  CASE WHEN year(curdate()) - CAST(value_text as INT) between 20 and 29 THEN '30-39' ELSE CASE WHEN year(curdate()) - CAST(value_text as INT) between 10 and 19 THEN '10-19' ELSE CASE WHEN year(curdate()) - CAST(value_text as INT) between 0 and 9 THEN '0-9' ELSE CASE WHEN year(curdate()) - CAST(value_text as INT) between 50 and 59 THEN '50-59' ELSE CASE WHEN year(curdate()) - CAST(value_text as INT) between 60 and 69 THEN '60-69' ELSE CASE WHEN year(curdate()) - CAST(value_text as INT) between 70 and 79 THEN '70-79' ELSE '80+' END 		 END 		 END 	 END  END  END  END END as Calc_AgeGroup from my_people_details WHERE hub_transaction_id='Q210'  HAVING Calc_AgeGroup IN ('30-39','40-49') ) END as Calc_AgeGroup  HAVING Calc_AgeGroup IS NOT NULL )`;
  };

  const chooseSelect = (param, index, func, obj) => {
    return (
      <>
        {/* <select
          disabled={selectDisabled}
          value={param}
          onChange={(event) =>
            func({
              ...obj,
              [index]: { [event.target.value]: null },
            })
          }
        >
          <option value="country">Country</option>
          <option value="ageGroup">Age group</option>
          <option value="gender">Gender</option>
        </select> */}

        <Select
          value={handleSelectValue(param, typeOpt)}
          isDisabled={selectDisabled}
          onChange={(event) =>
            func({
              ...obj,
              [index]: { [event.value]: null },
            })
          }
          options={typeOpt}
        />
      </>
    );
  };

  const handleSelectValue = (param, arr) => {
    let foundIndex = arr.findIndex((element) => element.value === param);
    if (foundIndex !== -1) {
      return arr[foundIndex];
    }
  };

  const handleRenderOptions = (param, index, func, obj) => {
    switch (param) {
      case "country":
        return countryOption(index, func, obj);
      case "ageGroup":
        return ageGroupOption(index, func, obj);
      case "gender":
        return genderOption(index, func, obj);
      default:
        return null;
    }
  };

  const countryOption = (index, func, obj) => {
    return (
      <>
        <Select
          isDisabled={selectDisabled}
          onChange={(event) =>
            func({
              ...obj,
              [index]: { country: event.value },
            })
          }
          options={questions["Q215"].options}
        />
        {/* <select
          onChange={event =>
            func({
              ...obj,
              [index]: { country: event.target.value }
            })
          }
        >
          <option value=""></option>
          <option value="DE">DE</option>
          <option value="CZ">CZ</option>
          <option value="SK">SK</option>
        </select> */}
      </>
    );
  };

  const ageGroupOption = (index, func, obj) => {
    return (
      <>
        <Select
          isDisabled={selectDisabled}
          onChange={(event) =>
            func({
              ...obj,
              [index]: { ageGroup: event.value },
            })
          }
          options={questions["Q211"].options}
        />
        {/* <select
          onChange={event =>
            func({
              ...obj,
              [index]: { ageGroup: event.target.value }
            })
          }
        >
          <option value=""></option>
          <option value="0-9">0-9</option>
          <option value="10-19">10-19</option>
          <option value="20-29">20-29</option>
          <option value="30-39">30-39</option>
          <option value="40-49">40-49</option>
          <option value="50-59">50-59</option>
          <option value="60-69">60-69</option>
          <option value="70-79">70-79</option>
          <option value="80-89">80-89</option>
          <option value="90-99">90-99</option>
          <option value="100+">100+</option>
        </select> */}
      </>
    );
  };

  const genderOption = (index, func, obj) => {
    return (
      <>
        <Select
          isDisabled={selectDisabled}
          onChange={(event) =>
            func({
              ...obj,
              [index]: { gender: event.value },
            })
          }
          options={questions["Q209"].options}
        />
        {/* <select
          onChange={event =>
            func({
              ...obj,
              [index]: { gender: event.target.value }
            })
          }
        >
          <option value=""></option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </select> */}
      </>
    );
  };

  const handleRemoveSelect = (item, obj, func) => {
    let temp_obj = obj;
    // console.log(temp_obj[item]);
    delete temp_obj[item];
    // console.log(temp_obj);
    // console.log(item);
    func({ ...temp_obj });
  };

  const handleChosenType = () => {
    switch (chosenType) {
      case "include":
        return (
          <>
            {Object.keys(limitSelection).map((item, i) => {
              // console.log(Object.keys(limitSelection[item])[0], item);
              if (!item) {
                return null;
              } else {
                return (
                  <div className="hub_prompter_fil_select_wraper" key={i}>
                    <div
                      className="hub_prompter_fil_select_clear"
                      style={selectDisabled ? { color: "hsl(0,0%,60%)" } : null}
                      onClick={
                        !selectDisabled
                          ? () => {
                              handleRemoveSelect(
                                item,
                                limitSelection,
                                setLimitSelection
                              );
                            }
                          : null
                      }
                    >
                      <ClearIcon />
                    </div>
                    <div className="hub_prompter_fil_select">
                      {chooseSelect(
                        Object.keys(limitSelection[item])[0],
                        item,
                        setLimitSelection,
                        limitSelection
                      )}
                    </div>
                    <div className="hub_prompter_fil_select">
                      {handleRenderOptions(
                        Object.keys(limitSelection[item])[0],
                        item,
                        setLimitSelection,
                        limitSelection
                      )}
                    </div>
                  </div>
                );
              }
            })}
            {!selectDisabled ? (
              <div className="hub_prompter_add_filter_wrapper">
                <div
                  className="hub_prompter_add_filter"
                  onClick={() => {
                    setLimitSelection({
                      ...limitSelection,
                      [assignedIndex]: {},
                    });
                    setAssignedIndex(assignedIndex + 1);
                  }}
                >
                  {intl.formatMessage({
                    id: "hub_prompter_add_filter",
                  })}
                </div>
              </div>
            ) : null}
          </>
        );
      case "exclude":
        return (
          <>
            {Object.keys(excludeSelection).map((item, i) => {
              // console.log(Object.keys(excludeSelection[item])[0], item);
              if (!item) {
                return null;
              } else {
                return (
                  <div style={{ display: "flex" }} key={i}>
                    <div
                      style={{ padding: "3px" }}
                      onClick={() => {
                        handleRemoveSelect(
                          item,
                          excludeSelection,
                          setExcludeSelection
                        );
                      }}
                    >
                      X
                    </div>
                    <div>
                      {chooseSelect(
                        Object.keys(excludeSelection[item])[0],
                        item,
                        setExcludeSelection,
                        excludeSelection
                      )}
                    </div>
                    <div>
                      {handleRenderOptions(
                        Object.keys(excludeSelection[item])[0],
                        item,
                        setExcludeSelection,
                        excludeSelection
                      )}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className="hub_prompter_add_filter"
              onClick={() => {
                setExcludeSelection({
                  ...excludeSelection,
                  [assignedIndex]: {},
                });
                setAssignedIndex(assignedIndex + 1);
              }}
            >
              {intl.formatMessage({
                id: "hub_prompter_add_filter",
              })}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="hubprompt_filters_container">
      <div className="hubprompt_filtered_search_step_title">
        {intl.formatMessage({
          id: "hub_prompter_step2",
        })}
      </div>
      {/* <select
        disabled={selectDisabled}
        className="hubprompt_filtered_select"
        value={chosenType}
        onChange={(event) => {
          setChosenType(event.target.value);
          setLimitSelection({});
          setExcludeSelection({});
        }}
      >
        <option value="include">Limit to</option>
        <option value="exclude">Exclude</option>
      </select> */}

      <Select
        value={handleSelectValue(chosenType, limitOpt)}
        isDisabled={selectDisabled}
        onChange={(event) => {
          setChosenType(event.value);
          setLimitSelection({});
          setExcludeSelection({});
        }}
        options={limitOpt}
      />
      {handleChosenType()}
      <div className="hubprompt_filtered_search_step_title">
        {intl.formatMessage({
          id: "hub_prompter_step3",
        })}
      </div>
    </div>
  );
}
