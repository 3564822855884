import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  progress_bar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    maxWidth: "500px",
    padding: "0 10px",
    marginBottom: "20px"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    width: "300px"
  },
  modalTitleErr: {
    alignSelf: "center",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
    backgroundColor: "#ff5858",
    color: "white",
    textAlign: "center",
    paddingTop: "18px",
    height: "60px",
    width: "100%"
  },
  status: {
    alignSelf: "center",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#3FA5FF",
    padding: "20px 0"
  },
  error_text: {
    color: "#545669",
    fontFSize: "14px",
    fontWeight: "600",
    padding: "20px",
    textAlign: "center"
  },
  error_btn_wrap: {
    display: "flex",
    justifyContent: "center"
  },
  error_btn: {
    cursor: "pointer",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: "#ff5858",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#ffffff",
    fontFSize: "14px",
    fontWeight: "600",
    padding: "3px 10px",
    marginBottom: "5px"
  }
}));

const CreateHubAlert = ({ open, setOpen, timeLeft }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Dialog aria-labelledby="modal" aria-describedby="modal" open={open}>
      <div className={classes.modal}>
        <span className={classes.modalTitleErr}>ALERT</span>

        <div className={classes.error_text}>
          {intl.formatMessage({
            id: "create_hub_time_lock_message"
          })}
          {` ${timeLeft} min`}
        </div>
        <div className={classes.error_btn_wrap}>
          <div
            className={classes.error_btn}
            onClick={() => {
              setOpen(false);
            }}
          >
            {intl.formatMessage({
              id: "bookcoll_cancel_button"
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateHubAlert;
