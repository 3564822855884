import { URLconst } from "./const";
import axios from "axios";

//GET

export function getHubTables(param) {
  let BaseURL = URLconst("hub_manager/manager.php");

  return axios.get(BaseURL, {
    params: param
  });
}

export function getTableColumns(param) {
  let BaseURL = URLconst("hub_manager/manager.php");

  return axios.get(BaseURL, {
    params: param
  });
}

//POST

export function postNewTableRow(param) {
  let BaseURL = URLconst("hub_manager/manager.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

//PUT

export function putNewTableRow(param) {
  let BaseURL = URLconst("hub_manager/manager.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data }
  });
}

//DELETE

export function deleteTableRow(param) {
  let BaseURL = URLconst("hub_manager/manager.php");

  return axios.delete(BaseURL, {
    params: param
  });
}
