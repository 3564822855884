import React, { useState, useEffect } from "react";
import "./DayValue.css";

const DayValue = ({ title, value, id, colors }) => {
  const [textColor, setTextColor] = useState("")

  useEffect(() => {
    getColor();// eslint-disable-next-line
  }, [colors])

  const getColor = () => {
    colors.forEach(element => {
      if (element.category === title) {
        setTextColor(element.color);
      }
    });
  }

  return (
    <div className="value_container" key={id}>
      <div className="value_title" style={{ color: textColor }}>{title}</div>
      <div className="value_text">{value}</div>
    </div>
  );
};

export default DayValue;
