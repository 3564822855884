import { URLconst } from "./const";
import axios from "axios";

export function getActiveMinute(param) {
  let BaseURL = URLconst("personal_hub/active_minute.php");

  return axios.get(BaseURL, {
    params: param
  });
}

export function getCovid19Poll(param) {
  let BaseURL = URLconst("personal_hub/covid_19.php");

  return axios.get(BaseURL, {
    params: param
  });
}
