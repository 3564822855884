import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import "./AddItemModal.css";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  modal: {
    position: "relative",
    maxWidth: "350px",
    maxHeight: "80%",
    margin: "30% auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "column"
  },
  modalTitle: {
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0.69px",
    lineHeight: "25px",
    backgroundColor: "#3FA5FF",
    color: "white",
    textAlign: "center",
    paddingTop: "18px",
    height: "60px"
  },
  inputContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  inputBlock: {
    width: "100%",
    padding: "5px"
  },
  dateBlock: {
    width: "50%",
    padding: "5px"
  },
  inputTitle: {
    minWidth: "130px"
  },
  inputField: {
    width: "100%"
  },
  select: {
    width: "100%",
    borderRadius: "7px !important",
    padding: "8.5px 5px",
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  bntBlock: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  },
  button: {
    marginRight: "5px",
    width: "100px",
    height: "33px",
    border: "1px solid #AAC8FF",
    borderRadius: "4px",
    backgroundColor: " #3FA5FF",
    boxShadow: "0 2px 4px 0 rgba(25,50,89,0.2)",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "16px",
    margin: "10px"
  },
  closeButton: {
    position: "absolute",
    top: "2px",
    right: "0",
    border: "none",
    backgroundColor: "transparent",
    color: "#fff"
  }
});

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const AddItemModal = ({
  open,
  setOpen,
  fields,
  apiRequest,
  target_hub_id,
  getApiData,
  apiParam,
  apiParamValue,
  additionalApiParam,
  additionalApiParamValue,
  timeNeeded
}) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  // const defaultPlaceholder = "";
  const postApiData = () => {
    let send_credential_to_registrar = {
      target_hub_id: target_hub_id
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = data;
    if (apiParam !== undefined && apiParamValue !== undefined) {
      send_credential_to_registrar.data[apiParam] = apiParamValue;
    }
    if (
      additionalApiParam !== undefined &&
      additionalApiParamValue !== undefined
    ) {
      send_credential_to_registrar.data[
        additionalApiParam
      ] = additionalApiParamValue;
    }

    // if (new Date(send_credential_to_registrar.data.start_date).getDate()) {
    //   console.log("true");
    // } else {
    //   console.log("false");
    // }
    // console.log(send_credential_to_registrar);

    apiRequest(send_credential_to_registrar)
      .then(result => {
        if (result.data.error) {
          // console.log(result.data.error);
          alert(result.data.error);
        } else {
          getApiData();
          setOpen(false);
          // console.log(result.data.success);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const checkType = (item, id) => {
    switch (item.type) {
      case "select":
        var options = item.parameter_value.split(",");
        if (data[item.field] === undefined) {
          setData({ ...data, [item.field]: options[0] });
        }
        return (
          <div key={id} className={classes.inputBlock}>
            <select
              className={classes.select}
              value={data[item.field]}
              onChange={event => {
                setData({ ...data, [item.field]: event.target.value });
              }}
            >
              {options.map((option, index) => {
                // console.log(index)
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
        );
      default:
        if (
          data[item.field] === undefined &&
          item.type === "date" &&
          timeNeeded === true
        ) {
          setData({
            ...data,
            [item.field]: moment().format("YYYY-MM-DDTHH:mm")
          });
        }
        if (data[item.field] === undefined && item.type === "date-time") {
          setData({
            ...data,
            [item.field]: moment().format("YYYY-MM-DDTHH:mm")
          });
        }
        return (
          <div
            key={id}
            className={
              (item.type === "date" && timeNeeded !== true
                ? classes.dateBlock
                : classes.inputBlock) + " input_fields"
            }
          >
            {item.type === "date-time" ? (
              <TextField
                type="datetime-local"
                label={item.title}
                value={data[item.field]}
                fullWidth
                onChange={event =>
                  setData({
                    ...data,
                    [item.field]:
                      event.target.value !== "" ? event.target.value : null
                  })
                }
                variant="outlined"
                // value={moment().format("YYYY-MM-DDTHH:mm")}
                size="small"
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <TextField
                type={item.type}
                label={item.title}
                value={data[item.field]}
                fullWidth
                onChange={event =>
                  setData({
                    ...data,
                    [item.field]:
                      event.target.value !== "" ? event.target.value : null
                  })
                }
                variant="outlined"
                size="small"
                InputLabelProps={
                  item.type === "date"
                    ? {
                        shrink: true
                      }
                    : {}
                }
              />
            )}
          </div>
        );
    }
  };
  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      PaperComponent={PaperComponent}
    >
      {/* <div className={classes.root}> */}
      {/* <div className={classes.modal}> */}
      <span className={classes.modalTitle}>Add data</span>
      <DialogContent>
        <div className={classes.inputContainer}>
          {fields.map((item, id) => {
            if (item.showInModal === "1") {
              if (item.type === "date" && data[item.field] === undefined) {
                var today = new Date();
                let val =
                  today.getFullYear() +
                  "-" +
                  ("0" + (today.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + today.getDate()).slice(-2);
                setData({ ...data, [item.field]: val });
              }
              return checkType(item, id);
            } else {
              return null;
            }
          })}
        </div>
        <div className={classes.bntBlock}>
          <button className={classes.button} onClick={() => postApiData()}>
            Save
          </button>
          <button
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </DialogContent>
      {/* </div> */}
      {/* </div> */}
    </Dialog>
  );
};

export default AddItemModal;
