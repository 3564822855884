import React, { useState } from "react";
import "./BackupPage.css";
import { useIntl } from "react-intl";
import { FormattedHTMLMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  getBackupDataOnly,
  getBackupWholeDB,
} from "../../services/GetHUBsiteData";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InfoIcon from "@material-ui/icons/Info";
// import RemoveIcon from "@material-ui/icons/Cancel";
import RemoveIcon from "@material-ui/icons/Remove";
import GetAppIcon from "@material-ui/icons/GetApp";

export default function BackupPage() {
  const intl = useIntl();
  const history = useHistory();

  const [showDataOnlyBackupInfo, setShowDataOnlyBackupInfo] = useState(false);
  const [showCompleteBackupInfo, setShowCompleteBackupInfo] = useState(false);
  const [showFilesBackupInfo, setShowFilesBackupInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [withPassword, setWithPassword] = useState({
    data_only: false,
    whole_db: false,
    files: false,
  });
  const [withEncryption, setWithEncryption] = useState({
    data_only: false,
    whole_db: false,
    files: false,
  });

  const handleBackupDownload = (request, param) => {
    setLoading(param);

    var query_params = {};
    if (!sessionStorage.getItem("hub_token")) {
      query_params.logged_in = null;
    } else {
      query_params.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    query_params.section = "";
    query_params.target_hub_id = query_params.logged_in.my_hub_id;
    query_params.requestor_hub_id = query_params.target_hub_id;

    if (withPassword[param]) {
      query_params.zip = "";
      // console.log("zip enabled");
    }
    if (withEncryption[param]) {
      query_params.encrypt = "";
      // console.log("encrypt enabled");
    }

    request(query_params)
      .then((response) => {
        let fnh = response.headers["content-disposition"];
        let filename = fnh.substring(
          fnh.indexOf('"') + 1,
          fnh.lastIndexOf('"')
        );
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
        // handle error
        setLoading(false);
        setTimeout(() => {
          alert(
            "It was not possible to create the backup file at this moment. Please try again later."
          );
        }, 100);
      });
  };

  return (
    <div className="backup_page_root">
      <img
        className="backup_page_bg"
        src="/assets/images/background_img_hubsite.png"
        alt=""
      />
      <div className="backup_page_back_button" onClick={() => history.goBack()}>
        <ArrowBackIcon />{" "}
        {intl.formatMessage({
          id: "active_minute_back",
        })}
      </div>
      <div className="backup_page_container">
        <div className="backup_page_title_text">
          {intl.formatMessage({
            id: "backup_page_title",
          })}
        </div>

        <div className="backup_page_history_container">
          <div className="backup_page_history_text">
            {intl.formatMessage({
              id: "backup_page_history_when",
            })}
            :<span> 72 day ago. </span>
            {intl.formatMessage({
              id: "backup_page_history_type",
            })}
            :<span> Data-only. </span>
          </div>
          <div className="backup_page_history_text">
            {intl.formatMessage({
              id: "backup_page_history_note",
            })}
            : On black USB 8GB, pwd protected.
          </div>
        </div>

        <div className="backup_page_block">
          <div className="backup_page_block_top">
            <div className="backup_page_block_top_left">
              <div
                className="backup_page_download_btn_wrap"
                style={{ backgroundColor: "#3fa4ff" }}
                onClick={
                  loading !== "data_only"
                    ? () => {
                        handleBackupDownload(getBackupDataOnly, "data_only");
                      }
                    : null
                }
              >
                {loading === "data_only" ? (
                  <img
                    style={{ width: "40px", height: "40px" }}
                    alt="loading"
                    src="/assets/images/loadingv3.gif"
                  />
                ) : (
                  <GetAppIcon fontSize="inherit" />
                )}
              </div>
              <div className="backup_page_subtitle">
                {intl.formatMessage({
                  id: "backup_page_history_data_only",
                })}{" "}
                {intl.formatMessage({
                  id: "backup_page_history_backup",
                })}
              </div>
              <div
                className="backup_page_info_btn_wrap"
                onClick={() => {
                  setShowDataOnlyBackupInfo(!showDataOnlyBackupInfo);
                }}
              >
                <InfoIcon
                  className={
                    "backup_page_info_btn_show " +
                    (showDataOnlyBackupInfo ? "bpibhide" : "")
                  }
                  fontSize="inherit"
                />
                <RemoveIcon
                  className={
                    "backup_page_info_btn_close " +
                    (showDataOnlyBackupInfo ? "" : "bpibhide")
                  }
                  fontSize="inherit"
                />
              </div>
            </div>
            <div className="backup_page_block_top_right">
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_dwnld_as_zip",
                  })}
                </div>
                <input
                  checked={withPassword.data_only}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithPassword({
                      ...withPassword,
                      data_only: e.target.checked,
                    });
                  }}
                ></input>
              </div>
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_pass_protected",
                  })}
                </div>
                <input
                  checked={withEncryption.data_only}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithEncryption({
                      ...withEncryption,
                      data_only: e.target.checked,
                    });
                    return e.target.checked
                      ? setShowDataOnlyBackupInfo(!showDataOnlyBackupInfo)
                      : null;
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div
            className={
              "backup_page_block_bottom " +
              (showDataOnlyBackupInfo ? "bpibexpand" : "")
            }
          >
            <FormattedHTMLMessage id="backup_page_history_data_only_info" />
            {/* {intl.formatMessage({
              id: "backup_page_history_data_only_info",
            })} */}
          </div>
        </div>

        <div className="backup_page_block">
          <div className="backup_page_block_top">
            <div className="backup_page_block_top_left">
              <div
                className="backup_page_download_btn_wrap"
                style={{ backgroundColor: "#eca5a0" }}
                onClick={
                  loading !== "whole_db"
                    ? () => {
                        handleBackupDownload(getBackupWholeDB, "whole_db");
                      }
                    : null
                }
              >
                {loading === "whole_db" ? (
                  <img
                    style={{ width: "40px", height: "40px" }}
                    alt="loading"
                    src="/assets/images/loadingv3.gif"
                  />
                ) : (
                  <GetAppIcon fontSize="inherit" />
                )}
              </div>
              <div className="backup_page_subtitle">
                {intl.formatMessage({
                  id: "backup_page_history_complete",
                })}{" "}
                {intl.formatMessage({
                  id: "backup_page_history_backup",
                })}
              </div>
              <div
                className="backup_page_info_btn_wrap"
                onClick={() => {
                  setShowCompleteBackupInfo(!showCompleteBackupInfo);
                }}
              >
                <InfoIcon
                  className={
                    "backup_page_info_btn_show " +
                    (showCompleteBackupInfo ? "bpibhide" : "")
                  }
                  fontSize="inherit"
                />
                <RemoveIcon
                  className={
                    "backup_page_info_btn_close " +
                    (showCompleteBackupInfo ? "" : "bpibhide")
                  }
                  fontSize="inherit"
                />
              </div>
            </div>
            <div className="backup_page_block_top_right">
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_dwnld_as_zip",
                  })}
                </div>
                <input
                  checked={withPassword.whole_db}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithPassword({
                      ...withPassword,
                      whole_db: e.target.checked,
                    });
                  }}
                ></input>
              </div>
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_pass_protected",
                  })}
                </div>
                <input
                  checked={withEncryption.whole_db}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithEncryption({
                      ...withEncryption,
                      whole_db: e.target.checked,
                    });
                    return e.target.checked
                      ? setShowCompleteBackupInfo(!showCompleteBackupInfo)
                      : null;
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div
            className={
              "backup_page_block_bottom " +
              (showCompleteBackupInfo ? "bpibexpand" : "")
            }
          >
            <FormattedHTMLMessage id="backup_page_history_complete_info" />
            {/* {intl.formatMessage({
              id: "backup_page_history_complete_info",
            })} */}
          </div>
        </div>

        <div className="backup_page_block">
          <div className="backup_page_block_top">
            <div className="backup_page_block_top_left">
              <div
                className="backup_page_download_btn_wrap"
                style={{ backgroundColor: "#b1d8b3" }}
                onClick={() => {
                  alert("There are no files");
                }}
              >
                <GetAppIcon fontSize="inherit" />
              </div>
              <div className="backup_page_subtitle">
                {intl.formatMessage({
                  id: "backup_page_history_files",
                })}{" "}
                {intl.formatMessage({
                  id: "backup_page_history_backup",
                })}
              </div>
              <div
                className="backup_page_info_btn_wrap"
                onClick={() => {
                  setShowFilesBackupInfo(!showFilesBackupInfo);
                }}
              >
                <InfoIcon
                  className={
                    "backup_page_info_btn_show " +
                    (showFilesBackupInfo ? "bpibhide" : "")
                  }
                  fontSize="inherit"
                />
                <RemoveIcon
                  className={
                    "backup_page_info_btn_close " +
                    (showFilesBackupInfo ? "" : "bpibhide")
                  }
                  fontSize="inherit"
                />
              </div>
            </div>
            <div className="backup_page_block_top_right">
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_dwnld_as_zip",
                  })}
                </div>
                <input
                  checked={withPassword.files}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithPassword({
                      ...withPassword,
                      files: e.target.checked,
                    });
                  }}
                ></input>
              </div>
              <div className="backup_page_info_checkbox_wrapper">
                <div className="backup_page_info_checkbox_title">
                  {intl.formatMessage({
                    id: "backup_page_history_pass_protected",
                  })}
                </div>
                <input
                  checked={withEncryption.files}
                  className="backup_page_info_checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setWithEncryption({
                      ...withEncryption,
                      files: e.target.checked,
                    });
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div
            className={
              "backup_page_block_bottom " +
              (showFilesBackupInfo ? "bpibexpand" : "")
            }
          >
            {intl.formatMessage({
              id: "backup_page_history_files_info",
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
