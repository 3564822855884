import React, { useState, useEffect } from "react";
import moment from "moment";
import TodayIcon from "@material-ui/icons/Today";
import { postSleepData } from "../../../../../services/PostActiveMinuteData";
import { putActiveMinuteSleepData } from "../../../../../services/PutActiveMinuteData";
import TextField from "@material-ui/core/TextField";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Slider from "rc-slider";
import { injectIntl } from "react-intl";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

function Advanced({
  handleSubmitIconChange,
  item,
  sleepData,
  getApiData,
  intl,
}) {
  const [clickedItem, setClickedItem] = useState(6);

  var today = new Date();
  var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  let valbed =
    yesterday.getFullYear() +
    "-" +
    ("0" + (yesterday.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + yesterday.getDate()).slice(-2) +
    "T22:00";
  let valup =
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2) +
    "T07:00";

  const [bedTime, setBedTime] = useState(valbed);
  const [wakeUpTime, setWakeUpTime] = useState(valup);
  const [openRange, setOpenRange] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState({
    index: 6,
    date_on: moment().format("YYYY-MM-DD"),
    real_id: null,
    personal_note: null,
  });

  const sliderStep = [
    "10:00",
    "09:55",
    "09:50",
    "09:45",
    "09:40",
    "09:35",
    "09:30",
    "09:25",
    "09:20",
    "09:15",
    "09:10",
    "09:05",
    "09:00",
    "08:55",
    "08:50",
    "08:45",
    "08:40",
    "08:35",
    "08:30",
    "08:25",
    "08:20",
    "08:15",
    "08:10",
    "08:05",
    "08:00",
    "07:55",
    "07:50",
    "07:45",
    "07:40",
    "07:35",
    "07:30",
    "07:25",
    "07:20",
    "07:15",
    "07:10",
    "07:05",
    "07:00",
    "06:55",
    "06:50",
    "06:45",
    "06:40",
    "06:35",
    "06:30",
    "06:25",
    "06:20",
    "06:15",
    "06:10",
    "06:05",
    "06:00",
    "05:55",
    "05:50",
    "05:45",
    "05:40",
    "05:35",
    "05:30",
    "05:25",
    "05:20",
    "05:15",
    "05:10",
    "05:05",
    "05:00",
    "04:55",
    "04:50",
    "04:45",
    "04:40",
    "04:35",
    "04:30",
    "04:25",
    "04:20",
    "04:15",
    "04:10",
    "04:05",
    "04:00",
    "03:55",
    "03:50",
    "03:45",
    "03:40",
    "03:35",
    "03:30",
    "03:25",
    "03:20",
    "03:15",
    "03:10",
    "03:05",
    "03:00",
    "02:55",
    "02:50",
    "02:45",
    "02:40",
    "02:35",
    "02:30",
    "02:25",
    "02:20",
    "02:15",
    "02:10",
    "02:05",
    "02:00",
    "01:55",
    "01:50",
    "01:45",
    "01:40",
    "01:35",
    "01:30",
    "01:25",
    "01:20",
    "01:15",
    "01:10",
    "01:05",
    "01:00",
    "00:55",
    "00:50",
    "00:45",
    "00:40",
    "00:35",
    "00:30",
    "00:25",
    "00:20",
    "00:15",
    "00:10",
    "00:05",
    "00:00",
    "23:55",
    "23:50",
    "23:45",
    "23:40",
    "23:35",
    "23:30",
    "23:25",
    "23:20",
    "23:15",
    "23:10",
    "23:05",
    "23:00",
    "22:55",
    "22:50",
    "22:45",
    "22:40",
    "22:35",
    "22:30",
    "22:25",
    "22:20",
    "22:15",
    "22:10",
    "22:05",
    "22:00",
    "21:55",
    "21:50",
    "21:45",
    "21:40",
    "21:35",
    "21:30",
    "21:25",
    "21:20",
    "21:15",
    "21:10",
    "21:05",
    "21:00",
    "20:55",
    "20:50",
    "20:45",
    "20:40",
    "20:35",
    "20:30",
    "20:25",
    "20:20",
    "20:15",
    "20:10",
    "20:05",
    "20:00",
  ];

  const trackStyle = [
    {
      background:
        "linear-gradient(0deg, #FBDE8D 0%, #3B4680 43.22%, #3B4680 53.82%, #FF8A62 100%)",
      width: 12,
      left: "1px",
    },
  ];
  const trackStyleHoriz = [
    {
      background:
        "linear-gradient(-90deg, #FBDE8D 0%, #3B4680 43.22%, #3B4680 53.82%, #FF8A62 100%)",
      width: 12,
      left: "1px",
    },
  ];

  const trackStyleWide = [
    {
      background:
        "linear-gradient(0deg, #FBDE8D 0%, #3B4680 43.22%, #3B4680 53.82%, #FF8A62 100%)",
      width: 40,
      left: "-13px",
    },
  ];
  const trackStyleGray = [
    {
      background: "#CFD7DE",
      width: 12,
      left: "1px",
    },
  ];

  const handleStyle = [
    {
      border: "transparent",
      borderRadius: "3px",
      height: "16px",
      width: "12px",
      backgroundColor: "transparent",
      bottom: "15%",
      marginLeft: "0px",
      marginBottom: "-7px",
    },
    {
      border: "transparent",
      borderRadius: "3px",
      height: "16px",
      width: "12px",
      backgroundColor: "transparent",
      bottom: "15%",
      marginLeft: "0px",
      marginBottom: "-7px",
    },
  ];
  const railStyle = { backgroundColor: "#ffffff00", width: 12 };
  const railStyleWide = { backgroundColor: "#ffffff00", width: 40 };

  useEffect(() => {
    setSelected({
      index: selected.index,
      date_on: selected.date_on,
      real_id:
        sleepData[selected.index] !== undefined
          ? sleepData[selected.index].real_id
          : null,
      personal_note:
        sleepData[selected.index] !== undefined
          ? sleepData[selected.index].personal_note
          : null,
    });
    // eslint-disable-next-line
  }, [sleepData]);

  let hubid = JSON.parse(sessionStorage.getItem("hub_token"))
    .userData.my_hub_id.slice(-5)
    .replace("_", "");

  const sendValueToApi = () => {
    let send_credential_to_registrar = {
      target_hub_id: hubid,
    };
    if (sessionStorage.getItem("mimic_hub")) {
      send_credential_to_registrar.mimic_hub = sessionStorage.getItem(
        "mimic_hub"
      );
    }
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }

    send_credential_to_registrar.data = {};
    send_credential_to_registrar.data.category = "sleep";
    send_credential_to_registrar.data.unit = "time";
    send_credential_to_registrar.data.time_start = bedTime.replace("T", " ");
    send_credential_to_registrar.data.time_end = wakeUpTime.replace("T", " ");
    send_credential_to_registrar.data.personal_note = selected.personal_note;

    send_credential_to_registrar.data.date_on = selected.date_on;
    if (selected.real_id !== null) {
      send_credential_to_registrar.data.id = selected.real_id;
      putActiveMinuteSleepData(send_credential_to_registrar).then(() =>
        getApiData()
      );
    } else {
      postSleepData(send_credential_to_registrar).then((result) => {
        getApiData();
        setSelected({ ...selected, real_id: Number(result.data.insert_id) });
      });
      if (selected.date_on === moment().format("YYYY-MM-DD")) {
        handleSubmitIconChange(item);
      }
    }
    setOpenRange(false);
  };

  const handleSliders = (param) => {
    setSuccess(false);
    var today = moment(selected.date_on).format("YYYY-MM-DD");
    var yesterday = moment(selected.date_on)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    var toUseBed = today;
    var toUseWake = today;

    if (
      Number(sliderStep[param[1]].slice(0, 2)) < 24 &&
      Number(sliderStep[param[1]].slice(0, 2)) > 10
    ) {
      toUseBed = yesterday;
    }
    let resultBed = toUseBed + "T" + sliderStep[param[1]];
    setBedTime(resultBed);

    // if (Number(sliderStep[param[0]].slice(0, 2)) < 24) {
    //   toUseWake = yesterday;
    // }
    let resultWake = toUseWake + "T" + sliderStep[param[0]];
    setWakeUpTime(resultWake);
    // if (this.timeout) clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //   console.log(resultWake);
    //   console.log(resultBed);
    // }, 1000);
  };

  const getHelper = (item, duration) => {
    const helper = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{duration}h</div>
        <div>
          {moment(item.time_start).format("H:mm")}-
          {moment(item.time_end).format("H:mm")}
        </div>
      </div>
    );
    return helper;
  };

  // const handletInputTime = (type, param) => {
  //   var today = moment(selected.date_on).format("YYYY-MM-DD");
  //   var yesterday = moment(selected.date_on)
  //     .subtract(1, "days")
  //     .format("YYYY-MM-DD");
  //   var toUseBed = today;
  //   var toUseWake = today;

  //   if (Number(param.slice(0, 2)) < 24 && Number(param.slice(0, 2)) > 10) {
  //     toUseBed = yesterday;
  //   }
  //   if (type === "bedTime") {
  //     let resultBed = toUseBed + "T" + param;
  //     setBedTime(resultBed);
  //   } else {
  //     let resultWake = toUseWake + "T" + param;
  //     setWakeUpTime(resultWake);
  //   }
  // };

  let grid_style = {
    width: "100%",
    height: "1px",
    backgroundColor: "#CFD7DE",
  };
  return (
    <>
      <div
        style={{ display: "flex", marginTop: "20px" }}
        className="sleep_list"
      >
        <img
          style={{
            height: "165px",
            margin: "13px 5px 0 -5px",
          }}
          alt=""
          src="/assets/images/day_states.png"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "180px",
            flexDirection: "column",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "italic",
            marginTop: "5px",
            marginRight: "5px",
            color: "#545669",
          }}
        >
          <div>20:00</div>
          <div>22:00</div>
          <div>00:00</div>
          <div>02:00</div>
          <div>04:00</div>
          <div>06:00</div>
          <div>08:00</div>
          <div>10:00</div>
        </div>
        <div
          style={{
            width: "80%",
            height: "200px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              height: "165px",
              marginTop: "15px",
            }}
          >
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
            <div style={grid_style}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              height: "185px",
              marginTop: "15px",
            }}
          >
            {sleepData.map((item, i) => {
              if (!item) {
                return null;
              } else {
                let arr = [30, 138];
                let track_style = trackStyleGray;
                let rail_style = railStyle;
                let duration = null;
                if (item.time_start !== null && item.time_end !== null) {
                  let start = sliderStep.indexOf(item.time_start.slice(11, 16));
                  if (start === -1) {
                    start = 168;
                  }

                  let end = sliderStep.indexOf(item.time_end.slice(11, 16));
                  if (end === -1) {
                    end = 0;
                  }

                  let difference = moment.duration(
                    moment(item.time_end).diff(moment(item.time_start))
                  );

                  //Get hours and subtract from duration
                  var hours = difference.hours().toString();
                  // if (hours.length < 2) {
                  //   hours = "0" + hours;
                  // }
                  //Get Minutes and subtract from duration
                  var minutes = difference.minutes().toString();
                  if (minutes.length < 2) {
                    minutes = "0" + minutes;
                  }
                  duration = hours + ":" + minutes;

                  arr = [end, start];
                  track_style = trackStyle;
                }
                if (clickedItem === i) {
                  track_style = trackStyleWide;
                  rail_style = railStyleWide;
                  if (item.time_start === null && item.time_end === null) {
                    track_style = [
                      { ...track_style[0], background: "#CFD7DE" },
                    ];
                  }
                }

                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      position: "relative",
                      alignItems: "center",
                      height: "100%",
                      opacity:
                        clickedItem !== null && clickedItem !== i ? "0.7" : "1",
                      width:
                        clickedItem !== i
                          ? "calc(100% / 8)"
                          : "calc(2*(100% / 8))",
                    }}
                    onClick={() => {
                      setClickedItem(i);
                      setSelected({
                        index: i,
                        date_on: item.curr_date,
                        real_id: item.real_id,
                        personal_note: item.personal_note,
                      });
                      setOpenRange(false);
                      setSuccess(false);
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-35px",
                        fontSize: "11px",
                        lineHeight: "95%",
                        transform: "rotate(-45deg)",
                      }}
                    >
                      {duration !== null ? getHelper(item, duration) : null}
                    </div>
                    <Range
                      // className={clickedItem !== i ? "range_selected" : null}
                      vertical={true}
                      disabled={selected.index === i ? false : true}
                      onChange={handleSliders}
                      trackStyle={track_style}
                      handleStyle={handleStyle}
                      railStyle={rail_style}
                      min={0}
                      max={168}
                      defaultValue={arr}
                      allowCross={false}
                      pushable={true}
                      tipFormatter={(value) => `${sliderStep[value]}`}
                    />
                    <div
                      style={{
                        height: "20px",
                        color: "#000000",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontStyle: "italic",
                        letterSpacing: "0.43px",
                        lineHeight: "14px",
                        textAlign: "center",
                      }}
                    >
                      {item.display_day}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", overflow: "auto", flexWrap: "wrap" }}>
        <div className="advanced_input_fields_container">
          <div className="date_icon_wrapper">
            <TodayIcon className="date_icon" fontSize="large" />
          </div>
          <TextField
            id="date"
            // label="Day"
            type="date"
            value={selected.date_on}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setSelected({
                real_id: null,
                date_on: e.target.value,
                personal_note: null,
              });
              setOpenRange(true);
              setClickedItem(null);
            }}
          />
        </div>
        {openRange ? (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              position: "relative",
            }}
          >
            <img className="night_icon" src="/assets/images/night.svg" alt="" />
            <div
              style={{
                paddingTop: "20px",
                width: "calc(100% - 120px)",
                height: "36px",
              }}
            >
              <Range
                // className={clickedItem !== i ? "range_selected" : null}
                onChange={handleSliders}
                trackStyle={trackStyleHoriz}
                // handleStyle={handleStyle}
                railStyle={railStyle}
                min={0}
                max={168}
                defaultValue={[0, 168]}
                allowCross={false}
                tipFormatter={(value) => `${sliderStep[value]}`}
                reverse={true}
              />
            </div>
            <img className="day_icon" src="/assets/images/Morning.svg" alt="" />
          </div>
        ) : null}
      </div>
      <div className="sleep_submit_container">
        <div className="steps_basic_input_note_container">
          <div style={{ marginBottom: "5px" }}>
            {intl.formatMessage({
              id: "active_minute_note",
            })}
          </div>
          <input
            style={success ? { backgroundColor: "transparent" } : null}
            disabled={success}
            type="text"
            value={
              selected.personal_note !== null ? selected.personal_note : ""
            }
            onChange={(event) =>
              setSelected({
                ...selected,
                personal_note: event.target.value,
              })
            }
          />
        </div>
        <button
          className={success ? "rotate" : ""}
          onClick={
            success
              ? null
              : () => {
                  sendValueToApi();
                  setSuccess(true);
                }
          }
        >
          {success ? (
            <DoneOutlineIcon />
          ) : (
            intl.formatMessage({
              id: "active_minute_save",
            })
          )}
        </button>
      </div>
    </>
  );
}
export default injectIntl(Advanced);
// useEffect(() => {
//   if (sleepData.length !== 0) {
//     parseApiData();
//   }
//   // eslint-disable-next-line
// }, [sleepData.length]);

// const parseApiData = () => {
//   let apiDataToMap = sleepData;
//   let chart = [];

//   apiDataToMap.forEach((item, id) => {
//     if (id < 5) {
//       chart.push(item);
//     }
//   });
//   return setChartData(chart);
// };

// useEffect(() => {
//   let result = sleepData.sort(function(a, b) {
//     if (Number(a.id) < Number(b.id)) {
//       return -1;
//     } else if (Number(a.id) > Number(b.id)) {
//       return 1;
//     }
//     return 0;
//   });
//   // console.log([...result]);
//   setResultData([...result]);
// }, [sleepData]);
