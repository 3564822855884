import React, { Component } from "react";

import { getHubTables } from "../../services/HubManager";
import { Link } from "react-router-dom";

// import BookDetails from "./BookDetails";

import "./Hubmanager.css";

class Hubmanager extends Component {
  constructor() {
    super();
    this.state = {
      hub_tables: []
    };
  }

  componentDidMount() {
    this.get_hub_tables();
  }

  get_hub_tables = () => {
    let send_credential_to_registrar = {};
    if (!sessionStorage.getItem("hub_token")) {
      send_credential_to_registrar.logged_in = null;
    } else {
      send_credential_to_registrar.logged_in = JSON.parse(
        sessionStorage.getItem("hub_token")
      ).userData;
    }
    getHubTables(send_credential_to_registrar).then(result => {
      if (result.data.data_array) {
        this.setState({
          hub_tables: result.data.data_array
        });
      } else {
        console.log("something went wrong");
      }
    });
  };

  render() {
    const { hub_tables } = this.state;

    return (
      <div id="Hubmanager">
        <img
          id="hubmanager_top_bookshelf_bg"
          alt=""
          src="/assets/images/database_img.jpg"
        />
        <div className="hubmanager_round_corner_background">
          <div className="hubmanager_title">Raw Data</div>
          <div className="hubmanager_tables_container">
            {hub_tables.map((item, index) => {
              if (!item) {
                return null;
              }

              return (
                <div className="hubmanager_table_item" key={index}>
                  <Link to={"/hubmanager/" + item.TABLE_NAME}>
                    <img
                      className={
                        "hubmanager_table_icon hmimg" +
                        Math.floor(Math.random() * 10 + 1)
                      }
                      alt=""
                      src="/assets/images/table_icon_white.png"
                    />
                    <div className="hubmanager_table_name">
                      {item.TABLE_NAME.replace(/_/g, " ")}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Hubmanager;
