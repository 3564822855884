import { countries_json_gen } from "../../translations/countries_json_generator";
import { useIntl } from "react-intl";

export function CovidQuestions() {
  const intl = useIntl();

  const currLanguage =
    localStorage.getItem("personal_hub_language") !== null
      ? localStorage.getItem("personal_hub_language").split(/[-_]/)[0]
      : "en";

  const yesNo = [
    { value: 0, label: "-" },
    {
      value: 1,
      label: intl.formatMessage({
        id: "covid_no"
      })
    },
    {
      value: 2,
      label: intl.formatMessage({
        id: "covid_yes"
      })
    }
  ];

  const zeroToten = [
    { value: 0, label: "-" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" }
  ];

  const symptomsIntesity = [
    { value: 0, label: "-" },
    {
      value: 1,
      label: intl.formatMessage({
        id: "covid_Q232_o1"
      })
    },
    {
      value: 2,
      label: intl.formatMessage({
        id: "covid_Q232_o2"
      })
    },
    {
      value: 3,
      label: intl.formatMessage({
        id: "covid_Q232_o3"
      })
    },
    {
      value: 4,
      label: intl.formatMessage({
        id: "covid_Q232_o4"
      })
    }
  ];

  const questions = {
    Q200: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q200"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_no"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q200_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q200_o3"
          })
        },
        {
          value: 4,
          label: intl.formatMessage({
            id: "covid_Q200_o4"
          })
        },
        {
          value: 5,
          label: intl.formatMessage({
            id: "covid_Q200_o5"
          })
        },
        {
          value: 6,
          label: intl.formatMessage({
            id: "covid_Q200_o6"
          })
        }
      ]
    },
    Q201: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q201"
      }),
      options: yesNo
    },
    Q202: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q202"
      }),
      options: yesNo
    },
    Q203: {
      depend: ["Q202"],
      label: intl.formatMessage({
        id: "covid_Q203"
      }),
      options: countries_json_gen("value", currLanguage)
    },
    Q204: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q204"
      }),
      options: yesNo
    },
    Q205: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q205"
      }),
      options: yesNo
    },
    Q206: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q206"
      }),
      options: yesNo
    },
    Q207: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q207"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q207_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q207_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q207_o3"
          })
        }
      ]
    },
    Q209: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q209"
      }),
      options: [
        { value: "-", label: "-" },
        {
          value: "Male",
          label: intl.formatMessage({
            id: "covid_male"
          })
        },
        {
          value: "Female",
          label: intl.formatMessage({
            id: "covid_female"
          })
        }
      ]
    },
    Q210: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q210"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q211: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q211"
      }),
      options: [
        { value: "-", label: "-" },
        { value: "0-9", label: "0-9" },
        { value: "10-19", label: "10-19" },
        { value: "20-29", label: "20-29" },
        { value: "30-39", label: "30-39" },
        { value: "40-49", label: "40-49" },
        { value: "50-59", label: "50-59" },
        { value: "60-69", label: "60-69" },
        { value: "70-79", label: "70-79" },
        { value: "80-89", label: "80-89" },
        { value: "90-99", label: "90-99" },
        { value: "100+", label: "100+" }
      ]
    },
    Q212: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q212"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q213: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q213"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q214: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q214"
      }),
      options: countries_json_gen("code", currLanguage)
    },
    Q215: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q215"
      }),
      options: countries_json_gen("code", currLanguage)
    },
    Q216: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q216"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q217: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q217"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q218: {
      depend: [],
      label: intl.formatMessage({
        id: "covid_Q218"
      }),
      options: yesNo
    },
    Q219: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q219"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q219_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q219_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q219_o3"
          })
        },
        {
          value: 4,
          label: intl.formatMessage({
            id: "covid_Q219_o4"
          })
        }
      ]
    },
    Q220: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q220"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q221: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q221"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q222: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q222"
      }),
      options: zeroToten
    },
    Q223: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q223"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q223_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q223_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q223_o3"
          })
        }
      ]
    },
    Q224: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q224"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q224_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q224_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q224_o3"
          })
        }
      ]
    },
    Q225: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q225"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q225_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q225_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q225_o3"
          })
        },
        {
          value: 4,
          label: intl.formatMessage({
            id: "covid_Q225_o4"
          })
        }
      ]
    },
    Q226: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q226"
      }),
      options: [
        { value: 0, label: "-" },
        {
          value: 1,
          label: intl.formatMessage({
            id: "covid_Q226_o1"
          })
        },
        {
          value: 2,
          label: intl.formatMessage({
            id: "covid_Q226_o2"
          })
        },
        {
          value: 3,
          label: intl.formatMessage({
            id: "covid_Q226_o3"
          })
        }
      ]
    },
    Q227: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q227"
      }),
      options: yesNo
    },
    Q228: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q228"
      }),
      options: yesNo
    },
    Q229: {
      depend: ["Q218", "Q228"],
      label: intl.formatMessage({
        id: "covid_Q229"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q230: {
      depend: ["Q218", "Q228"],
      label: intl.formatMessage({
        id: "covid_Q230"
      }),
      options: yesNo
    },
    Q231: {
      depend: ["Q218", "Q228", "Q230"],
      label: intl.formatMessage({
        id: "covid_Q231"
      }),
      options: [{ value: "input-field", label: "" }]
    },
    Q232: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q232"
      }),
      options: symptomsIntesity
    },
    Q233: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q233"
      }),
      options: symptomsIntesity
    },
    Q234: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q234"
      }),
      options: symptomsIntesity
    },
    Q235: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q235"
      }),
      options: symptomsIntesity
    },
    Q236: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q236"
      }),
      options: symptomsIntesity
    },
    Q237: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q237"
      }),
      options: symptomsIntesity
    },
    Q238: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q238"
      }),
      options: symptomsIntesity
    },
    Q239: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q239"
      }),
      options: symptomsIntesity
    },
    Q240: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q240"
      }),
      options: symptomsIntesity
    },
    Q241: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q241"
      }),
      options: symptomsIntesity
    },
    Q242: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q242"
      }),
      options: yesNo
    },
    Q243: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q243"
      }),
      options: yesNo
    },
    Q244: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q244"
      }),
      options: yesNo
    },
    Q245: {
      depend: ["Q218"],
      label: intl.formatMessage({
        id: "covid_Q245"
      }),
      options: yesNo
    }
  };

  return questions;
}
