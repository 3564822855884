import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../helpers/PrivateRoute";

// import Welcome from '../screens/Welcome/Welcome';
import Login from "../screens/Login/login";
import Logout from "../screens/Logout/logout";
import VisitHUBsite from "../screens/VisitHUBsite/VisitHUBsite";
import ActiveMinute from "../screens/ActiveMinute/ActiveMinute";
import AdminPage from "../screens/AdminPage/AdminPage";
import AboutPage from "../screens/AboutPage/AboutPage";
import ChangeAdminPassword from "../screens/AdminPage/ChangeAdminPassword";
// import NotFound from "../screens/NotFound/NotFound";
// import Cockpit from "../screens/Cockpit/cockpit";
// import HomePage from "../screens/HomePage/HomePage";
import Navigator from "../screens/Navigator/Navigator";
import ScanAndBarcode from "../screens/ScanAndBarcode/ScanAndBarcode";
import SingleBookInfo from "../screens/SingleBookInfo/SingleBookInfo";
import ReceiptsShopIcons from "../screens/Receipt/ReceiptsShopIcons";
import ReceiptSingle from "../screens/Receipt/ReceiptSingle";
import ReceiptDisplay from "../screens/ReceiptDisplay/ReceiptDisplay";
import BookcolInsertBook from "../screens/Bookcollector/BookcolInsertBook";
import BookcolTabsControl from "../components/Bookcollector/BookcolTabsControl";
import Hubmanager from "../screens/Hubmanager/Hubmanager";
import TableEdit from "../screens/Hubmanager/TableEdit";
import Charts from "../screens/Charts";
import Maps from "../screens/Maps/index";
import VirtualAssistant from "../screens/VirtualAssistant/VirtualAssistant";
import VirtualAssistantMaps from "../screens/VirtualAssistant/VirtualAssistantMaps";
import HUBsite from "../screens/HUBsite";
import Impressum from "../screens/Impressum/Impressum";
import Avatar from "../screens/Avatar/avatar";
import UploadTest from "../screens/UploadTest";
import Covid19 from "../screens/Covid19/Covid19";
import AboutCovid19 from "../screens/AboutCovid19/AboutCovid19";
import HubPrompter from "../screens/HubPrompter/HubPrompter";
import CovidStatistics from "../screens/CovidStatistics/CovidStatistics";

import BackupPage from "../screens/BackupPage/BackupPage";
import UpgradePage from "../screens/UpgradePage/UpgradePage";

const Routes = (props) => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/receipts/:shop_id" component={ReceiptSingle} />
      <PrivateRoute exact path="/receipts" component={ReceiptsShopIcons} />
      <Route exact path="/virtualassistant" component={VirtualAssistant} />

      <PrivateRoute
        exact
        path="/virtualassistantmaps"
        component={VirtualAssistantMaps}
      />

      <PrivateRoute exact path="/hubsearchengine" component={HubPrompter} />

      <PrivateRoute exact path="/backup" component={BackupPage} />
      <PrivateRoute exact path="/upgrade" component={UpgradePage} />

      <Route path="/maps" component={Maps} />

      <Route path="/uploadtest" component={UploadTest} />

      <PrivateRoute exact path="/scanandbarcode" component={ScanAndBarcode} />
      <PrivateRoute
        exact
        path="/scanandbarcode/singlebookinfo"
        component={SingleBookInfo}
      />

      <PrivateRoute
        exact
        path="/bookcollector/insertbook"
        component={BookcolInsertBook}
      />
      <PrivateRoute path="/bookcollector" component={BookcolTabsControl} />
      <Route path="/impressum" component={Impressum} />
      <PrivateRoute path="/avatar" component={Avatar} />
      <PrivateRoute path="/visit" component={VisitHUBsite} />
      <PrivateRoute path="/activeMinute" component={ActiveMinute} />
      <PrivateRoute path="/createAdmin" component={AdminPage} />
      <Route exact path="/about/covid19" component={AboutCovid19} />
      <Route path="/about" component={AboutPage} />
      <PrivateRoute
        path="/changeAdminPassword"
        component={ChangeAdminPassword}
      />
      <PrivateRoute exact path="/hubmanager" component={Hubmanager} />
      <PrivateRoute
        exact
        path="/hubmanager/:table_name"
        component={TableEdit}
      />
      <Route path="/charts" component={Charts} />

      <PrivateRoute exact path="/logout" component={Logout} />

      <PrivateRoute
        exact
        path="/covid19/statistics"
        component={CovidStatistics}
      />
      <PrivateRoute exact path="/covid19/:page?" component={Covid19} />

      <Route exact path="/navigation" component={Navigator} />

      <Route exact path="/receiptdisplay" component={ReceiptDisplay} />

      <Route exact path="/login" component={Login} />
      <PrivateRoute
        path="/HUBsite/:hub_id?/:test_param1?/:test_param2?"
        component={HUBsite}
      />
      {/* <Route path="/HUBsite" component={HUBsite} /> */}

      <PrivateRoute exact path="/" component={Login} />
      <PrivateRoute path="*" component={Login} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
