import { URLconst } from "./const";

export function LoginCall(userData) {
  let BaseURL = URLconst("hub_locations/my_hub_login.php");

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}
