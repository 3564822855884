import React, { Component } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import Barcode from "react-hooks-barcode";
import "./ScanAndBarcode.css";
import { Redirect } from "react-router-dom";
import { GetBookDataKNV } from "../../services/FindPosition";
import { FormattedMessage } from "react-intl";

import ReactCardFlip from "react-card-flip";
import Content from "../../components/Content";

const barcode_config = {
  marginTop: "20px",
  marginBottom: "20px",
  width: 4,
  isFlipped: false
};

class ScanAndBarcode extends Component {
  state = {
    showScanner: false,
    // book_ean: "9783792000496",
    // book_ean: "9783831037773",
    // book_ean: "9783845465421",
    book_ean: null,
    loadbookdata: false,
    // loadbookdata: true,
    sessionID: null,
    book_info: null,
    scanner_message: false,
    scan_type: null,
    scanned_address: null,
    redirectToSingleBook: false
  };

  get_book_data_knv_general = () => {
    let send_credential_to_registrar = {
      search_string: this.state.book_ean
    };
    if (this.state.sessionID !== null) {
      send_credential_to_registrar.sessionID = this.state.sessionID;
    }
    GetBookDataKNV(send_credential_to_registrar).then(result => {
      if (result.book_info) {
        this.setState(
          {
            book_info: result.book_info,
            loadbookdata: false
          },
          () => {
            this.setState({ redirectToSingleBook: true });
          }
        );
      } else {
        this.setState({
          scanner_message: true,
          loadbookdata: false,
          showScanner: false,
          scan_type: "bar_code"
        });
        console.log("knv error");
      }
    });
  };

  handleFlip = e => {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  };

  handleOpenScanner = () => {
    this.setState({
      showScanner: true,
      scan_type: null,
      scanned_address: null
    });
    this.ScannZxing();
  };

  handleCloseScanner = () => {
    this.setState({ showScanner: false });
  };

  scanner_callback = (ISBN, closeScanner, loadbookdata) => {
    this.setState({
      book_ean: ISBN,
      showScanner: closeScanner,
      loadbookdata: loadbookdata
    });
  };

  ScannZxing = () => {
    // let selectedDeviceId;
    const codeReader = new BrowserMultiFormatReader();
    // console.log("ZXing code reader initialized");

    codeReader
      .decodeFromInputVideoDevice(undefined, "bcas_video")
      .then(result => {
        // console.log(result);
        if (result.format === 11) {
          var subtext = result.text.substring(0, 28);
          // var address = "https://www.personal-hub.com";
          var address = window.location.href.substring(0, 28);
          if (subtext === address) {
            window.location.href = result.text;
          } else {
            this.scanner_callback(null, false, true);
            this.setState({
              scan_type: "qr_code",
              scanned_address: result.text
            });
          }
        } else {
          this.scanner_callback(result.text, false, true);
        }
      })
      .catch(err => console.error("some error" + err));
  };

  render() {
    if (this.state.redirectToSingleBook) {
      return (
        <Redirect
          push={true}
          to={{
            pathname: "/scanandbarcode/singlebookinfo",
            state: { book_info: this.state.book_info }
          }}
        />
      );
    }

    if (this.state.loadbookdata) {
      this.get_book_data_knv_general();
    }
    let message_body;
    switch (this.state.scan_type) {
      case "bar_code":
        message_body = (
          <div className="bcas_error_msg_div">
            <FormattedMessage id="scan_and_barcode_scan_error" />
          </div>
        );
        break;
      case "qr_code":
        message_body = (
          <div className="bcas_error_msg_div">
            <span>
              Only personal hub QR codes are supported you scanned:
              <br />
              {this.state.scanned_address}
            </span>
          </div>
        );
        break;
      default:
    }

    return (
      <Content currComp={"scanAndBarcode"}>
        <div id="ScanAndBarcode">
          <div id="bcas_top_section">
            {this.state.showScanner ? (
              <video id="bcas_video"></video>
            ) : (
              <div className="bcas_scan_wrapper">
                <div className="bcas_scan_title">
                  <FormattedMessage id="scan_and_barcode_scan_code" />
                </div>
                <img
                  onClick={this.handleOpenScanner}
                  className="bcas_scan_img"
                  src="/assets/images/camera_icon.png"
                  alt=""
                />
                <div className="bcas_scan_text">
                  <FormattedMessage id="scan_and_barcode_open_scaner" />
                </div>
              </div>
            )}
          </div>
          <div id="bcas_bottom_section">
            {this.state.scanner_message ? (
              <div className="bcas_error_msg_wrapper">{message_body}</div>
            ) : null}
            {this.state.showScanner || this.state.scanner_message ? null : (
              <ReactCardFlip
                isFlipped={this.state.isFlipped}
                flipDirection="horizontal"
              >
                <div
                  className="bcas_bs_content_wrapper"
                  key="front"
                  onClick={this.handleFlip}
                >
                  <div className="bcas_title_text">
                    <FormattedMessage id="scan_and_barcode_your_id" />
                  </div>
                  <div className="bcas_barcode_img">
                    <Barcode
                      value={
                        sessionStorage.getItem("hub_token")
                          ? JSON.parse(sessionStorage.getItem("hub_token"))
                              .userData.my_hub_id.slice(-5)
                              .replace("_", "")
                          : "LOG IN"
                      }
                      {...barcode_config}
                    />
                  </div>
                  <div>
                    <span aria-label="none" role="img">
                      &#9899;{" "}
                    </span>
                    <span aria-label="none" role="img">
                      {" "}
                      &#9898;
                    </span>
                  </div>
                  {/* <div className="bcas_bottom_text">
                  <FormattedMessage id="scan_and_barcode_click_message" />
                </div> */}
                </div>
                <div
                  className="bcas_bs_content_wrapper"
                  key="back"
                  onClick={this.handleFlip}
                >
                  <div
                    style={{
                      width: "292px",
                      height: "166px",
                      border: "2px solid black",
                      padding: "10px",
                      marginTop: "21px"
                    }}
                    className="bcas_bottom_text"
                  >
                    <FormattedMessage id="scan_and_barcode_show_message" />
                  </div>
                  <div>
                    <span aria-label="none" role="img">
                      &#9898;{" "}
                    </span>
                    <span aria-label="none" role="img">
                      {" "}
                      &#9899;
                    </span>
                  </div>
                  {/* <div
                    style={{ marginTop: "20px" }}
                    className="bcas_bottom_text"
                  >
                    <FormattedMessage id="scan_and_barcode_click_back_message" />
                  </div> */}
                </div>
              </ReactCardFlip>
            )}
          </div>
        </div>
      </Content>
    );
  }
}

export default ScanAndBarcode;
