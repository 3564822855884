import React from "react";
import Select from "react-select";

export default function CustomSearch({ inputValue, setInputValue }) {
  const people_details_opt = [
    { value: "", label: "-- list of fields --" },
    {
      value: "my_people_details",
      label: "my_people_details | table name"
    },
    { value: "id", label: "id" },
    {
      value: "related_people_id",
      label: "related_people_id | = 0 | always"
    },
    {
      value: "category",
      label: "category | ='covid-19' | always, required filter"
    },
    {
      value: "what",
      label: "what | ~ 'Nationality' | question text in local language"
    },
    {
      value: "value_text",
      label: "value_text | ~ 'Female' | text of the answer, English"
    },
    {
      value: "value_date",
      label: "value_date | ~ 2020-03-19 11:24:51 | time when answered"
    },
    {
      value: "value_number",
      label: "value_number | ~ 175 | for numeric answers"
    },
    {
      value: "unit",
      label: "unit | ~ 'cm' | only for some answers"
    },
    {
      value: "hub_transaction_id",
      label: "hub_transaction_id | ~ 'Q214' | Question unique ID"
    },
    {
      value:
        "id, related_people_id, category, what, value_text, value_date, value_number, unit, hub_transaction_id",
      label: "ALL FIELDS"
    }
  ];

  const ratings_opt = [
    { value: "", label: "-- list of fields --" },
    { value: "my_ratings", label: "my_ratings | table name" },
    { value: "id", label: "id" },
    {
      value: "entry_type",
      label: "entry_type | :'covid-19' | always, required filter"
    },
    {
      value: "subject_type",
      label: "subject_type | : 'poll' | always, required filter"
    },
    {
      value: "subject",
      label: "subject | ~ 'Q4' | Question unique ID"
    },
    {
      value: "rating",
      label: "rating | ~ 4 | value of the answer (1 - 10; 0, null)"
    },
    {
      value: "explanation",
      label:
        "explanation | ~ 'I rarely experience scarcity of supplies' | text of question in local language"
    },
    {
      value: "date_on",
      label: "date_on | ~ 2020-03-19 11:24:51 | time when answered"
    },
    {
      value: "keywords",
      label: "keywords | ~'G1'| Which group of questions; subject of change"
    },
    {
      value: "identified_by",
      label: "identified_by | ~ 3 | Internal ID of the question"
    },
    {
      value:
        "id, entry_type, subject_type, subject, rating, explanation, date_on, keywords, identified_by",
      label: "ALL FIELDS"
    }
  ];

  const medical_values_opt = [
    { value: "", label: "-- list of fields --" },
    {
      value: "my_medical_values",
      label: "my_medical_values | table name"
    },
    { value: "id", label: "id" },
    {
      value: "category",
      label: "category | ='covid-19' | always, required filter"
    },
    {
      value: "date_on",
      label: "date_on | ~ 2020-03-19 11:24:51 | time when answered"
    },
    {
      value: "time_start",
      label:
        "time_start | ~ 2020-03-19 11:24:51 | date/time 'from', e.g.disease start"
    },
    {
      value: "time_end",
      label:
        "time_end | ~ 2020-03-19 11:24:51 | date/time 'to', e.g. diseaseend"
    },
    {
      value: "what",
      label:
        "what | ~ 'How careful do I think I was when getting infected' |text of question in local language or symptom in English"
    },
    {
      value: "value",
      label: "value | ~ 38,6 | value of the answer, numeric"
    },
    {
      value: "reference_value",
      label:
        "reference_value | ~ '2-Yes' | selected option, id and value,English"
    },
    {
      value: "universal_index",
      label: "universal_index | ~'Q228'| Unique ID of the question"
    },
    {
      value:
        "id, category, date_on, time_start, time_end, what, value, reference_value, universal_index",
      label: "ALL FIELDS"
    }
  ];

  return (
    <>
      <div className="custom_search_container">
        <div className="custom_search_droppD_title">
          Tables and fields where answers are stored
        </div>
        <div className="custom_search_droppD_wrapper">
          <div className="custom_search_droppD_label">
            About me (demographics)
          </div>
          <Select
            defaultValue={people_details_opt[0]}
            onChange={e => {
              setInputValue(inputValue + " " + e.value);
              e.value = "";
              e.label = "-- list of fields --";
            }}
            options={people_details_opt}
          />
        </div>
        <div className="custom_search_droppD_wrapper">
          <div className="custom_search_droppD_label">Poll</div>
          <Select
            defaultValue={ratings_opt[0]}
            onChange={e => {
              setInputValue(inputValue + " " + e.value);
              e.value = "";
              e.label = "-- list of fields --";
            }}
            options={ratings_opt}
          />
        </div>
        <div className="custom_search_droppD_wrapper">
          <div className="custom_search_droppD_label">
            Risk and disease questions; symptom values
          </div>
          <Select
            defaultValue={medical_values_opt[0]}
            onChange={e => {
              setInputValue(inputValue + " " + e.value);
              e.value = "";
              e.label = "-- list of fields --";
            }}
            options={medical_values_opt}
          />
        </div>
      </div>
    </>
  );
}
