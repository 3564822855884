const filterDropDown = [
  { value: 'year', name: 'Year' },
  { value: 'month', name: 'Month' },
  { value: 'week', name: 'Week' }
];

const ChartsCategories = [
  {
    name: 'Buy',
    description: 'Books bought per month / week / year',
    value: 'buy'
  },
  {
    name: 'Read',
    description: 'Books read per month / week / year',
    value: 'read'
  },
  // {
  //   name: 'Selected product',
  //   description: 'Spending on a selected product (drug store; product like shampoo) - frequency and cost showing on a graph',
  //   value: 'selected'
  // },
  // {
  //   name: 'Spent',
  //   description: 'Money spent on books last month / year',
  //   value: 'spent'
  // },
  {
    name: 'Gift',
    description: 'Books bought as gift (in different time zones)',
    value: 'gift'
  }
];

export default {
  filterDropDown,
  ChartsCategories
};
