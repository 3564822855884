import { URLconst } from "./const";
import axios from "axios";

export function postLogData(param) {
  let BaseURL = URLconst("logging/log.php");

  return axios({
    url: BaseURL,
    method: "post",
    headers: { "content-type": "application/json" },
    params: {
      target_hub_id: param.target_hub_id,
      logged_in: param.logged_in
    },
    data: null
  });
}
