import React from "react";
// import Swipe from "react-easy-swipe";
import "./MenuSlider.css";

const MenuSlider = ({
  discard,
  setModal,
  receipt_loaded,
  showScanner,
  setShowScanner
}) => {
  return (
    <div className="menu_slider_block">
      {!showScanner ? (
        <>
          {receipt_loaded ? (
            <button
              onClick={() => {
                setModal(true);
              }}
            >
              <img alt="save" src="assets/images/Save.svg" />
            </button>
          ) : (
            <button style={{ backgroundColor: "lightgrey" }}>
              <img alt="save" src="assets/images/Save.svg" />
            </button>
          )}
          <button
            onClick={() => {
              discard();
            }}
          >
            <img alt="scan" src="assets/images/qr-code.png" />
          </button>
          <button
            onClick={() => {
              discard();
            }}
          >
            <img alt="save" src="assets/images/Trash.svg" />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default MenuSlider;
