import React from "react";
import { useIntl, FormattedHTMLMessage } from "react-intl";

export default function Covid19Welcome() {
  const intl = useIntl();

  return (
    <>
      <div className="covid19_welcome_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid19_welcome_title"
          })}
        </span>
      </div>
      <div className="covid_welcome_container">
        <div className="covid_welcome_bold">
          {intl.formatMessage({
            id: "covid19_welcome_text_pt1"
          })}
        </div>
        <div className="covid_welcome_list_container">
          <div className="covid_welcome_list_item_wrap">
            <div className="covid_welcome_small_dot_filled"></div>
            <div className="covid_welcome_italic">
              {intl.formatMessage({
                id: "covid19_welcome_text_pt2"
              })}
            </div>
          </div>
          <div className="covid_welcome_list_item_wrap">
            <div className="covid_welcome_small_dot_filled"></div>
            <div className="covid_welcome_italic">
              {intl.formatMessage({
                id: "covid19_welcome_text_pt3"
              })}
            </div>
          </div>
          <div className="covid_welcome_list_item_wrap">
            <div className="covid_welcome_small_dot_filled"></div>
            <div className="covid_welcome_italic">
              {intl.formatMessage({
                id: "covid19_welcome_text_pt4"
              })}
            </div>
          </div>
          <div className="covid_welcome_list_item_wrap">
            <div className="covid_welcome_small_dot_filled"></div>
            <div className="covid_welcome_italic">
              {intl.formatMessage({
                id: "covid19_welcome_text_pt5"
              })}
            </div>
          </div>
        </div>
        <div className="covid_welcome_formated_html_bold">
          <FormattedHTMLMessage id="covid19_welcome_text_pt6" />
        </div>
        <div className="covid_welcome_formated_html_bold">
          <FormattedHTMLMessage id="covid19_welcome_text_pt7" />
        </div>
        <div>
          {intl.formatMessage({
            id: "covid19_welcome_text_pt8"
          })}
        </div>
        <div>
          {intl.formatMessage({
            id: "covid19_welcome_text_pt9"
          })}
        </div>
      </div>
    </>
  );
}
