import React, { useState, useEffect, useRef } from "react";
import "./CreateAdminAccount.scss";
import Modal from "react-modal";
import { postMail } from "../../services/SendMail";
import { postAdminPassword, postBasics } from "../../services/setAdminPassword";

import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";

//validation of login input
var passwordValidator = require("password-validator");

var schema_length = new passwordValidator();
var schema_upper = new passwordValidator();
var schema_lower = new passwordValidator();
var schema_digit = new passwordValidator();
var schema_symbol = new passwordValidator();
var schema_spaces = new passwordValidator();
//has to be 4 digits and no spaces
schema_length
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100); // Maximum length 100
schema_upper.has().uppercase(); // Must have Uppercase
schema_lower.has().lowercase(); // Must have Uppercase
schema_digit.has().digits(); // Must have digits
schema_symbol.has().symbols(); // Must have symbol
schema_spaces
  .has()
  .not()
  .spaces(); // Should not have spaces

const CreateAdminAccount = ({ display_create_admin }) => {
  const [currentStep, setCurentStep] = useState(1);
  // const [showModal, setShowModal] = useState(true);
  const [showModal] = useState(true);
  const [validationResult, setValidationResult] = useState({
    length: false,
    upper: false,
    lower: false,
    digit: false,
    symbol: false,
    spaces: true
  });

  const [email, setEmail] = useState("");
  const [saveEmail, setSaveEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [receivedPin, setReceivedPin] = useState("");
  const [generatedPin] = useState(
    Math.floor(Math.random() * (999 - 100 + 1) + 100)
  );

  const validation_ref = useRef();

  useEffect(() => {
    validation_ref.current = validationResult;
  });

  useEffect(() => {
    var keys = Object.keys(validation_ref.current);
    var obj = {};
    keys.forEach(e => {
      switch (e) {
        case "length":
          if (schema_length.validate(password)) {
            obj.length = true;
          } else {
            obj.length = false;
          }
          break;
        case "upper":
          if (schema_upper.validate(password)) {
            obj.upper = true;
          } else {
            obj.upper = false;
          }
          break;
        case "lower":
          if (schema_lower.validate(password)) {
            obj.lower = true;
          } else {
            obj.lower = false;
          }
          break;
        case "digit":
          if (schema_digit.validate(password)) {
            obj.digit = true;
          } else {
            obj.digit = false;
          }
          break;
        case "symbol":
          if (schema_symbol.validate(password)) {
            obj.symbol = true;
          } else {
            obj.symbol = false;
          }
          break;
        case "spaces":
          if (schema_spaces.validate(password)) {
            obj.spaces = true;
          } else {
            obj.spaces = false;
          }
          break;
        default:
      }
    });
    setValidationResult(obj);
  }, [password]);

  useEffect(() => {
    if (Object.values(validationResult).includes(false)) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  }, [validationResult]);

  const send_mail = () => {
    if (email !== "") {
      let body;
      if (currentStep === 3) {
        body = {
          email_address: email,
          subject: "Your new Personal HUB admin password",
          mail_body:
            "<p>Your new admin password is:</p><h1>" +
            password +
            "</h1><p>Use this password for login as admin.</p><p>Your HUBweb team</p><p><small>Please do NOT reply to this email. For any questions use info@hub-web.com instead.</small></p>"
        };
      } else {
        body = {
          email_address: email,
          subject: "Confirmation of your email address – one-time code",
          mail_body:
            "<p>Welcome to Personal HUB admin account setup wizard.</p><p>Your one-time email confirmation code is:</p><h1>" +
            generatedPin +
            "</h1><p>Enter this number into Step2 of the wizard to continue the setup.</p><p>Your HUBweb team</p><p><small>Please do NOT reply to this email. For any questions use info@hub-web.com instead.</small></p>"
        };
      }

      postMail(body).then(result => {
        if (result.data.error) {
          console.log(result.data.error);
        } else {
          // console.log(result.data.success);
          if (currentStep !== 3) {
            if (email !== "" && saveEmail) {
              let body = JSON.parse(sessionStorage.getItem("hub_token"))
                .userData;
              body.email = email;
              body.nickname = email.split("@")[0];

              postBasics(body).then(result => {
                if (result.data.error) {
                  console.log(result.data.error);
                } else {
                  // console.log(result.data.success);
                }
              });
            }
            setCurentStep(2);
          }
        }
      });
    }
  };

  const send_admin_password = () => {
    if (email !== "") {
      let body = JSON.parse(sessionStorage.getItem("hub_token")).userData;
      body.admin_password = password;

      postAdminPassword(body).then(result => {
        if (result.data.error) {
          console.log(result.data.error);
          setCurentStep(3);
        } else {
          // console.log(result.data.success);
          send_mail();
          setCurentStep(4);
        }
      });
    }
  };

  const verify_pin = () => {
    if (Number(receivedPin) === Number(generatedPin)) {
      setCurentStep(3);
    } else {
      console.log("wrong pin");
    }
  };

  const bgCol = [
    { backgroundColor: "#36759e" },
    { backgroundColor: "lightgray" }
  ];

  const login_as_admin = () => {
    let hub_token = JSON.parse(sessionStorage.getItem("hub_token"));
    hub_token.userData.db_username = hub_token.userData.my_hub_id + "_admin";
    hub_token.userData.db_pwd = password;
    sessionStorage.setItem("hub_token", JSON.stringify(hub_token));
    display_create_admin(false);
  };

  return (
    <Modal
      isOpen={showModal}
      contentLabel="Create admin account modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "10000"
        },
        content: {
          background: "rgba(255,255,255,1)",
          top: "20%",
          right: "5%",
          left: "5%",
          bottom: "8%",
          borderRadius: "30px"
        }
      }}
    >
      <span
        className="modal_info close"
        onClick={() => display_create_admin(false)}
        id="info_close"
      >
        <span>&times;</span>
      </span>
      <div className="caa_main_container">
        <div className="caa_title">Create Admin Account</div>
        <div className="caa_container">
          <div className="caa_lp_1">
            <div
              className="fake_dot"
              style={currentStep >= 1 ? bgCol[0] : bgCol[1]}
            ></div>
            <div
              className="fake_vertical_line fhl_start"
              style={currentStep >= 1 ? bgCol[0] : bgCol[1]}
            ></div>
          </div>
          <div className="caa_lp_2">
            <div
              className="fake_dot"
              style={currentStep >= 2 ? bgCol[0] : bgCol[1]}
            ></div>
            <div
              className="fake_vertical_line"
              style={currentStep >= 2 ? bgCol[0] : bgCol[1]}
            ></div>
          </div>
          <div className="caa_lp_3">
            <div
              className="fake_dot"
              style={currentStep >= 3 ? bgCol[0] : bgCol[1]}
            ></div>
            <div
              className="fake_vertical_line"
              style={currentStep >= 3 ? bgCol[0] : bgCol[1]}
            ></div>
          </div>
          <div className="caa_lp_4">
            <div
              className="fake_dot"
              style={currentStep >= 4 ? bgCol[0] : bgCol[1]}
            ></div>
          </div>

          <div className="caa_pp_1">
            <div className="caa_title">Enter your email address</div>
            <input
              style={{ width: "100%", height: "45px", marginBottom: "10px" }}
              onChange={event => {
                setEmail(event.target.value);
              }}
              id="caa_email_input"
              type="text"
              name="email_address"
            />
            {/* <div
              style={{
                width: "22px",
                height: "22px",
                border: "1px solid grey",
                display: "inline-block",
                marginRight: "10px"
              }}
            ></div> */}
            <Checkbox
              style={{ margin: "0", padding: "0 10px 0 0" }}
              checked={saveEmail}
              onChange={() => {
                setSaveEmail(!saveEmail);
              }}
              value="save email"
              color="primary"
            />
            <span style={{ paddingBottom: "5px" }}>
              Save this email in my Personal HUB
            </span>
            <button
              className="caa_button cca_float_right"
              onClick={() => send_mail()}
            >
              Send confirmation
            </button>
          </div>

          <div className="caa_pp_2">
            <div className="caa_title">Enter confirmation code</div>
            <div style={currentStep >= 2 ? null : { color: "lightgrey" }}>
              3-digit code from the email we've sent to the address above
            </div>
            {currentStep >= 2 ? (
              <>
                <input
                  style={{ minWidth: "150px", width: "50%", height: "45px" }}
                  onChange={event => {
                    setReceivedPin(event.target.value);
                  }}
                  id="caa_confirmation_input"
                  type="number"
                  name="confiramtion_code"
                />
                <button
                  className="caa_button cca_float_right"
                  onClick={() => verify_pin()}
                >
                  Confirm
                </button>
              </>
            ) : (
                <>
                  <input
                    style={{
                      minWidth: "150px",
                      width: "50%",
                      height: "45px",
                      border: "lightgrey"
                    }}
                    id="caa_confirmation_input"
                    type="number"
                    name="confiramtion_code"
                    disabled
                  />
                  <button
                    className="caa_button_disabled cca_float_right"
                    disabled
                  >
                    Confirm
                </button>
                </>
              )}
          </div>

          <div className="caa_pp_3">
            <div className="caa_title">Set admin password</div>
            <div style={currentStep >= 3 ? null : { color: "lightgrey" }}>
              <span style={validationResult.length ? { color: "green" } : null}>
                Minimum 8 ch
              </span>
              ,{" "}
              <span style={validationResult.upper ? { color: "green" } : null}>
                1 capital letter
              </span>
              ,{" "}
              <span style={validationResult.lower ? { color: "green" } : null}>
                1 small letter
              </span>
              ,{" "}
              <span style={validationResult.digit ? { color: "green" } : null}>
                1 number
              </span>
              ,{" "}
              <span style={validationResult.symbol ? { color: "green" } : null}>
                1special ch
              </span>
              {validationResult.spaces ? null : (
                <span style={{ color: "red" }}> no spaces alowed</span>
              )}
              .
            </div>
            {currentStep >= 3 ? (
              <div style={{ position: "relative" }}>
                <input
                  style={{ minWidth: "150px", width: "50%", height: "45px" }}
                  onChange={event => {
                    setPassword(event.target.value);
                  }}
                  id="caa_password_input"
                  type="password"
                  name="admin_password"
                />
                <img
                  alt=""
                  src="/assets/images/eye_icon.png"
                  style={{
                    height: "20px",
                    position: "absolute",
                    right: "calc(50% + 10px)",
                    top: "13px"
                  }}
                  onClick={event => {
                    let element = document.getElementById("caa_password_input");
                    if (element.type === "password") {
                      element.type = "text";
                      event.target.src = "/assets/images/eye_icon_off.png";
                    } else {
                      element.type = "password";
                      event.target.src = "/assets/images/eye_icon.png";
                    }
                  }}
                />
                {passwordValid ? (
                  <button
                    className="caa_button cca_float_right"
                    onClick={() => send_admin_password()}
                  >
                    Set & send
                  </button>
                ) : (
                    <button
                      className="caa_button_disabled cca_float_right"
                      disabled
                    >
                      Set & send
                  </button>
                  )}
              </div>
            ) : (
                <div style={{ position: "relative" }}>
                  <input
                    style={{
                      minWidth: "150px",
                      width: "50%",
                      height: "45px",
                      border: "lightgrey"
                    }}
                    id="caa_password_input"
                    type="password"
                    name="admin_password"
                    disabled
                  />
                  <button
                    className="caa_button_disabled cca_float_right"
                    disabled
                  >
                    Set & send
                </button>
                </div>
              )}
          </div>

          <div className="caa_pp_4">
            <div className="caa_title">Done.</div>
            <div style={currentStep >= 4 ? null : { color: "lightgrey" }}>
              Your password will be also sent to your email
            </div>
          </div>
        </div>
        {currentStep >= 4 ? (
          <button
            className="caa_button caa_finish_btn"
            onClick={() => {
              login_as_admin();
            }}
          >
            Continue as admin
          </button>
        ) : (
            <button className="caa_button_disabled caa_finish_btn" disabled>
              Continue as admin
          </button>
          )}
        <Link style={{ display: "flex", justifyContent: "center" }} to="/login">
          <button
            onClick={() => sessionStorage.removeItem("hub_token")}
            className="caa_button"
          >
            Login as admin
          </button>
        </Link>
      </div>
    </Modal>
  );
};
export default CreateAdminAccount;
