import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { postCovid19MedValData } from "../../services/PostHUBsiteData";
import { putCovid19MedValData } from "../../services/PutHUBsiteData";
import { useIntl } from "react-intl";
import { CovidQuestions } from "./CovidQuestions";

export default function Covid19OverallIllness({
  query_params,
  medValData,
  getCovid19MedVal
}) {
  const intl = useIntl();
  const [answeredMap, setAnsweredMap] = useState({});
  const [loading, setLoading] = useState(false);
  const questions = CovidQuestions();

  useEffect(() => {
    if (medValData.length > 0) {
      let temp_obj = {};

      medValData.forEach((item, index) => {
        temp_obj = { ...temp_obj, [item.universal_index]: index };
      });
      setAnsweredMap(temp_obj);
      //   console.log(temp_obj);
    }
  }, [medValData]);

  const handleSelectMedVal = (qID, val, label, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};
    payload.data.category = "covid-19";
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.value = val;
    payload.data.reference_value = val !== null ? val + "-" + label : null;
    payload.data.universal_index = qID;

    if (id !== null) {
      payload.data.id = id;
      putCovid19MedValData(payload).then(result => {
        // console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        // console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    }
  };

  const renderSelectMedVal = (question, width) => {
    let width_val = 200;
    if (width !== undefined) {
      width_val = width;
    }
    let oldAnswer = null;
    let id = null;
    if (answeredMap[question] !== undefined) {
      let indexInOptions = questions[question].options.findIndex(
        element =>
          Number(element.value) ===
          Number(medValData[answeredMap[question]].value)
      );
      // console.log(question, indexInOptions);
      id = medValData[answeredMap[question]].id;
      oldAnswer = questions[question].options[indexInOptions];
    }

    let disabled = true;
    let temp_arr = Object.keys(answeredMap);
    if (questions[question].depend.length > 0) {
      let verif_arr = [];
      questions[question].depend.forEach(element => {
        if (temp_arr.includes(element)) {
          let temp_val = medValData[answeredMap[element]].value;
          if (Number(temp_val) === 2) {
            verif_arr.push(true);
          } else {
            verif_arr.push(false);
          }
        } else {
          verif_arr.push(false);
        }
      });
      if (!verif_arr.includes(false)) {
        disabled = false;
      }
    } else {
      disabled = false;
    }

    return (
      <div className="covid19_noshrink" style={{ width: width_val }}>
        <Select
          isDisabled={disabled}
          placeholder={""}
          value={oldAnswer}
          onChange={e => {
            e !== null
              ? handleSelectMedVal(question, e.value, e.label, id)
              : handleSelectMedVal(question, null, null, null);
          }}
          options={questions[question].options}
        />
      </div>
    );
  };

  const renderInputMedVal = (question, val_type, unit) => {
    let oldAnswer = "";
    let id = null;
    let date_width = val_type === "date" ? "covid19_input_date_width" : "";
    let disabled = true;
    let disabledOveride = false;
    if (answeredMap[question] !== undefined) {
      if (val_type === "date") {
        if (unit === "from") {
          if (
            moment(
              medValData[answeredMap[question]].time_start,
              moment.ISO_8601,
              true
            ).isValid()
          ) {
            oldAnswer = moment(
              medValData[answeredMap[question]].time_start
            ).format("YYYY-MM-DD");
          }
        } else {
          if (
            moment(
              medValData[answeredMap[question]].time_end,
              moment.ISO_8601,
              true
            ).isValid()
          ) {
            oldAnswer = moment(
              medValData[answeredMap[question]].time_end
            ).format("YYYY-MM-DD");
          } else {
            if (
              Number(medValData[answeredMap[question]].reference_value) === 1
            ) {
              disabledOveride = true;
            }
          }
        }
      } else if (val_type === "checkbox") {
        let temp_val = medValData[answeredMap[question]].reference_value;
        oldAnswer = Number(temp_val) === 1 ? true : false;
        if (
          moment(
            medValData[answeredMap[question]].time_end,
            moment.ISO_8601,
            true
          ).isValid()
        ) {
          disabledOveride = true;
        }
      } else {
        oldAnswer = medValData[answeredMap[question]].value;
      }

      // console.log(oldAnswer, question);
      id = medValData[answeredMap[question]].id;
    }
    let temp_arr = Object.keys(answeredMap);
    if (questions[question].depend.length > 0) {
      let verif_arr = [];
      questions[question].depend.forEach(element => {
        if (temp_arr.includes(element)) {
          let temp_val = medValData[answeredMap[element]].value;
          if (Number(temp_val) === 2) {
            verif_arr.push(true);
          } else {
            verif_arr.push(false);
          }
        } else {
          verif_arr.push(false);
        }
      });
      if (!verif_arr.includes(false)) {
        disabled = false;
      }
    } else {
      disabled = false;
    }

    // if (question === "Q221" && unit === "from") {
    //   disabledOveride = true;
    // }

    if (disabledOveride) {
      disabled = true;
    }

    if (val_type === "checkbox") {
      return (
        <input
          disabled={disabled}
          className={""}
          type={val_type}
          name={question}
          onChange={e => {
            handleInputMedVal(question, e.target.checked, val_type, unit, id);
          }}
          checked={oldAnswer}
        />
      );
    } else {
      return (
        <input
          disabled={disabled}
          className={"cov19_inf_risk_input " + date_width}
          type={val_type}
          name={question}
          onBlur={e => {
            handleInputMedVal(question, e.target.value, val_type, unit, id);
          }}
          defaultValue={oldAnswer}
        />
      );
    }
  };

  const handleInputMedVal = (qID, val, val_type, unit, id) => {
    setLoading(true);
    let payload = query_params;
    payload.data = {};

    payload.data.category = "covid-19";
    payload.data.date_on = moment().format("YYYY-MM-DDTHH:mm:ss");
    payload.data.what = questions[qID].label.substring(0, 49);
    payload.data.universal_index = qID;
    if (val_type === "number") {
      payload.data.value = val;
    } else if (val_type === "date") {
      if (unit !== null) {
        if (unit === "from") {
          payload.data.time_start = moment(val).format("YYYY-MM-DDTHH:mm:ss");
        } else {
          payload.data.time_end = moment(val).format("YYYY-MM-DDTHH:mm:ss");
        }
      } else {
        console.log("input error unit not provided");
        return null;
      }
    } else if (val_type === "checkbox") {
      payload.data.reference_value = val;
    }

    if (unit !== null) {
      payload.data.unit = unit;
    }
    if (id !== null) {
      payload.data.id = id;
    }

    // console.log(payload);
    if (payload.data.id !== undefined) {
      putCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    } else {
      postCovid19MedValData(payload).then(result => {
        console.log(result);
        getCovid19MedVal();
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div className="cov19_title">
        <span style={{ fontWeight: "800" }}>
          {intl.formatMessage({
            id: "covid_oi_main_title"
          })}
        </span>
      </div>
      <div
        className="cov19_poll_question_block_title"
        style={{ textTransform: "none" }}
      >
        {intl.formatMessage({
          id: "covid_oi_sub_title_1"
        })}
      </div>
      <div style={{ flexWrap: "wrap" }} className="cov19_info_shade_block">
        <div className="cov19_shade_block_item">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q218</span>
            {questions.Q218.label}
          </div>
          {renderSelectMedVal("Q218")}
        </div>
        <div className="cov19_shade_block_item">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q219</span>
            {questions.Q219.label}
          </div>
          {renderSelectMedVal("Q219")}
        </div>
      </div>

      <div className="cov19_orange_text_block">
        {intl.formatMessage({
          id: "covid_oi_anouncment"
        })}
      </div>

      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_oi_sub_title_2"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q220</span>
            {questions.Q220.label}
          </div>
          <div className="cov19_illnes_dates_cont">
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_from"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "date", "from")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_to"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "date", "to")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_ongoing"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q220", "checkbox", null)}
            </div>
          </div>
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q221</span>
            {questions.Q221.label}
          </div>
          <div className="cov19_illnes_dates_cont">
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_from"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "date", "from")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_to"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "date", "to")}
            </div>
            <div className="cov19_dates_wrapper">
              <span>
                {intl.formatMessage({
                  id: "covid_ild_ongoing"
                })}
                :{" "}
              </span>
              {renderInputMedVal("Q221", "checkbox", null)}
            </div>
          </div>
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q222</span>
            {questions.Q222.label}
          </div>
          {renderSelectMedVal("Q222")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q242</span>
            {questions.Q242.label}
          </div>
          {renderSelectMedVal("Q242")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q243</span>
            {questions.Q243.label}
          </div>
          {renderSelectMedVal("Q243")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q244</span>
            {questions.Q244.label}
          </div>
          {renderSelectMedVal("Q244")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q245</span>
            {questions.Q245.label}
          </div>
          {renderSelectMedVal("Q245")}
        </div>
      </div>
      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_oi_sub_title_3"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q223</span>
            {questions.Q223.label}
          </div>
          {renderSelectMedVal("Q223")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q224</span>
            {questions.Q224.label}
          </div>
          {renderSelectMedVal("Q224")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q225</span>
            {questions.Q225.label}
          </div>
          {renderSelectMedVal("Q225")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q226</span>
            {questions.Q226.label}
          </div>
          {renderSelectMedVal("Q226")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q227</span>
            {questions.Q227.label}
          </div>
          {renderSelectMedVal("Q227")}
        </div>
      </div>
      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_oi_sub_title_4"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q228</span>
            {questions.Q228.label}
          </div>
          {renderSelectMedVal("Q228")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q229</span>
            {questions.Q229.label}
          </div>
          {renderInputMedVal("Q229", "number", null)}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q230</span>
            {questions.Q230.label}
          </div>
          {renderSelectMedVal("Q230")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q231</span>
            {questions.Q231.label}
          </div>
          {renderInputMedVal("Q231", "number", null)}
        </div>
      </div>
      <div className="cov19_poll_question_block_title">
        {intl.formatMessage({
          id: "covid_oi_sub_title_5"
        })}
      </div>
      <div className="cov19_inf_risk_container">
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q232</span>
            {questions.Q232.label}
          </div>
          {renderSelectMedVal("Q232")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q233</span>
            {questions.Q233.label}
          </div>
          {renderSelectMedVal("Q233")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q234</span>
            {questions.Q234.label}
          </div>
          {renderSelectMedVal("Q234")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q235</span>
            {questions.Q235.label}
          </div>
          {renderSelectMedVal("Q235")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q236</span>
            {questions.Q236.label}
          </div>
          {renderSelectMedVal("Q236")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q237</span>
            {questions.Q237.label}
          </div>
          {renderSelectMedVal("Q237")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q238</span>
            {questions.Q238.label}
          </div>
          {renderSelectMedVal("Q238")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q239</span>
            {questions.Q239.label}
          </div>
          {renderSelectMedVal("Q239")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q240</span>
            {questions.Q240.label}
          </div>
          {renderSelectMedVal("Q240")}
        </div>
        <div className="cov19_inf_risk_question_wrapper">
          <div className="cov19_inf_risk_question_text">
            <span className="covid_question_small_label">Q241</span>
            {questions.Q241.label}
          </div>
          {renderSelectMedVal("Q241")}
        </div>
      </div>

      {loading ? (
        <div className="loading_overlay">
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
}
