import React from "react";
import "./avatar.css";
import { getProfileBasics } from "../../services/getProfileBasics";
import { injectIntl } from "react-intl";
import AvatarEditor from "react-avatar-editor";

import Slider from "@material-ui/core/Slider";

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: {},
      avatar_load: true,
      avatar_to_show: "",

      image: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 50,
      preview: null,
      width: 250,
      height: 250,

      redirected: false
    };
  }

  componentDidMount() {
    if (this.state.avatar_load) {
      this.get_profile_data();
      this.setState({ avatar_load: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.avatar_to_show !== this.state.avatar_to_show) {
      this.get_profile_data();
    }
  }

  get_profile_data = () => {
    let send_credential_to_registrar = {};
    send_credential_to_registrar.logged_in = JSON.parse(
      sessionStorage.getItem("hub_token")
    ).userData;
    send_credential_to_registrar.section = "";
    getProfileBasics(send_credential_to_registrar).then(result => {
      let receiptJson = result;
      if (receiptJson.data.userData) {
        // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        this.setState({ user_data: receiptJson.data.userData }, function() {
          this.get_avatar();
        });
      } else {
        this.get_avatar();
      }
    });
  };

  get_avatar = () => {
    if (this.state.user_data.profile_picture === null) {
      if (this.state.user_data.avatar !== null) {
        this.setState({
          avatar_to_show: `/assets/images/${this.state.user_data.avatar}`
        });
      } else {
        this.setState({
          avatar_to_show: `/assets/images/person_icon.png`
        });
      }
    } else {
      this.setState({ avatar_to_show: this.state.user_data.profile_picture });
    }
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] });
    this.handleSave();
  };

  handleSave = () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState(
      {
        preview: {
          img,
          rect,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          borderRadius: this.state.borderRadius
        }
      },
      function() {
        this.props.setPicture(this.state.preview.img);
      }
    );
  };

  handleScale = (event, newValue) => {
    const scale = parseFloat(newValue);
    this.setState({ scale }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleSave();
      }, 1000);
    });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
    this.handleSave();
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleSave();
      }, 500);
    });
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="AvatarEditor">
        <AvatarEditor
          ref={this.setEditorRef}
          scale={parseFloat(this.state.scale)}
          width={this.state.width}
          height={this.state.height}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          rotate={parseFloat(this.state.rotate)}
          borderRadius={this.state.width / (100 / this.state.borderRadius)}
          onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
          onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
          onImageReady={this.logCallback.bind(this, "onImageReady")}
          image={this.state.image}
          style={{
            width: "100%",
            height: "auto",
            gridArea: "left1",
            margin: "auto 0"
          }}
          className="avatar_editor_canvas"
        />
        <div className="avatar_editor_customization">
          <Slider
            style={{ width: "80%" }}
            value={typeof this.state.scale === "number" ? this.state.scale : 0}
            min={this.state.allowZoomOut ? 0.1 : 1}
            step={0.01}
            max={2}
            onChange={this.handleScale}
            aria-labelledby="input-slider"
          />
          <img
            style={{ transform: "scaleX(-1)" }}
            alt="rotate"
            src="/assets/images/rotate.svg"
            onClick={this.rotateRight}
          />
        </div>

        {/* <div className="avatar_editor_selectfile">
          <input
            name="newImage"
            // className="custom_file_input"
            type="file"
            onChange={this.handleNewImage}
          />
        </div> */}
        <div className="input__wrapper">
          <input
            type="file"
            name="newImage"
            id="input__file"
            className="input input__file"
            onChange={this.handleNewImage}
          />
          <label htmlFor="input__file" className="input__file-button">
            <span className="input__file-button-text">
              {intl.formatMessage({
                id: "edit_profile_choose_file"
              })}
            </span>
          </label>
        </div>
      </div>
    );
  }
}

export default injectIntl(Avatar);
