import React from "react";
import { Redirect } from "react-router-dom";

const ReceiptsApp = ({ loggedInAs }) => {
  if (loggedInAs !== "visitor") {
    return <Redirect to="/receipts" />;
  }
  return null;
};
export default ReceiptsApp;
