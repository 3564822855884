import { URLconst } from "./const";
import axios from "axios";

export function putHubBasics(param) {
  let BaseURL = URLconst("hub_basics/basics.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: null,
    data: param,
  });
}

export function putReceiptToShop(param) {
  let BaseURL = URLconst("index.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: { endpoint: "receipts/shop_receipt" },
    data: param,
  });
}

export function putCovid19Ratings(param) {
  let BaseURL = URLconst("hubsite/various/covid19ratings.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
    data: { data: param.data },
  });
}

export function putUpgradeHubDB(param) {
  let BaseURL = URLconst("personal_hub/upgrade_db.php");

  return axios({
    url: BaseURL,
    method: "put",
    headers: { "content-type": "application/json" },
    params: param,
  });
}
