import { URLconst } from './const';

export function ChangePin(userData) {
  const BaseURL = URLconst('hub_locations/change_pin.php');

  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: 'POST',
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
